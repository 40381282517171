import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { saveLangId } from '../../../../../../store/langId/actions';
import parse from 'html-react-parser';
import { useTour } from '@reactour/tour';

const ResiliencyStages = () => {
  const { isOpen } = useTour();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [resultData, setResultData] = useState('');
  const [activeLanguage, setActiveLanguage] = useState();
  const langId = useSelector((state) => state?.langId?.payload);

  useEffect(() => {
    const getAssessmentData = async () => {
      try {
        const result = await window.$http.getWithHeaders('assessment/start');

        if (result.code === window.$constants.STATUS.OK) {
          setResultData(result?.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getAssessmentData();
  }, []);

  useEffect(() => {
    if (
      (langId && !resultData) ||
      (resultData?.languages?.length > 1 && langId)
    ) {
      setActiveLanguage(langId);
    } else if (
      resultData?.languages?.length == 1 &&
      resultData?.languages[0]?.rp_gbl_language_id != langId
    ) {
      setActiveLanguage(resultData?.languages[0]?.rp_gbl_language_id);
    } else {
      if (resultData) {
        setActiveLanguage(resultData?.languages[0]?.rp_gbl_language_id);
      }
    }
  }, [resultData, langId]);

  useEffect(() => {
    if (activeLanguage && activeLanguage !== langId) {
      dispatch(saveLangId(activeLanguage));
    }
  }, [activeLanguage]);

  const handleAssessment = async () => {
    try {
      const result = await window.$http.getWithHeaders('assessment');

      if (result.code === window.$constants.STATUS.OK) {
        navigate('/dashboard/assessments/terms-and-conditions');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLanguage = (value) => {
    setActiveLanguage(value);
  };

  return (
    <section id='assessment'>
      <div className='container'>
        <div className='row '>
          <div className='col-12 '>
            <div className='position-relative px-2 px-lg-0'>
              <div className='positive-impact px-2 px-lg-4 pt-4 bg-white pb-5 br-12 box_shadow'>
                {resultData?.languages?.map((el) => el)?.length > 1 ? (
                  <div className='sidenav'>
                    {resultData?.languages?.map((el, id) => (
                      <div
                        key={id}
                        onClick={() => handleLanguage(el?.rp_gbl_language_id)}
                      >
                        <button
                          href='#!'
                          className={
                            activeLanguage !== el?.rp_gbl_language_id
                              ? 'lang-btn'
                              : 'lang-btn active'
                          }
                        >
                          {el?.language_name}
                        </button>
                      </div>
                    ))}
                  </div>
                ) : (
                  ''
                )}
                <div className='col-md-12 m-auto mt-3'>
                  <h3 className='section-titel section-heading text-center'>
                    You can have a positive impact on your resiliency - Today
                  </h3>
                </div>

                <div className='col-md-9 m-auto mt-4'>
                  <div className='circle-pill my-3'>
                    <div className='pill-badge rp-primary-dark '>E</div>
                    <div className='pill-badge rp-primary-red '>F</div>
                    <div className='pill-badge rp-primary-dark'>M</div>
                    <div className='pill-badge rp-primary-red '>N</div>
                    <div className='hr-behind-card'></div>
                  </div>
                </div>
                <div className='col-md-9 m-auto mt-4'>
                  <div className='icon-list pt-4'>
                    <ul>
                      {resultData?.points?.map((el, id) => (
                        <li className='d-flex align-items-center mb-4' key={id}>
                          <div className=' icon'>
                            <i className='bi bi-command'></i>
                          </div>
                          <div className='li-cnt'>
                            <p>{parse(`${el[activeLanguage]}`)}</p>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className='mt-0 mt-lg-4 text-md-center text-lg-start'>
                    <button
                      id='second'
                      className={`btn btn-rp-primary take-ass ${
                        isOpen ? 'disabled' : ''
                      }`}
                      onClick={handleAssessment}
                    >
                      Begin Assessment
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResiliencyStages;
