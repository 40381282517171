import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import RpFooterLogo from '../../assets/images/rp-logo.png';
import Disclaimer from '../../pages/Login/Footer/Components/Disclaimer';
import PrivacyPolicy from '../../pages/Login/Footer/Components/PrivacyPolicy';
import TermsOfUse from '../../pages/Login/Footer/Components/TermsOfUse';

const Footer = (props) => {
  const [showModal, setShowModal] = useState('');
  const assmtData = useSelector((state) => state?.assmt);

  const handleCloseModal = () => {
    setShowModal('');
  };

  const handleShowModal = (value) => {
    setShowModal(value);
  };
  return (
    <>
      <footer
        className={props.domainValue === 'sms' ? 'd-none d-lg-block' : ''}
      >
        <div
          className={`primary-footer ${props?.addClass} ${
            props.value === 'footer' ? 'hide' : ''
          }`}
        >
          <div className='container'>
            <div className='row m-0'>
              <div className='d-flex justify-content-between'>
                <div className='d-flex align-items-center justify-content-start'>
                  <div className='ft-logo'>
                    <img className='img-fluid' src={RpFooterLogo} alt='logo' />
                  </div>
                </div>
                <div
                  className='d-none d-xl-flex align-items-center justify-content-center'
                  onClick={() => handleShowModal('privacy')}
                >
                  <span data-bs-toggle='modal' data-bs-target='#privacy'>
                    Privacy Policy
                  </span>
                </div>
                <div
                  className='d-none d-xl-flex align-items-center justify-content-end'
                  onClick={() => handleShowModal('terms')}
                >
                  <span data-bs-toggle='modal' data-bs-target='#terms'>
                    Terms of Use
                  </span>
                </div>

                <div
                  className='d-none d-xl-flex align-items-center justify-content-end'
                  onClick={() => handleShowModal('disclaimer')}
                >
                  <span data-bs-toggle='modal' data-bs-target='#disclaimer'>
                    Disclaimer
                  </span>
                </div>
                <div className='d-none d-xl-flex align-items-center justify-content-end'>
                  <span style={{ cursor: 'default' }}>
                    Version{' '}
                    {props.version
                      ? props.version
                      : assmtData?.metadata?.web_curr_version}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {showModal === 'privacy' ? (
        <PrivacyPolicy
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          handleShowModal={handleShowModal}
        />
      ) : showModal === 'terms' ? (
        <TermsOfUse showModal={showModal} handleCloseModal={handleCloseModal} />
      ) : showModal === 'disclaimer' ? (
        <Disclaimer showModal={showModal} handleCloseModal={handleCloseModal} />
      ) : (
        ''
      )}
    </>
  );
};

export default Footer;
