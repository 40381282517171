import React, { useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';

const SupportModal = () => {
  const [msg, setMsg] = useState('');
  const [desc, setDesc] = useState('');
  const [buttonState, setButtonState] = useState({
    value: '',
    isLoading: false,
  });
  const [modalVisible, setModalVisible] = useState(false);

  const sendQuery = async () => {
    setButtonState({ value: 'sendQuery', isLoading: true });
    try {
      const result = await window.$http.postWithHeaders('support', {
        description: desc,
        device_browser_info: window.navigator.userAgent,
      });

      if (result.code === window.$constants.STATUS.OK) {
        setMsg(result?.data?.message);
        setModalVisible(true);
      } else {
        console.log('Something went wrong!');
      }
    } catch (err) {
      console.log(err);
    }
    setButtonState({ value: 'sendQuery', isLoading: false });
  };

  const handleCloseModal = () => {
    setDesc('');
    setModalVisible(false);
  };

  return (
    <div
      className='modal fade'
      id='support'
      data-bs-backdrop='static'
      aria-hidden='true'
      tabIndex='-1'
    >
      <div className='modal-dialog modal-lg modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header px-4'>
            <h5 className='modal-title'>Support</h5>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={handleCloseModal}
            ></button>
          </div>
          <div className='modal-body'>
            <div className='row'>
              <div
                className={
                  modalVisible ? 'jq-query-field hide' : 'jq-query-field'
                }
              >
                <h1
                  className='rp-primary-dark-g mb-4'
                  style={{
                    fontSize: '25px',
                    letterSpacing: '0.3px',
                    lineHeight: '30px',
                  }}
                >
                  Please describe the issue you are facing, and we will look
                  into it immediately.
                </h1>

                <div className='col-md-12 mb-4'>
                  <textarea
                    className='form-control'
                    style={{ height: 'unset' }}
                    required
                    name=''
                    placeholder='Tell us about the issue you are facing *'
                    value={desc}
                    onChange={(e) => setDesc(e.target.value)}
                    id=''
                    cols='30'
                    rows='5'
                  ></textarea>
                </div>
              </div>
              <div
                className={
                  modalVisible
                    ? 'jq-query-submisson'
                    : 'jq-query-submisson hide'
                }
              >
                <h1
                  className='rp-primary-dark-g mb-4'
                  style={{
                    fontSize: '25px',
                    letterSpacing: '0.3px',
                    lineHeight: '30px',
                  }}
                >
                  {msg}
                </h1>
              </div>
            </div>
          </div>
          <div
            className={
              modalVisible
                ? 'modal-footer jq-query-field hide'
                : 'modal-footer jq-query-field'
            }
          >
            <button
              type='button'
              className={`btn btn-rp-primary btn-small ${
                buttonState?.isLoading && buttonState?.value === 'sendQuery'
                  ? 'disabled'
                  : ''
              }`}
              onClick={sendQuery}
            >
              {buttonState?.isLoading && buttonState?.value === 'sendQuery' ? (
                <Spinner
                  as='i'
                  animation='border'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
              ) : (
                'Submit'
              )}
            </button>
            <button
              type='button'
              className='btn btn-rp-primary-red btn-small'
              data-bs-dismiss='modal'
              onClick={handleCloseModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportModal;
