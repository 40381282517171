import React from 'react';

const ProgressBar = (props) => {
  return (
    <>
      <ul className='nav nav-pills over-tab-div' id='pills-tab' role='tablist'>
        {props.burnouts?.length
          ? props.burnouts?.map((item, index) => (
              <div key={index}>
                <li className='nav-item over-tab' role='presentation'>
                  <button
                    onClick={() =>
                      props.handleProgressBarClick(
                        item?.rp_gbl_burnout_stage_id,
                        item?.title
                      )
                    }
                    className={`btn btn-rp-secondary ${
                      props.burnLevel === item?.rp_gbl_burnout_stage_id
                        ? 'active'
                        : ''
                    }`}
                    type='button'
                  >
                    {item?.title}
                  </button>
                  <div className='progress-label'>
                    <span className='label-cent'>
                      {item?.participated_percentage}%
                    </span>
                    <br />
                    <span className='participants-count'>
                      {item?.participated} of {item?.total}
                    </span>
                  </div>
                </li>
              </div>
            ))
          : 'No Data'}
      </ul>
    </>
  );
};

export default ProgressBar;
