import { SAVEMFAHASH, REMOVEMFAHASH } from './types';

const INITIAL_STATE = {};

const mfaHash = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SAVEMFAHASH: {
      return {
        ...state,
        mfaHash: payload?.data?.mfa_hash,
      };
    }
    case REMOVEMFAHASH: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default mfaHash;
