import React from 'react';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import AuthGuard from './AuthGuard';
import Login from '../../pages/Login';
import DashboardRoutes from './DashboardRoutes/DashboardRoutes';
import AssessmentRoutes from './DashboardRoutes/AssessmentRoutes';
import ResiliencyPlanRoutes from './DashboardRoutes/ResiliencyPlanRoutes';
import OnBoarding from '../../pages/OnBoarding';
import ViewProfile from '../../pages/Menu/ProfilePages/ViewProfile';
import EditProfile from '../../pages/Menu/ProfilePages/EditProfile';
import Setting from '../../pages/Menu/Setting';
import RedirectRoutes from './RedirectRoutes';
import PhoneVerification from '../../pages/OnBoarding/Components/PhoneVerification';
import NotFound from '../../pages/Others/NotFound';
import NavigateUsers from './NavigateUsers';
import Invite from '../../pages/Menu/Invite';
import AdminRoutes from './DashboardRoutes/AdminRoutes';
import Maintenance from '../../pages/Others/Maintenance';
import ScrollToTop from './ScrollToTop';
import ResetPassword from '../../pages/Login/ResetPassword';
import Access from '../../common/Access';
import AnalyticsRoutes from './DashboardRoutes/AnalyticsRoutes';

const MainRouter = (props) => {
  const DashboardLayout = React.lazy(() => import('../../pages/Dashboard'));
  const AssessmentLayout = React.lazy(() =>
    import('../../pages/Dashboard/Components/Assessment')
  );
  const ResiliencyPlanLayout = React.lazy(() =>
    import('../../pages/Dashboard/Components/ResiliencyPlan')
  );
  const AdminLayout = React.lazy(() =>
    import('../../pages/Dashboard/Components/Admin')
  );
  const AnalyticsLayout = React.lazy(() =>
    import('../../pages/Dashboard/Components/CompanyAnalytics')
  );

  return (
    <ScrollToTop>
      <Routes>
        {['/login/*', '/'].map((path, index) => (
          <Route
            path={path}
            key={index}
            element={
              <NavigateUsers
                path='/'
                element={
                  <Routes>
                    <Route path='/' element={<Login />} key={index} />
                  </Routes>
                }
              />
            }
          />
        ))}

        <Route
          path='/dashboard'
          element={
            <RedirectRoutes
              path='/'
              element={
                <AuthGuard
                  is_profile_completed={true}
                  path='/*'
                  {...props}
                  element={
                    <>
                      <DashboardLayout {...props}>
                        <Routes>
                          {DashboardRoutes.map((el) => (
                            <Route
                              path={el.url}
                              key={el.url}
                              element={<el.element />}
                            />
                          ))}
                        </Routes>
                      </DashboardLayout>
                    </>
                  }
                />
              }
            />
          }
        />

        <Route
          path='/on-boarding/*'
          element={
            <RedirectRoutes
              path='/'
              element={
                <AuthGuard
                  isOnboarding={true}
                  path='/'
                  element={
                    <Routes>
                      <Route path='/' element={<OnBoarding />} />
                    </Routes>
                  }
                />
              }
            />
          }
        />
        <Route
          path='/phone-verification/*'
          element={
            <RedirectRoutes
              path='/'
              element={
                <AuthGuard
                  isOnboarding={true}
                  path='/'
                  element={
                    <Routes>
                      <Route path='/' element={<PhoneVerification />} />
                    </Routes>
                  }
                />
              }
            />
          }
        />

        {['/dashboard/', '/dashboard/assessments/*'].map((path, index) => (
          <Route
            path={path}
            key={index}
            element={
              <RedirectRoutes
                path='/'
                element={
                  <AuthGuard
                    is_profile_completed={true}
                    {...props}
                    path='/*'
                    element={
                      <>
                        <AssessmentLayout {...props}>
                          <Routes>
                            {AssessmentRoutes.map((el) => (
                              <Route
                                path={el.url}
                                key={el.url}
                                element={<el.element />}
                              />
                            ))}
                          </Routes>
                        </AssessmentLayout>
                      </>
                    }
                  />
                }
              />
            }
          />
        ))}

        {
          <Route
            path='/dashboard/resiliency-plan/*'
            element={
              <RedirectRoutes
                path='/'
                element={
                  <AuthGuard
                    is_profile_completed={true}
                    {...props}
                    path='/*'
                    element={
                      <>
                        {`/${window.location.href.split('/')[4]}`?.includes(
                          'resiliency-plan'
                        ) &&
                        !`/${window.location.href.split('/')[5]}`?.includes(
                          'undefined'
                        ) ? (
                          ResiliencyPlanRoutes?.some(
                            (el) =>
                              el?.url?.includes(
                                `/${window.location.href.split('/')[5]}`
                              )
                            // `/${window.location.href.split('/')[4]}`?.includes(
                            //   'resiliency-plan'
                          ) ? (
                            <ResiliencyPlanLayout {...props}>
                              <Routes>
                                {ResiliencyPlanRoutes.map((el) => (
                                  <Route
                                    path={el.url}
                                    key={el.url}
                                    element={<el.element />}
                                  />
                                ))}
                              </Routes>
                            </ResiliencyPlanLayout>
                          ) : (
                            <Routes>
                              <Route path='*' element={<NotFound />} />
                            </Routes>
                          )
                        ) : (
                          <ResiliencyPlanLayout {...props}>
                            <Routes>
                              {ResiliencyPlanRoutes.map((el) => (
                                <Route
                                  path={el.url}
                                  key={el.url}
                                  element={<el.element />}
                                />
                              ))}
                            </Routes>
                          </ResiliencyPlanLayout>
                        )}
                      </>
                    }
                  />
                }
              />
            }
          />
        }

        {
          <Route
            path='/dashboard/company-analytics/*'
            element={
              <RedirectRoutes
                path='/'
                element={
                  <AuthGuard
                    is_profile_completed={true}
                    {...props}
                    path='/*'
                    element={
                      <>
                        <AnalyticsLayout {...props}>
                          <Routes>
                            {AnalyticsRoutes.map((el) => (
                              <Route
                                path={el.url}
                                key={el.url}
                                element={<el.element />}
                              />
                            ))}
                          </Routes>
                        </AnalyticsLayout>
                      </>
                    }
                  />
                }
              />
            }
          />
        }

        {['/manage/*', '/dashboard/admin/*'].map((path, index) => (
          <Route
            path={path}
            key={index}
            element={
              <RedirectRoutes
                path='/'
                element={
                  <AuthGuard
                    is_profile_completed={true}
                    {...props}
                    path='/*'
                    element={
                      <>
                        <AdminLayout {...props}>
                          <Routes>
                            {AdminRoutes.map((el) => (
                              <Route
                                path={el.url}
                                key={el.url}
                                element={<el.element />}
                              />
                            ))}
                          </Routes>
                        </AdminLayout>
                      </>
                    }
                  />
                }
              />
            }
          />
        ))}

        <Route
          path='/view-profile/*'
          element={
            <RedirectRoutes
              path='/'
              element={
                <AuthGuard
                  is_profile_completed={true}
                  {...props}
                  path='/*'
                  element={<ViewProfile />}
                />
              }
            />
          }
        />
        <Route
          path='/edit-profile/*'
          element={
            <RedirectRoutes
              is_profile_completed={true}
              {...props}
              path='/*'
              element={<AuthGuard path='/' element={<EditProfile />} />}
            />
          }
        />
        <Route
          path='/setting/*'
          element={
            <RedirectRoutes
              is_profile_completed={true}
              {...props}
              path='/*'
              element={<AuthGuard path='/' element={<Setting />} />}
            />
          }
        />
        <Route
          path='/invite/*'
          element={
            <RedirectRoutes
              is_profile_completed={true}
              {...props}
              path='/*'
              element={<AuthGuard path='/' element={<Invite />} />}
            />
          }
        />

        <Route
          path='/password/forgot'
          exact
          element={<Login isVisible={true} />}
        />

        <Route
          path='/login/otp/:password_reset_hash'
          exact
          element={<Login value='otp' />}
        />

        {[
          '/password/create/:password_reset_hash',
          '/password/reset/:password_reset_hash',
        ].map((path, index) => (
          <Route path={path} key={index} element={<ResetPassword />} />
        ))}

        <Route path='/landing-page' element={<Access />} />
        <Route path='/404' element={<NotFound />} />
        <Route path='/maintenance' element={<Maintenance />} />
        <Route path='/logout' exact element={<Navigate to='/login' />} />
        {/* <Route path='*' element={<Navigate to='/login' />} /> */}
        <Route path='*' element={<NotFound />} />
      </Routes>
    </ScrollToTop>
  );
};

export default MainRouter;
