import React, { useState } from 'react';
import { Tooltip } from 'antd';
import RpiImage from '../../assets/images/rpi.png';
import ModalFade from './Components/ModalFade';
import { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const VideoPlayer = (props) => {
  const [result, setResult] = useState([]);
  const [content_Id, setContent_Id] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isVideoLiked, setIsVideoLiked] = useState(false);
  const [resultData, setResultData] = useState([]);

  useEffect(() => {
    setResultData(props.resultData);
  }, [props.resultData]);

  const handleShowModal = (index, content_id) => {
    setShowModal(true);
    setContent_Id(content_id);
    const data = props.resultData.find((data) => data.rp_content_id === index);
    setResult((prevResult) => data);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setResult([]);
    setContent_Id('');
  };

  const handleVideoContent = (
    rp_content_category_id,
    rp_content_id,
    content_id
  ) => {
    handleShowModal(rp_content_id, content_id);
    contentAudit(rp_content_category_id, rp_content_id);
  };

  const contentAudit = async (rp_content_category_id, rp_content_id) => {
    try {
      const result = await window.$http.getWithHeaders(
        `/content/viewaudit/${
          rp_content_category_id ? rp_content_category_id : 0
        }/${rp_content_id}`
      );

      if (result.code === window.$constants.STATUS.OK) {
        console.log('success');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const likeContent = async (id) => {
    try {
      const result = await window.$http.postWithHeaders('favourite/content', {
        content_id: id,
      });
      if (result.code === window.$constants.STATUS.OK) {
        toast.success(result?.data?.message);
        if (props.value === 'favourite') {
          console.log('Success');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = (id, rp_content_id, favourite) => {
    var copyResultData = resultData;
    if (copyResultData.length) {
      let index = copyResultData?.findIndex(
        (el) => el?.rp_content_id === rp_content_id
      );

      copyResultData[index].is_favourite = !favourite;
      setResultData(copyResultData);
      setIsVideoLiked(!isVideoLiked);
      const found = copyResultData.some((el) => el.is_favourite === false);
      if (props?.unFavFun) {
        props.unFavFun(found);
      }
    }
    likeContent(rp_content_id);
  };

  return (
    <>
      {props?.resultData?.map((data, id) =>
        data?.rp_content_type_id === window.$enums.ContentType.Video ? (
          <div className='col-md-4 mb-5' key={id}>
            <div
              className='video-card position-relative'
              style={{
                background: `url(${process.env.REACT_APP_API_URL}media/content/v2/${data?.content_image_id}/thumb/${data?.rp_content_category_id}) no-repeat center center/cover`,
              }}
            >
              {props.value !== 'favourite' ? (
                <button
                  className='btn-play video-card-play'
                  data-bs-toggle='modal'
                  data-bs-target='#video1'
                  onClick={() =>
                    handleVideoContent(
                      data?.rp_content_category_id,

                      data?.rp_content_id,

                      data?.content_id
                    )
                  }
                >
                  <i className='fa-solid fa-play text-light'></i>
                </button>
              ) : (
                ''
              )}
              {data?.is_favourite && props.value === 'favourite' ? (
                <button
                  className='btn-play video-card-play'
                  data-bs-toggle='modal'
                  data-bs-target='#video1'
                  onClick={() =>
                    handleVideoContent(
                      data?.rp_content_category_id,

                      data?.rp_content_id,

                      data?.content_id
                    )
                  }
                >
                  <i className='fa-solid fa-play text-light'></i>
                </button>
              ) : (
                props.value === 'favourite' && (
                  <div className='undo-massage'>
                    <p>Video removed from your favourites</p>
                    <span
                      onClick={() =>
                        handleClick(id, data?.rp_content_id, data?.is_favourite)
                      }
                    >
                      Undo
                    </span>
                  </div>
                )
              )}
            </div>
            <div className='video-card-discription'>
              <div className='music-titel'>
                {data?.content_title?.length < 40 ? (
                  <h6>{data?.content_title}</h6>
                ) : (
                  <Tooltip title={data?.content_title} placement='bottomRight'>
                    <h6>
                      {data?.content_title?.slice(0, 40)}
                      <span
                        style={{
                          color: '#1a1a1a',
                        }}
                      >
                        ...
                      </span>
                    </h6>
                  </Tooltip>
                )}
                {data?.content_description?.length < 50 ? (
                  <p>{data?.content_description}</p>
                ) : (
                  <Tooltip
                    title={data?.content_description}
                    placement='bottomRight'
                  >
                    <p>
                      {data?.content_description?.slice(0, 50)}
                      <span
                        style={{
                          color: 'rgba(44, 65, 67, 0.5)',
                        }}
                      >
                        ...
                      </span>
                    </p>
                  </Tooltip>
                )}
              </div>
              <div className='cnt-source mb-2'>
                <span>{data?.content_length_str}</span>
                <div>
                  <span
                    className={
                      resultData.length &&
                      resultData?.find(
                        (el) => el.rp_content_id === data?.rp_content_id
                      )?.is_favourite
                        ? 'like-btn liked'
                        : 'like-btn'
                    }
                    onClick={() =>
                      handleClick(id, data?.rp_content_id, data?.is_favourite)
                    }
                  ></span>
                  <img src={RpiImage} alt='Source ioc' />
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )
      )}

      {props?.notif === 'showNotification' ? '' : <ToastContainer />}

      <ModalFade
        result={result}
        content_Id={content_Id}
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        handleClick={handleClick}
      />
    </>
  );
};

export default VideoPlayer;
