import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ManageLocationForm from './Components/ManageLocationForm';
import { Popconfirm } from 'antd';
import { usePagination, DOTS } from '../../../hooks/Pagination/usePagination';
import { isEmpty } from 'lodash';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const text = 'Are you sure you want to delete?';

const InitialItemState = {
  rp_location_id: '',
  parentLocationName: '',
  locationName: '',
  locationId: '',
};

const ManageLocation = () => {
  const companyState = useSelector((state) => state.companyData);
  const [resultData, setResultData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [items, setItems] = useState(InitialItemState);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [formErrors, setFormErrors] = useState({});
  const [msg, setMsg] = useState('');
  const [locationOptions, setLocationOptions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [clickList, setClickList] = useState([]);
  const [buttonState, setButtonState] = useState({
    value: '',
    isLoading: false,
  });
  const [location, setLocation] = useState({
    parentLocationName: '',
    locationName: '',
    locationId: '',
  });
  const [rp_parent_id, setrp_parent_id] = useState('');
  const [parentId, setParentId] = useState('');
  const [isActive, setIsActive] = useState('');
  const [activeValue, setActiveValue] = useState('');
  const [isSubmit, setIsSubmit] = useState(false);
  const siblingCount = 1;
  const paginationRange = usePagination({
    page,
    pageCount,
    siblingCount,
  });
  const tableId = document.getElementById('table');
  const columnLength = tableId?.rows[0]?.cells?.length;
  const [searchPageCount, setSearchPageCount] = useState(1);
  const [isEnterClicked, setIsEnterClicked] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [metaData, setMetaData] = useState(false);
  const userState = useSelector((state) => state?.user);
  const [isAdmin, setIsAdmin] = useState();
  const companyTypeId = useSelector((state) => state?.companyTypeId?.payload);
  const [styleChange, setStyleChange] = useState(false);

  useEffect(() => {
    getLocationOptions();
    if (
      userState?.rp_gbl_company_type_id ===
      window.$enums.CompanyTypeId.CompanyAdmin
    ) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [companyTypeId]);

  function queryParamBuilder() {
    let query;
    if (
      userState?.rp_gbl_company_type_id ===
        window.$enums.CompanyTypeId.CompanyAdmin &&
      companyTypeId?.rp_gbl_company_type_id
    ) {
      query = {
        rp_company_id: window.$utility.setCompanyId(userState, companyTypeId),
      };
    }
    return query;
  }

  useEffect(() => {
    if (page && searchValue && isEnterClicked) {
      return getLocationData(searchValue);
    } else {
      return getLocationData();
    }
  }, [clickList?.length, isEnterClicked, companyTypeId]);

  const getLocationOptions = async () => {
    try {
      const query = queryParamBuilder();
      const result = await window.$http.getWithQueryAndHeaders(
        `${window.$utility.setApiUrl(userState)}locations`,
        query
      );
      if (result.code === window.$constants.STATUS.OK) {
        setLocationOptions(result?.data);
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (items) {
      setLocation({
        parentLocationName: items?.parentLocationName,
        locationName: items?.locationName,
        locationId: items?.locationId,
      });
    }
    if (parentId === 0) {
      setrp_parent_id('');
    } else {
      setrp_parent_id(parentId);
    }
    if (activeValue?.toString() === 'false') {
      setIsActive(activeValue);
    } else {
      setIsActive('Yes');
    }
  }, [items, activeValue, parentId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let currentFormErrors = formErrors;
    setMsg('');
    switch (name) {
      case 'locationName':
        if (!value) {
          currentFormErrors[name] = 'Please Enter Location Name';
        } else {
          delete currentFormErrors[name];
        }
        break;
      case 'locationId':
        if (!value) {
          currentFormErrors[name] = 'Please Enter Location Id';
        } else {
          delete currentFormErrors[name];
        }
        break;
      default:
        currentFormErrors = 'All fields are required';
        break;
    }
    setLocation({ ...location, [name]: value });
    setFormErrors(currentFormErrors);
  };

  const handleParentState = (e) => {
    setrp_parent_id(e.target.value);
  };

  const handleActiveState = (e) => {
    setIsActive(e.target.value);
  };

  const handleItems = () => {
    setItems({});
    setParentId('');
    setActiveValue('');
    setMsg('');
    setFormErrors({});
    setShowModal(true);
    setIsSubmit(false);
  };

  const handleEditedItems = () => {
    setMsg('');
    setFormErrors({});
    setShowModal(true);
    setIsSubmit(false);
  };

  const pageLoadNext = (e) => {
    if (!metaData?.request?.q) {
      setIsEnterClicked(false);
    }
    clickList.push(e);
    setClickList(clickList);
    if (page < pageCount) {
      return setPage(page + 1);
    } else {
      return setPage(pageCount);
    }
  };

  const pageLoadPrev = (e) => {
    if (!metaData?.request?.q) {
      setIsEnterClicked(false);
    }
    clickList.push(e);
    setClickList(clickList);
    if (page > 1) {
      setPage(page - 1);
    } else {
      return;
    }
  };

  const handlePageNo = (e, number) => {
    if (!metaData?.request?.q) {
      setIsEnterClicked(false);
    }
    clickList.push(e);
    setClickList(clickList);
    setPage(number);
  };

  const saveLocation = async (id) => {
    if (!location.locationId) {
      return setIsSubmit(true);
    } else if (!location.locationName) {
      return setIsSubmit(true);
    } else {
      setButtonState({ value: 'saveLocation', isLoading: true });
      const query = queryParamBuilder();
      try {
        if (!id) {
          const result = await window.$http.postWithQueryAndHeaders(
            `${window.$utility.setApiUrl(userState)}location/${0}`,
            query,
            {
              rp_parent_id: rp_parent_id,
              location_name: location.locationName,
              location_id: location.locationId,
              is_active: isActive,
            }
          );

          if (result.code === window.$constants.STATUS.CONFLICT) {
            setMsg(result?.message);
          } else {
            setMsg('');
          }
          if (result.code === window.$constants.STATUS.OK) {
            handleModal();
            getLocationData();
            toast.success(result?.message);
          } else {
            console.log('Something went wrong!');
          }
        }
      } catch (err) {
        console.log(err);
      }
      setButtonState({ value: 'saveLocation', isLoading: false });
    }
  };

  const updateLocation = async (id) => {
    if (!location.locationId) {
      return setIsSubmit(true);
    } else if (!location.locationName) {
      return setIsSubmit(true);
    } else {
      setButtonState({ value: 'updateLocation', isLoading: true });
      try {
        const query = queryParamBuilder();
        const result = await window.$http.postWithQueryAndHeaders(
          `${window.$utility.setApiUrl(userState)}location/${id}`,
          query,
          {
            rp_parent_id: rp_parent_id,
            location_name: location.locationName,
            location_id: location.locationId,
            is_active: isActive,
          }
        );
        if (result.code === window.$constants.STATUS.CONFLICT) {
          setMsg(result?.message);
        }
        if (result.code === window.$constants.STATUS.OK) {
          if (searchValue) {
            getLocationData(searchValue);
          } else {
            getLocationData();
          }

          handleModal();
          toast.success(result?.message);
        } else {
          console.log('Something went wrong!');
        }
      } catch (err) {
        console.log(err);
      }
      setButtonState({ value: 'updateLocation', isLoading: false });
    }
  };

  const getLocationData = async (searchValue, pageNo) => {
    setIsDataLoading(true);
    try {
      const query = {
        q: searchValue,
        page: searchValue
          ? pageNo
            ? pageNo
            : isEnterClicked || searchPageCount === 1
            ? page
            : searchPageCount > 1
            ? page
            : ''
          : page,
        rp_company_id: window.$utility.setCompanyId(userState, companyTypeId),
      };

      const result = await window.$http.getWithQueryAndHeaders(
        `${window.$utility.setApiUrl(userState)}location`,
        query
      );
      if (result.code === window.$constants.STATUS.OK) {
        setIsDataLoading(false);
        setResultData(result?.data);
        setMetaData(result?.metadata);
        if (searchValue) {
          setSearchPageCount(result?.metadata?.page_count);
        }
        setPageCount(result?.metadata?.page_count);
      } else {
        setIsDataLoading(false);
        console.log('Something went wrong!');
      }
    } catch (error) {
      setIsDataLoading(false);
      console.log(error);
    }
  };

  const getLocation = async (id) => {
    try {
      const query = queryParamBuilder();
      const result = await window.$http.getWithQueryAndHeaders(
        `${window.$utility.setApiUrl(userState)}location/${id}`,
        query
      );
      if (result.code === window.$constants.STATUS.OK) {
        setItems({
          rp_location_id: result?.data?.rp_location_id,
          parentLocationName: result?.data?.rp_parent_id,
          locationName: result?.data?.location_name,
          locationId: result?.data?.location_id,
        });
        setParentId(result?.data?.rp_parent_id);
        setActiveValue(result?.data?.is_active);
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteLocation = async (id) => {
    try {
      const query = queryParamBuilder();
      const result = await window.$http.deleteWithQueryAndHeaders(
        `${window.$utility.setApiUrl(userState)}location/${id}`,
        query
      );
      if (result.data.code === window.$constants.STATUS.OK) {
        setShowModal(false);
        if (searchValue) {
          getLocationData(searchValue);
        } else {
          getLocationData();
        }
        toast.success(result?.data?.message);
      } else {
        result?.data?.message
          ? toast.error(result?.data?.message)
          : toast.error('Error occured, please try again or contact admin.');
        console.log('Something went wrong!');
      }
    } catch (error) {
      toast.error('Error occured, please try again or contact admin.');
      console.log(error);
    }
  };

  const handleKeyPress = (e) => {
    if (e?.key === 'Enter' || e?.key === 'NumpadEnter') {
      setPage(1);
      setSearchPageCount(1);
      setIsEnterClicked(true);
      getLocationData(searchValue, 1);
    }
  };

  const handleAddButton = () => {
    setPage(1);
    handleItems();
  };

  const handleModal = () => {
    setItems(InitialItemState);
    setShowModal(false);
    setButtonState({ value: '', isLoading: false });
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    setStyleChange(true);
    if (isEmpty(e.target.value)) {
      setStyleChange(false);
      getLocationData();
    }
  };

  const handleCrossClick = () => {
    setSearchValue('');
    setStyleChange(false);
    if (isEnterClicked) {
      getLocationData();
    }
  };

  return (
    <>
      <h1 className='section-heading text-center rp-primary-dark-g'>
        Locations at {companyState?.company_display_name}
      </h1>

      {/* <div>
        <h2 className='section-sub-heading text-center fs-5 rp-primary-dark-g'>
          Create and Manage locations to better understand resiliency patterns
        </h2>
      </div> */}

      <div className='col-md-12 d-flex align-items-center justify-content-between mt-4'>
        <div className='col-md-6'>
          <div className='position-relative'>
            <span style={{ position: 'absolute', left: '10px', top: '10px' }}>
              <i
                type='button'
                className='bi bi-search z-1 me-n5'
                onClick={() => getLocationData(searchValue)}
              ></i>
            </span>
            <input
              className='form-control ps-5'
              name='search'
              type='search'
              placeholder='Search by location name, or location id'
              aria-label='Search'
              value={searchValue}
              onChange={(e) => handleSearchChange(e)}
              onKeyPress={(e) => handleKeyPress(e)}
            />
            <span
              className='search-cancel-button'
              style={
                styleChange
                  ? { opacity: '1', cursor: 'pointer', pointerEvents: 'auto' }
                  : {}
              }
              onClick={handleCrossClick}
            >
              <i class='fa-solid fa-xmark'></i>
            </span>
          </div>
        </div>
        <div className='text-end'>
          <button
            type='button'
            title='Add Location'
            className='btn btn-rp-primary'
            onClick={handleAddButton}
          >
            Add Location
          </button>
        </div>
      </div>

      <div className='br-12 bg-light p-4 bg-white mt-5'>
        <div className='table-responsive'>
          <table className='table align-middle people-page' id='table'>
            <thead>
              <tr height='50' valign='middle' className='cursor-p'>
                <th>Location Name</th>
                <th>Parent Location</th>
                <th>Location Id</th>
                <th>Is Active</th>
                <th style={{ textAlign: 'center' }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {!isDataLoading ? (
                resultData ? (
                  resultData.map((data, id) => (
                    <tr className='tr-group-item' key={id}>
                      <td>{data?.location_name}</td>
                      <td>{data?.parent_location_name}</td>
                      <td>{data?.location_id}</td>
                      <td>
                        {(data?.is_active).toString() === 'true' ? 'Yes' : 'No'}
                      </td>
                      <td className='downlode_result'>
                        <span
                          className='btn btn-sm btn-rp-primary btn-space me-1'
                          title='Edit Location'
                          onClick={() =>
                            getLocation(data?.rp_location_id) &&
                            handleEditedItems()
                          }
                        >
                          <i className='fa-solid fa-pen'></i>
                        </span>
                        <Popconfirm
                          placement='topRight'
                          title={text}
                          onConfirm={() => deleteLocation(data?.rp_location_id)}
                          okText='Yes'
                          cancelText='No'
                          overlayClassName='antdButton'
                        >
                          <span
                            className='btn btn-sm btn-rp-primary-red btn-space'
                            title='Delete Location'
                            style={{ minWidth: '41px' }}
                          >
                            <i className='fa-solid fa-trash-can'></i>
                          </span>
                        </Popconfirm>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className='tr-group-item'>
                    <td
                      colspan={columnLength}
                      height='80'
                      style={{ borderRadius: 'unset' }}
                      className='text-center'
                    >
                      No result found
                    </td>
                  </tr>
                )
              ) : (
                <tr className='tr-group-item'>
                  <td
                    colSpan={columnLength}
                    height='80'
                    style={{ borderRadius: 'unset' }}
                    className='text-center'
                  >
                    <Spinner
                      as='i'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* <!-- pagination --> */}
      <nav className='mt-5' aria-label='Page navigation example'>
        <ul className='pagination justify-content-center'>
          <li className='page-item'>
            <button
              className='page-link'
              to='#'
              aria-label='Previous'
              onClick={pageLoadPrev}
            >
              <span aria-hidden='true'>
                <i className='fa-solid fa-angle-left'></i>
              </span>
            </button>
          </li>
          {paginationRange
            ? paginationRange.map((pageNumber) => {
                if (pageNumber === DOTS) {
                  return <li className='dots'>&#8230;</li>;
                }
                return (
                  <li className='page-item' key={pageNumber}>
                    <Link
                      className={
                        page === pageNumber ? 'page-link active' : 'page-link'
                      }
                      to='#'
                      onClick={(event) => handlePageNo(event, pageNumber)}
                    >
                      {pageNumber}
                    </Link>
                  </li>
                );
              })
            : ''}
          <li className='page-item'>
            <button
              className='page-link'
              to='#'
              aria-label='Next'
              onClick={pageLoadNext}
            >
              <span aria-hidden='true'>
                <i className='fa-solid fa-angle-right'></i>
              </span>
            </button>
          </li>
        </ul>
      </nav>

      <ToastContainer />
      <ManageLocationForm
        items={items}
        location={location}
        rp_parent_id={rp_parent_id}
        isActive={isActive}
        buttonState={buttonState}
        saveLocation={saveLocation}
        handleCloseModal={handleModal}
        updateLocation={updateLocation}
        handleChange={handleChange}
        handleParentState={handleParentState}
        handleActiveState={handleActiveState}
        formErrors={formErrors}
        message={msg}
        locationOptions={locationOptions}
        isSubmit={isSubmit}
        showModal={showModal}
      />
    </>
  );
};

export default ManageLocation;
