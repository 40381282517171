import React from 'react';

const ParticipantCard = (props) => {
  return (
    <div className='d-flex justify-content-between'>
      {props.participationDetail?.length
        ? props.participationDetail?.map((item, index) => (
            <div key={index} className='participant-card'>
              <div className='dots-styles'></div>
              <h6 className='participation-group'>{item?.title}</h6>
              <div className='titel'>{item?.demographic}</div>
              <div className='participation-stats'>
                {item?.participated_percentage}% <br />
                <span className='primary-color'>Participation</span>
              </div>
              <div className='out-of'>
                <span className='fw-bold rp-med-gray'>
                  {item?.participated} of {item?.total}
                </span>
                <br />
                {item?.description}
              </div>
            </div>
          ))
        : 'No Data'}
    </div>
  );
};

export default ParticipantCard;
