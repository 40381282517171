import { combineReducers } from 'redux';
import auth from './auth';
import user from './user';
import assmt from './assmt';
import assmtId from './assmtId';
import companyData from './company-data';
import langId from './langId';
import notificationData from './notification-data';
import randomAttempts from './random-attempts';
import mfaAuth from './mfaAuth';
import mfaHash from './mfaHash';
import revision from './revision';
import userAssmtId from './userAssmtId';
import categorySlug from './categorySlug';
import companyTypeId from './companyTypeId';
import filters from './filters';
import tabs from './tabs';
import adminFilters from './adminFilters';

const rootReducer = combineReducers({
  auth,
  user,
  assmt,
  assmtId,
  companyData,
  langId,
  notificationData,
  randomAttempts,
  mfaAuth,
  mfaHash,
  revision,
  userAssmtId,
  categorySlug,
  companyTypeId,
  filters,
  tabs,
  adminFilters,
});

export default rootReducer;
