import { MFALOGIN, MFALOGOUT } from './types';

const INITIAL_STATE = {};

const mfaAuth = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case MFALOGIN: {
      return {
        ...state,
        mfaToken: payload?.data?.mfa_token,
      };
    }
    case MFALOGOUT: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default mfaAuth;
