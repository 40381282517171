import React from 'react';
import { Tooltip } from 'antd';

const FactorScale = (props) => {
  return (
    <>
      <div className='mb-4'>
        <div className='resillency-factor'>
          <span>
            ({props?.burnoutResult?.section_title.charAt(0)}){' '}
            {props?.burnoutResult?.section_title}
          </span>
          <span
            style={{
              color: props?.burnoutResult?.color_code,
              fontWeight: 'bold',
            }}
          >
            {' '}
            {props?.burnoutResult?.section_percentage} %
          </span>
        </div>
        <div className='factor-bar'>
          <Tooltip
            title={
              props?.burnoutResult?.is_high_score_resilient === true
                ? 'Minimum optimal level'
                : 'Maximum optimal level'
            }
            color='#00a39d'
            overlayStyle={{
              maxWidth: '106px',
              borderRadius: '10px',
            }}
            overlayInnerStyle={{
              fontSize: '12px',
              textAlign: 'center',
            }}
          >
            <div
              style={{
                left: props?.burnoutResult?.optimize_level,
              }}
              className='place'
            >
              <span>
                {props?.burnoutResult?.optimize_level}
              </span>
            </div>
          </Tooltip>

          <div className='progress'>
            <div
              className={
                props?.burnoutResult?.burnout_level ===
                  window.$enums.BurnoutLevel.Red
                  ? 'progress-bar rp-primary-red'
                  : 'progress-bar'
              }
              role='progressbar'
              style={{
                width: `${Math.trunc(
                  props?.burnoutResult.section_percentage
                )}%`,
                backgroundColor: props?.burnoutResult?.color_code,
              }}
              aria-valuenow='41'
              aria-valuemin='0'
              aria-valuemax='100'
            ></div>
          </div>
        </div>
        <div className='scaling-factor'>
          <span
            className={
              props?.burnoutResult?.is_high_score_resilient === false
                ? 'primary-color fw-bold'
                : 'rp-primary-red-color fw-bold'
            }
          >
            Low
          </span>
          <span
            className={
              props?.burnoutResult?.is_high_score_resilient === true
                ? 'primary-color fw-bold'
                : 'rp-primary-red-color fw-bold'
            }
          >
            High
          </span>
        </div>
      </div>
    </>
  );
};

export default FactorScale;
