import React from 'react';
import parse from 'html-react-parser';

const ImpactCard = (props) => {
  return (
    <>
      {props.impactResult?.length
        ? props.impactResult?.map((item, index) => (
            <div
              key={index}
              className={`col-md-6 impact-col ${index > 1 ? 'mt-4' : ''}`}
            >
              <div
                className={`${
                  index === 0 || index === 3
                    ? 'impact-card'
                    : 'impact-card-green'
                }`}
              >
                <div className='d-flex justify-content-between'>
                  <p className='sub-titel'>Impact</p>
                  <span
                    className={`${
                      item?.trend === 'up' ? 'increse' : 'decrease'
                    }`}
                  >
                    <i
                      className={`fa-solid ${
                        item?.trend === 'up' ? 'fa-arrow-up' : 'fa-arrow-down'
                      }`}
                    />
                  </span>
                </div>
                <h6 className='card-heading'>{item?.summary}</h6>
                <div className='icon'>
                  <img
                    src={`${process.env.REACT_APP_API_URL}media/icon/${item?.icon_name}`}
                    alt=''
                  />
                </div>
                <div className='card-info'>
                  {item?.description ? parse(item?.description) : ''}
                </div>
                <div className='asumption-div'>{item?.assumption}</div>
              </div>
            </div>
          ))
        : <h6 className='text-center'>No data found for the {props?.changeText}</h6>}
    </>
  );
};

export default ImpactCard;
