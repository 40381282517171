import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DailyLinksVideos from '../../pages/Dashboard/Components/ResiliencyPlan/Components/DailyLinks/Components/UnAuthenticatedDailyLinks';

const RedirectRoutes = ({ element, ...rest }) => {
  const lastLocation = useLocation();
  const authState = useSelector((state) => state.auth);
  const location = lastLocation.pathname.split('/');

  return (
    <>
      {authState?.isAuthenticated ? (
        location[3] === 'dailylinks' && location[4] ? (
          <Routes>
            <Route path='/*' element={<DailyLinksVideos />} />
          </Routes>
        ) : (
          <Routes>
            <Route path='/*' element={element} />
          </Routes>
        )
      ) : location[3] === 'dailylinks' && location[4] ? (
        <Routes>
          <Route path='/*' element={<DailyLinksVideos />} />
        </Routes>
      ) : (
        <Routes>
          <Route
            path='/*'
            element={
              <Navigate to={`/login?redirectTo=${lastLocation.pathname}`} />
            }
          />
        </Routes>
      )}
    </>
  );
};

export default RedirectRoutes;
