import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

const CompareModal = (props) => {
  const navigate = useNavigate();
  const [eraOptions, setEraOptions] = useState([]);
  const [dropDown1Open, setDropDown1Open] = useState(false);
  const [dropDown2Open, setDropDown2Open] = useState(false);
  const [selectedEra1Tab, setSelectedEra1Tab] = useState();
  const [selectedEra2Tab, setSelectedEra2Tab] = useState();

  useEffect(() => {
    setEraOptions(props?.eraOptions?.data);
    setSelectedEra1Tab(props?.eraOptions?.data[0]);
    setSelectedEra2Tab(props?.eraOptions?.data[1]);
  }, [props?.eraOptions]);

  const handleSelectedEra1 = (data) => {
    setSelectedEra1Tab(data);
    setDropDown1Open(!dropDown1Open);
    if (data?.rp_company_assmt_id >= selectedEra2Tab?.rp_company_assmt_id) {
      setSelectedEra2Tab(
        eraOptions?.find(
          (elem) => elem?.rp_company_assmt_id > data?.rp_company_assmt_id
        )
      );
    }
  };

  const handleSelectedEra2 = (data) => {
    setSelectedEra2Tab(data);
    setDropDown2Open(!dropDown2Open);
  };

  const handleArrow1Click = () => {
    setDropDown1Open(!dropDown1Open);
  };

  const handleArrow2Click = () => {
    setDropDown2Open(!dropDown2Open);
  };

  const handleCompareButton = () => {
    navigate(
      `/dashboard/company-analytics/report/${selectedEra1Tab?.rp_company_assmt_id}/${selectedEra2Tab?.rp_company_assmt_id}`,
      {
        state: {
          selectedEra1Tab: selectedEra1Tab,
          selectedEra2Tab: selectedEra2Tab,
        },
      }
    );
  };

  return (
    <>
      <Modal
        aria-labelledby='contained-modal-title-vcenter'
        centered
        show={props.showModal ? true : false}
        className='modal session-modal'
      >
        <Modal.Body className='px-4 pt-0'>
          <div className='my-4 text-center fs-6 text-dark'>
            Select any two session to compare
          </div>
          <div className='col-12 mb-4 d-flex justify-content-between'>
            <div>
              <p className='mb-3'>Select your first session</p>
              <div className='assessment-tabs-group'>
                <button
                  type='button'
                  className={
                    dropDown1Open
                      ? 'position-relative btn-assment-month prev-mnth month-active'
                      : 'position-relative btn-assment-month prev-mnth month-active'
                  }
                  onClick={handleArrow1Click}
                >
                  <span>{selectedEra1Tab?.display_tab_up}</span>
                  <span>{selectedEra1Tab?.display_tab_down}</span>
                  <div
                    className='position-absolute'
                    style={{ right: '15px', top: '20px' }}
                  >
                    <i
                      className={
                        dropDown1Open
                          ? 'fa-solid fa-angle-down rotate'
                          : 'fa-solid fa-angle-down'
                      }
                      onClick={handleArrow1Click}
                    ></i>
                  </div>

                  <div
                    className={
                      dropDown1Open
                        ? 'session-dropdown-menu fade-down show-drop'
                        : 'session-dropdown-menu fade-down'
                    }
                  >
                    {eraOptions?.map((data, id) => (
                      <div
                        className={
                          data?.rp_company_assmt_id ===
                          selectedEra1Tab?.rp_company_assmt_id
                            ? 'btn-assment-month prev-mnth month-active'
                            : data?.rp_company_assmt_id ===
                              eraOptions[eraOptions?.length - 1]
                                ?.rp_company_assmt_id
                            ? 'btn-assment-month prev-mnth disabled'
                            : 'btn-assment-month prev-mnth'
                        }
                        key={id}
                        onClick={() => handleSelectedEra1(data)}
                      >
                        <span>{data?.display_tab_up}</span>
                        <span>{data?.display_tab_down}</span>
                      </div>
                    ))}
                  </div>
                </button>
              </div>
            </div>
            <div>
              <div className='filter-seperator'></div>
            </div>
            <div>
              <p className='mb-3'>Select your second session</p>
              <div className='assessment-tabs-group'>
                <button
                  type='button'
                  className={
                    dropDown2Open
                      ? 'position-relative btn-assment-month prev-mnth month-active'
                      : 'position-relative btn-assment-month prev-mnth month-active'
                  }
                  onClick={handleArrow2Click}
                >
                  <span>{selectedEra2Tab?.display_tab_up}</span>
                  <span>{selectedEra2Tab?.display_tab_down}</span>
                  <div
                    className='position-absolute'
                    style={{ right: '15px', top: '20px' }}
                  >
                    <i
                      className={
                        dropDown2Open
                          ? 'fa-solid fa-angle-down rotate'
                          : 'fa-solid fa-angle-down'
                      }
                      onClick={handleArrow2Click}
                    ></i>
                  </div>

                  <div
                    className={
                      dropDown2Open
                        ? 'session-dropdown-menu fade-down show-drop'
                        : 'session-dropdown-menu fade-down'
                    }
                  >
                    {eraOptions?.map((data, id) => (
                      <div
                        className={
                          data?.rp_company_assmt_id ===
                          selectedEra2Tab?.rp_company_assmt_id
                            ? data?.rp_company_assmt_id <
                              selectedEra1Tab?.rp_company_assmt_id
                              ? 'btn-assment-month prev-mnth month-active disabled'
                              : 'btn-assment-month prev-mnth month-active'
                            : data?.rp_company_assmt_id <=
                              selectedEra1Tab?.rp_company_assmt_id
                            ? 'btn-assment-month prev-mnth disabled'
                            : 'btn-assment-month prev-mnth'
                        }
                        key={id}
                        onClick={() => handleSelectedEra2(data)}
                      >
                        <span>{data?.display_tab_up}</span>
                        <span>{data?.display_tab_down}</span>
                      </div>
                    ))}
                  </div>
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='col-12 d-flex justify-content-center gap-5'>
            <button
              type='button'
              className='btn btn-rp-secondary'
              onClick={props.handleCloseModal}
            >
              Cancel
            </button>
            <button
              className='btn btn-rp-primary'
              onClick={handleCompareButton}
            >
              Compare
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CompareModal;
