import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import Plan from './Plan';
import Skeleton from 'react-loading-skeleton';
import { useNavigate, useLocation } from 'react-router-dom';

const FoodAndDiet = (props) => {
  const navigate = useNavigate();
  const [showPlan, setShowPlan] = useState(false);
  const [isReadMore, setIsReadMore] = useState(true);
  const [rp_food_diet_plan_id, setrp_food_diet_plan_id] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [readMore, setReadMore] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageData, setImageData] = useState([]);
  const location = useLocation();
  const loc = location.pathname.split('/');

  useEffect(() => {
    getImages(props?.dietImageId?.map((el) => el.map((elem) => elem)).join());
  }, [props?.dietImageId, props?.dietResult]);

  useEffect(() => {
    if (loc[6]) {
      setrp_food_diet_plan_id(loc[6]);
      setShowPlan(true);
    }
  }, [loc]);

  const toggleReadMore = (index) => {
    setIsReadMore(!isReadMore);
    let readMoreArray = readMore;
    if (readMore.length == 0) {
      readMore.push({ 0: false });
      readMore.push({ 1: false });
      readMore[index] = true;
      readMoreArray = readMore;
    }
    if (readMoreArray.length) {
      for (let key in readMoreArray) {
        if (key == index) {
          readMoreArray[index] = !readMoreArray[index];
        }
      }
      setReadMore(readMoreArray);
    }
  };

  function checkReadMore(index) {
    let readMoreArray = readMore;
    if (readMoreArray.length) {
      return readMoreArray[index];
    }
    return true;
  }

  const handlePlanPage = (id, title) => {
    setReadMore([]);
    setShowPlan(!showPlan);
    setTitle(title);
    props.showPlanPage(!showPlan);
    setrp_food_diet_plan_id(id);
    setIsLoading(true);
    navigate(`/dashboard/resiliency-plan/lifestyle/food-and-diet/plan/${id}`);
  };

  const getImages = async (id, key) => {
    try {
      if (id) {
        setLoading(true);
        const result = await window.$http.getWithHeaders(
          `media/images/${id}/food_diet`
        );
        if (result.code === window.$constants.STATUS.OK) {
          setImageData(result?.data);
          if (result?.data?.map((el) => el?.id)) {
            setLoading(false);
          }
        } else {
          console.log(result);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!loc[6]) {
      setShowPlan(props?.showPlan);
    }
  }, [props?.showPlan]);

  useEffect(() => {
    let readMoreArray = [];
    for (var key in props?.dietResult) {
      readMoreArray.push({ [key]: true });
    }
    setReadMore(readMoreArray);
  }, []);

  const handleListVisible = (value) => {
    setShowPlan(value);
    props.showPlanPage(false);
  };

  return (
    <>
      {!showPlan ? (
        <>
          {props?.dietResult?.map((data, id) => (
            <div className='row' key={id}>
              <div className='sries_title mb-4'>
                <h1 className='card-heading-sec-dark fs-3'>{data?.title}</h1>
                <p>
                  {checkReadMore(id)
                    ? (parse(data?.description.slice(0, 441)+ '...') )
                    : parse(data?.description)}
                  <span
                    onClick={() => toggleReadMore(id)}
                    className='read-or-hide'
                  >
                    {checkReadMore(id) ? (
                      parse(data?.description).length < 441 ? (
                        <span className='primary-color cursor-p moreless-button'>
                          Read more
                        </span>
                      ) : (
                        ''
                      )
                    ) : (
                      <span className='primary-color cursor-p moreless-button'>
                        Read less
                      </span>
                    )}
                  </span>
                </p>
                {/* ... */}
              </div>

              {data?.plans &&
                data?.plans?.map((el, id) => (
                  <div
                    data-tag='plan1'
                    className='col-md-4 mb-5 diet_plan'
                    key={id}
                  >
                    <div
                      className='video-card cursor-p position-relative'
                      onClick={() =>
                        handlePlanPage(el?.rp_food_diet_plan_id, data?.title)
                      }
                    >
                      {loading ? (
                        <Skeleton width='100%' height='230px' />
                      ) : (
                        imageData &&
                        imageData?.map((elem) =>
                          el?.image_id === elem?.id ? (
                            <>
                              {!elem?.image_data ? (
                                <Skeleton width='100%' height='230px' />
                              ) : (
                                <img src={elem?.image_data} alt=''></img>
                              )}
                            </>
                          ) : (
                            ''
                          )
                        )
                      )}
                    </div>

                    <div className='video-card-discription'>
                      <div className='music-titel'>
                        <h6>{el?.title}</h6>
                        <p>{el?.description}</p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          ))}
        </>
      ) : (
        ''
      )}
      {showPlan && (
        <Plan
          planId={rp_food_diet_plan_id}
          showPlan={showPlan}
          handleListVisible={handleListVisible}
          handleShowList={props.handleShowList}
          isLoading={isLoading}
          title={title}
          dietResult={props?.dietResult}
        />
      )}
    </>
  );
};

export default FoodAndDiet;
