import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { saveCompanyTypeId } from '../../store/companyTypeId/actions';

export default function CompanyRadioFilter(props) {
  const dispatch = useDispatch();
  const companyTypeId = useSelector((state) => state?.companyTypeId?.payload);
  const [selectedOption, setSelectedOption] = useState();

  useEffect(() => {
    if (!isEmpty(companyTypeId)) {
      setSelectedOption(companyTypeId);
      dispatch(saveCompanyTypeId(companyTypeId));
    } else {
      setSelectedOption(props?.options ? props?.options[0] : '');
      dispatch(saveCompanyTypeId(props?.options ? props?.options[0] : ''));
    }
  }, [props?.options]);

  const handleChange = (value) => {
    setSelectedOption(value);
    dispatch(saveCompanyTypeId(value));
  };

  return (
    <div className='App'>
      <Select
        value={selectedOption}
        onChange={handleChange}
        options={props?.options}
        className='company-select'
        classNamePrefix='company'
        styles={{
          dropdownIndicator: (provided, state) => ({
            ...provided,
            transform: state?.selectProps?.menuIsOpen && 'rotate(180deg)',
          }),
        }}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: '#fff',
            primary: '#fff',
            primary50: '#fff',
            neutral10: '#fff',
            neutral30: '#fff',
            neutral20: '#fff',
            dangerLight: '#fff',
          },
        })}
        placeholder='Select company'
      />
    </div>
  );
}
