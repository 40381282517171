import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../layout/Components/Header';
import Footer from '../../layout/Components/Footer';
import '../../config';
import ProfileForm from '../../common/Form';
import { useSelector } from 'react-redux';

const OnBoarding = () => {
  const navigate = useNavigate();
  const [resultData, setResultData] = useState({});
  const [navigateTo, setNavigateTo] = useState();
  const [contentData, setContentData] = useState('');
  const userState = useSelector((state) => state?.user);

  useEffect(() => {
    if (userState?.onboarding_step === 1) {
      navigate('/phone-verification');
    } else {
      getProfileData();
    }
  }, []);

  const getProfileData = async () => {
    try {
      const result = await window.$http.getWithHeaders('v2/my_profile');
      setContentData(result);
      if (result.code === window.$constants.STATUS.OK) {
        if (result?.data?.is_onboard_step2_required) {
          setNavigateTo('/phone-verification');
        } else {
          if (
            result?.data?.landing_page === 0 ||
            result?.data?.landing_page === 1
          ) {
            setNavigateTo('/dashboard');
          } else if (result?.data?.landing_page === 2) {
            setNavigateTo('/dashboard/resiliency-plan');
          } else {
            setNavigateTo('/dashboard/assessments/result/0');
          }
        }
        setResultData(result?.data);
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className='bg-gradiant-light'>
        <Header />
        <main>
          <section>
            <div className='container'>
              <div className='row'>
                <div className='personal-info onbording p-3 p-lg-5'>
                  <h3 className='mb-4'>Personal Profile</h3>
                  <p>
                    Your personal profile will help us create a comprehensive
                    resiliency plan for you
                  </p>
                  {resultData ? (
                    <ProfileForm
                      profileData={resultData}
                      navigateTo={navigateTo}
                      value='onBoarding'
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </section>
        </main>
        <Footer version={contentData?.metadata?.web_curr_version} />
      </div>
    </>
  );
};

export default OnBoarding;
