import React, { useEffect, useState } from 'react';
import { Tooltip } from 'antd';

const QuestionAnalytics = (props) => {
  const [questionData, setQuestionData] = useState([]);
  const [selectedQuestionId, setSelectedQuestionId] = useState();
  const [selectedLegend, setSelectedLegend] = useState([]);
  const [scroll, setScroll] = useState(0);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState();

  useEffect(() => {
    if (props?.data) {
      setQuestionData(props?.data);
      setSelectedQuestionId(props?.data?.questions[0]?.id);
      setSelectedQuestion(props?.data?.questions[0]);
      setSelectedLegend([]);
    }
  }, [props?.data, props?.manager]);

  useEffect(() => {
    if (selectedQuestionId) {
      props?.getSelectedQuestion(selectedQuestionId);
    }
  }, [selectedQuestionId]);

  useEffect(() => {
    const myDiv = document.querySelector('#scroller');
    myDiv.addEventListener('scroll', (event) => {
      setScroll(myDiv.scrollTop);
    });
  }, [questionData]);

  const handleSelectedLegends = (id) => {
    let arr = [];
    const index = selectedLegend?.findIndex((el) => el === id);
    if (index === -1) {
      arr?.push(id);
    } else {
      selectedLegend?.splice(index, 1);
    }
    return setSelectedLegend(selectedLegend?.concat(arr));
  };

  const handleSelectedQuestion = (id, data) => {
    setSelectedQuestionId(id);
    setSelectedQuestion(data);
    setDropDownOpen(!dropDownOpen);
  };

  const setTitle = (id, legends, el) => {
    const legendObject = legends?.find((el) => el?.id === id);
    return `${legendObject?.label},  ${el?.percentage}%`;
  };

  const handleArrowClick = () => {
    setDropDownOpen(!dropDownOpen);
  };

  return (
    <>
      <div
        className='question-analytics mb-5'
        id='scroller'
        style={{ maxHeight: '200px' }}
      >
        <div
          className={
            dropDownOpen ? 'select-container active' : 'select-container'
          }
          onClick={handleArrowClick}
        >
          <div className='select'>
            <div className='question-input'>
              <div className='questions-graph'>
                <div className='col-6'>
                  {questionData?.questions?.length
                    ? selectedQuestion?.title
                    : 'No Data'}
                </div>
                <div className='col-6'>
                  <div className='progress'>
                    {selectedQuestion?.progress_bar?.map((el, id) => (
                      <Tooltip
                        title={() =>
                          setTitle(el?.id, questionData?.legends, el)
                        }
                        overlayStyle={{
                          maxWidth: '106px',
                          borderRadius: '10px',
                        }}
                        overlayInnerStyle={{
                          fontSize: '12px',
                          textAlign: 'center',
                        }}
                      >
                        <div
                          key={id}
                          className={`progress-bar`}
                          role='progressbar'
                          aria-label={`${el?.percentage}%`}
                          id={el?.id}
                          style={{
                            width: `${
                              selectedLegend?.some((value) => value === el?.id)
                                ? '0%'
                                : `${el?.percentage}%`
                            } `,
                            backgroundColor: `${el?.colour}`,
                          }}
                          aria-valuemin='0'
                          aria-valuemax='100'
                        >
                          {el?.percentage > 10 ? `${el?.percentage}%` : ''}
                        </div>
                      </Tooltip>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ cursor: 'pointer', padding: '0 15px' }}>
              <i
                className={
                  dropDownOpen
                    ? 'fas fa-chevron-down jq_drop__t rotate'
                    : 'fas fa-chevron-down jq_drop__t'
                }
                aria-hidden='true'
                onClick={handleArrowClick}
              ></i>
            </div>
          </div>
          <div className='option-container'>
            {questionData?.questions?.map((data, id) => (
              <div className='option'>
                <div
                  className={`questions-graph ${
                    data?.id === selectedQuestionId ? 'selected' : ''
                  } `}
                  key={id}
                  onClick={() => handleSelectedQuestion(data?.id, data)}
                >
                  <div className='col-6'>{data?.title}</div>
                  <div className='col-6'>
                    <div className='progress'>
                      {data?.progress_bar?.map((el, id) => (
                        <Tooltip
                          title={() =>
                            setTitle(el?.id, questionData?.legends, el)
                          }
                          overlayStyle={{
                            maxWidth: '106px',
                            borderRadius: '10px',
                          }}
                          overlayInnerStyle={{
                            fontSize: '12px',
                            textAlign: 'center',
                          }}
                        >
                          <div
                            key={id}
                            className={`progress-bar`}
                            role='progressbar'
                            aria-label={`${el?.percentage}%`}
                            id={el?.id}
                            style={{
                              width: `${
                                selectedLegend?.some(
                                  (value) => value === el?.id
                                )
                                  ? '0%'
                                  : `${el?.percentage}%`
                              } `,
                              backgroundColor: `${el?.colour}`,
                            }}
                            aria-valuemin='0'
                            aria-valuemax='100'
                          >
                            {el?.percentage > 10 ? `${el?.percentage}%` : ''}
                          </div>
                        </Tooltip>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* <div className='legends-container mb-5'>
        <div className='col-8 m-auto d-flex justify-content-around'>
          {questionData?.legends?.map((el, id) => (
            <div
              className={`legends-box ${
                selectedLegend?.find((data) => data === el?.id) ? 'slected' : ''
              }`}
              key={id}
              id={el?.id}
              onClick={() => handleSelectedLegends(el?.id)}
            >
              <span
                className='legends'
                style={
                  selectedLegend?.find((data) => data === el?.id)
                    ? { backgroundColor: '#ccc' }
                    : { backgroundColor: `${el?.color_code}` }
                }
              ></span>
              <span className='legends-title'>{el?.label}</span>
            </div>
          ))}
        </div>
      </div> */}
    </>
  );
};

export default QuestionAnalytics;
