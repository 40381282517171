import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AuthenticatedDailyLinks from './Components/AuthenticatedDailyLinks';
import UnAuthenticatedDailyLinks from './Components/UnAuthenticatedDailyLinks';

const Dailylinks = (props) => {
  const [authValue, setAuthValue] = useState(false);
  const [resultData, setResultData] = useState('');
  const userState = useSelector((state) => state?.user);

  useEffect(() => {
    const handleUserAuthentication = async () => {
      try {
        const result = await window.$http.getWithHeaders('validate/token');
        setResultData(result);
        if (result.code === window.$constants.STATUS.OK) {
          setAuthValue(true);
        }
      } catch (error) {
        console.log(error);
      }
    };

    handleUserAuthentication();
  }, []);

  return (
    <>
      {resultData ? (
        authValue && userState?.visible_header_tabs?.includes('plan') ? (
          <AuthenticatedDailyLinks
            isClicked={props.isClicked}
            handleDailyLinkVideos={props.handleDailyLinkVideos}
            id={props.id}
          />
        ) : (
          <UnAuthenticatedDailyLinks />
        )
      ) : (
        ''
      )}
    </>
  );
};

export default Dailylinks;
