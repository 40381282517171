import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';

const ZoomModal = (props) => {
  const [productImageId, setProductImageId] = useState('');
  const [index, setIndex] = useState({
    value: '',
    id: '',
  });

  useEffect(() => {
    if (props?.productImageId !== 0) {
      setProductImageId(props?.productImageId);
      setIndex({
        value: props?.data?.findIndex(
          (el) => el?.rp_supplement_image_id === productImageId
        ),
      });
    } else {
      if (props?.images) {
        setProductImageId(props?.images[0]?.rp_supplement_image_id);
      }

      if (props.data) {
        setIndex({
          value: props?.data?.findIndex(
            (el) => el?.rp_supplement_image_id === productImageId
          ),
        });
      }
    }
  }, [props, productImageId]);

  useEffect(() => {
    if (props?.data !== undefined) {
      if (index.id !== undefined) {
        setProductImageId(props?.data[index.id]?.rp_supplement_image_id);
      }
    }
  }, [index.id, productImageId, props.data]);

  const handleNext = () => {
    if (index !== '') {
      setIndex({ ...index, id: index.value + 1 });
    }
  };

  const handlePrevious = () => {
    if (index !== '') {
      setIndex({ ...index, id: index.value - 1 });
    }
  };

  return (
    <Modal
      aria-labelledby='contained-modal-title-vcenter'
      centered
      show={props?.isModalOpen}
      dialogClassName='modal-width'
    >
      <Modal.Header>
        <button
          type='button'
          className='btn-close'
          data-bs-dismiss='modal'
          aria-label='Close'
          onClick={props?.handleCloseModal}
        ></button>
      </Modal.Header>
      <Modal.Body>
        <div
          id='carouselExampleControls'
          className='carousel slide'
          data-bs-interval='false'
          data-bs-ride='carousel'
        >
          <div className='carousel-inner'>
            <div className='carousel-item active'>
              <SliderCom
                productImageId={productImageId}
                isModalOpen={props?.isModalOpen}
              />
            </div>
          </div>

          {props?.data?.find(
            (el) => el?.rp_supplement_image_id === productImageId - 1
          ) ? (
            <button
              className='carousel-control-prev'
              type='button'
              data-bs-target='#carouselExampleControls'
              data-bs-slide='prev'
              onClick={handlePrevious}
            >
              <span
                className='carousel-control-prev-icon'
                aria-hidden='true'
              ></span>
              <span className='visually-hidden'>Previous</span>
            </button>
          ) : (
            ''
          )}

          {props?.data?.find(
            (el) => el?.rp_supplement_image_id === productImageId + 1
          ) ? (
            <button
              className='carousel-control-next'
              type='button'
              data-bs-target='#carouselExampleControls'
              data-bs-slide='next'
              onClick={handleNext}
            >
              <span
                className='carousel-control-next-icon'
                aria-hidden='true'
              ></span>
              <span className='visually-hidden'>Next</span>
            </button>
          ) : (
            ''
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ZoomModal;

const SliderCom = (props) => {
  return (
    <>
      {props?.isModalOpen && props?.productImageId !== undefined ? (
        <img
          src={`${process.env.REACT_APP_API_URL}media/supplement/image/${props?.productImageId}`}
          alt=''
        />
      ) : (
        ''
      )}
    </>
  );
};
