import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Input } from 'semantic-ui-react';
import _ from 'lodash';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

const TreeDropdown = (props) => {
  const [filterValues, setFilterValues] = useState({});
  const ref = useRef();
  const [nodesData, setNodesData] = useState([]);
  const [currState, setCurrState] = useState({
    checked: [],
    expanded: [],
    keyword: '',
  });
  const [showDropdown, setShowDropdown] = useState();
  const [searchTerm, setSearchTerm] = useState();
  const location = useLocation()?.pathname?.split('/');
  const filters = useSelector((state) => state?.filters);
  const adminFilters = useSelector((state) => state?.adminFilters);

  const { checked, expanded, keyword } = currState;

  useEffect(() => {
    if (
      filters?.analytics?.[`${location[3]}`] &&
      filters?.analytics?.[`${location[3]}`]?.[`${props?.optionName}`]?.[
        `${props?.optionName}`
      ]?.length
    ) {
      setCurrState({
        ...currState,
        checked:
          filters?.analytics?.[`${location[3]}`]?.[`${props?.optionName}`]?.[
            `${props?.optionName}`
          ],
      });
    }
    //  else {
    //   setCurrState({ ...currState, checked: [] });
    // }
    if (
      adminFilters?.admin?.[`${location[2]}`] &&
      adminFilters?.admin?.[`${location[2]}`]?.[`${props?.optionName}`]?.[
        `${props?.optionName}`
      ]?.length
    ) {
      setCurrState({
        ...currState,
        checked:
          adminFilters?.admin?.[`${location[2]}`]?.[`${props?.optionName}`]?.[
            `${props?.optionName}`
          ],
      });
    }
  }, []);

  useEffect(() => {
    if (checked.length) {
      let filterName = props?.optionName;
      let optFilterValue = '';
      let optFilterValueArray = filterValues;
      optFilterValue = checked;
      optFilterValueArray[filterName] = optFilterValue;
      setFilterValues(optFilterValueArray);
      props?.filterData(optFilterValueArray);
      if (
        checked[0] === 'select-all' ||
        checked[checked?.length - 1] === 'select-all'
      ) {
        props?.handleCount(props?.optionName, checked?.length - 1);
      } else {
        props?.handleCount(props?.optionName, checked?.length);
      }
      if (location[3] === 'corporate') {
        props?.apiCallsWithoutLevelId();
      }
      return props?.apiCalls();
    }
  }, [checked?.length]);

  useEffect(() => {
    const arr = [{ label: 'All', value: 'select-all' }];
    setNodesData(arr.concat(props?.nodes));
  }, [props?.nodes?.length]);

  useEffect(() => {
    setShowDropdown(props?.showDropdown ? true : false);
  }, [props?.showDropdown]);

  useEffect(() => {
    setSearchTerm('');
    searchedNodes?.forEach(function iter(a) {
      if (checked?.includes(a.value)) {
        a.className = 'nonActiveFilter';
      }
      Array.isArray(a.children) && a.children.forEach(iter);
    });
    props?.clearData('value');
    if (props?.clearValue) {
      setCurrState({ checked: [], expanded: [], keyword: '' });
      let filterName = props?.optionName;
      let optFilterValue = '';
      let optFilterValueArray = filterValues;
      optFilterValue = [];
      optFilterValueArray[filterName] = optFilterValue;
      setFilterValues(optFilterValueArray);
      props?.filterData(optFilterValueArray);
      props?.handleCount(props?.optionName, 0);
      if (location[3] === 'corporate') {
        props?.apiCallsWithoutLevelId();
      }
      return props?.apiCalls();
    }
  }, [props?.clearValue]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [ref?.current]);

  const onSearchInputChange = (event, data, searchedNodes) => {
    setSearchTerm(data?.value);
    setCurrState((prevState) => {
      if (prevState?.keyword?.trim() && !data?.value?.trim()) {
        return {
          ...currState,
          expanded: [],
          keyword: data?.value,
        };
      }
      return {
        ...currState,
        expanded: getAllValuesFromNodes(searchedNodes, true),
        keyword: data?.value,
      };
    });
  };

  const getHighlightText = (text, keyword) => {
    const key = keyword.charAt(0).toUpperCase() + keyword.slice(1);
    const startIndex = text?.indexOf(key);
    return startIndex !== -1 ? (
      <span>
        {text.substring(0, startIndex)}
        <span style={{ color: '#00a39d' }}>
          {text.substring(startIndex, startIndex + key?.length)}
        </span>
        {text.substring(startIndex + key?.length)}
      </span>
    ) : (
      <span>{text}</span>
    );
  };

  const keywordFilter = (nodes, keyword) => {
    let newNodes = [];
    for (let n of nodes) {
      if (n.children) {
        const nextNodes = keywordFilter(n.children, keyword);
        if (nextNodes.length > 0) {
          n.children = nextNodes;
        } else if (n.label.toLowerCase().includes(keyword?.toLowerCase())) {
          n.children = nextNodes.length > 0 ? nextNodes : [];
        }
        if (
          nextNodes.length > 0 ||
          n.label.toLowerCase().includes(keyword?.toLowerCase())
        ) {
          n.label = getHighlightText(n.label, keyword);
          newNodes.push(n);
        }
      } else {
        if (n.label.toLowerCase().includes(keyword?.toLowerCase())) {
          n.label = getHighlightText(n.label, keyword);
          newNodes.push(n);
        }
      }
    }
    return newNodes;
  };

  const getAllValuesFromNodes = (nodes, firstLevel) => {
    if (firstLevel) {
      const values = [];
      for (let n of nodes) {
        values.push(n.value);
        if (n.children) {
          values.push(...getAllValuesFromNodes(n.children, true));
        }
      }
      return values;
    } else {
      const values = [];
      for (let n of nodes) {
        values.push(n.value);
        if (n.children) {
          values.push(...getAllValuesFromNodes(n.children, true));
        }
      }
      return values;
    }
  };

  let searchedNodes = keyword?.trim()
    ? keywordFilter(_.cloneDeep(nodesData), keyword)
    : nodesData;

  useEffect(() => {
    if (!isEmpty(checked)) {
      searchedNodes?.forEach(function iter(a) {
        if (checked?.includes(a.value)) {
          a.className = 'activeFilter';
        }
        Array.isArray(a.children) && a.children.forEach(iter);
      });
    }
  }, [checked?.length]);

  const find = (array, id) => {
    var result;
    array.some(
      (o) => (result = o.value === id ? o : find(o.children || [], id))
    );
    return result;
  };

  const handleClear = (value) => {
    if (location[3] === 'corporate') {
      props?.setFactorTabIndex(0);
    }
    if (checked?.some((el) => el === 'select-all')) {
      const arr = ['select-all', value];
      const id = checked.findIndex((el) => el === value);
      checked.splice(id, 1);
      const index = checked?.findIndex((el) => el === 'select-all');
      checked.splice(index, 1);
      searchedNodes?.forEach(function iter(a) {
        if (arr?.includes(a.value)) {
          a.className = 'nonActiveFilter';
        }
        Array.isArray(a.children) && a.children.forEach(iter);
      });

      setCurrState({ ...currState, checked: checked });
    } else {
      const id = checked.findIndex((el) => el === value);
      checked?.splice(id, 1);
      searchedNodes?.forEach(function iter(a) {
        if (value === a?.value) {
          a.className = 'nonActiveFilter';
        }
        Array.isArray(a.children) && a.children.forEach(iter);
      });
      setCurrState({ ...currState, checked: checked });
    }
    props?.handleCount(props?.optionName, checked?.length);
    if (location[3] === 'corporate') {
      props?.apiCallsWithoutLevelId();
    }
    return props?.apiCalls();
  };

  const handleClick = (event) => {
    props?.handleCountLength();
    if (event?.value === 'select-all') {
      for (let i = 0; i < props?.nodes?.length; i++) {
        if (checked?.some((el) => el === event?.value)) {
          if (!checked.some((el) => el === props?.nodes[i]?.value)) {
            checked.push(props?.nodes[i]?.value);
            setCurrState({ ...currState, checked: checked });
          }
        } else {
          if (checked.some((el) => el === props?.nodes[i]?.value)) {
            checked.push(event?.value);
            setCurrState({ ...currState, checked: checked });
          } else {
            checked?.push(event?.value, props?.nodes[i]?.value);
            setCurrState({ ...currState, checked: checked });
          }
        }
        if (props?.nodes[i]?.children?.length) {
          handleChildren(props?.nodes[i].children);
        }
      }
    } else if (!checked?.some((el) => el === event?.value)) {
      if (checked?.length + 1 === props?.count) {
        checked?.push(event?.value);
        setCurrState({ ...currState, checked: checked });
        checked?.push('select-all');
        setCurrState({ ...currState, checked: checked });
      } else {
        checked?.push(event?.value);
        setCurrState({
          ...currState,
          checked: checked,
        });
      }
      if (event?.children?.length) {
        handleChildren(event?.children);
      }
    }
    // return props?.apiCalls();
  };

  const handleChildren = (child) => {
    for (let i = 0; i < child?.length; i++) {
      if (!checked.some((el) => el === child[i]?.value)) {
        checked?.push(child[i]?.value);
        setCurrState({
          ...currState,
          checked: [...new Set(checked)],
        });
      }
      if (child[i]?.children?.length) {
        handleChildren(child[i]?.children);
      }
    }
  };

  const onExpand = (expanded) => {
    setCurrState({ ...currState, expanded });
  };

  const handleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <>
      {nodesData?.length ? (
        <div ref={ref}>
          <div
            style={{
              backgroundColor: '#f8f8f8',
              borderRadius: '8px',
            }}
          >
            <div
              style={{
                marginBottom: '15px',
                padding: `${checked?.length ? '10px 8px' : ''}`,
              }}
            >
              {checked?.map((el) => find(nodesData, el))
                ? checked
                    ?.map((el) => find(nodesData, el))
                    ?.map((elm, id) =>
                      elm?.value !== 'select-all' ? (
                        <span
                          className='badge mb-2 position-relative badge-2 cursor-p'
                          key={id}
                          style={{
                            padding: '10px',
                            borderRadius: '50px',
                            marginRight: '5px',
                            color: '#42526E',
                            borderColor: '#42526E',
                          }}
                        >
                          {elm?.label}{' '}
                          <button
                            className='label-clear'
                            onClick={() => handleClear(elm?.value)}
                          >
                            x
                          </button>{' '}
                        </span>
                      ) : (
                        ''
                      )
                    )
                : ''}
            </div>
            <div
              className=' d-flex align-items-center justify-content-between cursor-p'
              style={{
                padding: '8px 10px',
                border: `${showDropdown ? '1px solid #00a39d' : 'none'}`,
                borderRadius: '5px',
              }}
            >
              <Input
                style={{
                  marginRight: '0',
                  border: 'none',
                  backgroundColor: '#f8f8f8',
                }}
                className='location-input-checkbox'
                placeholder='Select...'
                onChange={(event, data) => {
                  onSearchInputChange(event, data, searchedNodes);
                }}
                value={searchTerm}
                onClick={() => setShowDropdown(true)}
              />
              <i
                onClick={handleDropdown}
                id='web-toggler'
                className={`fas cursor-p  ${
                  showDropdown ? 'fa-chevron-up' : 'fa-chevron-down'
                }`}
                style={{ fontSize: '14px' }}
              ></i>
            </div>
          </div>
          {showDropdown ? (
            <div className='scrollableTree'>
              <CheckboxTree
                nodes={searchedNodes}
                checkModel='all'
                expanded={expanded}
                iconsClass='fa5'
                onExpand={onExpand}
                showNodeIcon={false}
                onClick={(event) => handleClick(event)}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default TreeDropdown;
