import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import parse from 'html-react-parser';
import LoadingSpinner from '../../../../../../common/LoadingSpinner';

const WorkshopModal = (props) => {
  const [workshopModalData, setWorkshopModalData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getWorkshopModalData();
  }, []);

  const getWorkshopModalData = async () => {
    setIsLoading(true);
    try {
      const result = await window.$http.getWithHeaders(
        `workshop-event/${props?.workshopId}`
      );
      if (result.code === window.$constants.STATUS.OK) {
        setIsLoading(false);
        setWorkshopModalData(result?.data);
      } else {
        setIsLoading(false);
        console.log('Something went wrong!');
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        aria-labelledby='contained-modal-title-vcenter'
        centered
        size='lg'
        show={props.showModal ? true : false}
        className={props.showModal ? 'modal fade show' : 'modal fade'}
        dialogClassName='rp-modal'
      >
        <div className='rp-modal-header'>
          <h1 className='modal-title' id='exampleModalLabel'>
            {workshopModalData?.title}
          </h1>
          <button
            type='button'
            className='btn-close'
            data-bs-dismiss='modal'
            aria-label='Close'
            onClick={props.handleCloseModal}
          ></button>
        </div>
        <Modal.Body>
          <ul className='nav nav-pills mb-3' id='pills-tab' role='tablist'>
            <li className='nav-item' role='presentation'>
              <button
                className='nav-link active'
                id='pills-home-tab'
                data-bs-toggle='pill'
                data-bs-target='#pills-home'
                type='button'
                role='tab'
                aria-controls='pills-home'
                aria-selected='true'
              >
                Info
              </button>
            </li>
            <li className='nav-item' role='presentation'>
              <button
                className='nav-link'
                id='pills-profile-tab'
                data-bs-toggle='pill'
                data-bs-target='#pills-profile'
                type='button'
                role='tab'
                aria-controls='pills-profile'
                aria-selected='false'
              >
                NPS Score
              </button>
            </li>
          </ul>
          <div className='tab-content' id='pills-tabContent'>
            {!isLoading ? (
              <>
                <div
                  className='tab-pane fade show active'
                  id='pills-home'
                  role='tabpanel'
                  aria-labelledby='pills-home-tab'
                  tabindex='0'
                >
                  <div className='event-instructor-info'>
                    <div className='event-date'>
                      <span>{workshopModalData?.info?.date}</span>
                      <span className='separator'></span>
                      <span>{workshopModalData?.info?.time}</span>
                    </div>
                    <div className='event-mode'>
                      {workshopModalData?.info?.venue}
                    </div>
                    <div className='event-description'>
                      {workshopModalData?.info?.description}
                    </div>
                    <div className='instructor-container'>
                      {workshopModalData?.info?.presenters?.map((el) => (
                        <div className='instructor-info'>
                          <div className='instructor'>
                            <img
                              src={`${process.env.REACT_APP_API_URL}media/content/v2/${el?.image_id}/thumb`}
                              alt='instructor-pic'
                            />
                          </div>
                          <div className='instructor-description'>
                            <div className='name'>{el?.name}</div>
                            {el?.description}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div
                  className='tab-pane fade'
                  id='pills-profile'
                  role='tabpanel'
                  aria-labelledby='pills-profile-tab'
                  tabindex='0'
                >
                  {workshopModalData?.nps?.questions?.map((el, id) => (
                    <div className='event-feedback-stats'>
                      <div className='feedback-question'>
                        {`${id + 1}.  ${el?.text}`}
                      </div>
                      <div className='stats-container'>
                        <div className='d-flex justify-content-between align-items-center mb-4'>
                          <div className='nps-score-pills'>
                            <span>
                              NPS Score: <b>{el?.score}%</b>
                            </span>
                          </div>

                          <div className='nps-detractors-pills'>
                            <span>
                              Total Detractors: <b>{el?.detractors}%</b>
                            </span>
                          </div>

                          <div className='nps-promoters-pills'>
                            <span>
                              Total Promoters: <b>{el?.promoters}%</b>
                            </span>
                          </div>
                        </div>
                        <div className='nps-scale'>
                          <div className='NPS-scale mb-3'>NPS Scale</div>
                          <div className='nps-progress-bar'>
                            <div
                              style={{ left: `calc(${el?.score / 2}% + 50%)` }}
                              className='nps-score-indicator'
                            >
                              <span style={{ color: '#22AA58' }}>
                                {el?.score}
                              </span>
                            </div>
                            <div className='progress'>
                              <div className='progress-bar improvement_bar'></div>
                              <div className='progress-bar good_bar'>0</div>
                              <div className='progress-bar great_bar'>30</div>
                              <div className='progress-bar excellent_bar'>
                                70
                              </div>
                            </div>
                          </div>
                          <div className='nps-indicator-container'>
                            <div className='nps-indicators'>
                              <div className='nps-indicator first-indi'>
                                Needs Improvement
                              </div>
                              <div className='nps-indicator second-indi'>
                                Good
                              </div>
                              <div className='nps-indicator third-indi'>
                                Great
                              </div>
                              <div className='nps-indicator fourth-indi'>
                                Excellent
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <div className='centered' style={{ marginLeft: '1rem' }}>
                <LoadingSpinner />
              </div>
            )}
          </div>
        </Modal.Body>

        {/* <Modal.Footer className='justify-content-center'>
          <div className='modal-footer justify-content-center'>
            <button type='button' className='btn  btn-rp-secondary'>
              Download<i className='fa-solid fa-download ms-3'></i>
            </button>
          </div>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default WorkshopModal;
