import React from 'react';
// import ReactFC from 'react-fusioncharts';
// import FusionCharts from 'fusioncharts';
// import msline from 'fusioncharts/fusioncharts.charts';
// import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
// ReactFC.fcRoot(FusionCharts, msline, FusionTheme);
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from 'chart.js';
ChartJS.register(LineElement, CategoryScale, LinearScale, Tooltip, Legend);

const LineChart = (props) => {
  const chartData = {
    labels: props.category.map((item) => item.label),
    datasets: [
      {
        label: '',
        data: props.dataset1.map((item) => item.value),
        borderColor: '#00a39d',
        fill: false,
        tension: 0.1,
        pointStyle: 'circle',
        pointRadius: 6,
      },
    ],
  };
  const VerticalLinePlugin = {
    id: 'verticalLine',
    afterDraw: (chart) => {
      if (chart.tooltip._active && chart.tooltip._active.length) {
        const ctx = chart.ctx;
        const activePoint = chart.tooltip._active[0];
        const x = activePoint.element.x;
        const topY = chart.scales.y.top;
        const bottomY = chart.scales.y.bottom;

        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x, topY);
        ctx.lineTo(x, bottomY);
        ctx.lineWidth = 1;
        ctx.strokeStyle = 'rgba(0, 0, 0, 0.5)';
        ctx.stroke();
        ctx.restore();
      }
    },
  };

  const chartOptions = {
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        backgroundColor: '#fff',
        titleColor: '#42526e',
        bodyColor: '#42526e',
        borderColor: '#bbbbbb',
        borderWidth: 1,
        callbacks: {
          label: function (tooltipItem) {
            let dataPoint = Number(tooltipItem.raw);
            return `${dataPoint.toFixed(2)}%`;
          },
        },
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
        },
        grid: {
          display: false,
        },
      },
      y: {
        title: {
          display: true,
          // text: 'Low                                                                                         High',
        },
        ticks: {
          stepSize: 20,
          display: false,
          callback: function (value) {
            return value + '%';
          },
          beginAtZero: true,
        },
        max: 100,
      },
    },
  };

  return (
    <Line
      data={chartData}
      options={chartOptions}
      width={500}
      height={300}
      plugins={[VerticalLinePlugin]}
    />
  );
};



 export default LineChart;
