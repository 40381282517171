import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RpLogo from '../../../assets/images/rp-logo.png';

const Maintenance = () => {
  const navigate = useNavigate();
  const [result, setResult] = useState();

  useEffect(() => {
    handleLogin();
  }, []);

  const handleLogin = async () => {
    try {
      const result = await window.$http.getWithoutHeaders('login');

      if (result.code === window.$constants.STATUS.OK) {
        return navigate('/');
      } else if (result.code === window.$constants.STATUS.UNDER_MAINTAINENCE) {
        setResult(result);
      }
    } catch (error) {
      setResult(error);
    }
  };

  return (
    <div className='container p-5'>
      <style>{`
         ::selection {
            color: #fff;
            background: #00a39d;
         }
   
         .box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-height: 85vh;
            background: #fff;
            border-radius: 12px;
            box-shadow: 5px 10px 21px rgba(0, 0, 0, 0.17);
            position: relative;
         }
   
         .spin-one i,
         .spin-three i {
            font-size: 80px;
            color: #e02726;
         }
   
         .spin-one i {
            margin-top: -10px;
            margin-right: 8px;
         }
   
         .spin-three i {
            margin-top: -50px;
            margin-left: -10px;
         }
   
         .spin-two i {
            font-size: 100px;
            color: #00a39d;
         }
   
         .animation {
            display: flex;
            justify-content: center;
            margin-bottom: 5%;
         }
   
         .card-heading-sec-dark {
            font-size: 32px;
            line-height: 36px;
            font-weight: 600;
            letter-spacing: 0.3px;
            color: #42526e;
            margin-bottom: 20px;
            font-family: 'Poppins', sans-serif;
         }
   
         .logo {
            position: absolute;
            right: 5%;
            top: 5%;
         }
   
         @media (max-width: 767.98px) {
            .logo {
               top: 2%;
            }
         }
        `}</style>
      <div className='row'>
        <div className='box p-5'>
          <div className='logo'>
            <img src={RpLogo} alt='RP Logo' />
          </div>
          <div className='animation'>
            <div className='spin-one'>
              <i className='fa-solid fa-cog fa-spin'></i>
            </div>
            <div className='spin-two'>
              <i className='fa-solid fa-cog fa-spin fa-spin-reverse'></i>
            </div>
            <div className='spin-three'>
              <i className='fa-solid fa-cog fa-spin'></i>
            </div>
          </div>
          <h1 className='card-heading-sec-dark'>{result?.data?.header}</h1>

          {result?.data?.message?.map((data, id) => (
            <div key={id}>
              {id % 2 === 0 ? <p>{data}</p> : <p>{data}</p>}
              <p></p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Maintenance;
