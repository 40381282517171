import { SAVE, REMOVE } from './types';

const INITIAL_STATE = {};

const user = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SAVE: {
      return {
        ...state,
        ...payload.data,
      };
    }
    case REMOVE: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default user;
