import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import LifeCoach from './Components/LifeCoach';
import Nutritionist from './Components/Nutritionist';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { isEmpty } from 'lodash';

const ScheduleCalls = (props) => {
  const [isVisible, setIsVisible] = useState();
  const [statusData, setStatusData] = useState();
  const [scheduleCallData, setScheduleCallData] = useState();
  const [scheduleCallId, setScheduleCallId] = useState();

  useEffect(() => {
    setIsVisible(props?.showTab);
    getList();
  }, [props]);

  useEffect(() => {
    if (!isEmpty(isVisible)) {
      getScheduleStatus();
    }
  }, [isVisible]);

  const getScheduleStatus = async () => {
    try {
      const result = await window.$http.getWithHeaders('schedule_call/status');

      if (result.code === window.$constants.STATUS.OK) {
        setStatusData(result?.data);
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getList = async () => {
    try {
      const result = await window.$http.getWithHeaders(
        'lookup?types=preferred_day,preferred_time,schedule_call_option,education,relationship_status,work_hours,work_model,previous_consultation,food_habit,blood_group,health_condition'
      );

      if (result.code === window.$constants.STATUS.OK) {
        setScheduleCallData(result?.data);

        if (props?.showTab === 'is_nutritionist_enabled') {
          setScheduleCallId(
            result?.data?.schedule_call_option?.find(
              (el) => el.value === 'nutritionist'
            )?.id
          );
        } else {
          setScheduleCallId(
            result?.data?.schedule_call_option?.find(
              (el) => el.value === 'lifecoach'
            )?.id
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleTabs = (value) => {
    if (isVisible !== value) {
      setStatusData();
      setIsVisible(value);
    }
  };

  return (
    <Modal
      aria-labelledby='contained-modal-title-vcenter'
      centered
      size='lg'
      show={props.showModal ? true : false}
    >
      <Modal.Header>
        <h5 className='modal-title'>
          Schedule a call with our{' '}
          {props?.showTab === 'is_nutritionist_enabled'
            ? 'Nutritionist'
            : 'Life Coach'}
        </h5>
        <button
          type='button'
          className='btn-close'
          aria-label='Close'
          onClick={props.handleCloseModal}
        ></button>
      </Modal.Header>
      <Tabs
        activeKey={isVisible}
        onSelect={(k) => handleTabs(k)}
        style={{ padding: '15px 15px 0px 15px' }}
      >
        {isVisible === 'is_nutritionist_enabled' ? (
          <Tab eventKey='is_nutritionist_enabled' title='Nutritionist'></Tab>
        ) : (
          <Tab eventKey='is_lifecoach_enabled' title='Life Coach'></Tab>
        )}
      </Tabs>

      {isVisible === 'is_nutritionist_enabled' ? (
        <Nutritionist
          handleCloseModal={props.handleCloseModal}
          statusData={statusData}
          id={scheduleCallId}
          planId={props?.planId}
          formOptions={scheduleCallData}
        />
      ) : (
        <LifeCoach
          handleCloseModal={props.handleCloseModal}
          statusData={statusData}
          id={scheduleCallId}
          formOptions={scheduleCallData}
        />
      )}
    </Modal>
  );
};

export default ScheduleCalls;
