import React, { useState } from 'react';
import { Popconfirm } from 'antd';
import { useLocation } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Message = (props) => {
  const [reminderValue, setReminderValue] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const loc = location.pathname.split('/');

  const handleGetReminder = async () => {
    setIsLoading(true);
    try {
      const result = await window.$http.getWithHeaders(
        loc[3] === 'corporate'
          ? 'send-reminder/ora/last-sent'
          : 'send-reminder/era/last-sent'
      );
      if (result.code === window.$constants.STATUS.OK) {
        setIsLoading(false);
        setReminderValue(result?.data);
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const handleSendReminder = async () => {
    setIsLoading(true);
    try {
      const result = await window.$http.postWithHeaders(
        loc[3] === 'corporate' ? 'send-reminder/ora' : 'send-reminder/era'
      );
      if (result.code === window.$constants.STATUS.OK) {
        setIsLoading(false);
        setReminderValue(result?.data);
        toast?.success(result?.message);
      } else {
        setIsLoading(false);
        toast?.error(result?.message);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  return (
    <section>
      <h2 className='card-heading-light rp-med-gray fw-5 centered '>
        {props?.msg}
      </h2>
      {props?.reminderEnabledValue ? (
        <div className='text-center mt-4'>
          <Popconfirm
            placement='topRight'
            title={
              reminderValue
                ? reminderValue
                : 'Are you sure you want to send reminder?'
            }
            onConfirm={handleSendReminder}
            overlayInnerStyle={{ width: '412px' }}
            okText='Yes'
            cancelText='No'
            overlayClassName='antdReminderButton'
          >
            <button
              type='button'
              className='btn btn-rp-primary'
              onClick={handleGetReminder}
            >
              {isLoading ? (
                <Spinner
                  as='i'
                  animation='border'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
              ) : (
                'Send reminder'
              )}
            </button>
          </Popconfirm>
        </div>
      ) : (
        ''
      )}
      {/* <div className="text-center mt-4">
          <button type="button" className="btn btn-rp-primary" onClick={handleSendReminder}>Send reminder</button>
        </div> */}
      {/* <div style={{ filter: 'blur(10px)' }}>
        <img src={PlaceHolderImg} alt='' />
      </div> */}
      <ToastContainer />
    </section>
  );
};

export default Message;
