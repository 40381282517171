import React from 'react';
import Skeleton from 'react-loading-skeleton';

const SectionCardSkeleton = () => {
  return (
    <div>
      <div className='row user-results gx-5 mt-5'>
        <div className='col-xs-12 col-md-12 mb-3'>
          <h6>
            <Skeleton width='25%' height={30} />
          </h6>
        </div>
        <div className='col-xl-5 col-md-12 user-result'>
          <ul className='ps-0'>
            {[1, 2, 3, 4].map((n) => (
              <li key={n} className='mb-4'>
                <Skeleton circle={true} height={45} width={45} />
                <div className='li-cnt'>
                  <h6 className='primary-color'>
                    <Skeleton width='230px' height={20} />
                  </h6>
                  <p>
                    <Skeleton width='370px' height={40} />
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className='col-xl-7 col-md-12 mt-4 mt-lg-0 z-1'>
          <Skeleton width='100%' height='350px' />
        </div>
      </div>
    </div>
  );
};

export default SectionCardSkeleton;
