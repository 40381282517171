import React from 'react';
import BothFilter from '../../Dashboard/Components/CompanyAnalytics/Components/Common/BothFilter';

const ManageParticipation = () => {
  return (
    <>
      <div className='col-12 mb-4'>
        <div className='d-flex justify-content-between mb-3'>
          <div className='col-md-4 position-relative'>
            <i
              className='bi bi-search position-absolute'
              style={{ left: '13px', top: '10px' }}
            ></i>
            <input
              className='form-control ps-5'
              type='search'
              placeholder='Search by name, employee ID or Email'
              aria-label='Search'
            />
          </div>
          <div className='col-3'>
            <select
              className='form-control form-select select-company'
              aria-label='Default select example'
            >
              <option selected='' hidden defaultValue='0'>
                Select Company
              </option>
              <option value='1'>Company 1</option>
              <option value='2'>Company 2</option>
              <option value='3'>Company 3</option>
              <option value='4'>Company 4</option>
              <option value='5'>Company 5</option>
              <option value='6'>Company 6</option>
              <option value='7'>Company 7</option>
            </select>
          </div>
        </div>
        <BothFilter />
      </div>

      <div className='mt-5'>
        <div className='table-responsive manage-participation-table'>
          <table className='table align-middle'>
            <thead>
              <tr valign='middle'>
                <th>
                  <span>
                    <i className='fa-solid fa-user'></i>
                  </span>
                  <span>Name</span>
                </th>
                <th>
                  <span>
                    <i className='fa-solid fa-user-group'></i>
                  </span>
                  <span>Group</span>
                </th>
                <th>
                  <span>
                    <i className='fa-solid fa-location-dot'></i>
                  </span>
                  <span>Location</span>
                </th>
                <th>
                  <span>
                    <i className='fa-solid fa-id-card-clip'></i>
                  </span>
                  <span>Employee Id</span>
                </th>
                <th width='150'>
                  <div className='d-flex align-items-center'>
                    <span>
                      <i className='fa-solid fa-chart-simple'></i>
                    </span>
                    <span>Assessment complete</span>
                  </div>
                </th>
                <th width='150'>
                  <span>
                    <i className='fa-solid fa-wrench'></i>
                  </span>
                  <span>Action</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className='tr-group-item'>
                <td>Vikas Gupta</td>
                <td>Dev Team</td>
                <td>United States</td>
                <td>RP-001</td>
                <td className='action_buttons'>
                  <span>Unopened</span>
                  <span title='Resend Welcome Email'>
                    <i className='fa-solid fa-paper-plane'></i>
                  </span>
                </td>
                <td className='action_buttons'>
                  <span title='Download Result'>
                    <i className='fa-solid fa-download'></i>
                  </span>
                  <span
                    data-bs-toggle='modal'
                    data-bs-target='#editPeople'
                    title='Edit User'
                  >
                    <i className='fa-solid fa-pencil'></i>
                  </span>
                  <span title='Delete User'>
                    <i className='fa-solid fa-trash-can'></i>
                  </span>
                </td>
              </tr>
              <tr className='tr-group-item'>
                <td>Manas Agarwal</td>
                <td>Design Team</td>
                <td>India</td>
                <td>RP-002</td>
                <td className='action_buttons'>
                  <span>No</span>
                  <span title='Assessment Reminder'>
                    <i className='fa-solid fa-stopwatch'></i>
                  </span>
                </td>
                <td className='action_buttons'>
                  <span title='Download Result'>
                    <i className='fa-solid fa-download'></i>
                  </span>
                  <span
                    data-bs-toggle='modal'
                    data-bs-target='#editPeople2'
                    title='Edit User'
                  >
                    <i className='fa-solid fa-pen'></i>
                  </span>
                  <span title='Delete User'>
                    <i className='fa-solid fa-trash-can'></i>
                  </span>
                </td>
              </tr>
              <tr className='tr-group-item'>
                <td>Steven Zodkoy</td>
                <td>Analysts</td>
                <td>United States</td>
                <td>RP-003</td>
                <td className='action_buttons'>
                  <span>Unopened</span>
                  <span title='Resend Welcome Email'>
                    <i className='fa-solid fa-paper-plane'></i>
                  </span>
                </td>
                <td className='action_buttons'>
                  <span title='Download Result'>
                    <i className='fa-solid fa-download'></i>
                  </span>
                  <span
                    data-bs-toggle='modal'
                    data-bs-target='#editPeople3'
                    title='Edit User'
                  >
                    <i className='fa-solid fa-pen'></i>
                  </span>
                  <span title='Delete User'>
                    <i className='fa-solid fa-trash-can'></i>
                  </span>
                </td>
              </tr>
              <tr className='tr-group-item'>
                <td>Ankit Aggrwal </td>
                <td>Management Team</td>
                <td>India</td>
                <td>RP-004</td>
                <td className='action_buttons'>
                  <span>No</span>
                  <span title='Assessment Reminder'>
                    <i className='fa-solid fa-stopwatch'></i>
                  </span>
                </td>
                <td className='action_buttons'>
                  <span title='Download Result'>
                    <i className='fa-solid fa-download'></i>
                  </span>
                  <span
                    data-bs-toggle='modal'
                    data-bs-target='#editPeople4'
                    title='Edit User'
                  >
                    <i className='fa-solid fa-pen'></i>
                  </span>
                  <span title='Delete User'>
                    <i className='fa-solid fa-trash-can'></i>
                  </span>
                </td>
              </tr>
              <tr className='tr-group-item'>
                <td>Suraj Kumar Maurya</td>
                <td>Dev Team</td>
                <td>India</td>
                <td>RP-005</td>
                <td className='action_buttons'>
                  <span>No</span>
                  <span title='Assessment Reminder'>
                    <i className='fa-solid fa-stopwatch'></i>
                  </span>
                </td>
                <td className='action_buttons'>
                  <span title='Download Result'>
                    <i className='fa-solid fa-download'></i>
                  </span>
                  <span
                    data-bs-toggle='modal'
                    data-bs-target='#editPeople5'
                    title='Edit User'
                  >
                    <i className='fa-solid fa-pen'></i>
                  </span>
                  <span title='Delete User'>
                    <i className='fa-solid fa-trash-can'></i>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <nav className='mt-5' aria-label='Page navigation example'>
        <ul className='pagination justify-content-center'>
          <li className='page-item'>
            <button
              to='javascript:void(0)'
              aria-label='Previous'
              type='button'
              className='page-link'
            >
              <span aria-hidden='true'>
                <i className='fa-solid fa-angle-left'></i>
              </span>
            </button>
          </li>
          <li className='page-item'>
            <a className='page-link active' href='javascript:void(0)'>
              1
            </a>
          </li>
          <li className='page-item'>
            <a className='page-link' href='javascript:void(0)'>
              2
            </a>
          </li>
          <li className='page-item'>
            <button
              to='javascript:void(0)'
              aria-label='Next'
              type='button'
              className='page-link'
            >
              <span aria-hidden='true'>
                <i className='fa-solid fa-angle-right'></i>
              </span>
            </button>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default ManageParticipation;
