import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Scroll from 'react-scroll';
import parse from 'html-react-parser';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import AssessmentCompleteImage from '../../../../../../assets/images/assessment-complete.png';
import { removeRandomAttempts } from '../../../../../../store/random-attempts/actions';
import { saveAssmt } from '../../../../../../store/assmt/actions';
import Spinner from 'react-bootstrap/Spinner';

const ScrollLink = Scroll.Link;

const Result = () => {
  const modalRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPreloader, setShowPreloader] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const rp_user_assmt_id = useSelector((state) => state.userAssmtId?.payload);
  const randomAttemptsData = useSelector((state) => state?.randomAttempts);

  useEffect(() => {
    if (randomAttemptsData?.is_random_attempts === false) {
      setTimeout(() => {
        setShowPreloader(false);
      }, 5000);
    } else if (randomAttemptsData?.is_random_attempts === true) {
      setTimeout(() => {
        setShowPreloader(false);
      }, 5000);
      setShowModal(true);
    } else if (
      randomAttemptsData?.is_random_attempts === [] ||
      !randomAttemptsData?.is_random_attempts
    ) {
      getAssessmentData();
    }
  }, []);

  const getAssessmentData = async () => {
    try {
      const result = await window.$http.getWithHeaders('my/assessment');
      if (result.code === window.$constants.STATUS.OK) {
        dispatch(saveAssmt(result));
        result?.data?.map((el) =>
          el.status === window.$enums.AssmtStatus.Current
            ? navigate('/dashboard/assessments/questionnaire')
            : navigate('/dashboard/assessments/result/0')
        );
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (modalRef.current && randomAttemptsData?.is_random_attempts === 'true') {
      modalRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  }, [randomAttemptsData?.is_random_attempts]);

  const handleResult = async (e) => {
    e.preventDefault();
    dispatch(removeRandomAttempts());
    const result = await window.$http.getWithHeaders('my/assessment');
    if (result.code === window.$constants.STATUS.OK) {
      dispatch(saveAssmt(result));
    }
    return navigate(`/dashboard/assessments/result/${rp_user_assmt_id}`);
  };

  const hanldeAssessmentReset = async () => {
    dispatch(removeRandomAttempts());
    return navigate('/dashboard/assessments/questionnaire');
  };

  const handleConfirm = async () => {
    try {
      setIsBtnLoading(true);
      const result = await window.$http.postWithHeaders(
        'assessment/randomattempts/confirm',
        {
          rp_user_assmt_id,
        }
      );

      if (result.code === window.$constants.STATUS.OK) {
        return setShowModal(false);
      }
    } catch (error) {
      console.log(error);
    }
    setIsBtnLoading(false);
  };

  return (
    <section id='assessment' ref={modalRef}>
      <div className='container'>
        <div className='row '>
          <div className='col-12 '>
            <div className='position-relative px-2 px-lg-0'>
              <div className='achivment box_shadow'>
                <div className='dots-style-2'></div>
                <div
                  className={
                    showPreloader ? 'preloader show' : 'preloader hide'
                  }
                >
                  <div className='preloader-equalizer'>
                    <ul>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                    </ul>
                    <h5 className='text-light mt-2'>Calculating Results</h5>
                  </div>
                </div>

                {!showPreloader ? (
                  randomAttemptsData?.is_random_attempts && showModal ? (
                    <Modal
                      aria-labelledby='contained-modal-title-vcenter'
                      centered
                      show={showModal ? true : false}
                    >
                      <Modal.Header>
                        <h5 className='modal-title'>
                          {randomAttemptsData?.header_text}
                        </h5>
                      </Modal.Header>
                      <Modal.Body>
                        {parse(randomAttemptsData?.description)}
                        {parse(randomAttemptsData?.note)}
                      </Modal.Body>
                      <Modal.Footer className='confirmation-footer justify-content-between'>
                        <ScrollLink
                          smooth={true}
                          duration={500}
                          to='assessment'
                        >
                          <button
                            type='button'
                            className='btn btn-rp-primary-red'
                            data-bs-dismiss='modal'
                            onClick={hanldeAssessmentReset}
                          >
                            Restart Assessment
                          </button>
                        </ScrollLink>
                        <button
                          type='button'
                          data-bs-dismiss='modal'
                          className={`btn btn-rp-primary ${
                            isBtnLoading ? 'disabled' : ''
                          }`}
                          onClick={handleConfirm}
                          style={{ width: '8rem' }}
                        >
                          {isBtnLoading ? (
                            <Spinner
                              as='i'
                              animation='border'
                              size='sm'
                              role='status'
                              aria-hidden='true'
                            />
                          ) : (
                            'Confirm'
                          )}
                        </button>
                      </Modal.Footer>
                    </Modal>
                  ) : (
                    <>
                      <div className='mb-4'>
                        <img
                          src={AssessmentCompleteImage}
                          alt='medal'
                          className='img-fluid'
                        />
                      </div>

                      <h5 className='mb-1'>Well Done!</h5>
                      <h6 className='mb-8 text-center'>
                        {randomAttemptsData?.completion_message
                          ? randomAttemptsData?.completion_message
                          : 'Assessment Complete.'}
                      </h6>

                      <button
                        className='btn btn-rp-secondary position-relative'
                        onClick={handleResult}
                      >
                        Show my Results
                      </button>
                    </>
                  )
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Result;
