import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import ReactWhatsapp from 'react-whatsapp';
import HeaderTabs from '../../pages/Dashboard/Components/HeaderTabs';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../store/auth/actions';
import { saveNotificationData } from '../../store/notification-data/actions';
import Banner from '../../common/Banner';
import Disclaimer from '../../pages/Login/Footer/Components/Disclaimer';
import PrivacyPolicy from '../../pages/Login/Footer/Components/PrivacyPolicy';
import TermsOfUse from '../../pages/Login/Footer/Components/TermsOfUse';
import { removeLangId } from '../../store/langId/actions';
import { isEmpty } from 'lodash';
import { removeAssmt } from '../../store/assmt/actions';
import { saveAssmt } from '../../store/assmt/actions';
import { removeAssmtId } from '../../store/assmtId/actions';
import { removeRandomAttempts } from '../../store/random-attempts/actions';
import { removeCategorySlug } from '../../store/categorySlug/actions';
import { removeCompanyData } from '../../store/company-data/actions';
import { removeNotificationData } from '../../store/notification-data/actions';
import { removeRevision } from '../../store/revision/actions';
import { removeUserAssmtId } from '../../store/userAssmtId/actions';
import SupportModal from '../../common/Modals/SupportModal';
import MobileApp from '../../common/Modals/MobileApp';
import RpLogo from '../../assets/images/rp-logo.png';
import { removeCompanyTypeId } from '../../store/companyTypeId/actions';
import { removeFilters } from '../../store/filters/actions';
import { removeTabs } from '../../store/tabs/actions';
import { remove } from '../../store/user/actions';

const Header = (props) => {
  const dispatch = useDispatch();
  const ref = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const [bannerData, setBannerData] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const auth = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user);
  const [showModal, setShowModal] = useState('');
  const notificationData = useSelector(
    (state) => state?.notificationData?.payload
  );
  const fullName =
    (!isEmpty(user?.first_name) ? user?.first_name : '') +
    ' ' +
    (!isEmpty(user?.last_name) ? user?.last_name : '');
  const loc = location.pathname.split('/');
  const assmt = useSelector((state) => state?.assmt);
  const [assmtData, setAssmtData] = useState();
  const [fixedTop, setFixedTop] = useState(false);

  const handleCloseModal = () => {
    setShowModal('');
  };

  useEffect(() => {
    if (isEmpty(showModal)) {
      setIsClicked(true);
      document.body.classList.remove('overflow-hidden');
    }
  }, [showModal]);

  const handleShowModal = (value) => {
    // if (assmt && value === 'invite' && !isEmpty(assmtData)) {
    //   document.body.classList.remove('overflow-hidden');
    //   return navigate('/invite');
    // } else {
    setIsClicked(false);
    setShowModal(value);
    // }
  };

  useEffect(() => {
    setAssmtData(
      assmt?.data?.find(
        (data) => data?.status === window.$enums.AssmtStatus.Previous
      )
    );
  }, [assmt]);

  useEffect(() => {
    if (loc[2] === 'assessments') {
      dispatch(saveNotificationData('assmt'));
    } else if (loc[2] === 'resiliency-plan') {
      dispatch(saveNotificationData('plan'));
    } else if (loc[2] === 'company-analytics') {
      dispatch(saveNotificationData('analytics'));
    } else if (loc[2] === 'admin' || loc[1] === 'manage') {
      dispatch(saveNotificationData('admin'));
    } else if (loc[1] === 'dashboard' && !loc[2]) {
      dispatch(saveNotificationData('dashboard'));
    }
    getBannerData();
  }, []);

  const getBannerData = async () => {
    try {
      const result = await window.$http.getWithHeaders(
        'dashboard/web-only/banner'
      );

      if (result.code === window.$constants.STATUS.OK) {
        setBannerData(result.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const logoutHandler = async () => {
    try {
      const result = await window.$http.logoutUser('v2/logout');
      if (result.code === window.$constants.STATUS.OK) {
        dispatch(removeCategorySlug());
        dispatch(removeCompanyData());
        dispatch(removeNotificationData());
        dispatch(removeRevision());
        dispatch(removeUserAssmtId());
        dispatch(removeRandomAttempts());
        dispatch(removeLangId());
        dispatch(removeAssmt());
        dispatch(removeCompanyTypeId());
        dispatch(removeTabs());
        dispatch(removeFilters());
        dispatch(logout());
        dispatch(remove());
        dispatch(removeAssmtId());
        navigate('/logout');
      } else {
        console.log('Something went wrong');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const profileReset = async () => {
    try {
      const result = await window.$http.postWithHeaders(
        'profile/completed/reset'
      );
      if (result.code === window.$constants.STATUS.OK) {
        dispatch(removeCategorySlug());
        dispatch(removeCompanyData());
        dispatch(removeNotificationData());
        dispatch(removeRevision());
        dispatch(removeUserAssmtId());
        dispatch(removeAssmtId());
        dispatch(removeRandomAttempts());
        dispatch(removeLangId());
        dispatch(removeCompanyTypeId());
        dispatch(removeTabs());
        dispatch(removeFilters());
        dispatch(logout());
        dispatch(remove());
        dispatch(removeAssmt());
        navigate('/logout');
      } else {
        console.log('Something went wrong');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const assessmentReset = async () => {
    try {
      const result = await window.$http.postWithHeaders(
        'my/assessment/reset/0'
      );
      if (result.code === window.$constants.STATUS.OK) {
        setIsClicked(!isClicked);
        getAssessmentData();
        return navigate('/dashboard');
      } else {
        console.log('Something went wrong');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAssessmentData = async () => {
    try {
      const result = await window.$http.getWithHeaders('my/assessment');
      if (result.code === window.$constants.STATUS.OK) {
        dispatch(saveAssmt(result));
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSupport = () => {
    setIsClicked(!isClicked);
    document.body.classList.remove('overflow-hidden');
  };

  const handleWhatsapp = () => {
    setIsClicked(!isClicked);
    document.body.classList.remove('overflow-hidden');
  };

  const handleNotification = () => {
    if (loc[2] === 'assessments') {
      if (notificationData) {
        dispatch(saveNotificationData(''));
      } else {
        dispatch(saveNotificationData('assmt'));
      }
    } else if (loc[2] === 'resiliency-plan') {
      if (notificationData) {
        dispatch(saveNotificationData(''));
      } else {
        dispatch(saveNotificationData('plan'));
      }
    } else if (loc[2] === 'company-analytics') {
      if (notificationData) {
        dispatch(saveNotificationData(''));
      } else {
        dispatch(saveNotificationData('analytics'));
      }
    } else if (loc[2] === 'admin' || loc[1] === 'manage') {
      if (notificationData) {
        dispatch(saveNotificationData(''));
      } else {
        dispatch(saveNotificationData('admin'));
      }
    } else if (loc[1] === 'dashboard' && !loc[2]) {
      if (notificationData) {
        dispatch(saveNotificationData(''));
      } else {
        dispatch(saveNotificationData('dashboard'));
      }
    } else {
      setIsVisible(!isVisible);
    }
  };

  const handleMenuOptions = () => {
    document.body.classList.remove('overflow-hidden');
  };

  const handleData = (data) => {
    setBannerData(data);
  };

  const handleHeader = () => {
    if (window.scrollY) {
      setFixedTop(true);
      let navbar_height = document.querySelector('header')?.offsetHeight;
      document.body.style.paddingTop = navbar_height + 'px';
    } else {
      setFixedTop(false);
      document.body.style.paddingTop = '0';
    }
  };

  window.addEventListener('scroll', handleHeader);

  function handleMenubars() {
    dispatch(saveNotificationData(''));
    setIsVisible(false);
    document.getElementById('myDropdown').classList.toggle('show');
    document.getElementById('nav-icon3').classList.toggle('open');
  }

  function myFunction() {
    dispatch(saveNotificationData(''));
    setIsVisible(false);
    document.getElementById('myDropdown').classList.toggle('show');
    document.getElementById('web-toggler').classList.toggle('rotate');
  }

  window.onclick = function (event) {
    if (
      (window.innerWidth > 768 && !event.target.matches('.fa-chevron-down')) ||
      (window.innerWidth < 768 && event.target.matches('.open'))
    ) {
      var dropdowns = document.getElementsByClassName('menu-items');
      var menu_togglers = document.getElementById('web-toggler');
      var i;
      for (i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.classList.contains('show')) {
          openDropdown.classList.remove('show');
          menu_togglers.classList.remove('rotate');
        }
      }
    }
  };

  return (
    <>
      {props.domainValue === 'sms' ? (
        auth?.isAuthenticated ? (
          <header className='bg-white px-0 px-lg-4'>
            <div className='row py-3 m-0 px-3 px-lg-4'>
              <div className='header px-0'>
                <div className='client-icon'>
                  <img src={RpLogo} alt='client logo' />
                </div>

                <div className='col-md-3 user-options'>
                  <div className='user-info d-flex align-items-center me-3'>
                    <Link
                      style={{ minWidth: '100px' }}
                      onClick={logoutHandler}
                      className='btn btn-rp-primary'
                    >
                      Logout
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </header>
        ) : (
          <header>
            <div
              className='bg-white d-flex justify-content-center align-items-center'
              style={{ height: '70px' }}
            >
              <div className='client-icon'>
                <img src={RpLogo} alt='client logo' />
              </div>
            </div>
          </header>
        )
      ) : (
        <header
          className={
            fixedTop
              ? 'mb-0 fixed-top'
              : loc[2]
              ? loc[2] === 'assessments'
                ? ''
                : 'mb-0'
              : ''
          }
          ref={ref}
        >
          <div className='bg-white header'>
            <div className='great'>
              <div className='profile-mob'>
                <div className='profile-icon me-2'>
                  {window.$utility.getInitials(fullName)}
                </div>
              </div>
              <div>
                <h5 className='user-name '>Hello, {user?.first_name}</h5>
                <p>Welcome to Resiliency Program</p>
              </div>
            </div>
            <div className='col-md-3 user-options'>
              {user?.is_profile_completed ? (
                <div className='user-action d-flex align-items-center'>
                  <div className='position-relative'>
                    <div
                      className='nav-ioc position-relative notificaton m-3'
                      onClick={handleNotification}
                      style={
                        !bannerData?.length
                          ? window.innerWidth < 768
                            ? { visibility: 'hidden' }
                            : { display: 'none' }
                          : {}
                      }
                    >
                      <div className='dot fa-beat'></div>
                      <span>
                        <i className='bi bi-bell'></i>
                      </span>
                    </div>
                    {bannerData && bannerData?.length > 0 ? (
                      <Banner
                        isVisible={isVisible}
                        bannerData={bannerData}
                        handleData={handleData}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              ) : (
                ''
              )}

              <div className='client-icon me-3'>
                <img
                  src={`${
                    process.env.REACT_APP_API_URL
                  }media/company/${window.$utility.getSubDomain()}`}
                  alt='client logo'
                />
              </div>

              <div className='user-info d-flex align-items-center'>
                <div className='user-profile d-flex align-items-center'>
                  <div className='profile-menu position-relative'>
                    <div className='dropdown-toggler cursor-p'>
                      <i
                        id='web-toggler'
                        className='fas fa-chevron-down'
                        onClick={myFunction}
                      ></i>
                    </div>
                    <>
                      <div className='menu-items box_shadow' id='myDropdown'>
                        <ul className='fade-down'>
                          {(user?.is_profile_completed ||
                            user?.rp_gbl_company_type_id ===
                              window.$enums.CompanyTypeId.CompanyAdmin) && (
                            <>
                              <div className='d-flex nav-bb py-3'>
                                <div className='profile-icon me-2'>
                                  {window.$utility.getInitials(fullName)}
                                </div>
                                <div className='d-flex flex-column'>
                                  <h5 className='mb-0'>{fullName}</h5>
                                  <p className='user-id primary-color'>
                                    {user?.email}
                                  </p>
                                </div>
                              </div>

                              {/* {user.rp_gbl_company_type_id ===
                                window.$enums.CompanyTypeId.Clinical &&
                              user?.is_company_admin ? (
                                ''
                              ) : (
                                <div className='nav-bb py-3'>
                                  {user?.is_web_enabled ? (
                                    <li>
                                      <Link
                                        className='dropdown-item'
                                        to='/view-profile'
                                        onClick={handleMenuOptions}
                                      >
                                        <i className='bi bi-person'></i>Profile
                                      </Link>
                                    </li>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              )} */}

                              {window.innerWidth < 768 ? (
                                <div className='nav-bb py-3'>
                                  <li
                                    className='d-block d-xl-none'
                                    onClick={() => handleShowModal('privacy')}
                                  >
                                    <Link className='dropdown-item' to='#!'>
                                      <i className='bi bi-shield-check'></i>{' '}
                                      Privacy Policy{' '}
                                    </Link>
                                  </li>
                                  <li
                                    className='d-block d-xl-none'
                                    onClick={() => handleShowModal('terms')}
                                  >
                                    <Link className='dropdown-item' to='#!'>
                                      <i className='bi bi-file-earmark-lock2'></i>{' '}
                                      Terms of Use
                                    </Link>
                                  </li>
                                  <li
                                    className='d-block d-xl-none'
                                    onClick={() =>
                                      handleShowModal('disclaimer')
                                    }
                                  >
                                    <Link className='dropdown-item' to='#!'>
                                      <i className='bi bi-file-earmark-lock2'></i>
                                      Disclaimer{' '}
                                    </Link>
                                  </li>
                                </div>
                              ) : (
                                ''
                              )}
                              {user?.has_reset_access ? (
                                <>
                                  <div className='nav-bb py-3 d-none d-xl-block'>
                                    <li>
                                      <Link
                                        className='dropdown-item'
                                        to='#'
                                        onClick={() => profileReset()}
                                      >
                                        <i className='fa-regular fa-id-badge'></i>{' '}
                                        Profile Reset
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        className='dropdown-item'
                                        to='#'
                                        onClick={() => assessmentReset()}
                                      >
                                        <i className='fa-regular fa-clipboard'></i>{' '}
                                        Assessment Reset
                                      </Link>
                                    </li>
                                  </div>
                                </>
                              ) : (
                                ''
                              )}
                              <div className='nav-bb py-3'>
                                {user?.is_web_enabled ? (
                                  <>
                                    {!isEmpty(user?.settings_visible_options) &&
                                    user?.settings_visible_options !== null ? (
                                      <li>
                                        <Link
                                          className='dropdown-item'
                                          to='/setting'
                                          onClick={handleMenuOptions}
                                        >
                                          <i className='bi bi-gear'></i>Settings
                                        </Link>
                                      </li>
                                    ) : (
                                      ''
                                    )}
                                  </>
                                ) : (
                                  ''
                                )}

                                <li>
                                  <Link
                                    className='dropdown-item'
                                    to='#'
                                    data-bs-toggle='modal'
                                    data-bs-target='#support'
                                    onClick={() => handleSupport()}
                                  >
                                    <i className='bi bi-envelope'></i>
                                    Support
                                  </Link>
                                </li>
                                <li>
                                  <ReactWhatsapp
                                    className='dropdown-item'
                                    to='#'
                                    number='+919311587006'
                                    target='_blank'
                                    onClick={() => handleWhatsapp()}
                                  >
                                    <i className='bi bi-whatsapp'></i>
                                    Contact us
                                  </ReactWhatsapp>
                                </li>
                              </div>
                            </>
                          )}
                          <div className='py-3'>
                            <li>
                              <Link
                                className='dropdown-item'
                                onClick={logoutHandler}
                                to='#'
                              >
                                <i className='bi bi-arrow-down-left'></i>
                                Log out
                              </Link>
                            </li>
                          </div>
                        </ul>
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </div>

            <div className='mob-toggler'>
              <div id='nav-icon3' className='' onClick={handleMenubars}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
          <div style={{ background: '#f7f7f7', height: '2px' }}></div>
          <div className='head-tabs bg-white'>
            {(user?.is_profile_completed ||
              user?.rp_gbl_company_type_id ===
                window.$enums.CompanyTypeId.CompanyAdmin) && <HeaderTabs />}
          </div>
        </header>
      )}
      <SupportModal />
      {showModal === 'privacy' && (
        <PrivacyPolicy
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          handleShowModal={handleShowModal}
        />
      )}
      {showModal === 'terms' && (
        <TermsOfUse showModal={showModal} handleCloseModal={handleCloseModal} />
      )}
      {showModal === 'disclaimer' && (
        <Disclaimer showModal={showModal} handleCloseModal={handleCloseModal} />
      )}
      {assmt && showModal === 'scheduleCalls' && isEmpty(assmtData) ? (
        <MobileApp
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          heading='Schedule a call with our Life Coach'
        />
      ) : (
        showModal === 'scheduleCalls' && (
          <MobileApp
            showModal={showModal}
            handleCloseModal={handleCloseModal}
            heading='Schedule a call with our Life Coach'
          />
        )
      )}
      {assmt && showModal === 'invite' && (
        <MobileApp
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          heading='Invite Spouse/Children/Parents'
        />
      )}
    </>
  );
};

export default Header;
