import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import parse from 'html-react-parser';
import Recipe from './Recipe';
import Scroll from 'react-scroll';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import LoadingSpinner from '../../../../../../../common/LoadingSpinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from 'react-bootstrap/Spinner';
import { isEmpty } from 'lodash';
import MobileApp from '../../../../../../../common/Modals/MobileApp';

const ScrollLink = Scroll.Link;

const Plan = (props) => {
  const user = useSelector((state) => state?.user);
  const [planResult, setPlanResult] = useState();
  const [isReadMore, setIsReadMore] = useState(true);
  const [showRecipe, setShowRecipe] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [textId, setTextId] = useState('');
  const [timing, setTiming] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [title, setTitle] = useState();
  const [showModal, setShowModal] = useState('');

  const location = useLocation();
  const loc = location.pathname.split('/');

  useEffect(() => {
    window.scrollTo(0, 0);
    if (props.showPlan) {
      setShowTable(props.showPlan);
      setShowRecipe(false);
    }

    if (!props?.title) {
      for (let i = 0; i < props?.dietResult?.length; ) {
        for (let j = 0; j < props?.dietResult[i]?.plans?.length; ) {
          if (props?.dietResult[i]?.plans[j].rp_food_diet_plan_id === +loc[6]) {
            setTitle(props?.dietResult[i]?.title);
            break;
          } else {
            j++;
          }
        }
        i++;
      }
    } else {
      setTitle(props?.title);
    }
  }, [props]);

  useEffect(() => {
    if (!isEmpty(title)) {
      getPlanData();
    }
  }, [title]);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const downloadPlan = async () => {
    setIsDownloadLoading(true);
    try {
      const result = await window.$http.getWithHeaders(
        `food-diet/plan/${props.planId}/download`
      );
      if (result.code === window.$constants.STATUS.OK) {
        setIsDownloadLoading(false);
        var linkSource = result?.data?.media_content;
        var downloadLink = document.createElement('a');
        var fileName = result?.data?.filename
          ? result?.data?.filename
          : 'plan.pdf';
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      } else {
        setIsDownloadLoading(false);
        result?.message
          ? toast.error(result?.message)
          : toast.error('Error occured, please try again or contact admin.');
      }
    } catch (error) {
      setIsDownloadLoading(false);
      console.log(error);
    }
  };

  const getPlanData = async () => {
    setIsLoading(true);
    try {
      if (props.planId) {
        const data = await window.$http.getWithHeaders(
          `food-diet/plan/${props.planId}`
        );
        if (data.code === window.$constants.STATUS.OK) {
          setIsLoading(false);
          setPlanResult(data?.data);
        } else {
          console.log('Something went wrong!');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const tableHeader = () => {
    var header = '';
    for (var key in planResult?.planner[0]) {
      if (key === 'time') {
        header +=
          `<th scope='col'>` +
          planResult?.planner[0]?.[key] +
          `</th scope='col'>`;
      } else {
        header +=
          `<th scope='col'>` +
          planResult?.planner[0]?.[key]?.text +
          `</th scope='col'>`;
      }
    }
    return header;
  };

  const handleCloseModal = () => {
    setShowModal('');
  };

  const handleRecipe = (id, key) => {
    setShowRecipe(true);
    setTextId(id);
    setTiming(key?.time);
  };

  const handleTable = () => {
    setShowTable(!showTable);
    props.handleListVisible(!showTable);
  };

  const handleLifestyle = () => {
    props.handleShowList(false);
    setShowRecipe(false);
  };

  function tdContent(index, key) {
    if (index === 0) {
      return '';
    }
    var dataArray = planResult?.planner[index];
    if (key === 'time') {
      return <th scope='row'>{dataArray?.[key]}</th>;
    } else {
      return (
        <td>
          <ScrollLink to='recipe' smooth={true} duration={100}>
            <span onClick={() => handleRecipe(dataArray?.[key]?.id, dataArray)}>
              {dataArray?.[key]?.text
                ? dataArray?.[key]?.text
                : 'Not Available'}
            </span>
          </ScrollLink>
        </td>
      );
    }
  }

  return (
    <>
      {planResult && !isLoading ? (
        <div id='plan1' className={showTable ? 'list' : 'list hide'}>
          <nav aria-label='breadcrumb mb-5'>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>
                <Link
                  className='item-link'
                  to='/dashboard/resiliency-plan/lifestyle'
                  onClick={handleLifestyle}
                >
                  Lifestyle Changes
                </Link>
              </li>
              <li className='breadcrumb-item back-btn'>
                <Link
                  className='item-link'
                  to={`/dashboard/resiliency-plan/lifestyle/${loc[4]}`}
                  onClick={handleTable}
                >
                  {title}
                </Link>
              </li>
              <li className='breadcrumb-item' aria-current='page'>
                {planResult?.title}
              </li>
            </ol>
          </nav>
          <div className='mb-5'>
            <div className='d-flex align-items-center justify-content-between mb-3'>
              <div className='d-flex align-items-center'>
                <h1 className='card-heading-sec-dark'>{planResult?.title}</h1>
                <button
                  className='btn btn-rp-primary ms-3'
                  onClick={downloadPlan}
                  disabled={isDownloadLoading && true}
                  style={{ marginBottom: '15px' }}
                >
                  {isDownloadLoading ? (
                    <Spinner
                      as='i'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    'Download Plan'
                  )}
                </button>

                {user?.is_food_plan_schedule_enabled ? (
                  <button
                    className='btn btn-rp-primary ms-3'
                    onClick={() => setShowModal('plan')}
                    style={{ marginBottom: '15px' }}
                  >
                    Start My Plan
                  </button>
                ) : (
                  ''
                )}
              </div>
            </div>
            {isReadMore
              ? parse(planResult?.description?.slice(0, 280) + '...')
              : parse(planResult?.description)}
            <span onClick={toggleReadMore} className='read-or-hide'>
              {isReadMore ? (
                <span className='primary-color cursor-p moreless-button'>
                  Read more
                </span>
              ) : (
                <span className='primary-color cursor-p moreless-button'>
                  Read less
                </span>
              )}
            </span>
          </div>

          <div className='plan-table mb-5'>
            <div className='table-responsive-md'>
              <table className='table'>
                <thead>
                  <tr>{parse(tableHeader())}</tr>
                </thead>
                <tbody>
                  {planResult?.planner?.map((item, index) => (
                    <>
                      <tr>
                        {item &&
                          Object.keys(planResult?.planner[index]).map(
                            (data, dataIndex) => <>{tdContent(index, data)}</>
                          )}
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div id='recipe'></div>
          {showRecipe && (
            <Recipe showRecipe={showRecipe} textId={textId} timing={timing} />
          )}

          {user?.schedule_call_options?.includes('is_nutritionist_enabled') ? (
            <div
              className='bg-white p-2 br-6'
              style={{
                boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                display: 'inline-block',
                marginBottom: '15px',
              }}
            >
              <div className='align-items-center d-flex '>
                <span
                  className='fw-5 rp-dark-grey '
                  style={{
                    fontSize: '18px',
                  }}
                >
                  <i className='fa-regular fa-comments me-2'></i> If you want to
                  discuss more about the plan, then{' '}
                </span>
                <button
                  className='btn btn-rp-primary ms-3'
                  onClick={() => setShowModal('schedule')}
                >
                  Talk to our Nutritionist
                </button>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        <div className='centered' style={{ marginLeft: '1rem' }}>
          <LoadingSpinner />
        </div>
      )}
      <ToastContainer />
      {showModal === 'schedule' && (
        <MobileApp
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          heading='Talk to our Nutritionist'
        />
      )}

      {showModal === 'plan' ? (
        <MobileApp
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          heading='Start My Plan'
        />
      ) : (
        ''
      )}
    </>
  );
};

export default Plan;
