import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Sidebar from './Components/Common/Sidebar';
import WorkshopModal from './Components/Workshops/WorkshopModal';
import LoadingSpinner from '../../../../common/LoadingSpinner';
import { saveFilters } from '../../../../store/filters/actions';

const Workshops = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [workshopData, setWorkshopData] = useState([]);
  const [workshopId, setWorkshopId] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [noDataMsg, setNoDataMsg] = useState();
  const [selectedArray, setSelectedArray] = useState({});

  useEffect(() => {
    getWorkshopsData('all');
  }, []);

  const getWorkshopsData = async (buttonType) => {
    setIsLoading(true);
    try {
      let query = {};

      query = {
        venue_type: buttonType,
      };
      const result = await window.$http.getWithQueryAndHeaders(
        'workshop-events',
        query
      );
      if (result.code === window.$constants.STATUS.OK) {
        setIsLoading(false);
        setWorkshopData(result?.data?.events);
        setNoDataMsg(result?.metadata?.no_data_message);
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleWorkshopModal = (id) => {
    setWorkshopId(id);
    setShowModal(!showModal);
  };

  const handleCloseModal = () => {
    setShowModal('');
  };

  const getFilterValues = (value) => {
    const data = window.$utility.setDynamicFilterOptions(value, selectedArray);
    setSelectedArray(data);
    dispatch(saveFilters(data, 'workshop'));
  };

  return (
    <main>
      <div className='plan_container'>
        <Sidebar getFilterValues={getFilterValues} workshopValue='workshop' />

        <div className='main_content'>
          <div className='row'>
            <div className='col-12 mb-4'>
              <div className='assessment-tabs-group workshop-event-tabs-group'>
                <ul className='nav nav-pills' id='pills-tab' role='tablist'>
                  <li className='nav-item' role='presentation'>
                    <button
                      className='btn-assment-month active'
                      id='pills-all-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-all'
                      type='button'
                      role='tab'
                      aria-controls='pills-all'
                      aria-selected='true'
                      onClick={() => getWorkshopsData('all')}
                    >
                      All
                    </button>
                  </li>
                  <li className='nav-item' role='presentation'>
                    <button
                      className='btn-assment-month '
                      id='pills-online-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-online'
                      type='button'
                      role='tab'
                      aria-controls='pills-online'
                      aria-selected='false'
                      onClick={() => getWorkshopsData('online')}
                    >
                      Online
                    </button>
                  </li>
                  <li className='nav-item' role='presentation'>
                    <button
                      className='btn-assment-month '
                      id='pills-onsite-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-onsite'
                      type='button'
                      role='tab'
                      aria-controls='pills-onsite'
                      aria-selected='false'
                      onClick={() => getWorkshopsData('onsite')}
                    >
                      Onsite
                    </button>
                  </li>
                </ul>
              </div>
            </div>

            {/* <!-- Burnout levels --> */}
            {!isLoading ? (
              workshopData?.length || workshopData !== null ? (
                <div className='col-12'>
                  <div className='bg-white p-4 br-8'>
                    <div className='tab-content' id='pills-tabContent'>
                      <div
                        className='tab-pane fade active show'
                        id='pills-all'
                        role='tabpanel'
                        aria-labelledby='pills-all-tab'
                        tabIndex='0'
                      >
                        <div className='row gy-4'>
                          {workshopData?.map((data) => (
                            <div className='col-4'>
                              <div
                                className='event-card'
                                style={{
                                  background: `url(${process.env.REACT_APP_API_URL}media/content/v2/${data?.bg_image_id}/thumb) no-repeat center center/cover`,
                                }}
                                data-bs-toggle='modal'
                                data-bs-target='#reviveModal'
                                onClick={() =>
                                  handleWorkshopModal(data?.workshop_event_id)
                                }
                              >
                                <div className='event-description'>
                                  <div className='event-title'>
                                    {data?.title}
                                  </div>
                                  <div className='instructor-container'>
                                    {data?.presenters?.map((el) => (
                                      <div className='instructor'>
                                        <img
                                          src={`${process.env.REACT_APP_API_URL}media/content/v2/${el?.image_id}/thumb`}
                                          alt='instructor-pic'
                                        />
                                      </div>
                                    ))}
                                  </div>
                                  <div className='event-date'>
                                    <span>
                                      <i className='bi bi-calendar3'></i>
                                      {data?.date}
                                    </span>
                                    <span className='separator'></span>
                                    <span>{data?.time}</span>
                                  </div>
                                  <div className='event-mode'>
                                    {data?.venue}
                                  </div>
                                  <div className='nps-scores'>
                                    <span>NPS :</span>
                                    {data?.nps?.map((el) => (
                                      <div className='nps-score'>{el}</div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <p className='center'>{noDataMsg}</p>
              )
            ) : (
              <div className='centered' style={{ marginLeft: '1rem' }}>
                <LoadingSpinner />
              </div>
            )}
          </div>
        </div>
      </div>
      {showModal && (
        <WorkshopModal
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          workshopId={workshopId}
        />
      )}
    </main>
  );
};

export default Workshops;
