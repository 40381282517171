import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import LoadingSpinner from '../../../../../../common/LoadingSpinner';
import VideoPlayer from '../../../../../../common/VideoPlayer';
import Series from './Components/Series';

const Favourite = (props) => {
  const [page, setPage] = useState(1);
  const [favPage, setFavPage] = useState(1);
  const [resultData, setResultData] = useState([]);
  const [data, setData] = useState('');
  const [unFavFlag, setUnFavFlag] = useState(false);
  const [videos, setVideos] = useState({
    isVisible: false,
    isLoading: false,
    seriesName: '',
    seriesDesc: '',
    seriesId: '',
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props?.activeFavSub]);

  const getVideosData = async (id, name, desc) => {
    try {
      setVideos({
        isVisible: !videos?.isVisible,
        isLoading: true,
        seriesName: name,
        seriesDesc: desc,
        seriesId: id,
      });
      const query = {
        rp_series_id: id,
        my_plan: 1,
        page: page,
      };
      const response = await window.$http.getWithQueryAndHeaders(
        'content',
        query
      );

      if (response.code === window.$constants.STATUS.OK) {
        if (response.data) {
          if (page > 1) {
            setResultData([...resultData, ...response?.data]);
          } else {
            setResultData((prev) => response?.data);
          }
          props.handleFavVideos('clicked');
        }
        setData(response);
      } else {
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (page > 1) {
      getVideosData(videos?.seriesId, videos?.seriesName, videos?.seriesDesc);
    }
  }, [page]);

  const loadMore = () => {
    const favePageNumber = unFavFlag ? favPage : favPage + 1;
    setFavPage(favePageNumber);
    props.handleFavPage(favePageNumber);
    props.handleUnfavFlag(unFavFlag);
    setUnFavFlag(false);
  };

  const loadVideosMore = () => {
    setPage(page + 1);
    setVideos({ ...videos, isVisible: false });
    setData('');
  };

  const unFavFun = (found) => {
    setUnFavFlag(found);
  };

  return (
    <div className='list'>
      <div className='row'>
        {!videos?.isVisible && !props?.isClicked ? (
          <>
            {(props?.activeFavSub === 'series' &&
              !isEmpty(props?.contentData)) ||
            (props?.activeFavSub === 'content' &&
              !isEmpty(props?.contentData)) ? (
              <div className='sries_title mb-4'>
                <h1 className='card-heading-sec-dark'>
                  {props?.activeFavSub === 'series'
                    ? props?.data?.data?.title
                    : props?.data?.metadata?.request?.title}
                </h1>

                <p>{props?.data?.data?.description}</p>
              </div>
            ) : (
              ''
            )}
            {props?.activeFavSub === 'series' ? (
              !isEmpty(props?.contentData) ? (
                <Series
                  contentData={props?.contentData}
                  getVideosData={getVideosData}
                />
              ) : (
                <div className='bg-white p-3 p-xl-5 box_shadow br-12 position-relative'>
                  <div
                    className='dots-styles d-none d-xl-block'
                    style={{ width: '25%' }}
                  ></div>
                  <h1 className='fs-2 lh-sm rp-primary-dark-g mb-3'>
                    {props?.data?.data?.message}
                  </h1>
                </div>
              )
            ) : !isEmpty(props?.contentData) ? (
              <>
                <VideoPlayer
                  resultData={props?.contentData}
                  value='favourite'
                  unFavFun={unFavFun}
                />
                {props.data ? (
                  props?.data?.metadata?.page_count > favPage ? (
                    <div className='lode-more text-center'>
                      <button className='lode-button' onClick={loadMore}>
                        <i className='fa-solid fa-circle-chevron-down fa-xl primary-color'></i>
                      </button>
                    </div>
                  ) : (
                    ''
                  )
                ) : (
                  <div className='centered' style={{ marginLeft: '1rem' }}>
                    <LoadingSpinner />
                  </div>
                )}
              </>
            ) : (
              <div className='bg-white p-3 p-xl-5 box_shadow br-12 position-relative'>
                <div
                  className='dots-styles d-none d-xl-block'
                  style={{ width: '25%' }}
                ></div>
                <h1 className='fs-2 lh-sm rp-primary-dark-g mb-3'>
                  {props?.data?.metadata?.request?.message}
                </h1>
              </div>
            )}
          </>
        ) : (
          ''
        )}
        <div
          className={
            videos?.isVisible && props?.isClicked ? 'row list' : 'row list hide'
          }
          id='cat_1'
        >
          <div className='sries_title mb-4'>
            <h1 className='card-heading-sec-dark'>{videos?.seriesName}</h1>

            <p>{videos?.seriesDesc}</p>
          </div>
          <VideoPlayer resultData={resultData} />
          {data ? (
            data?.metadata?.page_count > page ? (
              <div className='lode-more text-center'>
                <button className='lode-button' onClick={loadVideosMore}>
                  <i className='fa-solid fa-circle-chevron-down fa-xl primary-color'></i>
                </button>
              </div>
            ) : (
              ''
            )
          ) : (
            <div className='centered' style={{ marginLeft: '1rem' }}>
              <LoadingSpinner />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Favourite;
