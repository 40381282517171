import { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import RpLogo from '../../assets/images/rp-logo.png';
import { useDispatch, useSelector } from 'react-redux';
import { saveCompanyData } from '../../store/company-data/actions';
import Footer from './Footer';
import LoginWithOtp from './LoginWithOtp';
import Spinner from 'react-bootstrap/Spinner';
import ResiliencyModal from './Footer/Components/ResiliencyModal';
import { saveRevision } from '../../store/revision/actions';
import { mfaLogin } from '../../store/mfaAuth/actions';
import { login } from '../../store/auth/actions';
import queryString from 'query-string';
import Authentication from './Authentication';
import { save } from '../../store/user/actions';
import LoginBGImage from '../../assets/images/login-bg.webp';
import { isEmpty } from 'lodash';

const device_id = 'RP123456789';

const initialState = {
  showPassword: false,
  resultData: '',
  otpCode: '',
  otpValid: true,
};

const initialMsgState = {
  success: '',
  failure: '',
};

const Login = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [message, setMessage] = useState(initialMsgState);
  const [state, setState] = useState(initialState);
  const [currState, setCurrState] = useState({ email: '', password: '' });
  const [invalid, setInvalid] = useState({ email: false, password: false });
  const [showActive, setShowActive] = useState('Login');
  const [isLoading, setIsLoading] = useState();
  const [otpLoginState, setOtpLoginState] = useState();
  const [isButtonLoading, setIsButtonLoading] = useState({
    login: false,
    loginWithOtp: false,
    recoverPassword: false,
    verifyAuth: false,
  });
  const [showModal, setShowModal] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const location = useLocation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [rememberMeEnabled, setRememberMeEnabled] = useState(false);
  const [counter, setCounter] = useState();
  const [isResendClicked, setIsResendClicked] = useState(false);
  const [mfaValue, setMfaValue] = useState();

  const { redirectTo } = queryString.parse(location.search);
  const mfaHash = useSelector((state) => state?.user)?.mfa_hash;
  const mfa_hash = mfaHash ? mfaHash : '';
  const mfaToken = useSelector((state) => state?.mfaAuth)?.mfaToken;
  const { password_reset_hash } = useParams();
  const [loginResult, setLoginResult] = useState();
  const [isForgotLoading, setIsForgotLoading] = useState(false);
  const userState = useSelector((state) => state?.user);

  useEffect(() => {
    const validateSubdomain = async () => {
      setIsLoading(true);
      let hideLoader = true;
      var userAgent = navigator.userAgent;
      try {
        const result = await window.$http.getWithoutHeaders(
          'validate/subdomain'
        );

        if (result.code === window.$constants.STATUS.INVALID_SUBDOMAIN) {
          hideLoader = false;
        }

        if (result.code === window.$constants.STATUS.OK) {
          dispatch(saveCompanyData(result));
          setState({ ...state, resultData: result?.data });

          if (result?.data?.is_mobile_app_view) {
            if (result?.data?.is_forceful_redirect) {
              var link = 'https://rpi.page.link/welcome';
              if (userAgent.includes('Android')) {
                return window.location.assign(link);
              } else if (userAgent.includes('iPhone')) {
                return window.location.assign(link);
              } else if (userAgent.includes('iPad')) {
                return window.location.assign(link);
              } else {
                return navigate('/landing-page');
              }
            }
          }

          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
      if (hideLoader) {
        setIsLoading(false);
      }
    };

    validateSubdomain();
  }, []);

  useEffect(() => {
    if (props?.value === 'otp') {
      setShowActive('Otp');
    }
  }, [props]);

  useEffect(() => {
    if (isResendClicked || mfaValue) {
      const timer =
        counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [counter, isResendClicked, mfaValue]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let currentFormErrors = formErrors;
    setMessage(initialMsgState);

    switch (name) {
      case 'email':
        if (!value) {
          currentFormErrors[name] = 'Please enter email.';
        } else if (!global.emailRegex.test(value)) {
          currentFormErrors[name] = 'Please enter valid email.';
        } else {
          delete currentFormErrors[name];
          setInvalid({ ...invalid, email: false });
        }
        break;

      case 'password':
        if (!value) {
          currentFormErrors[name] = 'Please select date.';
        } else {
          delete currentFormErrors[name];
          setInvalid({ ...invalid, password: false });
        }
        break;

      default:
        currentFormErrors = 'All fields are required';
        break;
    }
    setCurrState({ ...currState, [name]: value });
    setFormErrors(currentFormErrors);
  };

  const handleLoginWithOtp = async () => {
    setIsSubmitting(true);
    if (!currState?.email) {
      setInvalid({ email: true, password: false });
      return setMessage({
        success: '',
        failure: 'Email is required.',
      });
    } else if (currState?.email && !global.emailRegex.test(currState?.email)) {
      setInvalid({ email: true, password: false });
      return setMessage({
        success: '',
        failure: 'Please enter a valid email.',
      });
    } else {
      setIsButtonLoading({ ...isButtonLoading, loginWithOtp: true });
      try {
        const result = await window.$http.postWithoutHeaders('loginwithotp', {
          email: currState?.email,
        });
        if (result.code === window.$constants.STATUS.OK) {
          setShowActive('Otp');
          setOtpLoginState(result?.data);
          setMessage({ success: result?.message, failure: '' });
          navigate('/login/');
        } else {
          setInvalid({ ...invalid, email: true });
          setMessage({ success: '', failure: result?.message });
        }
      } catch (error) {
        console.log(error);
        setMessage({ success: '', failure: 'Something went wrong!' });
      }
      setIsButtonLoading({ ...isButtonLoading, loginWithOtp: false });
    }
  };

  const callProfileData = async () => {
    try {
      const result = await window.$http.getWithHeaders('v2/my_profile');
      if (result.code === window.$constants.STATUS.OK) {
        dispatch(save(result));
        if (
          result?.data?.rp_gbl_company_type_id ===
          window.$enums.CompanyTypeId.CompanyAdmin
        ) {
          return navigate('/manage');
        } else if (result?.data?.visible_header_tabs?.includes('admin')) {
          return navigate('/manage');
        } else if (!result?.data?.is_web_enabled) {
          return navigate('/landing-page');
        } else {
          if (result?.data?.is_profile_completed === false) {
            if (result?.data?.onboarding_step === 0) {
              return navigate(
                redirectTo === undefined ? '/on-boarding' : `/on-boarding`
              );
            } else if (result?.data?.onboarding_step === 1) {
              return navigate(
                redirectTo === undefined
                  ? '/phone-verification'
                  : `/phone-verification`
              );
            } else {
              return navigate(
                redirectTo === undefined ? '/dashboard' : `${redirectTo}`
              );
            }
          } else {
            if (redirectTo === '/on-boarding') {
              return navigate('/dashboard');
            }
            if (
              result?.data?.landing_page === 0 ||
              result?.data?.landing_page === 1
            ) {
              return navigate(
                redirectTo === undefined ? '/dashboard' : `${redirectTo}`
              );
            } else if (result?.data?.landing_page === 2) {
              return navigate(
                redirectTo === undefined
                  ? '/dashboard/resiliency-plan'
                  : `${redirectTo}`
              );
            } else {
              return navigate(
                redirectTo === undefined
                  ? '/dashboard/assessments/result/0'
                  : `${redirectTo}`
              );
            }
          }
        }
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLogin = async () => {
    setIsSubmitting(true);
    if (!currState?.email && !currState?.password) {
      setInvalid({ email: true, password: true });
      return setMessage({
        success: '',
        failure: 'All fields are required.',
      });
    } else if (!currState?.email) {
      setInvalid({ ...invalid, email: true });
      return setMessage({
        success: '',
        failure: 'Email is required.',
      });
    } else if (currState?.email && !global.emailRegex.test(currState?.email)) {
      setInvalid({ ...invalid, email: true });
      return setMessage({
        success: '',
        failure: 'Please enter a valid email.',
      });
    } else if (!currState?.password) {
      setInvalid({ ...invalid, password: true });
      return setMessage({
        success: '',
        failure: 'Please enter password.',
      });
    } else {
      setIsButtonLoading({ ...isButtonLoading, login: true });
      try {
        const result = await window.$http.postWithoutHeaders('v2/login', {
          login_with_password: true,
          email: currState?.email,
          password: currState?.password,
          device_id,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          mfa_hash,
        });

        if (result.code === window.$constants.STATUS.OK) {
          setLoginResult(result?.data);
          setCounter(result?.data?.resent_timer);
          setState({ ...state, otpCode: '', otpValid: true });
          dispatch(saveRevision(result.revision));
          if (result?.data?.is_mfa_required) {
            setMfaValue(result?.data?.is_mfa_required);
            dispatch(mfaLogin(result));
            setShowActive('Authentication');
          } else {
            dispatch(login(result));
            callProfileData();
          }
        } else if (
          result.code === window.$constants.STATUS.UNAUTHORIZED ||
          window.$constants.STATUS.BAD_REQUEST
        ) {
          setInvalid({ email: true, password: true });
          setMessage({ success: '', failure: result.message });
        } else {
          setMessage({ success: '', failure: result.message });
        }
      } catch (error) {
        console.log(error);
        setMessage({ success: '', failure: 'Something went wrong!' });
      }
      setIsButtonLoading({ ...isButtonLoading, login: false });
    }
  };

  const handleKeypress = (e) => {
    if (e.key === 'Enter' || e.key === 'NumpadEnter') {
      if (currState?.password) {
        handleLogin();
      } else {
        handleLoginWithOtp();
      }
    }
  };

  const handleRecoverPassword = async () => {
    setMessage({ failure: '', success: '' });
    setIsForgotLoading(true);
    if (!currState?.email) {
      setInvalid({ ...invalid, email: true });
      return setMessage({ success: '', failure: 'Email is required.' });
    }
    if (currState?.email) {
      if (
        !currState?.email.match(
          /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+.+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        )
      ) {
        setInvalid({ ...invalid, email: true });
        return setMessage({
          success: '',
          failure: 'Please enter a valid email.',
        });
      }
    }
    setIsButtonLoading({ ...isButtonLoading, recoverPassword: true });
    try {
      const result = await window.$http.postWithoutHeaders('password', {
        email: currState?.email,
        device_id,
      });
      if (result.code === window.$constants.STATUS.OK) {
        setIsForgotLoading(false);
        setMessage({ failure: '', success: result?.message });
      } else {
        setIsForgotLoading(false);
        setInvalid({ ...invalid, email: false });
        setMessage({ failure: result?.message, success: '' });
      }
    } catch (error) {
      setIsForgotLoading(false);
      console.log(error);
      setMessage({ success: '', failure: 'Something went wrong!' });
    }
    setIsButtonLoading({ ...isButtonLoading, recoverPassword: false });
  };

  const handleBackToLogin = (value) => {
    if (value) {
      setState({ ...state, otpCode: '', otpValid: true });
      setCounter(loginResult?.resent_timer);
      setIsResendClicked(false);
      setMfaValue('');
    }
    setCurrState({ email: '', password: '' });
    setState({ ...state, showPassword: false });
    setShowActive('Login');
    handleMsg();
    navigate('/login');
    setInvalid({ email: false, password: false });
    setFormErrors({});
  };

  const handleMsg = () => {
    setMessage({ success: '', failure: '' });
  };

  const handleShowModal = (value) => {
    setShowModal(value);
  };
  const handleCloseModal = () => {
    setShowModal('');
  };

  const otpChangeHandler = (e) => {
    if (isSubmitting) {
      setMessage(initialMsgState);
      if (e.target.value.length === 7) return false;
      return setState({
        ...state,
        otpCode: e.target.value,
        otpValid: true,
      });
    } else {
      return setState({ ...state, otpCode: e.target.value });
    }
  };

  const handleVerifyCode = async () => {
    setIsSubmitting(true);

    if (!state?.otpCode) {
      setState({ ...state, otpValid: false });
      return setMessage({
        success: '',
        failure: 'Authentication Code is required.',
      });
    }
    setIsButtonLoading({ ...isButtonLoading, verifyAuth: true });
    try {
      const result = await window.$http.postWithHeaders('v2/mfa/verify', {
        otp_code: state?.otpCode,
        mfa_token: mfaToken,
        remember_me: rememberMeEnabled,
      });
      if (result.code === window.$constants.STATUS.OK) {
        dispatch(login(result));
        callProfileData();
      } else if (
        result?.code === window.$constants.STATUS.UNAUTHORIZED ||
        window.$constants.STATUS.BAD_REQUEST
      ) {
        setMessage({ success: '', failure: result?.message });
      } else {
        setMessage({ success: '', failure: result?.message });
      }
    } catch (error) {
      console.log(error);
      setMessage({ success: '', failure: 'Something went wrong!' });
    }
    setIsButtonLoading({ ...isButtonLoading, verifyAuth: false });
  };

  const handleRememberMeEnable = () => {
    setRememberMeEnabled(!rememberMeEnabled);
  };

  const handleResendCode = async () => {
    setCounter(loginResult?.resent_timer);
    try {
      const data = await window.$http.postWithHeaders('mfa/resend', {
        mfa_token: mfaToken,
      });

      if (data.code === window.$constants.STATUS.OK) {
        setMessage({ success: 'Authentication code sent.', failure: '' });
      } else {
        setMessage({
          success: '',
          failure: 'Some error occured. Please contact admin!',
        });
      }
    } catch (error) {
      console.log(error);
      setMessage({ success: '', failure: 'Something went wrong!' });
    }
  };

  const resendCode = () => {
    handleResendCode();
    setIsResendClicked(true);
  };

  return (
    <>
      {isLoading ? (
        <div className='preloader-dot' style={{ display: 'block' }}>
          <div className='dot'></div>
          <div className='dot'></div>
          <div className='dot'></div>
          <div className='dot'></div>
        </div>
      ) : (
        isLoading === false && (
          <main className='mt-0'>
            <div className='row m-0'>
              <div
                className={`col-lg-8 login-bg ${props.isVisible || showActive !== 'Login'
                  ? 'position-static'
                  : ''
                  }`}
                style={
                  state?.resultData?.login_image &&
                    !isEmpty(state?.resultData?.login_image) &&
                    state?.resultData?.login_image !== null
                    ? {
                      background: `url(${process.env.REACT_APP_API_URL}media/content/v2/${state?.resultData?.login_image}/thumb) no-repeat center center/cover`,
                    }
                    : { background: LoginBGImage }
                }
              ></div>
              <div
                className={`col-md-12 col-lg-4 login-container ${props.isVisible || showActive !== 'Login'
                  ? 'position-relative'
                  : ''
                  }`}
                style={
                  props.isVisible || showActive !== 'Login' ? { height: '650px' } :
                  (
                  showActive === 'Login' && window.innerHeight > 793
                    ? { height: '100%' }
                    : {}
                  )}
              >
                <div className='company-logo'>
                  <div className='rp-logo'>
                    <Link to='#!'>
                      <img src={RpLogo} alt='RP Logo' />
                    </Link>
                  </div>
                  <span className='separator'></span>
                  <div className='client-icon'>
                    <Link to='#!'>
                      <img
                        src={`${process.env.REACT_APP_API_URL
                          }media/company/${window.$utility.getSubDomain()}`}
                        alt='client-icon'
                      />
                    </Link>
                  </div>
                </div>
                {!props.isVisible ? (
                  <>
                    <div className='login-box'>
                      <div id='login_form'>
                        {showActive === 'Login' ? (
                          <>
                            <div className='wlcm-note mt-6'>
                              <h6>Welcome to Resiliency Program</h6>
                              <p
                                style={{
                                  visibility: `${state?.resultData ? 'visible' : 'hidden'
                                    }`,
                                }}
                              >
                                {
                                  state?.resultData?.welcome_message
                                }
                                {/* {window.$utility.getCmpTypeMsg(
                                  state?.resultData?.rp_gbl_company_type_id,
                                  'login'
                                )} */}
                              </p>
                            </div>
                            <div className='my-4'>
                              <div className='d-flex align-items-center position-relative'>
                                <i className='far fa-envelope position-absolute ms-2'></i>
                                <input
                                  type='email'
                                  name='email'
                                  className={
                                    formErrors?.email || invalid?.email
                                      ? 'form-control invalid'
                                      : 'form-control'
                                  }
                                  placeholder='Email'
                                  onChange={handleChange}
                                  value={currState?.email}
                                  onKeyPress={handleKeypress}
                                />
                              </div>
                            </div>
                            <div className='my-4'>
                              <div className='d-flex align-items-center position-relative'>
                                <i
                                  className='fas fa-lock position-absolute ms-2'
                                  aria-hidden='true'
                                ></i>
                                <input
                                  type={
                                    state?.showPassword ? 'text' : 'password'
                                  }
                                  className={
                                    formErrors?.password || invalid?.password
                                      ? 'form-control invalid'
                                      : 'form-control'
                                  }
                                  name='password'
                                  placeholder='Password'
                                  onChange={handleChange}
                                  value={currState?.password}
                                  onKeyPress={handleKeypress}
                                />
                                <div
                                  onClick={() =>
                                    setState({
                                      ...state,
                                      showPassword: !state?.showPassword,
                                    })
                                  }
                                >
                                  <i
                                    className={
                                      state?.showPassword
                                        ? 'bi bi-eye'
                                        : 'bi bi-eye-slash'
                                    }
                                    id='togglePassword'
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='my-2 text-end'>
                              <span
                                className='forgot-pw'
                                onClick={handleRecoverPassword}
                              >
                                {isForgotLoading ? (
                                  <Spinner
                                    as='i'
                                    animation='border'
                                    size='sm'
                                    role='status'
                                    aria-hidden='true'
                                  />
                                ) : (
                                  'Forgot/Create password?'
                                )}
                              </span>
                            </div>
                            <Link
                              to='#'
                              className={`btn loginpassword btn-login mt-2 ${isButtonLoading?.login ? 'disabled' : ''
                                }`}
                              onClick={handleLogin}
                            >
                              {isButtonLoading?.login ? (
                                <Spinner
                                  as='i'
                                  animation='border'
                                  size='sm'
                                  role='status'
                                  aria-hidden='true'
                                  style={{ color: '#fff' }}
                                />
                              ) : (
                                'Login'
                              )}
                            </Link>
                            <div className='login-type'>OR</div>
                            <button
                              type='button'
                              className={`btn loginotp btn-login ${isButtonLoading?.loginWithOtp ? 'disabled' : ''
                                }`}
                              onClick={handleLoginWithOtp}
                            >
                              {isButtonLoading?.loginWithOtp ? (
                                <Spinner
                                  as='i'
                                  animation='border'
                                  size='sm'
                                  role='status'
                                  aria-hidden='true'
                                  style={{ color: '#fff' }}
                                />
                              ) : (
                                'Login with Authentication Code'
                              )}
                            </button>
                          </>
                        ) : showActive === 'Otp' ? (
                          <LoginWithOtp
                            otpLoginData={otpLoginState}
                            handleOtp={handleBackToLogin}
                            email={currState?.email}
                            handleMsg={handleMsg}
                            password_reset_hash={password_reset_hash}
                          />
                        ) : (
                          showActive === 'Authentication' && (
                            <Authentication
                              otpCode={state?.otpCode}
                              otpValid={state?.otpValid}
                              mfaValue={mfaValue}
                              otpChangeHandler={otpChangeHandler}
                              handleVerifyCode={handleVerifyCode}
                              handleRememberMeEnable={handleRememberMeEnable}
                              resendCode={resendCode}
                              counter={counter}
                              isResendClicked={isResendClicked}
                              handleAuthRoute={handleBackToLogin}
                              isLoading={isButtonLoading?.verifyAuth}
                            />
                          )
                        )}
                      </div>
                      <div
                        className={`mt-4 fs-6 ${message?.success ? 'primary-color' : 'text-danger'
                          }`}
                        style={{
                          visibility: `${message?.failure || message?.success
                            ? 'visible'
                            : 'hidden'
                            }`,
                        }}
                      >
                        {message?.failure
                          ? message?.failure
                          : message?.success
                            ? message?.success
                            : 'message'}
                      </div>
                    </div>
                    {showActive === 'Login' ? (
                      <div className='email mt-5 text-center'>
                        <Link
                          to='#'
                          className='fw-bold'
                          style={{ fontSize: '15px' }}
                          onClick={() => handleShowModal('resiliency')}
                        >
                          Know more about Resiliency Program
                        </Link>
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  <>
                    <div className='login-box'>
                      <div className='forgot_field'>
                        <div className='wlcm-note mt-6'>
                          <h6>Forgot Password</h6>
                          <p>Please enter your email to reset the password</p>
                        </div>
                        <div className='my-4'>
                          <div className='d-flex align-items-center position-relative'>
                            <i className='far fa-envelope position-absolute ms-2' />
                            <input
                              type='email'
                              name='email'
                              className={
                                formErrors?.email || invalid?.email
                                  ? 'form-control invalid'
                                  : 'form-control'
                              }
                              placeholder='Email'
                              onChange={handleChange}
                              value={currState?.email}
                              onKeyPress={(e) =>
                                e.key === 'Enter' || e.key === 'NumpadEnter'
                                  ? handleRecoverPassword()
                                  : ''
                              }
                            />
                          </div>
                        </div>
                        <div className='bck-log text-end mb-3'>
                          <button onClick={handleBackToLogin}>
                            Back to login
                          </button>
                        </div>

                        <button
                          type='login'
                          className={`btn btn-login ${isButtonLoading?.recoverPassword ? 'disabled' : ''
                            }`}
                          onClick={handleRecoverPassword}
                        >
                          {isButtonLoading?.recoverPassword ? (
                            <Spinner
                              as='i'
                              animation='border'
                              size='sm'
                              role='status'
                              aria-hidden='true'
                              style={{ color: '#fff' }}
                            />
                          ) : (
                            'Reset Password'
                          )}
                        </button>
                      </div>
                      {message?.failure ? (
                        <p className='mt-4 text-danger fs-6'>
                          {' '}
                          {message?.failure}{' '}
                        </p>
                      ) : (
                        message?.success && (
                          <p className='primary-color mt-4 fs-6'>
                            {message?.success}
                          </p>
                        )
                      )}
                    </div>
                  </>
                )}

                {showModal === 'resiliency' ? (
                  <ResiliencyModal
                    showModal={showModal}
                    handleCloseModal={handleCloseModal}
                  />
                ) : (
                  ''
                )}
                <Footer
                  value={
                    props.isVisible || showActive !== 'Login' ? 'reset' : ''
                  }
                />
              </div>
            </div>
          </main>
        )
      )}
    </>
  );
};

export default Login;
