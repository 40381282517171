import React from 'react';
import ReactCardFlip from 'react-card-flip';
import CardData from './cardData.json';
import Supplements from './Supplements';
import clinicalImage from '../../../../../../assets/images/steve.png';

const NutritionalSupplements = () => {
  return (
    <>
      <div className='row justify-content-around list'>
        <h1 className='section-heading rp-primary-dark-g mb-5'>
          Resiliency Plan brings you a curated set of recommendations from
          industry’s leading experts.
        </h1>
        <div className='col-12'>
          <div className='doc-profile p-lg-5 p-3 br-12 box_shadow'>
            <div
              style={{ height: '100%', width: '35%' }}
              className='dots-styles d-none d-lg-block'
            ></div>
            <div className='col-12 col-xl-4 px-3'>
              <img src={clinicalImage} alt='' />
            </div>
            <div className='col-12 mt-4 mt-lg-0 col-xl-8 px-3 z-1'>
              <p className='p-text'>
                “I have been a physician and double board certified nutritionist
                utilizing nutritional supplements to help my patients achieve
                maximum health for over 30 years. I have found that the greatest
                success is achieved when nutritional supplements are utilized to
                treat the core health issues that cause physical and emotional
                symptoms rather than trying to simply treat the symptoms
                themselves. Utilizing nutritional supplements to treat the core
                health issues allows the mind & body to rebuild which allows for
                true healing, improved function and a higher quality of life.
                Good health is achieved when your mind & body is resilient to
                life's stressors not simply the absence of pain and symptoms.”
              </p>
              <br />
              <span className='rp-primary-dark-g doc-name'>
                Dr. Steven Zodkoy
              </span>
              <br />
              <span className='gradient-text '>
                world renowned chiropractor, nutritionist and kinesiologist
              </span>
            </div>
          </div>
        </div>
        <div className='col-md-12 mb-0 mb-lg-8'>
          <h2 className='section-sub-heading rp-primary-dark-g mb-0'>
            Nutritional supplements help the mind body rebuild from years of
            being overly stressed.
          </h2>
        </div>
        {CardData?.map((item, index) => (
          <Card cardData={item} key={`card-${index}`} />
        ))}
      </div>
      <Supplements />
    </>
  );
};

export default NutritionalSupplements;

const Card = ({ cardData }) => {
  return (
    <div className='col-md-4 card-4 d-none d-xl-block'>
      <ReactCardFlip flipDirection='horizontal'>
        <div className='about-card rp-plan-card' style={{ minHeight: '415px' }}>
          <div className='dots-styles'></div>
          <p className='sub-titel'>{cardData?.title}</p>

          {cardData.svg ? (
            <div className='mb-4'>
              <svg
                width={cardData?.svg?.width}
                height={cardData?.svg?.height}
                viewBox={cardData?.svg?.viewBox}
                fill={cardData?.svg?.fill}
                xmlns={cardData?.svg?.xmlns}
              >
                <path d={cardData?.path.d} fill={cardData?.path.fill}></path>
              </svg>
            </div>
          ) : (
            <div>
              <span>{cardData?.heading}</span>
            </div>
          )}

          <h6 className='about-sub-hed'>{cardData?.description}</h6>
        </div>

        <div className={cardData?.card_class}>
          <div className='dots-styles'></div>
          <p className='sub-titel'>{cardData?.title}</p>
          <h4 className='card-heading-light'>{cardData?.back_heading}</h4>
          <p>{cardData?.back_description}</p>
        </div>
      </ReactCardFlip>
    </div>
  );
};
