import React, { useState, useEffect } from 'react';
import Data from './cardData.json';
import Card from '../../card';
import LoadingSpinner from '../../../../../../common/LoadingSpinner';
import VideoPlayer from '../../../../../../common/VideoPlayer';
import Disclaimer from '../../../../../Login/Footer/Components/Disclaimer';
import ScheduleCalls from '../../../../../../common/Modals/ScheduleCalls';
import FoodAndDiet from './Components/FoodAndDiet';
import { useLocation } from 'react-router-dom';
import MobileApp from '../../../../../../common/Modals/MobileApp';
import { useSelector } from 'react-redux';

const Lifestyle = (props) => {
  const [result, setResult] = useState([]);
  const [data, setData] = useState('');
  const [page, setPage] = useState(1);
  const [showModal, setShowModal] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [dietResult, setDietResult] = useState([]);
  const [showPlan, setShowPlan] = useState(false);
  const [dietImageId, setDietImageId] = useState();
  const [contentCategoryId, setContentCategoryId] = useState('');
  const [firstLoad, setFirstLoad] = useState(false);
  const location = useLocation();
  const loc = location.pathname.split('/');
  const user = useSelector((state) => state?.user);

  useEffect(() => {
    if (props.showMoreVideos === false) {
      setPage(1);
      setData('');
    }
  }, [props.showMoreVideos]);

  const loadMore = () => {
    setPage(page + 1);
    props.handleCategoryVideos('clicked');
    getData(page + 1);
  };

  useEffect(() => {
    getData();
  }, [contentCategoryId]);

  useEffect(() => {
    setContentCategoryId(props?.rpContentCategoryId);
  }, [props?.rpContentCategoryId]);

  useEffect(() => {
    if (props.showActive === 'food-and-diet') {
      getFoodAndDietData();
    }
  }, [props.showActive]);

  const getData = async (myPage = 1) => {
    setIsLoading(true);
    if (myPage === 1) {
      setFirstLoad(true);
    }

    try {
      if (props.rpContentCategoryId) {
        const data = await window.$http.getWithHeaders(
          `content?rp_content_category_id=${props.rpContentCategoryId}&page=${myPage}`
        );

        if (data.code === window.$constants.STATUS.OK) {
          setIsLoading(false);
          setFirstLoad(false);
          if (myPage > 1) {
            setResult([...result, ...data?.data]);
          } else {
            if (props?.rpContentCategoryId === contentCategoryId) {
              setResult(data?.data);
            }
          }
          setData(data);
        } else {
          console.log('Something went wrong!');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getFoodAndDietData = async () => {
    setIsLoading(true);
    try {
      const data = await window.$http.getWithHeaders('food-diet');

      if (data.code === window.$constants.STATUS.OK) {
        setIsLoading(false);
        setDietResult(data?.data);
        setDietImageId(
          data?.data?.map((elem) => elem?.plans?.map((el) => el?.image_id))
        );
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseModal = () => {
    setShowModal('');
  };

  const showPlanPage = (value) => {
    setShowPlan(value);
    if (value) {
      props?.getListOpen(value);
    }
  };

  useEffect(() => {
    setShowPlan(props?.isListOpen);
  }, [props?.isListOpen]);

  return (
    <>
      <div className={props.showList === false ? 'row list' : 'row list hide'}>
        <div className='col-md-12 mb-4 mb-lg-8'>
          <h2 className='section-sub-heading rp-primary-dark-g'>
            Lifestyle changes are an excellent way to improve your resiliency.
            We recommend these through certain good habits, diet and exercise.
          </h2>
        </div>
        {Data?.map((item, index) => (
          <Card cardData={item} key={`card-${index}`} uniqueKey='lifestyle' />
        ))}

        <div className='mt-0 mt-lg-5'>
          <p className='fw-bold primary-color mb-2 text-decoration-underline fs-6'>
            Disclaimer
          </p>
          <span className='rp-primary-red-color fs-6'>
            The information provided by the instructor/presenter of the videos
            in respect of any exercises, mindfulness training, good habits,
            focus and concentration, removal of stress and anxiety etc.
          </span>
          <span
            className='primary-color text-decoration-underline cursor-p fs-6 ms-1'
            onClick={() => setShowModal('disclaimer')}
          >
            Read More
          </span>
        </div>
      </div>
      <div
        id={props.rpContentCategoryId}
        className={
          props.showActive === 'food-and-diet'
            ? `list plan_list ${props.showList ? '' : 'hide'}`
            : `list ${props.showList ? '' : 'hide'}`
        }
      >
        <div className='row'>
          {!showPlan && !loc[6] ? (
            <div className='sries_title mb-4'>
              <div className='d-flex'>
                <h1 className='card-heading-sec-dark'>{props.categoryTitle}</h1>
                {user?.is_food_plan_schedule_enabled ? (
                  <button
                    className='btn btn-rp-primary ms-3'
                    onClick={() => setShowModal('foodDiet')}
                    style={{ marginBottom: '15px' }}
                  >
                    My Plan
                  </button>
                ) : (
                  ''
                )}
              </div>
              <p>{props.categoryDesc}</p>
            </div>
          ) : (
            ''
          )}

          {result && !firstLoad ? (
            props.showActive === 'food-and-diet' ? (
              <FoodAndDiet
                dietResult={dietResult}
                showActive={props.showActive}
                showList={props.showList}
                showPlanPage={showPlanPage}
                categoryTitle={props.categoryTitle}
                categoryDesc={props.categoryDesc}
                showPlan={showPlan}
                handleShowList={props.handleShowList}
                dietImageId={dietImageId}
              />
            ) : (
              <VideoPlayer
                resultData={
                  props?.rpContentCategoryId === contentCategoryId ? result : []
                }
              />
            )
          ) : (
            <div className='centered' style={{ marginLeft: '1rem' }}>
              <LoadingSpinner />
            </div>
          )}
        </div>
      </div>

      {isLoading &&
      !firstLoad &&
      props.rpContentCategoryId &&
      data?.metadata?.page_count > 1 ? (
        <div className='centered' style={{ marginLeft: '1rem' }}>
          <LoadingSpinner />
        </div>
      ) : data &&
        data?.metadata?.page_count > page &&
        props.rpContentCategoryId &&
        props.showActive !== 'food-and-diet' &&
        !isLoading ? (
        <div className='lode-more text-center'>
          <button onClick={loadMore} className='lode-button'>
            <i className='fa-solid fa-circle-chevron-down fa-xl primary-color'></i>
          </button>
        </div>
      ) : (
        ''
      )}

      {showModal === 'disclaimer' && (
        <Disclaimer showModal={showModal} handleCloseModal={handleCloseModal} />
      )}
      {showModal === 'schedule' && (
        <ScheduleCalls
          showModal={showModal}
          handleCloseModal={handleCloseModal}
        />
      )}

      {showModal === 'foodDiet' ? (
        <MobileApp
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          heading='My Plan'
        />
      ) : (
        ''
      )}
    </>
  );
};

export default Lifestyle;
