import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import LoadingSpinner from '../../../../../../../common/LoadingSpinner';
import VideoPlayer from '../../../../../../../common/VideoPlayer';
import { Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import Iframe from 'react-iframe-click';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AuthenticatedDailyLinks = (props) => {
  const navigate = useNavigate();
  const [resultData, setResultData] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [contentData, setContentData] = useState();
  const [data, setData] = useState('');
  const [showBox, setShowBox] = useState(false);
  const [showListVideos, setShowListVideos] = useState(false);
  const [seriesData, setSeriesData] = useState({});
  const [mySeries, setMySeries] = useState([]);
  const [seriesName, setSeriesName] = useState('');
  const [seriesDesc, setSeriesDesc] = useState('');
  const [seriesId, setSeriesId] = useState('');
  const [forceUpdate, setForceUpdate] = useState(0);
  const [notif, setNotif] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    getSeries();
  }, []);

  const videoAudit = async (rp_content_category_id, rp_content_id) => {
    try {
      const result = await window.$http.getWithHeaders(
        `/content/playaudit/${
          rp_content_category_id ? rp_content_category_id : 0
        }/${rp_content_id}`
      );
      if (result.code === window.$constants.STATUS.OK) {
        console.log('success');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSeries = async () => {
    setIsLoading(true);
    try {
      const result = await window.$http.getWithHeaders('my/series');
      if (result.code === window.$constants.STATUS.OK) {
        setIsLoading(false);
        if (
          result?.data &&
          result?.data?.series &&
          result?.data?.series.length > 0
        ) {
          setSeriesData(result?.data);
          setMySeries(result?.data?.series);
        } else {
          setShowBox(true);
        }
      } else {
        console.log(result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getLikedVideos = (value) => {
    if (value) {
      loadVideos(seriesId, seriesName, seriesDesc);
    }
  };

  useEffect(() => {
    if (props.isClicked === false) {
      setShowListVideos(false);
      setPage(1);
      setSeriesId('');
      setSeriesName('');
      setSeriesDesc('');
    }
  }, [props.isClicked]);

  useEffect(() => {
    if (page > 1) {
      loadVideos(seriesId, seriesName, seriesDesc);
    }
  }, [page]);

  const loadVideos = async (id, name, desc) => {
    try {
      setIsLoading(true);
      setSeriesName(name);
      setSeriesDesc(desc);
      setSeriesId(id);
      const query = {
        rp_series_id: id,
        my_plan: 1,
        page: page,
      };
      const response = await window.$http.getWithQueryAndHeaders(
        'content',
        query
      );

      if (response.code === window.$constants.STATUS.OK) {
        if (response.data) {
          if (page > 1) {
            setResultData([...resultData, ...response?.data]);
          } else {
            setResultData((prev) => response?.data);
          }
          props.handleDailyLinkVideos('clicked');
        } else {
          setShowBox(true);
        }
        setData(response);
        setErrorMsg('');
      } else {
        console.log(response);
      }
    } catch (error) {
      setErrorMsg('Error while loading data. Try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isEmpty(props.id)) {
      const getContentData = async () => {
        try {
          const result = await window.$http.getWithHeaders(
            `content/assmt/${props.id}`
          );
          if (result.code === window.$constants.STATUS.OK) {
            setContentData(result.data);
            setMessage(result?.data?.message);
          } else {
            console.log(result);
          }
        } catch (error) {
          console.log(error);
        }
      };

      getContentData();
    }
  }, [props.id]);

  const loadMore = () => {
    setPage((page) => page + 1);
  };

  const handleRpCloseModal = () => {
    setContentData('');
    navigate('/dashboard/resiliency-plan/dailylinks');
  };

  const likeSeries = async (id) => {
    try {
      const result = await window.$http.postWithHeaders('favourite/series', {
        series_id: id,
      });
      if (result.code === window.$constants.STATUS.OK) {
        console.log('success');
        toast?.success(result?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function handleLike(seriesId, favourite) {
    setNotif('showNotification');
    let seriesArray = seriesData?.series;
    if (seriesArray.length) {
      let index = seriesArray?.findIndex((el) => el?.series_id === seriesId);

      seriesArray[index].is_favourite = !favourite;
      setMySeries(seriesArray);
      setForceUpdate((forceUpdate) => forceUpdate + 1);
    }
    likeSeries(seriesId);
  }

  return (
    <>
      {contentData && contentData !== undefined ? (
        <Modal
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered
          show={true}
        >
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='staticBackdropLabel'>
                {contentData?.content_title}
              </h5>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={handleRpCloseModal}
              ></button>
            </div>
            <div
              className='modal-body p-0'
              onClick={() =>
                videoAudit(
                  contentData?.rp_content_category_id,
                  contentData?.rp_content_id
                )
              }
            >
              <Iframe
                width='100%'
                height='450'
                src={
                  contentData?.rp_content_source_id ===
                  window.$enums.Source.Youtube
                    ? `https://www.youtube-nocookie.com/embed/${contentData?.content_id}`
                    : contentData?.rp_content_source_id ===
                      window.$enums.Source.Vimeo
                    ? `https://player.vimeo.com/video/${contentData?.content_id}`
                    : ''
                }
                title={contentData?.content_filename}
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
                onInferredClick={() =>
                  videoAudit(
                    contentData?.rp_content_category_id,
                    contentData?.rp_content_id
                  )
                }
              ></Iframe>
              <div className='video-info px-3 pt-3'>
                {contentData?.content_description ? (
                  <div className='video-discription mb-2'>
                    {contentData?.content_description}
                  </div>
                ) : (
                  ''
                )}
                {contentData?.content_author ? (
                  <div className='video-author'>
                    <span className='fw-bold rp-primary-dark-gray'>
                      Content Author:
                    </span>{' '}
                    {contentData?.content_author?.replace(/[{}""]/g, '')}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </Modal>
      ) : (
        ''
      )}
      <div
        className={
          showListVideos === false && props.isClicked === false
            ? 'row p-0 m-0'
            : 'row p-0 m-0 hide'
        }
      >
        {Object.keys(seriesData)?.length !== 0 ? (
          <div className='sries_title mb-4'>
            <h1 className='card-heading-sec-dark'>{seriesData?.title}</h1>
            <p>{seriesData?.description}</p>
          </div>
        ) : (
          ''
        )}
        {mySeries?.map((data, id) => (
          <div className='col-md-4 mb-5' key={id}>
            <div
              id='jqmainwraper_1'
              className='jqmainwraper_1 video-card good_habit-placeholder position-relative'
              style={{
                background: `url(${process.env.REACT_APP_API_URL}media/content/v2/${data?.series_image_id}/thumb/0 ) no-repeat center center/cover`,
              }}
            >
              <Link
                className='link_overlay'
                to='/dashboard/resiliency-plan/dailylinks'
                onClick={() =>
                  loadVideos(
                    data?.series_id,
                    data?.series_name,
                    data?.series_description
                  ) && setShowListVideos(!showListVideos)
                }
              ></Link>
            </div>
            <div className='video-card-discription'>
              <div className='music-titel'>
                {data?.series_name?.length < 40 ? (
                  <h6>{data?.series_name}</h6>
                ) : (
                  <Tooltip title={data?.series_name} placement='bottomRight'>
                    <h6>
                      {data?.series_name?.slice(0, 40)}
                      <span
                        style={{
                          color: '#1a1a1a',
                        }}
                      >
                        ...
                      </span>
                    </h6>
                  </Tooltip>
                )}
                {data?.series_description?.length < 70 ? (
                  <p>{data?.series_description}</p>
                ) : (
                  <Tooltip
                    title={data?.series_description}
                    placement='bottomRight'
                  >
                    <p>
                      {data?.series_description?.slice(0, 70)}
                      <span
                        style={{
                          color: 'rgba(44, 65, 67, 0.5)',
                        }}
                      >
                        ...
                      </span>
                    </p>
                  </Tooltip>
                )}
              </div>

              <div className='cnt-source mb-2'>
                {data?.videos_count}
                <span
                  className={data?.is_favourite ? 'like-btn liked' : 'like-btn'}
                  onClick={() =>
                    handleLike(data?.series_id, data?.is_favourite)
                  }
                ></span>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div
        className={
          showListVideos && props.isClicked ? 'row list' : 'row list hide'
        }
        id='cat_1'
      >
        <div className='sries_title mb-4'>
          <h1 className='card-heading-sec-dark'>{seriesName}</h1>
          <p>{seriesDesc}</p>
        </div>
        <VideoPlayer
          resultData={resultData}
          getLikedVideos={getLikedVideos}
          notif={notif}
        />
      </div>
      {showBox && (
        <>
          <div className='bg-white p-3 p-xl-5 box_shadow br-12 position-relative'>
            <div
              className='dots-styles d-none d-xl-block'
              style={{ width: '25%' }}
            ></div>
            <h1 className='fs-2 lh-sm rp-primary-dark-g mb-3'>
              Thanks for completing your assessment and taking the most
              important step towards your Resiliency.
            </h1>

            <h2 className='fs-5 rp-primary-dark-g mb-4'>
              Our team has carefully curated content for you. This content will
              be delivered to your email everyday morning starting tomorrow. We
              keep the content length small so that it's easy for you to consume
              and follow.{' '}
            </h2>

            <h2 className='fs-3 lh-sm rp-primary-dark-g mb-5'>
              The content sent to your email will also be available on your
              Daily Links tab from tomorrow.
            </h2>
            <p className='rp-primary-dark-g fs-5  text-start fw-bold mb-4'>
              Please follow your personalized Resiliency Plan to have full
              access to recommendations on
            </p>

            <ul className='text-start list-disc'>
              <li className='mb-2'>
                <span
                  className='primary-color cursor-p'
                  onClick={() =>
                    navigate('/dashboard/resiliency-plan/lifestyle')
                  }
                >
                  Lifestyle Changes
                </span>
              </li>
              <li className=' mb-2 '>
                <span
                  className='primary-color cursor-p'
                  onClick={() =>
                    navigate(
                      '/dashboard/resiliency-plan/nutritional-supplements'
                    )
                  }
                >
                  Nutritional Supplements
                </span>
              </li>
              <li>
                <span
                  className='primary-color cursor-p'
                  onClick={() =>
                    navigate('/dashboard/resiliency-plan/mindfulness-training')
                  }
                >
                  Mindfulness Training
                </span>
              </li>
            </ul>
          </div>
        </>
      )}
      <div className='lode-more text-center'>
        {errorMsg && <p className='centered'>{errorMsg}</p>}
        {isLoading ? (
          <div className='centered' style={{ marginLeft: '1rem' }}>
            <LoadingSpinner />
          </div>
        ) : data && data?.metadata?.page_count > page && !isLoading ? (
          <button onClick={loadMore} className='lode-button'>
            <i className='fa-solid fa-circle-chevron-down fa-xl primary-color'></i>
          </button>
        ) : (
          ''
        )}
      </div>
      <ToastContainer />
    </>
  );
};

export default AuthenticatedDailyLinks;
