import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { save } from '../../store/user/actions';
import Spinner from 'react-bootstrap/Spinner';

const MobileVerification = (props) => {
  const userCountry = useSelector((state) => state?.user?.rp_gbl_country_id);
  const is_company_mfa_enabled = useSelector(
    (state) => state?.user?.is_company_mfa_enabled
  );
  const user = useSelector((state) => state?.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [countryCode, setCountryCode] = useState('');
  const [mobileNo, setMobileNo] = useState('');
  const [counter, setCounter] = useState(30);
  const [button, setButton] = useState('send');
  const [buttonClicked, setButtonClicked] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);
  const [mfaEnabled, setMfaEnabled] = useState(false);
  const [state, setState] = useState({
    success: '',
    error: '',
    value: '',
  });
  const [otpCode, setOtpCode] = useState({
    value: '',
    valid: '',
  });
  const [currState, setCurrState] = useState({
    loading: '',
    valid: '',
  });
  // const phoneRegex = /^[0-9\b]+$/;
  const phoneRegex = /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/;
  const [codeStatus, setCodeStatus] = useState();
  const [isOtpLoading, setIsOtpLoading] = useState(false);

  useEffect(() => {
    getCountryOptions();
    if (user?.is_user_mfa_enabled === true) {
      setMfaEnabled(true);
    } else {
      setMfaEnabled(false);
    }
  }, []);

  useEffect(() => {
    handleFooter();
  }, [window.innerWidth]);

  const handleFooter = () => {
    if (window.innerWidth < 768) {
      props.otpCodeValid(true);
    } else {
      props.otpCodeValid('');
    }
  };

  useEffect(() => {
    if (props.isClicked === 'MFA') {
      setButton('change');
      setMobileNo(user?.mobile_no);
      setCountryCode(user?.mobile_country_code);
      setState({
        success: '',
        error: '',
        value: '',
      });
      setOtpCode({
        value: '',
        valid: '',
      });
      setCurrState({
        loading: '',
        valid: '',
      });
    }
  }, [props.isClicked]);

  useEffect(() => {
    if (otpCode?.valid !== '') {
      const timer =
        counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [counter, otpCode?.valid]);

  useEffect(() => {
    if (user.is_mobile_verified) {
      setButton('change');
    }
  }, [user.is_mobile_verified]);

  const handleSendOtp = async () => {
    setCodeStatus('');
    if (mobileNo === '') {
      return setState({
        success: '',
        error: 'Mobile number required',
        value: 'mobile',
      });
    }
    if (isNaN(mobileNo)) {
      return setState({
        success: '',
        error: 'Please enter valid mobile number',
        value: 'mobile',
      });
    }
    if (mobileNo.length < 10) {
      return setState({
        success: '',
        error: 'Mobile number must be 10 digit',
        value: 'mobile',
      });
    }
    if (!phoneRegex.test(mobileNo)) {
      return setState({
        success: '',
        error: 'Please enter valid mobile number',
        value: 'mobile',
      });
    }
    setCounter(30);
    setState({ success: '', error: '' });
    setIsOtpLoading(true);
    try {
      const result = await window.$http.postWithHeaders('phone/sendotp', {
        mobile_country_code: countryCode,
        mobile_no: mobileNo,
      });
      if (result.code === window.$constants.STATUS.OK) {
        setIsOtpLoading(false);
        handleFooter();

        setOtpCode({
          value: '',
          valid: true,
        });
        setButton('change');
        setCodeStatus('verified');
        setState({
          success: 'Authentication code sent.',
          error: '',
          value: '',
        });
      } else if (result.code === window.$constants.STATUS.BAD_REQUEST) {
        setIsOtpLoading(false);
        if (props.value === 'Setting') {
          setButton('change');
        }
        setCodeStatus('');
        return setState({
          success: '',
          error: result?.message
            ? result?.message
            : 'Some error occured. Please contact admin!',
          value: 'otp',
        });
      } else {
        setIsOtpLoading(false);
        if (props.value === 'Setting') {
          setButton('change');
        }
        setCodeStatus('');
        return setState({
          success: '',
          error: result?.message
            ? result?.message
            : 'Some error occured. Please contact admin!',
          value: 'otp',
        });
      }
    } catch (error) {
      setIsOtpLoading(false);
      console.log(error);
    }
  };

  const handleChangeNumber = () => {
    setCodeStatus('');
    if (button === 'send') {
      setButtonClicked(!buttonClicked);
    }

    setButton('send');
    setMobileNo('');
    setOtpCode({ ...otpCode, value: '' });
    setState({
      success: '',
      error: '',
      value: '',
    });
  };

  const handleVerifyOtp = async () => {
    setState({});
    setCurrState({ ...currState, loading: true });
    if (otpCode?.value === '') {
      setCurrState({ ...currState, loading: '' });
      return setState({
        success: '',
        error: 'Authentication code required',
        value: 'otp',
      });
    }
    try {
      const result = await window.$http.postWithHeaders('phone/verifyotp', {
        mobile_country_code: countryCode,
        mobile_no: mobileNo,
        otp_code: otpCode.value,
      });

      if (result.code === window.$constants.STATUS.OK) {
        saveProfileData();
        setCurrState({ loading: false, valid: true });
        props.value === 'Setting' &&
          setOtpCode({
            ...otpCode,
            valid: false,
          });
        setState({
          error: '',
          success: 'Your number has been verified',
          value: '',
        });
        if (props.value === 'Onboarding') {
          return setTimeout(() => {
            dispatch(save(result));
            if (result?.data?.visible_header_tabs?.includes('admin')) {
              return navigate('/manage');
            } else {
              navigate('/dashboard');
            }
          }, 3000);
        } else {
        }
      } else {
        setCurrState({ loading: '', valid: false });
        return setState({
          success: '',
          error:
            'Authentication code entered is not correct. Please enter the correct code.',
          value: 'otp',
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCountryOptions = async () => {
    try {
      const result = await window.$http.getWithHeaders('country');
      if (result.code === window.$constants.STATUS.OK) {
        setCountryOptions(result?.data);
        user?.mobile_country_code
          ? setCountryCode(
              result?.data?.find(
                (el) => el?.country_phone_code === user?.mobile_country_code
              )?.country_phone_code
            )
          : setCountryCode(
              result?.data.find((el) => el?.rp_gbl_country_id === userCountry)
                ?.country_phone_code
            );
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    if (e.target.value?.length === 7) return false;
    setOtpCode({
      ...otpCode,
      value: e.target.value,
    });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    if (name === 'countryCode') {
      setCountryCode(value);
    } else if (name === 'mobileNo') {
      setMobileNo(value);
    }
  };

  const saveProfileData = async () => {
    try {
      const result = await window.$http.getWithHeaders('v2/my_profile');
      if (result.code === window.$constants.STATUS.OK) {
        dispatch(save(result));
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleMfaEnable = async () => {
    setMfaEnabled(!mfaEnabled);

    try {
      const data = await window.$http.postWithHeaders('mfa/status', {
        is_mfa_enable: !mfaEnabled,
      });

      if (data.code === window.$constants.STATUS.OK) {
        saveProfileData();
        setState({
          success: data?.message,
          error: '',
          value: '',
        });
      } else {
        setState({
          success: '',
          error: data?.message,
          value: '',
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className='col-md-12 mt-5'>
        <div className='row'>
          <div className='col-xl-7 d-block d-xl-flex'>
            <div className='col-xl-3 col-12 mb-3 mb-xl-0'>
              <label className='label-mb'>Country Code</label>
              <select
                className='form-select form-control country-code me-3'
                aria-label='Default select example'
                value={
                  props.value === 'Setting'
                    ? button === 'change'
                      ? countryCode
                        ? countryCode
                        : user?.mobile_country_code
                      : button === 'send'
                      ? countryCode
                      : ''
                    : countryCode
                }
                name='countryCode'
                onChange={handleInput}
                onBlur={() => props.newFooterState('')}
                onClick={() =>
                  window.innerWidth < 768 && props.newFooterState('footer')
                }
                disabled={button === 'change' && !buttonClicked}
              >
                {countryOptions?.length
                  ? countryOptions?.map((data, id) =>
                      data?.is_available_for_mobile_verification ? (
                        <option value={data?.country_phone_code} key={id}>
                          {data?.iso_code_2} {data?.country_phone_code}
                        </option>
                      ) : (
                        ''
                      )
                    )
                  : ''}
              </select>
            </div>
            <div className='col-xl-9 col-12 ms-0 ms-xl-3'>
              <label>
                Mobile number should not include{' '}
                <span className='fw-bold'>"-, # &amp; space"</span>
              </label>
              <input
                type='text'
                maxLength='10'
                pattern='[0-9]*'
                className={
                  state?.value === 'mobile' || state?.error
                    ? 'form-control me-4 invalid'
                    : 'form-control me-4'
                }
                placeholder=''
                aria-label='Mobile Number'
                value={
                  props.value === 'Setting'
                    ? button === 'change'
                      ? mobileNo
                        ? mobileNo
                        : user?.mobile_no
                      : button === 'send'
                      ? mobileNo
                      : ''
                    : mobileNo
                }
                name='mobileNo'
                onChange={handleInput}
                onClick={() =>
                  window.innerWidth < 768 && props.newFooterState('footer')
                }
                onBlur={() => props.newFooterState('')}
                onKeyPress={(e) =>
                  e.key === 'Enter' || e.key === 'NumpadEnter'
                    ? handleSendOtp()
                    : ''
                }
                disabled={button === 'change' && !buttonClicked}
              />
            </div>
          </div>
          <div className='col-12 col-xl-4 auth-btn'>
            <button
              className='btn btn-rp-primary'
              onClick={button === 'send' ? handleSendOtp : handleChangeNumber}
            >
              {button === 'send' ? (
                isOtpLoading ? (
                  <Spinner
                    as='i'
                    animation='border'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                ) : (
                  'Send Authentication Code'
                )
              ) : (
                'Change Number'
              )}
            </button>
          </div>
        </div>
      </div>

      {otpCode?.valid ? (
        <>
          {codeStatus ? (
            <>
              <div className='col-md-12 col-lg-5 mt-4 mb-4'>
                <label>Enter authentication code</label>
                <div className='d-flex align-items-center'>
                  <input
                    type='number'
                    maxLength='6'
                    max='6'
                    className={
                      state?.value === 'otp'
                        ? 'form-control me-4 invalid'
                        : 'form-control me-4'
                    }
                    aria-label='Enter OTP'
                    value={otpCode?.value}
                    onChange={handleChange}
                    onKeyDown={(evt) =>
                      ['e', '+', '-'].includes(evt.key) && evt.preventDefault()
                    }
                    onKeyPress={(e) =>
                      e.key === 'Enter' || e.key === 'NumpadEnter'
                        ? handleVerifyOtp()
                        : ''
                    }
                  />
                  <button
                    className='btn btn-rp-primary align-items-center d-flex justify-content-center'
                    onClick={handleVerifyOtp}
                  >
                    {currState?.loading === true && (
                      <i className='fa-solid fa-spinner fa-spin-pulse me-2'></i>
                    )}
                    Verify
                    {currState?.loading === false && (
                      <i className='fa-solid fa-check ms-2'></i>
                    )}
                    {currState?.loading === '' && ''}
                  </button>
                </div>
                <div className='primary-color mt-3'>{otpCode?.msg}</div>
              </div>
              <div className='col-md-12 mb-4'>
                <div className='col-md-12 auth-massage_div mb-4'>
                  <span className='auth-massage'>
                    Not recieved your authentication code?
                  </span>{' '}
                  <div className='mt-2 mt-xl-0'>
                    <button
                      className={
                        counter === 0
                          ? 'btn primary-color me-4'
                          : 'btn primary-color me-4 disabled'
                      }
                      onClick={handleSendOtp}
                    >
                      Resend
                    </button>
                    {counter > 0 ? (
                      <span className='rp-primary-red-color'>
                        <span className='rp-primary-red-color' id='time'>
                          {counter}
                        </span>{' '}
                        Sec.
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}
      {props.value === 'Setting' && is_company_mfa_enabled ? (
        <div className='form-check form-switch my-5'>
          <input
            className='form-check-input'
            type='checkbox'
            value={mfaEnabled}
            role='switch'
            id='flexSwitchCheckDefault'
            onClick={handleMfaEnable}
            disabled={!user.is_mobile_verified || otpCode?.valid}
            checked={mfaEnabled ? 'checked' : ''}
          />
          <label
            className='form-check-label fs-6'
            style={{ cursor: 'default' }}
            htmlFor='flexSwitchCheckDefault'
          >
            Enable Two Step Verification
          </label>
        </div>
      ) : (
        ''
      )}

      {state?.error ? (
        <p className='rp-primary-red-color mt-3 fw-bold'> {state?.error} </p>
      ) : (
        state?.success && (
          <p className='primary-color fs-6 mt-3 fw-bold'>{state?.success}</p>
        )
      )}
    </>
  );
};

export default MobileVerification;
