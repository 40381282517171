import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ManageGroupForm from './Components/ManageGroupForm';
import { Popconfirm } from 'antd';
import { usePagination, DOTS } from '../../../hooks/Pagination/usePagination';
import { isEmpty } from 'lodash';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const text = 'Are you sure you want to delete?';

const InitialItemState = {
  rp_group_id: '',
  parentGroupName: '',
  groupName: '',
  groupId: '',
};

const ManageGroup = () => {
  const companyState = useSelector((state) => state.companyData);
  const [resultData, setResultData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [items, setItems] = useState(InitialItemState);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [formErrors, setFormErrors] = useState({});
  const [msg, setMsg] = useState('');
  const [groupOptions, setGroupOptions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [clickList, setClickList] = useState([]);
  const [buttonState, setButtonState] = useState({
    value: '',
    isLoading: false,
  });
  const [group, setGroup] = useState({
    parentGroupName: '',
    groupName: '',
    groupId: '',
  });
  const [rp_parent_id, setrp_parent_id] = useState('');
  const [parentId, setParentId] = useState('');
  const [isActive, setIsActive] = useState('');
  const [activeValue, setActiveValue] = useState('');
  const [isSubmit, setIsSubmit] = useState(false);
  const siblingCount = 1;
  const paginationRange = usePagination({
    page,
    pageCount,
    siblingCount,
  });
  const tableId = document.getElementById('table');
  const columnLength = tableId?.rows[0]?.cells?.length;
  const [searchPageCount, setSearchPageCount] = useState(1);
  const [isEnterClicked, setIsEnterClicked] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [metaData, setMetaData] = useState(false);
  const userState = useSelector((state) => state?.user);
  const companyTypeId = useSelector((state) => state?.companyTypeId?.payload);
  const [styleChange, setStyleChange] = useState(false);

  useEffect(() => {
    getGroupOptions();
  }, [companyTypeId]);

  function queryParamBuilder() {
    let query;
    if (
      userState?.rp_gbl_company_type_id ===
        window.$enums.CompanyTypeId.CompanyAdmin &&
      companyTypeId?.rp_gbl_company_type_id
    ) {
      query = {
        rp_company_id: window.$utility.setCompanyId(userState, companyTypeId),
      };
    }
    return query;
  }

  useEffect(() => {
    if (page && searchValue && isEnterClicked) {
      return getGroupData(searchValue);
    } else {
      return getGroupData();
    }
  }, [clickList?.length, isEnterClicked, companyTypeId]);

  const getGroupOptions = async () => {
    try {
      const query = queryParamBuilder();
      const result = await window.$http.getWithQueryAndHeaders(
        `${window.$utility.setApiUrl(userState)}groups`,
        query
      );
      if (result.code === window.$constants.STATUS.OK) {
        setGroupOptions(result?.data);
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (items) {
      setGroup({
        parentGroupName: items?.parentGroupName,
        groupName: items?.groupName,
        groupId: items?.groupId,
      });
    }
    if (parentId === 0) {
      setrp_parent_id('');
    } else {
      setrp_parent_id(parentId);
    }
    if (activeValue.toString() === 'false') {
      setIsActive(activeValue);
    } else {
      setIsActive('Yes');
    }
  }, [items, activeValue, parentId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let currentFormErrors = formErrors;
    setMsg('');
    switch (name) {
      case 'groupName':
        if (!value) {
          currentFormErrors[name] = 'Please Enter Group Name';
        } else {
          delete currentFormErrors[name];
        }
        break;
      case 'groupId':
        if (!value) {
          currentFormErrors[name] = 'Please Enter Group Id';
        } else {
          delete currentFormErrors[name];
        }
        break;
      default:
        currentFormErrors = 'All fields are required';
        break;
    }
    setGroup({ ...group, [name]: value });
    setFormErrors(currentFormErrors);
  };

  const handleParentState = (e) => {
    setrp_parent_id(e.target.value);
  };

  const handleActiveState = (e) => {
    setIsActive(e.target.value);
  };

  const handleItems = () => {
    setItems({});
    setParentId('');
    setActiveValue('');
    setMsg('');
    setFormErrors({});
    setShowModal(true);
    setIsSubmit(false);
  };

  const handleEditedItems = () => {
    setMsg('');
    setFormErrors({});
    setShowModal(true);
    setIsSubmit(false);
  };

  const pageLoadNext = (e) => {
    if (!metaData?.request?.q) {
      setIsEnterClicked(false);
    }
    clickList.push(e);
    setClickList(clickList);
    if (page < pageCount) {
      return setPage(page + 1);
    } else {
      return setPage(pageCount);
    }
  };

  const pageLoadPrev = (e) => {
    if (!metaData?.request?.q) {
      setIsEnterClicked(false);
    }
    clickList.push(e);
    setClickList(clickList);
    if (page > 1) {
      setPage(page - 1);
    } else {
      return;
    }
  };

  const handlePageNo = (e, number) => {
    if (!metaData?.request?.q) {
      setIsEnterClicked(false);
    }
    clickList.push(e);
    setClickList(clickList);
    setPage(number);
  };

  const saveGroup = async (id) => {
    if (!group.groupId) {
      return setIsSubmit(true);
    } else if (!group.groupName) {
      return setIsSubmit(true);
    } else {
      setButtonState({ value: 'saveGroup', isLoading: true });
      try {
        const query = queryParamBuilder();
        if (!id) {
          const result = await window.$http.postWithQueryAndHeaders(
            `${window.$utility.setApiUrl(userState)}group/${0}`,
            query,
            {
              rp_parent_id: rp_parent_id,
              group_name: group.groupName,
              group_id: group.groupId,
              is_active: isActive,
            }
          );

          if (result.code === window.$constants.STATUS.CONFLICT) {
            setMsg(result?.message);
          } else {
            setMsg('');
          }
          if (result.code === window.$constants.STATUS.OK) {
            handleModal();
            getGroupData();
            toast.success(result?.message);
          } else {
            console.log('Something went wrong!');
          }
        }
      } catch (err) {
        console.log(err);
      }
      setButtonState({ value: 'saveGroup', isLoading: false });
    }
  };

  const updateGroup = async (id) => {
    if (!group.groupId) {
      return setIsSubmit(true);
    } else if (!group.groupName) {
      return setIsSubmit(true);
    } else {
      setButtonState({ value: 'updateGroup', isLoading: true });
      try {
        const query = queryParamBuilder();
        const result = await window.$http.postWithQueryAndHeaders(
          `${window.$utility.setApiUrl(userState)}group/${id}`,
          query,
          {
            rp_parent_id: rp_parent_id,
            group_name: group.groupName,
            group_id: group.groupId,
            is_active: isActive,
          }
        );
        if (result.code === window.$constants.STATUS.CONFLICT) {
          setMsg(result?.message);
        }
        if (result.code === window.$constants.STATUS.OK) {
          if (searchValue) {
            getGroupData(searchValue);
          } else {
            getGroupData();
          }
          handleModal();
          toast.success(result?.message);
        } else {
          console.log('Something went wrong!');
        }
      } catch (err) {
        console.log(err);
      }
      setButtonState({ value: 'updateGroup', isLoading: false });
    }
  };

  const getGroupData = async (searchValue, pageNo) => {
    setIsDataLoading(true);
    try {
      const query = {
        q: searchValue,
        page: searchValue
          ? pageNo
            ? pageNo
            : isEnterClicked || searchPageCount === 1
            ? page
            : searchPageCount > 1
            ? page
            : ''
          : page,
        rp_company_id: window.$utility.setCompanyId(userState, companyTypeId),
      };

      const result = await window.$http.getWithQueryAndHeaders(
        `${window.$utility.setApiUrl(userState)}group`,
        query
      );
      if (result.code === window.$constants.STATUS.OK) {
        setIsDataLoading(false);
        setResultData(result.data);
        setMetaData(result?.metadata);
        if (searchValue) {
          setSearchPageCount(result?.metadata?.page_count);
        }
        setPageCount(result.metadata?.page_count);
      } else {
        setIsDataLoading(false);
        console.log('Something went wrong!');
      }
    } catch (error) {
      setIsDataLoading(false);
      console.log(error);
    }
  };

  const getGroup = async (id) => {
    try {
      const query = queryParamBuilder();
      const result = await window.$http.getWithQueryAndHeaders(
        `${window.$utility.setApiUrl(userState)}group/${id}`,
        query
      );
      if (result.code === window.$constants.STATUS.OK) {
        setItems({
          rp_group_id: result?.data?.rp_group_id,
          parentGroupName: result?.data?.rp_parent_id,
          groupName: result?.data?.group_name,
          groupId: result?.data?.group_id,
        });
        setParentId(result?.data?.rp_parent_id);
        setActiveValue(result?.data?.is_active);
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteGroup = async (id) => {
    try {
      const query = queryParamBuilder();
      const result = await window.$http.deleteWithQueryAndHeaders(
        `${window.$utility.setApiUrl(userState)}group/${id}`,
        query
      );
      if (result.data.code === window.$constants.STATUS.OK) {
        setShowModal(false);
        if (searchValue) {
          getGroupData(searchValue);
        } else {
          getGroupData();
        }

        toast.success(result?.data.message);
      } else {
        result?.data?.message
          ? toast.error(result?.data?.message)
          : toast.error('Error occured, please try again or contact admin.');
        console.log('Something went wrong!');
      }
    } catch (error) {
      toast.error('Error occured, please try again or contact admin.');
      console.log(error);
    }
  };

  const handleKeyPress = (e) => {
    if (e?.key === 'Enter' || e?.key === 'NumpadEnter') {
      setPage(1);
      setSearchPageCount(1);
      setIsEnterClicked(true);
      getGroupData(searchValue, 1);
    }
  };

  const handleAddButton = () => {
    setPage(1);
    handleItems();
  };

  const handleModal = () => {
    setItems(InitialItemState);
    setShowModal(false);
    setButtonState({ value: '', isLoading: false });
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    setStyleChange(true);
    if (isEmpty(e.target.value)) {
      setStyleChange(false);
      getGroupData();
    }
  };

  const handleCrossClick = () => {
    setSearchValue('');
    setStyleChange(false);
    if (isEnterClicked) {
      getGroupData();
    }
  };

  return (
    <>
      <h1 className='section-heading text-center rp-primary-dark-g'>
        Groups at {companyState?.company_display_name}
      </h1>
      {/* <div>
        <h2 className='section-sub-heading text-center fs-5 rp-primary-dark-g'>
          Create and Manage groups to better understand resiliency patterns
        </h2>
      </div> */}

      <div className='col-md-12 d-flex align-items-center justify-content-between mt-4'>
        <div className='col-md-6'>
          <div className='position-relative'>
            <span style={{ position: 'absolute', left: '10px', top: '10px' }}>
              <i
                type='button'
                className='bi bi-search z-1 me-n5'
                onClick={() => getGroupData(searchValue)}
              ></i>
            </span>
            <input
              className='form-control ps-5'
              name='search'
              type='search'
              placeholder='Search by group name, or group id'
              aria-label='Search'
              value={searchValue}
              onChange={(e) => handleSearchChange(e)}
              onKeyPress={(e) => handleKeyPress(e)}
            />
            <span
              className='search-cancel-button'
              style={
                styleChange
                  ? { opacity: '1', cursor: 'pointer', pointerEvents: 'auto' }
                  : {}
              }
              onClick={handleCrossClick}
            >
              <i class='fa-solid fa-xmark'></i>
            </span>
          </div>
        </div>
        <div className='text-end'>
          <button
            type='button'
            title='Add Group'
            className='btn btn-rp-primary'
            onClick={handleAddButton}
          >
            Add Group
          </button>
        </div>
      </div>

      <div className='br-12 bg-light p-4 bg-white mt-5'>
        <div className='table-responsive'>
          <table className='table align-middle people-page' id='table'>
            <thead>
              <tr height='50' valign='middle' className='cursor-p'>
                <th>Group Name</th>
                <th>Parent Group</th>
                <th>Group Id</th>
                <th>Is Active</th>
                <th style={{ textAlign: 'center' }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {!isDataLoading ? (
                resultData ? (
                  resultData.map((data, id) => (
                    <tr className='tr-group-item' key={id}>
                      <td>{data?.group_name}</td>
                      <td>{data?.parent_group_name}</td>
                      <td>{data?.group_id}</td>
                      <td>
                        {(data?.is_active).toString() === 'true' ? 'Yes' : 'No'}
                      </td>
                      <td className='downlode_result'>
                        <span
                          className='btn btn-sm btn-rp-primary btn-space me-1'
                          title='Edit Group'
                          onClick={() =>
                            getGroup(data?.rp_group_id) && handleEditedItems()
                          }
                        >
                          <i className='fa-solid fa-pen'></i>
                        </span>
                        <Popconfirm
                          placement='topRight'
                          title={text}
                          onConfirm={() => deleteGroup(data?.rp_group_id)}
                          okText='Yes'
                          cancelText='No'
                          overlayClassName='antdButton'
                        >
                          <span
                            className='btn btn-sm btn-rp-primary-red btn-space'
                            title='Delete Group'
                            style={{ minWidth: '41px' }}
                          >
                            <i className='fa-solid fa-trash-can'></i>
                          </span>
                        </Popconfirm>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className='tr-group-item'>
                    <td
                      colspan={columnLength}
                      height='80'
                      style={{ borderRadius: 'unset' }}
                      className='text-center'
                    >
                      No result found
                    </td>
                  </tr>
                )
              ) : (
                <tr className='tr-group-item'>
                  <td
                    colSpan={columnLength}
                    height='80'
                    style={{ borderRadius: 'unset' }}
                    className='text-center'
                  >
                    <Spinner
                      as='i'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* <!-- pagination --> */}
      <nav className='mt-5' aria-label='Page navigation example'>
        <ul className='pagination justify-content-center'>
          <li className='page-item'>
            <button
              className='page-link'
              to='#'
              aria-label='Previous'
              onClick={pageLoadPrev}
            >
              <span aria-hidden='true'>
                <i className='fa-solid fa-angle-left'></i>
              </span>
            </button>
          </li>
          {paginationRange
            ? paginationRange.map((pageNumber) => {
                if (pageNumber === DOTS) {
                  return <li className='dots'>&#8230;</li>;
                }
                return (
                  <li className='page-item' key={pageNumber}>
                    <Link
                      className={
                        page === pageNumber ? 'page-link active' : 'page-link'
                      }
                      to='#'
                      onClick={(event) => handlePageNo(event, pageNumber)}
                    >
                      {pageNumber}
                    </Link>
                  </li>
                );
              })
            : ''}
          <li className='page-item'>
            <button
              className='page-link'
              to='#'
              aria-label='Next'
              onClick={pageLoadNext}
            >
              <span aria-hidden='true'>
                <i className='fa-solid fa-angle-right'></i>
              </span>
            </button>
          </li>
        </ul>
      </nav>

      <ToastContainer />
      <ManageGroupForm
        items={items}
        group={group}
        rp_parent_id={rp_parent_id}
        isActive={isActive}
        buttonState={buttonState}
        handleCloseModal={handleModal}
        saveGroup={saveGroup}
        updateGroup={updateGroup}
        handleChange={handleChange}
        handleParentState={handleParentState}
        handleActiveState={handleActiveState}
        formErrors={formErrors}
        message={msg}
        groupOptions={groupOptions}
        isSubmit={isSubmit}
        showModal={showModal}
      />
    </>
  );
};

export default ManageGroup;
