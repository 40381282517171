import React, { useEffect, useState } from 'react';

const CurrencyDropdown = (props) => {
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [selectedCurrencyId, setSelectedCurrencyId] = useState();
  const [selectedCurrency, setSelectedCurrency] = useState();

  useEffect(() => {
    if (props?.data) {
      setSelectedCurrencyId(
        props?.data?.find((el) => el?.is_selected === true)?.label
      );
      setSelectedCurrency(props?.data?.find((el) => el?.is_selected));
    }
  }, [props?.data, props?.value]);

  useEffect(() => {
    if (selectedCurrencyId) {
      props?.getSelectedCurrency(selectedCurrencyId);
    }
  }, [selectedCurrencyId]);

  const handleArrowClick = () => {
    setDropDownOpen(!dropDownOpen);
  };

  const handleSelectedCurrency = (data) => {
    setSelectedCurrencyId(data?.label);
    setSelectedCurrency(data);
    setDropDownOpen(!dropDownOpen);
  };

  const handleSelection = (data) => {
    handleSelectedCurrency(data);
  };

  return (
    <div className='question-analytics'>
      <div
        className={
          dropDownOpen
            ? 'select-container custom-dropdown active'
            : 'select-container custom-dropdown'
        }
        onClick={handleArrowClick}
      >
        <div className='select'>
          <div className='question-input' onFocus='this.blur();'>
            <div className='questions-graph'>{selectedCurrency?.label}</div>
          </div>
          <div
            style={{
              cursor: 'pointer',
              padding: '0 15px',
            }}
          >
            <i
              className={
                dropDownOpen
                  ? 'fas fa-chevron-down jq_drop__t rotate'
                  : 'fas fa-chevron-down jq_drop__t'
              }
              aria-hidden='true'
              onClick={handleArrowClick}
            ></i>
          </div>
        </div>
        <div className='option-container' style={{ zIndex: '3' }}>
          {props?.data && props?.data?.length
            ? props?.data?.map((data, index) => (
                <div className='option' value={data?.id} key={index}>
                  <div
                    className={`questions-graph ${
                      data?.label === selectedCurrencyId ? 'selected' : ''
                    } `}
                    onClick={() => handleSelection(data)}
                  >
                    {data?.label}
                  </div>
                </div>
              ))
            : ''}
        </div>
      </div>
    </div>
  );
};

export default CurrencyDropdown;
