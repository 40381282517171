import React from 'react';
import Modal from 'react-bootstrap/Modal';

const TermsOfUse = (props) => {
  return (
    <Modal
      size='xl'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      scrollable={true}
      show={props.showModal ? true : false}
    >
      <Modal.Header>
        <h5 className='modal-title'>Terms of Use</h5>
        <button
          type='button'
          className='btn-close'
          data-bs-dismiss='modal'
          aria-label='Close'
          onClick={props.handleCloseModal}
        ></button>
      </Modal.Header>
      <Modal.Body>
        <div className='col-12'>
          <p>
            Please read the following Terms of Use carefully. These Terms of Use
            govern your access to the assessment, the Program made available by
            Resiliency Program and use of the website www.resiliencyprogram.in
            and www.resiliencyprogram.com
            <strong>(“Site/Website”)</strong>, owned by Resiliency Program
            (India) Pvt Ltd or its representatives, affiliates, officers,
            directors, assignees, principle or parent company (collectively,{' '}
            <strong>“we”</strong>, <strong>“us”</strong>, or
            <strong>“our”</strong>). Please note that the following terms and
            conditions as mentioned here in below form a valid contract when you
            accept the same by clicking – I ACCEPT between you and us.
          </p>

          <p>
            <span className='text-decoration-underline'>PLEASE READ:</span>{' '}
            THESE TERMS CONTAIN A MANDATORY ARBITRATION CLAUSE. THEY AFFECT YOUR
            LEGAL RIGHTS BY REQUIRING ANY DISPUTE BETWEEN YOU AND RESILIENCY
            PROGRAM, TO BE RESOLVED BY A FINAL AND BINDING ARBITRATION ON AN
            INDIVIDUAL BASIS, AND FORGO ANY AND ALL OTHER TYPES OF COURT
            PROCEEDINGS. BY ENTERING INTO THIS AGREEMENT, YOU ACKNOWLEDGE THAT
            YOU UNDERSTAND AND EXPRESSLY AGREE TO THE MANDATORY ARBITRATION
            AGREEMENT.{' '}
          </p>

          <p className='text-decoration-underline fw-bold'>
            ANY DISPUTE OR DIFFERENCE WHATSOEVER ARISING BETWEEN THE PARTIES OUT
            OF OR RELATING TO THE CONSTRUCTION, MEANING, SCOPE, OPERATION OR
            EFFECT OF THIS CONTRACT OR THE VALIDITY OR THE BREACH THEROF SHALL
            BE SETTLED BY ARBITRATION IN ACCORDANCE WITH THE RULES OF DOMESTIC
            COMMERCIAL ARBITRATION OF THE INDIAN COUNCIL OF ARBITRATION (ICA)
            AND THE AWARD MADE IN PURSUANCE THEREOF SHALL BE BINDING ON THE
            PARTIES. ANY DISPUTES SHALL BE REFERRED TO A SOLE ARBITRATOR
            APPOINTED BY ICA AND THE SEAT OF ARBITRATION SHALL BE NEW DELHI.
          </p>

          <h5 className='mt-4'>
            Important Notice – This is a Binding Contract{' '}
          </h5>

          <p>
            By continuing to use the Website and/or proceed with the assessment,
            you are agreeing to these Terms of Use. If you don’t agree with any
            of these Terms of Use including the Privacy Policy, you must not use
            the Website or proceed with the assessment and not provide us with
            any other Personal Information.
            <strong>“User Content”</strong> refers to the content added and
            provided by you on the Website of Resiliency Program during the
            process of signing up, registration, and while filing the assessment
            in the next step.
          </p>

          <ol className='list-group-numbered'>
            <li>
              <strong>ABOUT US –</strong>
              <br />
              <p>
                Resiliency Program product/service is an integrated set of
                online tools that allow individuals through its assessment
                (‘hereinafter referred to as <strong>“Assessment”</strong>) to
                assess their or their organization level of resilience, create
                an action plan to increase resilience, and monitor their
                progress increasing their resilience over time. Resiliency
                Program is offered directly to end-users (hereinafter referred
                to as <strong>“you”</strong> or <strong>“User(s)”</strong>) as
                well as being offered as an adjunct to corporate benefits (each,
                a <strong>“Program”</strong>) through employers, sponsors, or
                their respective designees <strong>(“Subscriber”)</strong> who
                enter into a contract with the RP for subscription of the
                Program. Resiliency Program collects and processes data about
                the individuals. We are a web-based SaaS solution to measure and
                reduce workplace burnout to drive powerful growth results and a
                culture of well-being and adaptive thinking. We are registered
                in India and have our registered office at BM – 52, WEST BLOCK,
                SHALIMAR BAGH, NEW DELHI – 110088
              </p>
            </li>
            <li>
              <strong>USER RESPONSIBILITY –</strong>
              <br />
              <p>
                When you provide Personal Information (as described more
                specifically under clause 3, hereinbelow) e-mail or otherwise
                make available User Content on or to the Site, you are entirely
                responsible for such User Content. Such User Content constitutes
                a submission by you, and this means that you are entirely
                responsible for all User Content that you provide. It is agreed
                by you that you have submitted and provided such information
                willingly, without any coercion and/ or undue influence. You
                agree to provide us with true, accurate and complete information
                about yourself in the Assessment and/ or at the time of
                registration as a User.
              </p>
            </li>
            <li>
              <strong>PERSONAL INFORMATION –</strong>
              <br />
              <p>
                Personal Information is defined as information that can identify
                the individual User and that information which is submitted by
                you while filling out the Assessment in regard to the assessment
                of burnout. Further this information is that which you provide
                or make available through or in connection with the Website or
                the Program, including when you register, which required you to
                create an account and provide contact information such as your
                name, email address, date of birth, country, experience, part
                time or full time employee, time period of working with the
                employer and mobile number, provided to or collected by us
                through or in connection with the Website or the Program and all
                the information provided while filling up the Assessment. You
                are responsible for maintaining the confidentiality of your
                account and password and to ensure that no one else uses the
                same. You may not register for more than one account.
              </p>
            </li>
            <li>
              <strong>NO MEDICAL CARE OF ADVICE –</strong>
              <br />
              <p>
                Resiliency Program is not a healthcare provider, or a medical
                organization and we cannot give you medical advice or diagnosis
                care, services, treatment or supplies related to the health of
                an individual. Nothing contained in this Website or provided or
                made available in connection with any services, should be
                construed as such medical advice or diagnosis care, services,
                treatment or supplies related to the health of an individual.
                The information presented and generated by us should not be
                interpreted as a substitute for physician consultation,
                evaluation, or treatment. Always seek the advice of your
                physician or other qualified health provider with any questions
                you may have regarding any medical condition.
              </p>
            </li>
            <li>
              <strong>NOT FOR EMERGENCIES –</strong>
              <br />
              <p>
                Our website and the services are not for medical emergencies or
                urgent situations. You should not disregard or delay seeking
                medical advice based on anything that appears or does not appear
                on our website. You should seek emergency help or follow up care
                when recommended by a doctor or when otherwise needed. You
                should continue to consult with your primary doctor and other
                healthcare professionals as recommended. Always seek the advice
                of a doctor or other qualified healthcare provider concerning
                questions you have regarding a medical condition and before
                stopping, starting, or modifying any treatment or your diet
                plan.
              </p>
            </li>
            <li>
              <strong className='text-decoration-underline'>
                RESTRICTIONS ON THE USE OF THE PROGRAM –
              </strong>
              <br />
              <p>
                Unless otherwise specified, we grant you a non-exclusive,
                non-transferable, limited right to access and use the
                Program/Website and the material provided therein for your
                personal and non-commercial use, provided that you comply fully
                with the provisions of these Terms of Use. You acknowledge that
                the Program/Website contains information, software, and
                questions (collectively, “Content”) that are protected by
                copyrights, systems, processes and codes that may be protected
                patents, trademarks, trade secrets and/or other proprietary
                rights, and that these rights are valid and protected in all
                forms, media and technologies existing now or hereafter
                developed. You clearly acknowledge the ownership and rights in
                the Content to us. When the Content is available to you, you do
                not obtain any ownership in such Content acknowledge that you
                shall not use it directly or indirectly for any commercial
                purpose.
              </p>
            </li>
            <li>
              <strong className='text-decoration-underline'>
                PRIVACY POLICY –
              </strong>
              <br />
              <p>
                Resiliency Program respects the privacy of its users. Please
                refer to our Privacy Policy available at
                WWW.RESILIENCYPROGRAM.COM which explains how we collect, use,
                and disclose information that pertains to your privacy. By
                accessing or using this Website or the Program, you agree to the
                Privacy Policy, including your acknowledgment of the provisions
                of the Privacy Policy and as required, your consent to the
                collection, provision, use, transfer, storage and disclosure or
                other processing of your personal information on the terms set
                forth in the Privacy Policy.
              </p>
            </li>
            <li>
              <strong className='text-decoration-underline'>
                ABOUT THE SERVICES –
              </strong>
              <br />
              <p>
                The service provides assessment of your burn out levels while
                working during your employment with your employer/ Subscriber
                and also provides you suggestions and with further plans for
                improving your burnout levels. The service includes suggestions
                like food and diet plans, exercise videos, crucial supplements,
                practice of good habits, mindfulness training etc. You are
                urged, advised, and required to seek the advice of your
                physician before following any of these suggestions, more
                specifically described in the Disclaimers mentioned at the
                respective sections.
              </p>
            </li>
            <li>
              <strong>LIMITED LICENSE –</strong>
              <br />
              <p>
                All Content is copyrighted under the copyright laws. Resiliency
                Program LLC (USA) owns copyright in the selection, coordination,
                arrangement, and enhancement of the Content, owns all other
                intellectual property rights and we are permitted users through
                a valid and subsisting license. When Content is downloaded to
                your computer, you do not obtain any ownership interest in such
                Content. You may use the Website subject to all of the
                following: (a) the Website may be used solely for your personal
                and non-commercial purposes; (b) you may not modify, translate
                or create derivative works of the Website and the Program; and
                (c) the Program and assessment may not be shared, rented,
                leased, loaned, transferred, sublicensed, assigned, copied,
                redistributed to any or be used for any purpose than its own
                use.
              </p>
            </li>
            <li>
              <strong className='text-decoration-underline'>
                MODIFICATION TO SERVICES PROVIDED –{' '}
              </strong>
              <br />
              <p>
                You acknowledge and agree that we may, with or without notice,
                modify these Terms of Use, Program, Contents, services and/or
                the Site to keep up with current rules and regulations and our
                offerings and protocols. We will notify you of changes we make
                by posting a notice on our Site and update the Terms of Use
                modification date, or, if we choose, by sending you an email on
                the address we have on file for your account. If you do not
                accept the changes, you can opt not to proceed with the
                assessment, but you cannot terminate your subscription in case
                you are enrolled by your Subscriber. Your use of the Site and/or
                the products or services offered after the notice is posted (or
                after we send you the email) shall be deemed to be acceptance of
                the modification of the services or these Terms of Use.
              </p>
            </li>
            <li>
              <strong className='text-decoration-underline'>
                REGISTRATION AND PASSWORD –
              </strong>
              <br />
              <p>
                You will provide current, complete, accurate information in the
                registration section of the Site. You will further update and
                keep that information current and updated as far as possible.
                You will be sent a Welcome email, wherein you will be required
                to set a and password in order to access the services and your
                account only after verifying your mobile number through an OTP
                (One Time Password) or authorization code that you shall
                receive. You are solely responsible for maintaining the
                confidentiality of your password and account information. You
                will immediately notify us of any unauthorized account activity,
                or any unauthorized use of your email or User account or any
                other breach of security you become aware of.
              </p>
            </li>
            <li>
              <strong className='text-decoration-underline'>
                USER RESTRICTIONS –
              </strong>
              <br />
              <p>You agree that you will not under any circumstances:</p>

              <ol className='modified-ol' style={{ listSyle: 'lower-alpha' }}>
                <li>
                  Access the services and the Program for any reason other than
                  your personal, non-commercial use.
                </li>
                <li>
                  Use the Site or the Program for the solicitation of business
                  in the course of trade or in connection with a commercial
                  enterprise.
                </li>
                <li>
                  Distribute any part or parts of the Site or the Program
                  without our explicit written permission.
                </li>
                <li>
                  Use the Program for any unlawful purpose or for the promotion
                  of illegal activities.
                </li>
                <li>Use another user’s account without permission.</li>
                <li>
                  Intentionally allow another user to access your account.
                </li>
                <li>
                  Violate directly or indirectly, any of Intellectual Property
                  Rights granted owner of which is Resiliency Program LLC (USA)
                  and we are permitted users by way of a valid and subsisting
                  license.
                </li>
                <li>
                  Provide false or inaccurate information when registering an
                  account.
                </li>
                <li>
                  Interfere or attempt to interfere with the proper functioning
                  of the Website.
                </li>
                <li>
                  Conduct any criminal activity as under any law for the time
                  being in force on the Website or commit the acts of
                  unauthorized usage, hacking or tampering with Computer data on
                  the Website.
                </li>
              </ol>
            </li>

            <li>
              <strong className='text-decoration-underline'>
                INTELLECTUAL PROPERTY RIGHTS –
              </strong>
              <br />
              <p>
                All intellectual property rights belong to Resiliency Program
                LLC, USA and Resiliency Program (India) Pvt Ltd is a permitted
                user by way of a valid and subsisting license to use the same.
                You acknowledge and agree that Resiliency Program LLC (USA) is
                the owner of all intellectual property rights including
                copyright, trademark, patents, trade secrets and that we are
                permitted users by way of a valid and subsisting license to use.
                You acknowledge specifically to the following:
              </p>

              <ol className='modified-ol'>
                <li>
                  Our website contains copyrighted material; trademarks and
                  other proprietary information including text, software,
                  photos, graphics, and the entire Content of our Website are
                  copyrighted as a collective work under copyright laws.
                  Resiliency Program LLC, USA is the owner of such copyrights
                  and Resiliency Program (India) Pvt Ltd is a permitted user by
                  way of a valid and subsisting license to use the same.
                </li>
                <li>
                  Our website contains copyrighted material; trademarks and
                  other proprietary information including text, software,
                  photos, graphics, and the entire Content of our Website are
                  copyrighted as a collective work under copyright laws.
                  Resiliency Program LLC, USA is the owner of such copyrights
                  and Resiliency Program (India) Pvt Ltd is a permitted user by
                  way of a valid and subsisting license to use the same.
                </li>
                <li>
                  We (resiliencyprogram.us and resiliencyprogram.in) own
                  copyright in the selection, coordination, arrangement, and
                  enhancement of such Content, which is created by us and is
                  original. Except as otherwise expressly permitted under
                  copyright law, you may not copy, redistribute, modify, adapt,
                  publish, display or commercially exploit any material from the
                  Content or Website without our express permission.
                </li>
                <li>
                  All intellectual property including our trademark, wordmark
                  and logo “Resiliency” are the sole and exclusive property
                  belonging to Resiliency Program LLC, USA and Resiliency
                  Program (India) Pvt Ltd is a permitted user by way of a valid
                  and subsisting license to use the same. Any passing off or
                  infringement of any intellectual property including designs,
                  copyrights, and/or trademarks shall attract legal action.
                </li>
                <li>
                  The copyright in the Contents is owned by Resiliency Program
                  LLC, USA and Resiliency Program (India) Pvt Ltd is a permitted
                  user by way of a valid and subsisting license to use the same.
                  All rights are reserved. You are responsible for obeying all
                  applicable copyright laws.
                </li>
                <li>
                  Developing this Website involved significant investments. The
                  Website and each of the elements it comprises (i.e., brands,
                  images, texts, videos, etc.) are protected by intellectual
                  property rights. No use, reproduction, or representation of
                  the Website (in whole or in part), on any media whatsoever,
                  for any other purposes, including, but not limited to,
                  commercial purposes, shall be authorized.
                </li>
                <li>
                  We grant you, for your personal and private use only, free of
                  charge and for the legal protection period of intellectual
                  property rights as defined by Indian and foreign laws and
                  international treaties, a non-exclusive and non-transferable
                  right to use the Content. Any reproduction, representation,
                  modification, or distribution of the Website/ shall be
                  prohibited and shall attract legal consequences by way of
                  injunction and damages. By using the Content and Assessment
                  you agree to use them in accordance with these Terms of Use.
                </li>
                <li>
                  Third-party Intellectual Property rights – We do not claim any
                  intellectual property rights to any third-party rights holders
                  including Amazon or any other third party products that we may
                  have links to for example any nutritional supplements we
                  recommend etc.{' '}
                </li>
              </ol>
            </li>

            <li>
              <strong className='text-decoration-underline'>STORAGE –</strong>
              <br />
              <p>
                You acknowledge that RP may establish general practices and
                limits concerning use of the Website and the Program, including
                without limitation the maximum period of time that data or other
                content to be retained by the Website will be 90 days from the
                expiration/termination of annual subscription with the
                Subscriber and the maximum storage space that will be allotted
                on RP’s servers on your behalf. Data once destroyed cannot be
                reproduced after 45 days of data log retention policy. You
                acknowledge that RP reserves the right to terminate accounts
                that are inactive for an extended period of time. You further
                acknowledge that RP reserves the right to change these practices
                and limits at any time, in its sole discretion, with or without
                notice.
              </p>
            </li>
            <li>
              <strong className='text-decoration-underline'>
                MOBILE SERVICES –
              </strong>
              <br />
              <p>
                The Website and Program include certain services that are
                available via a mobile device, including (i) the ability to
                browse the Website from a mobile device and (ii) the ability to
                access certain features through an application downloaded and
                installed on a mobile device (collectively, the “Mobile
                Services”). To the extent you access the Website and/or the
                Program through a mobile device, your wireless service carrier’s
                standard charges, data rates and other fees may apply. By using
                the Mobile Services, you agree that we may communicate with you
                regarding RP by text message or other electronic means to your
                mobile device and that certain information about your usage of
                the Mobile Services may be communicated to us. In the event you
                change or deactivate your mobile telephone number, you must
                promptly update your account information to ensure that your
                messages are not sent to the person that acquires your old
                number.{' '}
              </p>
            </li>
            <li>
              <strong className='text-decoration-underline'>
                ACCURACY OF INFORMATION –
              </strong>
              <br />
              <p>
                Although we attempt to ensure the accuracy of the Website, we
                make no representations, warranties, or guarantees whatsoever as
                to the correctness or accuracy of the Website and Content
                thereon. It is possible that the Website could include
                typographical errors, inaccuracies, or other errors. In the
                event that an inaccuracy arises, please inform us so that it can
                be corrected. We reserve the right to unilaterally correct any
                inaccuracies on the Website without notice. Information
                contained on the Website may be changed or updated without
                notice.
              </p>
            </li>
            <li>
              <strong className='text-decoration-underline'>
                INDEMNIFICATION –
              </strong>
              <br />
              <p>
                You unconditionally and irrevocably indemnify and holds harmless
                us and our successors, employees, officers, suppliers,
                contractors, agents, consultants, directors, doctors, assignees,
                and shareholders against all and any losses, claims,
                proceedings, actions, damages, (direct, consequential, or
                otherwise), liability, demands, expenses, legal costs (on an
                attorney and own client basis), medical costs or other costs
                howsoever arising out of, based upon, or in connection with
                (directly or indirectly) the products and services offered as
                under these Terms of Use.
              </p>
            </li>
            <li>
              <strong className='text-decoration-underline'>
                COMMUNICATIONS IN ELECTRONIC FORM –{' '}
              </strong>
              <br />
              <p>
                For contractual purposes, you: (a) consent to receive
                communications from us in an electronic form via the email
                address you have submitted; and (b) agree that all Terms of Use,
                notices, Privacy Policy, Disclaimers, disclosures, and other
                communications that we provide to you electronically satisfy any
                legal requirement that such communications would satisfy if it
                were in writing.{' '}
              </p>
            </li>

            <li>
              <strong className='text-decoration-underline'>
                TERMINATION/ CANCELLATION OF SUBSCRIPTION –
              </strong>
              <br />
              <ol className='modified-ol'>
                <li>
                  You may not terminate this contract if you are enrolled by
                  your Subscriber. Termination of this contract can be done by a
                  Registered User who has enrolled for the Program
                  himself/herself by contacting us at
                  support@resiliencyprogram.com.
                </li>
                <li>
                  We may terminate your use of the Program and services as a
                  result of your fraud or breach of any obligation under these
                  Terms of Use and/or Privacy Policy. Such termination may be
                  immediate and without notice and binding as under law.
                </li>
              </ol>
            </li>
            <li>
              <strong className='text-decoration-underline'>
                LIMITATION OF LIABILITY –
              </strong>
              <br />
              <p>
                To the fullest extent permissible under applicable law, we and
                our officers, directors, employees, contractors, and agents are
                not liable for any direct, incidental, consequential, special,
                indirect, punitive, or similar damages arising out of, or
                resulting from, these Terms of Use, including, without
                limitation, damages for harm to business, loss of profits, lost
                savings, or lost revenues.
              </p>
              <p>
                If you are a user, please note that we only provide our site for
                private use and non-commercial use. You agree not to use our
                site for any commercial or business purposes, and we have no
                liability to you for any loss of profit, loss of business or
                loss of opportunity.
              </p>
              <p>
                We will not be liable for any loss or damage caused by a virus
                or any harmful technology that may infect your computer device,
                computer programs, data or other proprietary material due to
                your use of our site or to your downloading of any content on
                it, or on any website linked to it.
              </p>
              <p>
                We assume no responsibility for the content of third-party
                websites linked on our site. Such links should not be
                interpreted as endorsed by us of those linked websites. We will
                not be liable for any loss or damage that may arise from your
                use of them.
              </p>
            </li>
            <li>
              <strong className='text-decoration-underline'>
                DISCLAIMER OF WARRANTIES –
              </strong>
              <br />
              <p>
                Any and all products, services, offerings, content and materials
                on this website are provided “as is” and without warranties of
                any kind, either express or implied. None of RP, our affiliates,
                and our or their respective licensors, licensees, service
                providers, assignees, parent, parent company, suppliers, or
                agents warrant that this Website, its services, the Program and
                or any function contained in this Website will be uninterrupted
                or error-free, that defects will be corrected, or that this
                Website, the services or the servers that make this Website
                available are free of viruses or other harmful components. Any
                product, services, offering and/or content downloaded or
                otherwise obtained through the use of this Website is done at
                your sole risk and you will be solely responsible for any damage
                to your computer system or loss of data that results from the
                download/use of any such product, services, offering and/or
                content. None of RP, our affiliates, and our assignees, parent
                company, respective licensors, licensees, service providers,
                suppliers, or agents warrant or make any representations
                regarding the use or the results from the use of the Website,
                its services, the Program or the products, services, offerings,
                content and materials in terms of their correctness, accuracy,
                reliability, or otherwise.
              </p>
            </li>
            <li>
              <strong className='text-decoration-underline'>
                THIRD PARTY LINKS –
              </strong>
              <br />
              <p>
                These Terms of Use apply only to this Website, and not to the
                websites of any other person or entity. We may provide third
                party links. You acknowledge and agree that we are not
                responsible for the availability of products on such external
                sites or resources, and do not endorse (and are not responsible
                or liable for) any content, advertising, products, or other
                materials on or available from such websites or resources. You
                further acknowledge and agree that, under no circumstances, will
                we be held responsible or liable, directly or indirectly, for
                any losses caused or alleged to have been caused to you in
                connection with your use of, or reliance on, any content,
                advertisements, products or other resources available on any
                other website. You should direct any concerns with respect to
                any other website to that website itself.
              </p>
            </li>
            <li>
              <strong className='text-decoration-underline'>
                SEVERABILITY –
              </strong>
              <br />
              <p>
                If any provision of these terms is declared invalid or
                unenforceable by the Arbitration Tribunal, such provision shall
                be deemed modified to the least extent necessary and possible to
                render it valid and enforceable. In any event, the
                unenforceability or invalidity of any provision shall not affect
                any other provision of these terms, and these terms shall
                continue in full force and effect, and be construed and
                enforced, as if such provision had not been included, or had
                been modified as above provided, as the case may be.
              </p>
            </li>
            <li>
              <strong className='text-decoration-underline'>
                ASSIGNMENT –
              </strong>
              <br />
              <p>
                You may not assign your rights and obligations under these Terms
                of Use, in whole or in part, without our prior written consent,
                and any such assignment without such consent will be null and
                void. We may assign our rights and obligations under these Terms
                of Use, in whole or in part, with written notice to you.
              </p>
            </li>
            <li>
              <strong className='text-decoration-underline'>
                DISPUTE RESOLUTION –
              </strong>
              <br />
              <p>
                We will try to work in good faith to resolve any issue you have
                with the Website and the Program, including products and
                services offered on the Website, if you bring that issue to the
                attention of our customer service department at
                support@resiliencyprogram.com. However, we realize that there
                may be rare cases where we may not be able to resolve an issue
                to a customer's satisfaction. Any dispute or difference
                whatsoever arising between the parties out of or relating to
                these Terms of Use and the Program meaning, scope, operation or
                effect of it or its validity shall be settled by Arbitration in
                accordance with the rules of Domestic Commercial Arbitration of
                the Indian council of Arbitration and the award made in
                pursuance thereof shall be binding on the parties. All disputes
                shall be referred to a sole arbitrator appointed by the Indian
                Council of Arbitration (ICA) and the said Arbitration shall be
                New Delhi. This clause shall survive termination of these Terms
                of Use any other contractual relationship between you and RP.
              </p>
            </li>
            <li>
              <strong className='text-decoration-underline'>
                GOVERNING LAW –
              </strong>
              <br />
              <p>
                These terms shall be governed by the laws of India and the
                Parties agree to subject themselves to the exclusive
                jurisdiction of the courts at New Delhi.
              </p>
            </li>
            <li>
              <strong className='text-decoration-underline'>
                CONTACT US –
              </strong>
              <br />
              <p>
                For any queries contact us at{' '}
                <a href='mailto:support@resiliencyprogram.com'>
                  support@resiliencyprogram.com
                </a>
                .
              </p>
            </li>
          </ol>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TermsOfUse;
