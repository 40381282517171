import React from 'react';

const BurnoutContent = (props) => {
  return (
    <div className='col-12'>
      <div className='result-summry box_shadow bg-white p-4 br-12 mt-5'>
        <span className='sub-titel'>About</span>
        {props.resultData?.data?.burnout?.rp_gbl_burnout_stage_id ===
        window.$enums.BurnoutStageId.Severe ? (
          <div className='mb-4'>
            <h2
              style={{
                fontSize: '24px',
                fontWeight: '500',
                marginBottom: '1rem',
                lineHeight: '26px',
              }}
              className='section-sub-heading primary-color'
            >
              What is Severe Burnout
            </h2>

            <p style={{ lineHeight: '2rem' }} className='pe-4'>
              A person with <span className='rp-primary-red-color'>Severe</span>{' '}
              Burnout is unable to deal with the events in their daily life.
              They tend to be depressed, nervous, on-edge and have health
              issues. They are quick to anger and become overwhelmed. They have
              no motivation in their professional or personal life. Their
              quality-of-life is very low.
            </p>
          </div>
        ) : props.resultData?.data?.burnout?.rp_gbl_burnout_stage_id ===
          window.$enums.BurnoutStageId.Chronic ? (
          <div className='mb-4'>
            <h2
              style={{
                fontSize: '24px',
                fontWeight: '500',
                marginBottom: '1rem',
                lineHeight: '26px',
              }}
              className='section-sub-heading primary-color'
            >
              What is Chronic Burnout
            </h2>

            <p style={{ lineHeight: '2rem' }} className='pe-4'>
              A person with <span className='chornic-color'>Chronic</span>{' '}
              Burnout has a difficult time dealing with the stress of everyday
              life. They usually have physical and emotional symptoms that
              worsen when they are stressed. They struggle to meet their
              professional and personal goals. They have a low quality-of-life.
            </p>
          </div>
        ) : props.resultData?.data?.burnout?.rp_gbl_burnout_stage_id ===
          window.$enums.BurnoutStageId.Moderate ? (
          <div className='mb-4'>
            <h2
              style={{
                fontSize: '24px',
                fontWeight: '500',
                marginBottom: '1rem',
                lineHeight: '26px',
              }}
              className='section-sub-heading primary-color'
            >
              What is Moderate Burnout
            </h2>

            <p style={{ lineHeight: '2rem' }} className='pe-4'>
              A person with <span className='moderate-color'>Moderate</span>{' '}
              Burnout is aware that they no longer can handle personal or
              professional stress. Their self motivation is depleted and they
              often need outside stimulus to complete their tasks. They will
              notice an increase in aches and pains plus a feeling of being
              emotionally drained. The joy they feel in their personal and
              professional lives is waning as is their motivation. They have
              only a fair quality-of-life.
            </p>
          </div>
        ) : props.resultData?.data?.burnout?.rp_gbl_burnout_stage_id ===
          window.$enums.BurnoutStageId.Mild ? (
          <div className='mb-4'>
            <h2
              style={{
                fontSize: '24px',
                fontWeight: '500',
                marginBottom: '1rem',
                lineHeight: '26px',
              }}
              className='section-sub-heading primary-color'
            >
              What is Mild Burnout
            </h2>

            <p style={{ lineHeight: '2rem' }} className='pe-4'>
              A person with <span className='minimal-color'>Mild</span> Burnout
              is able to handle a limited amount of stress without triggering a
              negative physical or emotional response. They may need more time
              to recover from stressful events and become more easily agitated.
              They tend to enjoy both their professional and personal life.
              Their quality-of-life is good.
            </p>
          </div>
        ) : props.resultData?.data?.burnout?.rp_gbl_burnout_stage_id ===
          window.$enums.BurnoutStageId.Resilient ? (
          <div className='mb-4'>
            <h2
              style={{
                fontSize: '24px',
                fontWeight: '500',
                marginBottom: '1rem',
                lineHeight: '26px',
              }}
              className='section-sub-heading primary-color'
            >
              What is Resilient Burnout
            </h2>

            <p style={{ lineHeight: '2rem' }} className='pe-4'>
              A <span className='primary-color'>Resilient</span> person has the
              ability to deal with life’s everyday events and trials without
              triggering a negative response. They may still be aggravated and
              annoyed but it will be proportional to stress and they will return
              to normal once it is resolved. They have a high quality-of-life
              and tend to enjoy their personal and professional life.
            </p>
          </div>
        ) : (
          ''
        )}

        {props.resultData?.data?.burnout?.rp_gbl_burnout_stage_id ===
        window.$enums.BurnoutStageId.Resilient ? (
          ''
        ) : (
          <div className='mb-4'>
            <h2
              style={{
                fontSize: '24px',
                fontWeight: '500',
                marginBottom: '1rem',
                lineHeight: '26px',
              }}
              className='section-sub-heading primary-color'
            >
              Why Nutritional Supplements are important for you
            </h2>

            <p style={{ lineHeight: '2rem' }} className='pe-4'>
              Nutritional supplements are the fastest way for you to start your
              recovery from Burnout.{' '}
              {props.resultData?.data?.burnout?.rp_gbl_burnout_stage_id ===
              window.$enums.BurnoutStageId.Severe ? (
                <span className='rp-primary-red-color'>Severe</span>
              ) : props.resultData?.data?.burnout?.rp_gbl_burnout_stage_id ===
                window.$enums.BurnoutStageId.Chronic ? (
                <span className='chornic-color'>Chronic</span>
              ) : props.resultData?.data?.burnout?.rp_gbl_burnout_stage_id ===
                window.$enums.BurnoutStageId.Moderate ? (
                <span className='moderate-color'>Moderate</span>
              ) : props.resultData?.data?.burnout?.rp_gbl_burnout_stage_id ===
                window.$enums.BurnoutStageId.Mild ? (
                <span className='minimal-color'>Mild</span>
              ) : (
                ''
              )}{' '}
              stress robs the mind and body of key nutrients needed to deal with
              stress and to rebuild resilience. Too often those with Burnout do
              not have enough energy to begin to recover. Nutritional
              supplements help to supply Burnout sufferers quickly with the
              nutrients and energy they need to feel better. The improved energy
              from nutritional supplements will help make lifestyle changes
              easier to achieve and more effective.
            </p>
          </div>
        )}

        <div className='mb-4'>
          <h2
            style={{
              fontSize: '24px',
              fontWeight: '500',
              marginBottom: '1rem',
              lineHeight: '26px',
            }}
            className='section-sub-heading primary-color'
          >
            What you should do to reduce Burnout
          </h2>
          <ul className='list-dot'>
            <li>
              Improve your diet
              <ul>
                <li>
                  Eat more fruits and vegetables gives your mind and body the
                  key nutrients to deal with stress and rebuild resiliency
                </li>
                <li>
                  Avoid sugar, alcohol and caffeine these types of foods cause
                  inflammation and irritate the nervous system
                </li>
              </ul>
            </li>

            <li>
              Exercise is a great way to improve your mind and body, It brings
              in more oxygen to your mind and helps to clear negative thoughts.
              It also releases endorphins which is how your mind improves your
              moods
            </li>
            <li>
              Practice mindfulness multiple times a day, just a few minutes
              makes a big difference
              <ul>
                <li>
                  Gratefulness training is a great way to start the day. Think
                  about the good in your life friends, family, sunshine, a job,
                  health
                </li>
                <li>Meditation to clear your mind and reduce anxiety</li>

                <li>Breathing exercises to clear your mind and anxiety</li>
                <li>
                  Yoga & Stretching will relax your body and calm your mind
                </li>
              </ul>
            </li>
            <li>
              Communicate and be around friends and family. Sharing time with
              others is a proven method to relieve stress and calm the mind.
            </li>
            <li>
              Sleep 8 hours a night to give your mind and body a chance to
              recharge
              <ul>
                <li>Stop all electronics 30 minutes before bedtime</li>
                <li>Try to set a consistent bedtime</li>
                <li>
                  Avoid alcohol and caffeine as these interrupts your sleep
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BurnoutContent;
