import { SAVECATEGORYSLUG, REMOVECATEGORYSLUG } from './types';

const INITIAL_STATE = {};

const categorySlug = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SAVECATEGORYSLUG: {
      return {
        ...state,
        payload,
      };
    }
    case REMOVECATEGORYSLUG: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default categorySlug;
