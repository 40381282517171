import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

const BothFilter = (props) => {
  const [eraOptions, setEraOptions] = useState({
    data: [],
    loading: true,
  });
  const [oraOptions, setOraOptions] = useState({
    data: [],
    loading: true,
  });
  const [filterOptionss, setFilterOptionss] = useState({
    data: [],
    loading: true,
  });
  const [filterArray, setFilterArray] = useState([]);
  const [eraValue, setEraValue] = useState('');
  const [eraStatus, setEraStatus] = useState(true);
  const [oraValue, setOraValue] = useState('');
  const [oraStatus, setOraStatus] = useState(true);

  let location = useLocation();
  let loc = location.pathname.split('/');

  useEffect(() => {
    getEraOptions();
    getOraOptions();
    getFilterOptions();
  }, []);

  useEffect(() => {
    if (loc[2] === 'participations' && !eraValue) {
      setEraValue(eraOptions?.data[0]?.rp_company_assmt_id);
    }
  }, [loc]);

  useEffect(() => {
    if (loc[2] === 'participations' && !oraValue) {
      setOraValue(oraOptions?.data[0]?.rp_company_assmt_id);
    }
  }, [loc]);

  const getEraOptions = async () => {
    try {
      const result = await window.$http.getWithHeaders('analytics/tabs/era');
      if (result.code === window.$constants.STATUS.OK) {
        setEraOptions({ data: result?.data?.tabs, loading: false });
        setEraStatus(result?.data?.tabs[0]?.is_enabled);
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getOraOptions = async () => {
    try {
      const result = await window.$http.getWithHeaders('analytics/tabs/ora');
      if (result.code === window.$constants.STATUS.OK) {
        setOraOptions({ data: result?.data?.tabs, loading: false });
        setOraStatus(result?.data?.tabs[0]?.is_enabled);
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getFilterOptions = async () => {
    try {
      const result = await window.$http.getWithHeaders('analytics/filters');
      if (result.code === window.$constants.STATUS.OK) {
        setFilterOptionss({ data: result?.data?.filters, loading: false });
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEraValue = (el) => {
    setEraValue(el?.rp_company_assmt_id);
    setEraStatus(el?.is_enabled);
    props?.getAssmtId(el?.rp_company_assmt_id);
    props?.getEraStatus(
      el?.is_enabled,
      el?.disable_message,
      el?.is_reminder_enabled
    );
  };

  const handleOraValue = (el) => {
    setOraValue(el?.rp_company_assmt_id);
    setOraStatus(el?.is_enabled);
    props?.getAssmtId(el?.rp_company_assmt_id);
    props?.getOraStatus(
      el?.is_enabled,
      el?.disable_message,
      el?.is_reminder_enabled
    );
  };

  useEffect(() => {
    if (filterOptionss?.data?.length) {
      for (let i = 1; i <= filterOptionss?.data?.length; i++) {
        filterArray?.push(i);
        setFilterArray(filterArray);
      }
    }
  }, [filterOptionss?.data?.length]);

  return (
    <>
      <div className='d-flex justify-content-between participation-filter-tab mb-3'>
        <div
          className={`assessment-tabs-group rp-era ${
            eraOptions?.loading ? 'd-flex' : ''
          }`}
        >
          {eraOptions?.loading ? (
            <>
              {[1, 2, 3].map((n) => (
                <Skeleton
                  width='150px'
                  height='60px'
                  style={{ marginRight: '3px' }}
                />
              ))}
            </>
          ) : (
            eraOptions?.data?.map((el, id) => (
              <button
                className={
                  eraValue === el?.rp_company_assmt_id
                    ? 'btn-assment-month month-active'
                    : 'btn-assment-month prev-mnth'
                }
                key={id}
              >
                <span>{el?.display_tab_up}</span>
                <span>{el?.display_tab_down}</span>
              </button>
            ))
          )}
        </div>
        <div
          className={`assessment-tabs-group rp-ora ${
            oraOptions?.loading ? 'd-flex' : ''
          }`}
        >
          {oraOptions?.loading ? (
            <>
              {[1, 2, 3].map((n) => (
                <Skeleton
                  width='150px'
                  height='60px'
                  style={{ marginRight: '3px' }}
                />
              ))}
            </>
          ) : (
            oraOptions?.data?.map((el, id) => (
              <button className='btn-assment-month prev-mnth' key={id}>
                <span>{el?.display_tab_up}</span>
                <span>{el?.display_tab_down}</span>
              </button>
            ))
          )}
        </div>
      </div>
      <div className='bg-white px-3 py-4 br-8'>
        <div className='d-flex justify-content-between'>
          <div className='col d-flex align-items-center'>
            <div className='d-flex align-items-center'>
              <span
                style={{
                  fontWeight: '500',
                  fontSize: '16px',
                  lineHeight: '20px',
                }}
              >
                Total Users : 85
              </span>
              <div
                style={{
                  height: '100%',
                  width: '1px',
                  backgroundColor: '#818181',
                  margin: '0 20px',
                }}
              ></div>
              <button
                style={{
                  backgroundColor: '#f5f5f5',
                  minWidth: '150px',
                  height: '45px',
                  borderRadius: '8px',
                  padding: '0.6rem 0.9rem',
                }}
              >
                <span className='me-3'>Send Email</span>
                <span>
                  <i className='fa-solid fa-angle-down'></i>
                </span>
              </button>
            </div>
            <div
              style={{
                height: '100%',
                width: '1px',
                backgroundColor: '#818181',
                margin: '0 20px',
              }}
            ></div>
            <div className='d-flex align-items-center'>
              <span
                style={{
                  fontWeight: '500',
                  fontSize: '16px',
                  lineHeight: '20px',
                }}
              >
                Filter by :
              </span>
              <div
                style={{
                  height: '100%',
                  width: '1px',
                  backgroundColor: '#818181',
                  margin: '0 20px',
                }}
              ></div>
              <button
                style={{
                  backgroundColor: '#f5f5f5',
                  minWidth: '150px',
                  height: '45px',
                  borderRadius: '8px',
                  padding: '0.6rem 0.9rem',
                }}
              >
                <span className='me-3'>Assessment given</span>
                <span>
                  <i className='fa-solid fa-angle-down'></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BothFilter;
