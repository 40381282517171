import React, { useEffect, useState } from 'react';
import TreeDropdown from '../../../../../../common/NestedDropDown';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { saveFilters } from '../../../../../../store/filters/actions';

const Sidebar = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filterOptions, setFilterOptions] = useState({
    data: [],
    loading: true,
  });
  const [clearValue, setClearValue] = useState();
  const userData = useSelector((state) => state?.user);
  const location = useLocation();
  const loc = location.pathname.split('/');
  const userState = useSelector((state) => state?.user);
  const companyTypeId = useSelector((state) => state?.companyTypeId?.payload);
  const [showDropdown, setShowDropdown] = useState();
  const [count, setCount] = useState([]);
  const [clearAll, setClearAll] = useState(false);

  useEffect(() => {
    getFilterOptions();
  }, [companyTypeId]);

  useEffect(() => {
    if (loc[3] === 'people' || loc[3] === 'corporate') {
      props.handleCount(count);
    }
  }, [count]);

  const getFilterOptions = async () => {
    try {
      const query = window.$utility.queryParamaBuilder(
        userState,
        companyTypeId
      );
      const result = await window.$http.getWithQueryAndHeaders(
        `${window.$utility.setApiUrl(userState)}analytics/filters`,
        query
      );
      if (result?.code === window?.$constants?.STATUS?.OK) {
        setFilterOptions({ data: result?.data?.filters, loading: false });
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const clearData = async (val) => {
    if (val === 'value') {
      setClearValue(false);
    } else {
      setClearValue(true);
    }
  };

  const clearFilterData = async () => {
    setClearAll(true);
    setShowDropdown();
    setCount([]);
    userData?.analytics_visible_options?.map((el) =>
      dispatch(saveFilters({}, `${el}`))
    );
    await clearData('clear');
  };

  const handleCountLength = () => {
    setClearAll(false);
  };

  const handletab = (value) => {
    return navigate(`/dashboard/company-analytics/${value}`);
  };

  function renderTab() {
    return (
      <>
        {userData?.analytics_visible_options?.some(
          (el) => el === 'dashboard'
        ) ? (
          <li
            className={`${
              loc[3] === 'dashboard' ? 'side-nav active_li' : 'side-nav'
            }`}
            onClick={() => handletab('dashboard')}
          >
            <Link to='#' className='li_link'>
              <span className='li_icon'>
                <i className='flaticon-dashboard'></i>
              </span>
              <span className='li_title'>Company Dashboard</span>
            </Link>
          </li>
        ) : (
          ''
        )}

        {userData?.analytics_visible_options?.some((el) => el === 'people') ? (
          <li
            className={`${
              loc[3] === 'people' || props?.reportValue === 'report'
                ? 'side-nav active_li'
                : 'side-nav'
            }`}
            onClick={() => handletab('people')}
          >
            <Link to='#' className='li_link'>
              <span className='li_icon'>
                <i className='flaticon-people-resiliency'></i>
              </span>
              <span className='li_title'>{(userState?.admin_display_text_obj?.web_admin_text)} Resiliency</span>
            </Link>
          </li>
        ) : (
          ''
        )}

        {userData?.analytics_visible_options?.some(
          (el) => el === 'corporate'
        ) ? (
          <li
            className={`${
              loc[3] === 'corporate' ? 'side-nav active_li' : 'side-nav'
            }`}
            onClick={() => handletab('corporate')}
          >
            <Link to='#' className='li_link'>
              <span className='li_icon'>
                <i className='flaticon-building'></i>
              </span>
              <span className='li_title'>Corporate Resiliency</span>
            </Link>
          </li>
        ) : (
          ''
        )}
        {userData?.analytics_visible_options?.some(
          (el) => el === 'workshop'
        ) ? (
          <li
            className={`${
              loc[3] === 'workshop' ? 'side-nav active_li' : 'side-nav'
            }`}
            onClick={() => handletab('workshop')}
          >
            <Link to='#' className='li_link'>
              <span className='li_icon'>
                <i className='flaticon-yoga'></i>
              </span>
              <span className='li_title'>Workshops</span>
            </Link>
          </li>
        ) : (
          ''
        )}
      </>
    );
  }

  const handleDropdown = (value) => {
    if (showDropdown === value) {
      setShowDropdown('');
    } else {
      setShowDropdown(value);
    }
  };

  const handleCount = (key, value) => {
    if (value) {
      count[key] = value;
      setCount(count);
    } else {
      delete count[key];
    }
  };

  return (
    <div className='side_nav open'>
      <div className='sidebar'>
        <div className='sidebar_list'>
          <div className='py-4'>
            <ul style={{ padding: '0' }}>
              {renderTab()}
              {props?.reportValue !== 'report' ? (
                props?.workshopValue !== 'workshop' ? (
                  <div className='filter-sec mt-4'>
                    <div className='filter-sep'></div>
                    <div className='side-nav'>
                      <div className=' d-flex align-items-center'>
                        <span className='li_icon'>
                          <i className='flaticon-filter'></i>
                        </span>
                        <span className='li_title'>Filters</span>
                      </div>
                    </div>
                    <div className='filter-sep mb-4'></div>
                    {filterOptions?.loading ? (
                      [1, 2, 3, 4].map((el) => (
                        <div style={{ marginTop: '8px', marginBottom: '15px' }}>
                          <Skeleton height='48px' />
                        </div>
                      ))
                    ) : (
                      <>
                        {filterOptions?.data?.map((el, id) => (
                          <div className='select-options' key={id}>
                            <div className='toggle-input'>
                              <div
                                className='dropdown-check-list list'
                                tabIndex='100'
                              >
                                <div
                                  className='form-control'
                                  style={{
                                    minHeight: '48px',
                                    height: 'revert',
                                    padding: '5px 0px',
                                    paddingBottom: '0px',
                                  }}
                                >
                                  <div
                                    className='d-flex align-items-center justify-content-between cursor-p'
                                    onClick={() => handleDropdown(el?.label)}
                                    style={{
                                      borderBottom: `${
                                        showDropdown === el?.label
                                          ? '1px solid #ccc'
                                          : ''
                                      }`,
                                      marginBottom: `${
                                        showDropdown === el?.label ? '10px' : ''
                                      }`,
                                    }}
                                  >
                                    <div
                                      className='check-titel'
                                      style={{
                                        color: 'black',
                                        paddingLeft: '10px',
                                      }}
                                    >
                                      {el?.label}

                                      {clearAll ? (
                                        ''
                                      ) : count[el?.value] ? (
                                        <span
                                          style={{
                                            'background-color': '#c6f6f5',
                                            color: '#42526E',
                                            'border-radius': '44px',
                                            padding: '5px 10px',
                                            marginLeft: '10px',
                                          }}
                                        >
                                          {count[el?.value]}
                                        </span>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                    <button
                                      style={{
                                        fontSize: '25px',
                                        marginRight: '12px',
                                        fontWeight: '500',
                                      }}
                                    >
                                      {showDropdown === el?.label ? '-' : '+'}
                                    </button>
                                  </div>
                                  <div
                                    className={` ${
                                      showDropdown === el?.label
                                        ? 'show'
                                        : 'hide'
                                    }`}
                                  >
                                    <TreeDropdown
                                      nodes={el?.data}
                                      optionName={el?.value}
                                      apiCallsWithoutLevelId={
                                        loc[3] === 'corporate'
                                          ? props?.apiCallsWithoutLevelId
                                          : ''
                                      }
                                      apiCalls={props?.apiCalls}
                                      clearValue={clearValue}
                                      clearData={clearData}
                                      filterOptions={el}
                                      filterData={props?.getFilterValues}
                                      handleCount={handleCount}
                                      handleCountLength={handleCountLength}
                                      showDropdown={showDropdown}
                                      setFactorTabIndex={
                                        props?.setFactorTabIndex
                                      }
                                      count={el?.count}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    )}

                    <button
                      type='button'
                      className='btn btn-rp-light btn-full'
                      title='Reset'
                      onClick={clearFilterData}
                    >
                      Reset
                    </button>
                  </div>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
