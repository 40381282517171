import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import '../../../config';
import Header from '../../../layout/Components/Header';
import Footer from '../../../layout/Components/Footer';
import { isEmpty } from 'lodash';
import LoadingSpinner from '../../../common/LoadingSpinner';

const ViewProfile = () => {
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState('');
  const [formValues, setFormValues] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fullName =
    (!isEmpty(profileData?.first_name) ? profileData?.first_name : '') +
    ' ' +
    (!isEmpty(profileData?.last_name) ? profileData?.last_name : '');

  useEffect(() => {
    const getProfileData = async () => {
      setIsLoading(true);
      try {
        const result = await window.$http.getWithHeaders('v2/my_profile');

        if (result.code === window.$constants.STATUS.OK) {
          setIsLoading(false);
          setProfileData(result.data);
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };

    getProfileData();
    getList();
  }, []);

  const getList = async () => {
    try {
      const result = await window.$http.getWithHeaders(
        'lookup?types=country,gender,employee_status,experience,occupation,education'
      );

      if (result.code === window.$constants.STATUS.OK) {
        setFormValues(result.data);
      }

      return await result.data;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Header />
      <main>
        <section>
          <div className='container'>
            <div className='row py-4 pt-0'>
              <div className='bg-white br-12 view-profile box_shadow py-5 px-4'>
                {isLoading ? (
                  <div className='centered' style={{ marginLeft: '1rem' }}>
                    <LoadingSpinner />
                  </div>
                ) : (
                  <>
                    <div className='col-12 text-center'>
                      <div className='cursor-p me-2'>
                        <div className='profile-icon m-auto'>
                          {profileData
                            ? window.$utility.getInitials(fullName)
                            : ''}
                        </div>
                      </div>
                      <h1 className='section-sub-heading mt-2 mb-0 text-center rp-primary-dark-gray'>
                        {profileData ? fullName : ''}
                      </h1>
                    </div>

                    <div className='row mt-5 mb-0 my-xl-5 profile-tabs'>
                      <div className='col-md-4 mb-4'>
                        <div className='d-flex py-3 px-4 align-items-center profile-tab'>
                          <div className='me-3'>
                            <i className='fa-solid fa-calendar-days fa-3x'></i>
                          </div>
                          <div>
                            <div className='tab-titel'>Date of Birth</div>
                            <div className='tab-sub-titel'>
                              {profileData?.dob
                                ? moment(profileData?.dob).format('ll')
                                : ''}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-4 mb-4'>
                        <div className='d-flex py-3 px-4 align-items-center profile-tab'>
                          <div className='me-3'>
                            <i className='fa-solid fa-venus-mars fa-3x'></i>
                          </div>
                          <div>
                            <div className='tab-titel'>Gender</div>
                            {formValues?.gender?.map(
                              (data, id) =>
                                data?.id === profileData?.rp_gbl_gender_id && (
                                  <div className='tab-sub-titel' key={id}>
                                    {data.value}
                                  </div>
                                )
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='col-md-4 mb-4'>
                        <div className='d-flex py-3 px-4 align-items-center profile-tab'>
                          <div className='me-3'>
                            <i className='fa-solid fa-globe fa-3x'></i>
                          </div>
                          <div>
                            <div className='tab-titel'>Country</div>
                            {formValues?.country?.map(
                              (data, id) =>
                                data?.id === profileData?.rp_gbl_country_id && (
                                  <div className='tab-sub-titel' key={id}>
                                    {data?.value}
                                  </div>
                                )
                            )}
                          </div>
                        </div>
                      </div>
                      {profileData?.rp_gbl_company_type_id ? (
                        profileData?.rp_gbl_company_type_id ===
                          window.$enums.CompanyTypeId.Personal ||
                        profileData?.rp_gbl_company_type_id ===
                          window.$enums.CompanyTypeId.Clinical ? (
                          <>
                            <div className='col-md-4 mb-4 mb-lg-0'>
                              <div className='d-flex py-3 px-4 align-items-center profile-tab'>
                                <div className='me-3'>
                                  <i className='fa-solid fa-street-view fa-3x'></i>
                                </div>
                                <div>
                                  <div className='tab-titel'>Occupation</div>
                                  {formValues?.occupation?.map(
                                    (data, id) =>
                                      data?.id ===
                                        profileData?.rp_gbl_occupation_id && (
                                        <div className='tab-sub-titel' key={id}>
                                          {data?.value}
                                        </div>
                                      )
                                  )}
                                </div>
                              </div>
                            </div>
                            {profileData?.rp_gbl_occupation_id ===
                            window.$enums.OccupationType.Student ? (
                              <>
                                <div className='col-md-4 mb-4 mb-lg-0'>
                                  <div className='d-flex py-3 px-4 align-items-center profile-tab'>
                                    <div className='me-3'>
                                      <i className='fa-solid fa-graduation-cap fa-3x'></i>
                                    </div>
                                    <div>
                                      <div className='tab-titel'>Education</div>
                                      {formValues?.education?.map(
                                        (data, id) =>
                                          data?.id ===
                                            profileData?.rp_gbl_education_id && (
                                            <div
                                              className='tab-sub-titel'
                                              key={id}
                                            >
                                              {data?.value}
                                            </div>
                                          )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : profileData?.rp_gbl_occupation_id ===
                              window.$enums.OccupationType.HouseManager ? (
                              <></>
                            ) : (
                              ''
                            )}
                          </>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}

                      {(profileData?.rp_gbl_company_type_id ===
                        window.$enums.CompanyTypeId.Employee ||
                        (profileData?.rp_gbl_company_type_id !==
                          window.$enums.CompanyTypeId.Employee &&
                          profileData?.rp_gbl_occupation_id ===
                            window.$enums.OccupationType.Employee)) && (
                        <>
                          <div className='col-md-4 mb-4 mb-lg-0'>
                            <div className='d-flex py-3 px-4 align-items-center profile-tab'>
                              <div className='me-3'>
                                <i className='fa-solid fa-street-view fa-3x'></i>
                              </div>
                              <div>
                                <div className='tab-titel'>
                                  How long at your present position
                                </div>

                                {formValues?.experience?.map(
                                  (data, id) =>
                                    data.id ===
                                      profileData?.rp_present_experience_id && (
                                      <div className='tab-sub-titel' key={id}>
                                        {data?.value}
                                      </div>
                                    )
                                )}
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 mb-4 mb-lg-0'>
                            <div className='d-flex py-3 px-4 align-items-center profile-tab'>
                              <div className='me-3'>
                                <i className='fa-solid fa-user-tie fa-3x'></i>
                              </div>
                              <div>
                                <div className='tab-titel'>Employee status</div>
                                {formValues?.employee_status?.map(
                                  (data, id) =>
                                    data.id ===
                                      profileData?.rp_gbl_employee_status_id && (
                                      <div className='tab-sub-titel' key={id}>
                                        {data?.value}
                                      </div>
                                    )
                                )}
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 mb-4 mb-lg-0'>
                            <div className='d-flex py-3 px-4 align-items-center profile-tab'>
                              <div className='me-3'>
                                <i className='fa-solid fa-timeline fa-3x'></i>
                              </div>
                              <div>
                                <div className='tab-titel'>
                                  Your total experience
                                </div>
                                {formValues?.experience?.map(
                                  (data, id) =>
                                    data?.id ===
                                      profileData?.rp_total_experience_id && (
                                      <div className='tab-sub-titel' key={id}>
                                        {data?.value}
                                      </div>
                                    )
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    {profileData &&
                    profileData?.rp_gbl_company_type_id !==
                      window.$enums.CompanyTypeId.CompanyAdmin ? (
                      <button
                        className='btn btn-rp-primary me-4'
                        onClick={() => navigate('/edit-profile')}
                      >
                        Edit
                      </button>
                    ) : (
                      ''
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default ViewProfile;
