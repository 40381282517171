import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { saveTabs } from '../../../../../../store/tabs/actions';

const EraFilter = (props) => {
  const dispatch = useDispatch();
  const [eraOptions, setEraOptions] = useState({
    data: [],
    loading: true,
  });
  const [eraArray, setEraArray] = useState([]);
  const [eraValue, setEraValue] = useState('');

  const location = useLocation();
  const loc = location.pathname.split('/');

  const userState = useSelector((state) => state?.user);
  const companyTypeId = useSelector((state) => state?.companyTypeId?.payload);
  const tabState = useSelector(
    (state) => state?.tabs?.analytics?.[`${loc[3]}`]
  );

  useEffect(() => {
    if (loc[2] === 'company-analytics' && !eraValue) {
      props?.getAssmtId(eraOptions?.data[0]?.rp_company_assmt_id);
      setEraValue(eraOptions?.data[0]?.rp_company_assmt_id);
    }
  }, [loc]);

  useEffect(() => {
    props?.getAssmtId(tabState);
    return setEraValue(tabState);
  }, [tabState]);

  useEffect(() => {
    getEraOptions();
  }, [companyTypeId]);

  const getEraOptions = async () => {
    try {
      const query = window.$utility.queryParamaBuilder(
        userState,
        companyTypeId
      );
      const result = await window.$http.getWithQueryAndHeaders(
        `${window.$utility.setApiUrl(userState)}analytics/tabs/era`,
        query
      );
      if (result.code === window.$constants.STATUS.OK) {
        setEraOptions({ data: result?.data?.tabs, loading: false });
        if (props?.value !== 'report') {
          if (!tabState) {
            props?.getEraStatus(
              result?.data?.tabs[0]?.is_enabled,
              result?.data?.tabs[0]?.disable_message,
              result?.data?.tabs[0]?.is_reminder_enabled,
              result?.data?.tabs[0]?.is_stress_assmt_available
            );
          } else {
            props?.getEraStatus(
              result?.data?.tabs?.find(
                (el) => el?.rp_company_assmt_id === tabState
              )?.is_enabled,
              result?.data?.tabs?.find(
                (el) => el?.rp_company_assmt_id === tabState
              )?.disable_message,
              result?.data?.tabs?.find(
                (el) => el?.rp_company_assmt_id === tabState
              )?.is_reminder_enabled,
              result?.data?.tabs?.find(
                (el) => el?.rp_company_assmt_id === tabState
              )?.is_stress_assmt_available
            );
          }
        }
        if (loc[3] === 'people') {
          props?.getEraLength(result?.data?.tabs);
        }
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getEraArray();
  }, [eraOptions?.data?.length]);

  const getEraArray = () => {
    let eraData = eraOptions?.data;
    let eraArr = eraArray;
    if (eraData?.length) {
      for (let i = 1; i <= eraData?.length; i++) {
        eraArr?.push(i);
      }
    }
    setEraArray(eraArr);
  };

  const handleEraValue = (el) => {
    if (loc[3] === 'dashboard') {
      dispatch(saveTabs(el?.rp_company_assmt_id, 'dashboard'));
    } else if (loc[3] === 'people') {
      dispatch(saveTabs(el?.rp_company_assmt_id, 'people'));
    } else {
      dispatch(saveTabs(el?.rp_company_assmt_id, 'report'));
    }
    setEraValue(el?.rp_company_assmt_id);
    props?.getAssmtId(el?.rp_company_assmt_id);
    if (props?.value !== 'report') {
      props?.getEraStatus(
        el?.is_enabled,
        el?.disable_message,
        el?.is_reminder_enabled,
        el?.is_stress_assmt_available
      );
    }
  };

  return (
    <>
      <div
        className={`assessment-tabs-group ${
          eraOptions?.loading ? 'd-flex' : ''
        }`}
      >
        {eraOptions?.loading ? (
          <>
            {[1, 2, 3]?.map((n, id) => (
              <Skeleton
                key={id}
                width='150px'
                height='60px'
                style={{ marginRight: '3px' }}
              />
            ))}
          </>
        ) : (
          eraOptions?.data?.map((el, id) => (
            <button
              className={
                (
                  props?.value !== 'report'
                    ? (eraValue ? eraValue : tabState) ===
                      el?.rp_company_assmt_id
                    : props?.state?.selectedEra1Tab?.rp_company_assmt_id ===
                        el?.rp_company_assmt_id ||
                      props?.state?.selectedEra2Tab?.rp_company_assmt_id ===
                        el?.rp_company_assmt_id
                )
                  ? 'btn-assment-month month-active'
                  : props?.value !== 'report'
                  ? 'btn-assment-month prev-mnth'
                  : 'btn-assment-month prev-mnth disabled'
              }
              key={id}
              onClick={() => handleEraValue(el)}
              style={id > 7 ? { marginTop: '10px' } : {}}
            >
              <span>{el?.display_tab_up}</span>
              <span>{el?.display_tab_down}</span>
            </button>
          ))
        )}
      </div>
    </>
  );
};

export default EraFilter;
