import React from 'react';

const PreLoader = () => {
  return (
    <div className='preloader' style={{ display: 'block' }} id='ido1'>
      <div className='dot'></div>
      <div className='dot'></div>
      <div className='dot'></div>
      <div className='dot'></div>
    </div>
  );
};

export default PreLoader;
