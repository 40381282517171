import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const NavigateUsers = ({ element, ...rest }) => {
  const authState = useSelector((state) => state.auth);
  const userState = useSelector((state) => state?.user);

  return (
    <>
      {authState?.isAuthenticated && userState?.is_web_enabled ? (
        <Routes>
          <Route
            path='*'
            element={
              userState?.rp_gbl_company_type_id ===
              window.$enums.CompanyTypeId.CompanyAdmin ? (
                <Navigate to='/manage' />
              ) : userState?.visible_header_tabs?.includes('admin') ? (
                <Navigate to='/manage' />
              ) : // ) : !userState?.is_web_enabled ? (
              //   <Navigate to='/landing-page' />
              userState.onboarding_step === 0 ? (
                <Navigate to='/on-boarding' />
              ) : userState.onboarding_step === 1 ? (
                <Navigate to='/phone-verification' />
              ) : userState.onboarding_step === 2 &&
                (userState.landing_page === 0 ||
                  userState.landing_page === 1) ? (
                <Navigate to='/dashboard' />
              ) : userState.onboarding_step === 2 &&
                userState.landing_page === 2 ? (
                <Navigate to='/dashboard/resiliency-plan' />
              ) : (
                userState.onboarding_step === 2 &&
                userState.landing_page === 3 && (
                  <Navigate to='/dashboard/assessments/result/0' />
                )
              )
            }
          />
        </Routes>
      ) : (
        <Routes>
          <Route path='/' element={element} />
        </Routes>
      )}
    </>
  );
};

export default NavigateUsers;
