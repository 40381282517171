import { SAVEUSERASSMTID, REMOVEUSERASSMTID } from './types';

const INITIAL_STATE = {};

const userAssmtId = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SAVEUSERASSMTID: {
      return {
        ...state,
        payload,
      };
    }
    case REMOVEUSERASSMTID: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default userAssmtId;
