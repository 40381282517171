import Axios from 'axios';
import { stringify } from 'qs';
import './utility';
import store from '../store';
import { save } from '../store/user/actions';
import { saveRevision } from '../store/revision/actions';
import { login } from '../store/auth/actions';

const CreateAxios = () => {
  const axios = Axios.create();
  axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.defaults.withCredentials = true;
  axios.defaults.headers.common['subdomain'] =
    process.env.REACT_APP_ENV === 'production'
      ? window.$utility.getSubDomain()
      : 'qa-demo';
  // axios.defaults.headers.common['subdomain'] = 'qa-admin';
  // axios.defaults.headers.common['subdomain'] = 'qa-demo';

  let value = false;
  let refreshPromise = null;
  const clearPromise = () => (refreshPromise = null);

  axios.interceptors.request.use(
    (config) => {
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    async (response) => {
      if (response.data.code === window.$constants.STATUS.INVALID_SUBDOMAIN) {
        window.location.href = '/404';
        window.$utility.logoutUser();
      }
      if (
        value === false &&
        store.getState().auth?.isAuthenticated &&
        !store.getState().auth?.token?.refresh_token
      ) {
        value = true;
        upgradeToken();
      }
      if (response.data.code === window.$constants.STATUS.UNAUTHORIZED) {
        if (store.getState().auth?.isAuthenticated && !response.config._retry) {
          response.config._retry = true;

          if (!refreshPromise) {
            refreshPromise = updateToken().finally(clearPromise);
          }

          const token = await refreshPromise;
          response.config.headers['Authorization'] = `Bearer ${
            store.getState().auth?.token?.access_token
          }`;

          return axios.request(response.config);
        }
        if (store.getState().auth?.token?.access_token) {
          if (window.location.href.indexOf('dailylinks/') > -1) {
            window.$utility.logoutUser();
            window.location.href = `${window.location.pathname}`;
          } else {
            window.location.href = '/login';
            window.$utility.logoutUser();
          }
        }
        if (
          store.getState().auth?.isAuthenticated &&
          !store.getState().auth?.token?.refresh_token
        ) {
          window.location.href = '/login';
          window.$utility.logoutUser();
        }
      }
      if (
        response.data.code === window.$constants.STATUS.UNPROCESSABLE_CONTENT
      ) {
        window.location.href = '/login';
        window.$utility.logoutUser();
      }
      if (response.data.code === window.$constants.STATUS.FORBIDDEN) {
        console.log('Insufficient Rights');
        // const loc = window.location.pathname.split('/');
        // if (loc[2] !== 'company-analytics') {
        //   window.location.href = '/404';
        // }
      }
      return response;
    },
    (error) => {
      if (error && error.response) {
        if (
          error?.response?.data?.code ===
          window.$constants.STATUS.UNDER_MAINTAINENCE
        ) {
          if (window.location.pathname !== '/maintenance') {
            window.location.href = '/maintenance';
          }
          return Promise.reject(error.response.data);
        } else {
          return Promise.reject(error.response.data);
        }
      }
      return Promise.reject(error);
    }
  );
  return axios;
};

const { dispatch } = store;

const upgradeToken = async () => {
  try {
    const result = await window.$http.getWithHeaders('token/upgrade');
    if (result.code === window.$constants.STATUS.OK) {
      if (store.getState().auth?.isAuthenticated) {
        dispatch(login(result));
      }
    } else {
      console.log('Something went wrong!');
    }
  } catch (error) {
    console.log(error);
  }
};

const updateToken = async () => {
  try {
    if (store.getState().auth?.token?.refresh_token) {
      const result = await window.$http.postWithHeaders('oauth/token', {
        grant_type: 'refresh_token',
        refresh_token: store.getState().auth?.token?.refresh_token,
        device_id: 'RP123456789',
      });
      if (result.code === window.$constants.STATUS.OK) {
        dispatch(login(result));
      } else {
        console.log('Something went wrong!');
      }
    }
  } catch (error) {
    console.log(error);
  }
};

const updateProfileData = async () => {
  try {
    const result = await window.$http.getWithHeaders('v2/my_profile');
    if (result.code === window.$constants.STATUS.OK) {
      dispatch(save(result));
      if (!result?.data?.is_web_enabled) {
        window.location.href = '/landing-page';
      }
    } else {
      console.log('Something went wrong!');
    }
  } catch (error) {
    console.log(error);
  }
};

const updateRevision = async (result) => {
  dispatch(saveRevision(result));
};

const api = CreateAxios();

const service = {
  getHeaders() {
    return {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${
        store.getState().auth?.token?.access_token
          ? store.getState().auth?.token?.access_token
          : store.getState().auth?.token
      }`,
    };
  },

  checkRevision(data) {
    const revision = store.getState().revision;
    if (
      !revision?.payload ||
      Object.keys(revision?.payload).length ||
      data.revision > revision.payload
    ) {
      updateProfileData();
      updateRevision(data.revision);
    }
  },

  // post services
  async postWithoutHeaders(route, body, loading = true) {
    const { data } = await api.post(route, body);
    return data;
  },

  async postWithQueryAndHeaders(route, query = {}, body) {
    const headers = this.getHeaders();
    const { data } = await api.post(`${route}?${stringify(query)}`, body, {
      headers,
    });
    return data;
  },

  async postWithHeaders(route, body, loading = true) {
    const headers = this.getHeaders();
    const { data } = await api.post(route, body, {
      headers,
    });
    return data;
  },

  async rawPost(route, loading = true) {
    const headers = this.getHeaders();
    const { data } = await api.post(route, {
      headers,
    });

    return data;
  },

  //get services
  async getWithoutHeaders(route, loading = true) {
    const { data } = await api.get(route);
    this.checkRevision(data);
    return data;
  },

  async get(route, query = {}, loading = true) {
    const { data } = await api.get(`${route}?${stringify(query)}`);
    this.checkRevision(data);
    return data;
  },

  async getWithHeaders(route, loading = true) {
    const headers = this.getHeaders();
    const { data } = await api.get(`${route}`, {
      headers,
    });
    this.checkRevision(data);
    return data;
  },

  async getWithQueryAndHeaders(route, query = {}, loading = true) {
    const headers = this.getHeaders();
    const { data } = await api.get(`${route}?${stringify(query)}`, {
      headers,
    });
    this.checkRevision(data);
    return data;
  },

  async getById(route, id, loading = true) {
    const headers = this.getHeaders();
    const { data } = await api.get(`${route}/${id}`, {
      headers,
    });
    this.checkRevision(data);
    return data;
  },

  // put services
  async update(route, query = {}, body) {
    const headers = this.getHeaders();
    const { data } = await api.put(`${route}?${stringify(query)}`, body, {
      headers,
    });
    return data;
  },

  async putWithoutHeaders(path) {
    const { data } = await api.put(path);
    return data;
  },

  async put(path) {
    const headers = this.getHeaders();
    const { data } = await api.put(path, {
      headers,
    });
    return data.object;
  },

  async updateById(route, id, body) {
    const headers = this.getHeaders();
    const { data } = await api.put(`${route}`, body, {
      headers,
    });
    return data.object;
  },

  async updateOrCreate(route, body) {
    const headers = this.getHeaders();

    let response;

    if (body.id) {
      response = await this.updateById(route, body.id, body, {
        headers,
      });
    } else {
      response = await this.rawPost(route, body, {
        headers,
      });
    }
    return response;
  },

  // patch services
  async patch(route, body) {
    const headers = this.getHeaders();
    const { data } = await api.patch(`${route}`, body, {
      headers,
    });
    return data.object;
  },

  // delete services
  async delete(route) {
    const headers = this.getHeaders();
    return api.delete(`${route}`, {
      headers,
    });
  },

  async deleteWithQueryAndHeaders(route, query = {}, loading = true) {
    const headers = this.getHeaders();
    return await api.delete(`${route}?${stringify(query)}`, {
      headers,
    });
  },

  async logoutUser(route) {
    const headers = this.getHeaders();
    const { data } = await api.get(`${route}`, {
      headers,
    });

    return data;
  },

  test() {
    console.log('http service is working fine.');
  },
};

window.$http = service;

export default service;
