import { SAVETABS, REMOVETABS } from './types';

const INITIAL_STATE = {};
let tabArray = {};

const tabs = (state = INITIAL_STATE, { type, payload, term }) => {
  switch (type) {
    case SAVETABS: {
      if (!state?.analytics || Object.keys(state?.analytics).length === 0) {
        tabArray[`${term}`] = payload;
      } else {
        tabArray = state?.analytics;
        Object.keys(tabArray).forEach((key) => {
          if (key === term) {
            tabArray[key] = payload;
          } else {
            tabArray[`${term}`] = payload;
          }
        });
      }
      return {
        ...state,
        analytics: tabArray,
      };
    }
    case REMOVETABS: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default tabs;
