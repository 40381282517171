import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../../../../layout/Components/Header';
import Footer from '../../../../layout/Components/Footer';

const CompanyAnalytics = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const loc = location.pathname.split('/');
  const userData = useSelector((state) => state?.user);

  useEffect(() => {
    if (loc[3] !== 'report') {
      if (isEmpty(userData?.analytics_visible_options)) {
        navigate('/dashboard');
      } else if (loc[3]) {
        if (!userData?.analytics_visible_options?.some((el) => el === loc[3])) {
          navigate(
            `/dashboard/company-analytics/${userData?.analytics_visible_options[0]}`
          );
        } else {
          navigate(`/dashboard/company-analytics/${loc[3]}`);
        }
      } else {
        navigate(
          `/dashboard/company-analytics/${userData?.analytics_visible_options[0]}`
        );
      }
    }
  }, [loc[2]]);

  return (
    <>
      <Header />
      {!isEmpty(userData?.analytics_visible_options) ? children : ''}
      <Footer />
    </>
  );
};

export default CompanyAnalytics;
