import React, { useEffect, useState } from 'react';
import Creatable from 'react-select/creatable';

export default function CreatableSelect(props) {
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedInputOption, setSelectedInputOption] = useState('');
  const [options, setOptions] = useState();

  useEffect(() => {
    if (props?.options) {
      setOptions(props?.options);
    }
  }, [props?.options]);

  useEffect(() => {
    if (props?.designationLevel) {
      return setSelectedOption({
        value: props?.designationLevel,
        label: props?.designationLevel,
      });
    }
  }, [props?.designationLevel]);

  useEffect(() => {
    if (props?.deptDiscipline) {
      return setSelectedOption({
        value: props?.deptDiscipline,
        label: props?.deptDiscipline,
      });
    }
  }, [props?.deptDiscipline]);

  const handleChange = (field, value) => {
    if (options?.some((el) => el?.value === value?.value) && value?.__isNew__)
      return;
    switch (field) {
      case 'designation':
        setSelectedOption(value);
        props?.getValue({
          designation_level: value?.value,
        });
        setOptions((prev) => [...prev, value]);
        break;

      case 'department':
        setSelectedOption(value);
        props?.getValue({
          dept_discipline: value?.value,
        });
        setOptions((prev) => [...prev, value]);
        break;

      default:
        break;
    }
  };

  const handleKeyDown = (event) => {
    if (!selectedInputOption) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setSelectedOption([
          // ...selectedOption,
          createOption(selectedInputOption),
        ]);
        setSelectedInputOption('');

        // event.preventDefault();
        break;

      default:
        break;
    }
  };

  const handleBlur = () => {
    if (!selectedInputOption) return;
    setSelectedOption([createOption(selectedInputOption)]);
    handleChange(
      props?.label === 'Designation' ? 'designation' : 'department',
      {
        label: selectedInputOption,
        value: selectedInputOption,
        __isNew__: true,
      }
    );
    setSelectedInputOption('');
  };

  const createOption = (label) => ({
    label,
    value: label,
  });

  const handleInputChange = (field, value) => {
    switch (field) {
      case 'designation':
        setSelectedInputOption(value);
        break;

      case 'department':
        setSelectedInputOption(value);
        break;

      default:
        break;
    }
  };

  return (
    <div className='App'>
      <Creatable
        className={
          props?.label === 'Designation'
            ? props?.isSubmit && !selectedOption
              ? 'custom-dropdown-select select_error'
              : 'custom-dropdown-select'
            : props?.label === 'Department'
            ? props?.isSubmit && !selectedOption
              ? 'custom-dropdown-select select_error'
              : 'custom-dropdown-select'
            : ''
        }
        classNamePrefix='company'
        inputValue={selectedInputOption}
        onBlur={handleBlur}
        onChange={(value) =>
          handleChange(
            props?.label === 'Designation' ? 'designation' : 'department',
            value
          )
        }
        onKeyDown={handleKeyDown}
        options={options}
        onInputChange={(value) =>
          handleInputChange(
            props?.label === 'Designation' ? 'designation' : 'department',
            value
          )
        }
        value={selectedOption}
        styles={{
          dropdownIndicator: (provided, state) => ({
            ...provided,
            transform: state?.selectProps?.menuIsOpen && 'rotate(180deg)',
          }),
        }}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: '#fff',
            primary: '#fff',
            primary50: '#fff',
            neutral10: '#fff',
            neutral30: '#fff',
            neutral20: '#fff',
            dangerLight: '#fff',
          },
        })}
        placeholder='Select'
      />
    </div>
  );
}
