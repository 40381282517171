import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../../../layout/Components/Header';
import Footer from '../../../layout/Components/Footer';
import MobileVerification from '../../../common/MobileVerification';

const initialState = {
  currentPassword: '',
  password: '',
  confirmPassword: '',
};

const initialErrorState = {
  currentPassword: false,
  password: false,
  confirmPassword: false,
};

const initialShowState = {
  password: false,
  confirmPassword: false,
  currentPassword: false,
};

const device_id = 'RP123456789';

const Setting = () => {
  const navigate = useNavigate();
  const [state, setState] = useState(initialState);
  const [show, setShow] = useState(initialShowState);
  const [error, setError] = useState(initialErrorState);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const email = useSelector((state) => state?.user).email;
  const user = useSelector((state) => state?.user);
  const settings_visible_options = useSelector(
    (state) => state?.user
  )?.settings_visible_options;
  const [message, setMessage] = useState({
    success: '',
    failure: '',
  });
  const pwdRegex =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*+.-_:~`]).{9,20}$/i;
  const [settingsData, setSettingsData] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [active, setActive] = useState('');

  useEffect(() => {
    setActive(settings_visible_options[0]);

    const getSettingsData = async () => {
      try {
        const result = await window.$http.getWithHeaders('settings');

        if (result?.code === window.$constants.STATUS.OK) {
          setSettingsData(result?.data);
        } else {
          console.log(result?.message);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getSettingsData();
  }, []);

  const handleChangePassword = (e) => {
    const { name, value } = e.target;
    let currentFormErrors = formErrors;
    setMessage({ success: '', failure: '' });

    switch (name) {
      case name:
        if (!value) {
          currentFormErrors[name] = 'Please enter password';
        } else {
          setError({ ...error, [name]: false });
          delete currentFormErrors[name];
        }
        break;

      default:
        currentFormErrors = 'All fields are required';
        break;
    }
    setFormErrors(currentFormErrors);
    setState({ ...state, [name]: value });
  };

  const resetPassword = async () => {
    if (
      settingsData?.password?.show_current_password_field &&
      !state?.currentPassword &&
      !state?.password &&
      !state?.confirmPassword
    ) {
      setIsSubmitting(true);
      return setMessage({ success: '', failure: 'Password is required.' });
    } else if (
      !settingsData?.password?.show_current_password_field &&
      !state?.password &&
      !state.confirmPassword
    ) {
      setIsSubmitting(true);
      return setMessage({ success: '', failure: 'Password is required.' });
    } else if (
      settingsData?.password?.show_current_password_field &&
      state?.currentPassword &&
      !state?.password &&
      !state?.confirmPassword
    ) {
      setIsSubmitting(true);
      return setMessage({
        success: '',
        failure: 'New password and confirm new password is required.',
      });
    } else if (
      settingsData?.password?.show_current_password_field &&
      !state?.currentPassword &&
      state?.password &&
      !state?.confirmPassword
    ) {
      setIsSubmitting(true);
      return setMessage({
        success: '',
        failure: 'Current password and confirm new password is required.',
      });
    } else if (
      settingsData?.password?.show_current_password_field &&
      !state?.currentPassword &&
      !state?.password &&
      state?.confirmPassword
    ) {
      setIsSubmitting(true);
      return setMessage({
        success: '',
        failure: 'Current password and new password is required.',
      });
    } else if (
      settingsData?.password?.show_current_password_field &&
      !state?.currentPassword
    ) {
      setIsSubmitting(true);
      return setMessage({
        success: '',
        failure: 'Current password is required.',
      });
    } else if (!state?.password) {
      setIsSubmitting(true);
      return setMessage({ success: '', failure: 'New password is required.' });
    } else if (!state?.confirmPassword) {
      setIsSubmitting(true);
      return setMessage({
        success: '',
        failure: 'Confirm new password is required.',
      });
    } else if (!pwdRegex.test(state?.password)) {
      setIsSubmitting(true);
      setError({ ...error, password: true });
      return setMessage({
        success: '',
        failure: 'New password format is invalid.',
      });
    } else if (state?.password !== state?.confirmPassword) {
      setIsSubmitting(true);
      setError({ ...error, password: true, confirmPassword: true });
      return setMessage({
        success: '',
        failure: 'New password and Confirm new password do not match.',
      });
    } else if (
      settingsData?.password?.show_current_password_field &&
      state?.currentPassword === state?.password
    ) {
      setError({ ...error, password: true, currentPassword: true });
      setIsSubmitting(true);
      return setMessage({
        success: '',
        failure: 'New Password cannot be same as your current password.',
      });
    } else {
      try {
        const data = await window.$http.postWithHeaders('password/change', {
          email,
          device_id,
          current_password: state?.currentPassword,
          new_password: state?.password,
        });
        if (data.code === window.$constants.STATUS.OK) {
          setMessage({ success: 'Password saved successfully.', failure: '' });
          return setTimeout(() => {
            navigate(-1);
          }, 3000);
        } else {
          setMessage({ success: '', failure: data?.message });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' || e.key === 'NumpadEnter') {
      resetPassword();
    }
  };

  const handleSettingClick = () => {
    setActive('password');
    setMessage({ success: '', failure: '' });
    setState(initialState);
    setShow(initialShowState);
    setError(initialErrorState);
  };

  const handleMfa = () => {
    setActive('number_change_screen');
    setFormErrors({});
    setError(initialErrorState);
    setState(initialState);
    setIsSubmitting(false);
  };

  const [counter, setCounter] = useState(30);
  const [footerState, setFooterState] = useState('');
  const [otpCode, setOtpCode] = useState({
    value: '',
    valid: '',
  });

  useEffect(() => {
    if (otpCode?.valid !== '') {
      const timer =
        counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [otpCode?.valid]);

  const otpCodeValid = (value) => {
    setOtpCode({
      ...otpCode,
      valid: value,
    });
  };

  const newFooterState = (value) => {
    setFooterState(value);
  };

  return (
    <>
      <Header />
      <main>
        <section>
          <div className='container'>
            <div className='row user-settings bg-white br-12 p-3 p-lg-5'>
              <ul className='nav nav-tabs' id='setingsTab' role='tablist'>
                {settings_visible_options?.some((el) => el === 'password') ? (
                  <li className='nav-item' role='presentation'>
                    <button
                      className={`nav-link ${
                        active === 'password' ? 'active' : ''
                      } `}
                      id='setings-tab'
                      data-bs-toggle='tab'
                      data-bs-target='#setings'
                      type='button'
                      role='tab'
                      aria-controls='setings'
                      aria-selected='true'
                      onClick={handleSettingClick}
                    >
                      Settings
                    </button>
                  </li>
                ) : (
                  ''
                )}
                {settings_visible_options?.some(
                  (el) => el === 'number_change_screen'
                ) ? (
                  <li className='nav-item' role='presentation'>
                    <button
                      className={`nav-link ${
                        active === 'number_change_screen' ? 'active' : ''
                      } `}
                      id='twoFactor'
                      data-bs-toggle='tab'
                      data-bs-target='#twoFactorTab'
                      type='button'
                      role='tab'
                      aria-controls='twoFactorTab'
                      aria-selected='false'
                      onClick={handleMfa}
                    >
                      Mobile number verification
                    </button>
                  </li>
                ) : (
                  ''
                )}
              </ul>
              <div className='tab-content p-0 mt-3' id='setingsContent'>
                {active === 'password' ? (
                  <div
                    className={`tab-pane fade show ${
                      active === 'password' ? 'active' : ''
                    }  `}
                    id='setings'
                    role='tabpanel'
                    aria-labelledby='setings-tab'
                  >
                    <div className='row'>
                      <h3 className='mb-4'>Settings</h3>
                      <p className='fw-bold text-black'>Password</p>
                      <p>
                        Password must be between 9–20 characters and include at
                        least one number, one upper case letter, one lower case
                        letter and one special character.
                      </p>
                      {settingsData?.password?.show_current_password_field ? (
                        <div className='mb-3 col-md-12 col-lg-6 mt-4'>
                          <div className='d-flex align-items-center'>
                            <input
                              name='currentPassword'
                              type={show?.currentPassword ? 'text' : 'password'}
                              className={
                                formErrors?.currentPassword ||
                                (isSubmitting && !state?.currentPassword) ||
                                error?.currentPassword
                                  ? 'form-control invalid'
                                  : 'form-control'
                              }
                              placeholder='Current Password'
                              onChange={handleChangePassword}
                              value={state?.currentPassword}
                              onKeyPress={handleKeyPress}
                            />
                            <div
                              onClick={() =>
                                setShow({
                                  currentPassword: !show?.currentPassword,
                                })
                              }
                            >
                              {show?.currentPassword ? (
                                <i className='bi bi-eye' id='togglePassword' />
                              ) : (
                                <i
                                  className='bi bi-eye-slash'
                                  id='togglePassword'
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>

                    <div className='row'>
                      <div className='mb-4 col-md-12 col-lg-6'>
                        <div className='d-flex align-items-center'>
                          <input
                            type={show?.password ? 'text' : 'password'}
                            name='password'
                            className={
                              formErrors?.password ||
                              (isSubmitting && !state?.password) ||
                              error?.password
                                ? 'form-control invalid'
                                : 'form-control'
                            }
                            placeholder={`${
                              settingsData?.password
                                ?.new_password_fiel_placeholder
                                ? settingsData?.password
                                    ?.new_password_fiel_placeholder
                                : 'New Password'
                            }`}
                            onChange={handleChangePassword}
                            value={state?.password}
                            onKeyPress={handleKeyPress}
                          />
                          <div
                            onClick={() =>
                              setShow({ password: !show?.password })
                            }
                          >
                            {show?.password ? (
                              <i className='bi bi-eye' id='togglePassword' />
                            ) : (
                              <i
                                className='bi bi-eye-slash'
                                id='togglePassword'
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='mb-4 col-md-12 col-lg-6'>
                        <div className='d-flex align-items-center'>
                          <input
                            type={show?.confirmPassword ? 'text' : 'password'}
                            name='confirmPassword'
                            className={
                              formErrors?.confirmPassword ||
                              (isSubmitting && !state?.confirmPassword) ||
                              error?.confirmPassword
                                ? 'form-control invalid'
                                : 'form-control'
                            }
                            placeholder='Confirm Password'
                            onChange={handleChangePassword}
                            value={state?.confirmPassword}
                            onKeyPress={handleKeyPress}
                          />
                          <div
                            onClick={() =>
                              setShow({
                                confirmPassword: !show?.confirmPassword,
                              })
                            }
                          >
                            {show?.confirmPassword ? (
                              <i className='bi bi-eye' id='togglePassword' />
                            ) : (
                              <i
                                className='bi bi-eye-slash'
                                id='togglePassword'
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='save-button'>
                        <button
                          className='btn btn-rp-primary'
                          onClick={resetPassword}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                    {message?.failure ? (
                      <p className='mt-4 text-danger fs-6'>
                        {' '}
                        {message?.failure}{' '}
                      </p>
                    ) : (
                      message?.success && (
                        <p className='primary-color mt-4 fs-6'>
                          {message?.success}
                        </p>
                      )
                    )}
                  </div>
                ) : (
                  <div
                    className={`tab-pane fade show ${
                      active === 'number_change_screen' ? 'active' : ''
                    }`}
                    id='twoFactorTab'
                    role='tabpanel'
                    aria-labelledby='twoFactor'
                  >
                    <h3 className='mb-4'>Mobile number verification</h3>

                    <p>
                      Note: If you use WhatsApp, then please provide the mobile
                      number associated with that account
                    </p>
                    <MobileVerification
                      active={active}
                      value='Setting'
                      isClicked={active === 'number_change_screen' ? 'MFA' : ''}
                      otpCodeValid={otpCodeValid}
                      newFooterState={newFooterState}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
      {otpCode?.valid !== '' ? (
        <Footer />
      ) : (
        <Footer
          addClass='fixed-bottom'
          value={footerState}
          version={user?.version}
        />
      )}
    </>
  );
};

export default Setting;
