import '../config';

const Utility = {
  pageAccessedByReload() {
    return (
      (window.performance.navigation &&
        window.performance.navigation.type === 1) ||
      window.performance
        .getEntriesByType('navigation')
        .map((nav) => nav.type)
        .includes('reload')
    );
  },

  getSubDomain() {
    var subdomain = 'qa-iradar';
    const parsedData = window.location.host.split('.');

    if (parsedData.length >= 3) {
      subdomain = parsedData[0];
    } else {
      subdomain = parsedData[0];
    }
    return subdomain;
  },
  logoutUser() {
    localStorage.clear();
  },

  clearStorageData() {
    localStorage.clear();
  },

  getCmpTypeMsg(rp_gbl_company_type_id, keyValue) {
    var data = global.messageData;
    var msg = '';
    if (data[keyValue]) {
      if (data[keyValue][rp_gbl_company_type_id]) {
        msg = data[keyValue][rp_gbl_company_type_id];
      } else {
        msg = data[keyValue][1];
      }
    }
    return msg;
  },

  getInitials(name) {
    if (name) {
      let initials = name.split(' ');

      if (initials.length > 1) {
        initials = initials.shift().charAt(0) + initials.pop().charAt(0);
      } else {
        initials = name.substring(0, 1);
      }

      return initials.toUpperCase();
    } else {
      return;
    }
  },

  getYears() {
    const currentYear = new Date().getFullYear();
    let years = [];

    for (let i = currentYear; i >= 1940; i--) {
      years.push(i);
    }

    return years;
  },

  getLastDay(year, month) {
    const yr =
      year && year !== 'Invalid date' ? year : new Date().getFullYear();
    return new Date(yr, parseInt(month) + 1, 0).getDate();
  },

  getDaysList() {
    const year = new Date().getFullYear();
    const month = new Date().getMonth();
    var lastDay = this.getLastDay(year, month);
    let days = [];
    for (let i = 1; i <= lastDay; i++) {
      days.push(i);
    }

    return days;
  },

  prepareChartData(data, chartType, invert = false) {
    let result = [];
    if (data) {
      switch (chartType) {
        case 'column2d':
          result = this.buildColumn2dData(data);
          break;

        case 'MSColumn2D':
          result = this.buildMSColumn2dData(data);
          break;

        case 'scrollstackedcolumn2d':
          result = this.buildStackedColumn2dData(data, invert);
          break;

        case 'pie2d':
          result = this.buildPie2dData(data);
          break;

        case 'doughnut2d':
          result = this.buildDoughnut2dData(data);
          break;

        default:
          result = null;
          break;
      }
    }
    if (data?.x_axis_label) {
      result['xAxisName'] = data?.x_axis_label;
    }
    if (data?.y_axis_label) {
      result['yAxisName'] = data?.y_axis_label;
    }
    if (data?.chart_title) {
      result['title'] = data?.chart_title;
    }
    if (data?.chart_type) {
      result['type'] = data?.chart_type;
    }
    if (data?.chart_message) {
      result['chartMessage'] = data?.chart_message;
    }
    if (data?.no_data_message) {
      result['message'] = data?.no_data_message;
    }
    return result;
  },

  buildColumn2dData(data) {
    let chartData = [];
    chartData['data'] = [];
    chartData['xAxisName'] = data?.x_axis_label;
    chartData['yAxisName'] = data?.y_axis_label;
    for (let item of data?.data) {
      let itemObj = {
        label: item.x_axis_label,
        value: item?.value,
        color: item.color_code,
      };
      chartData['data'].push(itemObj);
    }
    return chartData;
  },

  buildMSColumn2dData(data) {
    let labelArray = [];
    let dataset = [];
    let labels = [...new Set(data?.data?.map((item) => item?.separator))];
    let serieses = [...new Set(data?.data?.map((item) => item?.x_axis_label))];

    if (labels && labels.length) {
      for (const item of labels) {
        labelArray.push({ label: item });
      }
    }

    if (serieses && serieses.length) {
      for (const series of serieses) {
        let barData = [];
        let barDataArray = [];
        barData['seriesname'] = series;
        barData['color'] = data?.legends
          .filter((item) => item.text === series)
          .map((item) => item.color_code);
        if (labels && labels.length) {
          for (const label of labels) {
            barDataArray.push(
              data?.data
                .filter(
                  (item) =>
                    item.separator === label && item.x_axis_label === series
                )
                .map((item) => {
                  return { value: item?.value };
                })
            );
          }
          barData['data'] = barDataArray;
          dataset.push(barData);
        }
      }
    }

    let finalDataSet = [];
    if (dataset && dataset.length) {
      for (const dataItem of dataset) {
        var finalData = [];
        for (const item of dataItem.data) {
          finalData.push({
            value: item && item[0]?.value ? item[0]?.value : 0,
          });
        }
        finalDataSet.push({
          seriesname: dataItem.seriesname,
          color: dataItem?.color[0],
          data: finalData,
        });
      }
    }

    let finalDataSetArray = [];
    finalDataSetArray['categories'] = labelArray;
    finalDataSetArray['dataset'] = finalDataSet;
    return finalDataSetArray;
  },

  buildStackedColumn2dData(data, invert) {
    let labelArray = [];
    let result = [];
    let separatorArray = [
      ...new Set(data?.data?.map((item) => item?.separator)),
    ];
    let serieses = [
      ...new Set(
        data?.data?.map((item) =>
          invert ? item?.x_axis_label : item?.x_axis_stack_label
        )
      ),
    ];
    let labels = [
      ...new Set(
        data?.data?.map((item) =>
          invert ? item?.x_axis_stack_label : item?.x_axis_label
        )
      ),
    ];

    if (labels && labels.length) {
      for (const item of labels) {
        labelArray.push({ label: item });
      }
    }

    if (serieses && serieses.length) {
      for (const separator of separatorArray) {
        let dataset = [];
        let fdataSet = [];
        if (labels && labels.length) {
          for (const series of serieses) {
            let barDataArray = [];
            let barData = [];
            for (const label of labels) {
              dataset[series] = [];
              barData['series'] = series;
              barData['color'] = data?.legends
                .filter((item) => item.text === series)
                .map((item) => item.color_code)[0];
              let labelCheck = invert ? series : label;
              let seriesCheck = invert ? label : series;
              for (const item of data?.data) {
                if (
                  item.separator === separator &&
                  item.x_axis_label === labelCheck &&
                  item.x_axis_stack_label === seriesCheck
                ) {
                  barDataArray.push({
                    series: invert
                      ? item?.x_axis_label
                      : item?.x_axis_stack_label,
                    value: item?.value,
                  });
                }
              }
            }
            barData['data'] = barDataArray;
            dataset[series].push(barData);
          }
        }
        fdataSet[separator] = dataset;
        result.push(fdataSet);
      }
    }

    let finalData = [];
    for (const key in result) {
      let resultKey = result[key];
      for (const sepKey in resultKey) {
        const resultSepKey = resultKey[sepKey];
        for (const datakey in resultSepKey) {
          finalData.push({
            separator: sepKey,
            data: {
              seriesname: resultSepKey[datakey][0].series,
              color: resultSepKey[datakey][0].color,
              data: resultSepKey[datakey][0].data,
            },
          });
        }
      }
    }

    var filteredData = finalData.reduce(function (obj, value) {
      var key = value.separator ? value.separator : 'data'; //in case of if seprator is not available
      if (obj[key] == null) obj[key] = [];

      obj[key].push(value?.data);
      return obj;
    }, {});

    let finalDataSet = [];
    finalDataSet['data'] = filteredData;
    finalDataSet['categories'] = labelArray;
    return finalDataSet;
  },

  buildPie2dData(data) {
    let separatorArray = [
      ...new Set(data?.data?.map((item) => item?.separator)),
    ];
    let result = [];
    if (separatorArray && separatorArray.length > 1) {
      for (let separator of separatorArray) {
        let res = [];
        res[separator] = data?.data
          .filter((item) => item.separator === separator)
          .map((item) => {
            return {
              label: item?.x_axis_label,
              value: item?.value,
              color: item.color_code,
              separator: item.separator,
            };
          });
        result.push(res[separator]);
      }
    } else {
      result = data?.data.map((item) => {
        return {
          label: item?.x_axis_label,
          value: item?.value,
          color: item?.color_code,
        };
      });
    }
    return result;
  },

  buildDoughnut2dData(data) {
    let separatorArray = [
      ...new Set(data?.data?.map((item) => item?.separator)),
    ];
    let result = [];
    if (separatorArray && separatorArray.length > 1) {
      for (let separator of separatorArray) {
        let res = [];
        res[separator] = data?.data
          .filter((item) => item.separator === separator)
          .map((item) => {
            return {
              label: item?.x_axis_label,
              value: item?.value,
              color: item.color_code,
              separator: item.separator,
            };
          });
        result.push(res[separator]);
      }
    } else {
      result = data?.data.map((item) => {
        return {
          label: item?.x_axis_label,
          value: item?.value,
          color: item?.color_code,
        };
      });
    }
    return result;
  },

  prepareDynamicParamsCustomChart(query = {}, selectedArray) {
    let tempSelectedArray = selectedArray;
    for (let key in tempSelectedArray) {
      if (tempSelectedArray[key][key]?.length) {
        query[key] = tempSelectedArray[key][key]?.map((el) => el).join(',');
      }
    }
    return query;
  },

  setDynamicFilterOptions(value, selectedArray) {
    let currentChangingSelect = '';
    for (var key in value) {
      currentChangingSelect = key;
    }

    let tempSelectedArray = selectedArray;
    tempSelectedArray[currentChangingSelect] = value;
    return tempSelectedArray;
  },

  setApiUrl(userState) {
    if (
      userState?.rp_gbl_company_type_id ===
      window.$enums.CompanyTypeId.CompanyAdmin
    ) {
      return window.$utility.adminUrl(userState?.rp_gbl_company_type_id);
    } else {
      return '';
    }
  },

  setCompanyId(userState, companyTypeId) {
    if (
      userState?.rp_gbl_company_type_id ===
        window.$enums.CompanyTypeId.CompanyAdmin &&
      companyTypeId?.rp_gbl_company_type_id
    ) {
      return companyTypeId?.value;
    } else {
      return;
    }
  },

  queryParamaBuilder(userState, companyTypeId) {
    let query;
    if (
      userState?.rp_gbl_company_type_id ===
        window.$enums.CompanyTypeId.CompanyAdmin &&
      companyTypeId?.rp_gbl_company_type_id
    ) {
      query = {
        rp_company_id: companyTypeId?.value,
      };
    }
    return query;
  },

  adminUrl(companyTypeId) {
    let value;
    if (companyTypeId === window.$enums.CompanyTypeId.CompanyAdmin) {
      value = '/admin/';
    }
    return value;
  },

  showMessage(msg) {
    return (
      <p
        style={{
          color: '#e02726',
          textAlign: 'center',
          fontSize: '13px',
        }}
      >
        {msg}
      </p>
    );
  },
};

export const CustomSelect = (props) => (
  <div className='form-group'>
    <select
      className='form-control'
      name={props.name}
      onChange={props.onChange}
    >
      <option defaultValue>Select </option>
      {props.options.map((item, index) => (
        <option key={index} value={item.id}>
          {item}
        </option>
      ))}
    </select>
  </div>
);

window.$utility = Utility;

export default Utility;
