import React from 'react';

const RadialBar = (props) => {
  function showSize(section_percentage, section_code, color_code) {
    if (color_code === window.$enums.BurnoutLevel.Red) {
      if (section_code === 'E') {
        return `c100 p${section_percentage} big maroon circle_1 red-shadow`;
      } else if (section_code === 'F') {
        return `c100 p${section_percentage} large maroon circle_2 red-shadow`;
      } else if (section_code === 'M') {
        return `c100 p${section_percentage} to-small maroon circle_3 red-shadow`;
      } else {
        return `c100 p${section_percentage} small maroon circle_4 red-shadow`;
      }
    } else {
      if (section_code === 'E') {
        return `c100 p${section_percentage} big green circle_1 green-shadow`;
      } else if (section_code === 'F') {
        return `c100 p${section_percentage} large green circle_2 green-shadow`;
      } else if (section_code === 'M') {
        return `c100 p${section_percentage} to-small green circle_3 green-shadow`;
      } else {
        return `c100 p${section_percentage} small green circle_4 green-shadow`;
      }
    }
  }

  return (
    <div className='burnOut'>
      <div>
        {props?.burnout_data ? (
          props?.burnout_data?.map((el, id) => (
            <div
              className={
                el?.color_code === window.$enums.BurnoutLevel.Red
                  ? `${showSize(
                      Math.trunc(el?.section_percentage),
                      el?.section_code,
                      el?.color_code
                    )}`
                  : `${showSize(
                      Math.trunc(el?.section_percentage),
                      el?.section_code,
                      el?.color_code
                    )}`
              }
            >
              <label className='sname'>{el?.section_code}</label>
              {el?.section_code === 'E' ? (
                <span
                  className={
                    props.burnout_stage_id ===
                    window.$enums.BurnoutStageId.Severe
                      ? 'rp-primary-red-color'
                      : props.burnout_stage_id ===
                        window.$enums.BurnoutStageId.Chronic
                      ? 'chornic-color'
                      : props.burnout_stage_id ===
                        window.$enums.BurnoutStageId.Moderate
                      ? 'moderate-color'
                      : props.burnout_stage_id ===
                        window.$enums.BurnoutStageId.Mild
                      ? 'minimal-color'
                      : props.burnout_stage_id ===
                        window.$enums.BurnoutStageId.Resilient
                      ? 'primary-color'
                      : ''
                  }
                >
                  {props.burnout_title ? props.burnout_title : 'Severe'} <br />{' '}
                  <span>Burnout</span>
                </span>
              ) : (
                ''
              )}
              <div className='slice'>
                <div className='bar'></div>
                <div className='fill'></div>
              </div>
            </div>
          ))
        ) : (
          <div className='burnOut'>
            <div className='c100 p50 big green circle_1'>
              <label className='sname'>E</label>
              <span
                className={
                  props.burnout_stage_id === window.$enums.BurnoutStageId.Severe
                    ? 'rp-primary-red-color'
                    : props.burnout_stage_id ===
                      window.$enums.BurnoutStageId.Chronic
                    ? 'chornic-color'
                    : props.burnout_stage_id ===
                      window.$enums.BurnoutStageId.Moderate
                    ? 'moderate-color'
                    : props.burnout_stage_id ===
                      window.$enums.BurnoutStageId.Mild
                    ? 'minimal-color'
                    : props.burnout_stage_id ===
                      window.$enums.BurnoutStageId.Resilient
                    ? 'primary-color'
                    : ''
                }
              >
                {props.burnout_title ? props.burnout_title : 'Severe'} <br />{' '}
                <span>Burnout</span>
              </span>
              <div className='slice'>
                <div className='bar'></div>
                <div className='fill'></div>
              </div>
            </div>
            <div className='c100 p90 large maroon circle_2'>
              <label className='sname'>F</label>
              <div className='slice'>
                <div className='bar'></div>
                <div className='fill'></div>
              </div>
            </div>
            <div className='c100 p45 to-small green circle_3'>
              <label className='sname'>M</label>
              <div className='slice'>
                <div className='bar'></div>
                <div className='fill'></div>
              </div>
            </div>
            <div className='c100 p80 small maroon  circle_4'>
              <label className='sname'>N</label>
              <div className='slice'>
                <div className='bar'></div>
                <div className='fill'></div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RadialBar;
