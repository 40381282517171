import { Route, Routes, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import DailyLinksVideos from '../../pages/Dashboard/Components/ResiliencyPlan/Components/DailyLinks/Components/UnAuthenticatedDailyLinks';

const AuthGuard = ({ element, is_profile_completed, ...rest }) => {
  const location = useLocation();
  const loc = location.pathname.split('/');
  const userState = useSelector((state) => state?.user);
  const authState = useSelector((state) => state?.auth);

  if (authState?.isAuthenticated && userState) {
    if (userState?.is_web_enabled === false) {
      if (loc[3] === 'dailylinks' && loc[4]) {
        return (
          <Routes>
            <Route path='/*' element={<DailyLinksVideos />} />
          </Routes>
        );
      } else {
        return (
          <Routes>
            <Route path='/*' element={<Navigate to='/landing-page' />} />
          </Routes>
        );
      }
    } else if (
      userState?.rp_gbl_company_type_id ===
      window.$enums.CompanyTypeId.CompanyAdmin
    ) {
      if (
        (loc[1] === 'dashboard' && !loc[2]) ||
        loc[2] === 'resiliency-plan' ||
        loc[2] === 'assessments' ||
        loc[1] === 'on-boarding' ||
        loc[1] === 'phone-verification'
      ) {
        return (
          <Routes>
            <Route path='/*' element={<Navigate to='/dashboard/admin' />} />
          </Routes>
        );
      } else {
        return (
          <Routes>
            <Route path='/*' element={element} />
          </Routes>
        );
      }
    } else if (userState?.is_profile_completed === true) {
      if (loc[1] === 'on-boarding' || loc[1] === 'phone-verification') {
        if (userState?.visible_header_tabs?.includes('admin')) {
          return (
            <Routes>
              <Route path='/' element={<Navigate to='/dashboard/admin' />} />
            </Routes>
          );
        } else {
          return (
            <Routes>
              <Route
                path='/'
                element={<Navigate to='/dashboard/assessments' />}
              />
            </Routes>
          );
        }
      }
      return (
        <Routes>
          <Route {...rest} path='/*' element={element} />
        </Routes>
      );
    } else if (userState?.is_profile_completed === false) {
      if (rest.isOnboarding) {
        return (
          <Routes>
            <Route {...rest} element={element} path='/' />
          </Routes>
        );
      } else if (userState.onboarding_step === 0) {
        return (
          <Routes>
            <Route
              {...rest}
              element={<Navigate to='/on-boarding' />}
              path='/*'
            />
          </Routes>
        );
      } else if (userState.onboarding_step === 1) {
        return (
          <Routes>
            <Route
              {...rest}
              element={<Navigate to='/phone-verification' />}
              path='/*'
            />
          </Routes>
        );
      } else if (userState.onboarding_step === 2) {
        return (
          <Routes>
            <Route {...rest} element={<Navigate to='/dashboard' />} path='/*' />
          </Routes>
        );
      }
    }
  } else {
    return (
      <Routes>
        <Route path='/' element={<Navigate to='/login' />} />
      </Routes>
    );
  }
};
export default AuthGuard;
