import React, { useState, useEffect } from 'react';
import ReactCardFlip from 'react-card-flip';
import tabImage from '../../../../../../assets/images/tab.svg';
import timingImage from '../../../../../../assets/images/timing.svg';
import { useSelector } from 'react-redux';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Tooltip } from 'antd';
import ZoomModal from './Components/ZoomModal';
import Disclaimer from '../../../../../Login/Footer/Components/Disclaimer';
import { isEmpty } from 'lodash';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MobileApp from '../../../../../../common/Modals/MobileApp';

const Supplements = () => {
  const [data, setData] = useState([]);
  const [content, setContent] = useState('');
  const [userCountry, setUserCountry] = useState();
  const [showModal, setShowModal] = useState('');
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const user = useSelector((state) => state?.user);

  useEffect(() => {
    getResultData();
  }, []);

  const getResultData = async () => {
    const result = await window.$http.getWithHeaders('my/supplement');

    if (result.code === window.$constants.STATUS.OK) {
      if (result.data === null) {
      } else {
        setContent(result?.data);
        setData(result?.data.supplements);
        setUserCountry(result?.data?.rp_gbl_country_id);
      }
    } else {
      console.log('Something went wrong!');
    }
  };

  const getDosageInfo = async () => {
    setIsDownloadLoading(true);
    try {
      const result = await window.$http.getWithHeaders(
        `media/supplement/dosage/0`
      );
      if (result.code === window.$constants.STATUS.OK) {
        setIsDownloadLoading(false);
        var linkSource = result.data.media_content;
        var downloadLink = document.createElement('a');
        var fileName = result?.data?.filename
          ? result?.data?.filename
          : 'dosage.pdf';
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      } else {
        setIsDownloadLoading(false);
        result?.message
          ? toast.error(result?.message)
          : toast.error('Error occured, please try again or contact admin.');
      }
    } catch (err) {
      setIsDownloadLoading(false);
      console.log(err);
    }
  };

  const handleCloseModal = () => {
    setShowModal('');
  };

  return (
    <>
      {isEmpty(content?.supplements) ? (
        <div className='centered'>
          <h1 className='section-sub-heading rp-primary-dark-g mb-0'>
            {content?.message}
          </h1>
        </div>
      ) : data ? (
        data.map((el, id) => (
          <div
            className={
              id === 0
                ? 'supplement-container mt-8 list'
                : 'supplement-container list'
            }
            key={id}
          >
            <Card cardData={el} key={`card-${id}`} />
            {el.supplement_images ? (
              <SupplementsData
                supplementData={el}
                key={id}
                productImageData={el?.supplement_images}
                userCountry={userCountry}
              />
            ) : (
              ''
            )}
          </div>
        ))
      ) : (
        ''
      )}
      {content && data ? (
        data ? (
          <div className='col-md-11 mt-4'>
            {!isEmpty(content?.supplements) ? (
              <>
                <p className='fw-bold primary-color mb-2 text-decoration-underline fs-6'>
                  Disclaimer
                </p>
                <span className='rp-primary-red-color fs-6'>
                  *Please read product packaging carefully prior to purchase and
                  use. Resiliency Program is in no way responsible for use of
                  any of these products as these are only suggestions.
                </span>
                <span
                  className='primary-color text-decoration-underline cursor-p fs-6 ms-1'
                  onClick={() => setShowModal('disclaimer')}
                >
                  Read More
                </span>
              </>
            ) : (
              ''
            )}
            <div className='mt-4'>
              {!isEmpty(content?.supplements) ? (
                <>
                  {userCountry === window.$enums.CountryId.US ? (
                    <button
                      className='btn btn-rp-primary mx-3'
                      onClick={() => window.open(`${data[0]?.supplement_url}`)}
                    >
                      Buy Now
                    </button>
                  ) : (
                    ''
                  )}
                  <button
                    className='btn btn-rp-primary mb-3 mb-xl-0'
                    onClick={getDosageInfo}
                    disabled={isDownloadLoading && true}
                  >
                    {isDownloadLoading ? (
                      <Spinner
                        as='i'
                        animation='border'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      'Download Dosage Chart'
                    )}
                  </button>
                </>
              ) : (
                ''
              )}
              {user?.schedule_call_options?.includes('is_lifecoach_enabled') ? (
                <button
                  className='btn btn-rp-primary call_btn ms-0 ms-xl-2'
                  onClick={() => setShowModal('schedule')}
                >
                  Schedule a call with our Life Coach
                </button>
              ) : (
                ''
              )}
            </div>
          </div>
        ) : (
          ''
        )
      ) : (
        ''
      )}
      {showModal === 'disclaimer' && (
        <Disclaimer showModal={showModal} handleCloseModal={handleCloseModal} />
      )}
      {showModal === 'schedule' && (
        <MobileApp
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          heading='Schedule a call with our Life Coach'
        />
      )}
    </>
  );
};

export default Supplements;

const Card = ({ cardData }) => {
  const [isCardFlipped, setIsCardFlipped] = useState(false);

  const handleCardFlip = (e) => {
    e.preventDefault();
    setIsCardFlipped(!isCardFlipped);
  };

  return (
    <div className='col-md-5 card-5'>
      <div className='scene scene--card'>
        <div className='result-card'>
          <ReactCardFlip isFlipped={isCardFlipped} flipDirection='horizontal'>
            <div className='product-about-card' style={{ minHeight: '428px' }}>
              <div className='dots-style-2'></div>
              <p className='sub-titel'>{cardData?.supplement_type_title}</p>

              {cardData?.supplement_short_desc?.length > 220 ? (
                <h6 className='card-heading-light'>
                  {cardData?.supplement_short_desc?.slice(0, 220)}
                  <Tooltip
                    title={cardData?.supplement_short_desc}
                    placement='top'
                  >
                    {/* <div> */}
                    <div
                      style={{
                        color: '#ffffff',
                        // cursor: 'pointer',
                      }}
                    >
                      ...
                    </div>
                    {/* </div> */}
                  </Tooltip>
                </h6>
              ) : (
                <h6 className='card-heading-light'>
                  {cardData?.supplement_short_desc}
                </h6>
              )}

              {cardData.supplement_long_desc ? (
                <button className='btn btn-flip' onClick={handleCardFlip}>
                  <i className='fas fa-plus'></i>
                </button>
              ) : (
                ''
              )}
            </div>

            <div
              className='card__face product-about-card'
              style={{
                minHeight: '428px',
                minWidth: '400px',
              }}
            >
              <div className='dots-style-2'></div>
              <p className='sub-titel'>{cardData?.product_type_title}</p>

              <p className='card-heading-light'>
                {cardData?.supplement_long_desc}
              </p>
              <button className='btn btn-flip' onClick={handleCardFlip}>
                <i className='fas fa-minus'></i>
              </button>
            </div>
          </ReactCardFlip>
        </div>
      </div>
    </div>
  );
};

const SupplementsData = ({ supplementData, productImageData, userCountry }) => {
  const [imageId, setImageId] = useState('');
  const [productImageId, setProductImageId] = useState(0);
  const [isCardFlipped, setIsCardFlipped] = useState(false);
  const [images, setImages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState({
    productData: [],
    id: '',
    productImageId: '',
  });

  const handleCardFlip = (e) => {
    e.preventDefault();
    setIsCardFlipped(!isCardFlipped);
  };

  const handleContainerImage = (id) => {
    var index = productImageData?.findIndex(
      (data) => data?.rp_supplement_image_id === id
    );
    setProductImageId(id);
    setImageId(index);
  };

  const handleCloseModal = (e) => {
    e.preventDefault();
    setIsModalOpen(false);
  };

  const prodImgCnt = Object.values(productImageData).length;

  const settings = {
    infinite: true,
    dots: false,
    slidesToShow: 5,
    slidesToScroll: 5,
    autoplay: false,
    speed: 500,
    vertical: true,
    verticalSwiping: true,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 1,
          vertical: false,
          verticalSwiping: false,
        },
      },
    ],
  };

  const handleImages = () => {
    setData({
      productData: productImageData,
      id: productImageData[0]?.rp_supplement_image_id,
      productImageId: productImageId,
    });

    setImages(productImageData);
    if (isCardFlipped) {
      setIsModalOpen(false);
    } else {
      setIsModalOpen(true);
    }
  };

  return (
    <>
      <div className='col-md-5 card-5 mt-5 mt-xl-0'>
        <div className='scene scene--card'>
          <div
            className={
              isCardFlipped
                ? 'result-card product-card is-flipped'
                : 'result-card product-card'
            }
          >
            <div
              className='card__face card__face--front bg-white product-card-main'
              style={{
                minHeight: '426px',
                display: isCardFlipped ? 'none' : '',
              }}
            >
              <div className='dots-styles'></div>
              <div className='product-img text-center' onClick={handleImages}>
                <img
                  className='img1'
                  src={`${
                    process.env.REACT_APP_API_URL
                  }media/supplement/image/${
                    productImageId
                      ? productImageId
                      : productImageData[0]?.rp_supplement_image_id
                  }`}
                  alt='Supplements images'
                />
              </div>
              <div className='product-cnt'>
                <div className='product-titel mb-3'>
                  <h6>{supplementData?.supplement_title}</h6>
                </div>

                <div className='d-flex justify-content-between'>
                  {userCountry === window.$enums.CountryId.US ? (
                    ''
                  ) : (
                    <button
                      className='btn btn-shop'
                      onClick={() =>
                        window
                          .open(
                            `${supplementData?.supplement_url}`,
                            '_blank',
                            'noopener,noreferrer'
                          )
                          ?.focus()
                      }
                    >
                      Buy Now
                    </button>
                  )}
                  <button
                    className='btn btn-rp-secondary dosage'
                    onClick={handleCardFlip}
                  >
                    Get Dosage
                  </button>
                </div>
              </div>
            </div>

            <div
              className='card__face card__face--back product-card-main'
              style={{ minHeight: '426px' }}
            >
              <div className='dots-styles'></div>
              <div className='position-relative'>
                <p className='sub-titel rp-med-gray'>
                  {supplementData?.supplement_type_title}
                </p>
                <h6 className='card-heading-light rp-primary-dark-gray'>
                  {supplementData?.supplement_title}
                </h6>
                <p>{supplementData?.supplement_short_desc}</p>
                <div className='prescription mb-3'>
                  {supplementData?.dosage_info ? (
                    <div className='mb-3 rp-primary-dark-g'>
                      <img src={tabImage} alt='' />
                      {supplementData?.dosage_info}
                    </div>
                  ) : (
                    ''
                  )}
                  {supplementData?.dosage_info2 ? (
                    <div
                      className='rp-primary-dark-g'
                      style={{ display: 'flex' }}
                    >
                      <img src={timingImage} alt='' />
                      {supplementData?.dosage_info2}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className='dosage-action justify-content-end'>
                <button className='btn dosage' onClick={handleCardFlip}>
                  Return
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          prodImgCnt >= 6
            ? 'col-md-1 prodduct-thumbnail br-12 mt-4 mt-lg-0 justify-content-center'
            : 'col-md-1 prodduct-thumbnail br-12 mt-4 mt-lg-0 justify-content-around'
        }
      >
        {prodImgCnt >= 6 ? (
          <>
            <Slider {...settings}>
              {productImageData?.map((data, id) => (
                <div
                  key={id}
                  className='cursor-p'
                  style={{ width: '60px' }}
                  onClick={() =>
                    handleContainerImage(data?.rp_supplement_image_id)
                  }
                >
                  <img
                    className={
                      imageId
                        ? imageId === id
                          ? 'thmbnlActive'
                          : ''
                        : id === 0
                        ? 'thmbnlActive'
                        : ''
                    }
                    src={`${process.env.REACT_APP_API_URL}media/supplement/image/${data?.rp_supplement_image_id}`}
                    alt=''
                    width='60'
                    height={60}
                  />
                </div>
              ))}
            </Slider>
          </>
        ) : (
          <>
            {productImageData?.map((data, id) => (
              <div
                key={id}
                className='cursor-p'
                onClick={() =>
                  handleContainerImage(data?.rp_supplement_image_id)
                }
              >
                <img
                  className={
                    imageId
                      ? imageId === id
                        ? 'thmbnlActive'
                        : ''
                      : id === 0
                      ? 'thmbnlActive'
                      : ''
                  }
                  src={`${process.env.REACT_APP_API_URL}media/supplement/image/${data?.rp_supplement_image_id}`}
                  alt=''
                />
              </div>
            ))}
          </>
        )}
      </div>
      <ToastContainer />
      <ZoomModal
        isModalOpen={isModalOpen}
        productImageId={productImageId}
        data={data.productData}
        handleCloseModal={handleCloseModal}
        images={images}
      />
    </>
  );
};
