import React from 'react';
import { CSVLink } from 'react-csv';

const ExcelExport = (props) => {
  return (
    <CSVLink
      data={props?.data}
      headers={props?.headers}
      filename={'my-file.csv'}
    >
      <button type='button' className='btn btn-rp-primary mx-1'>
        Export
      </button>
    </CSVLink>
  );
};

export default ExcelExport;
