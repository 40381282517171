import { SAVEFILTERS, REMOVEFILTERS } from './types';

const INITIAL_STATE = {};
let filterArray = {};

const filters = (state = INITIAL_STATE, { type, payload, term }) => {
  switch (type) {
    case SAVEFILTERS: {
      if (!state?.analytics || Object.keys(state?.analytics).length === 0) {
        filterArray[`${term}`] = payload;
      } else {
        filterArray = state?.analytics;
        Object.keys(filterArray).forEach((key) => {
          if (key === term) {
            filterArray[key] = payload;
          } else {
            filterArray[`${term}`] = payload;
          }
        });
      }
      return {
        ...state,
        analytics: filterArray,
      };
    }
    case REMOVEFILTERS: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default filters;
