import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Iframe from 'react-iframe-click';

const ModalFade = (props) => {
  const videoAudit = async (rp_content_category_id, rp_content_id) => {
    try {
      const result = await window.$http.getWithHeaders(
        `/content/playaudit/${
          rp_content_category_id ? rp_content_category_id : 0
        }/${rp_content_id}`
      );
      if (result.code === window.$constants.STATUS.OK) {
        console.log('success');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      className='video-modal'
      show={props?.showModal ? true : false}
    >
      <Modal.Header>
        <h5 className='modal-title' id='staticBackdropLabel'>
          {props?.result?.content_title}
        </h5>
        <button
          type='button'
          className='btn-close'
          data-bs-dismiss='modal'
          aria-label='Close'
          onClick={props?.handleCloseModal}
        ></button>
      </Modal.Header>
      <Modal.Body
        className='p-0'
        onClick={() =>
          videoAudit(
            props?.result?.rp_content_category_id,
            props?.result?.rp_content_id
          )
        }
      >
        <Iframe
          width='100%'
          height='450'
          src={
            props?.result
              ? props?.result?.rp_content_source_id ===
                window.$enums.Source.Youtube
                ? `https://www.youtube-nocookie.com/embed/${props?.content_Id}`
                : props?.result?.rp_content_source_id ===
                  window.$enums.Source.Vimeo
                ? `https://player.vimeo.com/video/${props?.content_Id}`
                : ''
              : ''
          }
          title={props?.result?.content_filename}
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
          onInferredClick={() =>
            videoAudit(
              props?.result?.rp_content_category_id,
              props?.result?.rp_content_id
            )
          }
        ></Iframe>

        <div className='video-info px-3 pt-3'>
          {props?.result?.content_description ? (
            <div className='video-discription mb-2'>
              {props?.result?.content_description}
            </div>
          ) : (
            ''
          )}
          <div className='d-flex justify-content-between align-item-center'>
            {props?.result?.content_author ? (
              <div className='video-author'>
                <span className='fw-bold rp-primary-dark-gray'>
                  Content Author:
                </span>{' '}
                {props?.result?.content_author?.replace(/[{}""]/g, '')}
              </div>
            ) : (
              ''
            )}

            <div>
              <span
                className={
                  props?.result?.is_favourite ? 'like-btn liked' : 'like-btn'
                }
                onClick={() =>
                  props?.handleClick(
                    props?.result?.rp_content_category_id,
                    props?.result?.rp_content_id,
                    props?.result?.is_favourite
                  )
                }
              ></span>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalFade;
