import { useEffect } from 'react';
import { useLocation } from 'react-router';

const ScrollToTop = (props) => {
  const location = useLocation();
  const loc = location.pathname.split('/');

  useEffect(() => {
    if (
      (loc[1] && !loc[2]) ||
      (loc[2] === 'assessments' && !loc[3]) ||
      loc[2] !== 'assessments'
    ) {
      if (loc[3] !== 'favourite') {
        window.scrollTo(0, 0);
      }
    }
  }, [loc]);

  return <>{props.children}</>;
};

export default ScrollToTop;
