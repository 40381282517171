import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import parse from 'html-react-parser';
import ParticipantCard from '../../../../common/ParticipantCard';
import FactorTab from './Components/Corporate/FactorTab';
import Skeleton from 'react-loading-skeleton';
import ScrollToTopButton from '../../../../common/ScrollToTopButton';
import OraFilter from './Components/Common/OraFilter';
import Message from './Components/Common/Message';
import { isEmpty } from 'lodash';
import Sidebar from './Components/Common/Sidebar';
import AdminFilter from './Components/Common/AdminFilter';
import ScrollToTop from '../../../../app/routes/ScrollToTop';
import DoughnutGraph from '../../../../common/DoughnutGraph';
import { saveFilters } from '../../../../store/filters/actions';
import QuestionAnalytics from '../../../../common/QuestionAnalytics';
import StackedColumnChart from '../../../../common/StackedColumnChart';
import { saveTabs } from '../../../../store/tabs/actions';
import ManagerDropdown from '../../../../common/ManagerDropdown';

const InitialState = {
  managerSelectedOption: '',
  questionSelectedOption: '',
};

const Corporate = (props) => {
  // Variables
  const dispatch = useDispatch();
  const [currState, setCurrState] = useState(InitialState);
  const [factorTabIndex, setFactorTabIndex] = useState(0);
  const [corporateOverviewResult, setCorporateOverviewResult] = useState({
    data: '',
    loading: true,
  });
  const [corporateResiliencyResult, setCorporateResiliencyResult] = useState({
    data: '',
    loading: true,
  });
  const [burnOutGraphResult, setBurnOutGraphResult] = useState({
    data: '',
    loading: true,
  });
  const [sectionResultData, setSectionResultData] = useState({
    data: '',
    loading: true,
  });
  const [managerOptions, setManagerOptions] = useState({
    data: '',
    loading: true,
  });
  const [questionOptions, setQuestionOptions] = useState({
    data: '',
    loading: true,
  });
  const [questionAnalyticsData, setQuestionAnalyticsData] = useState({
    data: '',
    loading: true,
  });
  const [questionData, setQuestionData] = useState({
    data: '',
    loading: true,
  });
  const [assmtId, setAssmtId] = useState('');
  const [selectedArray, setSelectedArray] = useState({});
  const [oraStatus, setOraStatus] = useState('');
  const [queryParams, setQueryParams] = useState({});
  const [oraMsg, setOraMsg] = useState('');
  const [reminderEnabledValue, setReminderEnabledValue] = useState('');
  const [countValue, setCountValue] = useState([]);
  const userState = useSelector((state) => state?.user);
  const companyTypeId = useSelector((state) => state?.companyTypeId?.payload);
  const [factorsDonutChartData, setFactorsDonutChartData] = useState({
    data: '',
    loading: true,
  });
  const [cardTitle, setCardTitle] = useState('');
  const [cardClicked, setCardClicked] = useState(false);

  const location = useLocation();
  const loc = location.pathname.split('/');

  const tabState = useSelector(
    (state) => state?.tabs?.analytics?.[`${loc[3]}`]
  );

  useEffect(() => {
    <ScrollToTop />;
    window.addEventListener('load', clearFilter);
    return () => {
      window.removeEventListener('load', clearFilter);
    };
  }, []);

  const getSelectedQuestion = (id) => {
    setCurrState({ ...currState, questionSelectedOption: id });
    setQuestionAnalyticsData({ data: '', loading: true });
  };

  const getSelectedManager = (id) => {
    setCurrState({ ...currState, managerSelectedOption: id });
    setQuestionAnalyticsData({ data: '', loading: true });
  };

  const clearFilter = (e) => {
    e.preventDefault();
    if (window.$utility.pageAccessedByReload()) {
      dispatch(saveFilters({}, 'corporate'));
    }
  };

  useEffect(() => {
    dispatch(saveTabs(tabState, 'corporate'));
  }, [tabState]);

  function apiCallsWithoutLevelId() {
    if (tabState) {
      setCorporateOverviewResult({ data: '', loading: true });
      setQuestionAnalyticsData({ data: '', loading: true });

      getCorporateOverview();
      questionsData();
      getQuestionsData();
    }
  }

  useEffect(() => {
    apiCallsWithoutLevelId();
  }, [tabState, companyTypeId]);

  // Custom functions
  async function getCorporateOverview() {
    try {
      let query = {};
      const preQuery = {
        rp_company_assmt_id: tabState,
        rp_company_id: window.$utility.setCompanyId(userState, companyTypeId),
      };
      query = window.$utility.prepareDynamicParamsCustomChart(
        preQuery,
        selectedArray
      );
      const result = await window.$http.getWithQueryAndHeaders(
        `${window.$utility.setApiUrl(userState)}analytics/corporate/overview`,
        query
      );
      if (result.code === window.$constants.STATUS.OK) {
        setCorporateResiliencyResult({ data: result?.data, loading: false });
        setFactorsDonutData(result?.data);
        setCardTitle(parse(result?.data?.rslncy_level?.summary));
        setCorporateOverviewResult({ data: result?.data, loading: false });
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getBurnoutLevelGraphData(query) {
    try {
      const result = await window.$http.getWithQueryAndHeaders(
        `${window.$utility.setApiUrl(
          userState
        )}analytics/corporate/burnout-lvl`,
        query
      );
      if (result.code === window.$constants.STATUS.OK) {
        prepareBurnOutGraphResultData(result.data);
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  }

  function prepareBurnOutGraphResultData(resultData) {
    let allChartData = [];
    const locationChartData = window.$utility.prepareChartData(
      resultData?.burnout_location,
      resultData?.burnout_location?.chart_type
    );

    const groupChartData = window.$utility.prepareChartData(
      resultData?.burnout_group,
      resultData?.burnout_group?.chart_type
    );

    const ageChartData = window.$utility.prepareChartData(
      resultData?.burnout_age,
      resultData?.burnout_age?.chart_type
    );

    allChartData['chartLocation'] = locationChartData;
    allChartData['chartGroup'] = groupChartData;
    allChartData['chartAge'] = ageChartData;

    setBurnOutGraphResult({ data: allChartData, loading: false });
  }

  async function getSectionResultById(sectionId) {
    try {
      const query = queryParamaBuilder();
      const result = await window.$http.getWithQueryAndHeaders(
        `${window.$utility.setApiUrl(
          userState
        )}analytics/corporate/explore-factors/${sectionId}`,
        query
      );
      if (result.code === window.$constants.STATUS.OK) {
        setCardClicked(false);
        setSectionResultData({ data: result.data, loading: false });
        setCardTitle(parse(result?.data?.rslncy_level?.summary));
        setFactorsDonutData(result?.data);
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  }

  function setFactorsDonutData(resultData) {
    let donutChartResult = [];

    const doughnutChartData = window.$utility.prepareChartData(
      resultData?.chart_donut,
      resultData?.chart_donut?.chart_type
    );

    donutChartResult['doughnutChart'] = doughnutChartData;

    setFactorsDonutChartData({ data: donutChartResult, loading: false });
  }

  function handleFactorTabClick(index, sectionId) {
    setSectionResultData({ data: '', loading: true });
    setFactorTabIndex(index);
    getSectionResultById(sectionId);
    setCardClicked(true);
  }

  async function getLookupData() {
    try {
      let query = {};
      query = {
        types: 'manager',
        rp_company_id: window.$utility.setCompanyId(userState, companyTypeId),
      };

      const result = await window.$http.getWithQueryAndHeaders(
        `${window.$utility.setApiUrl(userState)}lookup`,
        query
      );
      if (result.code === window.$constants.STATUS.OK) {
        setCurrState({
          ...currState,
          managerSelectedOption: result?.data?.manager[0]?.id,
        });
        setManagerOptions({ data: result?.data?.manager, loading: false });
        setQuestionOptions({
          data: result?.data?.corporate_analytics_questions,
          loading: false,
        });
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  }

  function handleQuestionAnalyticsChange(e) {
    const { name, value } = e.target;
    if (name === 'manager') {
      setCurrState({ ...currState, managerSelectedOption: value });
      setQuestionAnalyticsData({ data: '', loading: true });
      setQuestionData({ data: '', loading: true });
    } else if (name === 'question') {
      setCurrState({ ...currState, questionSelectedOption: value });
      setQuestionAnalyticsData({ data: '', loading: true });
      setQuestionData({ data: '', loading: true });
    }
  }

  async function getQuestionAnalticsData() {
    try {
      const query = queryParamaBuilder();
      const result = await window.$http.getWithQueryAndHeaders(
        `${window.$utility.setApiUrl(
          userState
        )}analytics/corporate/question-analytics/${currState?.managerSelectedOption
        }/${currState?.questionSelectedOption}`,
        query
      );
      if (result.code === window.$constants.STATUS.OK) {
        setData(result?.data);
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  }

  function setData(resultData) {
    let allChartData = [];
    if (resultData) {
      const ageChartData = window.$utility.prepareChartData(
        resultData?.question_age,
        resultData?.question_age?.chart_type
      );

      const answerChartData = window.$utility.prepareChartData(
        resultData?.question_answer,
        resultData?.question_answer?.chart_type
      );

      const genderChartData = window.$utility.prepareChartData(
        resultData?.question_gender,
        resultData?.question_gender?.chart_type
      );

      const groupChartData = window.$utility.prepareChartData(
        resultData?.question_group,
        resultData?.question_group?.chart_type
      );

      const locationChartData = window.$utility.prepareChartData(
        resultData?.question_location,
        resultData?.question_location?.chart_type
      );

      allChartData['chartAge'] = ageChartData;
      allChartData['chartAnswer'] = answerChartData;
      allChartData['chartGender'] = genderChartData;
      allChartData['chartGroup'] = groupChartData;
      allChartData['chartLocation'] = locationChartData;

      setQuestionAnalyticsData({ data: allChartData, loading: false });
    }
  }

  const handleCount = (val) => {
    setCountValue(val);
  };

  function changeText() {
    if (!isEmpty(countValue)) {
      return 'selected criteria';
    } else {
      return 'organization';
    }
  }

  function queryParamaBuilder() {
    let query = {};
    if (tabState) {
      const preQuery = {
        rp_company_assmt_id: tabState,
        rp_company_id: window.$utility.setCompanyId(userState, companyTypeId),
      };
      query = window.$utility.prepareDynamicParamsCustomChart(
        preQuery,
        selectedArray
      );
    }
    setQueryParams(query);
    return query;
  }

  function questionsData() {
    if (
      currState?.managerSelectedOption !== '' &&
      currState?.managerSelectedOption !== undefined &&
      currState?.questionSelectedOption !== '' &&
      currState?.questionSelectedOption !== undefined
    ) {
      getQuestionAnalticsData();
    }
  }

  function apiCalls() {
    if (tabState) {
      setCorporateResiliencyResult({ data: '', loading: true });
      setBurnOutGraphResult({ data: '', loading: true });
      setSectionResultData({ data: '', loading: true });
      setFactorsDonutChartData({ data: '', loading: true });

      const query = queryParamaBuilder();

      getBurnoutLevelGraphData(query);
    }
  }

  const getQuestionsData = async () => {
    try {
      if (
        currState?.managerSelectedOption === 0 ||
        currState?.managerSelectedOption === 1 ||
        currState?.managerSelectedOption === -1
      ) {
        setQuestionData({ data: '', loading: true });
        let query = {};
        const preQuery = {
          is_manager: currState?.managerSelectedOption,
          rp_company_assmt_id: tabState,
          rp_company_id: window.$utility.setCompanyId(userState, companyTypeId),
        };
        query = window.$utility.prepareDynamicParamsCustomChart(
          preQuery,
          selectedArray
        );
        const result = await window.$http.getWithQueryAndHeaders(
          `${window.$utility.setApiUrl(
            userState
          )}analytics/corporate/questions`,
          query
        );
        if (result.code === window.$constants.STATUS.OK) {
          setQuestionData({ data: result.data, loading: false });
        } else {
          console.log('Something went wrong!');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  //Inbuit functions section

  useEffect(() => {
    if (tabState) {
      getQuestionsData();
    }
  }, [currState?.managerSelectedOption, tabState, companyTypeId]);

  useEffect(() => {
    getLookupData();
  }, [companyTypeId]);

  useEffect(() => {
    apiCalls();
  }, [tabState, companyTypeId]);

  useEffect(() => {
    questionsData();
  }, [currState, companyTypeId, tabState]);

  const getAssmtId = (val) => {
    if (val) {
      setAssmtId(val);
      dispatch(saveTabs(val, 'corporate'));
    }
  };

  const getFilterValues = (value) => {
    setFactorTabIndex(0);
    const data = window.$utility.setDynamicFilterOptions(value, selectedArray);
    setSelectedArray(data);
    dispatch(saveFilters(data, 'corporate'));
  };

  const getOraStatus = (value, msg, remVal) => {
    setOraStatus(value);
    setOraMsg(msg);
    setReminderEnabledValue(remVal);
  };

  return (
    <main>
      <div className='plan_container'>
        <Sidebar
          assmtId={assmtId}
          getFilterValues={getFilterValues}
          apiCallsWithoutLevelId={apiCallsWithoutLevelId}
          apiCalls={apiCalls}
          handleCount={handleCount}
          tabState={tabState}
          setFactorTabIndex={setFactorTabIndex}
        />
        <div className='main_content'>
          <AdminFilter />
          <div class='col-12 mb-4'>
            <OraFilter
              apiCallsWithoutLevelId={apiCallsWithoutLevelId}
              apiCalls={apiCalls}
              getAssmtId={getAssmtId}
              tab={props?.tab}
              getFilterValues={getFilterValues}
              getOraStatus={getOraStatus}
            />
          </div>
          {oraStatus ? (
            <>
              {isEmpty(corporateOverviewResult?.data?.no_data_message) ? (
                <>
                  <div className='row'>
                    <div className='col-12'>
                      <div className='bg-white burnout_level p-4 br-12 position-relative'>
                        <div className='about z-1 mb-5'>
                          <div className='col-md-12'>
                            <div className='mb-4'>
                              {corporateOverviewResult?.loading ? (
                                <Skeleton height={50} width={500} />
                              ) : (
                                <p className='primary-color'>
                                  Corporate Resiliency
                                </p>
                              )}
                              {corporateOverviewResult?.loading ? (
                                <Skeleton height={100} width={1350} />
                              ) : (
                                <h1
                                  style={{
                                    fontSize: '35px',
                                    lineHeight: '40px',
                                  }}
                                  className='card-heading-light fw-bold'
                                >
                                  {corporateOverviewResult?.data?.title
                                    ? parse(
                                      corporateOverviewResult?.data?.title
                                    )
                                    : ''}
                                </h1>
                              )}
                            </div>
                            <div>
                              <h3
                                className='card-heading-light rp-med-gray fw-5 '
                                style={{ marginBottom: '0' }}
                              >
                                {corporateOverviewResult?.loading ? (
                                  <Skeleton height={75} width={1350} />
                                ) : (
                                  corporateOverviewResult?.data?.subtitle
                                )}
                              </h3>
                            </div>
                          </div>
                        </div>

                        <div className='d-flex justify-content-between mb-5'>
                          {corporateResiliencyResult?.loading ? (
                            [1, 2, 3, 4, 5].map((n) => (
                              <Skeleton height={275} width={275} key={n} />
                            ))
                          ) : (
                            <ParticipantCard
                              participationDetail={
                                corporateResiliencyResult?.data
                                  ?.participation_detail
                              }
                            />
                          )}
                        </div>

                        <div className='col-12'>
                          <h2 className='section-titel section-heading mb-5'>
                            Paying attention to the following company factors
                            will increase impact further...
                          </h2>
                          <div className='assessment-factor-card mb-5 p-0'>
                            {corporateResiliencyResult?.loading ? (
                              <div className='tab-content'>
                                <div className='tab-pane fade show active'>
                                  <div className='d-flex justify-content-between mb-6'>
                                    {[1, 2, 3, 4, 5, 6, 7].map((n) => (
                                      <Skeleton
                                        height={150}
                                        width={130}
                                        key={n}
                                      />
                                    ))}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <FactorTab
                                sectionData={
                                  corporateResiliencyResult?.data?.sections
                                }
                                handleFactorTabClick={handleFactorTabClick}
                                factorTabIndex={factorTabIndex}
                              />
                            )}
                            <div
                              id='AssignmentsTab'
                              className='factor-content'
                              style={{ display: 'block' }}
                            >
                              {corporateResiliencyResult?.loading ? (
                                <Skeleton height={380} width={1370} />
                              ) : (
                                <div className='col-12 text-center box_shadow br-12 p-3'>
                                  {cardClicked ? (
                                    <Skeleton height={40} width={750} />
                                  ) : (
                                    <div
                                      className='result-titel rp-primary-dark-g'
                                      style={{
                                        fontSize: '30px',
                                        letterSpacing: '0.3px',
                                        lineHeight: '35px',
                                      }}
                                    >
                                      {cardTitle ? cardTitle : ''}
                                    </div>
                                  )}

                                  {/* {factorsDonutChartData ? ( */}
                                  <div id='feedback-doughnut'>
                                    {cardClicked ? (
                                      <Skeleton
                                        circle={true}
                                        height={300}
                                        width={300}
                                      />
                                    ) : (
                                      <DoughnutGraph
                                        data={
                                          factorsDonutChartData?.data
                                            ?.doughnutChart
                                        }
                                        height={350}
                                        bgColor={'#ffffff'}
                                        type={
                                          factorsDonutChartData?.data
                                            ?.doughnutChart?.type
                                        }
                                      />
                                    )}
                                  </div>
                                  {/* ) : (
                                    ''
                                  )} */}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className='col-12'>
                          <h2 className='section-titel section-heading mb-5'>
                            Let's see quick information about the Resiliency in
                            your {changeText()}.
                          </h2>
                          <div className='bg-white br-12'>
                            <div className='question-anlytics_graph p-3 br-8 box_shadow bg-white'>
                              <div className='title'>
                                <span className='rp-primary-dark-g fw-6 mb-4'>
                                  {
                                    burnOutGraphResult?.data?.chartLocation
                                      ?.title
                                  }
                                </span>
                              </div>
                              {burnOutGraphResult?.loading ? (
                                <Skeleton height={400} width='100%' />
                              ) : (
                                <StackedColumnChart
                                  data={
                                    burnOutGraphResult?.data?.chartLocation
                                      ?.data['data']
                                  }
                                  xAxisName={
                                    burnOutGraphResult?.data?.chartLocation
                                      ?.xAxisName
                                  }
                                  yAxisName={
                                    burnOutGraphResult?.data?.chartLocation
                                      ?.yAxisName
                                  }
                                  separator={''}
                                  height={390}
                                  categories={
                                    burnOutGraphResult?.data?.chartLocation
                                      ?.categories
                                  }
                                  bgColor={'#ffffff'}
                                  type={
                                    burnOutGraphResult?.data?.chartLocation
                                      ?.type
                                  }
                                />
                              )}
                              {window.$utility.showMessage(
                                burnOutGraphResult?.data?.chartLocation
                                  ?.chartMessage
                              )}
                            </div>

                            <div className='question-anlytics_graph p-3 br-8 box_shadow bg-white'>
                              <div className='title'>
                                <span className='rp-primary-dark-g'>
                                  {burnOutGraphResult?.data?.chartGroup?.title}
                                </span>
                              </div>
                              {burnOutGraphResult?.loading ? (
                                <Skeleton height={400} width='100%' />
                              ) : (
                                <StackedColumnChart
                                  data={
                                    burnOutGraphResult?.data?.chartGroup?.data[
                                    'data'
                                    ]
                                  }
                                  xAxisName={
                                    burnOutGraphResult?.data?.chartGroup
                                      ?.xAxisName
                                  }
                                  yAxisName={
                                    burnOutGraphResult?.data?.chartGroup
                                      ?.yAxisName
                                  }
                                  separator={''}
                                  height={390}
                                  categories={
                                    burnOutGraphResult?.data?.chartGroup
                                      ?.categories
                                  }
                                  bgColor={'#ffffff'}
                                  type={
                                    burnOutGraphResult?.data?.chartGroup?.type
                                  }
                                />
                              )}
                              {window.$utility.showMessage(
                                burnOutGraphResult?.data?.chartGroup
                                  ?.chartMessage
                              )}
                            </div>

                            <div className='question-anlytics_graph p-3 br-8 box_shadow bg-white'>
                              <div className='title'>
                                <span className='rp-primary-dark-g'>
                                  {burnOutGraphResult?.data?.chartAge?.title}
                                </span>
                              </div>
                              {burnOutGraphResult?.loading ? (
                                <Skeleton height={400} width='100%' />
                              ) : (
                                <StackedColumnChart
                                  data={
                                    burnOutGraphResult?.data?.chartAge?.data[
                                    'data'
                                    ]
                                  }
                                  xAxisName={
                                    burnOutGraphResult?.data?.chartAge
                                      ?.xAxisName
                                  }
                                  yAxisName={
                                    burnOutGraphResult?.data?.chartAge
                                      ?.yAxisName
                                  }
                                  separator={''}
                                  height={390}
                                  categories={
                                    burnOutGraphResult?.data?.chartAge
                                      ?.categories
                                  }
                                  bgColor={'#ffffff'}
                                  type={
                                    burnOutGraphResult?.data?.chartAge?.type
                                  }
                                />
                              )}
                              {window.$utility.showMessage(
                                burnOutGraphResult?.data?.chartAge?.chartMessage
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row mt-5'>
                    <div className='col-12'>
                      <div className='bg-white box_shadow br-12 p-4'>
                        <h2 className='section-titel section-heading mb-5'>
                          Find out the response of your {changeText()}'s
                          employees at an individual question level.
                        </h2>
                        <div className='d-flex justify-content-between align-items-center mb-3'>
                          <div className='col-9'>
                            <p className='rp-primary-dark-g fw-bold'>
                              Questionnaire Level Analytics
                            </p>
                          </div>
                          <div className='col-3'>
                            {managerOptions?.loading ? (
                              <Skeleton height={40} />
                            ) : (
                              <ManagerDropdown
                                data={managerOptions?.data}
                                getSelectedManager={getSelectedManager}
                              />
                            )}
                          </div>
                        </div>
                        {questionData?.loading ? (
                          <Skeleton height='30vh' />
                        ) : (
                          <QuestionAnalytics
                            data={questionData?.data}
                            getSelectedQuestion={getSelectedQuestion}
                            manager={currState?.managerSelectedOption}
                          />
                        )}
                        <div className='mt-4'>
                          <div className='question-anlytics_graph p-3 br-8 box_shadow'>
                            <div className='title'>
                              <span className='rp-primary-dark-g'>
                                {
                                  questionAnalyticsData?.data?.chartAnswer
                                    ?.title
                                }
                              </span>
                            </div>
                            {questionAnalyticsData?.loading ? (
                              <Skeleton height='46vh' />
                            ) : (
                              <DoughnutGraph
                                data={questionAnalyticsData?.data?.chartAnswer}
                                height={350}
                                bgColor={'#ffffff'}
                                type={
                                  questionAnalyticsData?.data?.chartAnswer?.type
                                }
                              />
                            )}
                          </div>
                          <div className='question-anlytics_graph br-8 box_shadow p-3'>
                            <div className='title'>
                              <span className='rp-primary-dark-g'>
                                {
                                  questionAnalyticsData?.data?.chartGender
                                    ?.title
                                }
                              </span>
                            </div>
                            {questionAnalyticsData?.loading ? (
                              <Skeleton height='47vh' />
                            ) : (
                              <StackedColumnChart
                                data={
                                  questionAnalyticsData?.data?.chartGender
                                    ?.data['data']
                                }
                                xAxisName={
                                  questionAnalyticsData?.data?.chartGender
                                    ?.xAxisName
                                }
                                yAxisName={
                                  questionAnalyticsData?.data?.chartGender
                                    ?.yAxisName
                                }
                                separator={''}
                                height={390}
                                categories={
                                  questionAnalyticsData?.data?.chartGender
                                    ?.categories
                                }
                                bgColor={'#ffffff'}
                                type={
                                  questionAnalyticsData?.data?.chartGender?.type
                                }
                              />
                            )}
                            {window.$utility.showMessage(
                              questionAnalyticsData?.data?.chartGender
                                ?.chartMessage
                            )}
                          </div>
                          <div className='question-anlytics_graph p-3 br-8 box_shadow'>
                            <div className='title'>
                              <span className='rp-primary-dark-g'>
                                {questionAnalyticsData?.data?.chartAge?.title}
                              </span>
                            </div>
                            {questionAnalyticsData?.loading ? (
                              <Skeleton height='47vh' />
                            ) : (
                              <StackedColumnChart
                                data={
                                  questionAnalyticsData?.data?.chartAge?.data[
                                  'data'
                                  ]
                                }
                                xAxisName={
                                  questionAnalyticsData?.data?.chartAge
                                    ?.xAxisName
                                }
                                yAxisName={
                                  questionAnalyticsData?.data?.chartAge
                                    ?.yAxisName
                                }
                                separator={''}
                                height={390}
                                categories={
                                  questionAnalyticsData?.data?.chartAge
                                    ?.categories
                                }
                                bgColor={'#ffffff'}
                                type={
                                  questionAnalyticsData?.data?.chartAge?.type
                                }
                              />
                            )}
                            {window.$utility.showMessage(
                              questionAnalyticsData?.data?.chartAge
                                ?.chartMessage
                            )}
                          </div>
                          <div className='question-anlytics_graph p-3 br-8 box_shadow'>
                            <div className='title'>
                              <span className='rp-primary-dark-g'>
                                {questionAnalyticsData?.data?.chartGroup?.title}
                              </span>
                            </div>

                            {questionAnalyticsData?.loading ? (
                              <Skeleton height='47vh' />
                            ) : (
                              <StackedColumnChart
                                data={
                                  questionAnalyticsData?.data?.chartGroup?.data[
                                  'data'
                                  ]
                                }
                                xAxisName={
                                  questionAnalyticsData?.data?.chartGroup
                                    ?.xAxisName
                                }
                                yAxisName={
                                  questionAnalyticsData?.data?.chartGroup
                                    ?.yAxisName
                                }
                                separator={''}
                                height={390}
                                categories={
                                  questionAnalyticsData?.data?.chartGroup
                                    ?.categories
                                }
                                bgColor={'#ffffff'}
                                type={
                                  questionAnalyticsData?.data?.chartGroup?.type
                                }
                              />
                            )}
                            {window.$utility.showMessage(
                              questionAnalyticsData?.data?.chartGroup
                                ?.chartMessage
                            )}
                          </div>
                          <div className='question-anlytics_graph p-3 br-8 box_shadow'>
                            <div className='title'>
                              <span className='rp-primary-dark-g'>
                                {
                                  questionAnalyticsData?.data?.chartLocation
                                    ?.title
                                }
                              </span>
                            </div>
                            {questionAnalyticsData?.loading ? (
                              <Skeleton height='47vh' />
                            ) : (
                              <StackedColumnChart
                                data={
                                  questionAnalyticsData?.data?.chartLocation
                                    ?.data['data']
                                }
                                xAxisName={
                                  questionAnalyticsData?.data?.chartLocation
                                    ?.xAxisName
                                }
                                yAxisName={
                                  questionAnalyticsData?.data?.chartLocation
                                    ?.yAxisName
                                }
                                separator={''}
                                height={390}
                                categories={
                                  questionAnalyticsData?.data?.chartLocation
                                    ?.categories
                                }
                                bgColor={'#ffffff'}
                                type={
                                  questionAnalyticsData?.data?.chartLocation
                                    ?.type
                                }
                              />
                            )}
                            {window.$utility.showMessage(
                              questionAnalyticsData?.data?.chartLocation
                                ?.chartMessage
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <h6 className='text-center'>
                  {corporateOverviewResult?.data?.no_data_message}
                </h6>
              )}
            </>
          ) : oraStatus === '' ? (
            ''
          ) : (
            <Message
              tab={props?.tab}
              msg={oraMsg}
              reminderEnabledValue={reminderEnabledValue}
            />
          )}
          <ScrollToTopButton />
        </div>
      </div>
    </main>
  );
};

export default Corporate;
