import React from 'react';
import LoadingSpinner from '../../../../common/LoadingSpinner';
import Modal from 'react-bootstrap/Modal';

const ViewModal = (props) => {
  return (
    <>
      <Modal
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        scrollable={true}
        show={props.showModal ? true : false}
      >
        <Modal.Header className='px-4'>
          <button
            type='button'
            className='btn-close'
            aria-label='Close'
            onClick={props.handleCloseModal}
          ></button>
        </Modal.Header>
        {props?.modalData && !props.isModalLoading ? (
          <>
            <Modal.Body>
              <ul>
                {props?.modalData?.map((data, id) => (
                  <li className=''>
                    <span className='  fw-bold'>{data?.company_name}</span>
                    <span className='mx-1'>-</span>
                    <span>{data?.user_name}</span>
                  </li>
                ))}
              </ul>
            </Modal.Body>
          </>
        ) : (
          <div className='centered' style={{ marginLeft: '1rem' }}>
            <LoadingSpinner />
          </div>
        )}
      </Modal>
    </>
  );
};

export default ViewModal;
