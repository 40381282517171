import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { saveTabs } from '../../../../../../store/tabs/actions';

const OraFilter = (props) => {
  const dispatch = useDispatch();
  const [oraOptions, setOraOptions] = useState({
    data: [],
    loading: true,
  });
  const [oraValue, setOraValue] = useState('');

  const location = useLocation();
  const loc = location.pathname.split('/');

  const userState = useSelector((state) => state?.user);
  const companyTypeId = useSelector((state) => state?.companyTypeId?.payload);
  const tabState = useSelector(
    (state) => state?.tabs?.analytics?.[`${loc[3]}`]
  );

  useEffect(() => {
    if (loc[2] === 'company-analytics' && !oraValue) {
      props?.getAssmtId(oraOptions?.data[0]?.rp_company_assmt_id);
      setOraValue(oraOptions?.data[0]?.rp_company_assmt_id);
    }
  }, [loc]);

  useEffect(() => {
    props?.getAssmtId(tabState);
    return setOraValue(tabState);
  }, [tabState]);

  useEffect(() => {
    getOraOptions();
  }, [companyTypeId]);

  const getOraOptions = async () => {
    try {
      const query = window.$utility.queryParamaBuilder(
        userState,
        companyTypeId
      );
      const result = await window.$http.getWithQueryAndHeaders(
        `${window.$utility.setApiUrl(userState)}analytics/tabs/ora`,
        query
      );
      if (result.code === window.$constants.STATUS.OK) {
        setOraOptions({ data: result?.data?.tabs, loading: false });
        props?.getOraStatus(
          result?.data?.tabs[0]?.is_enabled,
          result?.data?.tabs[0]?.disable_message,
          result?.data?.tabs[0]?.is_reminder_enabled
        );
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOraValue = (el) => {
    dispatch(saveTabs(el?.rp_company_assmt_id, 'corporate'));
    setOraValue(el?.rp_company_assmt_id);
    props?.getAssmtId(el?.rp_company_assmt_id);
    props?.getOraStatus(
      el?.is_enabled,
      el?.disable_message,
      el?.is_reminder_enabled
    );
  };

  return (
    <>
      <div
        className={`assessment-tabs-group ${
          oraOptions?.loading ? 'd-flex' : ''
        }`}
      >
        {oraOptions?.loading ? (
          <>
            {[1, 2, 3].map((n, id) => (
              <Skeleton
                key={id}
                width='150px'
                height='60px'
                style={{ marginRight: '3px' }}
              />
            ))}
          </>
        ) : (
          oraOptions?.data?.map((el, id) => (
            <button
              className={
                (oraValue ? oraValue : tabState) === el?.rp_company_assmt_id
                  ? 'btn-assment-month month-active'
                  : 'btn-assment-month prev-mnth'
              }
              key={id}
              onClick={() => handleOraValue(el)}
              style={id > 7 ? { marginTop: '10px' } : {}}
            >
              <span>{el?.display_tab_up}</span>
              <span>{el?.display_tab_down}</span>
            </button>
          ))
        )}
      </div>
    </>
  );
};

export default OraFilter;
