import React, { useState } from 'react';
import SuccessMsg from '../../../Messages';
import LoadingSpinner from '../../../LoadingSpinner';
import Spinner from 'react-bootstrap/Spinner';

const InitialState = {
  education: '',
  designation: '',
  relationship: '',
  workHours: '',
  workFrom: '',
  consulted: '',
  preferredDay: '',
  preferredTime: '',
  message: '',
  agreeToShare: false,
};

const LifeCoach = (props) => {
  const [currState, setCurrState] = useState(InitialState);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState({ success: '', failure: '' });
  const [buttonState, setButtonState] = useState({
    value: '',
    isLoading: false,
  });
  const inputRegex = /^\s*$/;

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    let currentFormErrors = formErrors;
    switch (name) {
      case 'education':
        if (!value) {
          currentFormErrors[name] = 'Please select education';
        } else {
          delete currentFormErrors[name];
        }
        break;
      case 'designation':
        if (!value) {
          currentFormErrors[name] = 'Please enter designation';
        } else {
          delete currentFormErrors[name];
        }
        break;
      case 'relationship':
        if (!value) {
          currentFormErrors[name] = 'Please select relationship';
        } else {
          delete currentFormErrors[name];
        }
        break;
      case 'workHours':
        if (!value) {
          currentFormErrors[name] = 'how many hours do you spend';
        } else {
          delete currentFormErrors[name];
        }
        break;
      case 'workFrom':
        if (!value) {
          currentFormErrors[name] = 'Do you work from home';
        } else {
          delete currentFormErrors[name];
        }
        break;
      case 'message':
        if (!value) {
          currentFormErrors[name] = 'Please enter a message';
        } else {
          delete currentFormErrors[name];
        }
        break;
      case 'consulted':
        if (!value) {
          currentFormErrors[name] = 'Are you consulted';
        } else {
          delete currentFormErrors[name];
        }
        break;
      case 'agreeToShare':
        if (checked) {
          return setCurrState({ ...currState, [name]: true });
        } else {
          return setCurrState({ ...currState, [name]: false });
        }
      case 'preferredDay':
        if (!value) {
          currentFormErrors[name] = 'Please select food habits';
        } else {
          delete currentFormErrors[name];
        }
        break;
      case 'preferredTime':
        if (!value) {
          currentFormErrors[name] = 'Please select food habits';
        } else {
          delete currentFormErrors[name];
        }
        break;

      default:
        currentFormErrors = 'All fields are required';
        break;
    }
    setCurrState({ ...currState, [name]: value });
    setFormErrors(currentFormErrors);
  };

  const handleSubmit = async () => {
    setMessage({ success: '', failure: '' });
    if (!currState?.education) {
      return setIsSubmitting(true);
    } else if (!currState?.designation) {
      return setIsSubmitting(true);
    } else if (
      currState?.designation &&
      global.inputRegex.test(currState?.designation)
    ) {
      return setIsSubmitting(true);
    } else if (!currState?.relationship) {
      return setIsSubmitting(true);
    } else if (!currState?.workHours) {
      return setIsSubmitting(true);
    } else if (!currState?.workFrom) {
      return setIsSubmitting(true);
    } else if (!currState?.consulted) {
      return setIsSubmitting(true);
    } else if (!currState?.message) {
      return setIsSubmitting(true);
    } else if (
      currState?.message &&
      global.inputRegex.test(currState?.message)
    ) {
      return setIsSubmitting(true);
    } else if (!currState?.preferredDay) {
      return setIsSubmitting(true);
    } else if (!currState?.preferredTime) {
      return setIsSubmitting(true);
    } else {
      setButtonState({ value: 'submit', isLoading: true });
      try {
        const result = await window.$http.postWithHeaders(
          'contact/life_coach',
          {
            rp_gbl_education_id: currState?.education,
            designation: currState?.designation,
            relationship_status_id: currState?.relationship,
            work_hours_id: currState?.workHours,
            work_model_id: currState?.workFrom,
            previous_consultation_id: currState?.consulted,
            preferred_day: currState?.preferredDay,
            preferred_time: currState?.preferredTime,
            consultation_reason: currState?.message,
            agree_to_share_result: currState?.agreeToShare,
          }
        );

        if (result.code === window.$constants.STATUS.OK) {
          return setMessage({ success: result.message, failure: '' });
        } else {
          return setMessage({
            success: '',
            failure: 'Some error occured. Please contact admin',
          });
        }
      } catch (error) {
        console.log(error);
      }
      setButtonState({ value: 'submit', isLoading: false });
    }
  };

  const handleSendReminder = async () => {
    setButtonState({ value: 'reminder', isLoading: true });
    try {
      const result = await window.$http.postWithHeaders(
        `schedule_call/reminder/${props?.id}`
      );
      if (result.code === window.$constants.STATUS.OK) {
        setMessage({ success: result?.message, failure: '' });
      } else {
        setMessage({ success: '', failure: result?.message });
      }
    } catch (error) {
      console.log(error);
    }
    setButtonState({ value: 'reminder', isLoading: false });
  };
  return (
    <>
      {message.success ? (
        <SuccessMsg
          msg={message.success}
          handleCloseModal={props?.handleCloseModal}
        />
      ) : (
        <>
          {props?.statusData ? (
            <>
              <div className='modal-body'>
                {props?.statusData?.lifecoach?.is_already_scheduled ? (
                  <div className='appoitment-massage mt-0'>
                    {props?.statusData?.lifecoach?.message}
                  </div>
                ) : (
                  <div
                    className='tab-pane fade show active'
                    id='nav-profile'
                    role='tabpanel'
                    aria-labelledby='nav-profile-tab'
                  >
                    <div className='row'>
                      <div className='col-12 col-xl-6 mb-4'>
                        <label className='asterisk'>
                          Educational Qualification
                        </label>
                        <select
                          name='education'
                          className={
                            formErrors?.education ||
                            (isSubmitting && !currState?.education)
                              ? 'form-select form-control border-danger'
                              : 'form-select form-control'
                          }
                          value={currState?.education}
                          onChange={handleChange}
                        >
                          <option hidden value=''>
                            Select
                          </option>
                          {props?.formOptions?.education?.map((data, id) => (
                            <option value={data?.id} key={id}>
                              {data?.value}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className='col-md-12 col-lg-6 mb-4'>
                        <label className='asterisk'>Designation</label>
                        <input
                          type='text'
                          className={
                            formErrors?.designation ||
                            (isSubmitting &&
                              (!currState?.designation ||
                                global.inputRegex.test(currState?.designation)))
                              ? 'form-control border-danger'
                              : 'form-control'
                          }
                          placeholder='Designation'
                          aria-label='designation'
                          onChange={handleChange}
                          value={currState?.designation}
                          name='designation'
                        />
                      </div>

                      <div className='col-12 col-xl-6 mb-4'>
                        <label className='asterisk'>Relationship Status</label>
                        <select
                          className={
                            formErrors?.relationship ||
                            (isSubmitting && !currState?.relationship)
                              ? 'form-select form-control border-danger'
                              : 'form-select form-control'
                          }
                          name='relationship'
                          value={currState?.relationship}
                          onChange={handleChange}
                        >
                          <option hidden value=''>
                            Select
                          </option>
                          {props?.formOptions?.relationship_status?.map(
                            (data, id) => (
                              <option value={data?.id} key={id}>
                                {data?.value}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className='col-12 col-xl-6 mb-4'>
                        <label className='asterisk'>
                          On an average how many hours do you spend at work
                        </label>
                        <select
                          className={
                            formErrors?.workHours ||
                            (isSubmitting && !currState?.workHours)
                              ? 'form-select form-control border-danger'
                              : 'form-select form-control'
                          }
                          name='workHours'
                          value={currState?.workHours}
                          onChange={handleChange}
                        >
                          <option hidden value=''>
                            Select
                          </option>
                          {props?.formOptions?.work_hours?.map((data, id) => (
                            <option value={data?.id} key={id}>
                              {data?.value}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className='col-12 col-xl-6 mb-4'>
                        <label className='asterisk'>
                          Do you work from home or from the office
                        </label>
                        <select
                          className={
                            formErrors?.workFrom ||
                            (isSubmitting && !currState?.workFrom)
                              ? 'form-select form-control border-danger'
                              : 'form-select form-control'
                          }
                          name='workFrom'
                          value={currState?.workFrom}
                          onChange={handleChange}
                        >
                          <option hidden value=''>
                            Select
                          </option>
                          {props?.formOptions?.work_model?.map((data, id) => (
                            <option value={data?.id} key={id}>
                              {data?.value}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className='col-12 col-xl-6 mb-4'>
                        <label className='asterisk'>
                          Have you previously consulted a psychologist/life
                          coach
                        </label>
                        <select
                          className={
                            formErrors?.consulted ||
                            (isSubmitting && !currState?.consulted)
                              ? 'form-select form-control border-danger'
                              : 'form-select form-control'
                          }
                          value={currState?.consulted}
                          name='consulted'
                          onChange={handleChange}
                        >
                          <option hidden value=''>
                            Select
                          </option>
                          {props?.formOptions?.previous_consultation?.map(
                            (data, id) => (
                              <option value={data?.id} key={id}>
                                {data?.value}
                              </option>
                            )
                          )}
                        </select>
                      </div>

                      <div className='col-12 col-xl-6 mb-4'>
                        <label className='asterisk'>Preferred Day</label>
                        <select
                          className={
                            formErrors?.preferredDay ||
                            (isSubmitting && !currState?.preferredDay)
                              ? 'form-select form-control border-danger'
                              : 'form-select form-control'
                          }
                          name='preferredDay'
                          value={currState?.preferredDay}
                          onChange={handleChange}
                        >
                          <option hidden value=''>
                            Select
                          </option>
                          {props?.formOptions?.preferred_day?.map(
                            (data, id) => (
                              <option value={data?.id} key={id}>
                                {data?.value}
                              </option>
                            )
                          )}
                        </select>
                      </div>

                      <div className='col-12 col-xl-6 mb-4'>
                        <label className='asterisk'>Preferred Time</label>
                        <select
                          className={
                            formErrors?.preferredTime ||
                            (isSubmitting && !currState?.preferredTime)
                              ? 'form-select form-control border-danger'
                              : 'form-select form-control'
                          }
                          name='preferredTime'
                          value={currState?.preferredTime}
                          onChange={handleChange}
                        >
                          <option hidden value=''>
                            Select
                          </option>
                          {props?.formOptions?.preferred_time?.map(
                            (data, id) => (
                              <option value={data?.id} key={id}>
                                {data?.value}
                              </option>
                            )
                          )}
                        </select>
                      </div>

                      <div className='col-md-12 mb-4'>
                        <label className='asterisk'>
                          Reason for consultation
                        </label>
                        <textarea
                          className={
                            formErrors?.message ||
                            (isSubmitting &&
                              (!currState?.message ||
                                global.inputRegex.test(currState?.message)))
                              ? 'form-control border-danger'
                              : 'form-control'
                          }
                          value={currState?.message}
                          name='message'
                          onChange={handleChange}
                        ></textarea>
                      </div>

                      <div className='col-md-12 mb-4'>
                        <div className='form-check form-check-inline'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value={currState?.agreeToShare}
                            name='agreeToShare'
                            onChange={handleChange}
                          />
                          <label
                            className='form-check-label'
                            htmlFor='agreeToShare'
                          >
                            I agree to share my assessment result with the life
                            coach.
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className='modal-footer'>
                {props?.statusData?.lifecoach?.is_already_scheduled ? (
                  <button
                    type='button'
                    className={`btn btn-rp-primary btn-small ${
                      !props?.statusData?.lifecoach?.can_send_reminder ||
                      (buttonState?.value === 'reminder' &&
                        buttonState?.isLoading)
                        ? 'disabled'
                        : ''
                    }`}
                    onClick={handleSendReminder}
                  >
                    {buttonState?.value === 'reminder' &&
                    buttonState?.isLoading ? (
                      <Spinner
                        as='i'
                        animation='border'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      'Send Reminder'
                    )}
                  </button>
                ) : (
                  <button
                    type='button'
                    className={`btn btn-rp-primary btn-small ms-3 ${
                      buttonState?.value === 'submit' && buttonState?.isLoading
                        ? 'disabled'
                        : ''
                    }`}
                    onClick={handleSubmit}
                  >
                    {buttonState?.value === 'submit' &&
                    buttonState?.isLoading ? (
                      <Spinner
                        as='i'
                        animation='border'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      'Submit'
                    )}
                  </button>
                )}
                <button
                  type='button'
                  className='btn btn-rp-primary-red btn-small'
                  data-bs-dismiss='modal'
                  onClick={props.handleCloseModal}
                >
                  Cancel
                </button>
                {message?.failure && (
                  <p className='mt-4 text-danger fs-6'> {message?.failure} </p>
                )}
              </div>
            </>
          ) : (
            <div className='centered'>
              <LoadingSpinner />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default LifeCoach;
