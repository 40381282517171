import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Assessment from '../../../../../../common/Assessment';

const Questionnaire = () => {
  const [resultData, setResultData] = useState([]);
  const [count, setCount] = useState('');
  const rp_company_assmt_id = useSelector((state) => state?.assmtId?.payload);

  useEffect(() => {
    const getQuestions = async () => {
      if (rp_company_assmt_id) {
        try {
          const data = await window.$http.getWithHeaders(
            `assessment/detail/${rp_company_assmt_id}`
          );

          if (data.code === window.$constants.STATUS.OK) {
            setResultData(data.data);
            setCount(`${Math.ceil(data?.data?.questions?.length / 3)}`);
          } else {
            console.log('Something went wrong!');
          }
        } catch (error) {
          console.log(error);
        }
      }
    };

    getQuestions();
  }, [rp_company_assmt_id]);

  return (
    <section id='assessment'>
      <div className='container'>
        <div className='row english_row'>
          <div className='col-12 '>
            <div className='position-relative px-2 px-lg-0'>
              <Assessment count={count} resultData={resultData} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Questionnaire;
