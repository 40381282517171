import React from 'react';
// import ReactFC from 'react-fusioncharts';
// import FusionCharts from 'fusioncharts';
// import MSColumn2D from 'fusioncharts/fusioncharts.charts';
// import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
// ReactFC.fcRoot(FusionCharts, MSColumn2D, FusionTheme);
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

// const Pie2dGraph = (props) => {
//     const chartConfigs = {
//       type: props?.type,
//       renderAt: 'burnoutManagers_pie',
//       width: '100%',
//       height: props?.height,
//       dataFormat: 'json',
//       dataSource: {
//         chart: {
//           bgColor: props?.bgColor ? props?.bgColor : '#ffffff',
//           slicingDistance: '10',
//           numbersuffix: '%',
//           showPercentValues: '0',
//           showPercentInTooltip: '0',
//           decimals: '2',
//           useDataPlotColorForLabels: '1',
//           //Theme
//           theme: 'fusion',
//         },
//         // data
//         data: props?.data,
//     },
// };

const Pie2dGraph = (props) => {
    const type = props?.type || 'pie';
    const hasData = props.data?.some((item) => item.value > 0);
  
    const data = {
      labels: hasData ? props.data.map((item) => item.label) : ['No Data Found'],
      datasets: [
        {
          data: hasData ? props.data.map((item) => item.value) : [1],
          backgroundColor: hasData ? props.data.map((item) => item.color || '#0075c2') : ['#e0e0e0'],
        },
      ],
    };
  
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        tooltip: {
          callbacks: {
            label: (tooltipItem) => hasData ? `${tooltipItem.raw}%` : '',
          },
          backgroundColor: '#fff',
          titleColor: '#42526e',
          bodyColor: '#42526e',
          borderColor: '#bbbbbb',
        },
        legend: {
          position: 'bottom',
          padding: 10,
          labels: {
            padding: 26,
            boxWidth: 12,
            boxHeight: 12,
            usePointStyle: true,
            pointStyle: 'circle',
            font: {
              size: 14,
            },
            generateLabels: function (chart) {
              const dataset = chart.data.datasets[0];
              return chart.data.labels.map((label, index) => {
              //   const value = dataset.data[index];
              //   const percentage = value;
                const fontColor = dataset.backgroundColor[index];
                return {
                  text: label,
                  fillStyle: fontColor,
                  strokeStyle: fontColor,
                  padding: 10,
                  hidden: chart.getDatasetMeta(0).data[index].hidden,
                  index: index,
                };
              });
            },
          },
        },
        datalabels: {
          anchor: 'center',
          align: 'center',
          color:'#fff',
         
          formatter: (value, context) => {
            if (!hasData) return '';
            const total = context.dataset.data.reduce((acc, val) => acc + val, 0);
            const percentage = (value / total) * 100;
            const twoDecimalPercentage = percentage.toFixed(2);
            const label = context.chart.data.labels[context.dataIndex];
            return percentage > 0 ? `${percentage.toFixed(0)}%` : '';
          },
        },
      },
      animation: {
        animateRotate: true,
        animateScale: true,
      },
    };
  
    return (
      <div style={{ width: '100%', height: '250px', margin: '0 auto', }}>
        <Pie data={data} options={options} height={props.height} type={type} />
      </div>
    );
  };
  
  export default Pie2dGraph;