import { SAVENOTIFICATIONDATA, REMOVENOTIFICATIONDATA } from './types';

const INITIAL_STATE = {};

const notificationData = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SAVENOTIFICATIONDATA: {
      return {
        ...state,
        payload,
      };
    }
    case REMOVENOTIFICATIONDATA: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default notificationData;
