import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import RpLogo from '../../../assets/images/rp-logo.png';
import { Link } from 'react-router-dom';
import Footer from '../Footer';
import LoadingSpinner from '../../../common/LoadingSpinner';
import { Spinner } from 'react-bootstrap';

const initialState = {
  password: '',
  confirmPassword: '',
};

const ResetPassword = () => {
  const navigate = useNavigate();
  const [state, setState] = useState(initialState);
  const [show, setShow] = useState(initialState);
  const [error, setError] = useState(initialState);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showActive, setShowActive] = useState('reset');
  const [validPwdHash, setValidPwdHash] = useState();
  const [expiryMessage, setExpiryMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState({
    success: '',
    failure: '',
  });
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const pwdRegex =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*+.-_:~`]).{9,20}$/i;

  const { password_reset_hash } = useParams();
  const url = window.location.pathname.split('/');

  useEffect(() => {
    setIsLoading(true);
    const validatePassword = async () => {
      try {
        const result = await window.$http.postWithoutHeaders(
          'password/validate',
          {
            password_reset_hash,
            is_reset: url[2] === 'create' ? 0 : 1,
          }
        );
        if (result.code !== window.$constants.STATUS.OK) {
          setValidPwdHash(false);
          setIsLoading(false);
          setExpiryMessage(result.message);
        } else {
          setIsLoading(false);
          setValidPwdHash(true);
        }
      } catch (error) {
        console.log(error);
      }
    };

    validatePassword();
  }, []);

  const handleChangePassword = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
    if (isSubmitting) {
      setError({ ...error, [name]: false });
      setMessage({ success: '', failure: '' });
    }
  };

  const resetPassword = async () => {
    setIsSubmitting(true);
    if (!state.password && !state.confirmPassword) {
      setMessage({ success: '', failure: 'Password is required.' });
      return setError({ confirmPassword: true, password: true });
    } else if (!state.password) {
      setMessage({ success: '', failure: 'Password is required.' });
      return setError({ ...error, password: true });
    } else if (!state.confirmPassword) {
      setMessage({ success: '', failure: 'Confirm password is required.' });
      return setError({ ...error, confirmPassword: true });
    } else if (
      state.password &&
      state.confirmPassword &&
      state.password !== state.confirmPassword
    ) {
      setMessage({
        success: '',
        failure: 'Password and Confirm password do not match.',
      });
      return setError({ confirmPassword: true, password: true });
    } else if (!pwdRegex.test(state.password)) {
      setMessage({ success: '', failure: 'Password format is invalid.' });
      return setError({ confirmPassword: true, password: true });
    } else {
      setIsButtonLoading(true);
      try {
        const result = await window.$http.postWithoutHeaders('password/reset', {
          password_reset_hash,
          password: state?.password,
          is_reset: url[2] === 'create' ? 0 : 1,
        });
        if (result.code === window.$constants.STATUS.OK) {
          setMessage({ failure: '', success: result?.message });
          setTimeout(() => {
            setShowActive('login');
          }, 1000);
        } else {
          setMessage({ failure: result?.message, success: '' });
        }
      } catch (error) {
        console.log(error);
      }
      setIsButtonLoading(false);
    }
  };

  const handleKeypress = (e) => {
    if (e.key === 'Enter' || e.key === 'NumpadEnter') {
      resetPassword();
    }
  };

  return (
    <main style={{ marginTop: '0' }}>
      <div className='row m-0'>
        <div className='col-lg-8 login-bg position-static'></div>
        <div className='col-md-12 col-lg-4 login-container position-relative'>
          <div className='company-logo d-flex justify-content-between'>
            <div className='rp-logo'>
              <a href='#!'>
                <img src={RpLogo} alt='RP Logo' />
              </a>
            </div>
            <span className='separator'></span>
            <div className='client-logo'>
              <Link to='#!'>
                <img
                  src={`${
                    process.env.REACT_APP_API_URL
                  }media/company/${window.$utility.getSubDomain()}`}
                  alt='client-icon'
                />
              </Link>
            </div>
          </div>

          {isLoading ? (
            <div className='centered' style={{ marginLeft: '1rem' }}>
              <LoadingSpinner />
            </div>
          ) : (
            ''
          )}
          {!isLoading && validPwdHash ? (
            <>
              <div className='wlcm-note mt-6'>
                <h6>
                  {url[2] === 'create' ? 'Create Password' : 'Reset Password'}
                </h6>
              </div>
              {showActive === 'reset' ? (
                <>
                  <p>
                    Password must be between 9–20 characters and include at
                    least one number, one upper case letter, one lower case
                    letter and one special character.
                  </p>

                  <div className='login-box mt-4'>
                    <div>
                      <div className='mb-4'>
                        <div className='d-flex align-items-center'>
                          <i className='fas fa-lock position-absolute ms-2'></i>
                          <input
                            type={show.password ? 'text' : 'password'}
                            name='password'
                            className={
                              error.password
                                ? 'form-control invalid'
                                : 'form-control'
                            }
                            id='id_password'
                            placeholder={
                              url[2] === 'create' ? 'Password' : 'New Password'
                            }
                            onChange={handleChangePassword}
                            value={state.password}
                            onKeyPress={handleKeypress}
                          />
                          <div
                            onClick={() =>
                              setShow({ password: !show.password })
                            }
                          >
                            {show.password ? (
                              <i className='bi bi-eye' id='togglePassword' />
                            ) : (
                              <i
                                className='bi bi-eye-slash'
                                id='togglePassword'
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='mb-4'>
                        <div className='d-flex align-items-center position-relative'>
                          <i className='fas fa-lock position-absolute ms-2'></i>
                          <input
                            type={show.confirmPassword ? 'text' : 'password'}
                            name='confirmPassword'
                            className={
                              error.confirmPassword
                                ? 'form-control invalid'
                                : 'form-control'
                            }
                            id='id_confirmPassword'
                            placeholder='Confirm Password'
                            onChange={handleChangePassword}
                            value={state.confirmPassword}
                            onKeyPress={handleKeypress}
                          />
                          <div
                            onClick={() =>
                              setShow({
                                confirmPassword: !show.confirmPassword,
                              })
                            }
                          >
                            {show.confirmPassword ? (
                              <i className='bi bi-eye' id='togglePassword' />
                            ) : (
                              <i
                                className='bi bi-eye-slash'
                                id='togglePassword'
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='login-action d-flex'>
                        <button
                          className={`btn btn-login mt-2 ${
                            isButtonLoading ? 'disabled' : ''
                          }`}
                          onClick={resetPassword}
                        >
                          {isButtonLoading ? (
                            <Spinner
                              as='i'
                              animation='border'
                              size='sm'
                              role='status'
                              aria-hidden='true'
                              style={{ color: '#fff' }}
                            />
                          ) : (
                            'Save'
                          )}
                        </button>
                      </div>
                      <div className='login-type'>OR</div>
                      <button
                        type='button'
                        className='btn loginotp btn-login'
                        onClick={() =>
                          navigate(`/login/otp/${password_reset_hash}`)
                        }
                      >
                        Login with Authentication Code
                      </button>
                      {message.failure ? (
                        <p className='mt-4 text-danger fs-6'>
                          {' '}
                          {message.failure}{' '}
                        </p>
                      ) : (
                        message.success && (
                          <p className='primary-color mt-4 fs-6'>
                            {message.success}
                          </p>
                        )
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {' '}
                  {url[2] === 'create' ? (
                    <p className='mt-4'>
                      Your account password has been created successfully.
                    </p>
                  ) : (
                    <p>Your account password has been resetted successfully.</p>
                  )}
                  <button
                    className='btn btn-login mt-3'
                    onClick={() => navigate('/login')}
                  >
                    Back to login
                  </button>
                </>
              )}
            </>
          ) : url[2] === 'create' && !isLoading ? (
            <>
              <p className='mt-6'>{expiryMessage}</p>
              <div className='login-action d-flex mt-4'>
                <button
                  className='btn btn-login mt-2'
                  onClick={() => navigate('/login')}
                >
                  Back to login
                </button>
              </div>
            </>
          ) : (
            !isLoading && (
              <>
                <p className='mt-6'>{expiryMessage}</p>
                <div className='login-action d-flex mt-4'>
                  <button
                    className='btn btn-login mt-2'
                    onClick={() => navigate('/login')}
                  >
                    Back to login
                  </button>
                </div>
              </>
            )
          )}
          <Footer value='reset' />
        </div>
      </div>
    </main>
  );
};

export default ResetPassword;
