import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.scss';
import './assets/custom_css/index.css';
import './assets/custom_css/circle.css';
import './assets/flat_icon/flaticon_rp-icons.css';
import 'animate.css';
import './config';
import store from './store';
import { Provider } from 'react-redux';

import FusionCharts from 'fusioncharts';
FusionCharts.options.license({
  key: global.FUSION_KEY,
  creditLabel: false,
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
