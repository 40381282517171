import React, { useState, useEffect } from 'react';
import SuccessMsg from '../../../Messages';
import LoadingSpinner from '../../../LoadingSpinner';
import Spinner from 'react-bootstrap/Spinner';

const InitialState = {
  weight: '',
  height: '',
  bloodGroup: '',
  foodHabits: '',
  preferredDay: '',
  preferredTime: '',
  disease: [],
  otherDisease: '',
  agreeToShare: false,
};

const Nutritionist = (props) => {
  const [currState, setCurrState] = useState(InitialState);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState({
    success: '',
    failure: '',
  });
  const [buttonState, setButtonState] = useState({
    value: '',
    isLoading: false,
  });

  useEffect(() => {
    currState?.disease?.splice(0, currState?.disease?.length);
    setCurrState({ ...currState, disease: currState?.disease });
  }, []);

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    let currentFormErrors = formErrors;
    switch (name) {
      case 'weight':
        if (!value) {
          currentFormErrors[name] = 'Please enter weight';
        } else {
          delete currentFormErrors[name];
        }
        break;
      case 'height':
        if (!value) {
          currentFormErrors[name] = 'Please enter height';
        } else {
          delete currentFormErrors[name];
        }
        break;
      case 'bloodGroup':
        if (!value) {
          currentFormErrors[name] = 'Please select blood group';
        } else {
          delete currentFormErrors[name];
        }
        break;
      case 'foodHabits':
        if (!value) {
          currentFormErrors[name] = 'Please select food habits';
        } else {
          delete currentFormErrors[name];
        }
        break;
      case 'preferredDay':
        if (!value) {
          currentFormErrors[name] = 'Please select food habits';
        } else {
          delete currentFormErrors[name];
        }
        break;
      case 'preferredTime':
        if (!value) {
          currentFormErrors[name] = 'Please select food habits';
        } else {
          delete currentFormErrors[name];
        }
        break;
      case 'disease':
        if (checked) {
          currState?.disease?.push(value);
          return setCurrState({ ...currState, [name]: currState?.disease });
        } else {
          for (var i = 0; i < currState?.disease?.length; i++) {
            if (currState?.disease[i] === value) {
              currState?.disease?.splice(i, 1);
              i--;
            }
          }
          return setCurrState({ ...currState, [name]: currState?.disease });
        }
      case 'otherDisease':
        if (!value) {
          currentFormErrors[name] = 'Please enter other disease';
        } else {
          delete currentFormErrors[name];
        }
        break;
      case 'agreeToShare':
        if (checked) {
          return setCurrState({ ...currState, [name]: true });
        } else {
          return setCurrState({ ...currState, [name]: false });
        }
      default:
        currentFormErrors = 'All fields are required';
        break;
    }
    setCurrState({ ...currState, [name]: value });
    setFormErrors(currentFormErrors);
  };

  const handleSubmit = async () => {
    setMessage({ success: '', failure: '' });
    if (!currState?.weight) {
      return setIsSubmitting(true);
    } else if (!currState?.height) {
      return setIsSubmitting(true);
    } else if (!currState?.bloodGroup) {
      return setIsSubmitting(true);
    } else if (!currState?.foodHabits) {
      return setIsSubmitting(true);
    } else if (!currState?.preferredDay) {
      return setIsSubmitting(true);
    } else if (!currState?.preferredTime) {
      return setIsSubmitting(true);
    } else if (
      currState?.disease.some((el) => el === window.$enums.Disease.Other) &&
      !currState?.otherDisease
    ) {
      return setIsSubmitting(true);
    } else {
      setButtonState({ value: 'submit', isLoading: true });
      try {
        const result = await window.$http.postWithHeaders(
          'contact/nutritionist',
          {
            weight: currState?.weight,
            height: currState?.height,
            blood_group_id: currState?.bloodGroup,
            food_habit_id: currState?.foodHabits,
            preferred_day: currState?.preferredDay,
            preferred_time: currState?.preferredTime,
            health_condition_id: `${currState?.disease?.join(', ')}`,
            other_disease: currState?.otherDisease,
            agree_to_share_result: currState?.agreeToShare,
            rp_food_diet_plan_id: props?.planId,
          }
        );
        if (result.code === window.$constants.STATUS.OK) {
          currState?.disease?.splice(0, currState?.disease?.length);
          return setMessage({ success: result.message, failure: '' });
        } else {
          return setMessage({
            success: '',
            failure: 'Some error occured. Please contact admin',
          });
        }
      } catch (error) {
        console.log(error);
      }
      setButtonState({ value: 'submit', isLoading: false });
    }
  };

  const handleSendReminder = async () => {
    setButtonState({ value: 'reminder', isLoading: true });
    try {
      const result = await window.$http.postWithHeaders(
        `schedule_call/reminder/${props?.id}`
      );
      if (result.code === window.$constants.STATUS.OK) {
        setMessage({ success: result?.message, failure: '' });
      } else {
        setMessage({ success: '', failure: result?.message });
      }
    } catch (error) {
      console.log(error);
    }
    setButtonState({ value: 'reminder', isLoading: false });
  };

  return (
    <>
      {message.success ? (
        <SuccessMsg
          msg={message.success}
          handleCloseModal={props?.handleCloseModal}
        />
      ) : (
        <>
          {props?.statusData ? (
            <>
              <div className='modal-body'>
                {props?.statusData?.nutritionist?.is_already_scheduled ? (
                  <div className='appoitment-massage mt-0'>
                    {props?.statusData?.nutritionist?.message}
                  </div>
                ) : (
                  <div
                    className='tab-pane fade show active'
                    id='nav-home'
                    role='tabpanel'
                    aria-labelledby='nav-home-tab'
                  >
                    <div className='row'>
                      <div className='col-md-12 col-lg-6 mb-4'>
                        <label className='asterisk'>Weight in KG</label>
                        <input
                          type='text'
                          className={
                            formErrors?.weight ||
                            (isSubmitting && !currState?.weight)
                              ? 'form-control border-danger'
                              : 'form-control'
                          }
                          placeholder='Weight'
                          name='weight'
                          value={currState?.weight}
                          onChange={handleChange}
                        />
                      </div>
                      <div className='col-md-12 col-lg-6 mb-4'>
                        <label className='asterisk'>Height in CM</label>
                        <input
                          type='text'
                          className={
                            formErrors?.height ||
                            (isSubmitting && !currState?.height)
                              ? 'form-control border-danger'
                              : 'form-control'
                          }
                          placeholder='Height'
                          name='height'
                          value={currState?.height}
                          onChange={handleChange}
                        />
                      </div>

                      <div className='col-12 col-xl-6 mb-4'>
                        <label className='asterisk'>Blood Group</label>
                        <select
                          className={
                            formErrors?.bloodGroup ||
                            (isSubmitting && !currState?.bloodGroup)
                              ? 'form-select form-control border-danger'
                              : 'form-select form-control'
                          }
                          name='bloodGroup'
                          value={currState?.bloodGroup}
                          onChange={handleChange}
                        >
                          <option hidden value=''>
                            Select
                          </option>
                          {props?.formOptions?.blood_group?.map((data, id) => (
                            <option value={data?.id} key={id}>
                              {data?.value}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className='col-12 col-xl-6 mb-4'>
                        <label className='asterisk'>Food habits</label>
                        <select
                          className={
                            formErrors?.foodHabits ||
                            (isSubmitting && !currState?.foodHabits)
                              ? 'form-select form-control border-danger'
                              : 'form-select form-control'
                          }
                          name='foodHabits'
                          value={currState?.foodHabits}
                          onChange={handleChange}
                        >
                          <option hidden value=''>
                            Select
                          </option>
                          {props?.formOptions?.food_habit?.map((data, id) => (
                            <option value={data?.id} key={id}>
                              {data?.value}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className='col-12 col-xl-6 mb-4'>
                        <label className='asterisk'>Preferred Day</label>
                        <select
                          className={
                            formErrors?.preferredDay ||
                            (isSubmitting && !currState?.preferredDay)
                              ? 'form-select form-control border-danger'
                              : 'form-select form-control'
                          }
                          name='preferredDay'
                          value={currState?.preferredDay}
                          onChange={handleChange}
                        >
                          <option hidden value=''>
                            Select
                          </option>
                          {props?.formOptions?.preferred_day?.map(
                            (data, id) => (
                              <option value={data?.id} key={id}>
                                {data?.value}
                              </option>
                            )
                          )}
                        </select>
                      </div>

                      <div className='col-12 col-xl-6 mb-4'>
                        <label className='asterisk'>Preferred Time</label>
                        <select
                          className={
                            formErrors?.preferredTime ||
                            (isSubmitting && !currState?.preferredTime)
                              ? 'form-select form-control border-danger'
                              : 'form-select form-control'
                          }
                          name='preferredTime'
                          value={currState?.preferredTime}
                          onChange={handleChange}
                        >
                          <option hidden value=''>
                            Select
                          </option>
                          {props?.formOptions?.preferred_time?.map(
                            (data, id) => (
                              <option value={data?.id} key={id}>
                                {data?.value}
                              </option>
                            )
                          )}
                        </select>
                      </div>

                      <div className='col-12 mb-2'>
                        <label>Health Condition</label>
                        <div>
                          {props?.formOptions?.health_condition?.map(
                            (el, id) => (
                              <div
                                className='form-check form-check-inline'
                                key={id}
                              >
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  name='disease'
                                  value={el?.id}
                                  onChange={handleChange}
                                />
                                <label
                                  className='form-check-label'
                                  htmlFor='disease'
                                >
                                  {el.value}
                                </label>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                      <div
                        className={
                          currState?.disease?.some(
                            (el) => el === window.$enums.Disease.Other
                          )
                            ? 'col-md-12 col-lg-6 mb-4'
                            : 'col-md-12 col-lg-6 mb-4 hide'
                        }
                      >
                        <label className='asterisk'>
                          Other health condition
                        </label>
                        <input
                          type='text'
                          className={
                            formErrors?.otherDisease ||
                            (isSubmitting && !currState?.otherDisease)
                              ? 'form-control border-danger'
                              : 'form-control'
                          }
                          placeholder='Other health condition'
                          name='otherDisease'
                          value={currState?.otherDisease}
                          onChange={handleChange}
                        />
                      </div>

                      <div className='col-md-12 mb-4'>
                        <div className='form-check form-check-inline'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value={currState?.agreeToShare}
                            name='agreeToShare'
                            onChange={handleChange}
                          />
                          <label
                            className='form-check-label'
                            htmlFor='agreeToShare'
                          >
                            I agree to share my assessment result with the
                            nutritionist.
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className='modal-footer'>
                {props?.statusData?.nutritionist?.is_already_scheduled ? (
                  <button
                    type='button'
                    className={`btn btn-rp-primary btn-small ${
                      !props?.statusData?.nutritionist?.can_send_reminder ||
                      (buttonState?.value === 'reminder' &&
                        buttonState?.isLoading)
                        ? 'disabled'
                        : ''
                    }`}
                    onClick={handleSendReminder}
                  >
                    {buttonState?.value === 'reminder' &&
                    buttonState?.isLoading ? (
                      <Spinner
                        as='i'
                        animation='border'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      'Send Reminder'
                    )}
                  </button>
                ) : (
                  <button
                    type='button'
                    className={`btn btn-rp-primary btn-small ms-3 ${
                      buttonState?.value === 'submit' && buttonState?.isLoading
                        ? 'disabled'
                        : ''
                    }`}
                    onClick={handleSubmit}
                  >
                    {buttonState?.value === 'submit' &&
                    buttonState?.isLoading ? (
                      <Spinner
                        as='i'
                        animation='border'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      'Submit'
                    )}
                  </button>
                )}
                <button
                  type='button'
                  className='btn btn-rp-primary-red btn-small'
                  onClick={props.handleCloseModal}
                >
                  Cancel
                </button>
                {message?.failure && (
                  <p className='mt-4 text-danger fs-6'> {message?.failure} </p>
                )}
              </div>
            </>
          ) : (
            <div className='centered'>
              <LoadingSpinner />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Nutritionist;
