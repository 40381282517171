import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-scroll';
import EnthusiasmIcon from '../../../../assets/images/enthu.png';
import FatigueIcon from '../../../../assets/images/fatigue-ico.png';
import MotivationIcon from '../../../../assets/images/motivation-ioc.png';
import NegativityIcon from '../../../../assets/images/negitivity-ioc.png';
import CardData from './cardData.json';
import RadialBar from '../../../../common/RadialBar';
import moment from 'moment';
import '../../../../config';
import Header from '../../../../layout/Components/Header';
import Footer from '../../../../layout/Components/Footer';
import LoadingSpinner from '../../../../common/LoadingSpinner';
import { saveAssmt } from '../../../../store/assmt/actions';
import { isEmpty } from 'lodash';
import { useTour } from '@reactour/tour';

const Assessment = (props) => {
  const { setIsOpen, setSteps, setCurrentStep, currentStep, isOpen } =
    useTour();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const companyData = useSelector((state) => state.companyData);
  const loc = location.pathname.split('/');
  const [position, setPosition] = useState();
  const assmtData = useSelector((state) => state?.assmt);
  const [resultData, setResultData] = useState();

  useEffect(() => {
    if (user?.guided_tour_status !== '2') {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }

    setCurrentStep(0);
    setSteps([
      {
        selector: '#start',
        content: 'Start Tour',
      },
      {
        selector: '#first',
        content: 'Click on this to go to Assessment section',
      },
      {
        selector: '#second',
        content: 'Clicking on this will start the Assessment',
      },
      {
        selector: '#third',
        content:
          'After giving Assessment, you can see your personalized Resiliency Plan here',
      },
    ]);

    if (!resultData) {
      getAssessmentData();
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isEmpty(assmtData)) {
      setResultData(assmtData);
    } else {
      getAssessmentData();
    }
  }, [assmtData]);

  useEffect(() => {
    if (location.pathname === '/dashboard/assessments') {
      if (
        resultData?.data?.find(
          (data) => data?.status === window.$enums.AssmtStatus.Previous
        ) &&
        resultData?.data?.find(
          (data) => data?.status !== window.$enums.AssmtStatus.Current
        )
      ) {
        navigate('/dashboard/assessments/result/0');
      }
    }
  }, [loc[3]]);

  const getAssessmentData = async () => {
    try {
      const result = await window.$http.getWithHeaders('my/assessment');
      if (result.code === window.$constants.STATUS.OK) {
        dispatch(saveAssmt(result));
        setResultData(result);
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (loc[3] === 'resiliency-stages') {
      document
        ?.getElementById('assessment')
        ?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [loc]);

  const handlePrevAssmtResult = (rp_user_assmt_id) => {
    dispatch(saveAssmt(resultData));
    navigate(`/dashboard/assessments/result/${rp_user_assmt_id}`);
  };

  useEffect(() => {
    if (resultData?.data) {
      if (
        resultData?.data?.find(
          (data) => data?.status === window.$enums.AssmtStatus.Current
        )
      ) {
        if (user?.guided_tour_status !== '2') {
          setIsOpen(true);
        }

        if (
          resultData?.data?.find(
            (data) => data?.status === window.$enums.AssmtStatus.Previous
          )
        ) {
          setPosition('top');
        } else {
          setPosition('bottom');
        }
      } else if (
        resultData?.data?.find(
          (data) => data?.status === window.$enums.AssmtStatus.Previous
        ) &&
        resultData?.data?.find(
          (data) => data?.status !== window.$enums.AssmtStatus.Current
        ) &&
        loc[3] !== 'result'
      ) {
        navigate('/dashboard/assessments/result/0');
      } else if (
        resultData?.data?.find(
          (data) => data?.status === window.$enums.AssmtStatus.Next
        ) &&
        resultData?.data?.length <= 1
      ) {
        setPosition('next-assmt');
      }
    } else {
      setPosition('');
    }
  }, [resultData?.data]);

  const handleBeginAssmt = () => {
    navigate('/dashboard/assessments/');
  };

  return (
    <>
      <Header />
      {resultData ? (
        <main>
          {loc[3] === 'result' ? (
            <>{props?.children}</>
          ) : (
            <>
              <section>
                <div className='container'>
                  <div className='row'>
                    <div class='col-12'>
                      <div className='assessment-tabs-group d-flex align-items-center mb-3'>
                        {resultData && (
                          <>
                            {resultData?.data?.map((data, id) =>
                              data?.status ===
                              window.$enums.AssmtStatus.Previous ? (
                                <button
                                  type='button'
                                  className='btn-assment-month prev-mnth'
                                  key={id}
                                  onClick={() =>
                                    handlePrevAssmtResult(
                                      data?.rp_user_assmt_id
                                    )
                                  }
                                >
                                  <span>Result</span>
                                  <span>
                                    {moment(
                                      new Date(data?.display_date)
                                    ).format('MMM DD, YYYY')}
                                  </span>
                                </button>
                              ) : data?.status ===
                                window.$enums.AssmtStatus.Current ? (
                                <Link
                                  to='assessment'
                                  smooth={true}
                                  duration={100}
                                  spy={true}
                                  key={id}
                                >
                                  <button
                                    className='btn-assment-month month-active'
                                    onClick={() =>
                                      navigate('/dashboard/assessments/')
                                    }
                                  >
                                    <span>Current</span>
                                    <span>Assessment</span>
                                  </button>
                                </Link>
                              ) : data?.status ===
                                window.$enums.AssmtStatus.Next ? (
                                <button
                                  className='btn-assment-month next-mnth disabled'
                                  key={id}
                                >
                                  <span>Next Assessment</span>
                                  <span>
                                    {moment(
                                      new Date(data?.display_date)
                                    ).format('MMM DD, YYYY')}
                                  </span>
                                </button>
                              ) : (
                                ' '
                              )
                            )}
                          </>
                        )}
                      </div>

                      <div className='bg-white about p-4 p-lg-5 br-12 br-top-0'>
                        <div className='col-md-6 col-lg-8'>
                          <p className='sub-hed-p'>About</p>
                          <h1 className='section-heading'>
                            You are on your way to better resiliency.
                          </h1>
                          <h2 className='section-sub-heading'>
                            {window.$utility.getCmpTypeMsg(
                              companyData?.rp_gbl_company_type_id,
                              'dashboard'
                            )}
                          </h2>

                          {user ? (
                            <>
                              <Link
                                to='assessment'
                                smooth={true}
                                duration={100}
                                spy={true}
                                className={`${isOpen ? 'disabled' : ''}`}
                              >
                                {resultData &&
                                resultData?.data?.find(
                                  (data) =>
                                    data?.status ===
                                    window.$enums.AssmtStatus.Current
                                ) ? (
                                  <div className='mt-8'>
                                    <span
                                      id='first'
                                      className='fw-bold'
                                      onClick={handleBeginAssmt}
                                      style={{ textDecoration: 'underline' }}
                                    >
                                      Begin Assessment
                                    </span>
                                  </div>
                                ) : (
                                  ''
                                )}
                              </Link>

                              {resultData?.data?.map((data, id) =>
                                data?.status ===
                                window.$enums.AssmtStatus.Current ? (
                                  <>
                                    {data?.end_date ? (
                                      <h2
                                        className='section-sub-heading'
                                        id={id}
                                      >
                                        The assessment will end on
                                        <span className='end-date'>
                                          {' '}
                                          {data?.end_date}
                                        </span>
                                        .
                                      </h2>
                                    ) : (
                                      ''
                                    )}
                                  </>
                                ) : (
                                  ''
                                )
                              )}
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className='col-md-6 col-lg-4 radial-chart text-end mt-3 my-lg-0 mb-5 mb-lg-0'>
                          <RadialBar />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              {resultData?.data ? (
                position === 'top' ? (
                  <>{props?.children} </>
                ) : position === 'next-assmt' ? (
                  <div className='container'>
                    <h1 className='section-sub-heading rp-primary-red-color mb-4 fw-bold'>
                      Assessment will be available on{' '}
                      {
                        resultData?.data?.find(
                          (data) =>
                            data?.status === window.$enums.AssmtStatus.Next
                        )?.display_date
                      }
                      .
                    </h1>
                  </div>
                ) : (
                  ''
                )
              ) : (
                ''
              )}

              <section>
                <div className='container'>
                  <div className='row gx-0 gx-lg-4'>
                    <div className='col-md-12 mb-4'>
                      <h2 className='section-titel section-heading'>
                        Resiliency refers to your ability to deal with physical
                        and emotional stress without triggering a negative
                        physical or emotional response.
                      </h2>
                    </div>
                    <div className='col-md-6'>
                      <div className='score-card mt-4 mt-lg-0'>
                        <div className='dots-styles'></div>
                        <span className='primary-color sub-titel'>About</span>
                        <h3 className=' card-heading-dark'>
                          Your Resiliency Result
                        </h3>
                        <p>
                          Your Resiliency Result is calculated based on your
                          Enthusiasm, Fatigue, Motivation and Negativity about
                          work and life
                        </p>
                        <div className='radial-chart d-flex justify-content-center pt-4'>
                          <RadialBar />
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='resiliency-factor mt-5 mt-lg-0'>
                        <div className='dots-styles'></div>
                        <span className='sub-titel primary-color'>About</span>
                        <h3 className='card-heading-dark'>
                          Resiliency Factors
                        </h3>
                        <p>
                          These 4 distinct factors directly affect your
                          quality-of-life.
                        </p>
                        <div className='icon-list pt-4'>
                          <ul className='ps-0'>
                            <li className='mb-4'>
                              <div className='col-1 icon'>
                                <img src={EnthusiasmIcon} alt='Enthusiasm' />
                              </div>
                              <div className='li-cnt'>
                                <h6>(E) Enthusiasm</h6>
                                <p>
                                  Is the excitement and satisfaction you feel
                                  for work and life.
                                </p>
                              </div>
                            </li>
                            <li className='mb-4'>
                              <div className='col-1 icon'>
                                <img src={FatigueIcon} alt='Fatigue' />
                              </div>
                              <div className='li-cnt'>
                                <h6>(F) Fatigue</h6>
                                <p>
                                  Refers to the amount of physical and emotional
                                  energy your work and life provides or takes
                                  from you.
                                </p>
                              </div>
                            </li>
                            <li className='mb-4'>
                              <div className='col-1 icon'>
                                <img src={MotivationIcon} alt='Motivation' />
                              </div>
                              <div className='li-cnt'>
                                <h6>(M) Motivation</h6>
                                <p>
                                  Is your personal drive to do a good job, no
                                  matter what obstacles are in your way.
                                </p>
                              </div>
                            </li>
                            <li>
                              <div className='col-1 icon'>
                                <img src={NegativityIcon} alt='Negativity' />
                              </div>
                              <div className='li-cnt'>
                                <h6>(N) Negativity</h6>
                                <p>
                                  Refers to the loss of confidence in your work
                                  and life choices.
                                </p>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section>
                <div className='container'>
                  <div className='row'>
                    <div className='col-md-12 mb-0 mb-xl-5'>
                      <h2 className='section-titel section-heading'>
                        Steps To Resiliency and a higher quality-of-life
                      </h2>

                      <div className='icon-list pt-4'>
                        <ul className='p-0'>
                          <li className='mb-4'>
                            <div className='icon me-3'>
                              <i className='bi bi-command'></i>
                            </div>
                            <div className='li-cnt'>
                              <p className='about-sub-hed'>
                                Complete your confidential Resiliency assessment
                                (RP-ERA)
                              </p>
                            </div>
                          </li>
                          <li className='mb-4'>
                            <div className='icon me-3'>
                              <i className='bi bi-command'></i>
                            </div>
                            <div className='li-cnt'>
                              <p className='about-sub-hed'>
                                Review your Resiliency Result based on your
                                individual scores on Enthusiasm(E), Fatigue(F),
                                Motivation(M) and Negativity(N).
                              </p>
                            </div>
                          </li>
                          <li className='mb-4'>
                            <div className='icon me-3'>
                              <i className='bi bi-command'></i>
                            </div>
                            <div className='li-cnt'>
                              <p className='about-sub-hed'>
                                Follow your personalized Resiliency Plan that
                                includes
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    {CardData?.map((item, index) => (
                      <Card cardData={item} key={`card-${index}`} />
                    ))}
                  </div>
                </div>
              </section>
            </>
          )}

          {position === 'bottom' ? (
            !resultData?.data?.find(
              (el) => el.status === window.$enums.AssmtStatus.Previous
            ) ? (
              <>{props?.children}</>
            ) : (
              ''
            )
          ) : (
            ''
          )}
        </main>
      ) : (
        <>
          <div className='text-center'>
            <LoadingSpinner />
          </div>
          <div
            className='centered'
            style={{ marginLeft: '1rem', height: '50000px' }}
          ></div>
        </>
      )}
      <Footer addClass={resultData ? '' : 'fixed-bottom_2'} />
    </>
  );
};

export default Assessment;

const Card = ({ cardData }) => {
  return (
    <div className='col-md-4 d-none d-xl-block'>
      <div className='scene scene--card'>
        <div className='result-card'>
          <div
            className={`card__face card__face--front about-card ${cardData?.key}`}
          >
            <div className='dots-styles'></div>

            <h4 className='card-heading-sec-dark'>{cardData?.heading}</h4>
            <p>{cardData?.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
