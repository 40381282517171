import React from 'react';
import parse from 'html-react-parser';
import EnthuIcon from '../../../../../../assets/images/enthu.png';
import FatiqueIcon from '../../../../../../assets/images/fatigue-ico.png';
import MotivationIcon from '../../../../../../assets/images/motivation-ioc.png';
import NegativityIcon from '../../../../../../assets/images/negitivity-ioc.png';
import FactorScale from '../../../../../../common/FactorScale';

const SectionResult = (props) => {
  return (
    <>
      <div>
        <div className='row user-results gx-5 mt-5'>
          {
            props.burnoutResult?.sections?.length ?
              <div className='col-xs-12 col-md-12 mb-3'>
                <h6>
                  {props?.burnText} Result ({props?.burnoutResult?.participated} of{' '}
                  {props?.burnoutResult?.total})
                </h6>
              </div> : ''
          }
          <div className='col-xl-5 col-md-12 user-result'>
            <ul className='ps-0'>
              {props.burnoutResult?.sections?.length
                ? props.burnoutResult.sections?.map((item, index) => (
                  <li key={index} className='mb-4'>
                    <div className='col-1 icon'>
                      <img
                        src={
                          item.section_title === 'Enthusiasm'
                            ? EnthuIcon
                            : item.section_title === 'Motivation'
                              ? MotivationIcon
                              : item.section_title === 'Negativity'
                                ? NegativityIcon
                                : FatiqueIcon
                        }
                        alt={item?.section_title}
                      />
                    </div>
                    <div className='li-cnt'>
                      <h6 className='primary-color'>
                        ({item?.section_code}) {item?.section_title}
                      </h6>
                      <p>
                        {item?.result_summary
                          ? parse(item?.result_summary)
                          : ''}
                      </p>
                    </div>
                  </li>
                ))
                : ''}
            </ul>
          </div>
          <div className='col-xl-7 col-md-12 mt-4 mt-lg-0 z-1'>
            {props.burnoutResult?.sections?.length
              ? props.burnoutResult.sections?.map((item, index) => (
                <FactorScale key={index} burnoutResult={item} />
              ))
              : 'No Data'}
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionResult;
