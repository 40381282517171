import React from 'react';
import Modal from 'react-bootstrap/Modal';
import QRCodePlayImage from '../../assets/images/qr-code-play.png';
import QRCodeAppImage from '../../assets/images/qr-code-app.png';
import AppStoreImage from '../../assets/images/app-store.png';
import PlayStoreImage from '../../assets/images/play-store.png';

const MobileApp = (props) => {
  return (
    <Modal
      aria-labelledby='contained-modal-title-vcenter'
      centered
      size='lg'
      show={props.showModal ? true : false}
    >
      <Modal.Header className='mb-0'>
        <h5 className='modal-title'>{props.heading}</h5>
        <button
          type='button'
          className='btn-close'
          aria-label='Close'
          onClick={props.handleCloseModal}
        ></button>
      </Modal.Header>
      <Modal.Body className='p-0'>
        <div className='mobile-installation p-5 position-relative'>
          <div className=''>
            <div className='fs-5 mb-4'>
              This feature is available only on iOS and Android.
            </div>

            <div className='display-6 rp-dark-gray mb-3 fw-5 fs-5 text-decoration-underline'>
              App installation instructions
            </div>

            <div className='fs-5 mb-4'>
              Scan the QR code below to download the app to your device.
            </div>

            <div className='col-10'>
              <div className='align-items-top d-flex justify-content-between'>
                <div
                  className='scan-download'
                  style={{
                    border: '1px solid #00a89d',
                    padding: '7px',
                    width: '45%',
                  }}
                >
                  <div className='scaner-title mb-3 text-center'>
                    <span className='fs-6 fw-6'>AVAILABLE ON THE</span>
                    <span className='app-store'>
                      <img src={AppStoreImage} alt='' />
                    </span>
                  </div>
                  <div
                    className='qr-scaner'
                    style={{
                      position: 'relative',
                    }}
                  >
                    <img
                      src={QRCodeAppImage}
                      alt=''
                      style={{ width: '150px' }}
                    />
                  </div>
                </div>
                <div
                  className='scan-download'
                  style={{
                    border: '1px solid #00a89d',
                    padding: '7px',
                    width: '45%',
                  }}
                >
                  <div className='scaner-title mb-3 text-center'>
                    <span className='fs-6 fw-6'>AVAILABLE ON THE</span>
                    <span className='app-store'>
                      <img src={PlayStoreImage} alt='' />
                    </span>
                  </div>
                  <div className='qr-scaner'>
                    <img
                      src={QRCodePlayImage}
                      alt=''
                      style={{ width: '150px' }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='dots-styles-top-right'></div>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default MobileApp;
