import { SAVELANGID, REMOVELANGID } from './types';

const INITIAL_STATE = {};

const langId = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SAVELANGID: {
      return {
        ...state,
        payload,
      };
    }
    case REMOVELANGID: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default langId;
