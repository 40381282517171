import React from 'react';
import Modal from 'react-bootstrap/Modal';

const ResiliencyModal = (props) => {
  return (
    <Modal
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      className='video-modal intro-video'
      show={props?.showModal ? true : false}
    >
      <Modal.Header>
        <button
          type='button'
          className='btn-close'
          data-bs-dismiss='modal'
          aria-label='Close'
          onClick={props?.handleCloseModal}
        ></button>
      </Modal.Header>
      <Modal.Body className='p-0'>
        <iframe
          width='100%'
          height='450'
          src='https://player.vimeo.com/video/770181403?h=7cfef1268c&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=58479/embed'
          allow='autoplay; fullscreen; picture-in-picture'
          title='Resiliency'
          allowfullscreen
          frameborder='0'
        ></iframe>
      </Modal.Body>
    </Modal>
  );
};

export default ResiliencyModal;
