import Assessments from '../../../pages/Dashboard/Components/Assessment';
import CompanyAnalytics from '../../../pages/Dashboard/Components/CompanyAnalytics';
import ResiliencyPlan from '../../../pages/Dashboard/Components/ResiliencyPlan';

const DashboardRoutes = [
  {
    url: '/*',
    name: 'Dashboard',
    element: Assessments,
  },
  {
    url: '/assessments',
    name: 'Assessments',
    element: Assessments,
  },
  {
    url: '/resiliency-plan',
    name: 'Resiliency Plan',
    element: ResiliencyPlan,
  },
  {
    url: '/company-analytics',
    name: 'Company Analytics',
    element: CompanyAnalytics,
  },
];

export default DashboardRoutes;
