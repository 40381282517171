import React from 'react';
// import ReactFC from 'react-fusioncharts';
// import FusionCharts from 'fusioncharts';
// import MSColumn2D from 'fusioncharts/fusioncharts.charts';
// import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
// ReactFC.fcRoot(FusionCharts, MSColumn2D, FusionTheme);
import { Bar } from 'react-chartjs-2';
// import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
ChartJS.register(ArcElement, Tooltip, Legend);

// const MSColumn2dGraph = (props) => {
//   const chartConfigs = {
//     type: props?.type,
//     renderAt: 'chart-container3',
//     width: '100%',
//     height: props?.height,
//     dataFormat: 'json',
//     dataSource: {
//       chart: {
//         showLegend: '1',
//         bgColor: props?.bgColor ? props?.bgColor : '#ffffff',
//         showValues: '1',
//         valueFontColor: '#ffffff',
//         placeValuesInside: '1',
//         valueFontSize: '12',
//         numberSuffix: '%',
//         xAxisName: props?.xAxisName,
//         yAxisName: props?.yAxisName,
//         yAxisMaxValue: '100',
//         palettecolors: '#00A39D',
//         plotPaddingPercent: '20',
//         theme: 'fusion',
//       },
//       categories: [
//         {
//           category: props?.data?.categories,
//         },
//       ],
//       dataset: props?.data?.dataset,
//     },
//   };
//   return <ReactFC {...chartConfigs} />;
// };

const MSColumn2dGraph = (props) => {
  const data = {
    labels: props?.data?.categories?.map((category) => category.label),
    datasets: props?.data?.dataset?.map((dataset) => ({
      label: dataset.seriesname,
      data: dataset.data.map((dataPoint) => dataPoint.value),
      backgroundColor: dataset.color ? dataset.color : '#00A39D',
      borderWidth: 1,
      maxBarThickness: '50',
    })),
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Important for custom height
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          padding: 20,
          boxWidth: 15,
          boxHeight: 15,
          font: {
            size: 14,
          },
        },
      },
      // tooltip: {
      //   enabled: true,
      //   backgroundColor: '#fff',
      //   titleColor: '#42526e',
      //   bodyColor: '#42526e',
      //   borderColor: '#bbbbbb',
      //   callbacks: {
      //     label: function (context) {
      //       const label = context?.chart?.data?.labels[context.dataIndex];
      //       return `${label}${context.raw}%`;
      //     },
      //   },
      // },
      tooltip: {
        enabled: true,
        backgroundColor: '#fff',
        titleColor: '#42526e',
        bodyColor: '#42526e',
        borderColor: '#bbbbbb',
        borderWidth: 1,
        callbacks: {
          label: function (tooltipItem) {
            let datasetLabel = tooltipItem.dataset.label || '';
            let dataPoint = tooltipItem.raw;
            let percentage = dataPoint;
            return datasetLabel + ' ' + percentage + '%';
          },
        },
      },
      datalabels: {
        anchor: 'center',
        align: 'center',
        color: '#fff',
        display: function (context) {
          const value = context.dataset.data[context.dataIndex];
          return value > 10;
        },
        formatter: function (value) {
          return value > 0 ? `${value}%` : ''; // Format to show percentage
        },
        // formatter: function (value, context) {
        //   const label = context.chart.data.labels[context.dataIndex];
        //   return `${label} - ${value}%`;
        // },
      },
    },
    scales: {
      x: {
        title: {
          display: !!props?.data?.xAxisName,
          text: props?.data?.xAxisName,
          color: '#000000',
        },
        grid: {
          display: false,
        },
      },
      y: {
        title: {
          display: !!props?.data?.yAxisName,
          text: props?.data?.yAxisName,
          color: '#000000',
        },
        ticks: {
          stepSize: 20,
          callback: function (value) {
            return value + '%';
          },
          min: 0,
          max: 100,
          beginAtZero: true,
        },
      },
    },
  };

  return (
    <div style={{ width: '100%', height: '350px' }}>
      <Bar
        data={data}
        options={options}
      />
    </div>
  );
};

export default MSColumn2dGraph;
