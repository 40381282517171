import React, { useState, useEffect } from 'react';
import Data from './cardData.json';
import Card from '../../card';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import VideoPlayer from '../../../../../../common/VideoPlayer';
import Disclaimer from '../../../../../Login/Footer/Components/Disclaimer';
import LoadingSpinner from '../../../../../../common/LoadingSpinner';

const MindfulnessTraining = (props) => {
  const [result, setResult] = useState([]);
  const [data, setData] = useState('');
  const [page, setPage] = useState(1);
  const [showModal, setShowModal] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [contentCategoryId, setContentCategoryId] = useState('');
  const [firstLoad, setFirstLoad] = useState(false);

  useEffect(() => {
    if (props.showMoreVideos === false) {
      setPage(1);
      setData('');
    }
  }, [props.showMoreVideos]);

  const loadMore = () => {
    setPage(page + 1);
    props.handleCategoryVideos('clicked');
    getData(page + 1);
  };

  useEffect(() => {
    getData();
  }, [contentCategoryId]);

  useEffect(() => {
    setContentCategoryId(props?.rpContentCategoryId);
  }, [props?.rpContentCategoryId]);

  const getLikedVideos = (value) => {
    if (value) {
      getData();
    }
  };

  const getData = async (myPage = 1) => {
    setIsLoading(true);
    if (myPage === 1) {
      setFirstLoad(true);
    }
    try {
      if (props.rpContentCategoryId) {
        const data = await window.$http.getWithHeaders(
          `content?rp_content_category_id=${props.rpContentCategoryId}&page=${myPage}`
        );

        if (data.code === window.$constants.STATUS.OK) {
          setIsLoading(false);
          setFirstLoad(false);
          if (myPage > 1) {
            setResult([...result, ...data?.data]);
          } else {
            if (props.rpContentCategoryId === contentCategoryId) {
              setResult(data?.data);
            }
          }
          setData(data);
        } else {
          console.log('Something went wrong!');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseModal = () => {
    setShowModal('');
  };

  return (
    <>
      <div className={props.showList === false ? 'row list' : 'row list hide'}>
        <div className='mb-0 mb-lg-8'>
          <h2 className='section-sub-heading rp-primary-dark-g'>
            Mindfulness training, which includes meditation, games, music,
            brainwork exercises and mindful activities, will help improve your
            resiliency.
          </h2>
        </div>
        {Data?.map((item, index) => (
          <Card cardData={item} key={`card-${index}`} uniqueKey='mindfulness' />
        ))}

        <div className='mt-4 about-sub-hed '>
          Meditation can produce a deep state of relaxation and a tranquil mind.
          During meditation, you focus your attention and eliminate the stream
          of jumbled thoughts that may be crowding your mind and causing stress
          and anxiety.
        </div>

        <div className='mt-4'>
          <p className='fw-bold primary-color mb-2 text-decoration-underline fs-6'>
            Disclaimer
          </p>
          <span className='rp-primary-red-color fs-6'>
            The information provided by the instructor/presenter of the videos
            in respect of any exercises, mindfulness training, good habits,
            focus and concentration, removal of stress and anxiety etc.
          </span>
          <span
            className='primary-color text-decoration-underline cursor-p fs-6 ms-1'
            onClick={() => setShowModal('disclaimer')}
          >
            Read More
          </span>
        </div>
      </div>
      <div
        id={props.rpContentCategoryId}
        className={props.showList ? 'list' : 'list hide'}
      >
        <div className='row gx-0 gx-lg-5'>
          <div className='sries_title mb-4'>
            <h1 className='card-heading-sec-dark'>{props.categoryTitle}</h1>

            <p>{props.categoryDesc}</p>
          </div>
          {result && !firstLoad ? (
            <VideoPlayer
              resultData={
                props?.rpContentCategoryId === contentCategoryId ? result : []
              }
              getLikedVideos={getLikedVideos}
            />
          ) : (
            <div className='centered' style={{ marginLeft: '1rem' }}>
              <LoadingSpinner />
            </div>
          )}
        </div>
      </div>

      {isLoading &&
      !firstLoad &&
      props.rpContentCategoryId &&
      data?.metadata?.page_count > 1 ? (
        <div className='centered' style={{ marginLeft: '1rem' }}>
          <LoadingSpinner />
        </div>
      ) : data &&
        data?.metadata?.page_count > page &&
        props.rpContentCategoryId &&
        !isLoading ? (
        <div className='lode-more text-center'>
          <button onClick={loadMore} className='lode-button'>
            <i className='fa-solid fa-circle-chevron-down fa-xl primary-color'></i>
          </button>
        </div>
      ) : (
        ''
      )}

      {showModal === 'disclaimer' && (
        <Disclaimer showModal={showModal} handleCloseModal={handleCloseModal} />
      )}
    </>
  );
};

export default MindfulnessTraining;
