import React, { useEffect, useRef } from 'react';
// import ReactFC from 'react-fusioncharts';
// import FusionCharts from 'fusioncharts';
// ReactFC.fcRoot(FusionCharts, MSColumn2D, FusionTheme);
// import MSColumn2D from 'fusioncharts/fusioncharts.charts';
// import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables, ChartDataLabels);

// const Column2dGraph = (props) => {
//   const chartConfigs = {
//     type: props?.type,
//     width: '100%',
//     height: props?.height,
//     dataFormat: 'json',
//     dataSource: {
//       chart: {
//         labelDisplay: props?.data?.data?.length > 5 ? 'rotate' : '',
//         slantLabel: '1',
//         maxLabelHeight: '77',
//         showValues: '1',
//         valueFontColor: '#ffffff',
//         placeValuesInside: '1',
//         valueFontSize: '13',
//         bgColor: props?.bgColor ? props?.bgColor : '#ffffff',
//         numberSuffix: '%',
//         theme: 'fusion',
//         xAxisName: props?.data?.xAxisName,
//         yAxisName: props?.data?.yAxisName,
//         palettecolors: '#00A39D',
//       },
//       data: props?.data?.data,
//     },
//   };
//   return <ReactFC {...chartConfigs} />;
// };

const Column2dGraph = (props) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');

    const chartInstance = new Chart(ctx, {
      type: 'bar', // default to 'bar' if no type is provided
      data: {
        labels: props?.data?.data?.map((item) => item.label),
        datasets: [
          {
            data: props?.data?.data?.map((item) => item.value),
            backgroundColor: props?.data?.color
              ? props?.data?.color
              : '#00A39D',
            borderWidth: 1,
            barThickness: 50,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            title: {
              display: true,
              text: props?.data?.xAxisName || '',
              font: {
                size: 14,
              },
              padding: {
                top: 20,
              },
            },
            ticks: {
              font: {
                size: 14,
              },
              maxRotation: 45, // Rotate labels if they are too long
              minRotation: 45, // Force label rotation
            },
          },
          y: {
            beginAtZero: true,
            ticks: {
              stepSize: 10,
              callback: function (value) {
                return value + '%'; // Add suffix
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            backgroundColor: '#fff',
            titleColor: '#42526e',
            bodyColor: '#42526e',
            borderColor: '#bbbbbb',
            callbacks: {
              label: function (context) {
                const label = context?.chart?.data?.labels[context.dataIndex];
                return ` ${context.raw}%`;
              },
            },
          },
          datalabels: {
            anchor: 'center',
            align: 'center',
            color: '#fff',
            formatter: function (value, context) {
              // const label = context.chart.data.labels[context.dataIndex];
              return value > 0 ? `${value}%` : '';
            },
          },
        },
      },
    });

    // Cleanup function to destroy chart instance on component unmount
    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [props]);

  return <canvas ref={chartRef} style={{ width: '100%', height: props?.height || '400px' }}></canvas>;
};

export default Column2dGraph;
