import React from 'react';
// import ReactFC from 'react-fusioncharts';
// import FusionCharts from 'fusioncharts';
// import MSColumn2D from 'fusioncharts/fusioncharts.charts';
// import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
// ReactFC.fcRoot(FusionCharts, MSColumn2D, FusionTheme);
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

// const DoughnutGraph = (props) => {
//   const chartConfigs = {
//     type: props?.type,
//     renderAt: 'feedback-doughnut',
//     width: '100%',
//     height: '350',
//     dataFormat: 'json',
//     dataSource: {
//       chart: {
//         bgColor: '#ffffff',
//         pieRadius: '100',
//         doughnutRadius: '70',
//         slicingDistance: '10',
//         numbersuffix: '%',
//         showPercentValues: '0',
//         defaultCenterLabel: 'Employee Feedback',
//         centerLabelBold: '1',
//         showTooltip: '0',
//         showPercentInTooltip: '0',
//         centerLabel: '$label: $value',
//         decimals: '2',
//         useDataPlotColorForLabels: '1',
//         //Theme
//         theme: 'fusion',
//       },
//       // data
//       data: props?.data,
//     },
//   };
//   return <ReactFC {...chartConfigs} />;
// };

const centerTextPlugin = {
  id: 'centerText',
  afterDraw(chart) {
    const {
      ctx,
      // chartArea: { width, height },
    } = chart;
    ctx.save();
    ctx.font = 'bold 16px "Source Sans Pro"';
    ctx.fillStyle = '#000666';
    ctx.textAlign = 'center';
    // ctx.fillText('Employee Feedback', width / 2, height / 2);
    ctx.restore();
  },
};

const DoughnutGraph = (props) => {
  const chartData = {
    labels: props?.data?.map((item) => item.label) || [],
    datasets: [
      {
        data: props?.data?.map((item) => item.value) || [],
        backgroundColor: props?.data?.map((item) => item.color) || [],
        hoverOffset: 15,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      // tooltip: {
      //   callbacks: {
      //     label: function (tooltipItem) {
      //       return `${tooltipItem.raw || 0}%`;
      //     },
      //   },
      // },
      tooltip: {
        enabled: true,
        backgroundColor: '#fff',
        titleColor: '#42526e',
        bodyColor: '#42526e',
        borderColor: '#bbbbbb',
        borderWidth: 1,
        callbacks: {
          label: function (tooltipItem) {
            let dataPoint = Number(tooltipItem.raw);
            return `${dataPoint}%`;
          },
        },
      },

      legend: {
        position: 'bottom',
        labels: {
          padding: 25,
          boxWidth: 12,
          boxHeight: 12,
          usePointStyle: true,
          pointStyle: 'circle',
          font: {
            size: 14,
          },
        },
      },
      datalabels: {
        anchor: 'center',
        align: 'center',
        color:'#fff',
        // color: function (context) {
        //   return context.dataset.backgroundColor[context.dataIndex];
        // },
        formatter: (value, context) => {
          console.log(typeof value);
          const dataset = context.chart.data.datasets[context.datasetIndex];
          const total = dataset.data.reduce((acc, val) => acc + val, 0);
          if (total === 0) return ''; 
          // const percentage = (value / total) * 100;
          const label = context.chart.data.labels[context.dataIndex] || '';
          return value > 0 ? `${parseFloat(value).toFixed(0)}%` : '';
        },
      },
    },
    cutout: '65%',
    maintainAspectRatio: false,
    animation: {
      animateScale: true,
      animateRotate: true,
    },
  };

  return (
    <div
      style={{
        width: '100%',

        height: '350px',
        margin: '0 auto',
        padding: '20px',
      }}>
      <Doughnut
        data={chartData}
        options={options}
        plugins={[centerTextPlugin]}
      />
    </div>
  );
};

export default DoughnutGraph;
