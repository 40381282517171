import NutritionalSupplements from '../../../pages/Dashboard/Components/ResiliencyPlan/Components/NutritionalSupplements';
import DailyLinks from '../../../pages/Dashboard/Components/ResiliencyPlan/Components/DailyLinks';
import Lifestyle from '../../../pages/Dashboard/Components/ResiliencyPlan/Components/Lifestyle';
import MindfulnessTraining from '../../../pages/Dashboard/Components/ResiliencyPlan/Components/MindfulnessTraining';
import Favourite from '../../../pages/Dashboard/Components/ResiliencyPlan/Components/Favourite';
import Recommended from '../../../pages/Dashboard/Components/ResiliencyPlan/Components/Recommended';

const ResiliencyPlanRoutes = [
  {
    url: '/recommended',
    name: 'Recommended',
    element: Recommended,
  },
  {
    url: '/dailylinks',
    name: 'Daily Links',
    element: DailyLinks,
  },
  {
    url: '/dailylinks/:assmt_content_unique_hash',
    name: 'Daily Links',
    element: DailyLinks,
  },
  {
    url: '/nutritional-supplements',
    name: 'Nutritional Supplements',
    element: NutritionalSupplements,
  },
  {
    url: '/lifestyle',
    name: 'Lifestyle',
    element: Lifestyle,
  },
  {
    url: '/mindfulness-training/category-slug',
    name: 'Mindfulness Training',
    element: MindfulnessTraining,
  },
  {
    url: '/favourite',
    name: 'Favourite',
    element: Favourite,
  },
];

export default ResiliencyPlanRoutes;
