import { SAVEREVISION, REMOVEREVISION } from './types';

const INITIAL_STATE = {};

const revision = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SAVEREVISION: {
      return {
        ...state,
        payload,
      };
    }
    case REMOVEREVISION: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default revision;
