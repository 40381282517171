import React from 'react';

const Card = ({ cardData, uniqueKey }) => {
  return (
    <div className='col-md-4 d-none d-xl-block'>
      <div className={`about-card ${uniqueKey}-style-2  rp-plan-card`}>
        <div className='dots-styles'></div>
        <h6 className='card-heading-light mb-4'>{cardData.heading}</h6>
        <p>{cardData.description}</p>
      </div>
    </div>
  );
};

export default Card;
