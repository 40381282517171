import React, { useState, useEffect } from 'react';
import RecipeModal from './RecipeModal';
import LoadingSpinner from '../../../../../../../common/LoadingSpinner';
import Skeleton from 'react-loading-skeleton';

const Recipe = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [recipeResult, setRecipeResult] = useState();
  const [desc, setDesc] = useState([]);
  const [recipeModalResult, setRecipeModalResult] = useState();
  const [modalDesc, setModalDesc] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageData, setImageData] = useState([]);

  useEffect(() => {
    getRecipeData();
  }, [props.textId]);

  useEffect(() => {
    getImages(recipeResult?.image);
  }, [recipeResult?.image]);

  const getRecipeData = async () => {
    if (props.textId) {
      setIsLoading(true);
    }

    try {
      if (props.textId) {
        const data = await window.$http.getWithHeaders(
          `food-diet/recipe/${props.textId}`
        );
        if (data.code === window.$constants.STATUS.OK) {
          setIsLoading(false);
          setRecipeResult(data?.data);
          setDesc(data?.data?.description);
        } else {
          console.log('Something went wrong!');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getRecipeModalData = async () => {
    setIsModalLoading(true);
    try {
      if (props.textId) {
        const data = await window.$http.getWithHeaders(
          `food-diet/recipe/${props.textId}/view`
        );
        if (data.code === window.$constants.STATUS.OK) {
          setIsModalLoading(false);
          setRecipeModalResult(data?.data);
          setModalDesc(data?.data?.recipe);
          setIngredients(data?.data?.ingredients);
        } else {
          console.log('Something went wrong!');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getImages = async (id, key) => {
    try {
      if (id) {
        setLoading(true);
        const result = await window.$http.getWithHeaders(
          `media/images/${id}/food_diet`
        );
        if (result.code === window.$constants.STATUS.OK) {
          setImageData(result?.data);
          if (result?.data?.map((el) => el?.id)) {
            setLoading(false);
          }
        } else {
          console.log(result);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRecipeModal = () => {
    setShowModal(!showModal);
    getRecipeModalData();
  };

  const handleCloseModal = () => {
    setShowModal('');
    setRecipeModalResult();
  };

  return (
    <>
      <div
        className={
          props.showRecipe
            ? 'col-12 mb-5 recipe-section'
            : 'col-12 mb-5 recipe-section hide'
        }
      >
        <div className='recipe-titel'>
          <h5 className='mb-0'>{props.timing}</h5>
          <div className='hr-line'></div>
        </div>
        {recipeResult && !isLoading ? (
          <>
            <div className='recipe-col'>
              <div className='about-dite col-12 col-xl-8 px-3'>
                <span className='fs-5 fw-bold mb-4'>{recipeResult?.title}</span>
                <ul className='list-decimal'>
                  {desc?.map((data, id) => (
                    <li className='mb-2' key={id}>
                      {data}
                    </li>
                  ))}
                </ul>
              </div>

              <div className='col-12 col-xl-4'>
                <div className='dite-image'>
                  {loading ? (
                    <Skeleton width='100%' height='230px' />
                  ) : (
                    imageData &&
                    imageData?.map((elem) =>
                      recipeResult?.image === elem?.id ? (
                        <img
                          src={
                            !elem?.image_data ? (
                              <Skeleton width='100%' height='230px' />
                            ) : (
                              elem?.image_data
                            )
                          }
                        ></img>
                      ) : (
                        ''
                      )
                    )
                  )}
                  {recipeResult?.has_recipe ? (
                    <button
                      type='button'
                      className='btn btn-rp-primary mt-4'
                      data-bs-toggle='modal'
                      data-bs-target='#recipe1'
                      onClick={handleRecipeModal}
                    >
                      See Recipe
                    </button>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className='centered' style={{ marginLeft: '1rem' }}>
            <LoadingSpinner />
          </div>
        )}

        {showModal && (
          <RecipeModal
            showModal={showModal}
            textId={props.textId}
            recipeModalResult={recipeModalResult}
            modalDesc={modalDesc}
            ingredients={ingredients}
            isModalLoading={isModalLoading}
            handleCloseModal={handleCloseModal}
            imageData={imageData}
          />
        )}
      </div>
    </>
  );
};

export default Recipe;
