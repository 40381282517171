import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register necessary chart components
ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);
// import ReactFC from 'react-fusioncharts';
// import FusionCharts from 'fusioncharts';
// import MSColumn2D from 'fusioncharts/fusioncharts.charts';
// import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
// ReactFC.fcRoot(FusionCharts, MSColumn2D, FusionTheme);

// Chart.register(ChartDataLabels);

// const BarChart = (props) => {
//   const chartConfigs = {
//     type: 'MSColumn2D',
//     width: '100%',
//     height: '300',
//     dataFormat: 'json',
//     dataSource: {
//       chart: {
//         showLegend: '0',
//         showYAxisValues: '0',
//         showValues: '1',
//         valueFontColor: '#ffffff',
//         placeValuesInside: '1',
//         valueFontSize: '13',
//         numberSuffix: '%',
//         yAxisName:
//           'Low&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;High',
//         palettecolors: '#00A39D',
//         plotPaddingPercent: '20',
//         theme: 'fusion',
//       },
//       categories: [
//         {
//           category: props.labels,
//         },
//       ],
//       dataset: props.dataset,
//     },
//   };
//   return <ReactFC {...chartConfigs} />;
// };

const BarChart = (props) => {
  const data = {
    labels: props.labels ? props.labels.map((label) => label.label) : [],
    datasets: props.dataset
      ? props.dataset.map((dataset) => ({
          label: dataset.seriesname || '',
          data: dataset.data ? dataset.data.map((item) => item.value) : [],
          backgroundColor: '#00A39D',
          maxBarThickness: '50',
        }))
      : [],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
        display: false,
      },
      datalabels: {
        anchor: 'center',
        align: 'center',
        color: '#fff',
        formatter: function (value) {
          return `${value}%`;
        },
      },
      tooltip: {
        enabled: true,
        backgroundColor: '#fff',
        titleColor: '#42526e',
        bodyColor: '#42526e',
        borderColor: '#bbbbbb',
        borderWidth: 1,
        callbacks: {
          label: function (tooltipItem) {
            let datasetLabel = tooltipItem.dataset.label || '';
            let percentage = tooltipItem.raw;
            return `${datasetLabel}, ${percentage}%`;
          },
        },
        // formatter: function (value, context) {
        //   const label = context.chart.data.labels[context.dataIndex];
        //   return `${label} - ${value}%`;
        // },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        display: true,
        title: {
          display: true,
          // text: 'Low                                                                      High',
        },
        ticks: {
          stepSize: 20,
          callback: function (value) {
            return value + '%';
          },
          beginAtZero: true,
        },
      },
      x: {
        display: true,
      },
    },
  };

  return (
    <Bar
      data={data}
      options={options}
      height={300}
    />
  );
};

export default BarChart;
