// import FusionCharts from 'fusioncharts';
// import ReactFC from 'react-fusioncharts';
// import MSColumn2D from 'fusioncharts/fusioncharts.charts';
// import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
// ReactFC.fcRoot(FusionCharts, MSColumn2D, FusionTheme);

import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

// const StackedColumnChart = (props) => {
//   function setAxisName() {
//     let xAxisName = props?.xAxisName;
//     if (props?.separator) {
//       xAxisName = xAxisName + ' (' + props?.separator + ')';
//     }
//     return xAxisName;
//   }

//   const chartConfigs = {
//     type: props?.type,
//     renderAt: 'burnoutMale',
//     width: '100%',
//     height: props?.height,
//     dataFormat: 'JSON',
//     dataSource: {
//       chart: {
//         labelDisplay:
//           props?.xAxisName !== 'Age Group'
//             ? props?.categories?.length > 5
//               ? 'rotate'
//               : ''
//             : '',
//         slantLabel:
//           props?.xAxisName !== 'Age Group'
//             ? props?.categories?.length > 5
//               ? '1'
//               : ''
//             : '',
//         maxLabelHeight: '77',
//         numVisiblePlot: '12',
//         flatscrollbars: '0',
//         scrollheight: '12',
//         bgColor: props?.bgColor ? props?.bgColor : '#ffffff',
//         showValues: '1',
//         valueFontSize: '12',
//         valueFontColor: '#ffffff',
//         placeValuesInside: '1',
//         numberSuffix: '%',
//         xAxisName: setAxisName(),
//         yAxisName: props?.yAxisName,
//         yAxisMinValue: '0',
//         yAxisMaxValue: '100',
//         minPlotHeightForValue: '15',
//         theme: 'fusion',
//       },
//       categories: [
//         {
//           category: props?.categories,
//         },
//       ],
//       dataset: props?.data,
//     },
//   };
//   return <ReactFC {...chartConfigs} />;
// };
const StackedColumnChart = (props) => {
  const xAxisName = props?.separator
    ? `${props?.xAxisName} (${props?.separator})`
    : props?.xAxisName;

  const maxLabelsToShow = 10; 

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          padding: 20,
          boxWidth: 15,
          boxHeight: 15,
        },
      },
      title: {
        display: true,
        text: props?.chartTitle || '',
      },
      tooltip: {
        backgroundColor: '#fff',
        titleColor: '#42526e',
        bodyColor: '#42526e',
        borderColor: '#bbbbbb',
        callbacks: {
          title: (tooltipItems) => {
            const item = tooltipItems[0];
            const category = props.categories[item.dataIndex]?.label || '';
            const datasetLabel = item.dataset.label || '';
            const value = item.raw || '';
            return `${datasetLabel}, ${category}, ${value}%`;
          },
          label: () => '',
        },
       
      },
      datalabels: {
        anchor: 'center',
        align: 'center',
        color: '#fff',
        font: {
          size: 12,
        },
        formatter: (value) => (value > 0 ? `${value}%` : ''),
        display: function (context) {
          const value = context.dataset.data[context.dataIndex];
          return value > 10;
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: xAxisName,
        },
        ticks: {
          callback: function (value, index) {
            const category = props?.categories[index];
            if (category && typeof category === 'object') {
              return category.name || category.label || category.value || '';
            } else {
              return category;
            }
          },
          maxRotation: 45,
          minRotation: 45,
        },
      },
      y: {
        stacked: true,
        ticks: {
          stepSize: 20,
          callback: function (value) {
            return value + '%';
          },
          beginAtZero: true,
        },
        title: {
          display: true,
          text: props?.yAxisName,
        },
        min: 0,
        max: 100,
      },
    },
  };

  const data = {
    labels: props?.categories || [],
    datasets: props?.data?.map((dataset) => ({
      label: dataset.seriesname,
      data: dataset.data.map((d) => d.value),
      backgroundColor: dataset.color || '#42A5F5',
      borderWidth: 1,
      barThickness: 50,
    })),
  };

  
  const containerStyle = {
    width: '100%',
    overflowX: props.categories.length > maxLabelsToShow ? 'auto' : 'hidden', 
    maxWidth: props.categories.length > maxLabelsToShow ? '100%' : '100%', 
  };

  const chartStyle = {
    width: props.categories.length > maxLabelsToShow
      ? `${props.categories.length * 100}px` 
      : '100%', 
    height: '400px',
  };

  return (
    <div style={containerStyle}>
      <div style={chartStyle}>
        <Bar
          data={data}
          options={options}
        />
      </div>
    </div>
  );
};

export default StackedColumnChart;


// export default StackedColumnChart;




