import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';

// import giftImage from '../../../assets/images/gift.jpg';
// import gift2Image from '../../../assets/images/gift-2.jpg';
// import gift3Image from '../../../assets/images/gift-3.jpg';
// import gift4Image from '../../../assets/images/gift-4.jpg';

const initialState = {
  address_1: '',
  address_2: '',
  address_landmark: '',
  city: '',
  postal_code: '',
  rp_gbl_country_id: '',
  rp_gbl_state_id: '',
};
const ComplimentaryGift = (props) => {
  const [giftState, setGiftState] = useState(initialState);
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [message, setMessage] = useState({
    success: '',
    failure: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (props.showModal) {
      getCountryOptions();
      setGiftState({ ...giftState, rp_gbl_country_id: user.rp_gbl_country_id });
    }
  }, [props.showModal]);

  const getCountryOptions = async () => {
    try {
      const result = await window.$http.getWithHeaders('country');
      if (result.code === window.$constants.STATUS.OK) {
        setCountryOptions(result.data);
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getStateOptions = async () => {
      try {
        if (giftState.rp_gbl_country_id) {
          const result = await window.$http.getById(
            'states',
            giftState.rp_gbl_country_id
          );
          if (result.code === window.$constants.STATUS.OK) {
            setStateOptions(result.data);
          } else {
            console.log('Something went wrong!');
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    getStateOptions();
  }, [giftState.rp_gbl_country_id]);

  const handleAddress = async (e) => {
    if (!giftState?.address_1) {
      return setIsSubmitting(true);
    } else if (!giftState?.address_landmark) {
      return setIsSubmitting(true);
    } else if (!giftState?.city) {
      return setIsSubmitting(true);
    } else if (!giftState?.postal_code) {
      return setIsSubmitting(true);
    } else if (!giftState?.rp_gbl_country_id) {
      return setIsSubmitting(true);
    } else if (!giftState?.rp_gbl_state_id) {
      return setIsSubmitting(true);
    } else {
      try {
        const result = await window.$http.postWithHeaders(
          'my_profile/address',
          {
            address_1: giftState?.address_1,
            address_2: giftState?.address_2,
            address_landmark: giftState?.address_landmark,
            city: giftState?.city,
            postal_code: giftState?.postal_code,
            rp_gbl_country_id: giftState?.rp_gbl_country_id,
            rp_gbl_state_id: giftState?.rp_gbl_state_id,
          }
        );
        if (result.code === window.$constants.STATUS.OK) {
          setMessage({ ...message, success: result.message });
          return props.handleCloseModal();
        } else {
          setMessage({ ...message, failure: result.message });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleCloseGiftModal = () => {
    props.handleCloseModal();
    setGiftState(initialState);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGiftState({ ...giftState, [name]: value });
    let currentFormErrors = formErrors;
    switch (name) {
      case 'address_1':
        if (!value) {
          currentFormErrors[name] = 'Please enter your address.';
        } else {
          delete currentFormErrors[name];
        }
        break;
      case 'address_landmark':
        if (!value) {
          currentFormErrors[name] = 'Please enter a landmark.';
        } else {
          delete currentFormErrors[name];
        }
        break;
      case 'city':
        if (!value) {
          currentFormErrors[name] = 'Please enter your city.';
        } else {
          delete currentFormErrors[name];
        }
        break;
      case 'postal_code':
        if (!value) {
          currentFormErrors[name] = 'Please enter your postal code.';
        } else if (!global.zipCodeRegex?.test(value)) {
          currentFormErrors[name] = 'Zip/postal code is not valid.';
        } else {
          delete currentFormErrors[name];
        }
        break;
      case 'rp_gbl_country_id':
        if (!value) {
          currentFormErrors[name] = 'Please select your country.';
        } else {
          delete currentFormErrors[name];
        }
        break;
      case 'rp_gbl_state_id':
        if (!value) {
          currentFormErrors[name] = 'Please select your state.';
        } else {
          delete currentFormErrors[name];
        }
        break;
      default:
        currentFormErrors = 'All fields are required';
        break;
    }

    setFormErrors(currentFormErrors);
  };

  return (
    <Modal
      size='xl'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      show={props?.showModal}
    >
      <Modal.Header>
        <span className='rp-primary-dark-g fw-bold'>
          HELLO {user?.first_name}
        </span>
        <button
          type='button'
          className='btn-close'
          aria-label='Close'
          onClick={handleCloseGiftModal}
        ></button>
      </Modal.Header>
      <div className='row px-3 px-lg-5'>
        <div className='col-12 mb-4'>
          <div className='card-heading-sec-dark gradient-text fw-bold mb-2'>
            Welcome to the Resiliency Program
          </div>
          <p>
            We are glad to have you with us,
            <br />
            Please accept this small token of compliment from Resiliency
            program.
          </p>
        </div>

        <div className='col-12 d-flex flex-wrap'>
          <div
            className='col-12 col-xl-5 px-0 px-lg-3'
            style={{ marginTop: '20px' }}
          >
            <div className='modal-body gift-box'>
              <div className='dots-styles-left'></div>
              <div
                id='carouselExampleIndicators'
                className='carousel slide'
                data-bs-interval='false'
                data-bs-ride='carousel'
              >
                <div className='carousel-indicators'>
                  <button
                    type='button'
                    data-bs-target='#carouselExampleIndicators'
                    data-bs-slide-to='0'
                    className='active'
                    aria-current='true'
                    aria-label='Slide 1'
                  ></button>
                  <button
                    type='button'
                    data-bs-target='#carouselExampleIndicators'
                    data-bs-slide-to='1'
                    aria-label='Slide 2'
                  ></button>
                  <button
                    type='button'
                    data-bs-target='#carouselExampleIndicators'
                    data-bs-slide-to='2'
                    aria-label='Slide 3'
                  ></button>
                  <button
                    type='button'
                    data-bs-target='#carouselExampleIndicators'
                    data-bs-slide-to='3'
                    aria-label='Slide 4'
                  ></button>
                </div>
                {/* <div className='carousel-inner'>
                  <div className='carousel-item active'>
                    <img src={giftImage} className='d-block w-100' alt='...' />
                  </div>
                  <div className='carousel-item'>
                    <img src={gift2Image} className='d-block w-100' alt='...' />
                  </div>
                  <div className='carousel-item'>
                    <img src={gift3Image} className='d-block w-100' alt='...' />
                  </div>
                  <div className='carousel-item'>
                    <img src={gift4Image} className='d-block w-100' alt='...' />
                  </div>
                </div> */}

                <button
                  className='carousel-control-prev'
                  type='button'
                  data-bs-target='#carouselExampleIndicators'
                  data-bs-slide='prev'
                >
                  <span
                    className='carousel-control-prev-icon'
                    aria-hidden='true'
                  ></span>
                </button>
                <button
                  className='carousel-control-next'
                  type='button'
                  data-bs-target='#carouselExampleIndicators'
                  data-bs-slide='next'
                >
                  <span
                    className='carousel-control-next-icon'
                    aria-hidden='true'
                  ></span>
                </button>
              </div>
            </div>
          </div>

          <div className='col-12 col-xl-7 px-0 px-lg-3'>
            <Modal.Body>
              <div className='row'>
                <div className='col-md-12 col-lg-6 mb-4'>
                  <label className='asterisk'>Address line 1</label>
                  <input
                    type='text'
                    name='address_1'
                    className={
                      formErrors?.address_1 ||
                      (isSubmitting && !giftState?.address_1)
                        ? 'form-control border-danger'
                        : 'form-control'
                    }
                    required
                    placeholder='Address line 1'
                    aria-label='Address line 1'
                    value={giftState?.address_1 || ''}
                    onChange={handleChange}
                  />
                </div>
                <div className='col-md-12 col-lg-6 mb-4'>
                  <label>Address line 2</label>
                  <input
                    type='text'
                    name='address_2'
                    className='form-control'
                    placeholder='Address line 2'
                    aria-label='Address line 2'
                    value={giftState?.address_2 || ''}
                    onChange={handleChange}
                  />
                </div>
                <div className='col-md-12 col-lg-6 mb-4'>
                  <label className='asterisk'>Landmark</label>
                  <input
                    type='text'
                    name='address_landmark'
                    required
                    className={
                      formErrors?.address_landmark ||
                      (isSubmitting && !giftState?.address_landmark)
                        ? 'form-control border-danger'
                        : 'form-control'
                    }
                    placeholder='Landmark'
                    aria-label='Landmark'
                    value={giftState?.address_landmark || ''}
                    onChange={handleChange}
                  />
                </div>
                <div className='col-md-12 col-lg-6 mb-4'>
                  <label className='asterisk'>City</label>
                  <div className='d-flex align-items-center'>
                    <input
                      type='text'
                      name='city'
                      required
                      className={
                        formErrors?.city || (isSubmitting && !giftState?.city)
                          ? 'form-control border-danger'
                          : 'form-control'
                      }
                      placeholder='City'
                      aria-label='city'
                      value={giftState?.city || ''}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className='col-md-12 col-lg-6 mb-4'>
                  <label className='asterisk'>Zip/Postal Code</label>
                  <div className='d-flex align-items-center'>
                    <input
                      type='text'
                      name='postal_code'
                      min='1'
                      className={
                        formErrors?.postal_code ||
                        (isSubmitting && !giftState?.postal_code)
                          ? 'form-control border-danger'
                          : 'form-control'
                      }
                      placeholder='Zip/Postal Code'
                      aria-label='Zip/Postal Code'
                      value={giftState?.postal_code || ''}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className='col-md-12 col-lg-6 mb-4'>
                  <label className='asterisk'>Country</label>
                  <select
                    name='rp_gbl_country_id'
                    className={
                      formErrors?.rp_gbl_country_id ||
                      (isSubmitting && !giftState?.rp_gbl_country_id)
                        ? 'form-select form-control border-danger'
                        : 'form-select form-control'
                    }
                    aria-label='Default select example'
                    value={
                      giftState?.rp_gbl_country_id !== undefined
                        ? giftState?.rp_gbl_country_id
                        : '' || ''
                    }
                    onChange={handleChange}
                  >
                    <option value=''>Country</option>
                    {countryOptions !== undefined
                      ? countryOptions?.map((data, id) => (
                          <option value={data?.rp_gbl_country_id} key={id}>
                            {data?.country_name}
                          </option>
                        ))
                      : ''}
                  </select>
                </div>
                <div className='col-md-12 col-lg-6 mb-4'>
                  <label className='asterisk'>State</label>
                  <select
                    name='rp_gbl_state_id'
                    className={
                      formErrors?.rp_gbl_state_id ||
                      (isSubmitting && !giftState?.rp_gbl_state_id)
                        ? 'form-select form-control border-danger'
                        : 'form-select form-control'
                    }
                    required
                    aria-label='Default select example'
                    value={giftState?.rp_gbl_state_id || ''}
                    onChange={handleChange}
                  >
                    <option value=''>State</option>
                    {stateOptions !== undefined
                      ? stateOptions?.map((data, id) => (
                          <option value={data?.rp_gbl_state_id} key={id}>
                            {data?.state_name}
                          </option>
                        ))
                      : ''}
                  </select>
                </div>
              </div>
            </Modal.Body>
            <div className='modal-footer'>
              {message?.failure ? (
                <p className='mt-4 text-danger fs-6'> {message?.failure} </p>
              ) : (
                message?.success && (
                  <p className='primary-color mt-4 fs-6'>{message?.success}</p>
                )
              )}
              <button
                type='button'
                className='btn btn-rp-primary-red btn-small'
                onClick={handleCloseGiftModal}
              >
                Cancel
              </button>
              <button
                type='button'
                className='btn btn-rp-primary btn-small'
                onClick={handleAddress}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ComplimentaryGift;
