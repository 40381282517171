global.FUSION_KEY = process.env.REACT_APP_FUSIONCHART_KEY;
global.managerData = [
  {
    id: 1,
    value: 'Yes',
  },
  {
    id: 2,
    value: 'No',
  },
];

global.activeData = [
  {
    id: 1,
    value: 'Yes',
  },
  {
    id: 2,
    value: 'No',
  },
];

global.messageData = {
  login: {
    1: 'Your employer is dedicated to maximizing your resiliency and quality-of-life.',
    2: 'Resiliency Program is dedicated to maximizing your resiliency and quality-of-life.',
    3: 'Your referrer is dedicated to maximizing your resiliency and quality-of-life.',
  },
  dashboard: {
    1: 'Your employer is dedicated to maximizing your resiliency and quality-of-life.',
    2: 'Resiliency Program is dedicated to maximizing your resiliency and quality-of-life.',
    3: 'Your referrer is dedicated to maximizing your resiliency and quality-of-life.',
  },
};

global.companyTypeId = 2;

global.months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

global.emailRegex = /\S+@\S+\.\S+/;
global.nameRegex = /^[A-Za-z][A-Za-z -]{1,20}$/;
global.zipCodeRegex = new RegExp('^[a-zA-Z0-9 ]*$');
global.numberRegex = /^\d+$/;
global.otpRegex = /^\d{6}$/;
global.inputRegex = /^\s*$/;
global.mobileRegex = /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/;
