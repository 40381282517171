import React from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

const PrivacyPolicy = (props) => {
  return (
    <Modal
      size='xl'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      scrollable={true}
      show={props.showModal ? true : false}
    >
      <Modal.Header>
        <h5 className='modal-title'>Resiliency Program Privacy Policy</h5>
        <button
          type='button'
          className='btn-close'
          data-bs-dismiss='modal'
          aria-label='Close'
          onClick={props.handleCloseModal}
        ></button>
      </Modal.Header>
      <Modal.Body>
        <div className='col-12'>
          <p>
            <em>Summary Statement</em>
            <br />
            <em>
              When you use our services, you trust us with your information.
              Your privacy is very important to us. This Privacy Policy explains
              what categories of personal data we collect, the purpose of
              collection, and how we share it. We encourage you to read the
              summaries below for your knowledge. Of course, you can review our
              full Privacy Policy below as well.
            </em>
          </p>
          <p>
            This Privacy Policy also describes the choices you have, the rights
            you have, and how the law protects you including how we comply with
            the General Data Protection Regulation (“GDPR”). It also includes
            how we comply with the laws related to the use of personal data.
            This Privacy Policy applies to all Personal Information and other
            data collected by Resiliency Program from or about the users of the
            Resiliency Program (the “Registered Users”) of and/or visitors to
            the Resiliency Program website (the “Website”), and mobile
            applications we may offer on behalf of ourselves or others.
          </p>

          <table className='table table-bordered'>
            <thead>
              <tr style={{ verticalAlign: 'middle' }}>
                <th className='col-3' scope='col'>
                  Category of Personal Information collected
                </th>
                <th className='col-3' scope='col'>
                  Source{' '}
                </th>
                <th className='col-3' scope='col'>
                  Purpose for collection and legal processing basis
                </th>
                <th className='col-3' scope='col'>
                  Categories of Recipients
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Contact and personal Information: such as first name and last
                  name, email address, Date of Birth, Gender, Country,
                  Experience, status or employment i.e., part time and/or full
                  time, employee ID, job title, gender, mobile number etc.{' '}
                </td>
                <td>
                  From you when you register for or enroll in any services
                  and/or the Program or from your Subscriber as applicable
                </td>
                <td>
                  To provide, manage, support, improve and personalize the
                  services and any related transactions, and to communicate with
                  and respond to you about the services including to validate
                  your identity or to meet legal obligations; the legal
                  processing basis for processing your personal data is
                  contractual legal or legitimate interest grounds.{' '}
                </td>
                <td>
                  We don’t share your Personal Information with anyone except,
                  we may share it with our successors (as the case may be),
                  professional advisors such as nutritionists and/or medical
                  advisors (who perform certain services and functions on our
                  behalf and who are bound by confidentiality obligations) as
                  the case may be, if required upon your consent
                </td>
              </tr>
              <tr>
                <td>Personalized assessment information</td>
                <td>
                  From you when you engage with the services and/or the Program
                  or answer questions mentioned in the assessment form related
                  to your preferences; or from as applicable, a Subscriber.
                </td>
                <td>
                  To provide, manage, improve, support, and personalize the
                  services and to provide your Subscriber with the required
                  information; the legal processing basis for processing your
                  personal data is consent, contractual or legitimate interest
                  grounds
                </td>
                <td>
                  We don’t share your Personal Information with anyone except,
                  we may share it with our successors (as the case may be),
                  professional advisors such as nutritionists and/or medical
                  advisors (who perform certain services and functions on our
                  behalf and who are bound by confidentiality obligations) as
                  the case may be, if required upon your consent.{' '}
                </td>
              </tr>
              <tr>
                <td>
                  Browsing information: such as your IP address or other device
                  identifier, the kind of browser or device you use, pages and
                  content that you visit on the Website, what you click on, the
                  state and country from which you access the Program, date and
                  time of your visit, and web pages you linked to the services
                  from
                </td>
                <td>
                  Your interactions the Website and/or Program, including
                  through the use of cookies or other tracking technologies
                  explained further below Where legally required in certain
                  jurisdictions (including Europe), we seek and only process
                  this data with your opt in consent. You can withdraw consent
                  or change your browser settings to reconfigure your settings
                </td>
                <td>
                  To evaluate usage of the services and improve functionality
                  and performance; to protect the security and integrity of the
                  services, Program and our business, such as preventing fraud,
                  hacking, and other criminal activity or to meet legal
                  obligations; The legal processing basis for processing your
                  personal data is consent, legal or legitimate interest grounds
                </td>
                <td>
                  Our service providers who help us with fraud protection and
                  website analytics
                </td>
              </tr>
            </tbody>
          </table>

          <span>
            **This table is for reference only and details of the Privacy Policy
            is explained herein below.**
          </span>

          <div className='mt-4'>
            <ol className='modified-ol upper-alpha'>
              <li className='fst-italic'>
                <span className='text-decoration-underline'>
                  {' '}
                  How we protect personal data:
                </span>
                We take reasonable and appropriate measures designed to
                safeguard personal data where we have control over it, but there
                is always some risk involved in transmitting data over the
                Internet or storing data electronically.
              </li>
              <li className='fst-italic'>
                <span className='text-decoration-underline'>
                  {' '}
                  How long we retain personal data:
                </span>
                We will retain your personal data till 90 days of
                expiration/termination of the annual subscription with your
                Subscriber unless such retention is restricted by applicable
                laws, or you request that it is deleted sooner or upon your
                request that it is deleted sooner.
              </li>
              <li className='fst-italic'>
                <span className='text-decoration-underline'>
                  Your choices regarding personal data:
                </span>
                You can access, update, correct, object to or delete your
                personal data, withdraw your consent, cancel your account and
                obtain a duplicate of your personal data through the
                functionality of our service. You can choose whether or not to
                receive promotional communications by opting out if you have
                subscribed or where we send you communications, we consider are
                relevant to you, but please note that we may still contact you
                with important information about your account. In addition, you
                may request that we restrict the processing of your personal
                data or request that a copy of certain of your data is provided
                to you or a third-party provider where applicable in certain
                jurisdictions such as Europe. All of these rights are subject to
                legal exemptions and limitations. We will respond within 15
                working days of your request unless we have grounds, as
                permitted by applicable laws, to extend the period and respond
                within three months (or such required maximum period under
                applicable laws) instead. We may need to ask you to provide
                identity information before we can process any of these
                requests.
              </li>
              <li>
                <strong className='text-decoration-underline'>
                  Background.
                </strong>
                The privacy and the security of your Personal Information
                (defined below) are very important to us at Resiliency Program
                (hereinafter referred to as{' '}
                <strong>"Resiliency Program"</strong>, “RP”, “we”, “us”, or
                “our”). This Privacy Policy explains what Personal Information
                and other data we collect through or in connection with the
                Website (defined below), how we use and share that information,
                and the choices you have concerning our policies regarding the
                collection, use, and sharing of such information. This Privacy
                Policy applies to all Personal Information and other data
                collected by RP from or about Registered Users of and/or
                visitors to the RP website (the
                <strong>“Website”</strong>), and Registered Users of the
                associated products, services, website and mobile applications
                we may offer on behalf of ourselves or others. Other capitalized
                terms used and not otherwise defined in this Privacy Policy have
                the meanings assigned to them in RP’s Terms and Conditions
                located at{' '}
                <span
                  className='primary-color text-decoration-underline cursor-p'
                  onClick={() => props.handleShowModal('terms')}
                >
                  here
                </span>
                . . Please read this Privacy Policy carefully and contact us if
                you have any questions or concerns. By accessing or using the
                Website or the services, you are indicating that you have read,
                understood, and agree to be bound by the terms of this Privacy
                Policy. IF YOU DO NOT AGREE WITH THE PRIVACY POLICY, YOU DO NOT
                HAVE PERMISSION TO ACCESS OR USE THE WEBSITE OR THE SERVICES. If
                you have any questions or concerns or queries about this Privacy
                Policy including any requests to exercise your data privacy
                rights, you may contact us at-
                <Link
                  to='#'
                  onClick={() =>
                    (window.location = 'mailto:support@resiliencyprogram.com')
                  }
                >
                  support@resiliencyprogram.com
                </Link>
                .
              </li>

              <li>
                <strong className='text-decoration-underline'>
                  Changes to the Privacy Policy.
                </strong>
                We may change this Privacy Policy from time to time, including
                as reasonably required to keep up with current with rules and
                regulations, technology and security standards, and our
                offerings and protocols. We will post any changes to this
                Privacy Policy on this page and update the Privacy Policy
                modification date. If the changes are significant, we will, in
                our sole discretion, post a more prominent notice on the
                homepage of the Website or notify you by email at the address we
                have on file for your account. You agree that your continued
                access or use of the Website or any services after posting of
                the changes to the Privacy Policy or your receipt of notice of a
                change to the Privacy Policy will constitute your affirmative
                acceptance of any such change and your agreement to such updated
                terms. The significant changes to this Policy include but are
                not limited to:
                <ol className='mt-3 list-group-numbered list-style-none'>
                  <li>
                    Major changes in our service mode, e.g., the purpose of
                    processing personal information, the type of processed
                    personal information, and how personal information is used;
                  </li>
                  <li>
                    Major changes in our ownership structure, organizational
                    structure, e.g., the change of control due to business
                    adjustments, bankruptcy, merger and acquisition;
                  </li>
                  <li>
                    Changes of the main recipients to which personal information
                    is disclosed, shared with, or transferred;
                  </li>
                  <li>
                    Changes in your rights involved in personal information
                    processing and in how you exercise such rights;
                  </li>
                  <li>
                    Changes in our responsible department, contacts, and
                    complaint channels for personal information protection;
                  </li>
                  <li>
                    When a personal information impact assessment report
                    indicates high risks.
                  </li>
                </ol>
              </li>

              <li>
                <strong className='text-decoration-underline'>
                  Personal Information.
                </strong>
                In this Privacy Policy means information that can be used to
                identify an individual. The information that you provide or make
                available through or in connection with the Website or the
                Program, including information provided by the Subscriber and
                when you register, which requires you to create an account and
                provide contact information such as your name, email address,
                employee ID, job title, gender, date of birth, country,
                experience, nature of employment, status of employment (part
                time or full time employee), time period of working with the
                employer and telephone number, provided to or collected by us
                through or in connection with the Website or the Program and all
                the information provided while filling up the questionnaire.
                “Non-personal Information” is information that is de-identified,
                aggregated, or cannot reasonably be associated with an
                identifiable individual.
              </li>
              <li>
                <strong className='text-decoration-underline'>
                  What We Do.
                </strong>
                Resiliency Program product/service is an integrated set of
                online tools that allow individuals to assess their or their
                Subscriber’s/organization’s level of resilience, create an
                action plan to increase resilience, and monitor their progress
                in increasing their resilience over time. Resiliency Program is
                offered directly to end-users/employees as well as being offered
                as an adjunct to corporate benefits (each, a “Program”) through
                employers, sponsors, or their respective designees (hereinafter
                referred to as “Subscriber”) who enter into a contract with RP
                for subscription of the Program. Resiliency Program collects and
                processes data about the individuals. We are a web-based SaaS
                solution to measure and reduce workplace burnout to drive
                powerful growth results and a culture of well-being and adaptive
                thinking.
              </li>
              <li>
                <strong className='text-decoration-underline'>
                  What information does Resiliency Program collect?
                </strong>
                <ol className='list-group-numbered list-style-none'>
                  <li>
                    <strong className='text-decoration-underline'>
                      {' '}
                      Information Collected and Used for Essential Business
                      Functions
                    </strong>
                    In order to provide you with our services, we must collect
                    and use certain Personal Information for our business. Below
                    we list the necessary Personal Information that we collect
                    and use about you to fulfill such essential and additional
                    business functions:
                    <ol className='modified-ol lower-alpha'>
                      <li>
                        <strong className='text-decoration-underline'>
                          {' '}
                          Information you/your Subscriber provide.
                        </strong>
                        We collect Personal Information that you/your Subscriber
                        may provide or make available through or in connection
                        with the Website or the Program. Your Subscriber shall
                        provide information like your name and email address.
                        Basis this information we send you a welcome email
                        pursuant to which you create a username and password and
                        further provide Personal Information like your mobile
                        number, date of birth, gender, country, experience, part
                        time or full-time employee, time period of working with
                        the present company before filling up the questionnaire.
                        If you are accessing the Website or the Program, your
                        Subscriber may provide some information on your behalf
                        that can be later edited only by your Subscriber. You
                        can only edit the information that you provide, not the
                        information provided by the Subscriber. In case you have
                        any problems with the correctness of the information
                        provided by your Subscriber please contact your
                        Subscriber for the same.
                        <br />
                        <br />
                        If you do not provide us with the Personal Information
                        that is required to register for and use the services,
                        you may not access the services, and we will have
                        difficulty contacting you to send you updates or respond
                        to your inquiries.
                      </li>
                      <li>
                        <strong className='text-decoration-underline'>
                          Device Information.
                        </strong>
                        We collect device-specific information from Registered
                        Users in order to provide them services (such as a
                        Registered User’s hardware model, operating system and
                        web browser versions, unique device identifiers and
                        mobile network information, including phone number). We
                        may associate your device-specific information with your
                        Personal Information on a periodic basis in order to
                        confirm you as a Registered User and to check the
                        security on your device. Where required under applicable
                        laws, we seek your consent before enabling cookies that
                        collect this information. This clause (b) is applicable
                        only for EU users.
                        <br />
                        <br />
                        If you do not provide us with the necessary Personal
                        Information above that is required to register for and
                        use our services, you may not be able to access our
                        services including the assessment questionnaire and we
                        will have difficulty contacting you to send you updates
                        or respond to your inquiries.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <strong className='text-decoration-underline'>
                      Information from cookies.
                    </strong>
                    Like many other websites, the Website gathers and stores
                    certain information automatically or, where required under
                    applicable laws, with your consent. Unless you are in a
                    jurisdiction requiring active consent to the use of certain
                    cookies, the cookies will be placed automatically and when
                    you visit the Website or click on a banner advertisement, we
                    may send one or more “cookies” to your computer or other
                    device to provide us with information relating to the
                    sources of our site traffic. Cookies are small pieces of
                    information that some websites store on your computer’s hard
                    drive when you visit them. Most web browsers automatically
                    accept cookies but allow you to modify security settings so
                    that you can approve or reject cookies on a case-by-case
                    basis. Your rejection or no active consent to the use of
                    non-essential cookies from the Website, will not impact your
                    use of the Resiliency Program software.
                  </li>
                  <li>
                    <strong className='text-decoration-underline'>
                      Log and other information.
                    </strong>
                    When you access the Website or the Program, or other content
                    served by or on behalf of us on other websites or mobile
                    applications, our servers may automatically or, where
                    required under applicable laws, with your consent, record
                    information that your browser sends whenever you visit a
                    website. These server logs typically include your web
                    request, Internet Protocol address, browser type, browser
                    language, the date and time of your request and one or more
                    cookies that may uniquely identify your browser or
                    account(s) to us. During some visits to our websites, we may
                    use software tools such as JavaScript to measure and collect
                    session information, including page response times, download
                    errors, length of visits to certain pages, page interaction
                    information (such as scrolling, clicks, and mouse-overs),
                    and methods used to browse away from the page.
                  </li>
                  <li>
                    <strong className='text-decoration-underline'>
                      {' '}
                      Surveys and Contests
                    </strong>
                    From time to time we may provide you the opportunity to
                    participate in contests or surveys. If you participate in a
                    survey or contest, we may request certain relevant Personal
                    Information. Participation in these surveys or contests is
                    completely voluntary and you have a choice whether or not to
                    disclose this information. The requested information
                    typically includes contact information, such as email
                    address or phone number.
                  </li>
                </ol>
              </li>

              <li>
                <strong className='text-decoration-underline'>
                  Sensitive Personal Information Collected and Used.
                </strong>
                We may collect or receive the following types of sensitive
                personal information about you, including but not limited to,
                your name, e-mail address, or other information you may enter
                for the purposes of providing you the services, including
                validating your eligibility to use the service and the Program.
                Therefore, you need to carefully consider before using the
                services we provide you. You agree that this sensitive personal
                information will be processed in accordance with the purposes
                and methods stated in this Privacy Policy.
              </li>
              <li>
                <strong className='text-decoration-underline'>
                  How does Resiliency Program use your information?
                </strong>
                We may use your Personal Information on a legal basis or basis
                which we believe applies to us. We may process your personal
                data on more than one lawful basis depending on the purpose for
                which we are using your data.
                <br />
                <br />
                We may use your Personal Information for the following purposes:
                <ol className='list-group-numbered list-style-none'>
                  <li>
                    <strong className='text-decoration-underline'>
                      {' '}
                      To manage your account and provide services.
                    </strong>
                    We use the information we collect to manage your account(s),
                    process your transaction(s), and provide the services and
                    customer service support, as well as to support our
                    aggregate reporting as part of the services. We may use
                    Personal Information to verify or validate the identity of a
                    Registered User.
                  </li>

                  <li>
                    <strong className='text-decoration-underline'>
                      To contact you.
                    </strong>
                    We may, subject to applicable law, use the contact
                    information we receive from you or third parties to contact
                    you directly or through third parties via email, direct
                    mail, telephone or other channels such as text messaging,
                    and third party integrated services, in each case provided
                    we are permitted by law to send such communications to you,
                    and you have not otherwise opted-out of receiving them. For
                    example, we may communicate with you in connection with your
                    participation in surveys or contests, as discussed above, to
                    educate you regarding use of or updates to the services, to
                    promote other products or services that may be of interest
                    to you as the case may be, or for other marketing or quality
                    control purposes. We may also use your contact information
                    to provide you administrative communications from time to
                    time. Where required under applicable laws, we will seek
                    your consent before sending any such communications to you.
                    <br />
                    <br />
                    Email communications that you send to us via the email links
                    on the Website may be shared with a customer service
                    representative, employee, expert or agent that is most able
                    to address your inquiry. We make reasonable efforts to
                    respond in a timely fashion once communications are
                    received. Internet email is inherently insecure, as any
                    given email may be sent via multiple third-party computers,
                    some of which can make a copy of the contents with no
                    detection possible. Because of this lack of security, it is
                    possible that your email communication may be accessed or
                    viewed by another Internet Registered User while in transit
                    to Resiliency Program. You may decide at some point that you
                    no longer wish to receive communications, such as
                    newsletters and updates, from the Website.
                  </li>
                  <li>
                    <strong className='text-decoration-underline'>
                      To develop, evaluate, improve and deliver the Website and
                      services related to the Program.
                    </strong>
                    We use the information we collect to develop, evaluate,
                    monitor, document, improve and deliver the Website and
                    services related to the Program. For example, if we learn
                    from the information, we collect that certain common event
                    are producing stress in the lives of Registered Users
                    (defined below), we may use that information to develop
                    personalized Resiliency Plan for the Registered User that
                    includes Supplement Suggestions, skill exercises/nutritional
                    diet plans designed to enable Registered Users to respond
                    productively to such events. A{' '}
                    <strong>“Registered User”</strong> is a person who completes
                    the enrollment process for the Website. We may work with the
                    Subscribers to tailor and promote Programs and relevant
                    services.
                  </li>
                  <li>
                    <strong className='text-decoration-underline'>
                      {' '}
                      To protect the rights or property of Resiliency Program,
                      Registered Users, and others.
                    </strong>
                    We also may use the information we collect to the extent we
                    believe it is necessary or appropriate to enforce or apply
                    our rights under our agreements with Registered Users,
                    and/or to protect the rights, property or safety of
                    Resiliency Program, our Registered Users and others.
                  </li>
                  <li>
                    To deliver our products and services to you, in a custom and
                    Registered User-friendly way and products and services that
                    you request from us or that may be of interest to you.
                  </li>
                  <li>
                    To protect the security and integrity of the services being
                    provided and our business, such as preventing fraud,
                    hacking, and other criminal activity, or to meet legal
                    obligations.
                  </li>
                  <li>To gather your feedback on our products and services.</li>
                  <li>
                    To carry out our obligations and enforce our rights arising
                    from any contracts entered into between you and us.
                  </li>
                  <li>
                    We also may use the information we collect to the extent we
                    believe it is necessary or appropriate to enforce or apply
                    our rights under our agreements with Registered Users,
                    and/or to protect the rights, property, or safety of
                    Resiliency Program, our Registered Users and others.
                  </li>
                  <li>
                    To respond to court orders, law enforcement requests, etc,
                    and to carry out our legal and contractual obligations.
                  </li>
                </ol>
              </li>
              <li>
                <strong className='text-decoration-underline'>
                  How does Resiliency Program share your information?
                </strong>

                <ol className='list-group-numbered list-style-none'>
                  <li>
                    <strong className='text-decoration-underline'>
                      {' '}
                      Non-affiliated Third Parties.
                    </strong>
                    You expressly consent to us instructing third parties to
                    process your Personal Information to the extent required or
                    permitted by applicable law or you are otherwise informed
                    that we may instruct third parties to process your Personal
                    Information as follows:
                  </li>
                  <ol className='modified-ol lower-alpha'>
                    <li>
                      <strong className='text-decoration-underline'>
                        {' '}
                        In business transfers.
                      </strong>
                      If there is a change of control in RP’s business (whether
                      by merger, sale, or otherwise), RP may assign or transfer
                      this Privacy Policy and your account and related Personal
                      Information to any person or entity that acquires or is
                      merged with RP. As part of any such assignment or
                      transfer, the transferee will be subject to applicable
                      laws regarding the collection, use, protection, and
                      disclosure of Personal Information. We will notify you by
                      email at the last address shown on our records in the
                      event of any such assignment or transfer.
                    </li>
                    <li>
                      <strong className='text-decoration-underline'>
                        To fulfill legal and regulatory requirements.
                      </strong>
                      We may provide your Personal Information to third parties
                      as we reasonably deem necessary to comply with an
                      applicable statute, regulation, rule or law, an order, a
                      search warrant, a court or regulatory order, or other
                      lawful requests by public authorities, including to meet
                      national security or law enforcement requirements, or in
                      the course of managing a dispute, governmental inquiry or
                      other litigation process. We may also provide your
                      Personal Information, in accordance with or as authorized
                      by law, to our accountants, auditors, agents, service
                      providers, lawyers, and other advisors when we have reason
                      to believe that providing this information is necessary in
                      order to investigate, prevent or take action regarding
                      illegal activities, suspected fraud, situations involving
                      potential threats to the physical safety of any person, or
                      the safety and security of our Registered Users, the
                      Website, the Program or the general public, or violations
                      of the Terms and Conditions.
                    </li>
                    <li>
                      <strong className='text-decoration-underline'>
                        With your consent.
                      </strong>
                      In addition to the above, we may share Personal
                      Information about you with others to the extent you
                      consent to such sharing. In some jurisdictions,
                      individuals may have the right to withdraw consent from
                      certain uses.
                    </li>
                  </ol>
                  <li>
                    <strong className='text-decoration-underline'>
                      Agents and Service Providers.
                    </strong>
                    We engage third party intermediaries and partners to perform
                    certain services and functions on our behalf and under our
                    instructions. For example, we may engage third parties to
                    help us develop the products and services offered on the
                    Website, send email and postal mail, analyze data, provide
                    marketing services, process applications, provide search
                    results and links, host the Website, and provide customer
                    service. You expressly consent to us sharing or, if
                    permitted under applicable laws, you acknowledge that we may
                    share your Personal Information with our agents and service
                    providers, to the extent relevant to the services they
                    perform on our behalf and subject to contractual agreements
                    with respect to the confidentiality of such information. For
                    Personal Information transferred to us from European Union,
                    we were previously certified under the EU-US Privacy Shield,
                    following the invalidation of this scheme, we now rely upon
                    Standard Contractual Clauses (from decision 2010/87/EU) in
                    order to meet our obligations regarding transfers of
                    Personal Information being subject
                  </li>
                  <li>
                    <strong className='text-decoration-underline'>
                      {' '}
                      Non-personal Information.
                    </strong>
                    We may provide Non-personal Information to Subscribers in
                    relation to a Program. For example, your information may be
                    aggregated with information of other Registered Users
                    (employees of the Subscriber) and analyzed to evaluate the
                    effectiveness and impact of the Website and the Program on
                    your Subscriber (this increase of productivity to be
                    calculated and provided to the Subscriber after second
                    assessment).
                  </li>
                  <li>
                    <strong className='text-decoration-underline'>
                      {' '}
                      For our business
                    </strong>
                    We may share your Personal Information with our analytics
                    technology services and support, and data security advisors.
                    We may also share your Personal Information with our
                    professional advisors such as nutritionists and/or medical
                    advisors (who perform certain services and functions on our
                    behalf and who are bound by confidentiality obligations) as
                    the case may be, if required upon your consent.
                  </li>
                  <li>
                    <strong className='text-decoration-underline'>
                      {' '}
                      To enforce our rights.
                    </strong>
                    We may share your Personal Information to enforce any
                    applicable Terms and Conditions and Terms of Use, and to
                    ensure the safety and security of our Website and our
                    Registered Users. We require all third parties to respect
                    the security of your personal data and to treat it in
                    accordance with the law. We do not allow our third-party
                    service providers to use your Personal Information for their
                    own purposes and only permit them to process your personal
                    data for specific purposes and in accordance with our
                    instructions.
                  </li>
                </ol>
              </li>

              <li>
                <strong className='text-decoration-underline'>
                  {' '}
                  How does Resiliency Program protect your information?
                </strong>
                We have put in place procedures to deal with any suspected
                personal data breach, and any will notify you and any applicable
                regulator of a breach where we are legally required to do so.
                <ol className='list-group-numbered list-style-none'>
                  <li>
                    <strong className='text-decoration-underline'>
                      {' '}
                      Security of your Personal Information.
                    </strong>
                    RP has implemented reasonable and appropriate measures
                    designed to safeguard your Personal Information from loss,
                    misuse, destruction, and unauthorized access, disclosure,
                    and alteration. Notwithstanding our commitment to take
                    reasonable and appropriate measures to protect your Personal
                    Information, you should be aware that there is always some
                    risk involved in transmitting information over the Internet.
                    There is also some risk that others could find a way to
                    thwart or hack our security systems. As a result, we cannot
                    ensure or warrant the security and privacy of any
                    information you transmit to us, and you do so at your own
                    risk. We will notify you, via email, posting or other
                    commercially reasonable methods, of any loss of data or
                    breach of our security measures that has resulted in the
                    loss or unauthorized access, disclosure, use or acquisition
                    of your Personal Information (including hard copy records)
                    or otherwise presents a potential threat to such
                    information, as required by law.
                    <br />
                    <br />
                    Please remember that you also play a valuable part in data
                    security. You should never share your password, if
                    applicable, with anyone. If you feel your password has been
                    compromised, you should change it immediately. After you
                    have finished using the Website, you should log out of your
                    account and exit your browser so that no unauthorized
                    persons can use the Website under your name or account.
                  </li>

                  <li>
                    <strong className='text-decoration-underline'>
                      {' '}
                      Links to other sites.
                    </strong>
                    Through our various online offerings, we may provide links
                    to third-party websites. Except for those portions of the
                    websites that are hosted by us, each of these websites may
                    have separate privacy policies and different data-collection
                    practices from RP (for e.g., www.amazon.in), and we are not
                    responsible for the actions or practices of these third
                    parties, nor for the content on these sites. We encourage
                    you to review the privacy policies of these third-party
                    websites. This Privacy Policy applies only to information
                    collected by us through or specifically with respect to the
                    Website or the Program.
                  </li>
                </ol>
              </li>
              <li>
                <strong className='text-decoration-underline'>
                  What choices do you have regarding your Personal Information?
                </strong>

                <ol className='list-group-numbered list-style-none'>
                  <li>
                    <strong className='text-decoration-underline'>
                      Choice and consent.
                    </strong>
                    Each time you email us, sign up for or use the Website or
                    the Program, you are giving your express consent to the
                    collection, use, retention, as described in this Privacy
                    Policy. You may access, correct, delete, withdraw your
                    consent, cancel your account, obtain a duplicate of your
                    Personal Information as follows:
                    <br />
                    <br />
                    If you wish to withdraw your consent so that we do not
                    contact you by mail or phone to promote other products or
                    services that may be of interest to you, or for other
                    marketing or quality control purposes, you may advise us via
                    email at support@resiliencyprogram.com that you are
                    withdrawing your consent to receive such communications. To
                    opt-out of commercial emails, simply click the link labeled
                    “unsubscribe” or “opt-out” at the bottom of any commercial
                    electronic communication we send you. Please note that even
                    if you opt-out of promotional communications, we may still
                    contact you with important information about your account.
                    If you wish to prevent the data sharing described herein,
                    you may contact your Subscriber to close your account or if
                    you have enrolled yourself individually you may close your
                    account upon request via email at
                    support@resiliencyprogram.com any given point at time. If
                    you close your account, you will no longer be able to use
                    the Website or the Program or receive updates or other
                    communications, and we will not be able to respond to your
                    inquiries.
                  </li>
                  <li>
                    <strong className='text-decoration-underline'>
                      Updates.
                    </strong>
                    We respect your right to access and correct your Personal
                    Information. If you need assistance correcting or updating
                    your Personal Information, you may contact us via email at
                    support@resiliencyprogram.com any given point in time.
                    Subscribers are responsible for any requests made to them by
                    Program participants regarding access to and rectification
                    of participants’ respective Personal Information that is
                    transferred to us.
                  </li>
                </ol>
              </li>

              <li>
                <strong className='text-decoration-underline'>
                  {' '}
                  Your Legal Rights
                </strong>
                <br />
                <em>
                  You can access, update and correct the information and cancel
                  your account when you have been enrolled to the Resiliency
                  Program as a Subscriber. However, you do not have the right to
                  cancel your account if you are enrolled in the Program through
                  your Subscriber. A Registered User who has enrolled himself or
                  has been enrolled by their Subscriber. You can choose whether
                  or not to receive promotional communications by opting out if
                  you have subscribed or where we send you communications, we
                  consider are relevant to you, but please note that we may
                  still contact you with important information about your
                  account. All of these rights are subject to legal exemptions
                  and limitations. We may need to ask you to provide identity
                  information before we can process any of these requests.
                </em>
                <br />
                <br />
                This section of our Privacy Policy provides details and explains
                how to exercise your rights. The rights are listed below. Please
                contact us if you wish to exercise any of your rights on the
                contact details provided above.
                <ol className='list-group-numbered list-style-none'>
                  <li>
                    <strong className='text-decoration-underline'>
                      Request access.
                    </strong>
                    This means you can receive a copy of the information you’ve
                    provided to us.
                  </li>
                  <li>
                    <strong className='text-decoration-underline'>
                      Request correction.
                    </strong>
                    You can get corrected any incomplete or inaccurate data that
                    we hold, though we may need to verify the new information
                    being provided by you.{' '}
                  </li>
                  <li>
                    <strong className='text-decoration-underline'>
                      Request erasing your personal data.
                    </strong>
                    You can ask us to remove your personal data from our records
                    at any given point in time whether or not there is any good
                    reason for us to hold to that information. We may not always
                    be able to comply with your request when there are legal
                    reasons.{' '}
                  </li>
                  <li>
                    <strong className='text-decoration-underline'>
                      Request a data transfer.
                    </strong>
                    We will provide to you or your chosen third party, your
                    personal information. This only applies to automated
                    information which you provided to us or which you provided
                    consent for us to use. This can be done only by the
                    Subscriber unless a User has individually enrolled
                    himself/herself and not through any Subscriber.{' '}
                  </li>
                  <li>
                    <strong className='text-decoration-underline'>
                      Withdraw your consent.
                    </strong>
                    We may a lot of times rely on your consent to process your
                    personal data. You can withdraw your consent at any time.
                    This will not affect the lawfulness of processing carried
                    out before you withdraw your consent.
                  </li>
                  <li>
                    <strong className='text-decoration-underline'>
                      Right to restrict processing.
                    </strong>
                    You have the right to request that RP restrict the
                    processing of your personal data, under certain
                    circumstances.{' '}
                  </li>
                </ol>
              </li>
              <li>
                <strong className='text-decoration-underline'>
                  {' '}
                  Assessment.
                </strong>
                The Registered User after registering with RP and providing all
                the required information is needed to fill up a questionnaire
                for calculation of the burn out level. The Registered User is
                expected to provide us with correct and accurate information.
                <br />
                The Registered User will further be provided with plans for
                improvement of their respective burn out levels and increase the
                resiliency.
                <br />
                After calculation of burn out level of the Registered Users,
                within 15 days of which the Registered User will be sent an
                assessment questionnaire specifically for the Subscriber. This
                assessment will test the resiliency of the Subscriber.
              </li>
              <li>
                <strong className='text-decoration-underline'>
                  How does RP handle requests regarding kids, Do Not Track
                  signals or other special circumstances?
                </strong>

                <ol className='list-group-numbered list-style-none'>
                  <li>
                    <strong className='text-decoration-underline'>
                      Collection of information from children.
                    </strong>{' '}
                    None of our products or services are directed to children
                    and Resiliency Program does not knowingly collect, either
                    online or offline, Personal Information from children under
                    the age of 13 (or such age if required by applicable laws)
                    without verifiable parent or guardian consent. If you
                    believe that we have any Personal Information from a child
                    under age 13 (or such higher age if required by applicable
                    laws), please contact us via email at
                    support@resiliencyprogram.com .
                  </li>
                  <li>
                    <strong className='text-decoration-underline'>
                      Collection of information from children.
                    </strong>
                    RP does not currently respond to Do Not Track signals that
                    may be sent by some browsers or other client software.
                    Personal Information collected by us as described in this
                    Privacy Policy is not maintained in personally identifiable
                    form in combination with any identifier collected by
                    cookies, web beacons, or other technologies.
                  </li>
                </ol>
              </li>

              <li>
                <strong className='text-decoration-underline'>
                  {' '}
                  How may your Personal Information be transferred
                </strong>
                <ol className='list-group-numbered list-style-none'>
                  <li>
                    <strong className='text-decoration-underline'>
                      {' '}
                      For our Registered Users in the European Union (EU), the
                      European Economic Area (EEA), and the United Kingdom (UK).
                    </strong>
                    <br />
                    Users in this territory have certain rights relating to
                    their personal information under the applicable European/UK
                    data privacy laws. This section only applies to such users.
                    Under applicable European data privacy laws, Resiliency
                    Program is the data controller.
                    <ol className='modified-ol lower-alpha'>
                      <li>
                        <strong className='text-decoration-underline'>
                          Rights to Control Your Personal Information
                        </strong>
                        <br />
                        Applicable European/UK data privacy laws gives certain
                        users the right to access their personal information.
                        Your right of access can be exercised in accordance with
                        the European/UK data privacy laws. If you would like to
                        request a copy of your personal information being held
                        by us, or request that it is deleted or to update and/or
                        correct your personal information or request that we
                        provide a copy to another data controller of your
                        personal information that you have provided to us,
                        please contact us. We will need enough information to
                        ascertain your identity as well as the nature of your
                        request. We will aim to respond to your request within
                        one calendar month of receipt of the request. Where we
                        were unable to do so within the calendar month, we will
                        notify you of the soonest practicable time within which
                        we can respond to your request (and within three months
                        from the date of your request). There are certain
                        exemptions and restrictions of these rights under the
                        European/UK data privacy laws that enable personal
                        information to be retained, processed or withheld.
                      </li>
                      <li>
                        <strong className='text-decoration-underline'>
                          International Transfer{' '}
                        </strong>
                        <br />
                        RP does not transfer your Personal information/data to
                        any other country . Registered User’s Personal
                        Information may be transferred to different servers
                        location within India.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>

              <li>
                <strong className='text-decoration-underline'>
                  What information can you access?{' '}
                </strong>
                Resiliency Program acknowledges the individual’s right to access
                their personal data. You can access and update your account
                information by accessing the account settings area on the
                Website. Personal Information entered actively by a Registered
                User during the use of the Website, such as answers to
                assessment questions, journal entries, self-reported
                stress-level, etc., is not deleted or modified and may remain on
                servers for as long as needed to provide you with the Website’s
                products and services or as otherwise permitted by law. However,
                should you wish to have your Personal Information deleted please
                contact your Subscriber or if you have enrolled yourself
                individually without any Subscriber you may contact us at
                support@resiliencyprogram.com . As soon as practically possible
                after your request is received, RP will disclose to you all of
                your Personal Information that it keeps. Promptly after
                receiving your request, all Personal Information stored in
                databases we actively use and other readily searchable media
                will be updated, corrected, changed, or deleted, as appropriate,
                as soon as reasonably practicable.
              </li>

              <li>
                <strong className='text-decoration-underline'>
                  Contact us about questions, comments, complaints, notices or
                  disputes.
                </strong>
                If you have a question, comment, complaint, or a problem, or if
                you believe your privacy rights have been violated, you may
                email us at support@resiliencyprogram.com . Please indicate the
                following reason for contacting us: “l want to provide feedback
                about your site.” Our Customer Service department will forward
                your question, comment, complaint, or notice to the appropriate
                Resiliency Program department for response or resolution.
                Generally, we will accept and handle your request on personal
                information within 15 working days.
              </li>

              <li>
                <strong className='text-decoration-underline'>
                  EU Representative
                </strong>
                <br />
                <br />
                <strong className='text-decoration-underline'>
                  Resiliency Program has a designated EU representative
                </strong>
                as per Article 27 of GDPR who may be contacted by any Registered
                User with concerns regarding Resiliency Program in its capacity
                as a data processor. Resiliency Program has appointed Vikas
                Gupta (CEO) as its Data Protection Officer (DPO). You can
                contact him at dpo@resiliencyprogram.com.
              </li>

              <li>
                <strong className='text-decoration-underline'>
                  How can you communicate with us?
                </strong>
                Any questions about this Privacy Policy should be addressed to{' '}
                <Link
                  to='#'
                  onClick={() =>
                    (window.location = 'mailto:support@resiliencyprogram.com')
                  }
                >
                  support@resiliencyprogram.com
                </Link>
                .
              </li>
              <li>
                <strong className='text-decoration-underline'>
                  Dispute Resolution and Governing Law{' '}
                </strong>
                <br />
                Any dispute or difference whatsoever arising between the parties
                out of or relating to this Privacy Policy meaning, scope,
                operation or effect of it or its validity shall be settled by
                arbitration in accordance with the rules of domestic commercial
                arbitration of the Indian council of arbitration and the award
                made in pursuance thereof shall be binding on the parties.
                <br />
                <br />
                Any disputes shall be referred to a sole arbitrator appointed by
                the ICA and the seat of arbitration shall be New Delhi.
                <br />
                <br />
                These terms shall be governed by the laws of India and the
                parties agree to subject themselves to the exclusive
                jurisdiction to the courts at New Delhi.
              </li>
            </ol>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PrivacyPolicy;
