import React from 'react';
import Modal from 'react-bootstrap/Modal';

const Disclaimer = (props) => {
  return (
    <Modal
      size='xl'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      scrollable={true}
      show={props.showModal ? true : false}
    >
      <Modal.Header>
        <h5 className='modal-title'>Disclaimer</h5>
        <button
          type='button'
          className='btn-close'
          data-bs-dismiss='modal'
          aria-label='Close'
          onClick={props.handleCloseModal}
        ></button>
      </Modal.Header>
      <Modal.Body>
        <div className='col-12'>
          <h3 className='modal-heding'>
            DISCLAIMERS FOR PRODUCTS AND SERVICES RELATED TO THE ASSESSMENT
            RESULTS
          </h3>
          <ol className='list-group-numbered mt-5 '>
            <li>
              <span className='fw-bold mb-2 text-decoration-underline fs-6'>
                For Lifestyle Changes – Videos
              </span>
              <br />
              <p className='mt-2'>
                The information provided by the instructor/presenter of the
                videos in respect of any exercises, mindfulness training, good
                habits, focus and concentration, removal of stress and anxiety
                etc. are for general information and awareness purposes only and
                should not be used as a substitute for professional advice
                and/or information, as circumstances may vary from person to
                person. You should not act or rely upon this information without
                seeking proper professional advice especially if you are
                pregnant or have any pre-existing health conditions. The
                informational videos neither are intended to be nor do they
                constitute any actionable professional advice. Resiliency
                Program disclaims any liability for any direct or indirect,
                incidental or consequential damages and assumes no
                responsibility for any loss, injury, or damage suffered by any
                person as a result of the use or misuse of any information in
                the videos made available on the website. All information is
                intended for general knowledge only and is not a substitute for
                medical advice or treatment for specific medical conditions. You
                understand and agree that you will indemnify, defend, and hold
                us and our affiliates harmless from any liability, loss, claim
                and expense, including reasonable attorney’s fees, arising from
                your use of this videos.
              </p>
            </li>
            <li>
              <span className='fw-bold mb-2 text-decoration-underline fs-6'>
                For Lifestyle Changes – Diet Plan
              </span>
              <br />
              <p className='mt-2'>
                The information offered in these diet plans is for people in
                good health. Anyone with medical problems including anyone with
                any allergies of any nature should see and consult a dietitian
                and/or doctor before starting any diet program as recommended in
                such diet plans. Even if you have no known health problems, it
                is advisable to consult your dietitian/doctor before making
                major changes in your diet plans. The information provided in
                these plans is for informational and awareness purposes only and
                is not intended to amount to any medical advice. The information
                contained in these diet plans should not be used for weight loss
                or diagnose or treat any illness. All information is intended
                for general knowledge only and is not a substitute for dietitian
                advice or treatment for specific medical conditions. We do not
                recommend self-management of health problems. You understand and
                agree that you will indemnify, defend, and hold us and our
                affiliates harmless from any liability, loss, claim and expense,
                including reasonable attorney’s fees, arising from your use of
                this diet plan. Resiliency Program disclaims any liability for
                any direct or indirect, incidental or consequential damages and
                assumes no responsibility for any loss, injury, or damage
                suffered by any person as a result of the use or misuse of any
                information in the diet plans made available on the website.
              </p>
            </li>

            <li>
              <span className='fw-bold mb-2 text-decoration-underline fs-6'>
                For Supplements
              </span>
              <br />
              <p className='mt-2'>
                Please read product packaging carefully prior to purchase and
                use. Resiliency Program is in no way responsible for use of any
                of these products as these are only suggestions. These
                supplements may not be suitable to everyone and should not be
                consumed before proper medical advice especially if you have any
                allergies, are pregnant or have any health issues. Efficacy of
                products depends on many things including the dosage, weight,
                age and health of the individual. While we endeavor to keep the
                information up-to-date and correct, we make no representations
                or warranties of any kind, express or implied, about the
                completeness, accuracy, reliability, suitability or availability
                with respect to these supplements or information contained on
                them. Any reliance you place on such information is therefore
                strictly at your own risk.
              </p>
              <p>
                These supplements may or may not be available as it is subject
                to availability on third party websites (for e.g.
                www.amazon.in). Resiliency Program exercises no control
                whatsoever over such other websites and is not responsible or
                liable for the availability thereof or the content, advertising,
                products or other materials on such websites. Resiliency Program
                disclaims any liability for incidental or consequential damages
                and assumes no responsibility for any loss, injury, or damage
                suffered by any person as a result of the use or misuse of any
                of these supplements. Once you choose to link to another
                website, you should read that website's privacy statement before
                disclosing any personal information. You understand and agree
                that you will indemnify, defend, and hold us and our affiliates
                harmless from any liability, loss, claim and expense, including
                reasonable attorney’s fees, arising from your use of this
                videos.
              </p>
            </li>

            <li>
              <span className='fw-bold mb-2 text-decoration-underline fs-6'>
                For Information
              </span>
              <br />
              <p className='mt-2'>
                The registered user provides his personal information on the
                basis of which his resiliency/burnout level is calculated.
                Resiliency program assumes no responsibility and disclaims any
                liability over the results of the Resiliency Program assessment
                provided if the user provides any inaccurate information.
              </p>
            </li>
          </ol>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Disclaimer;
