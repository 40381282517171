import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Footer from '../../../../layout/Components/Footer';
import Header from '../../../../layout/Components/Header';
import Scroll from 'react-scroll';
import { useDispatch } from 'react-redux';
import { saveAssmt } from '../../../../store/assmt/actions';
import { saveAssmtId } from '../../../../store/assmtId/actions';
import moment from 'moment';
import Dailylinks from './Components/DailyLinks';
import Lifestyle from './Components/Lifestyle';
import NutritionalSupplements from './Components/NutritionalSupplements';
import MindfulnessTraining from './Components/MindfulnessTraining';
import { isEmpty } from 'lodash';
import Spinner from 'react-bootstrap/Spinner';
import Favourite from './Components/Favourite';
import LoadingSpinner from '../../../../common/LoadingSpinner';
import Recommended from './Components/Recommended';
import { useSelector } from 'react-redux';
import { save } from '../../../../store/user/actions';

const ScrollLink = Scroll.Link;

const ResiliencyPlan = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [resultData, setResultData] = useState('');
  let location = useLocation();
  let loc = location.pathname.split('/');
  const [lifestyleCategoryData, setLifestyleCategoryData] = useState([]);
  const [mindfulCategoryData, setMindfulCategoryData] = useState([]);
  const [rpContentCategoryId, setRpContentCategoryId] = useState('');
  const [showActive, setShowActive] = useState('');
  const [showList, setShowList] = useState(false);
  const [tab, setTab] = useState('');
  const [categoryTitle, setCategoryTitle] = useState('');
  const [categoryDesc, setCategoryDesc] = useState('');
  const [isClicked, setIsClicked] = useState(false);
  const [sidebarMenuClicked, setSidebarMenuClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState();
  const [showMoreVideos, setShowMoreVideos] = useState();
  const [activeFavSub, setActiveFavSub] = useState();
  const [favPage, setFavPage] = useState(1);
  const [isSeriesClicked, setIsSeriesClicked] = useState(true);
  const [contentData, setContentData] = useState([]);
  const [favouriteData, setFavouriteData] = useState({
    data: '',
    isLoading: false,
  });
  const [isListOpen, setIsListOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState('');
  const [unFavFlag, setUnFavFlag] = useState(false);
  const [unFavFlagCounter, setUnFavFlagCounter] = useState(1);

  useEffect(() => {
    if (user?.is_recommendation_enabled) {
      updateProfileData();
      if (
        (loc[3] === 'recommended' &&
          loc[3] !== 'dailylinks' &&
          loc[3] !== 'nutritional-supplements' &&
          loc[3] !== 'lifestyle' &&
          loc[3] !== 'mindfulness-training') ||
        location.pathname === '/dashboard/resiliency-plan/' ||
        location.pathname === '/dashboard/resiliency-plan'
      ) {
        setTab('recommended');
      }
    } else {
      updateProfileData();
      if (
        (loc[3] === 'dailylinks' &&
          loc[3] !== 'nutritional-supplements' &&
          loc[3] !== 'lifestyle' &&
          loc[3] !== 'mindfulness-training') ||
        location.pathname === '/dashboard/resiliency-plan/' ||
        location.pathname === '/dashboard/resiliency-plan'
      ) {
        setTab('dailylinks');
      }
    }

    if (!lifestyleCategoryData.length) {
      getContentData(2);
    }

    if (!mindfulCategoryData.length) {
      getContentData(3);
    }

    const getAssessmentData = async () => {
      try {
        const result = await window.$http.getWithHeaders('my/assessment');
        if (result.code === window.$constants.STATUS.OK) {
          setResultData(result?.data);
          setStatus(result?.data?.find((el) => el)?.status);
          dispatch(saveAssmt(result));
          result?.data?.map((el) =>
            el?.is_completed
              ? el?.completed_date <= moment().format() &&
                dispatch(saveAssmtId(el?.rp_company_assmt_id))
              : ''
          );
        } else {
          console.log('Something went wrong!');
        }
      } catch (error) {
        console.log(error);
      }
    };

    getAssessmentData();

    if (loc[3] === 'favourite') {
      setFavouriteData({ data: '', isLoading: true });
      setIsSeriesClicked(false);
      if (!loc[4]) {
        setActiveFavSub('series');
        handleSeriesData();
      } else {
        if (loc[4] === 'series') {
          setActiveFavSub('series');
          handleSeriesData();
        } else if (loc[4] === 'content') {
          setActiveFavSub('content');
          handleContentData();
        }
      }
    }

    if (
      (loc[3] === 'lifestyle' || loc[3] === 'mindfulness-training') &&
      loc[4]
    ) {
      setShowActive(loc[4]);
    }
  }, []);

  const updateProfileData = async () => {
    try {
      const result = await window.$http.getWithHeaders('v2/my_profile');
      if (result.code === window.$constants.STATUS.OK) {
        dispatch(save(result));
        if (result?.data?.is_recommendation_enabled) {
          setTab('recommended');
        } else {
          setTab('dailylinks');
        }
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getListOpen = (value) => {
    setIsListOpen(value);
  };

  const getContentData = async (id) => {
    setIsLoading(true);
    try {
      const result = await window.$http.getWithHeaders(
        `content/categories/${id}`
      );
      if (result.code === window.$constants.STATUS.OK) {
        setIsLoading(false);
        if (id === 2) {
          setLifestyleCategoryData(result?.data?.children);
        } else if (id === 3) {
          setMindfulCategoryData(result?.data?.children);
        }
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLifestyleCategoryVideos = (value) => {
    if (!isEmpty(value)) {
      setShowMoreVideos(true);
    }
  };

  const handleMindfulCategoryVideos = (value) => {
    if (!isEmpty(value)) {
      setShowMoreVideos(true);
    }
  };

  useEffect(() => {
    if (loc[3]) {
      setTab(loc[3]);
    }

    if (loc[3] && !loc[4]) {
      setShowActive('');
    }
  }, [loc]);

  const handleContent = async (
    category_slug,
    category_title,
    id,
    category_desc
  ) => {
    setShowList(true);
    setIsListOpen(false);
    setSidebarMenuClicked(!sidebarMenuClicked);
    setActiveFavSub('');
    setFavPage(1);
    setContentData([]);
    document.body.classList.remove('overflow-hidden');
    setShowActive(category_slug);
    setCategoryTitle(category_title);
    setCategoryDesc(category_desc);
    if (rpContentCategoryId !== id) {
      setRpContentCategoryId(id);
      setShowMoreVideos(false);
    }
  };

  const handleTabs = (tab) => {
    setTab(tab);
    setIsCollapsed(tab);
    setFavPage(1);
    setRpContentCategoryId('');
    setShowList(false);
    setIsClicked(false);
    setSidebarMenuClicked(!sidebarMenuClicked);
    setShowActive('');
    document.body.classList.remove('overflow-hidden');
    if (tab !== 'favourite') {
      setActiveFavSub('');
      setContentData([]);
    }
  };

  const handleCollapsed = (value) => {
    setIsCollapsed(value);
  };

  const handleDailyLinkVideos = (value) => {
    if (!isEmpty(value)) {
      setIsClicked(true);
    }
  };

  const handleFavVideos = (value) => {
    if (!isEmpty(value)) {
      setIsSeriesClicked(true);
    }
  };

  const handleShowList = (value) => {
    setShowList(value);
    setTab('lifestyle');
  };

  const showTabContent = () => {
    if ((tab && tab === 'recommended') || loc[3] === 'recommended') {
      return (
        <Recommended
          rpContentCategoryId={rpContentCategoryId}
          showMoreVideos={showMoreVideos}
          handleCategoryVideos={handleLifestyleCategoryVideos}
        />
      );
    } else if (tab && tab === 'dailylinks' && !showList) {
      return (
        <Dailylinks
          isClicked={isClicked}
          handleDailyLinkVideos={handleDailyLinkVideos}
          id={loc[4] ? loc[4] : ''}
        />
      );
    } else if (
      tab &&
      tab === 'lifestyle' &&
      loc[3] === 'lifestyle' &&
      !loc[4]
    ) {
      return (
        <Lifestyle
          showList={showList}
          showActive={showActive}
          rpContentCategoryId={rpContentCategoryId}
          categoryTitle={categoryTitle}
          categoryDesc={categoryDesc}
          showMoreVideos={showMoreVideos}
          handleCategoryVideos={handleLifestyleCategoryVideos}
          getListOpen={getListOpen}
          isListOpen={isListOpen}
          handleShowList={handleShowList}
        />
      );
    } else if (tab && tab === 'lifestyle' && loc[3] === 'lifestyle' && loc[4]) {
      const data = lifestyleCategoryData?.find(
        (el) => el.category_slug === loc[4]
      );
      return (
        <Lifestyle
          showList={true}
          showActive={data?.category_slug}
          rpContentCategoryId={data?.rp_content_category_id}
          categoryTitle={data?.category_title}
          categoryDesc={data?.category_description}
          showMoreVideos={showMoreVideos}
          handleCategoryVideos={handleLifestyleCategoryVideos}
          getListOpen={getListOpen}
          isListOpen={isListOpen}
          handleShowList={handleShowList}
        />
      );
    } else if (
      (tab && tab === 'nutritional-supplements') ||
      loc[3] === 'nutritional-supplements'
    ) {
      return <NutritionalSupplements />;
    } else if (
      tab &&
      tab === 'mindfulness-training' &&
      loc[3] === 'mindfulness-training' &&
      !loc[4]
    ) {
      return (
        <MindfulnessTraining
          showList={showList}
          showActive={showActive}
          rpContentCategoryId={rpContentCategoryId}
          categoryTitle={categoryTitle}
          categoryDesc={categoryDesc}
          showMoreVideos={showMoreVideos}
          handleCategoryVideos={handleMindfulCategoryVideos}
        />
      );
    } else if (
      tab &&
      tab === 'mindfulness-training' &&
      loc[3] === 'mindfulness-training' &&
      loc[4]
    ) {
      const data = mindfulCategoryData?.find(
        (el) => el.category_slug === loc[4]
      );
      return (
        <MindfulnessTraining
          showList={true}
          showActive={data?.category_slug}
          rpContentCategoryId={data?.rp_content_category_id}
          categoryTitle={data?.category_title}
          categoryDesc={data?.category_description}
          showMoreVideos={showMoreVideos}
          handleCategoryVideos={handleMindfulCategoryVideos}
        />
      );
    } else if (tab && tab === 'favourite') {
      return (
        <>
          {favouriteData?.isLoading ? (
            <div className='centered' style={{ marginLeft: '1rem' }}>
              <LoadingSpinner />
            </div>
          ) : (
            <Favourite
              data={favouriteData?.data}
              loading={favouriteData?.isLoading}
              activeFavSub={activeFavSub}
              isClicked={isSeriesClicked}
              handleFavVideos={handleFavVideos}
              contentData={contentData}
              handleFavPage={handleFavPage}
              handleUnfavFlag={handleUnfavFlag}
            />
          )}
        </>
      );
    }
  };

  const handleResponsiveSidebar = () => {
    setSidebarMenuClicked(!sidebarMenuClicked);
  };

  const handleBodyClass = () => {
    if (!sidebarMenuClicked) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  };

  const handleSidebar = () => {
    handleResponsiveSidebar();
    handleBodyClass();
  };

  const handleFavourite = async (value) => {
    // navigate(`/dashboard/resiliency-plan/favourite/${value}`);
    if (value === 'series') {
      handleSeriesData();
    }
    setFavPage(1);
    setIsSeriesClicked(false);
    if (value !== activeFavSub) {
      setFavouriteData({ data: '', isLoading: true });
      // setActiveFavSub('');
      setActiveFavSub(value);
      handleTabs('favourite');
      try {
        if (value === 'series') {
          setContentData([]);
          handleSeriesData();
        } else if (value === 'content') {
          setContentData([]);
          handleContentData();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (activeFavSub === 'series' && !isSeriesClicked) {
      setFavouriteData({ data: '', isLoading: true });
    }
  }, [activeFavSub, isSeriesClicked]);

  const handleSeriesData = async () => {
    setUnFavFlag(false);
    try {
      const seriesData = await window.$http.getWithHeaders(`favourite/series`);

      if (seriesData.code === window.$constants.STATUS.OK) {
        if (seriesData?.data?.series) {
          setContentData(seriesData?.data?.series);
        } else {
          setContentData([]);
        }
        setFavouriteData({ data: seriesData, isLoading: false });
        navigate('/dashboard/resiliency-plan/favourite/series');
      } else {
        setFavouriteData({ data: '', isLoading: false });
        console.log('Something went wrong!');
      }
    } catch (error) {
      setFavouriteData({ data: '', isLoading: false });
      console.log(error);
    }
  };

  const handleContentData = async () => {
    try {
      const result = await window.$http.getWithHeaders(
        `content?is_favourite=1&page=${favPage}`
      );

      if (result?.code === window.$constants.STATUS.OK) {
        if (!isEmpty(result?.data)) {
          if (unFavFlag) {
            let onlyFavItem = contentData.filter(function (el) {
              return el.is_favourite == true;
            });
            const onlyInResult = onlyInNewResult(
              result?.data,
              onlyFavItem,
              isSameData
            );
            setContentData([...onlyFavItem, ...onlyInResult]);
          } else {
            setContentData([...contentData, ...result?.data]);
          }
        }
        setFavouriteData({ data: result, isLoading: false });
        navigate('/dashboard/resiliency-plan/favourite/content');
      } else {
        setFavouriteData({ data: '', isLoading: false });
        console.log('Something went wrong!');
      }
    } catch (error) {
      setFavouriteData({ data: '', isLoading: false });
      console.log(error);
    }
  };

  const onlyInNewResult = (left, right, compareFunction) =>
    left.filter(
      (leftValue) =>
        !right.some((rightValue) => compareFunction(leftValue, rightValue))
    );

  const isSameData = (a, b) => a.rp_content_id === b.rp_content_id;

  const handleFavPage = (value) => {
    setFavPage(value);
  };

  const handleUnfavFlag = (value) => {
    setUnFavFlag(value);
    setUnFavFlagCounter(unFavFlagCounter + 1);
  };

  useEffect(() => {
    if (favPage > 1 || unFavFlag) {
      setFavouriteData({ data: '', isLoading: false });
      handleContentData();
    }
  }, [favPage, unFavFlag, unFavFlagCounter]);

  return (
    <>
      <Header value='plan' />
      <main>
        {resultData ? (
          status === 0 ? (
            <>
              <div className='plan_container'>
                <div
                  className={
                    sidebarMenuClicked
                      ? 'side_nav open mobile-offcanvas'
                      : 'side_nav open'
                  }
                  style={{ width: '23%' }}
                >
                  <div className='sidebar'>
                    <div className='sidebar_list'>
                      <div className='py-4'>
                        <ul id='nav_accordion' style={{ padding: '0' }}>
                          {user?.is_recommendation_enabled ? (
                            <li
                              className={
                                (loc[3] === 'recommended' &&
                                  loc[3] !== 'dailylinks' &&
                                  loc[3] !== 'nutritional-supplements' &&
                                  loc[3] !== 'lifestyle' &&
                                  loc[3] !== 'mindfulness-training') ||
                                location.pathname ===
                                  '/dashboard/resiliency-plan/' ||
                                location.pathname ===
                                  '/dashboard/resiliency-plan'
                                  ? 'py-2 px-2 side-nav mb-2 active_li'
                                  : 'py-2 px-2 side-nav mb-2'
                              }
                            >
                              <Link
                                className='li_link'
                                to='/dashboard/resiliency-plan/recommended'
                                onClick={() => handleTabs('recommended')}
                              >
                                <span className='li_icon'>
                                  <i className='flaticon-like'></i>
                                </span>
                                <span className='li_title'>Recommended</span>
                              </Link>
                            </li>
                          ) : (
                            ''
                          )}
                          {user?.is_recommendation_enabled ? (
                            <li
                              className={
                                loc[3] === 'dailylinks'
                                  ? 'py-2 px-2 side-nav mb-2 active_li'
                                  : 'py-2 px-2 side-nav mb-2'
                              }
                            >
                              <Link
                                className='li_link'
                                to='/dashboard/resiliency-plan/dailylinks'
                                onClick={() =>
                                  handleTabs('dailylinks') &&
                                  handleDailyLinkVideos('')
                                }
                              >
                                <span className='li_icon'>
                                  <i className='flaticon-link'></i>
                                </span>
                                <span className='li_title'>Daily Links</span>
                              </Link>
                            </li>
                          ) : (
                            <li
                              className={
                                (loc[3] === 'dailylinks' &&
                                  loc[3] !== 'nutritional-supplements' &&
                                  loc[3] !== 'lifestyle' &&
                                  loc[3] !== 'mindfulness-training') ||
                                location.pathname ===
                                  '/dashboard/resiliency-plan/' ||
                                location.pathname ===
                                  '/dashboard/resiliency-plan'
                                  ? 'py-2 px-2 side-nav mb-2 active_li'
                                  : 'py-2 px-2 side-nav mb-2'
                              }
                            >
                              <Link
                                className='li_link'
                                to='/dashboard/resiliency-plan/dailylinks'
                                onClick={() =>
                                  handleTabs('dailylinks') &&
                                  handleDailyLinkVideos('')
                                }
                              >
                                <span className='li_icon'>
                                  <i className='flaticon-link'></i>
                                </span>
                                <span className='li_title'>Daily Links</span>
                              </Link>
                            </li>
                          )}

                          <li
                            className={
                              loc[3] === 'lifestyle'
                                ? 'py-2 px-2 side-nav d-block mb-2 active_li'
                                : 'py-2 px-2 side-nav d-block mb-2'
                            }
                          >
                            <div className='plan-groups'>
                              <Link
                                className='li_link'
                                to='/dashboard/resiliency-plan/lifestyle'
                                onClick={() => handleTabs('lifestyle')}
                              >
                                <span className='li_icon'>
                                  <i className='flaticon-daily-health-app'></i>
                                </span>
                                <span className='li_title'>
                                  Lifestyle Changes
                                </span>
                              </Link>
                              <span
                                data-bs-toggle='collapse'
                                data-bs-target='#menu_item1'
                                className={`drop_toggler ${
                                  (isCollapsed === '' && tab === 'lifestyle') ||
                                  isCollapsed === 'lifestyle'
                                    ? ''
                                    : 'collapsed'
                                }`}
                              >
                                <i
                                  className='fa-solid fa-chevron-down'
                                  onClick={() => handleCollapsed('lifestyle')}
                                ></i>
                              </span>
                            </div>
                            <ul
                              id='menu_item1'
                              className={`submenu collapse ${
                                (isCollapsed === '' && tab === 'lifestyle') ||
                                isCollapsed === 'lifestyle'
                                  ? 'show'
                                  : ''
                              }`}
                              data-bs-parent='#nav_accordion'
                            >
                              {lifestyleCategoryData?.map((data, id) => (
                                <li
                                  key={id}
                                  className={
                                    showActive === data?.category_slug
                                      ? 'nav-link active_sub'
                                      : 'nav-link'
                                  }
                                  onClick={() =>
                                    handleContent(
                                      data?.category_slug,
                                      data?.category_title,
                                      data?.rp_content_category_id,
                                      data?.category_description
                                    ) &&
                                    navigate(
                                      `/dashboard/resiliency-plan/lifestyle/${data?.category_slug}`
                                    )
                                  }
                                >
                                  {isLoading ? (
                                    <Spinner
                                      as='i'
                                      animation='border'
                                      size='sm'
                                      role='status'
                                      aria-hidden='true'
                                    />
                                  ) : (
                                    data?.category_title
                                  )}
                                </li>
                              ))}
                            </ul>
                          </li>
                          <li
                            className={
                              loc[3] === 'nutritional-supplements'
                                ? 'py-2 px-2 side-nav mb-2 active_li'
                                : 'py-2 px-2 side-nav mb-2 '
                            }
                          >
                            <Link
                              className='li_link'
                              to='/dashboard/resiliency-plan/nutritional-supplements'
                              onClick={() =>
                                handleTabs('nutritional-supplements')
                              }
                            >
                              <span className='li_icon'>
                                <i className='flaticon-supplement'></i>
                              </span>
                              <span className='li_title'>
                                Nutritional Supplements
                              </span>
                            </Link>
                          </li>
                          <li
                            className={
                              loc[3] === 'mindfulness-training'
                                ? 'py-2 px-2 side-nav d-block mb-2 active_li'
                                : 'py-2 px-2 side-nav d-block mb-2'
                            }
                          >
                            <div className='plan-groups'>
                              <Link
                                className='li_link'
                                to='/dashboard/resiliency-plan/mindfulness-training'
                                onClick={() =>
                                  handleTabs('mindfulness-training')
                                }
                              >
                                <span className='li_icon'>
                                  <i className='flaticon-mindfulness'></i>
                                </span>
                                <span className='li_title'>
                                  Mindfulness Training
                                </span>
                              </Link>
                              <span
                                data-bs-toggle='collapse'
                                data-bs-target='#menu_item2'
                                className={`drop_toggler ${
                                  (isEmpty(isCollapsed) &&
                                    tab === 'mindfulness-training') ||
                                  isCollapsed === 'mindfulness-training'
                                    ? ''
                                    : 'collapsed'
                                }`}
                              >
                                <i
                                  className='fa-solid fa-chevron-down'
                                  onClick={() =>
                                    handleCollapsed('mindfulness-training')
                                  }
                                ></i>
                              </span>
                            </div>
                            <ul
                              id='menu_item2'
                              className={`submenu collapse ${
                                (isCollapsed === '' &&
                                  tab === 'mindfulness-training') ||
                                isCollapsed === 'mindfulness-training'
                                  ? 'show'
                                  : ''
                              }`}
                              data-bs-parent='#nav_accordion'
                            >
                              {mindfulCategoryData?.map((data, id) => (
                                <li
                                  key={id}
                                  className={
                                    showActive === data?.category_slug
                                      ? 'nav-link active_sub'
                                      : 'nav-link'
                                  }
                                  onClick={() =>
                                    handleContent(
                                      data?.category_slug,
                                      data?.category_title,
                                      data?.rp_content_category_id,
                                      data?.category_description
                                    ) &&
                                    navigate(
                                      `/dashboard/resiliency-plan/mindfulness-training/${data?.category_slug}`
                                    )
                                  }
                                >
                                  {isLoading ? (
                                    <Spinner
                                      as='i'
                                      animation='border'
                                      size='sm'
                                      role='status'
                                      aria-hidden='true'
                                    />
                                  ) : (
                                    data?.category_title
                                  )}
                                </li>
                              ))}
                            </ul>
                          </li>

                          <li
                            className={`py-2 px-2 side-nav d-block  favourite_li mb-2 ${
                              loc[3] === 'favourite' ? 'active_li' : ''
                            }`}
                          >
                            <div
                              data-bs-target='#menu_item3'
                              data-bs-toggle='collapse'
                              className='plan-groups'
                            >
                              <div className='li_link cursor-p'>
                                <span className='li_icon'>
                                  <i className='flaticon-heart'></i>
                                </span>
                                <span
                                  className='li_title'
                                  onClick={() => handleCollapsed('favourite')}
                                >
                                  My Favourite
                                </span>
                              </div>
                              <span
                                data-bs-toggle='collapse'
                                data-bs-target='#menu_item3'
                                className={`drop_toggler ${
                                  (isEmpty(isCollapsed) &&
                                    tab === 'favourite') ||
                                  isCollapsed === 'favourite'
                                    ? ''
                                    : 'collapsed'
                                }`}
                              >
                                <i
                                  className='fa-solid fa-chevron-down'
                                  onClick={() => handleCollapsed('favourite')}
                                ></i>
                              </span>
                            </div>
                            <ul
                              id='menu_item3'
                              className={`submenu collapse ${
                                (isEmpty(isCollapsed) && tab === 'favourite') ||
                                isCollapsed === 'favourite'
                                  ? 'show'
                                  : ''
                              }`}
                              data-bs-parent='#nav_accordion'
                            >
                              <li
                                className={`nav-link ${
                                  activeFavSub === 'series' ? 'active_sub' : ''
                                } `}
                                onClick={() => handleFavourite('series')}
                              >
                                Series
                              </li>
                              <li
                                className={`nav-link ${
                                  activeFavSub === 'content' ? 'active_sub' : ''
                                } `}
                                onClick={() => handleFavourite('content')}
                              >
                                Content
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='main_content'>
                  <div className='text-end mb-4 d-block d-xl-none'>
                    <span className='side-nav_toggler pe-2'>
                      <i
                        className='fa-solid fa-ellipsis-vertical'
                        onClick={handleSidebar}
                      ></i>
                    </span>
                  </div>
                  {showTabContent()}
                </div>
              </div>
            </>
          ) : (
            <>
              <section style={{ marginTop: '4.5rem' }}>
                <div className='container'>
                  <div className='row'>
                    <div className='bg-white p-3 p-xl-5 box_shadow br-12 position-relative'>
                      <div
                        className='dots-styles d-none d-xl-block'
                        style={{ width: '25%' }}
                      ></div>
                      <h1 className='section-heading rp-primary-dark-g mb-5'>
                        A Resiliency Plan* is created based on your assessment
                        results.
                      </h1>

                      {resultData?.find(
                        (data) =>
                          data?.status === window.$enums.AssmtStatus.Next
                      ) && resultData?.length <= 1 ? (
                        <div className='container'>
                          <h1 className='section-sub-heading rp-primary-red-color mb-4 fw-bold'>
                            Assessment will be available on{' '}
                            {
                              resultData?.find(
                                (data) =>
                                  data?.status ===
                                  window.$enums.AssmtStatus.Next
                              )?.display_date
                            }
                            .
                          </h1>
                        </div>
                      ) : (
                        <>
                          <h2 className='fs-3 lh-base rp-primary-dark-g mb-5'>
                            Let's start by taking your assessment today and kick
                            off your journey to growth and well-being with the
                            Resiliency Program!
                          </h2>
                          <ScrollLink
                            smooth={true}
                            duration={500}
                            to='assessment'
                          >
                            <button
                              className='btn btn-rp-primary mb-5'
                              onClick={() =>
                                navigate(
                                  '/dashboard/assessments/resiliency-stages'
                                )
                              }
                            >
                              Begin Assessment
                            </button>
                          </ScrollLink>
                        </>
                      )}
                      <p className='rp-primary-red-color fw-bold'>
                        *Resiliency Plan maps out recommendations for Lifestyle
                        Changes, Nutritional Supplements, and Mindfulness
                        Training for you to incorporate into your daily routine.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </>
          )
        ) : (
          ''
        )}
      </main>
      <Footer
        addClass={
          resultData && window.innerWidth > 768
            ? ''
            : tab === 'lifestyle'
            ? 'fixed-bottom'
            : ''
        }
      />
    </>
  );
};

export default ResiliencyPlan;
