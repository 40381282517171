import React from 'react';

const FactorTab = (props) => {
  return (
    <div className='factor-icons mb-4'>
      {props.sectionData?.length
        ? props.sectionData?.map((item, index) => (
            <div
              key={index}
              onClick={() =>
                props.handleFactorTabClick(index, item?.rp_assmt_section_id)
              }
              className={`factor-ico ${
                index === props?.factorTabIndex ? 'active' : ''
              }`}
            >
              <div
                className={`${
                  item?.trend === 'down'
                    ? 'low-arrow'
                    : item?.trend === 'up'
                    ? 'high-arrwo'
                    : 'satisfactory-arrow'
                }`}
              >
                <i
                  className={`fa-solid ${
                    item?.trend === 'down'
                      ? 'fa-arrow-down'
                      : item?.trend === 'up'
                      ? 'fa-arrow-up'
                      : 'fa-arrows-left-right'
                  }`}
                />
              </div>
              <img
                src={`${process.env.REACT_APP_API_URL}media/icon/${item?.icon_name}`}
                alt=''
              />
              <p>{item?.title}</p>
            </div>
          ))
        : 'No Data'}
    </div>
  );
};

export default FactorTab;
