import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ViewModal from './Components/ViewModal';
import { Link } from 'react-router-dom';
import { usePagination, DOTS } from '../../../hooks/Pagination/usePagination';

const initialValue = {
  title: '',
  body: '',
  notificationType: '',
};

const PushNotifications = () => {
  const [showCompanyDropDown, setShowCompanyDropDown] = useState(false);
  const [showUserDropDown, setShowUserDropDown] = useState(false);
  const [showAssmtDropDown, setShowAssmtDropDown] = useState(false);
  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [userAssmtStatusOptions, setUserAssmtStatusOptions] = useState([]);
  const [assmtTypeOptions, setAssmtTypeOptions] = useState([]);
  const [actionOptions, setActionOptions] = useState([]);
  const [companyCheckValue, setCompanyCheckValue] = useState([]);
  const [userSelectValue, setUserSelectValue] = useState();
  const [assmtSelectValue, setAssmtSelectValue] = useState();
  const [data, setData] = useState(initialValue);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [buttonState, setButtonState] = useState({
    value: '',
    isLoading: false,
  });
  const [check, setCheck] = useState('');
  const [checkValue, setCheckValue] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [result, setResult] = useState([]);
  const [historyResult, setHistoryResult] = useState([]);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [clickList, setClickList] = useState([]);
  const [isEnterClicked, setIsEnterClicked] = useState(false);
  const [metaData, setMetaData] = useState(false);
  const siblingCount = 1;
  const paginationRange = usePagination({
    page,
    pageCount,
    siblingCount,
  });

  const tableId = document.getElementById('table');
  const columnLength = tableId?.rows[0]?.cells?.length;
  const companyValue = companiesOptions[0]?.value;
  const userValue = userAssmtStatusOptions[0]?.value;
  const assmtValue = assmtTypeOptions[0]?.value;

  useEffect(() => {
    getDropdownOptions();
    getCompaniesOptions();
    getData();
    getHistory();
  }, []);

  useEffect(() => {
    if (companyCheckValue?.length) {
      setCompanyValues();
    }
  }, [companyCheckValue?.length]);

  const getDropdownOptions = async () => {
    try {
      const result = await window.$http.getWithHeaders(
        'lookup?types=assessment-type,user-assessment-status,push-notification-action'
      );

      if (result.code === window.$constants.STATUS.OK) {
        setAssmtTypeOptions(result?.data?.assessment_type);
        setUserAssmtStatusOptions(result?.data?.user_assessment_status);
        setActionOptions(result?.data?.push_notification_action);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCompaniesOptions = async () => {
    try {
      const result = await window.$http.getWithHeaders('companies');

      if (result.code === window.$constants.STATUS.OK) {
        return setCompaniesOptions(result?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCompanyDropdown = () => {
    setShowCompanyDropDown(!showCompanyDropDown);
    setShowUserDropDown(false);
    setShowAssmtDropDown(false);
  };

  const handleUserDropdown = () => {
    setShowUserDropDown(!showUserDropDown);
    setShowCompanyDropDown(false);
    setShowAssmtDropDown(false);
  };

  const handleAssmtDropdown = () => {
    setShowAssmtDropDown(!showAssmtDropDown);
    setShowCompanyDropDown(false);
    setShowUserDropDown(false);
  };

  const handleCheckState = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      companyCheckValue.push(value);
      setCompanyCheckValue((prev) => companyCheckValue);
      setCheck(value);
    } else {
      setCheckValue([]);
      setCompanyCheckValue((curr) =>
        curr.filter((e) => {
          return e !== value;
        })
      );
      setCheck('');
    }
  };

  const handleUserSelectValue = (e) => {
    setUserSelectValue(e.target.value);
  };

  const handleAssmtSelectValue = (e) => {
    setAssmtSelectValue(e.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let currentFormErrors = formErrors;
    switch (name) {
      case 'title':
        if (!value) {
          currentFormErrors[name] = 'Please Enter Title';
        } else {
          delete currentFormErrors[name];
        }
        break;
      case 'body':
        if (!value) {
          currentFormErrors[name] = 'Please Enter Description';
        } else {
          delete currentFormErrors[name];
        }
        break;
      case 'notificationType':
        if (!value) {
          currentFormErrors[name] = 'Please Select Notification Type';
        } else {
          delete currentFormErrors[name];
        }
        break;
      default:
        currentFormErrors = 'All fields are required';
        break;
    }
    setData({ ...data, [name]: value });
    setFormErrors(currentFormErrors);
  };

  const saveData = async () => {
    if (!data?.title) {
      return setIsSubmit(true);
    } else if (!data?.body) {
      return setIsSubmit(true);
    } else if (!data?.notificationType) {
      return setIsSubmit(true);
    } else {
      setButtonState({ value: 'saveData', isLoading: true });
      try {
        const result = await window.$http.postWithHeaders(
          'push-notification/send',
          {
            title: data?.title,
            body: data?.body,
            push_notification_action: data?.notificationType,
            companies: companyCheckValue.join(),
            user_type: userSelectValue,
            assmt_type: assmtSelectValue,
          }
        );

        if (result.code === window.$constants.STATUS.OK) {
          getHistory();
          toast.success(result?.data?.message);
        } else {
          toast.error(result?.data?.message);
          console.log('Something went wrong!');
        }
      } catch (err) {
        console.log(err);
      }
      setButtonState({ value: 'saveData', isLoading: false });
    }
  };

  const clearData = () => {
    setData(initialValue);
    setCompanyCheckValue([]);
    setUserSelectValue();
    setAssmtSelectValue();
  };

  const setCompanyValues = () => {
    for (let i = 0; i <= companiesOptions.length; i++) {
      for (let j = 0; j <= companyCheckValue.length; j++) {
        if (companiesOptions[i]?.id === +companyCheckValue[j]) {
          checkValue?.push(companiesOptions[i]?.value);
          removeDuplicates(checkValue);
        }
      }
    }
  };

  function removeDuplicates(arr) {
    return setCheckValue((prev) =>
      arr.filter((item, index) => arr.indexOf(item) === index)
    );
  }

  const pageLoadNext = (e) => {
    if (!metaData?.request?.q) {
      setIsEnterClicked(false);
    }
    clickList.push(e);
    setClickList(clickList);
    if (page < pageCount) {
      return setPage(page + 1);
    } else {
      return setPage(pageCount);
    }
  };

  const pageLoadPrev = (e) => {
    if (!metaData?.request?.q) {
      setIsEnterClicked(false);
    }
    clickList.push(e);
    setClickList(clickList);
    if (page > 1) {
      setPage(page - 1);
    } else {
      return;
    }
  };

  const handlePageNo = (e, number) => {
    if (!metaData?.request?.q) {
      setIsEnterClicked(false);
    }
    clickList.push(e);
    setClickList(clickList);
    setPage(number);
  };

  const getData = async () => {
    setIsDataLoading(true);
    try {
      const data = await window.$http.getWithHeaders(
        'companies/assessment-info'
      );
      if (data.code === window.$constants.STATUS.OK) {
        setIsDataLoading(false);
        setResult(data?.data?.company_info);
      } else {
        setIsDataLoading(false);
        console.log('Something went wrong!');
      }
    } catch (error) {
      setIsDataLoading(false);
      console.log(error);
    }
  };

  const getHistory = async () => {
    setIsDataLoading(true);
    try {
      const query = {
        page: page,
      };
      const data = await window.$http.getWithQueryAndHeaders(
        'push-notification/history',
        query
      );
      if (data.code === window.$constants.STATUS.OK) {
        setIsDataLoading(false);
        setHistoryResult(data?.data);
        setMetaData(data?.metadata);
        setPageCount(data.metadata?.page_count);
      } else {
        setIsDataLoading(false);
        console.log('Something went wrong!');
      }
    } catch (error) {
      setIsDataLoading(false);
      console.log(error);
    }
  };

  const getModalData = async (id) => {
    setIsModalLoading(true);
    try {
      const data = await window.$http.getWithHeaders(
        `push-notification/history/${id}/recipient`
      );
      if (data.code === window.$constants.STATUS.OK) {
        setIsModalLoading(false);

        setModalData(data?.data?.user_notification);
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleViewModal = (id) => {
    setShowModal(!showModal);
    getModalData(id);
  };

  const handleCloseModal = () => {
    setShowModal('');
    setModalData();
  };

  return (
    <section>
      <div className='container'>
        <div className='row'>
          <div className='col-12 mt-5'>
            <div
              style={{ backgroundColor: '#f7f7f7' }}
              className='br-12 p-4 box_shadow'
            >
              <div className='table-responsive'>
                <table className='table align-middle people-page-2' id='table'>
                  <thead>
                    <tr height='50' valign='middle' className='border-right'>
                      <th>Name</th>
                      <th>Active Users</th>
                      <th>Active Employee Assessment</th>
                      <th>Employee Assessment Pending Users</th>
                      <th>Employee Assessment Given Users</th>
                      <th>Active Organisation Assessment</th>
                      <th>Organisation Assessment Pending Users</th>
                      <th>Organisation Assessment Given Users</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isDataLoading ? (
                      result ? (
                        result.map((data, id) => (
                          <tr className='tr-group-item' key={id}>
                            <td className=''>{data?.name}</td>
                            <td className=''>{data?.active_users}</td>
                            <td className=''>{data?.active_empl_assmt}</td>
                            <td className=''>
                              {data?.empl_assessment_pending_users}
                            </td>
                            <td className=''>
                              {data?.empl_assessment_given_users}
                            </td>
                            <td className=''>{data?.active_org_assmt}</td>
                            <td className=''>
                              {data?.org_assessment_pending_users}
                            </td>
                            <td className=''>
                              {data?.org_assessment_given_users}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr className='tr-group-item'>
                          <td
                            colspan={columnLength}
                            height='80'
                            style={{ borderRadius: 'unset' }}
                            className='text-center'
                          >
                            No result found
                          </td>
                        </tr>
                      )
                    ) : (
                      <tr className='tr-group-item'>
                        <td
                          colSpan={columnLength}
                          height='80'
                          style={{ borderRadius: 'unset' }}
                          className='text-center'
                        >
                          <Spinner
                            as='i'
                            animation='border'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                          />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='bg-white p-3 br-12 mt-5'>
              <div className='d-flex'>
                <div className='filter-down'>
                  <div className='analytics-filter br-12 p-3'>
                    <div target='4' className='filter-titel'>
                      Companies{' '}
                      <i
                        className={
                          showCompanyDropDown
                            ? 'fa-solid fa-angle-down rotate'
                            : 'fa-solid fa-angle-down'
                        }
                        onClick={handleCompanyDropdown}
                      ></i>
                    </div>
                    <div className='filter-pills mt-2 '>
                      <span className='badge '>
                        {companyCheckValue?.length
                          ? checkValue?.join(', ')
                          : ''}
                      </span>
                    </div>
                  </div>
                  <div
                    id='div4'
                    className={
                      showCompanyDropDown
                        ? 'col-4 drop-opt box_shadow show'
                        : 'col-4 drop-opt box_shadow'
                    }
                  >
                    {showCompanyDropDown && (
                      <>
                        {' '}
                        {companiesOptions?.map((el, id) => (
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              name='company'
                              id={id}
                              value={el?.id}
                              checked={
                                companyCheckValue?.find(
                                  (elem) => +elem === +el?.id
                                )
                                  ? true
                                  : false
                              }
                              onChange={handleCheckState}
                            />
                            <label
                              className='form-check-label'
                              htmlFor={id}
                              value={el?.id}
                              key={id}
                            >
                              {el?.value}
                            </label>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>

                <div className='filter-down'>
                  <div className='analytics-filter br-12 p-3'>
                    <div target='4' className='filter-titel'>
                      Type of Users{' '}
                      <i
                        className={
                          showUserDropDown
                            ? 'fa-solid fa-angle-down rotate'
                            : 'fa-solid fa-angle-down'
                        }
                        onClick={handleUserDropdown}
                      ></i>
                    </div>
                    <div className='filter-pills mt-2 '>
                      <span className='badge '>
                        {userSelectValue
                          ? userAssmtStatusOptions?.find(
                              (el) => el?.id === userSelectValue
                            )?.value
                          : userValue}
                      </span>
                    </div>
                  </div>
                  <div
                    id='div4'
                    className={
                      showUserDropDown
                        ? 'col-4 drop-opt box_shadow show'
                        : 'col-4 drop-opt box_shadow'
                    }
                  >
                    {showUserDropDown && (
                      <>
                        {userAssmtStatusOptions?.map((el, id) => (
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='user'
                              id={id}
                              value={el?.id}
                              checked={
                                userSelectValue === el?.id ? true : false
                              }
                              onClick={handleUserSelectValue}
                            />

                            <label
                              className='form-check-label'
                              htmlFor={id}
                              value={el?.id}
                              key={id}
                            >
                              {el?.value}
                            </label>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>

                <div className='filter-down'>
                  <div className='analytics-filter br-12 p-3'>
                    <div target='4' className='filter-titel'>
                      Type of Assessments{' '}
                      <i
                        className={
                          showAssmtDropDown
                            ? 'fa-solid fa-angle-down rotate'
                            : 'fa-solid fa-angle-down'
                        }
                        onClick={handleAssmtDropdown}
                      ></i>
                    </div>
                    <div className='filter-pills mt-2 '>
                      <span className='badge '>
                        {assmtSelectValue
                          ? assmtTypeOptions?.find(
                              (el) => el?.id === assmtSelectValue
                            )?.value
                          : assmtValue}
                      </span>
                    </div>
                  </div>
                  <div
                    id='div4'
                    className={
                      showAssmtDropDown
                        ? 'col-4 drop-opt box_shadow show'
                        : 'col-4 drop-opt box_shadow'
                    }
                  >
                    {showAssmtDropDown && (
                      <>
                        {assmtTypeOptions?.map((el, id) => (
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='assessment'
                              id={id}
                              value={el?.id}
                              checked={
                                assmtSelectValue === el?.id ? true : false
                              }
                              onClick={handleAssmtSelectValue}
                            />
                            <label
                              className='form-check-label'
                              htmlFor={id}
                              value={el?.id}
                              key={id}
                            >
                              {el?.value}
                            </label>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='row mt-5'>
              <div className='col-5'>
                <div className='col-md-12  mb-3'>
                  <label className='asterisk'>Title</label>
                  <div className='d-flex align-items-center'>
                    <input
                      type='text'
                      name='title'
                      className={
                        (formErrors && formErrors?.title) ||
                        (isSubmit && !data?.title)
                          ? 'form-control border-danger'
                          : 'form-control'
                      }
                      placeholder='Title'
                      aria-label='title'
                      value={data?.title || ''}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className='col-md-12  mb-3'>
                  <label className='asterisk'>Body</label>
                  <div className='d-flex align-items-center'>
                    <textarea
                      type='text'
                      name='body'
                      className={
                        (formErrors && formErrors?.body) ||
                        (isSubmit && !data?.body)
                          ? 'form-control border-danger'
                          : 'form-control'
                      }
                      placeholder='Body'
                      aria-label='body'
                      value={data?.body || ''}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className='col-md-12  mb-3'>
                  <label className='asterisk'>Notification Type</label>
                  <select
                    className={
                      (formErrors && formErrors?.notificationType) ||
                      (isSubmit && !data?.notificationType)
                        ? 'form-select form-control border-danger'
                        : 'form-select form-control'
                    }
                    aria-label='notificationType'
                    name='notificationType'
                    value={data?.notificationType || ''}
                    onChange={handleChange}
                  >
                    <option value='' hidden>
                      Select
                    </option>
                    {actionOptions?.map((data, id) => (
                      <option value={data?.id} key={id}>
                        {data?.value}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='edit-profile-actions'>
                  <button
                    className={`btn btn-rp-primary save ${
                      buttonState?.isLoading &&
                      buttonState?.value === 'saveData'
                        ? 'disabled'
                        : ''
                    }`}
                    onClick={saveData}
                  >
                    {buttonState?.isLoading &&
                    buttonState?.value === 'saveData' ? (
                      <Spinner
                        as='i'
                        animation='border'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      'Send'
                    )}
                  </button>

                  <button
                    className='btn btn-rp-primary-red'
                    onClick={clearData}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>

            <div
              style={{ backgroundColor: '#f7f7f7' }}
              className='br-12 p-4 box_shadow mt-5'
            >
              <div className='table-responsive'>
                <table className='table align-middle people-page-2' id='table'>
                  <thead>
                    <tr height='50' valign='middle' className='border-right'>
                      <th>Title</th>
                      <th>Body</th>
                      <th>Notification Type</th>
                      <th>Date & Time</th>
                      <th>Sent By</th>
                      <th style={{ textAlign: 'center' }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isDataLoading ? (
                      historyResult ? (
                        historyResult.map((data, id) => (
                          <tr className='tr-group-item' key={id}>
                            <td className=''>{data?.title}</td>
                            <td className=''>{data?.body}</td>
                            <td className=''>{data?.notification_type}</td>
                            <td className=''>{data?.sent_date}</td>
                            <td className=''>{data?.sent_by}</td>
                            <td>
                              <span
                                className='btn btn-rp-primary btn-sm me-1'
                                style={{ padding: '8px 10px' }}
                                title='View Data'
                                onClick={() =>
                                  handleViewModal(
                                    data?.push_notification_audit_id
                                  )
                                }
                              >
                                <i class='flaticon-001-search'></i>
                              </span>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr className='tr-group-item'>
                          <td
                            colspan={columnLength}
                            height='80'
                            style={{ borderRadius: 'unset' }}
                            className='text-center'
                          >
                            No result found
                          </td>
                        </tr>
                      )
                    ) : (
                      <tr className='tr-group-item'>
                        <td
                          colSpan={columnLength}
                          height='80'
                          style={{ borderRadius: 'unset' }}
                          className='text-center'
                        >
                          <Spinner
                            as='i'
                            animation='border'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                          />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <ToastContainer />
          </div>
        </div>
      </div>
      <nav className='mt-5' aria-label='Page navigation example'>
        <ul className='pagination justify-content-center'>
          <li className='page-item'>
            <button
              className='page-link'
              to='#'
              aria-label='Previous'
              onClick={pageLoadPrev}
            >
              <span aria-hidden='true'>
                <i className='fa-solid fa-angle-left'></i>
              </span>
            </button>
          </li>
          {paginationRange
            ? paginationRange.map((pageNumber) => {
                if (pageNumber === DOTS) {
                  return <li className='dots'>&#8230;</li>;
                }
                return (
                  <li className='page-item' key={pageNumber}>
                    <Link
                      className={
                        page === pageNumber ? 'page-link active' : 'page-link'
                      }
                      to='#'
                      onClick={(event) => handlePageNo(event, pageNumber)}
                    >
                      {pageNumber}
                    </Link>
                  </li>
                );
              })
            : ''}
          <li className='page-item'>
            <button
              className='page-link'
              to='#'
              aria-label='Next'
              onClick={pageLoadNext}
            >
              <span aria-hidden='true'>
                <i className='fa-solid fa-angle-right'></i>
              </span>
            </button>
          </li>
        </ul>
      </nav>
      {showModal && (
        <ViewModal
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          modalData={modalData}
          isModalLoading={isModalLoading}
        />
      )}
    </section>
  );
};

export default PushNotifications;
