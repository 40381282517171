import React, { useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import AsyncSelect from '../../../../../common/Filter/AsyncSelect';
import CreatableSelect from '../../../../../common/Filter/CreatableSelect';

function EmployeeForm(props) {
  const getValue = (val) => {
    props?.getValue(val);
  };

  return (
    <Modal
      centered
      tabIndex='-1'
      size='lg'
      show={props.showModal ? true : false}
      aria-hidden={props.showModal ? true : false}
    >
      <Modal.Header>
        {!props?.items?.rp_profile_id ? (
          <h5 className='modal-title add-people-hed'>Add {(props.userState?.admin_display_text_obj?.text)}</h5>
        ) : (
          <h5 className='modal-title add-people-hed'>Edit {(props.userState?.admin_display_text_obj?.text)}</h5>
        )}
        <button
          type='button'
          className='btn-close'
          aria-label='Close'
          onClick={props.handleCloseModal}
        ></button>
      </Modal.Header>
      <Modal.Body className='pt-0'>
        <div className='profile-edit'>
          <div className='row mt-3'>
            <div className='col-md-12 col-lg-6 mb-3'>
              <label className='asterisk'>First Name</label>
              <input
                type='text'
                name='firstName'
                className={
                  (props?.formErrors && props?.formErrors?.firstName) ||
                    (props?.isSubmit && !props?.user?.firstName) ||
                    (props?.isSubmit &&
                      props?.user?.firstName &&
                      !global.nameRegex?.test(props?.user?.firstName))
                    ? 'form-control border-danger'
                    : 'form-control'
                }
                placeholder='First name'
                aria-label='First name'
                value={props?.user?.firstName || ''}
                onChange={props.handleChange}
                required
              />
            </div>
            <div className='col-md-12 col-lg-6 mb-3'>
              <label>Last Name</label>
              <input
                type='text'
                name='lastName'
                className={
                  (props?.formErrors?.lastName && props?.user?.lastName) ||
                    (props?.isSubmit &&
                      props?.user?.lastName &&
                      !global.nameRegex?.test(props?.user?.lastName))
                    ? 'form-control border-danger'
                    : 'form-control'
                }
                placeholder='Last name'
                aria-label='Last name'
                value={props?.user?.lastName || ''}
                onChange={props.handleChange}
              />
            </div>
            {props?.userState?.is_only_number_login_enabled ? (
              <h8 style={{ paddingBottom: '3px', textAlign: 'center' }}>
                Please provide atleast one of the following (email/mobile no)
              </h8>
            ) : (
              ''
            )}
            <div className='col-md-12 col-lg-6 mb-3'>
              <label className='asterisk'>E-mail</label>
              <div className='d-flex align-items-center'>
                <input
                  type='email'
                  name='email'
                  className={
                    (props?.formErrors && props?.formErrors?.email) ||
                      (props?.isSubmit &&
                        !props?.user?.email &&
                        !props?.user?.mobileNo) ||
                      (props?.isSubmit &&
                        props?.user?.email &&
                        !global.emailRegex.test(props?.user.email)) ||
                      props.message === 'The email has already been taken.' ||
                      props.message === 'The Username has already been taken.' ||
                      props.message === 'Profile already exists'
                      ? 'form-control border-danger'
                      : 'form-control'
                  }
                  placeholder='E-mail'
                  aria-label='email'
                  value={props?.user?.email || ''}
                  onChange={props.handleChange}
                  required
                />
              </div>
            </div>
            <div className='col-md-12 col-lg-6 mb-3'>
              <label
                className={
                  props?.userState?.is_only_number_login_enabled
                    ? 'asterisk'
                    : ''
                }
              >
                Mobile No.
              </label>
              <div className='d-flex align-items-center'>
                <select
                  className={`form-select form-control country-code me-3 ${props?.userState?.is_only_number_login_enabled
                    ? (props?.formErrors && props?.formErrors?.mobileCode) ||
                      (props?.isSubmit &&
                        !props?.user?.mobileCode &&
                        props?.userState?.is_only_number_login_enabled) ||
                      props?.message ===
                      'The mobile number has already been taken.' ||
                      props?.message ===
                      'This mobile number has already been taken.'
                      ? 'border-danger'
                      : ''
                    : ''
                    }`}
                  aria-label='Default select example'
                  value={props?.user?.mobileCode}
                  name='mobileCode'
                  onChange={props?.handleChange}
                  style={{ width: '34%' }}
                >
                  {props.countryOptions?.length
                    ? props.countryOptions?.map((data, id) =>
                      data?.is_available_for_mobile_verification ? (
                        <option value={data?.country_phone_code} key={id}>
                          {data?.iso_code_2} {data?.country_phone_code}
                        </option>
                      ) : (
                        ''
                      )
                    )
                    : ''}
                </select>
                <input
                  type='text'
                  name='mobileNo'
                  className={`form-control ${(props?.formErrors && props?.formErrors?.mobileNo) ||
                    (props?.isSubmit &&
                      !props?.user?.mobileNo &&
                      props?.userState?.is_only_number_login_enabled &&
                      !props?.user?.email) ||
                    (props?.isSubmit &&
                      props?.user?.mobileNo &&
                      !global.mobileRegex.test(props?.user.mobileNo)) ||
                    props?.message ===
                    'This mobile number has already been taken.'
                    ? 'border-danger'
                    : ''
                    }`}
                  placeholder='Mobile No.'
                  aria-label='mobile'
                  value={props?.user?.mobileNo}
                  onChange={props.handleChange}
                  required
                />
              </div>
            </div>
            {props?.labels?.map((el) =>
              el?.key.includes('employee') ? (
                <div className='col-md-12 col-lg-6 mb-3'>
                  <label className='asterisk'>{el?.label}</label>
                  <div className='d-flex align-items-center'>
                    <input
                      type='text'
                      name='employeeId'
                      className={
                        (props?.formErrors && props?.formErrors?.employeeId) ||
                          (props?.isSubmit && !props?.user?.employeeId) ||
                          props.message === 'Employee Id already exists'
                          ? 'form-control border-danger'
                          : 'form-control'
                      }
                      placeholder={el?.label}
                      aria-label='employee_Id'
                      value={props?.user?.employeeId || ''}
                      onChange={props.handleChange}
                      required
                    />
                  </div>
                </div>
              ) : (
                ''
              )
            )}
            <div className='col-md-12 col-lg-6 mb-3'>
              <label className='asterisk'>Date of Birth</label>
              <div className='d-flex align-items-center'>
                <select
                  className={
                    (props?.formErrors &&
                      props?.formErrors?.year &&
                      props?.isSubmit) ||
                      (props?.isSubmit &&
                        (!props?.user?.year ||
                          props?.user?.year === 'Invalid date'))
                      ? 'form-select form-control border-danger'
                      : 'form-select form-control'
                  }
                  id='year'
                  name='year'
                  onChange={props.handleChange}
                  value={props?.user?.year || ''}
                  format='MMM DD, YYYY'
                >
                  <option value='' hidden>
                    Select
                  </option>
                  {props?.Years?.map((el, id) => {
                    return (
                      <option key={id} value={el}>
                        {el}
                      </option>
                    );
                  })}
                </select>
                <select
                  className={
                    (props?.formErrors &&
                      props?.formErrors?.month &&
                      props?.isSubmit) ||
                      (props?.isSubmit &&
                        (props?.user?.month === '' ||
                          props?.user?.month < 0 ||
                          isNaN(props?.user?.month)))
                      ? 'form-select form-control mx-4 border-danger'
                      : 'form-select form-control mx-4'
                  }
                  name='month'
                  onChange={props.handleChange}
                  value={
                    props?.user?.month
                      ? props?.user?.month
                      : props?.user?.month === 0
                        ? props?.user?.month
                        : ''
                  }
                  format='MMM DD, YYYY'
                >
                  <option value='' hidden>
                    Select
                  </option>
                  {global?.months?.map((el, id) => (
                    <option key={id} value={id}>
                      {el}
                    </option>
                  ))}
                </select>
                <select
                  className={
                    (props?.formErrors &&
                      props?.formErrors?.date &&
                      props?.isSubmit) ||
                      (props?.isSubmit &&
                        (!props?.user?.date ||
                          props?.user?.date === 'Invalid date'))
                      ? 'form-select form-control border-danger'
                      : 'form-select form-control'
                  }
                  id='day'
                  name='date'
                  onChange={props.handleChange}
                  value={props?.user?.date || ''}
                  format='MMM DD, YYYY'
                >
                  <option value='' hidden>
                    Select
                  </option>
                  {props.Days?.map((el, id) => {
                    return (
                      <option key={id} value={el}>
                        {el}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className='col-md-12 col-lg-6 mb-3'>
              <label className='asterisk'>Gender</label>
              <select
                className={
                  (props?.formErrors && props?.formErrors?.genderId) ||
                    (props?.isSubmit && !props?.user.genderId)
                    ? 'form-select form-control border-danger'
                    : 'form-select form-control'
                }
                aria-label='gender'
                name='genderId'
                value={props?.user?.genderId || ''}
                onChange={props.handleChange}
              >
                <option value='' hidden>
                  Select
                </option>
                {props?.manageOptions?.gender?.map((data, id) => (
                  <option value={data?.id} key={id}>
                    {data?.value}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-md-12 col-lg-6 mb-3'>
              <label className='asterisk'>Group</label>
              <select
                className={
                  (props?.formErrors && props?.formErrors?.groupId) ||
                    (props?.isSubmit && !props?.user.groupId)
                    ? 'form-select form-control border-danger'
                    : 'form-select form-control'
                }
                aria-label='group'
                name='groupId'
                value={props?.user?.groupId || ''}
                onChange={props.handleChange}
                required
              >
                <option value='' hidden>
                  Select
                </option>
                {props.groupOptions !== undefined
                  ? props?.groupOptions?.map((data, id) => (
                    <option value={data?.rp_group_id} key={id}>
                      {data?.group_name}
                    </option>
                  ))
                  : ''}
              </select>
            </div>
            <div className='col-md-12 col-lg-6 mb-3'>
              <label className='asterisk'>Location</label>
              <select
                className={
                  (props?.formErrors && props?.formErrors?.locationId) ||
                    (props?.isSubmit && !props?.user?.locationId)
                    ? 'form-select form-control border-danger'
                    : 'form-select form-control'
                }
                aria-label='location'
                name='locationId'
                value={props?.user?.locationId || ''}
                onChange={props.handleChange}
                required
              >
                <option value='' hidden>
                  Select
                </option>

                {props.locationOptions !== undefined
                  ? props?.locationOptions?.map((data, id) => (
                    <option value={data?.rp_location_id} key={id}>
                      {data?.location_name}
                    </option>
                  ))
                  : ''}
              </select>
            </div>
            <div className={props?.userState?.is_manager_field_disabled ? 'hide' : 'col-md-12 col-lg-6 mb-3'}>
              <label className='asterisk'>Is Manager</label>
              <select
                className={
                  (props?.formErrors && props?.formErrors?.isManager) ||
                    (props?.isSubmit &&
                      !props?.user?.isManager &&
                      props?.user?.isManager !== false)
                    ? 'form-select form-control border-danger'
                    : 'form-select form-control'
                }
                aria-label='isManager'
                name='isManager'
                value={
                  props?.user?.isManager === 'Yes' ||
                    props?.user?.isManager === true
                    ? 'Yes'
                    : props?.user?.isManager === 'No' ||
                      props?.user?.isManager === false
                      ? 'No'
                      : ''
                }
                onChange={props.handleChange}
              >
                <option value='' hidden>
                  Select
                </option>
                {global.managerData?.map((data, id) => (
                  <option value={data?.value} key={id}>
                    {data?.value}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-md-12 col-lg-6 mb-3'>
              <label className='asterisk'>Country</label>
              <select
                className={
                  props?.isSubmit && !props?.countryVal
                    ? 'form-select form-control border-danger'
                    : 'form-select form-control'
                }
                aria-label='country'
                name='countryId'
                value={props?.countryVal || ''}
                onChange={props.handleCountryData}
                required
              >
                <option value='' hidden>
                  Select
                </option>

                {props?.manageOptions?.country?.map((data, id) => (
                  <option value={data?.id} key={id}>
                    {data?.value}
                  </option>
                ))}
              </select>
            </div>

            {props?.userState?.is_custom_field_enabled ? (
              <>
                {props?.labels?.map((el) =>
                  el?.key.includes('designation_level') ? (
                    <div className='col-md-12 col-lg-6 mb-3'>
                      <label className='asterisk'>{el?.label}</label>
                      {/* <AsyncSelect
                        options={props?.manageOptions?.designation_level}
                        getValue={getValue}
                        label={el?.label}
                        designationLevel={props?.designationLevel}
                        isSubmit={props?.isSubmit}
                      /> */}
                      <CreatableSelect
                        options={props?.manageOptions?.designation_level}
                        getValue={getValue}
                        label={el?.label}
                        designationLevel={props?.designationLevel}
                        isSubmit={props?.isSubmit}
                      />
                    </div>
                  ) : (
                    ''
                  )
                )}

                {props?.labels?.map((el) =>
                  el?.key.includes('dept_discipline') ? (
                    <div className='col-md-12 col-lg-6 mb-3'>
                      <label className='asterisk'>{el?.label}</label>
                      {/* <AsyncSelect
                        options={props?.manageOptions?.dept_discipline}
                        getValue={getValue}
                        label={el?.label}
                        deptDiscipline={props?.deptDiscipline}
                        isSubmit={props?.isSubmit}
                      /> */}
                      <CreatableSelect
                        options={props?.manageOptions?.dept_discipline}
                        getValue={getValue}
                        label={el?.label}
                        deptDiscipline={props?.deptDiscipline}
                        isSubmit={props?.isSubmit}
                      />
                    </div>
                  ) : (
                    ''
                  )
                )}
              </>
            ) : (
              ''
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {!props?.items?.rp_profile_id ? (
          <button
            type='button'
            className={`btn btn-rp-primary ${props?.buttonState?.isLoading &&
              props?.buttonState?.value === 'saveEmployee'
              ? 'disabled'
              : ''
              }`}
            onClick={() => props.saveEmployee()}
          >
            {props?.buttonState?.isLoading &&
              props?.buttonState?.value === 'saveEmployee' ? (
              <Spinner
                as='i'
                animation='border'
                size='sm'
                role='status'
                aria-hidden='true'
              />
            ) : (
              'Save'
            )}
          </button>
        ) : (
          <button
            type='button'
            className={`btn btn-rp-primary ${props?.buttonState?.isLoading &&
              props?.buttonState?.value === 'updateEmployee'
              ? 'disabled'
              : ''
              }`}
            aria-label='Close'
            onClick={() => props.updateEmployee(props?.items.rp_profile_id)}
          >
            {props?.buttonState?.isLoading &&
              props?.buttonState?.value === 'updateEmployee' ? (
              <Spinner
                as='i'
                animation='border'
                size='sm'
                role='status'
                aria-hidden='true'
              />
            ) : (
              'Save'
            )}
          </button>
        )}
        {props?.message ? <p className='text-danger'>{props?.message}</p> : ''}
      </Modal.Footer>
    </Modal>
  );
}

export default EmployeeForm;
