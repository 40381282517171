import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import CompanyRadioFilter from '../../../../../../common/Filter/CompanyRadioFilter';

const AdminFilter = () => {
  const userData = useSelector((state) => state?.user);
  const [companiesOptions, setCompaniesOptions] = useState();
  const [isAdmin, setIsAdmin] = useState();

  useEffect(() => {
    if (
      userData?.rp_gbl_company_type_id ===
      window.$enums.CompanyTypeId.CompanyAdmin
    ) {
      setIsAdmin(true);
      getCompaniesOptions();
    } else {
      setIsAdmin(false);
    }
  }, [userData]);

  const getCompaniesOptions = async () => {
    try {
      const result = await window.$http.getWithHeaders(
        `${window.$utility.adminUrl(userData?.rp_gbl_company_type_id)}companies`
      );

      if (result.code === window.$constants.STATUS.OK) {
        let arr = [];
        result?.data?.company_data?.map((el) =>
          el?.is_accessible_in_analytics ? arr.push(el) : ''
        );
        return setCompaniesOptions(arr);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {isAdmin ? (
        <div className='d-flex justify-content-end mb-3'>
          <div className='col-3'>
            <CompanyRadioFilter options={companiesOptions} />
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default AdminFilter;
