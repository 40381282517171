import { SAVECOMPANYTYPEID, REMOVECOMPANYTYPEID } from './types';

const INITIAL_STATE = {};

const companyTypeId = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SAVECOMPANYTYPEID: {
      return {
        ...state,
        payload,
      };
    }
    case REMOVECOMPANYTYPEID: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default companyTypeId;
