// import React from 'react';
// import ReactFC from 'react-fusioncharts';
// import FusionCharts from 'fusioncharts';
// import Area2D from 'fusioncharts/fusioncharts.charts';
// import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
// ReactFC.fcRoot(FusionCharts, Area2D, FusionTheme);
// import React, { useRef, useEffect } from 'react';
// import { Chart, registerables } from 'chart.js';

// Chart.register(...registerables);

// const AreaChart = (props) => {
//   const chartConfigs = {
//     type: props?.type,
//     renderAt: 'chart-enthusiasm',
//     width: '100%',
//     height: '350',
//     dataFormat: 'json',
//     dataSource: {
//       chart: {
//         numberSuffix: '%',
//         yAxisMaxValue: '100',
//         showPercentValues: 1,
//         showTooltip: 1,
//         showPercentInTooltip: 1,
//         decimals: '1',
//         plotGradientColor: props?.plotGradientColor,
//         usePlotGradientColor: '1',
//         palettecolors: props?.palettecolors,
//         plotFillAngle: '90',
//         canvasPadding: '30',
//         anchorRadius: 6,
//         anchorBorderThickness: 3,
//         anchorBorderColor: props?.palettecolors,
//         theme: 'fusion',
//       },
//       // data
//       data: props?.data,

//       trendlines: [
//         {
//           line: [props?.trendlines],
//         },
//       ],
//     },
//   };
//   return <ReactFC {...chartConfigs} />;
// };
import React, { useRef, useEffect } from 'react';
import { Chart, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

// Register Chart.js components and annotation plugin
Chart.register(...registerables, annotationPlugin);

const AreaChart = (props) => {
  const chartRef = useRef(null);
  let gradientColor = 'rgba(255, 255, 255, 0.3)';
  if (props?.palettecolors?.toUpperCase() === '#E02726') {
    gradientColor = 'rgba(163, 0, 6, 0.3)';
  } else if (props?.palettecolors?.toUpperCase() === '#00A39D') {
    gradientColor = 'rgba(0, 163, 157, 0.5)';
  }

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');
    const areaChart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: props?.data?.map((item) => item.label) || [],
        datasets: [
          {
            label: props?.label || 'Dataset',
            data: props?.data?.map((item) => item.value) || [],
            backgroundColor: gradientColor,
            borderColor: props?.palettecolors || '#00A39D',
            borderWidth: 2,
            fill: true,
            pointRadius: 6,
            pointBorderWidth: 3,
            pointBorderColor: props?.palettecolors || '#00A39D',
            pointBackgroundColor: '#fff',
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
            min: 0,
            max: 100,
            ticks: {
              stepSize: 20,
              callback: function (value) {
                return value + '%';
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display:false,
          },
          tooltip: {
            callbacks: {
              label: function (tooltipItem) {
                return tooltipItem.raw + '%';
              },
            },
          },
          annotation: {
            annotations: {
              trendline: {
                type: 'line',
                yMin: parseFloat(props.trendlines.startvalue) || 0,
                yMax: parseFloat(props.trendlines.startvalue) || 0,
                borderColor: '#000',
                borderWidth: 1,
                label: {
                  display: true,
                  content: props.trendlines.displayvalue.replace(/<br\/?>/g,'\n'),
                  position: props.trendlines.valueOnRight === '1' ? 'center' : 'start',
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  color: '#000',
                  font: {
                    size: 14,
                    weight: 'bold',
                  },
                  padding: 8,
                  textAlign: 'center',
                },
              },
            },
          },
        },
      },
    });

    return () => {
      areaChart.destroy();
    };
  }, [props, gradientColor]);

  return (
    <div style={{ width: '100%', height: '350px' }}>
      <canvas ref={chartRef}></canvas>
    </div>
  );
};

export default AreaChart;
