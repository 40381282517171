import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Footer from '../../../../../../../layout/Components/Footer';
import LoadingSpinner from '../../../../../../../common/LoadingSpinner';
import IFrame from './IFrame';

const UnAuthenticatedDailyLinks = () => {
  const location = useLocation();
  const loc = location.pathname.split('/');
  const [contentData, setContentData] = useState();
  const assmtContentUniqueHash = loc[4];
  const [assmtContentUniqueHashId, setAssmtContentUniqueHashId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [sliceData, setSliceData] = useState('');
  const [relatableVideos, setRelatableVideos] = useState([]);
  const [resultVideo, setResultVideo] = useState();
  const [message, setMessage] = useState('');
  const authState = useSelector((state) => state?.auth);

  useEffect(() => {
    const getContentData = async () => {
      setIsLoading(true);
      setAssmtContentUniqueHashId(assmtContentUniqueHash);
      try {
        if (assmtContentUniqueHash) {
          const result = await window.$http.getWithHeaders(
            `content/assmt/external/${assmtContentUniqueHash}`
          );

          if (result.code === window.$constants.STATUS.OK) {
            setContentData(result);
            setIsLoading(false);
            setMessage(result?.data?.message);
          } else {
            console.log(result);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    getContentData();
  }, [assmtContentUniqueHash]);

  useEffect(() => {
    setResultVideo(
      contentData?.data?.relatable?.find(
        (el) => el?.assmt_content_unique_hash === assmtContentUniqueHashId
      )
    );
    var index = contentData?.data?.relatable?.findIndex(
      (el) => el?.assmt_content_unique_hash === assmtContentUniqueHashId
    );

    if (index > -1) {
      contentData?.data?.relatable?.splice(index, 1);
    }
    setRelatableVideos(contentData?.data?.relatable);
  }, [assmtContentUniqueHashId, contentData?.data]);

  const handleVideos = async (id) => {
    setAssmtContentUniqueHashId(id);
    contentData?.data?.relatable?.push(resultVideo);
    setSliceData(
      relatableVideos?.find((data) => data.assmt_content_unique_hash === id)
    );
    window.scrollTo({ top: 100, behavior: 'smooth' });
  };

  return (
    <>
      <header className='bg-white px-0 px-lg-4'>
        <div className='row py-3 m-0 px-3 px-lg-4'>
          <div className='header px-0'>
            <div className='great'>
              <div className='profile-mob'>
                <div className='profile-icon me-2'>
                  {window.$utility.getInitials(contentData?.data?.first_name)}
                </div>
              </div>
              <div>
                <h5 className='user-name primary-color'>
                  Hello, {contentData?.data?.first_name}{' '}
                </h5>
                <p>Welcome to Resiliency Program</p>
              </div>
            </div>

            <div className='col-md-3 user-options'>
              <div className='client-icon me-3'>
                <img
                  src={`${
                    process.env.REACT_APP_API_URL
                  }media/company/${window.$utility.getSubDomain()}`}
                  alt='client logo'
                />
              </div>
              {!authState?.isAuthenticated ? (
                <div className='user-info d-flex align-items-center me-3'>
                  <Link
                    style={{ minWidth: '100px' }}
                    to='/login'
                    className='btn btn-rp-primary'
                  >
                    Login
                  </Link>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </header>

      <main>
        <section>
          <div className='container'>
            <div className='row gx-0 gx-lg-5'>
              {sliceData ? (
                <IFrame
                  resultData={sliceData}
                  assmtContentUniqueHash={assmtContentUniqueHash}
                />
              ) : contentData?.data ? (
                <IFrame
                  resultData={contentData?.data}
                  assmtContentUniqueHash={assmtContentUniqueHash}
                />
              ) : isLoading ? (
                <div className='centered' style={{ marginLeft: '1rem' }}>
                  <LoadingSpinner />
                </div>
              ) : (
                contentData?.data === null && (
                  <div className='row centered'>
                    <div className='mb-4 mb-lg-8'>
                      <h1 className='section-heading rp-primary-dark-g'>
                        Sorry, this content is no longer available.
                      </h1>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </section>
      </main>

      <Footer
        addClass={contentData?.data ? '' : 'fixed-bottom'}
        version={contentData?.metadata?.web_curr_version}
      />
    </>
  );
};

export default UnAuthenticatedDailyLinks;
