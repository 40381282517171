import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import AsyncSelect from '../../../../../common/Filter/AsyncSelect';
import CreatableSelect from '../../../../../common/Filter/CreatableSelect';

const ClinicalForm = (props) => {
  const getValue = (val) => {
    props?.getValue(val);
  };
  return (
    <Modal
      centered
      tabIndex='-1'
      size='lg'
      show={props.showModal ? true : false}
      aria-hidden={props.showModal ? true : false}
    >
      <Modal.Header>
        {!props?.items?.rp_profile_id ? (
          <h5 className='modal-title add-people-hed'>Add People</h5>
        ) : (
          <h5 className='modal-title add-people-hed'>Edit People</h5>
        )}
        <button
          type='button'
          className='btn-close'
          aria-label='Close'
          onClick={props.handleCloseModal}
        ></button>
      </Modal.Header>
      <Modal.Body className='pt-0'>
        <div className='profile-edit'>
          <div className='row mt-3'>
            <div className='col-md-12 col-lg-6 mb-3'>
              <label className='asterisk'>First Name</label>
              <input
                type='text'
                name='firstName'
                className={
                  (props?.formErrors && props?.formErrors?.firstName) ||
                  (props?.isSubmit && !props?.user?.firstName)
                    ? 'form-control border-danger'
                    : 'form-control'
                }
                placeholder='First name'
                aria-label='First name'
                value={props?.user?.firstName || ''}
                onChange={props.handleChange}
              />
            </div>
            <div className='col-md-12 col-lg-6 mb-3'>
              <label>Last Name</label>
              <input
                type='text'
                name='lastName'
                className={
                  props?.formErrors?.lastName && props?.user?.lastName
                    ? 'form-control border-danger'
                    : 'form-control'
                }
                placeholder='Last name'
                aria-label='Last name'
                value={props?.user?.lastName || ''}
                onChange={props.handleChange}
              />
            </div>
            {props?.userState?.is_only_number_login_enabled ? (
              <h8 style={{ paddingBottom: '3px', textAlign: 'center' }}>
                Please provide atleast one of the following (email/mobile no)
              </h8>
            ) : (
              ''
            )}
            <div className='col-md-12 col-lg-6 mb-3'>
              <label className='asterisk'>E-mail</label>
              <div className='d-flex align-items-center'>
                <input
                  type='email'
                  name='email'
                  className={
                    (props?.formErrors && props?.formErrors?.email) ||
                    (props?.isSubmit &&
                      !props?.user?.email &&
                      !props?.user?.mobileNo) ||
                    (props?.isSubmit &&
                      props?.user?.email &&
                      !global.emailRegex.test(props?.user.email)) ||
                    props.message === 'The email has already been taken.' ||
                    props.message === 'The Username has already been taken.' ||
                    props.message === 'Profile already exists'
                      ? 'form-control border-danger'
                      : 'form-control'
                  }
                  placeholder='E-mail'
                  aria-label='email'
                  value={props?.user?.email || ''}
                  onChange={props.handleChange}
                />
              </div>
            </div>
            <div className='col-md-12 col-lg-6 mb-3'>
              <label
                className={
                  props?.userState?.is_only_number_login_enabled
                    ? 'asterisk'
                    : ''
                }
              >
                Mobile No.
              </label>
              <div className='d-flex align-items-center'>
                <select
                  className={`form-select form-control country-code me-3 ${
                    props?.userState?.is_only_number_login_enabled
                      ? (props?.formErrors && props?.formErrors?.mobileCode) ||
                        (props?.isSubmit &&
                          !props?.user?.mobileCode &&
                          props?.userState?.is_only_number_login_enabled) ||
                        props?.message ===
                          'The mobile number has already been taken.' ||
                        props?.message ===
                          'This mobile number has already been taken.'
                        ? 'border-danger'
                        : ''
                      : ''
                  }`}
                  aria-label='Default select example'
                  value={props?.user?.mobileCode}
                  name='mobileCode'
                  onChange={props?.handleChange}
                  style={{ width: '34%' }}
                >
                  {props.countryOptions?.length
                    ? props.countryOptions?.map((data, id) =>
                        data?.is_available_for_mobile_verification ? (
                          <option value={data?.country_phone_code} key={id}>
                            {data?.iso_code_2} {data?.country_phone_code}
                          </option>
                        ) : (
                          ''
                        )
                      )
                    : ''}
                </select>
                <input
                  type='mobileNo'
                  name='mobileNo'
                  className={`form-control ${
                    (props?.formErrors && props?.formErrors?.mobileNo) ||
                    (props?.isSubmit &&
                      !props?.user?.mobileNo &&
                      props?.userState?.is_only_number_login_enabled &&
                      !props?.user?.email) ||
                    (props?.isSubmit &&
                      props?.user?.mobileNo &&
                      !global.mobileRegex.test(props?.user.mobileNo)) ||
                    props?.message ===
                      'This mobile number has already been taken.'
                      ? 'border-danger'
                      : ''
                  }`}
                  placeholder='Mobile No.'
                  aria-label='mobile'
                  value={props?.user?.mobileNo}
                  onChange={props.handleChange}
                  required
                />
              </div>
            </div>
            {props?.labels?.map((el) =>
              el?.key.includes('employee') ? (
                <div className='col-md-12 col-lg-6 mb-3'>
                  <label>{el?.label}</label>
                  <div className='d-flex align-items-center'>
                    <input
                      type='text'
                      name='employeeId'
                      className={
                        props.message === 'Client Id/Cell No. already exists'
                          ? 'form-control border-danger'
                          : 'form-control'
                      }
                      placeholder={el?.label}
                      aria-label='employee_Id'
                      value={props?.user?.employeeId || ''}
                      onChange={props.handleChange}
                      required
                    />
                  </div>
                </div>
              ) : (
                ''
              )
            )}
            <div className='col-md-12 col-lg-6 mb-3'>
              <label>Date of Birth</label>

              <div className='d-flex align-items-center'>
                <select
                  className={
                    !props?.items?.rp_profile_id
                      ? props?.formErrors &&
                        props?.formErrors?.year &&
                        props?.isSubmit
                        ? 'form-select form-control border-danger'
                        : 'form-select form-control'
                      : (props?.formErrors &&
                          props?.formErrors?.year &&
                          props?.isSubmit) ||
                        (props?.isSubmit &&
                          props?.formErrors?.year &&
                          (!props?.user?.year ||
                            props?.user?.year === 'Invalid date'))
                      ? 'form-select form-control border-danger'
                      : 'form-select form-control'
                  }
                  id='year'
                  name='year'
                  onChange={props.handleChange}
                  value={props?.user?.year || ''}
                  format='MMM DD, YYYY'
                >
                  <option value='' hidden>
                    Select
                  </option>
                  {props?.Years.map((el, id) => {
                    return (
                      <option key={id} value={el}>
                        {el}
                      </option>
                    );
                  })}
                </select>

                <select
                  className={
                    !props?.items?.rp_profile_id
                      ? props?.formErrors &&
                        props?.formErrors?.month &&
                        props?.isSubmit
                        ? 'form-select form-control mx-4 border-danger'
                        : 'form-select form-control mx-4'
                      : (props?.formErrors &&
                          props?.formErrors?.month &&
                          props?.isSubmit) ||
                        (props?.isSubmit &&
                          props?.formErrors?.month &&
                          (props?.user?.month === '' ||
                            props?.user?.month < 0 ||
                            isNaN(props?.user?.month)))
                      ? 'form-select form-control mx-4 border-danger'
                      : 'form-select form-control mx-4'
                  }
                  name='month'
                  onChange={props.handleChange}
                  value={
                    props?.user?.month
                      ? props?.user?.month
                      : props?.user?.month === 0
                      ? props?.user?.month
                      : ''
                  }
                  format='MMM DD, YYYY'
                >
                  <option value='' hidden>
                    Select
                  </option>
                  {global?.months?.map((el, id) => (
                    <option key={id} value={id}>
                      {el}
                    </option>
                  ))}
                </select>
                <select
                  className={
                    !props?.items?.rp_profile_id
                      ? props?.formErrors &&
                        props?.formErrors?.date &&
                        props?.isSubmit
                        ? 'form-select form-control border-danger'
                        : 'form-select form-control'
                      : (props?.formErrors &&
                          props?.formErrors?.date &&
                          props?.isSubmit) ||
                        (props?.isSubmit &&
                          props?.formErrors?.date &&
                          (!props?.user?.date ||
                            props?.user?.date === 'Invalid date'))
                      ? 'form-select form-control border-danger'
                      : 'form-select form-control'
                  }
                  id='day'
                  name='date'
                  onChange={props.handleChange}
                  value={props?.user?.date ? props?.user?.date : ''}
                  format='MMM DD, YYYY'
                >
                  <option value='' hidden>
                    Select
                  </option>
                  {(props?.user?.month &&
                    isNaN(props?.user?.month) &&
                    props?.user?.month !== 'Invalid date') ||
                  (props?.user?.year && props?.user?.year !== 'Invalid date')
                    ? props.Days?.map((el, id) => {
                        return (
                          <option key={id} value={el}>
                            {el}
                          </option>
                        );
                      })
                    : ''}
                </select>
              </div>
            </div>
            <div className='col-md-12 col-lg-6 mb-3'>
              <label>Gender</label>
              <select
                className={
                  props?.formErrors && props?.formErrors?.genderId
                    ? 'form-select form-control border-danger'
                    : 'form-select form-control'
                }
                aria-label='gender'
                name='genderId'
                value={props?.user?.genderId || ''}
                onChange={props.handleChange}
              >
                <option value='' hidden>
                  Select
                </option>
                {props?.manageOptions?.gender?.map((data, id) => (
                  <option value={data?.id} key={id}>
                    {data?.value}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-md-12 col-lg-6 mb-3'>
              <label>Country</label>
              <select
                className='form-select form-control'
                aria-label='country'
                name='countryId'
                value={props?.countryVal || ''}
                onChange={props.handleCountryData}
              >
                <option value='' hidden>
                  Select
                </option>

                {props?.manageOptions?.country?.map((data, id) => (
                  <option value={data?.id} key={id}>
                    {data?.value}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-md-12 col-lg-6 mb-4'>
              <label>Occupation</label>
              <select
                className={
                  props?.formErrors && props?.formErrors?.occupation
                    ? 'form-select form-control border-danger'
                    : 'form-select form-control'
                }
                aria-label='occupation'
                value={props?.user?.occupation || ''}
                name='occupation'
                onChange={props.handleChange}
                disabled={
                  props?.items?.rp_profile_id && props?.userLoggedIn
                    ? true
                    : false
                }
              >
                <option hidden value=''>
                  Select
                </option>
                {props?.manageOptions?.occupation?.map((data, id) => (
                  <option value={data?.id} key={id}>
                    {data?.value}
                  </option>
                ))}
              </select>
            </div>
            {props?.userState?.is_custom_field_enabled ? (
              <>
                {props?.labels?.map((el) =>
                  el?.key.includes('designation_level') ? (
                    <div className='col-md-12 col-lg-6 mb-3'>
                      <label className='asterisk'>{el?.label}</label>
                      {/* <AsyncSelect
                        options={props?.manageOptions?.designation_level}
                        getValue={getValue}
                        label={el?.label}
                        designationLevel={props?.designationLevel}
                        isSubmit={props?.isSubmit}
                      /> */}
                      <CreatableSelect
                        options={props?.manageOptions?.designation_level}
                        getValue={getValue}
                        label={el?.label}
                        designationLevel={props?.designationLevel}
                        isSubmit={props?.isSubmit}
                      />
                    </div>
                  ) : (
                    ''
                  )
                )}

                {props?.labels?.map((el) =>
                  el?.key.includes('dept_discipline') ? (
                    <div className='col-md-12 col-lg-6 mb-3'>
                      <label className='asterisk'>{el?.label}</label>
                      {/* <AsyncSelect
                        options={props?.manageOptions?.dept_discipline}
                        getValue={getValue}
                        label={el?.label}
                        deptDiscipline={props?.deptDiscipline}
                        isSubmit={props?.isSubmit}
                      /> */}
                      <CreatableSelect
                        options={props?.manageOptions?.dept_discipline}
                        getValue={getValue}
                        label={el?.label}
                        deptDiscipline={props?.deptDiscipline}
                        isSubmit={props?.isSubmit}
                      />
                    </div>
                  ) : (
                    ''
                  )
                )}
              </>
            ) : (
              ''
            )}
            {/* {props?.userState?.is_custom_field_enabled ? (
              <>
                {props?.labels?.map((el) =>
                  el?.key.includes('designation_level') ? (
                    <div className='col-md-12 col-lg-6 mb-3'>
                      <label className='asterisk'>{el?.label}</label>
                      <select
                        className={
                          (props?.formErrors &&
                            props?.formErrors?.designation_level) ||
                          (props?.isSubmit && !props?.user?.designation_level)
                            ? 'form-select form-control border-danger'
                            : 'form-select form-control'
                        }
                        aria-label='designation_level'
                        name='designation_level'
                        value={props?.user?.designation_level || ''}
                        onChange={props.handleChange}
                        required
                      >
                        <option value='' hidden>
                          Select
                        </option>

                        {props?.manageOptions?.designation_level?.map(
                          (data, id) => (
                            <option value={data?.id} key={id}>
                              {data?.value}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  ) : (
                    ''
                  )
                )}

                {props?.labels?.map((el) =>
                  el?.key.includes('dept_discipline') ? (
                    <div className='col-md-12 col-lg-6 mb-3'>
                      <label className='asterisk'>{el?.label}</label>
                      <select
                        className={
                          (props?.formErrors &&
                            props?.formErrors?.dept_discipline) ||
                          (props?.isSubmit && !props?.user?.dept_discipline)
                            ? 'form-select form-control border-danger'
                            : 'form-select form-control'
                        }
                        aria-label='dept_discipline'
                        name='dept_discipline'
                        value={props?.user?.dept_discipline || ''}
                        onChange={props.handleChange}
                        required
                      >
                        <option value='' hidden>
                          Select
                        </option>

                        {props?.manageOptions?.dept_discipline?.map(
                          (data, id) => (
                            <option value={data?.id} key={id}>
                              {data?.value}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  ) : (
                    ''
                  )
                )}
              </>
            ) : (
              ''
            )} */}
            <div className='col-md-12 col-lg-6 mb-3'>
              <label>Result Emails</label>{' '}
              <span style={{ fontSize: '10px' }}>
                (For multiple emails, enter comma separated list.)
              </span>
              <div className='d-flex align-items-center'>
                <input
                  type='text'
                  name='resultEmails'
                  className='form-control'
                  placeholder='Result Emails'
                  aria-label='result_Emails'
                  value={props?.resultEmails || ''}
                  onChange={props.handleReportEmails}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {!props?.items?.rp_profile_id ? (
          <button
            type='button'
            className={`btn btn-rp-primary ${
              props?.buttonState?.isLoading &&
              props?.buttonState?.value === 'savePeople'
                ? 'disabled'
                : ''
            }`}
            aria-label='Close'
            onClick={() => props.savePeople(props?.items.rp_profile_id)}
          >
            {props?.buttonState?.isLoading &&
            props?.buttonState?.value === 'savePeople' ? (
              <Spinner
                as='i'
                animation='border'
                size='sm'
                role='status'
                aria-hidden='true'
              />
            ) : (
              'Save'
            )}
          </button>
        ) : (
          <button
            type='button'
            className={`btn btn-rp-primary ${
              props?.buttonState?.isLoading &&
              props?.buttonState?.value === 'updatePeople'
                ? 'disabled'
                : ''
            }`}
            aria-label='Close'
            onClick={() => props.updatePeople(props?.items.rp_profile_id)}
          >
            {props?.buttonState?.isLoading &&
            props?.buttonState?.value === 'updatePeople' ? (
              <Spinner
                as='i'
                animation='border'
                size='sm'
                role='status'
                aria-hidden='true'
              />
            ) : (
              'Save'
            )}
          </button>
        )}
        {props?.message ? <p className='text-danger'>{props?.message}</p> : ''}
      </Modal.Footer>
    </Modal>
  );
};

export default ClinicalForm;
