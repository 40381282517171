import React, { useEffect, useState } from 'react';
import '../../../config';
import Header from '../../../layout/Components/Header';
import Footer from '../../../layout/Components/Footer';
import ProfileForm from '../../../common/Form';
import { isEmpty } from 'lodash';
import LoadingSpinner from '../../../common/LoadingSpinner';

const EditProfile = () => {
  const [profileData, setProfileData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getProfileData();
  }, []);

  const getProfileData = async () => {
    setIsLoading(true);
    try {
      const result = await window.$http.getWithHeaders('v2/my_profile');
      if (result.code === window.$constants.STATUS.OK) {
        setIsLoading(false);
        setProfileData(result?.data);
      } else {
        setIsLoading(false);
        console.log('Something went wrong!');
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const fullName =
    (!isEmpty(profileData?.first_name) ? profileData?.first_name : '') +
    ' ' +
    (!isEmpty(profileData?.last_name) ? profileData?.last_name : '');

  return (
    <>
      <Header />
      <main>
        <section>
          <div className='container'>
            <div className='row'>
              <div className='bg-white br-12 profile-edit box_shadow mt-5 p-4 p-xl-5'>
                {isLoading ? (
                  <div className='centered' style={{ marginLeft: '1rem' }}>
                    <LoadingSpinner />
                  </div>
                ) : (
                  <>
                    {/* <div className='d-flex align-items-end me-2'>
                      <div className='profile-icon'>
                        {window.$utility.getInitials(fullName)}
                      </div>
                    </div> */}
                    <ProfileForm
                      profileData={profileData}
                      navigateTo='/view-profile'
                      value='edit-profile'
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default EditProfile;
