import React from 'react';
import Skeleton from 'react-loading-skeleton';

const ChartResultSkeleton = (props) => {
  return (
    <>
      {props?.index & 1 ? (
        <div className='analytic-card position-relative row mb-5'>
          <div className='dots-styles-top'></div>
          <div className='col-md-12 col-lg-5 pe-0 pe-lg-4'>
            <div className='result-sub-hed chart1'>
              {' '}
              <Skeleton width='70%' height='30px' />
            </div>
            <div className='result-titel gradient-text'>
              <Skeleton width='60%' height='30px' />
            </div>
            <div className='result-dic gradient-text fw-normal'>
              <Skeleton width='90%' height='90px' />
            </div>
            <div className='trend-button mt-5'>
              <div className='more-titel'>
                <Skeleton width='70%' />
              </div>
              <div className='d-flex'>
                <Skeleton className='me-3' width='150px' height='50px' />
                <Skeleton
                  className='agegender-btn position-relative'
                  width='150px'
                  height='50px'
                />
              </div>
            </div>
          </div>
          <div className='trend-graph bg-white col-md-12 col-lg-7 mt-4 mt-lg-0 br-12 z-1'>
            <div className='historic'>
              <Skeleton width='90%' height='350px' />
            </div>
          </div>
        </div>
      ) : (
        <div className='analytic-card position-relative row mb-5'>
          <div className='trend-graph bg-white col-md-12 col-lg-7 mt-4 mt-lg-0 br-12 z-1'>
            <div className='historic'>
              <Skeleton width='90%' height='350px' />
            </div>
          </div>
          <div className='dots-styles-top'></div>
          <div className='col-md-12 col-lg-5 pe-0 pe-lg-4'>
            <div className='result-sub-hed chart1'>
              {' '}
              <Skeleton width='70%' height='30px' />
            </div>
            <div className='result-titel gradient-text'>
              <Skeleton width='60%' height='30px' />
            </div>
            <div className='result-dic gradient-text fw-normal'>
              <Skeleton width='90%' height='90px' />
            </div>
            <div className='trend-button mt-5'>
              <div className='more-titel'>
                <Skeleton width='70%' />
              </div>
              <div className='d-flex'>
                <Skeleton className='me-3' width='150px' height='50px' />
                <Skeleton
                  className='agegender-btn position-relative'
                  width='150px'
                  height='50px'
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChartResultSkeleton;
