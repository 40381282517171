import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import EmployeeForm from './Components/EmployeeForm';
import ClinicalForm from './Components/ClinicalForm';
import moment from 'moment';
import { Popconfirm } from 'antd';
import { usePagination, DOTS } from '../../../hooks/Pagination/usePagination';
import { ToastContainer, toast } from 'react-toastify';
import { isEmpty, isNull, isNumber } from 'lodash';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from 'react-bootstrap/Spinner';
import Prescription from '../../../assets/images/prescription.svg';
import PrescriptionLight from '../../../assets/images/prescription-light.svg';
import { saveAdminFilters } from '../../../store/adminFilters/actions';
import LoadingSpinner from '../../../common/LoadingSpinner';
import ExcelExport from '../../../common/ExcelExport';
import CustomSelect from '../../../common/Filter/CustomSelect';

const text = 'Are you sure you want to delete?';

const imgs = [Prescription, PrescriptionLight];

const InitialState = {
  firstName: '',
  lastName: '',
  employeeId: '',
  email: '',
  groupId: '',
  locationId: '',
  isManager: '',
  genderId: '',
  month: '',
  date: '',
  year: '',
  occupation: '',
  mobileNo: '',
  mobileCode: '',
};

const InitialItemState = {
  rp_profile_id: '',
  firstName: '',
  lastName: '',
  employeeId: '',
  email: '',
  groupId: '',
  locationId: '',
  isManager: '',
  genderId: '',
  month: '',
  date: '',
  year: '',
  occupation: '',
  mobileNo: '',
  mobileCode: '',
};

const CompanyManagementPeople = (props) => {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state?.user);
  const companyState = useSelector((state) => state.companyData);
  const [resultData, setResultData] = useState();
  const [searchValue, setSearchValue] = useState('');
  const [reportEmails, setReportEmails] = useState('');
  const [countryId, setCountryId] = useState('');
  const [items, setItems] = useState(InitialItemState);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [formErrors, setFormErrors] = useState({});
  const [msg, setMsg] = useState('');
  const [groupOptions, setGroupOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [emailValue, setEmailValue] = useState('');
  const [countryValue, setCountryValue] = useState('');
  const [clickList, setClickList] = useState([]);
  const [buttonState, setButtonState] = useState({
    value: '',
    isLoading: false,
  });
  const [user, setUser] = useState(InitialState);
  const [isSubmit, setIsSubmit] = useState(false);
  const nameRegex = /^[A-Za-z][A-Za-z -]{1,20}$/;
  const siblingCount = 1;
  const paginationRange = usePagination({
    page,
    pageCount,
    siblingCount,
  });

  const tableId = document.getElementById('table');

  const columnLength = tableId?.rows[0]?.cells?.length;
  const [Years, setYears] = useState([]);
  const [Days, setDays] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rpProfileId, setRpProfileId] = useState();
  const [valueSet, setValueSet] = useState([]);
  const [reminderValueSet, setReminderValueSet] = useState([]);
  const [manageOptions, setManageOptions] = useState([]);
  const [userLoggedIn, setUserLoggedIn] = useState('');
  const [bcgImg, setBcgImg] = useState({ img: imgs[0], id: '' });
  const [isReportDownloading, setIsReportDownloading] = useState(false);
  const [isResultDownloading, setIsResultDownloading] = useState(false);
  const [reportValueSet, setReportValueSet] = useState([]);
  const [resultValueSet, setResultValueSet] = useState([]);
  const [userId, setUserId] = useState();
  const [searchPageCount, setSearchPageCount] = useState(1);
  const [isEnterClicked, setIsEnterClicked] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [metaData, setMetaData] = useState(false);
  const [countryOptions, setCountryOptions] = useState();
  const companyTypeId = useSelector((state) => state?.companyTypeId?.payload);
  const [styleChange, setStyleChange] = useState(false);
  const [labels, setLabels] = useState();
  const [dynamicPlaceholder, setDynamicPlaceholder] = useState();
  const [headerKey, setHeaderKey] = useState('name');
  const [headerClicked, setHeaderClicked] = useState(false);
  const [clickCount, setClickCount] = useState(1);
  const [exportResultData, setExportResultData] = useState();
  const [headerState, setHeaderState] = useState([]);
  const [sortDir, setSortDir] = useState('asc');
  const [designationLevel, setDesignationLevel] = useState('');
  const [designationValue, setDesignationValue] = useState('');
  const [deptDiscipline, setDeptDiscipline] = useState('');
  const [deptValue, setDeptValue] = useState('');

  useEffect(() => {
    getOccupationOptions();
    getGroupOptions();
    getLocationOptions();
    getCountryOptions();
    setYears(window.$utility.getYears());
    setDays(window.$utility.getDaysList());
  }, [companyTypeId]);

  function queryParamBuilder() {
    let query;
    if (
      userState?.rp_gbl_company_type_id ===
        window.$enums.CompanyTypeId.CompanyAdmin &&
      companyTypeId?.rp_gbl_company_type_id
    ) {
      query = {
        rp_company_id: window.$utility.setCompanyId(userState, companyTypeId),
      };
    }
    return query;
  }

  useEffect(() => {
    setReportEmails(userState?.email);
    setCountryId(userState?.rp_gbl_country_id);
    setUser({
      ...user,
    });
  }, [userState?.email, userState?.rp_gbl_country_id]);

  useEffect(() => {
    if (page && searchValue && isEnterClicked) {
      if (clickList?.length) {
        exportData(searchValue);
        return getPeopleData(searchValue);
      }
    } else {
      exportData();
      return getPeopleData();
    }
  }, [clickList?.length, isEnterClicked, companyTypeId, clickCount]);

  const pageLoadNext = (e) => {
    if (!metaData?.request?.q) {
      setIsEnterClicked(false);
    }
    clickList.push(e);
    setClickList(clickList);
    if (page < pageCount) {
      return setPage(page + 1);
    } else {
      return setPage(pageCount);
    }
  };

  const pageLoadPrev = (e) => {
    if (!metaData?.request?.q) {
      setIsEnterClicked(false);
    }
    clickList.push(e);
    setClickList(clickList);
    if (page > 1) {
      setPage(page - 1);
    } else {
      return;
    }
  };

  const handlePageNo = (e, number) => {
    if (!metaData?.request?.q) {
      setIsEnterClicked(false);
    }
    clickList.push(e);
    setClickList(clickList);
    setPage(number);
  };

  const getOccupationOptions = async () => {
    try {
      const query = {
        types: 'occupation,gender,designation-level,dept-discipline,country',
        rp_company_id: window.$utility.setCompanyId(userState, companyTypeId),
      };
      const result = await window.$http.getWithQueryAndHeaders(
        `${window.$utility.setApiUrl(userState)}lookup`,
        query
      );

      if (result.code === window.$constants.STATUS.OK) {
        return setManageOptions(result.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCountryOptions = async () => {
    try {
      const query = queryParamBuilder();
      const result = await window.$http.getWithQueryAndHeaders(
        `${window.$utility.setApiUrl(userState)}country`,
        query
      );
      if (result.code === window.$constants.STATUS.OK) {
        setCountryOptions(result?.data);
        setUser({
          ...user,
          mobileCode: result?.data?.find(
            (el) => el?.rp_gbl_country_id === userState?.rp_gbl_country_id
          )?.country_phone_code,
        });
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getGroupOptions = async () => {
    try {
      const query = queryParamBuilder();
      const result = await window.$http.getWithQueryAndHeaders(
        `${window.$utility.setApiUrl(userState)}groups`,
        query
      );
      if (result.code === window.$constants.STATUS.OK) {
        setGroupOptions(result?.data);
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getLocationOptions = async () => {
    try {
      const query = queryParamBuilder();
      const result = await window.$http.getWithQueryAndHeaders(
        `${window.$utility.setApiUrl(userState)}locations`,
        query
      );
      if (result.code === window.$constants.STATUS.OK) {
        setLocationOptions(result?.data);
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (items) {
      setUser({
        firstName: items?.firstName,
        lastName: items?.lastName,
        employeeId: items?.employeeId,
        email: items?.email,
        groupId: items?.groupId,
        locationId: items?.locationId,
        isManager: items?.isManager == null ? 'No' : items?.isManager,
        genderId: items?.genderId,
        year: items !== null || 'Invalid date' ? items?.year : '',
        month: items !== null || 'Invalid date' ? items?.month : '',
        date: items !== null || 'Invalid date' ? items?.date : '',
        occupation: items?.occupation,
        mobileNo: items?.mobileNo,
        mobileCode: items?.mobileCode
          ? items?.mobileCode
          : countryOptions?.find(
              (el) => el?.rp_gbl_country_id === userState?.rp_gbl_country_id
            )?.country_phone_code,
      });
      if (designationValue) {
        setDesignationLevel(designationValue);
      }
      if (deptValue) {
        setDeptDiscipline(deptValue);
      }
      if (emailValue) {
        setReportEmails(emailValue);
      } else {
        setReportEmails(userState?.email);
      }
      if (
        userState.rp_gbl_company_type_id !==
        window.$enums.CompanyTypeId.Clinical
      ) {
        if (countryValue) {
          setCountryId(countryValue);
        } else {
          setCountryId(userState?.rp_gbl_country_id);
        }
      } else {
        setCountryId(countryValue);
      }
    }
  }, [items, emailValue, countryValue, designationValue, deptValue]);

  const getValue = (val) => {
    if (Object.keys(val)?.includes('designation_level')) {
      return setDesignationLevel(val?.designation_level);
    } else if (Object.keys(val)?.includes('dept_discipline')) {
      return setDeptDiscipline(val?.dept_discipline);
    }
  };

  const handleItems = () => {
    setItems({
      mobileCode: countryOptions?.find(
        (el) => el?.rp_gbl_country_id === userState?.rp_gbl_country_id
      )?.country_phone_code,
    });
    setUser(InitialState);
    setEmailValue('');
    setCountryValue('');
    setDesignationValue('');
    setDesignationLevel('');
    setDeptValue('');
    setDeptDiscipline('');
    setMsg('');
    setFormErrors({});
    setShowModal(true);
    setIsSubmit(false);
  };

  const handleEditedItems = () => {
    setMsg('');
    setFormErrors({});
    setShowModal(true);
    setIsSubmit(false);
  };

  const handleDesginationLevel = (e) => {
    setDesignationLevel(e.target.value);
  };

  const handleDeptDiscipline = (e) => {
    setDeptDiscipline(e.target.value);
  };

  const handleReportEmails = (e) => {
    setReportEmails(e.target.value);
  };

  const handleCountryData = (e) => {
    setCountryId(e.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let currentFormErrors = formErrors;
    setMsg('');
    var day;
    switch (name) {
      case 'firstName':
        if (!value) {
          currentFormErrors[name] = 'Please Enter First Name';
        } else if (!nameRegex.test(value)) {
          currentFormErrors[name] = 'First Name is not valid';
        } else {
          delete currentFormErrors[name];
        }
        break;
      case 'lastName':
        if (!nameRegex.test(value)) {
          currentFormErrors[name] = 'Last Name is not valid';
        } else {
          delete currentFormErrors[name];
        }
        break;
      case 'employeeId':
        if (!value) {
          currentFormErrors[name] = 'Please Enter Employee Id';
        } else {
          delete currentFormErrors[name];
        }
        break;
      case 'email':
        if (!value) {
          currentFormErrors[name] = 'Please Enter Email';
        } else if (!global.emailRegex.test(value)) {
          currentFormErrors[name] = 'Email is not valid';
        } else {
          delete currentFormErrors[name];
        }
        break;
      case 'groupId':
        if (!value) {
          currentFormErrors[name] = 'Please Select Group';
        } else {
          delete currentFormErrors[name];
        }
        break;
      case 'locationId':
        if (!value) {
          currentFormErrors[name] = 'Please Select Location';
        } else {
          delete currentFormErrors[name];
        }
        break;
      case 'isManager':
        if (!value) {
          currentFormErrors[name] = 'Please Select Manager Field';
        } else {
          delete currentFormErrors[name];
        }
        break;
      case 'genderId':
        if (!value) {
          currentFormErrors[name] = 'Please Select Gender';
        } else {
          delete currentFormErrors[name];
        }
        break;
      case 'year':
        if (!value) {
          currentFormErrors[name] = 'Please select year.';
        } else {
          delete currentFormErrors[name];
          if (
            (!user.month || user.month === 'Invalid date') &&
            (!user.date || user.date === 'Invalid date')
          ) {
            currentFormErrors['month'] = 'Please select month';
            currentFormErrors['date'] = 'Please select date';
          } else if (!user.month) {
            currentFormErrors['month'] = 'Please select month';
          } else if (!user.date) {
            currentFormErrors['date'] = 'Please select date';
          }
        }
        break;

      case 'month':
        if (!value) {
          currentFormErrors[name] = 'Please enter select month.';
        } else {
          delete currentFormErrors[name];
          while (Days.length) {
            Days.pop();
          }
          day = window.$utility.getLastDay(user.year, value);
          for (let i = 1; i <= day; i++) {
            Days.push(i);
            setDays((prev) => Days);
          }

          if (
            (!user.date || user.date === 'Invalid date') &&
            (!user.year || user.year === 'Invalid date')
          ) {
            currentFormErrors['date'] = 'Please select date';
            currentFormErrors['year'] = 'Please select year';
          } else if (!user.date) {
            currentFormErrors['date'] = 'Please select date';
          } else if (!user.year) {
            currentFormErrors['year'] = 'Please select year';
          }
        }
        break;

      case 'date':
        if (!value) {
          currentFormErrors[name] = 'Please select date.';
        } else {
          delete currentFormErrors[name];
          if (
            (!user.month || user.month === 'Invalid date') &&
            !user.year &&
            user.year === 'Invalid date'
          ) {
            currentFormErrors['month'] = 'Please select month';
            currentFormErrors['year'] = 'Please select year';
          } else if (!user.month) {
            currentFormErrors['month'] = 'Please select month';
          } else if (!user.year) {
            currentFormErrors['year'] = 'Please select year';
          }
        }
        break;

      case 'occupation':
        if (!value) {
          currentFormErrors[name] = 'Please select an occupation.';
        } else {
          delete currentFormErrors[name];
        }
        break;

      case 'mobileCode':
        if (!value) {
          currentFormErrors[name] = 'Please select a mobile code';
        } else {
          delete currentFormErrors[name];
        }
        break;

      case 'mobileNo':
        if (!value && userState?.is_only_number_login_enabled) {
          currentFormErrors[name] = 'Please enter mobile no';
        } else if (!global.mobileRegex.test(value)) {
          currentFormErrors[name] = 'Please enter valid mobile no';
        } else {
          delete currentFormErrors[name];
        }
        break;

      default:
        currentFormErrors = 'All fields are required';
        break;
    }
    setUser({ ...user, [name]: value });
    setFormErrors(currentFormErrors);
  };

  const saveEmployee = async (id) => {
    if (!user.employeeId) {
      return setIsSubmit(true);
    } else if (!user.firstName) {
      return setIsSubmit(true);
    } else if (!nameRegex.test(user.firstName)) {
      return setIsSubmit(true);
    } else if (user?.lastName && !global.nameRegex?.test(user?.lastName)) {
      return setIsSubmit(true);
    } else if (
      userState?.is_only_number_login_enabled
        ? !user.email && !user?.mobileNo
        : !user?.email
    ) {
      return setIsSubmit(true);
    } else if (user.email && !global.emailRegex.test(user.email)) {
      return setIsSubmit(true);
    } else if (user?.mobileNo && !global.mobileRegex.test(user.mobileNo)) {
      return setIsSubmit(true);
    } else if (!user.genderId) {
      return setIsSubmit(true);
    } else if (!user.groupId) {
      return setIsSubmit(true);
    } else if (!user.locationId) {
      return setIsSubmit(true);
    } else if (!user.year) {
      return setIsSubmit(true);
    } else if (!user.month) {
      return setIsSubmit(true);
    } else if (!user.date) {
      return setIsSubmit(true);
    } else if (!user.isManager) {
      return setIsSubmit(true);
    } else if (
      userState?.is_custom_field_enabled
      && labels.some(e => e.key === 'designation_level')
      && !designationLevel
    ) {
      return setIsSubmit(true);
    } else if (userState?.is_custom_field_enabled
      && labels.some(e => e.key === 'dept_discipline')
      && !deptDiscipline
    ) {
      return setIsSubmit(true);
    } else {
      setButtonState({
        value: 'saveEmployee',
        isLoading: true,
      });
      try {
        const query = queryParamBuilder();
        if (!id) {
          const result = await window.$http.postWithQueryAndHeaders(
            `${window.$utility.setApiUrl(userState)}profile/${0}`,
            query,
            {
              employee_id: user?.employeeId,
              first_name: user?.firstName,
              last_name: user?.lastName,
              email: user?.email,
              rp_group_id: user?.groupId,
              rp_location_id: user?.locationId,
              is_manager: user?.isManager === 'Yes' ? true : false,
              rp_gbl_country_id: countryId,
              dob: moment(
                user?.date +
                  '-' +
                  (parseInt(user?.month) + 1) +
                  '-' +
                  user?.year,
                'DD-MM-YYYY'
              )
                .add(1, -'M')
                .format('YYYY-MM-DD'),
              rp_gbl_gender_id: user.genderId,
              designation_level: designationLevel,
              dept_discipline: deptDiscipline,
              mobile_country_code: user?.mobileCode ? user.mobileCode : '',
              mobile_no: user?.mobileNo ? user.mobileNo : '',
            }
          );

          if (result.code === window.$constants.STATUS.OK) {
            handleModal();
            getOccupationOptions();
            getPeopleData();
            exportData();
            toast.success(result?.message);
            setMsg('');
          } else {
            setMsg(result?.message);
            console.log('Something went wrong!');
          }
        }
      } catch (err) {
        console.log(err);
      }
      setButtonState({
        value: 'saveEmployee',
        isLoading: false,
      });
    }
  };

  const updateEmployee = async (id) => {
    if (!user.employeeId) {
      return setIsSubmit(true);
    } else if (!user.firstName) {
      return setIsSubmit(true);
    } else if (!nameRegex.test(user.firstName)) {
      return setIsSubmit(true);
    } else if (user?.lastName && !global.nameRegex?.test(user?.lastName)) {
      return setIsSubmit(true);
    } else if (
      userState?.is_only_number_login_enabled
        ? !user.email && !user?.mobileNo
        : !user?.email
    ) {
      return setIsSubmit(true);
    } else if (user?.email && !global.emailRegex.test(user.email)) {
      return setIsSubmit(true);
    } else if (user?.mobileNo && !global.mobileRegex.test(user.mobileNo)) {
      return setIsSubmit(true);
    } else if (!user.genderId) {
      return setIsSubmit(true);
    } else if (!user.groupId) {
      return setIsSubmit(true);
    } else if (
      !user.year ||
      !isNumber(parseInt(user.year) || user.year === 'Invalid date')
    ) {
      return setIsSubmit(true);
    } else if (user?.month === '' || user?.month < 0 || isNaN(user?.month)) {
      return setIsSubmit(true);
    } else if (
      !user.date ||
      !isNumber(parseInt(user.date) || user.date === 'Invalid date')
    ) {
      return setIsSubmit(true);
    } else if (!user.locationId) {
      return setIsSubmit(true);
    } else if (userState?.is_custom_field_enabled 
      && labels.some(e => e.key === 'designation_level')
      && !designationLevel) {
      return setIsSubmit(true);
    } else if (userState?.is_custom_field_enabled 
      && labels.some(e => e.key === 'dept_discipline')
      && !deptDiscipline) {
      return setIsSubmit(true);
    } else {
      setButtonState({
        value: 'updateEmployee',
        isLoading: true,
      });
      try {
        const query = queryParamBuilder();
        const result = await window.$http.postWithQueryAndHeaders(
          `${window.$utility.setApiUrl(userState)}profile/${id}`,
          query,
          {
            employee_id: user?.employeeId,
            first_name: user?.firstName,
            last_name: user?.lastName,
            email: user?.email,
            rp_group_id: user?.groupId,
            rp_location_id: user?.locationId,
            is_manager: user?.isManager,
            rp_gbl_country_id: countryId,
            dob: moment(
              user?.date + '-' + (parseInt(user?.month) + 1) + '-' + user?.year,
              'DD-MM-YYYY'
            )
              .add(1, -'M')
              .format('YYYY-MM-DD'),
            rp_gbl_gender_id: user?.genderId,
            designation_level: designationLevel,
            dept_discipline: deptDiscipline,
            mobile_country_code: user?.mobileCode ? user.mobileCode : '',
            mobile_no: user?.mobileNo ? user.mobileNo : '',
          }
        );
        if (result.code === window.$constants.STATUS.CONFLICT) {
          setMsg(result?.message);
        }
        if (result.code === window.$constants.STATUS.OK) {
          if (searchValue) {
            getPeopleData(searchValue);
            exportData(searchValue);
          } else {
            getPeopleData();
            exportData();
          }
          setMsg('');
          handleModal();
          getOccupationOptions();
          toast.success(result?.message);
        } else {
          setMsg(result?.message);
          console.log('Something went wrong!');
        }
      } catch (err) {
        console.log(err);
      }
      setButtonState({
        value: 'updateEmployee',
        isLoading: false,
      });
    }
  };

  const savePeople = async (id) => {
    if (!user.firstName) {
      return setIsSubmit(true);
    } else if (!nameRegex.test(user.firstName)) {
      return setIsSubmit(true);
    } else if (user?.lastName && !global.nameRegex?.test(user?.lastName)) {
      return setIsSubmit(true);
    } else if (
      userState?.is_only_number_login_enabled
        ? !user.email && !user?.mobileNo
        : !user?.email
    ) {
      return setIsSubmit(true);
    } else if (user?.email && !global.emailRegex.test(user.email)) {
      return setIsSubmit(true);
    } else if (user?.mobileNo && !global.mobileRegex.test(user.mobileNo)) {
      return setIsSubmit(true);
    } else if (userState?.is_custom_field_enabled && !designationLevel) {
      return setIsSubmit(true);
    } else if (userState?.is_custom_field_enabled && !deptDiscipline) {
      return setIsSubmit(true);
    } else {
      setButtonState({
        value: 'savePeople',
        isLoading: true,
      });
      try {
        const query = queryParamBuilder();
        if (!id) {
          const result = await window.$http.postWithQueryAndHeaders(
            `${window.$utility.setApiUrl(userState)}profile/${0}`,
            query,
            {
              employee_id: user.employeeId,
              first_name: user.firstName,
              last_name: user.lastName,
              email: user.email,
              rp_gbl_occupation_id: user.occupation,
              dob: moment(
                user?.date +
                  '-' +
                  (parseInt(user?.month) + 1) +
                  '-' +
                  user?.year,
                'DD-MM-YYYY'
              )
                .add(1, -'M')
                .format('YYYY-MM-DD'),
              rp_gbl_country_id: countryId,
              rp_gbl_gender_id: user.genderId,
              assmt_report_emails: reportEmails,
              mobile_country_code: user?.mobileCode ? user.mobileCode : '',
              mobile_no: user?.mobileNo ? user.mobileNo : '',
              designation_level: designationLevel,
              dept_discipline: deptDiscipline,
            }
          );

          if (result.code === window.$constants.STATUS.OK) {
            setMsg('');
            handleModal();
            getOccupationOptions();
            getPeopleData();
            exportData();
            toast.success(result?.message);
          } else {
            setMsg(result?.message);
            console.log('Something went wrong!');
          }
        }
      } catch (err) {
        console.log(err);
      }
      setButtonState({
        value: 'savePeople',
        isLoading: false,
      });
    }
  };

  const updatePeople = async (id) => {
    if (!user.firstName) {
      return setIsSubmit(true);
    } else if (!nameRegex.test(user.firstName)) {
      return setIsSubmit(true);
    } else if (user?.lastName && !global.nameRegex?.test(user?.lastName)) {
      return setIsSubmit(true);
    } else if (
      userState?.is_only_number_login_enabled
        ? !user.email && !user?.mobileNo
        : !user?.email
    ) {
      return setIsSubmit(true);
    } else if (user?.email && !global.emailRegex.test(user.email)) {
      return setIsSubmit(true);
    } else if (user?.mobileNo && !global.mobileRegex.test(user.mobileNo)) {
      return setIsSubmit(true);
    } else if (userState?.is_custom_field_enabled && !designationLevel) {
      return setIsSubmit(true);
    } else if (userState?.is_custom_field_enabled && !deptDiscipline) {
      return setIsSubmit(true);
    } else {
      setButtonState({
        value: 'updatePeople',
        isLoading: true,
      });
      try {
        const query = queryParamBuilder();
        const result = await window.$http.postWithQueryAndHeaders(
          `${window.$utility.setApiUrl(userState)}profile/${id}`,
          query,
          {
            employee_id: user.employeeId,
            first_name: user.firstName,
            last_name: user.lastName,
            email: user.email,
            rp_gbl_occupation_id: user.occupation,
            dob:
              user?.year &&
              user?.year !== 'Invalid date' &&
              !isNaN(user?.month) &&
              user?.date &&
              user?.date !== 'Invalid date'
                ? moment(
                    user?.date +
                      '-' +
                      (parseInt(user?.month) + 1) +
                      '-' +
                      user?.year,
                    'DD-MM-YYYY'
                  )
                    .add(1, -'M')
                    .format('YYYY-MM-DD')
                : '',
            rp_gbl_country_id: countryId,
            rp_gbl_gender_id: user.genderId,
            assmt_report_emails: reportEmails,
            mobile_country_code: user?.mobileCode ? user.mobileCode : '',
            mobile_no: user?.mobileNo ? user.mobileNo : '',
            designation_level: designationLevel,
            dept_discipline: deptDiscipline,
          }
        );
        if (result.code === window.$constants.STATUS.CONFLICT) {
          setMsg(result?.message);
        }
        if (result.code === window.$constants.STATUS.OK) {
          handleModal();
          getOccupationOptions();
          if (searchValue) {
            getPeopleData(searchValue);
            exportData(searchValue);
          } else {
            getPeopleData();
            exportData();
          }
          setMsg('');
          toast.success(result?.message);
        } else {
          setMsg(result?.message);
          console.log('Something went wrong!');
        }
      } catch (err) {
        console.log(err);
      }
      setButtonState({
        value: 'updatePeople',
        isLoading: false,
      });
    }
  };

  useEffect(() => {
    window.addEventListener('load', clearFilter);
    return () => {
      window.removeEventListener('load', clearFilter);
    };
  }, []);

  const clearFilter = (e) => {
    e.preventDefault();
    if (window.$utility.pageAccessedByReload()) {
      dispatch(saveAdminFilters({}, 'people'));
    }
  };

  useEffect(() => {
    setPage(1);
    if (props?.methodCall) {
      getPeopleData(null, null, true);
      exportData();
    }
  }, [props?.methodCall]);

  const getPeopleData = async (searchValue, pageNo, isLoadDefaultPage = false) => {
    if (headerClicked) {
      setIsDataLoading(false);
    } else {
      setIsDataLoading(true);
    }

    let sortData = [];

    try {
      let page_number = 1;
      // console.log(searchValue, pageNo, isEnterClicked, searchPageCount, page);
      if (!isLoadDefaultPage) {
        if (searchValue == '' || searchValue == null) {
          page_number = page;
        } else {
          if (pageNo != null) {
            page_number = pageNo;
          } else {
            if (isEnterClicked || searchPageCount === 1) {
              page_number = page;
            } else {
              if (searchPageCount > 1) {
                page_number = page;
              } else {
                page_number = 1;// page_number = '';
              }
            }
          }
        }
      }

      const preQuery = {
        q: searchValue,
        page: page_number,//searchValue ? (pageNo || (isEnterClicked || searchPageCount === 1 ? page : searchPageCount > 1 ? page : '')) : page,
        rp_company_id: window.$utility.setCompanyId(userState, companyTypeId),
        sort_by: headerKey ? headerKey : '',
        sort_dir: sortDir,
      };

      const query = window.$utility.prepareDynamicParamsCustomChart(
        preQuery,
        props?.selectedArray
      );

      const result = await window.$http.getWithQueryAndHeaders(
        `${window.$utility.setApiUrl(userState)}profile`,
        query
      );

      if (result.code === window.$constants.STATUS.OK) {
        setIsDataLoading(false);
        setHeaderClicked(false);
        setResultData(result?.data);
        if (headerState?.length === 0) {
          for (let elem of result?.data?.headers) {
            if (elem?.key === 'name') {
              sortData.push({ key: elem?.key, value: 'asc' });
            } else {
              sortData.push({ key: elem?.key, value: '' });
            }
          }
          setHeaderState(sortData);
        }
        setLabels(result?.data?.headers?.map((data, id) => data));
        setMetaData(result?.metadata);
        if (searchValue) {
          setSearchPageCount(result?.metadata?.page_count);
        }
        setPageCount(result?.metadata?.page_count);
        props?.apiCalls('val');
      } else {
        setIsDataLoading(false);
        console.log('Something went wrong!');
      }
    } catch (error) {
      setIsDataLoading(false);
      console.log(error);
    }
  };

  const getPeople = async (id) => {
    try {
      const query = queryParamBuilder();
      const result = await window.$http.getWithQueryAndHeaders(
        `${window.$utility.setApiUrl(userState)}profile/${id}`,
        query
      );

      if (result.code === window.$constants.STATUS.OK) {
        setItems({
          rp_profile_id: result?.data?.rp_profile_id,
          firstName: result?.data?.first_name,
          lastName: result?.data?.last_name,
          employeeId: result?.data?.employee_id,
          email: result?.data?.email,
          groupId: result?.data?.rp_group_id,
          locationId: result?.data?.rp_location_id,
          isManager: result?.data?.is_manager,
          occupation: result?.data?.rp_gbl_occupation_id,
          genderId: result?.data?.rp_gbl_gender_id,
          year:
            result?.data?.dob !== null || 'Invalid date'
              ? moment(result?.data?.dob).format('YYYY')
              : '',
          month:
            result?.data?.dob !== null || 'Invalid date'
              ? parseInt(moment(result?.data?.dob).format('M') - 1)
              : '',
          date:
            result?.data?.dob !== null || 'Invalid date'
              ? moment(result?.data?.dob).format('D')
              : '',
          mobileCode: !isNull(result?.data?.mobile_country_code)
            ? result?.data?.mobile_country_code
            : countryOptions?.find(
                (el) => el?.rp_gbl_country_id === userState?.rp_gbl_country_id
              )?.country_phone_code,
          mobileNo: result?.data?.mobile_no,
        });
        setDesignationValue(result?.data?.designation_level);
        setDeptValue(result?.data?.dept_discipline);
        setEmailValue(result?.data?.assmt_report_emails);
        setCountryValue(result?.data?.rp_gbl_country_id);
        setUserLoggedIn(result?.data?.user?.is_profile_completed);
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deletePeople = async (id) => {
    try {
      const query = queryParamBuilder();
      const result = await window.$http.deleteWithQueryAndHeaders(
        `${window.$utility.setApiUrl(userState)}profile/${id}`,
        query
      );
      if (result.data.code === window.$constants.STATUS.OK) {
        setShowModal(false);
        if (searchValue) {
          getPeopleData(searchValue);
          exportData(searchValue);
        } else {
          getPeopleData();
          exportData();
        }

        toast.success(result?.data?.message);
      } else {
        result?.data?.message
          ? toast.error(result?.data?.message)
          : toast.error('Error occured, please try again or contact admin.');
        console.log('Something went wrong!');
      }
    } catch (error) {
      toast.error('Error occured, please try again or contact admin.');
      console.log(error);
    }
  };

  const getReport = async (rp_user_id, rp_user_assmt_id) => {
    setUserId(rp_user_id);
    reportValueSet.push(rp_user_id);
    setReportValueSet(valueSet);
    setIsReportDownloading(true);
    try {
      const query = queryParamBuilder();
      const result = await window.$http.getWithQueryAndHeaders(
        `${window.$utility.setApiUrl(
          userState
        )}my/assessment/result/download/${rp_user_id}/${rp_user_assmt_id}`,
        query
      );
      if (result.code === window.$constants.STATUS.OK) {
        setIsReportDownloading(false);
        setReportValueSet((curr) =>
          curr.filter((value) => {
            return value !== rp_user_id;
          })
        );
        var linkSource = result?.data?.media_content;
        var downloadLink = document.createElement('a');
        var fileName = result?.data?.filename
          ? result?.data?.filename
          : 'result.pdf';
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      } else {
        setIsReportDownloading(false);
        setReportValueSet((curr) =>
          curr.filter((value) => {
            return value !== rp_user_id;
          })
        );
        result?.message
          ? toast.error(result?.message)
          : toast.error('Error occured, please try again or contact admin.');
      }
    } catch (err) {
      setIsReportDownloading(false);
      setReportValueSet((curr) =>
        curr.filter((value) => {
          return value !== rp_user_id;
        })
      );
      console.log(err);
    }
  };

  const getAssmtResult = async (user_id, id) => {
    setUserId(user_id);
    resultValueSet.push(user_id);
    setResultValueSet(valueSet);
    setIsResultDownloading(true);
    try {
      const query = queryParamBuilder();
      const assmtResult = await window.$http.getWithQueryAndHeaders(
        `${window.$utility.setApiUrl(
          userState
        )}media/supplement/dosage/0/${user_id}`,
        query
      );
      if (assmtResult.code === window.$constants.STATUS.OK) {
        setIsResultDownloading(false);
        setResultValueSet((curr) =>
          curr.filter((value) => {
            return value !== user_id;
          })
        );
        var linkSource = assmtResult?.data?.media_content;
        var downloadLink = document.createElement('a');
        var fileName = assmtResult?.data?.filename
          ? assmtResult?.data?.filename
          : 'dosage.pdf';
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        setBcgImg({
          img: imgs[0],
          id,
        });
      } else {
        setIsResultDownloading(false);
        setResultValueSet((curr) =>
          curr.filter((value) => {
            return value !== user_id;
          })
        );
        assmtResult?.message
          ? toast.error(assmtResult?.message)
          : toast.error('Error occured, please try again or contact admin.');
      }
    } catch (error) {
      setIsResultDownloading(false);
      setResultValueSet((curr) =>
        curr.filter((value) => {
          return value !== user_id;
        })
      );
      console.log(error);
    }
  };

  const handleResendEmail = async (rp_profile_id) => {
    setRpProfileId(rp_profile_id);
    valueSet.push(rp_profile_id);
    setValueSet(valueSet);
    setIsLoading(true);

    try {
      const query = queryParamBuilder();
      const result = await window.$http.postWithQueryAndHeaders(
        `${window.$utility.setApiUrl(
          userState
        )}profile/welcome/mail/${rp_profile_id}`,
        query
      );

      if (result.code === window.$constants.STATUS.OK) {
        setIsLoading(false);
        setValueSet((curr) =>
          curr.filter((value) => {
            return value !== rp_profile_id;
          })
        );
        toast.success(result?.message);
      } else {
        setIsLoading(false);
        setValueSet((curr) =>
          curr.filter((value) => {
            return value !== rp_profile_id;
          })
        );
        result?.message
          ? toast.error(result?.message)
          : toast.error('Error occured, please try again or contact admin.');
      }
    } catch (err) {
      setIsLoading(false);
      setValueSet((curr) =>
        curr.filter((value) => {
          return value !== rp_profile_id;
        })
      );
      toast.error('Error occured, please try again or contact admin.');
      console.log(err);
    }
  };

  const handleSendReminder = async (rp_profile_id) => {
    setRpProfileId(rp_profile_id);
    reminderValueSet.push(rp_profile_id);
    setReminderValueSet(reminderValueSet);
    setIsLoading(true);
    try {
      const query = queryParamBuilder();
      const result = await window.$http.postWithQueryAndHeaders(
        `${window.$utility.setApiUrl(
          userState
        )}profile/assmt/reminder/${rp_profile_id}`,
        query
      );
      if (result.code === window.$constants.STATUS.OK) {
        setIsLoading(false);
        setReminderValueSet((curr) =>
          curr.filter((value) => {
            return value !== rp_profile_id;
          })
        );
        toast.success(result?.message);
      } else {
        setIsLoading(false);
        setReminderValueSet((curr) =>
          curr.filter((value) => {
            return value !== rp_profile_id;
          })
        );
        result?.message
          ? toast.error(result?.message)
          : toast.error('Error occured, please try again or contact admin.');
      }
    } catch (err) {
      setIsLoading(false);
      setReminderValueSet((curr) =>
        curr.filter((value) => {
          return value !== rp_profile_id;
        })
      );
      toast.error('Error occured, please try again or contact admin.');
      console.log(err);
    }
  };

  const handleKeyPress = (e) => {
    if (e?.key === 'Enter' || e?.key === 'NumpadEnter') {
      setPage(1);
      setSearchPageCount(1);
      setIsEnterClicked(true);
      getPeopleData(searchValue, 1);
      exportData(searchValue, 1);
    }
  };

  const handleAddButton = () => {
    setPage(1);
    handleItems();
  };

  const handleModal = () => {
    setItems(InitialItemState);
    setShowModal(false);
    setButtonState({ value: '', isLoading: false });
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    setStyleChange(true);
    if (isEmpty(e.target.value)) {
      setStyleChange(false);
      getPeopleData();
      exportData();
    }
  };

  const handleCrossClick = () => {
    setSearchValue('');
    setStyleChange(false);
    if (isEnterClicked) {
      getPeopleData();
      exportData();
    }
  };

  const handleTableData = (values, data) => {
    return values.map(
      (val) =>
        val?.tableColumns &&
        (val?.data === data?.name ? (
          <td
            className='primary-color cursor-p fw-6'
            onClick={() =>
              getPeople(data?.rp_profile_id) && handleEditedItems()
            }
          >
            {val?.data}
          </td>
        ) : data?.can_send_wlcm_mail && val?.data === data?.welcome_invite ? (
          <>
            <td>
              <span className='me-1'>{val?.data}</span>
              <span
                className={
                  isLoading &&
                  (rpProfileId === data?.rp_profile_id ||
                    valueSet?.some((el) => el === data?.rp_profile_id))
                    ? 'btn btn-rp-secondary btn-sm me-1 action-btns btn-space disabled'
                    : 'btn btn-rp-secondary btn-sm me-1 action-btns btn-space'
                }
                title='Resend Welcome Email'
                onClick={() => handleResendEmail(data?.rp_profile_id)}
              >
                {isLoading &&
                (rpProfileId === data?.rp_profile_id ||
                  valueSet?.some((el) => el === data?.rp_profile_id)) ? (
                  <Spinner
                    as='i'
                    animation='border'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                ) : (
                  <i className='fa-solid fa-paper-plane'></i>
                )}
              </span>
            </td>
          </>
        ) : data?.can_send_assmt_reminder &&
          val?.data === data?.is_assmt_completed &&
          val?.key === 'is_assmt_completed' ? (
          <td>
            <span className='me-1'>{val.data}</span>
            <span>
              <Popconfirm
                placement='topRight'
                title={
                  data?.assmt_reminder_date
                    ? `Last reminder was sent on ${moment(
                        data?.assmt_reminder_date
                      ).format('MMM DD, YYYY, h:mm A')}
                                          Are you sure you still want to send
                                          reminder?`
                    : 'Are you sure you want to send reminder?'
                }
                onConfirm={() => handleSendReminder(data?.rp_profile_id)}
                overlayInnerStyle={{ width: '412px' }}
                okText='Yes'
                cancelText='No'
                overlayClassName='antdReminderButton'
              >
                <span
                  className={
                    isLoading &&
                    (rpProfileId === data?.rp_profile_id ||
                      reminderValueSet?.some(
                        (el) => el === data?.rp_profile_id
                      ))
                      ? 'btn btn-rp-secondary btn-sm action-btns btn-space disabled'
                      : 'btn btn-rp-secondary btn-sm action-btns btn-space'
                  }
                  title='Assessment Reminder'
                >
                  {isLoading &&
                  (rpProfileId === data?.rp_profile_id ||
                    reminderValueSet?.some(
                      (el) => el === data?.rp_profile_id
                    )) ? (
                    <Spinner
                      as='i'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    <i className='fa-solid fa-stopwatch'></i>
                  )}
                </span>
              </Popconfirm>
            </span>
          </td>
        ) : (
          <td>{val.data}</td>
        ))
    );
  };

  useEffect(() => {
    getDynamicPlaceholder();
  }, [labels]);

  const getDynamicPlaceholder = () => {
    labels?.forEach(function (arrayItem) {
      const x = arrayItem;
      if (x?.key.includes('employee')) {
        return setDynamicPlaceholder(
          `Search by name, email, or ${x.label.toLowerCase()}`
        );
      }
    });
  };

  const handleHeaderClick = (data) => {
    let setSort = [];
    if (data?.is_sortable) {
      setHeaderClicked(true);
      setHeaderKey(data?.key);
      setClickCount(clickCount + 1);

      Object.keys(headerState).forEach(function (d) {
        if (headerState[d]['key'] === data?.key) {
          if (headerState[d]['value'] === 'asc') {
            setSort.push({ key: headerState[d]['key'], value: 'desc' });
            setSortDir('desc');
          } else {
            setSort.push({ key: headerState[d]['key'], value: 'asc' });
            setSortDir('asc');
          }
        } else {
          setSort.push({
            key: headerState[d]['key'],
            value: '',
          });
        }
      });
      setHeaderState(setSort);
    }
  };

  const exportData = async (searchValue, pageNo) => {
    try {
      if (userState?.is_export_enabled) {
        const preQuery = {
          q: searchValue,
          page: searchValue
            ? pageNo
              ? pageNo
              : isEnterClicked || searchPageCount === 1
              ? page
              : searchPageCount > 1
              ? page
              : ''
            : page,
          rp_company_id: companyTypeId ? companyTypeId?.value : '',
          sort_by: headerKey ? headerKey : '',
          sort_dir: sortDir,
        };

        const query = window.$utility.prepareDynamicParamsCustomChart(
          preQuery,
          props?.selectedArray
        );

        const result = await window.$http.getWithQueryAndHeaders(
          `${window.$utility.setApiUrl(userState)}profile/export`,
          query
        );

        if (result.code === window.$constants.STATUS.OK) {
          setExportResultData(result?.data);
        } else {
          console.log('Something went wrong!');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <h1 className='section-heading text-center rp-primary-dark-g'>
        {userState?.admin_display_text_obj?.web_admin_text} at {companyState?.company_display_name}
      </h1>

      {/* <div>
        <h2 className='section-sub-heading text-center fs-5 rp-primary-dark-g'>
          Create, view and edit the Resiliency Program users profile for people
          at {companyState?.company_display_name}
        </h2>
      </div> */}

      <div className='col-md-12 d-flex align-items-center justify-content-between mt-4'>
        <div className='col-md-6'>
          <div className='position-relative'>
            <span style={{ position: 'absolute', left: '10px', top: '10px' }}>
              <i
                type='button'
                className='bi bi-search z-1 me-n5'
                onClick={() =>
                  getPeopleData(searchValue) && exportData(searchValue)
                }
              ></i>
            </span>

            <input
              className='form-control ps-5'
              name='search'
              type='search'
              placeholder={dynamicPlaceholder}
              aria-label='Search'
              value={searchValue}
              onChange={(e) => handleSearchChange(e)}
              onKeyPress={(e) => handleKeyPress(e)}
            />
            <span
              className='search-cancel-button'
              style={
                styleChange
                  ? { opacity: '1', cursor: 'pointer', pointerEvents: 'auto' }
                  : {}
              }
              onClick={handleCrossClick}
            >
              <i class='fa-solid fa-xmark'></i>
            </span>
          </div>
        </div>
        <div className='text-end'>
          <button
            type='button'
            title={('Add ' + userState?.admin_display_text_obj?.text)}
            className='btn btn-rp-primary'
            onClick={handleAddButton}
          >
            Add {(userState?.admin_display_text_obj?.text)}
          </button>
          {userState?.is_export_enabled ? (
            <ExcelExport
              data={
                exportResultData?.profiles ? exportResultData?.profiles : ''
              }
              headers={
                exportResultData?.headers ? exportResultData?.headers : ''
              }
            />
          ) : (
            ''
          )}
        </div>
      </div>

      {/* <div className='col-md-12 d-flex align-items-center justify-content-between mt-4'>
        <CustomSelect />
      </div> */}

      <div className='mt-5'>
        <h2 className=' fs-5 rp-primary-dark-g'>
          {`Total Count: ${resultData ? resultData?.total_count : '0'}`}
        </h2>
      </div>
      <div className='br-12 bg-light p-4 bg-white'>
        <div className='table-responsive'>
          {!isDataLoading ? (
            <table className='table align-middle people-page' id='table'>
              <thead>
                <tr height='50' valign='middle'>
                  <>
                    {resultData &&
                      resultData?.headers?.map(
                        (data, id) =>
                          data?.is_table_column && (
                            <th
                              className={
                                data?.is_sortable
                                  ? headerKey === data?.key
                                    ? sortDir === 'asc'
                                      ? 'shorting_up shorting_icon cursor-p'
                                      : 'shorting_down shorting_icon cursor-p'
                                    : 'shorting_icon cursor-p'
                                  : ''
                              }
                              width={data?.width}
                              onClick={() => handleHeaderClick(data)}
                            >
                              {data?.label}
                            </th>
                            // ) : (
                            //   ''
                          )
                      )}
                    <th
                      style={
                        userState?.rp_gbl_company_type_id ===
                        window.$enums.CompanyTypeId.Clinical
                          ? { textAlign: 'center', width: '195px' }
                          : { textAlign: 'center', width: '120px' }
                      }
                    >
                      Action
                    </th>
                  </>
                </tr>
              </thead>

              <tbody>
                {!headerClicked ? (
                  resultData?.profiles?.length ? (
                    <>
                      {resultData?.profiles?.map((data, id) => {
                        const values = resultData?.headers.map((th) => {
                          return {
                            data: data[th.key],
                            key: th.key,
                            tableColumns: th.is_table_column,
                          };
                        });

                        return (
                          <tr className='tr-group-item'>
                            {handleTableData(values, data)}
                            <td className='downlode_result'>
                              {data?.rp_user_assmt_id > 0 &&
                              resultData?.is_other_users_reports_visible ? (
                                <>
                                  <span
                                    key={id}
                                    className={
                                      isResultDownloading &&
                                      (userId === data?.rp_user_id ||
                                        resultValueSet?.some(
                                          (el) => el === data?.rp_user_id
                                        ))
                                        ? 'btn btn-rp-secondary btn-sm me-1 action-btns btn-space disabled'
                                        : 'btn btn-rp-secondary btn-sm me-1 action-btns btn-space'
                                    }
                                    onMouseOver={() =>
                                      setBcgImg({ img: imgs[1], id: id })
                                    }
                                    onMouseLeave={() =>
                                      setBcgImg({ img: imgs[0], id: id })
                                    }
                                    title='Download Supplement Report'
                                    onClick={() =>
                                      getAssmtResult(data?.rp_user_id, id)
                                    }
                                  >
                                    {isResultDownloading &&
                                    (userId === data?.rp_user_id ||
                                      resultValueSet?.some(
                                        (el) => el === data?.rp_user_id
                                      )) ? (
                                      <Spinner
                                        as='i'
                                        animation='border'
                                        size='sm'
                                        role='status'
                                        aria-hidden='true'
                                      />
                                    ) : (
                                      <img
                                        key={id}
                                        src={
                                          id === +bcgImg?.id
                                            ? bcgImg?.img
                                            : Prescription
                                        }
                                        alt='prescription'
                                        style={{
                                          height: '20%',
                                          width: '20px',
                                        }}
                                      />
                                    )}
                                  </span>

                                  <span
                                    className={
                                      isReportDownloading &&
                                      (userId === data?.rp_user_id ||
                                        reportValueSet?.some(
                                          (el) => el === data?.rp_user_id
                                        ))
                                        ? 'btn btn-rp-secondary btn-sm me-1 action-btns btn-space disabled'
                                        : 'btn btn-rp-secondary btn-sm me-1 action-btns btn-space'
                                    }
                                    title='Download Result'
                                    onClick={() =>
                                      getReport(
                                        data?.rp_user_id,
                                        data?.rp_user_assmt_id
                                      )
                                    }
                                  >
                                    {isReportDownloading &&
                                    (userId === data?.rp_user_id ||
                                      reportValueSet?.some(
                                        (el) => el === data?.rp_user_id
                                      )) ? (
                                      <Spinner
                                        as='i'
                                        animation='border'
                                        size='sm'
                                        role='status'
                                        aria-hidden='true'
                                      />
                                    ) : (
                                      <i className='fa-solid fa-download'></i>
                                    )}
                                  </span>
                                </>
                              ) : (
                                ''
                              )}
                              <span
                                className='btn btn-sm btn-rp-primary btn-space me-1'
                                title='Edit User'
                                onClick={() =>
                                  getPeople(data?.rp_profile_id) &&
                                  handleEditedItems()
                                }
                              >
                                <i className='fa-solid fa-pen'></i>
                              </span>
                              <Popconfirm
                                placement='topRight'
                                title={text}
                                onConfirm={() =>
                                  deletePeople(data?.rp_profile_id)
                                }
                                okText='Yes'
                                cancelText='No'
                                overlayClassName='antdButton'
                              >
                                <span
                                  className='btn btn-sm btn-rp-primary-red btn-space'
                                  title='Delete User'
                                  style={{ minWidth: '41px' }}
                                >
                                  <i className='fa-solid fa-trash-can'></i>
                                </span>
                              </Popconfirm>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <tr className='tr-group-item'>
                      <td
                        colSpan={columnLength}
                        height='80'
                        style={{ borderRadius: 'unset' }}
                        className='text-center'
                      >
                        No result found
                      </td>
                    </tr>
                  )
                ) : (
                  <tr className='tr-group-item'>
                    <td
                      colSpan={columnLength}
                      height='80'
                      style={{ borderRadius: 'unset' }}
                      className='text-center'
                    >
                      <div className='centered' style={{ marginLeft: '1rem' }}>
                        <LoadingSpinner />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          ) : (
            <div className='centered' style={{ marginLeft: '1rem' }}>
              <LoadingSpinner />
            </div>
          )}
        </div>
      </div>

      {/* <!-- pagination --> */}
      <nav className='mt-5' aria-label='Page navigation example'>
        <ul className='pagination justify-content-center'>
          <li className='page-item'>
            <button
              className='page-link'
              to='#'
              aria-label='Previous'
              onClick={pageLoadPrev}
            >
              <span aria-hidden='true'>
                <i className='fa-solid fa-angle-left'></i>
              </span>
            </button>
          </li>
          {paginationRange
            ? paginationRange.map((pageNumber) => {
                if (pageNumber === DOTS) {
                  return <li className='dots'>&#8230;</li>;
                }
                return (
                  <li className='page-item' key={pageNumber}>
                    <Link
                      className={
                        page === pageNumber ? 'page-link active' : 'page-link'
                      }
                      to='#'
                      onClick={(event) => handlePageNo(event, pageNumber)}
                    >
                      {pageNumber}
                    </Link>
                  </li>
                );
              })
            : ''}
          <li className='page-item'>
            <button
              className='page-link'
              to='#'
              aria-label='Next'
              onClick={pageLoadNext}
            >
              <span aria-hidden='true'>
                <i className='fa-solid fa-angle-right'></i>
              </span>
            </button>
          </li>
        </ul>
      </nav>

      <ToastContainer />
      {userState.rp_gbl_company_type_id ===
      window.$enums.CompanyTypeId.Clinical ? (
        <ClinicalForm
          items={items}
          labels={labels}
          user={user}
          Days={Days}
          Years={Years}
          userState={userState}
          manageOptions={manageOptions}
          buttonState={buttonState}
          savePeople={savePeople}
          updatePeople={updatePeople}
          handleCloseModal={handleModal}
          handleChange={handleChange}
          formErrors={formErrors}
          message={msg}
          isSubmit={isSubmit}
          showModal={showModal}
          resultEmails={reportEmails}
          countryVal={countryId}
          designationLevel={designationLevel}
          deptDiscipline={deptDiscipline}
          handleCountryData={handleCountryData}
          handleDesginationLevel={handleDesginationLevel}
          handleDeptDiscipline={handleDeptDiscipline}
          countryOptions={countryOptions}
          getValue={getValue}
          handleReportEmails={handleReportEmails}
          userLoggedIn={userLoggedIn}
        />
      ) : (
        <EmployeeForm
          items={items}
          labels={labels}
          user={user}
          Days={Days}
          Years={Years}
          manageOptions={manageOptions}
          buttonState={buttonState}
          saveEmployee={saveEmployee}
          updateEmployee={updateEmployee}
          handleCloseModal={handleModal}
          handleChange={handleChange}
          formErrors={formErrors}
          message={msg}
          groupOptions={groupOptions}
          locationOptions={locationOptions}
          isSubmit={isSubmit}
          showModal={showModal}
          countryVal={countryId}
          designationLevel={designationLevel}
          deptDiscipline={deptDiscipline}
          handleCountryData={handleCountryData}
          handleDesginationLevel={handleDesginationLevel}
          handleDeptDiscipline={handleDeptDiscipline}
          countryOptions={countryOptions}
          userState={userState}
          getValue={getValue}
        />
      )}
    </>
  );
};

export default CompanyManagementPeople;
