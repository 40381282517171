import React from 'react';
import { useDispatch } from 'react-redux';
import MainRouter from './routes';
import '../services/api';
import '../services/constants';
import '../services/utility';
import '../services/enum';
import 'antd/dist/antd.css';
import PreLoader from '../common/PreLoader';
import 'react-loading-skeleton/dist/skeleton.css';
import { TourProvider } from '@reactour/tour';
import { save } from '../store/user/actions';

const loading = () => {
  return <PreLoader />;
};

const App = () => {
  const dispatch = useDispatch();

  const updateProfileData = async () => {
    try {
      const result = await window.$http.getWithHeaders('v2/my_profile');
      if (result.code === window.$constants.STATUS.OK) {
        dispatch(save(result));
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  function Close({ onClick }) {
    const click = () => {
      if (onClick) {
        closeClick();
        onClick();
      }
    };

    return (
      <button
        onClick={click}
        style={{ position: 'absolute', right: 10, top: 1, color: '#00a39d' }}
      >
        x
      </button>
    );
  }

  const closeClick = async () => {
    try {
      const result = await window.$http.postWithHeaders('guided_tour/status', {
        guided_tour_status: 2,
      });
      if (result.code === window.$constants.STATUS.OK) {
        updateProfileData();
        console.log('success');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Suspense fallback={loading()}>
      {/* <TourProvider
        steps={[]}
        components={{ Close }}
        styles={{
          popover: (base) => ({
            ...base,
          }),

          badge: (base) => ({ ...base, backgroundColor: '#00a39d' }),
          dot: (base) => ({
            ...base,
            background: '#00a39d',
            color: '#00a39d',
          }),
        }}
      > */}
      <MainRouter />
      {/* </TourProvider> */}
    </React.Suspense>
  );
};

export default App;
