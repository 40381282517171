import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import SuccessMsg from '../Messages';
import Spinner from 'react-bootstrap/Spinner';
import LoadingSpinner from '../LoadingSpinner';

const InitialState = {
  firstName: '',
  lastName: '',
  email: '',
  year: '',
  month: '',
  date: '',
  gender: '',
  relationship: '',
  countryId: '',
};

const InviteMore = (props) => {
  const [currState, setCurrState] = useState(InitialState);
  const [items, setItems] = useState({
    rp_profile_id: '',
    firstName: '',
    lastName: '',
    email: '',
    year: '',
    month: '',
    date: '',
    gender: '',
    relationship: '',
    countryId: '',
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [Years, setYears] = useState([]);
  const [Days, setDays] = useState([]);
  const [formOptions, setFormOptions] = useState();
  const [message, setMessage] = useState({ success: '', failure: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [formLoadingState, setFormLoadingState] = useState(false);

  useEffect(() => {
    setYears(window.$utility.getYears());
    setDays(window.$utility.getDaysList());
    getList();
  }, []);

  useEffect(() => {
    if (items) {
      setCurrState({
        firstName: items?.firstName,
        lastName: items?.lastName,
        email: items?.email,
        year: items !== null || 'Invalid date' ? items?.year : '',
        month: items !== null || 'Invalid date' ? items?.month : '',
        date: items !== null || 'Invalid date' ? items?.date : '',
        gender: items?.genderId,
        relationship: items?.relationship,
        countryId: items?.countryId,
      });
    }
  }, [items]);

  useEffect(() => {
    if (props?.userModal) {
      getReferralData();
    }
  }, [props?.userModal]);

  const getList = async () => {
    try {
      const result = await window.$http.getWithHeaders(
        'lookup?types=gender,relation,country'
      );

      if (result.code === window.$constants.STATUS.OK) {
        return setFormOptions(result.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    setMessage({ success: '', failure: '' });
    const { name, value } = e.target;
    let currentFormErrors = formErrors;
    var day;

    switch (name) {
      case 'firstName':
        if (!value) {
          currentFormErrors[name] = 'Please enter first name';
        } else if (!global.nameRegex?.test(value)) {
          currentFormErrors[name] = 'First name is not valid';
        } else {
          delete currentFormErrors[name];
        }
        break;

      case 'lastName':
        if (!global.nameRegex?.test(value)) {
          currentFormErrors[name] = 'Last Name is not valid';
        } else {
          delete currentFormErrors[name];
        }
        break;

      case 'email':
        if (!value) {
          currentFormErrors[name] = 'Please Enter Email';
        } else if (!global.emailRegex?.test(value)) {
          currentFormErrors[name] = 'Email is not valid';
        } else {
          delete currentFormErrors[name];
        }
        break;

      case 'year':
        if (!value) {
          currentFormErrors[name] = 'Please select year.';
        } else {
          delete currentFormErrors[name];
          while (Days.length) {
            Days.pop();
          }
          day = window.$utility.getLastDay(value, currState.month);
          for (let i = 1; i <= day; i++) {
            Days.push(i);
            setDays((prev) => Days);
          }
        }
        break;

      case 'month':
        if (!value) {
          currentFormErrors[name] = 'Please enter select month.';
        } else {
          delete currentFormErrors[name];
          while (Days.length) {
            Days.pop();
          }
          day = window.$utility.getLastDay(currState.year, value);
          for (let i = 1; i <= day; i++) {
            Days.push(i);
            setDays((prev) => Days);
          }
        }
        break;

      case 'date':
        if (!value) {
          currentFormErrors[name] = 'Please select date.';
        } else {
          delete currentFormErrors[name];
        }
        break;

      case 'gender':
        if (!value) {
          currentFormErrors[name] = 'Please select gender.';
        } else {
          delete currentFormErrors[name];
        }
        break;
      case 'relationship':
        if (!value) {
          currentFormErrors[name] = 'Please select relationship.';
        } else {
          delete currentFormErrors[name];
        }
        break;

      case 'countryId':
        if (!value) {
          currentFormErrors[name] = 'Please Select Country';
        } else {
          delete currentFormErrors[name];
        }
        break;

      default:
        currentFormErrors = 'All fields are required';
        break;
    }
    setCurrState({ ...currState, [name]: value });
    setFormErrors(currentFormErrors);
  };

  const handleSubmit = async () => {
    if (!currState?.firstName) {
      return setIsSubmitting(true);
    } else if (!global.nameRegex?.test(currState?.firstName)) {
      return setIsSubmitting(true);
    } else if (
      currState?.lastName &&
      !global.nameRegex?.test(currState?.lastName)
    ) {
      return setIsSubmitting(true);
    } else if (!currState?.email) {
      return setIsSubmitting(true);
    } else if (!global.emailRegex?.test(currState?.email)) {
      return setIsSubmitting(true);
    } else if (!currState?.year) {
      return setIsSubmitting(true);
    } else if (!currState?.month) {
      return setIsSubmitting(true);
    } else if (!currState?.date) {
      return setIsSubmitting(true);
    } else if (!currState?.countryId) {
      return setIsSubmitting(true);
    } else if (!currState?.gender) {
      return setIsSubmitting(true);
    } else if (!currState?.relationship) {
      return setIsSubmitting(true);
    } else {
      setIsLoading(true);
      try {
        const result = await window.$http.postWithHeaders('add/referral', {
          first_name: currState?.firstName,
          last_name: currState?.lastName,
          email: currState?.email,
          dob: moment(
            currState?.date +
              '-' +
              (parseInt(currState?.month) + 1) +
              '-' +
              currState?.year,
            'DD-MM-YYYY'
          )
            .add(1, -'M')
            .format('YYYY-MM-DD'),
          rp_gbl_gender_id: currState?.gender,
          rp_gbl_relation_id: currState?.relationship,
          rp_gbl_country_id: currState?.countryId,
        });
        if (result.code === window.$constants.STATUS.OK) {
          setMessage({ success: result?.message, failure: '' });
        } else {
          setMessage({
            success: '',
            failure: result?.message,
          });
        }
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    }
  };

  const getReferralData = async () => {
    setFormLoadingState(true);
    if (props?.rpProfileId) {
      try {
        const result = await window.$http.getWithHeaders(
          `view/referral/${props?.rpProfileId}`
        );

        if (result.code === window.$constants.STATUS.OK) {
          setItems({
            rp_profile_id: result?.data?.rp_profile_id,
            firstName: result?.data?.first_name,
            lastName: result?.data?.last_name,
            email: result?.data?.email,
            countryId: result?.data?.rp_gbl_country_id,
            genderId: result?.data?.rp_gbl_gender_id,
            relationship: result?.data?.rp_gbl_relation_id,
            year:
              result?.data?.dob !== null || 'Invalid date'
                ? moment(result?.data?.dob).format('YYYY')
                : '',
            month:
              result?.data?.dob !== null || 'Invalid date'
                ? parseInt(moment(result?.data?.dob).format('M') - 1)
                : '',
            date:
              result?.data?.dob !== null || 'Invalid date'
                ? moment(result?.data?.dob).format('D')
                : '',
          });
        } else {
          console.log('Something went wrong!');
        }
      } catch (error) {
        console.log(error);
      }
    }
    setFormLoadingState(false);
  };

  return (
    <Modal
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      scrollable={true}
      show={props.showModal ? true : false}
    >
      <Modal.Header className='px-4'>
        <h5 className='modal-title'>Invite Spouse/Children/Parents</h5>
        <button
          type='button'
          className='btn-close'
          aria-label='Close'
          onClick={props.handleCloseModal}
        ></button>
      </Modal.Header>
      {message.success ? (
        <SuccessMsg
          msg={message.success}
          handleCloseModal={props.handleCloseModal}
        />
      ) : (
        <>
          {formLoadingState ? (
            <div className='centered'>
              <LoadingSpinner />
            </div>
          ) : (
            <>
              <Modal.Body>
                <div className='row'>
                  <div className='col-md-12 col-lg-6 mb-4'>
                    <label className='asterisk'>First Name</label>
                    <input
                      type='text'
                      name='firstName'
                      className={
                        (formErrors && formErrors?.firstName) ||
                        (isSubmitting && !currState.firstName)
                          ? 'form-control border-danger'
                          : 'form-control'
                      }
                      required
                      placeholder='First Name'
                      aria-label='First Name'
                      value={currState.firstName}
                      onChange={handleChange}
                      disabled={items?.rp_profile_id ? true : false}
                    />
                  </div>
                  <div className='col-md-12 col-lg-6 mb-4'>
                    <label>Last Name</label>
                    <input
                      type='text'
                      name='lastName'
                      className={
                        formErrors && formErrors?.lastName && currState.lastName
                          ? 'form-control border-danger'
                          : 'form-control'
                      }
                      placeholder='Last Name'
                      aria-label='Last Name'
                      value={currState.lastName}
                      onChange={handleChange}
                      disabled={items?.rp_profile_id ? true : false}
                    />
                  </div>
                  <div className='col-md-12 col-lg-6 mb-4'>
                    <label className='asterisk'>Email</label>
                    <input
                      type='email'
                      name='email'
                      className={
                        (formErrors && formErrors?.email) ||
                        (isSubmitting && !currState.email) ||
                        message?.failure === 'Profile already exists'
                          ? 'form-control border-danger'
                          : 'form-control'
                      }
                      placeholder='Email'
                      aria-label='email'
                      value={currState.email}
                      onChange={handleChange}
                      disabled={items?.rp_profile_id ? true : false}
                    />
                  </div>
                  <div className='col-md-12 col-lg-6'>
                    <label className='asterisk'>Date of birth</label>
                    <div className='d-flex'>
                      <select
                        name='year'
                        className={
                          (formErrors && formErrors?.year) ||
                          (isSubmitting && !currState.year)
                            ? 'form-select form-control border-danger'
                            : 'form-select form-control'
                        }
                        id='year'
                        value={currState.year}
                        onChange={handleChange}
                        disabled={items?.rp_profile_id ? true : false}
                      >
                        <option hidden value=''>
                          Select
                        </option>
                        {Years?.map((year, id) => (
                          <option value={year} key={id}>
                            {year}
                          </option>
                        ))}
                      </select>
                      <select
                        className={
                          (formErrors && formErrors?.month) ||
                          (isSubmitting && !currState.month)
                            ? 'form-select form-control border-danger mx-4'
                            : 'form-select form-control mx-4'
                        }
                        name='month'
                        value={currState.month}
                        onChange={handleChange}
                        disabled={items?.rp_profile_id ? true : false}
                      >
                        <option hidden value=''>
                          Select
                        </option>
                        {global.months?.map((month, id) => (
                          <option value={id} key={id}>
                            {month}
                          </option>
                        ))}
                      </select>
                      <select
                        className={
                          (formErrors && formErrors?.date) ||
                          (isSubmitting && !currState.date)
                            ? 'form-select form-control border-danger'
                            : 'form-select form-control'
                        }
                        name='date'
                        id='day'
                        value={currState.date}
                        onChange={handleChange}
                        disabled={items?.rp_profile_id ? true : false}
                      >
                        <option hidden value=''>
                          Select
                        </option>
                        {Days?.map((date, id) => (
                          <option value={date} key={id}>
                            {date}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className='col-md-12 col-lg-6 mb-4'>
                    <label className='asterisk'>Gender</label>
                    <select
                      name='gender'
                      className={
                        (formErrors && formErrors?.gender) ||
                        (isSubmitting && !currState.gender)
                          ? 'form-select form-control border-danger'
                          : 'form-select form-control'
                      }
                      required=''
                      aria-label='Default select example'
                      value={currState.gender}
                      onChange={handleChange}
                      disabled={items?.rp_profile_id ? true : false}
                    >
                      <option hidden value=''>
                        Select
                      </option>
                      {formOptions?.gender?.map((data, id) => (
                        <option value={data?.id} key={id}>
                          {data?.value}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='col-md-12 col-lg-6 mb-4'>
                    <label className='asterisk'>Relationship</label>
                    <select
                      name='relationship'
                      className={
                        (formErrors && formErrors?.relationship) ||
                        (isSubmitting && !currState.relationship)
                          ? 'form-select form-control border-danger'
                          : 'form-select form-control'
                      }
                      required=''
                      aria-label='Default select example'
                      value={currState.relationship}
                      onChange={handleChange}
                      disabled={items?.rp_profile_id ? true : false}
                    >
                      <option hidden value=''>
                        Select
                      </option>
                      {formOptions?.relation?.map((data, id) => (
                        <option value={data?.id} key={id}>
                          {data?.value}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='col-md-12 col-lg-6 mb-3'>
                    <label className='asterisk'>Country</label>
                    <select
                      className={
                        isSubmitting && !currState?.countryId
                          ? 'form-select form-control border-danger'
                          : 'form-select form-control'
                      }
                      aria-label='country'
                      name='countryId'
                      value={currState?.countryId || ''}
                      onChange={handleChange}
                      disabled={items?.rp_profile_id ? true : false}
                      required
                    >
                      <option value='' hidden>
                        Select
                      </option>

                      {formOptions?.country?.map((data, id) => (
                        <option value={data?.id} key={id}>
                          {data?.value}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {!items?.rp_profile_id ? (
                  <div className='rp-primary-red-color'>
                    <span className='fw-bold rp-primary-red-color'>Note:</span>{' '}
                    Anyone being invited on this platform should be minimum 18
                    years old.
                  </div>
                ) : (
                  ''
                )}
              </Modal.Body>
              <Modal.Footer>
                {!items?.rp_profile_id ? (
                  <button
                    type='button'
                    className={`btn btn-rp-primary btn-small ${
                      isLoading ? 'disabled' : ''
                    }`}
                    onClick={handleSubmit}
                  >
                    {isLoading ? (
                      <Spinner
                        as='i'
                        animation='border'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      'Submit'
                    )}
                  </button>
                ) : (
                  ''
                )}

                <button
                  type='button'
                  className='btn btn-rp-primary-red btn-small'
                  onClick={props.handleCloseModal}
                >
                  {!items?.rp_profile_id ? 'Cancel' : 'Close'}
                </button>
                {message?.failure && (
                  <p className='mt-2 text-danger fs-6'> {message?.failure} </p>
                )}
              </Modal.Footer>
            </>
          )}
        </>
      )}
    </Modal>
  );
};

export default InviteMore;
