import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';

function ManageGroupForm(props) {
  return (
    <Modal
      tabIndex='-1'
      size='lg'
      centered
      show={props.showModal ? true : false}
      aria-hidden={props.showModal ? true : false}
    >
      <Modal.Header>
        {!props?.items?.rp_group_id ? (
          <h5 className='modal-title add-people-hed'>Add Group</h5>
        ) : (
          <h5 className='modal-title add-people-hed'>Edit Group</h5>
        )}
        <button
          type='button'
          className='btn-close'
          aria-label='Close'
          onClick={props.handleCloseModal}
        ></button>
      </Modal.Header>
      <Modal.Body className='pt-0'>
        <div className='profile-edit'>
          <div className='row mt-3'>
            <div className='col-md-12 col-lg-6 mb-3'>
              <label className='asterisk'>Group Name</label>
              <input
                type='text'
                name='groupName'
                className={
                  (props?.formErrors && props?.formErrors?.groupName) ||
                  (props?.isSubmit && !props?.group?.groupName) ||
                  props?.message === 'Group name already exists'
                    ? 'form-control border-danger'
                    : 'form-control'
                }
                placeholder='Group Name'
                aria-label='Group name'
                value={props?.group?.groupName || ''}
                onChange={props.handleChange}
                required
              />
            </div>
            <div className='col-md-12 col-lg-6 mb-3'>
              <label>Parent Group</label>
              <select
                className='form-select form-control'
                aria-label='rp_parent_id'
                name='rp_parent_id'
                value={props?.rp_parent_id ? props?.rp_parent_id : ''}
                onChange={props.handleParentState}
              >
                {!props?.items?.rp_group_id ? (
                  <option value='' hidden>
                    Self
                  </option>
                ) : (
                  <option value=''>Select</option>
                )}
                {props?.groupOptions !== undefined
                  ? props?.groupOptions?.map((data, id) => (
                      <option value={data?.rp_group_id} key={id}>
                        {data?.group_name}
                      </option>
                    ))
                  : ''}
              </select>
            </div>
            <div className='col-md-12 col-lg-6 mb-3'>
              <label className='asterisk'>Group Id</label>
              <div className='d-flex align-items-center'>
                <input
                  type='text'
                  name='groupId'
                  className={
                    (props?.formErrors && props?.formErrors?.groupId) ||
                    (props?.isSubmit && !props?.group?.groupId) ||
                    props?.message === 'Group Id already exists'
                      ? 'form-control border-danger'
                      : 'form-control'
                  }
                  placeholder='Group Id'
                  aria-label='group_Id'
                  value={props?.group?.groupId || ''}
                  onChange={props.handleChange}
                  required
                />
              </div>
            </div>
            <div className='col-md-12 col-lg-6 mb-3'>
              <label className='asterisk'>Is Active</label>
              <select
                className='form-select form-control'
                aria-label='isActive'
                name='isActive'
                value={
                  props?.isActive?.toString() === 'true' ||
                  props?.isActive?.toString() === 'Yes'
                    ? 'Yes'
                    : props?.isActive?.toString() === 'false' ||
                      props?.isActive?.toString() === 'No'
                    ? 'No'
                    : ''
                }
                onChange={props.handleActiveState}
              >
                {global.activeData.map((data, id) => (
                  <option value={data.value} key={id}>
                    {data.value}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {!props?.items?.rp_group_id ? (
          <button
            type='button'
            className={`btn btn-rp-primary ${
              props?.buttonState?.isLoading &&
              props?.buttonState?.value === 'saveGroup'
                ? 'disabled'
                : ''
            }`}
            onClick={() => props.saveGroup()}
            data-bs-dismiss={props?.handleModal === false ? 'modal' : ''}
          >
            {props?.buttonState?.isLoading &&
            props?.buttonState?.value === 'saveGroup' ? (
              <Spinner
                as='i'
                animation='border'
                size='sm'
                role='status'
                aria-hidden='true'
              />
            ) : (
              'Save'
            )}
          </button>
        ) : (
          <button
            type='button'
            className={`btn btn-rp-primary ${
              props?.buttonState?.isLoading &&
              props?.buttonState?.value === 'updateGroup'
                ? 'disabled'
                : ''
            }`}
            aria-label='Close'
            onClick={() => props.updateGroup(props?.items?.rp_group_id)}
          >
            {props?.buttonState?.isLoading &&
            props?.buttonState?.value === 'updateGroup' ? (
              <Spinner
                as='i'
                animation='border'
                size='sm'
                role='status'
                aria-hidden='true'
              />
            ) : (
              'Save'
            )}
          </button>
        )}
        {props?.message ? <p className='text-danger'>{props?.message}</p> : ''}
      </Modal.Footer>
    </Modal>
  );
}

export default ManageGroupForm;
