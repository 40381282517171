import CompanyManagementPeople from '../../../pages/Menu/CompanyManagementPeople';
import ManageGroup from '../../../pages/Menu/ManageGroup';
import ManageLocation from '../../../pages/Menu/ManageLocation';
import PushNotifications from '../../../pages/Menu/PushNotifications';
import ManageParticipation from '../../../pages/Menu/ManageParticipation';

const AdminRoutes = [
  {
    url: '/people',
    name: 'Company Management People',
    element: CompanyManagementPeople,
  },
  {
    url: '/groups',
    name: 'Manage Groups',
    element: ManageGroup,
  },
  {
    url: '/locations',
    name: 'Manage Locations',
    element: ManageLocation,
  },
  {
    url: '/participations',
    name: 'ManageParticipation',
    element: ManageParticipation,
  },
  {
    url: '/push-notification',
    name: 'PushNotifications',
    element: PushNotifications,
  },
];

export default AdminRoutes;
