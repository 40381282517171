import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import Iframe from 'react-iframe-click';
import RpiImage from '../../../../../../../assets/images/rpi.png';

const IFrame = (props) => {
  const [id, setId] = useState();
  const [value, setValue] = useState('');

  useEffect(() => {
    if (props?.resultData) {
      setValue('');
      setId(props?.resultData?.rp_content_id);
    }
  }, [props?.resultData]);

  useEffect(() => {
    if (id && !isEmpty(value)) {
      videoAudit(id);
    }
  }, [id, value]);

  const videoAudit = async (rpContentId) => {
    if (rpContentId) {
      try {
        const result = await window.$http.getWithHeaders(
          `/content/assmt/external/playaudit/${rpContentId}/${props?.assmtContentUniqueHash}`
        );
        if (result.code !== window.$constants.STATUS.OK) {
          console.log('Something went wrong!');
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className='col-md-12 mb-5'>
      <div className='p-0 video-big'>
        <div
          className='vimeo-full-width'
          onClick={() => setValue('videoAudit')}
        >
          <Iframe
            width='90%'
            height='450'
            src={
              props?.resultData?.rp_content_source_id ===
              window.$enums.Source.Youtube
                ? `https://www.youtube-nocookie.com/embed/${props?.resultData?.content_id}`
                : props?.resultData?.rp_content_source_id ===
                  window.$enums.Source.Vimeo
                ? `https://player.vimeo.com/video/${props?.resultData?.content_id}`
                : ''
            }
            title={props?.resultData?.content_filename}
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
            onInferredClick={() => setValue('videoAudit')}
          ></Iframe>
        </div>
      </div>
      <div className='video-card-discription unauth-desc'>
        <div className='music-titel'>
          <h6>{props?.resultData?.content_title}</h6>
          <p>{props?.resultData?.content_description}</p>
        </div>
        <div className='cnt-source unauth-daily-link'>
          <div>
            <p>{props?.resultData?.content_length_str}</p>
          </div>
          <div>
            <img src={RpiImage} alt='Source ioc' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IFrame;
