import ResiliencyStages from '../../../pages/Dashboard/Components/Assessment/Components/ResiliencyStages';
import Questionnaire from '../../../pages/Dashboard/Components/Assessment/Components/Questionnaire';
import Result from '../../../pages/Dashboard/Components/Assessment/Components/Result';
import TermsAndConditions from '../../../pages/Dashboard/Components/Assessment/Components/TermsAndConditions';
import AssmtResult from '../../../pages/Dashboard/Components/Assessment/Components/Result/AssmtResult';

const AssessmentRoutes = [
  {
    url: '/*',
    name: 'Resiliency Stages',
    element: ResiliencyStages,
  },
  {
    url: '/resiliency-stages',
    name: 'Resiliency Stages',
    element: ResiliencyStages,
  },
  {
    url: '/terms-and-conditions',
    name: 'Terms and conditions',
    element: TermsAndConditions,
  },
  {
    url: '/questionnaire',
    name: 'Questionnaire',
    element: Questionnaire,
  },
  {
    url: '/show-my-result',
    name: 'Result',
    element: Result,
  },
  {
    url: '/result/:rp_user_assmt_id',
    name: 'Assmt Result',
    element: AssmtResult,
  },
];

export default AssessmentRoutes;
