import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../../../../../../common/LoadingSpinner';
import VideoPlayer from '../../../../../../common/VideoPlayer';

const Recommended = (props) => {
  const [result, setResult] = useState([]);
  const [data, setData] = useState('');
  const [page, setPage] = useState(1);
  const [firstLoad, setFirstLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.showMoreVideos === false) {
      setPage(1);
      setData('');
    }
  }, [props.showMoreVideos]);

  const loadMore = () => {
    setPage(page + 1);
    props.handleCategoryVideos('clicked');
    getData(page + 1);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async (myPage = 1) => {
    setIsLoading(true);
    if (myPage === 1) {
      setFirstLoad(true);
    }

    try {
      const data = await window.$http.getWithHeaders(
        `content?page=${myPage}&is_recommended=1`
      );

      if (data.code === window.$constants.STATUS.OK) {
        setIsLoading(false);
        setFirstLoad(false);
        if (myPage > 1) {
          setResult([...result, ...data?.data]);
        } else {
          setResult(data?.data);
        }
        setData(data);
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className='list'>
      <div className='row gx-0 gx-lg-5'>
        <div className='sries_title mb-4'>
          <h1 className='card-heading-sec-dark'>
            {data?.metadata?.request?.title}
          </h1>

          <p>
            {data?.metadata?.request?.message
              ? data?.metadata?.request?.message
              : data?.metadata?.request?.no_data_message}
          </p>
        </div>
        {result && !firstLoad ? (
          <VideoPlayer resultData={result} />
        ) : (
          <div className='centered' style={{ marginLeft: '1rem' }}>
            <LoadingSpinner />
          </div>
        )}

        {isLoading && !firstLoad && data?.metadata?.page_count > 1 ? (
          <div className='centered' style={{ marginLeft: '1rem' }}>
            <LoadingSpinner />
          </div>
        ) : data && data?.metadata?.page_count > page && !isLoading ? (
          <div className='lode-more text-center'>
            <button onClick={loadMore} className='lode-button'>
              <i className='fa-solid fa-circle-chevron-down fa-xl primary-color'></i>
            </button>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default Recommended;
