import React, { useState } from 'react';
import RpiLogo from '../../../assets/images/i-button.png';
import { Link } from 'react-router-dom';
import PrivacyPolicy from './Components/PrivacyPolicy';
import TermsOfUse from './Components/TermsOfUse';
import Disclaimer from './Components/Disclaimer';
import { useSelector } from 'react-redux';
import moment from 'moment';

const Footer = (props) => {
  const [showModal, setShowModal] = useState('');
  const companyData = useSelector((state) => state?.companyData);

  const handleCloseModal = () => {
    setShowModal('');
  };

  const handleShowModal = (value) => {
    setShowModal(value);
  };

  return (
    <>
      <div
        className={`footer mt-4 mb-3 ${
          props?.value === 'reset' ||
          (props?.value === '' && window.innerHeight > 793)
            ? 'fixed-to-bottom'
            : ''
        }`}
      >
        {console.log(props)}
        {props?.value === 'reset' ? (
          ''
        ) : (
          <div
            className='email mb-2 text-center'
            onClick={() =>
              window.open('https://resiliencyprogram.com/tech-support/')
            }
          >
            <Link to='#'>Having trouble logging in?</Link>
          </div>
        )}

        {props?.value === 'reset' ? (
          ''
        ) : (
            <div className='copyright'>
              <div
                className='email d-flex mb-2'
                onClick={() => handleShowModal('privacy')}
              >
                <img
                  src={RpiLogo}
                  className='rp-i-icon me-1'
                  alt='Privacy policy'
                />
                <Link to='#'>Privacy Policy</Link>
              </div>
              <div
                className='email d-flex mb-2'
                onClick={() => handleShowModal('terms')}
              >
                <img
                  src={RpiLogo}
                  className='rp-i-icon me-1'
                  alt='Terms & conditions'
                />
                <Link to='#'>Terms of Use</Link>
              </div>
              <div
                className='email d-flex mb-2'
                onClick={() => handleShowModal('disclaimer')}
              >
                <img src={RpiLogo} className='rp-i-icon me-1' alt=''></img>
                <Link to='#'>Disclaimer</Link>
              </div>
            </div>
          )}
            <div className='copy text-center'>
              &copy;Resiliency Program {moment().year()}
            </div>
            <div className='version text-center mt-2'>
              Version {companyData?.metaData?.web_curr_version}
            </div>
          </div>
          
        
          {showModal === 'privacy' ? (
            <PrivacyPolicy
              showModal={showModal}
              handleCloseModal={handleCloseModal}
              handleShowModal={handleShowModal}
            />
          ) : showModal === 'terms' ? (
            <TermsOfUse showModal={showModal} handleCloseModal={handleCloseModal} />
          ) : showModal === 'disclaimer' ? (
            <Disclaimer showModal={showModal} handleCloseModal={handleCloseModal} />
          ) : (
            ''
          )}

    </>
  );
};

export default Footer;
