import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import parse from 'html-react-parser';
import Assessment from '../Assessment';
import { useDispatch, useSelector } from 'react-redux';
import { saveNotificationData } from '../../store/notification-data/actions';
import ComplimentaryGift from './Components/ComplimentaryGift';
import SuccessMsg from '../Messages';

const Banner = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const loc = location.pathname.split('/');
  const dispatch = useDispatch();
  const [data, setData] = useState({
    bannerData: [],
    isVisible: false,
    value: '',
  });
  const [count, setCount] = useState('');
  const [resultData, setResultData] = useState('');
  const [value, setValue] = useState();
  const notificationData = useSelector(
    (state) => state?.notificationData?.payload
  );
  const [showModal, setShowModal] = useState({
    assmt: false,
    gift: false,
  });
  const [successData, setSuccessData] = useState('');

  useEffect(() => {
    setData({ bannerData: props?.bannerData, isVisible: props?.isVisible });
    if (props.showDropDown) {
      handleNotification();
    }
  }, [props]);

  const handleBannerData = async () => {
    try {
      const result = await window.$http.getWithHeaders(
        'dashboard/web-only/banner'
      );

      if (result.code === window.$constants.STATUS.OK) {
        setData({ ...data, bannerData: result?.data });
        props.handleData(result?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetQuestions = async () => {
    setValue('');
    setShowModal({ ...showModal, assmt: true });
    setSuccessData('');
    try {
      const result = await window.$http.getWithHeaders(
        `company/assessment/detail/1`
      );

      if (result.code === window.$constants.STATUS.OK) {
        setResultData(result?.data);
        setCount(`${Math.ceil(result?.data?.questions?.length / 3)}`);
        navigate(
          loc[1] === 'dashboard' && !loc[2]
            ? '/dashboard/assessments/company-questionnaire'
            : !loc[2]
            ? `${location.pathname}/company-questionnaire`
            : `/${loc[1]}/${loc[2]}/company-questionnaire`
        );
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleNotification = () => {
    if (
      loc[2] === 'assessments' ||
      loc[2] === 'resiliency-plan' ||
      loc[2] === 'company-analytics' ||
      loc[2] === 'admin' ||
      (loc[1] === 'dashboard' && !loc[2]) ||
      loc[1] === 'manage'
    ) {
      dispatch(saveNotificationData(''));
    } else {
      setData({ ...data, isVisible: false });
    }
  };

  const handleCloseModal = () => {
    handleBannerData();
    setValue('closeModal');
    setShowModal({ assmt: false, gift: false });
    setResultData();
    navigate(-1);
  };

  const handleModalSize = (data) => {
    setSuccessData(data?.data);
  };

  return (
    <>
      <div
        className={`notification-popup ${
          loc[2] === 'assessments' ||
          loc[2] === 'resiliency-plan' ||
          loc[2] === 'company-analytics' ||
          loc[2] === 'admin' ||
          (loc[1] === 'dashboard' && !loc[2]) ||
          loc[1] === 'manage'
            ? notificationData
              ? 'show'
              : ''
            : data.isVisible
            ? 'show'
            : ''
        }`}
      >
        <div className='noti-titel'>
          <div className='noti-icon'>
            <i className='bi bi-bell'></i> Notification
          </div>
          <i
            className='fa-solid fa-xmark close cursor-p'
            onClick={handleNotification}
          ></i>
        </div>
        {data?.bannerData?.map((el, id) => (
          <div className='notification-div' key={id}>
            <div className='notification-cnt primary-color'>
              {parse(el?.message_text)}
            </div>

            <div
              className={
                el?.action_type
                  ? 'noti-action'
                  : 'noti-action justify-content-end'
              }
            >
              {el?.action_type ? (
                <button
                  className='btn btn-rp-primary'
                  onClick={
                    el?.action_type === window.$enums.BannerMsgType.CompanyAssmt
                      ? handleGetQuestions
                      : el?.action_type === window.$enums.BannerMsgType.Address
                      ? () => setShowModal({ ...showModal, gift: true })
                      : undefined
                  }
                >
                  {el?.action_type === window.$enums.BannerMsgType.CompanyAssmt
                    ? 'Begin Assessment'
                    : 'Claim now'}
                </button>
              ) : (
                ''
              )}
            </div>
          </div>
        ))}
      </div>

      <ComplimentaryGift
        showModal={showModal.gift}
        handleCloseModal={handleCloseModal}
      />

      <Modal
        size={!successData ? 'xl' : 'lg'}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        show={showModal.assmt ? true : false}
      >
        {!successData ? (
          <>
            {resultData && !successData ? (
              <Assessment
                count={count}
                resultData={resultData}
                value={value}
                handleCloseModal={handleCloseModal}
                rp_user_assmt_id={resultData?.rp_user_assmt_id}
                handleModalSize={handleModalSize}
              />
            ) : (
              ''
            )}
          </>
        ) : (
          <>
            <div className='modal-header'>
              <h5 className='modal-title'>{successData?.header_text}</h5>
            </div>
            <SuccessMsg
              data={successData}
              handleCloseModal={handleCloseModal}
              value='assmt'
            />
          </>
        )}
      </Modal>
    </>
  );
};

export default Banner;
