import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../Common/Sidebar';
import EraFilter from '../Common/EraFilter';
import AdminFilter from '../Common/AdminFilter';
import QuestionAnalytics from '../../../../../../common/QuestionAnalytics';
import ManagerDropdown from '../../../../../../common/ManagerDropdown';
import Skeleton from 'react-loading-skeleton';
import { saveFilters } from '../../../../../../store/filters/actions';
import { saveTabs } from '../../../../../../store/tabs/actions';
import parse from 'html-react-parser';
import RadialBar from '../../../../../../common/RadialBar';
import DoughnutGraph from '../../../../../../common/DoughnutGraph';
import AreaChart from '../../../../../../common/AreaChart';
import CurrencyDropdown from '../../../../../../common/CurrencyDropdown';

const InitialState = {
  managerSelectedOption: '',
  questionSelectedOption: '',
};

const ReportPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [currState, setCurrState] = useState(InitialState);
  const [reportOverviewData, setReportOverviewData] = useState({
    data: '',
    loading: true,
  });
  const [managerOptions, setManagerOptions] = useState({
    data: '',
    loading: true,
  });
  const [questionOptions, setQuestionOptions] = useState({
    data: '',
    loading: true,
  });
  const [questionData, setQuestionData] = useState({
    data: '',
    loading: true,
  });
  const [questionAnalyticsData, setQuestionAnalyticsData] = useState({
    data: '',
    loading: true,
  });
  const [assmtId, setAssmtId] = useState('');
  const [selectedArray, setSelectedArray] = useState({});

  const location = useLocation();
  const loc = location.pathname.split('/');

  const userState = useSelector((state) => state?.user);
  const companyTypeId = useSelector((state) => state?.companyTypeId?.payload);
  const tabState = useSelector(
    (state) => state?.tabs?.analytics?.[`${loc[3]}`]
  );
  const [reportTitle, setReportTitle] = useState();
  const [selectedTabs, setSelectedTabs] = useState();
  const [countryId, setCountryId] = useState();
  const [isCurrencyLoading, setIsCurrencyLoading] = useState(true);

  useEffect(() => {
    window.addEventListener('load', clearFilter);
    return () => {
      window.removeEventListener('load', clearFilter);
    };
  }, []);

  useEffect(() => {
    setSelectedTabs(
      `${state?.selectedEra1Tab?.rp_company_assmt_id},${state?.selectedEra2Tab?.rp_company_assmt_id}`
    );
  }, [state]);

  const clearFilter = (e) => {
    e.preventDefault();
    if (window.$utility.pageAccessedByReload()) {
      dispatch(saveFilters({}, 'report'));
    }
  };

  useEffect(() => {
    dispatch(saveTabs(tabState, 'report'));
  }, [tabState]);

  useEffect(() => {
    setIsCurrencyLoading(true);
    getReportOverview();
  }, [countryId]);

  async function getReportOverview() {
    try {
      let query = {};
      const preQuery = {
        currency_country_id: countryId
          ? countryId
          : reportOverviewData?.data?.currencies?.find(
            (el) => el?.is_selected === true
          )?.value,
        rp_company_assmt_id: selectedTabs,
        rp_company_id: window.$utility.setCompanyId(userState, companyTypeId),
      };
      query = window.$utility.prepareDynamicParamsCustomChart(
        preQuery,
        selectedArray
      );
      const result = await window.$http.getWithQueryAndHeaders(
        `${window.$utility.setApiUrl(
          userState
        )}analytics/people/compare/overview`,
        query
      );
      if (result.code === window.$constants.STATUS.OK) {
        setIsCurrencyLoading(false);
        setReportOverviewData({ data: result.data, loading: false });
        setReportTitle(parse(result?.data?.title));
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getQuestionGraphData() {
    try {
      let query = {};
      const preQuery = {
        is_manager: currState?.managerSelectedOption,
        question_id: currState?.questionSelectedOption,
        rp_company_assmt_id: selectedTabs,
        rp_company_id: window.$utility.setCompanyId(userState, companyTypeId),
      };
      query = window.$utility.prepareDynamicParamsCustomChart(
        preQuery,
        selectedArray
      );
      const result = await window.$http.getWithQueryAndHeaders(
        `${window.$utility.setApiUrl(
          userState
        )}analytics/people/compare/question-analytics`,
        query
      );
      if (result.code === window.$constants.STATUS.OK) {
        setData(result.data?.question_analytics);
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getLookupData() {
    try {
      let query = {};

      query = {
        types: 'manager',
        rp_company_id: window.$utility.setCompanyId(userState, companyTypeId),
      };

      const result = await window.$http.getWithQueryAndHeaders(
        `${window.$utility.setApiUrl(userState)}lookup`,
        query
      );
      if (result.code === window.$constants.STATUS.OK) {
        setManagerOptions({ data: result?.data?.manager, loading: false });
        setQuestionOptions({
          data: result?.data?.people_analytics_questions,
          loading: false,
        });
        setCurrState({
          ...currState,
          managerSelectedOption: result?.data?.manager[0]?.id,
        });
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  }

  function setData(resultData) {
    let allChartData = [];
    if (resultData) {
      const leftChartData = window.$utility.prepareChartData(
        resultData?.left?.chart_data,
        resultData?.left?.chart_data?.chart_type
      );

      const rightChartData = window.$utility.prepareChartData(
        resultData?.right?.chart_data,
        resultData?.right?.chart_data?.chart_type
      );

      allChartData['leftChart'] = leftChartData;
      allChartData['rightChart'] = rightChartData;
      setQuestionAnalyticsData({ data: allChartData, loading: false });
    }
  }

  function queryParamaBuilder() {
    let query = {};
    if (selectedTabs) {
      const preQuery = {
        rp_company_assmt_id: selectedTabs,
        rp_company_id: window.$utility.setCompanyId(userState, companyTypeId),
      };
      query = window.$utility.prepareDynamicParamsCustomChart(
        preQuery,
        selectedArray
      );
    }

    return query;
  }

  function questionsData() {
    if (
      currState?.managerSelectedOption !== '' &&
      currState?.managerSelectedOption !== undefined &&
      currState?.questionSelectedOption !== '' &&
      currState?.questionSelectedOption !== undefined
    ) {
      getQuestionGraphData();
    }
  }

  function apiCalls() {
    if (selectedTabs) {
      setReportOverviewData({ data: '', loading: true });
      setQuestionAnalyticsData({ data: '', loading: true });
      setQuestionData({ data: '', loading: true });
      const query = queryParamaBuilder();
      getReportOverview();
      questionsData();
      getQuestionsData();
    }
  }

  const getQuestionsData = async () => {
    try {
      if (
        currState?.managerSelectedOption === 0 ||
        currState?.managerSelectedOption === 1 ||
        currState?.managerSelectedOption === -1
      ) {
        setQuestionData({ data: '', loading: true });
        let query = {};
        const preQuery = {
          is_manager: currState?.managerSelectedOption,
          rp_company_assmt_id: state?.selectedEra2Tab?.rp_company_assmt_id,
          rp_company_id: window.$utility.setCompanyId(userState, companyTypeId),
        };
        query = window.$utility.prepareDynamicParamsCustomChart(
          preQuery,
          selectedArray
        );
        const result = await window.$http.getWithQueryAndHeaders(
          `${window.$utility.setApiUrl(userState)}analytics/people/questions`,
          query
        );
        if (result.code === window.$constants.STATUS.OK) {
          setQuestionData({ data: result.data, loading: false });
        } else {
          console.log('Something went wrong!');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  //Inbuit functions section

  useEffect(() => {
    if (selectedTabs) {
      getQuestionsData();
    }
  }, [currState?.managerSelectedOption, selectedTabs, companyTypeId]);

  useEffect(() => {
    getLookupData();
  }, [companyTypeId]);

  useEffect(() => {
    apiCalls();
  }, [selectedTabs, companyTypeId]);

  useEffect(() => {
    questionsData();
  }, [currState, companyTypeId, selectedTabs]);

  const getAssmtId = (val) => {
    if (val) {
      setAssmtId(val);
      dispatch(saveTabs(val, 'report'));
    }
  };

  const getFilterValues = (value) => {
    const data = window.$utility.setDynamicFilterOptions(value, selectedArray);
    setSelectedArray(data);
    dispatch(saveFilters(data, 'report'));
  };

  const getSelectedQuestion = (id) => {
    setCurrState({ ...currState, questionSelectedOption: id });
    setQuestionAnalyticsData({ data: '', loading: true });
  };

  const getSelectedManager = (id) => {
    setCurrState({ ...currState, managerSelectedOption: id });
    setQuestionAnalyticsData({ data: '', loading: true });
  };

  const getSelectedCurrency = (id) => {
    // setSelectedCurrency(id);
    setCountryId(
      reportOverviewData?.data?.currencies?.find((el) => el?.label === id)
        ?.value
    );
  };

  return (
    <main>
      <div className='plan_container'>
        <Sidebar
          assmtId={assmtId}
          getFilterValues={getFilterValues}
          apiCalls={apiCalls}
          tabState={tabState}
          selectedTabs={selectedTabs}
          reportValue='report'
        />
        <div className='main_content'>
          <AdminFilter />
          <div className='col-12 mb-4 d-flex justify-content-between'>
            <EraFilter getAssmtId={getAssmtId} state={state} value='report' />
            <div>
              <button
                type='button'
                className='close-compare-btn'
                data-bs-target='#mymodal'
                data-bs-toggle='modal'
                onClick={() => navigate('/dashboard/company-analytics/people')}
              >
                Close
              </button>
            </div>
          </div>
          <div className='bg-white report-conatiner p-4 br-12'>
            <div className='col-12 about z-1'>
              <div className='mb-4'>
                {reportOverviewData?.loading ? (
                  <Skeleton width={1375} height={50} />
                ) : (
                  <h1 className='main-title'>{reportTitle}</h1>
                )}
              </div>
            </div>

            <div className='col-12 d-flex justify-content-around align-items-center mb-4'>
              <div className='col-6 text-center d-flex flex-column align-items-center'>
                {reportOverviewData?.loading ? (
                  <Skeleton circle={true} height={300} width={300} />
                ) : (
                  <>
                    <RadialBar
                      burnout_stage_id={
                        reportOverviewData?.data?.radial?.left
                          ?.rp_gbl_burnout_stage_id
                      }
                      burnout_title={
                        reportOverviewData?.data?.radial?.left?.burnout_title
                      }
                      burnout_data={
                        reportOverviewData?.data?.radial?.left?.chart_data
                      }
                    />
                  </>
                )}
                <div className='mt-2'>
                  <div>{state?.selectedEra1Tab?.display_tab_up}</div>
                  <div>{state?.selectedEra1Tab?.display_tab_down}</div>
                </div>
              </div>
              <div>
                <div className='text-center cost-card-icon'>
                  <i className='bi bi-arrow-right'></i>
                </div>
              </div>
              <div className='col-6 text-center d-flex flex-column align-items-center'>
                {reportOverviewData?.loading ? (
                  <Skeleton circle={true} height={300} width={300} />
                ) : (
                  <RadialBar
                    burnout_stage_id={
                      reportOverviewData?.data?.radial?.right
                        ?.rp_gbl_burnout_stage_id
                    }
                    burnout_title={
                      reportOverviewData?.data?.radial?.right?.burnout_title
                    }
                    burnout_data={
                      reportOverviewData?.data?.radial?.right?.chart_data
                    }
                  />
                )}
                <div className='mt-2'>
                  <div>{state?.selectedEra2Tab?.display_tab_up}</div>
                  <div>{state?.selectedEra2Tab?.display_tab_down}</div>
                </div>
              </div>
            </div>
            <div className='col-12 d-flex justify-content-evenly align-items-center mb-5'>
              {reportOverviewData?.loading ? (
                <Skeleton width={500} height={120} />
              ) : (
                <div className='col-4 participation-card'>
                  <div className='participation-dot'></div>
                  <div className='card-sub-title mb-4'>Total Participation</div>
                  <div className='card-title'>
                    {
                      reportOverviewData?.data?.participation?.left
                        ?.participated_percentage
                    }
                  </div>
                  <div className='card-sub-title'>{`${reportOverviewData?.data?.participation?.left?.participated} of ${reportOverviewData?.data?.participation?.left?.total}`}</div>
                </div>
              )}
              <div className='text-center cost-card-icon'>
                <i className='bi bi-arrow-right'></i>
              </div>
              {reportOverviewData?.loading ? (
                <Skeleton width={500} height={120} />
              ) : (
                <div className='col-4 participation-card'>
                  <div className='participation-dot'></div>
                  <div className='card-sub-title mb-4'>Total Participation</div>
                  <div className='card-title'>
                    {
                      reportOverviewData?.data?.participation?.right
                        ?.participated_percentage
                    }
                  </div>
                  <div className='card-sub-title'>{`${reportOverviewData?.data?.participation?.right?.participated} of ${reportOverviewData?.data?.participation?.right?.total}`}</div>
                </div>
              )}
            </div>

            <div className='col-12 mb-5'>
              <div className='main-sub-title mb-4'>
                {reportOverviewData?.loading ? (
                  <Skeleton width={500} height={70} />
                ) : (
                  reportOverviewData?.data?.factor?.factor_title
                )}
              </div>

              {reportOverviewData?.data?.factor?.factors?.map((el, id) =>
                id % 2 === 0 ? (
                  <>
                    <div className='col-12 box_shadow p-4 br-12 mb-4'>
                      <div className='sub-title fs-5 mb-4'>{el?.title}</div>
                      <div className='d-flex align-items-center'>
                        {reportOverviewData?.loading ? (
                          <Skeleton width={650} height={500} />
                        ) : (
                          <div className='col-7'>
                            <AreaChart
                              data={el?.chart_data?.data}
                              palettecolors={el?.chart_data?.palettecolors}
                              plotGradientColor={
                                el?.chart_data?.plotGradientColor
                              }
                              trendlines={el?.chart_data?.trendlines}
                              type={el?.chart_data?.chart_type}
                            />
                          </div>
                        )}
                        {reportOverviewData?.loading ? (
                          <Skeleton width={650} height={500} />
                        ) : (
                          <div className='col-5 summary'>
                            <ul className='list-disc'>
                              {el?.points?.map((elem) => (
                                <li>{elem ? parse(elem) : ''}</li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className='col-12 box_shadow p-4 br-12 mb-4'>
                      <div className='sub-title fs-5 mb-4'>{el?.title}</div>
                      <div className='d-flex align-items-center'>
                        {reportOverviewData?.loading ? (
                          <Skeleton width={650} height={500} />
                        ) : (
                          <div className='col-5 summary'>
                            <ul className='list-disc'>
                              {el?.points?.map((elem) => (
                                <li>{elem ? parse(elem) : ''}</li>
                              ))}
                            </ul>
                          </div>
                        )}
                        {reportOverviewData?.loading ? (
                          <Skeleton width={650} height={500} />
                        ) : (
                          <div className='col-7'>
                            <AreaChart
                              data={el?.chart_data?.data}
                              palettecolors={el?.chart_data?.palettecolors}
                              plotGradientColor={
                                el?.chart_data?.plotGradientColor
                              }
                              trendlines={el?.chart_data?.trendlines}
                              type={el?.chart_data?.chart_type}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )
              )}
            </div>
            {reportOverviewData?.data?.improvement_stat?.length ? (
              <div className='col-12 mb-5'>
                <div className='stats-li box_shadow p-4 br-12'>
                  <div className='main-sub-title mb-4'>
                    Burnout improvement stats
                  </div>
                  {reportOverviewData?.loading ? (
                    <Skeleton width={700} height={500} />
                  ) : (
                    reportOverviewData?.data?.improvement_stat?.map((el) => (
                      <div className='cnt-wrapper'>
                        <div className='bullet-point'>
                          <span>{el?.circular_data}</span>
                        </div>
                        <div className='cnt'>
                          {el?.title ? parse(el?.title) : ''}
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            ) : (
              ''
            )}
            <div className='col-12 mb-4'>
              <div className='d-flex justify-content-between align-items-center mb-3'>
                <div className='col-9'>
                  <div className='main-sub-title mb-4'>
                    Questionnaire Level Analytics
                  </div>
                </div>
                <div className='col-2'>
                  {managerOptions?.loading ? (
                    <Skeleton height={40} />
                  ) : (
                    <ManagerDropdown
                      data={managerOptions?.data}
                      getSelectedManager={getSelectedManager}
                    />
                  )}
                </div>
              </div>
              {questionData?.loading ? (
                <Skeleton height='30vh' />
              ) : (
                <QuestionAnalytics
                  data={questionData?.data}
                  getSelectedQuestion={getSelectedQuestion}
                  manager={currState?.managerSelectedOption}
                />
              )}
            </div>
            <div className='col-12 mb-5'>
              <div className='d-flex align-items-center justify-content-between position-relative'>
                <div className='col-6'>
                  {questionAnalyticsData?.loading ? (
                    <Skeleton height='46vh' />
                  ) : (
                    <DoughnutGraph
                      data={
                        questionData?.data?.questions?.length
                          ? questionAnalyticsData?.data?.leftChart
                          : {}
                      }
                      height={350}
                      bgColor={'#ffffff'}
                      type={questionAnalyticsData?.data?.leftChart?.type}
                    />
                  )}
                </div>
                <div className='position-absolute z-1' style={{ left: '50%' }}>
                  <div className='text-center cost-card-icon'>
                    <i className='bi bi-arrow-right'></i>
                  </div>
                </div>
                <div className='col-6'>
                  {questionAnalyticsData?.loading ? (
                    <Skeleton height='46vh' />
                  ) : (
                    <DoughnutGraph
                      data={
                        questionData?.data?.questions?.length
                          ? questionAnalyticsData?.data?.rightChart
                          : {}
                      }
                      height={350}
                      bgColor={'#ffffff'}
                      type={questionAnalyticsData?.data?.rightChart?.type}
                    />
                  )}
                </div>
              </div>
            </div>
            {reportOverviewData?.data?.cost == null ? (
              ''
            ) : (
              <div className='col-12'>
                <div className='box_shadow br-12 p-4'>
                  {reportOverviewData?.loading ? (
                    <Skeleton width={1375} height={500} />
                  ) : (
                    <>
                      <div className='d-flex justify-content-between align-items-center mb-5'>
                        <div className='col-9'>
                          <div className='main-sub-title mb-4'>
                            {
                              reportOverviewData?.data?.cost?.organisation
                                ?.title
                            }
                          </div>
                        </div>
                        <div className='col-2' style={{ width: '12%' }}>
                          <CurrencyDropdown
                            data={reportOverviewData?.data?.currencies}
                            getSelectedCurrency={getSelectedCurrency}
                          />
                        </div>
                      </div>
                      {isCurrencyLoading ? (
                        <Skeleton width={1375} height={300} />
                      ) : (
                        <>
                          <div className='cost-card-container'>
                            <div className='cost-card col-3'>
                              <div className='card-date'>
                                {
                                  reportOverviewData?.data?.cost?.organisation
                                    ?.left?.title
                                }{' '}
                                <br />{' '}
                                {
                                  reportOverviewData?.data?.cost?.organisation
                                    ?.left?.subtitle
                                }
                              </div>
                              <div className='total-cost'>
                                <span>
                                  {reportOverviewData?.data?.currency}
                                </span>
                                {
                                  reportOverviewData?.data?.cost?.organisation
                                    ?.left?.cost
                                }
                              </div>
                            </div>
                            <div className='text-center cost-card-icon'>
                              <i className='bi bi-arrow-right'></i>
                            </div>
                            <div className='cost-card col-3'>
                              <div className='card-date'>
                                {
                                  reportOverviewData?.data?.cost?.organisation
                                    ?.right?.title
                                }{' '}
                                <br />{' '}
                                {
                                  reportOverviewData?.data?.cost?.organisation
                                    ?.right?.subtitle
                                }
                              </div>
                              <div className='total-cost'>
                                <span>
                                  {reportOverviewData?.data?.currency}
                                </span>
                                {
                                  reportOverviewData?.data?.cost?.organisation
                                    ?.right?.cost
                                }
                              </div>
                            </div>
                          </div>
                          <div className='cost-card-container'>
                            <div className='col-3 cost-card-container-title fw-7'>
                              {
                                reportOverviewData?.data?.cost?.organisation
                                  ?.saving_text
                              }
                            </div>
                            <div className='text-center cost-card-icon'>
                              <i className='bi bi-arrow-right'></i>
                            </div>
                            <div className='col-3'>
                              <div className='cost-card-mini'>
                                <div className='total-cost'>
                                  <span>
                                    {reportOverviewData?.data?.currency}
                                  </span>
                                  {
                                    reportOverviewData?.data?.cost?.organisation
                                      ?.saving_amount
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {reportOverviewData?.loading ? (
                    <Skeleton width={1330} height={500} />
                  ) : (
                    <>
                      <div className='main-sub-title mb-4'>
                        {reportOverviewData?.data?.cost?.employee?.title}
                      </div>
                      {isCurrencyLoading ? (
                        <Skeleton width={1375} height={300} />
                      ) : (
                        <>
                          <div className='cost-card-container'>
                            <div className='cost-card col-3'>
                              <div className='card-date'>
                                {
                                  reportOverviewData?.data?.cost?.employee?.left
                                    ?.title
                                }{' '}
                                <br />{' '}
                                {
                                  reportOverviewData?.data?.cost?.employee?.left
                                    ?.subtitle
                                }
                              </div>
                              <div className='total-cost'>
                                <span>
                                  {reportOverviewData?.data?.currency}
                                </span>
                                {
                                  reportOverviewData?.data?.cost?.employee?.left
                                    ?.cost
                                }
                              </div>
                            </div>
                            <div className='text-center cost-card-icon'>
                              <i className='bi bi-arrow-right'></i>
                            </div>
                            <div className='cost-card col-3'>
                              <div className='card-date'>
                                {
                                  reportOverviewData?.data?.cost?.employee
                                    ?.right?.title
                                }{' '}
                                <br />{' '}
                                {
                                  reportOverviewData?.data?.cost?.employee
                                    ?.right?.subtitle
                                }
                              </div>
                              <div className='total-cost'>
                                <span>
                                  {reportOverviewData?.data?.currency}
                                </span>
                                {
                                  reportOverviewData?.data?.cost?.employee
                                    ?.right?.cost
                                }
                              </div>
                            </div>
                          </div>
                          <div className='cost-card-container '>
                            <div className='col-3 cost-card-container-title fw-7'>
                              {
                                reportOverviewData?.data?.cost?.employee
                                  ?.saving_text
                              }
                            </div>
                            <div className='text-center cost-card-icon'>
                              <i className='bi bi-arrow-right'></i>
                            </div>
                            <div className='col-3'>
                              <div className='cost-card-mini'>
                                <div className='total-cost'>
                                  <span>
                                    {reportOverviewData?.data?.currency}
                                  </span>
                                  {/* <span>{currencyIcon}</span> */}
                                  {
                                    reportOverviewData?.data?.cost?.employee
                                      ?.saving_amount
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                  <div className='note-section'>
                    <div className='mb-2'>Note:</div>
                    <ul>
                      {reportOverviewData?.loading ? (
                        <Skeleton width={700} height={70} />
                      ) : (
                        reportOverviewData?.data?.cost?.note?.map((el) => (
                          <li>{el}</li>
                        ))
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default ReportPage;
