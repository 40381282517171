import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BarChart from '../../../../../../common/Barchart';
import LineChart from '../../../../../../common/Linechart';
import parse from 'html-react-parser';
import Skeleton from 'react-loading-skeleton';

const ChartCard = (props) => {
  const [chartButton, setChartButton] = useState('historic');
  const [category, setCategory] = useState([]);
  const [dataset1, setDataset1] = useState([]);
  const [barCategory, setBarCategory] = useState([]);
  const [barData, setBarData] = useState([]);
  const [chartResultById, setChartResultById] = useState('');
  const [chartTitle, setChartTitle] = useState('Historic');
  const userState = useSelector((state) => state?.user);

  async function handleChartButtonClick(buttonValue, type) {
    if (buttonValue === 'age_gender') {
      setChartResultById('');
      getChartDataByIdAndType(buttonValue, type);
    } else {
      setChartTitle('Historic');
    }
    setChartButton(buttonValue);
  }

  async function getChartDataByIdAndType(type, sectionId) {
    try {
      const result = await window.$http.getWithQueryAndHeaders(
        `${window.$utility.setApiUrl(
          userState
        )}analytics/people/explore-section/${sectionId}/${type}`,
        props?.query
      );
      if (result.code === window.$constants.STATUS.OK) {
        setChartResultById(result.data);
        setChartTitle(result?.data?.chart_title);
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    prepareChartData(props?.chartData?.data);
  }, [props?.chartData?.data]);

  useEffect(() => {
    prepareBarChartData(chartResultById?.data);
  }, [chartResultById]);

  async function prepareChartData(chartData) {
    let cat = [];
    let data1 = [];
    for (const item of chartData) {
      cat.push({ label: item?.label });
      data1.push({ value: item?.avg_section_score });
    }
    setCategory(cat);
    setDataset1(data1);
  }

  async function prepareBarChartData(chartData) {
    let labelArray = [];
    let dataset = [];
    let labels = [...new Set(chartData?.map((item) => item?.labels))];
    let serieses = [...new Set(chartData?.map((item) => item?.age_title))];

    if (labels && labels.length) {
      for (const item of labels) {
        labelArray.push({ label: item });
      }
    }

    if (serieses && serieses.length) {
      for (const series of serieses) {
        let barData = [];
        let barDataArray = [];
        barData['seriesname'] = series;
        if (labels && labels.length) {
          for (const label of labels) {
            barDataArray.push(
              chartData
                .filter(
                  (item) => item.labels === label && item.age_title === series
                )
                .map((item) => {
                  return { value: item?.section_average_pct };
                })
            );
          }
          barData['data'] = barDataArray;
          dataset.push(barData);
        }
      }
    }

    let finalDataSet = [];
    if (dataset && dataset.length) {
      for (const dataItem of dataset) {
        var finalData = [];
        for (const item of dataItem.data) {
          finalData.push({
            value: item && item[0]?.value ? item[0]?.value : 0,
          });
        }
        finalDataSet.push({ seriesname: dataItem.seriesname, data: finalData });
      }
    }

    if (finalDataSet && finalDataSet.length) {
      setBarCategory(labelArray);
      setBarData(finalDataSet);
    } else {
      setBarCategory('');
      setBarData('');
    }
  }

  return (
    <div className='col-12'>
      {props?.index & 1 ? (
        <div className='analytic-card position-relative mb-5'>
          <div className='dots-styles-top'></div>
          <div className='col-md-12 col-lg-5 ps-0 ps-lg-4 z-1'>
            <div className='result-sub-hed chart1'>
              Assessment - {chartTitle}
            </div>
            <div className='result-titel gradient-text'>
              {props?.chartData?.title}
            </div>
            <div className='result-dic gradient-text fw-normal'>
              {props?.chartData?.description
                ? parse(props?.chartData?.description)
                : ''}
            </div>
            <div className='trend-button mt-5'>
              <div className='more-titel'>
                More on {props?.chartData?.title}
              </div>
              <button
                onClick={() =>
                  handleChartButtonClick(
                    'historic',
                    props?.chartData?.rp_assmt_section_id
                  )
                }
                className={`btn btn-rp-secondary me-3 ${
                  chartButton === 'historic' ? 'active-btn' : ''
                }`}
              >
                Historic
              </button>
              <button
                onClick={() =>
                  handleChartButtonClick(
                    'age_gender',
                    props?.chartData?.rp_assmt_section_id
                  )
                }
                className={`btn btn-rp-secondary ${
                  chartButton === 'age_gender' ? 'active-btn' : ''
                }`}
              >
                Age/Gender
              </button>
            </div>
          </div>
          <div className='trend-graph bg-white col-md-12 col-lg-7 mt-4 mt-lg-0 br-12 z-1'>
            <div
              className={`${
                props?.chartButton === 'historic' ? 'historic_1' : 'gender_1'
              }`}
            >
              {chartButton === 'historic' ? (
                props?.chartData?.data ? (
                  <LineChart category={category} dataset1={dataset1} />
                ) : (
                  <Skeleton width='100%' height='300px' />
                )
              ) : chartResultById ? (
                <BarChart labels={barCategory} dataset={barData} />
              ) : (
                <Skeleton width='100%' height='300px' />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className='analytic-card position-relative mb-5'>
          <div className='dots-styles-top-left'></div>
          <div className='trend-graph bg-white col-md-12 col-lg-7 mt-4 mt-lg-0 br-12 z-1'>
            <div
              className={`${
                props?.chartButton === 'historic' ? 'historic_1' : 'gender_1'
              }`}
            >
              {chartButton === 'historic' ? (
                props?.chartData?.data ? (
                  <LineChart category={category} dataset1={dataset1} />
                ) : (
                  <Skeleton width='90%' height='300px' />
                )
              ) : chartResultById ? (
                <BarChart labels={barCategory} dataset={barData} />
              ) : (
                <Skeleton width='90%' height='300px' />
              )}
            </div>
          </div>
          <div className='col-md-12 col-lg-5 ps-0 ps-lg-4 z-1'>
            <div className='result-sub-hed chart1'>
              Assessment - {chartTitle}
            </div>
            <div className='result-titel gradient-text'>
              {props?.chartData?.title}
            </div>
            <div className='result-dic gradient-text fw-normal'>
              {props?.chartData?.description
                ? parse(props?.chartData?.description)
                : ''}
            </div>
            <div className='trend-button mt-5'>
              <div className='more-titel'>
                More on {props?.chartData?.title}
              </div>
              <button
                onClick={() =>
                  handleChartButtonClick(
                    'historic',
                    props?.chartData?.rp_assmt_section_id
                  )
                }
                className={`btn btn-rp-secondary me-3 ${
                  chartButton === 'historic' ? 'active-btn' : ''
                }`}
              >
                Historic
              </button>
              <button
                onClick={() =>
                  handleChartButtonClick(
                    'age_gender',
                    props?.chartData?.rp_assmt_section_id
                  )
                }
                className={`btn btn-rp-secondary ${
                  chartButton === 'age_gender' ? 'active-btn' : ''
                }`}
              >
                Age/Gender
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChartCard;
