import { SAVERANDOMATTEMPTS, REMOVERANDOMATTEMPTS } from './types';

const INITIAL_STATE = {};

const randomAttempts = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SAVERANDOMATTEMPTS: {
      return {
        ...state,
        ...payload.data,
      };
    }
    case REMOVERANDOMATTEMPTS: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default randomAttempts;
