import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Scroll from 'react-scroll';
import LoadingSpinner from '../LoadingSpinner';
import { saveLangId } from '../../store/langId/actions';
import { saveRandomAttempts } from '../../store/random-attempts/actions';
import Questionnarie from './Components/QuestionData';
import { saveUserAssmtId } from '../../store/userAssmtId/actions';
import Spinner from 'react-bootstrap/Spinner';

const ScrollLink = Scroll.Link;

const Assessment = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(3);
  const [index, setIndex] = useState(0);
  const [showButton, setShowButton] = useState('next');
  const [progressWidth, setProgressWidth] = useState('');
  const [selectedData, setSelectedData] = useState([]);
  const [totalView, setTotalView] = useState(1);
  const [result, setResult] = useState([]);
  const [currData, setCurrData] = useState([]);
  const dataLimitPerPage = 3;
  const [activeLanguage, setActiveLanguage] = useState();
  const langId = useSelector((state) => state.langId?.payload);
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  useEffect(() => {
    if (props.resultData?.rp_user_assmt_id) {
      dispatch(saveUserAssmtId(props.resultData?.rp_user_assmt_id));
    }
    if (selectedData?.length > 0) {
      setSelectedData([]);
    }
  }, [props, props?.value]);

  useEffect(() => {
    if (langId) {
      setActiveLanguage(langId);
    } else {
      if (props?.resultData) {
        setActiveLanguage(props?.resultData?.languages[0]?.rp_gbl_language_id);
      }
    }
  }, [props?.resultData, langId]);

  useEffect(() => {
    if (activeLanguage !== langId && activeLanguage) {
      dispatch(saveLangId(activeLanguage));
    }
  }, [activeLanguage]);

  const handleQuestionnaire = async (e) => {
    e.preventDefault();
    try {
      setIsBtnLoading(true);
      const result = await window.$http.postWithHeaders('assessment/save', {
        rp_user_assmt_id: props.resultData?.rp_user_assmt_id,
      });

      if (result.code === window.$constants.STATUS.OK) {
        if (!props?.resultData?.is_company_assmt) {
          dispatch(saveRandomAttempts(result));
          return navigate(`/dashboard/assessments/show-my-result`);
        } else if (props?.resultData?.is_company_assmt) {
          return props.handleModalSize(result);
        }
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
    setIsBtnLoading(false);
  };

  const handleChecked = async (optId, quesId) => {
    const setData = {
      id: Math.random().toString(),
      quesId: quesId,
      optId: optId,
    };

    if (selectedData?.some((data) => data.quesId === quesId)) {
      var index = selectedData?.findIndex((data) => data.quesId === quesId);
      selectedData[index] = setData;
    } else {
      selectedData?.push(setData);
    }

    try {
      const result = await window.$http.postWithHeaders(
        'assessment/question/response',
        {
          rp_user_assmt_id: props.resultData?.rp_user_assmt_id,
          rp_assmt_question_id: quesId,
          rp_assmt_option_id: optId,
        }
      );

      if (result.code !== window.$constants.STATUS.OK) {
        console.log('Something went wrong');
      }
    } catch (error) {
      console.log(error);
    }

    setSelectedData((prevData) => [...selectedData]);
  };

  useEffect(() => {
    const getNewArray = () => {
      setIndex((i) => totalView * dataLimitPerPage - dataLimitPerPage);
      setLimit((l) => index + dataLimitPerPage);
      setResult(props?.resultData?.questions?.slice(index, limit));
    };
    getNewArray();
  }, [totalView, index, limit, props?.resultData?.questions, props?.count]);

  const handleNext = () => {
    setTotalView((prevView) => prevView + 1);

    if (`${totalView + 1}` === props?.count) {
      setShowButton('finish');
    }
  };

  useEffect(() => {
    const currentData = (totalView) => {
      let currIndex = totalView - 1;
      currIndex = currIndex + currIndex + totalView;
      const currLimit = currIndex + 3;
      setCurrData(
        props?.resultData?.questions?.slice(currIndex - 1, currLimit - 1)
      );
    };

    currentData(totalView);
  }, [props?.resultData?.questions, totalView]);

  const handlePrevious = () => {
    setShowButton('next');
    setTotalView((prevView) => prevView - 1);
  };

  useEffect(() => {
    const maxWidth = () => {
      const result = (limit / props?.resultData?.questions?.length) * 100;
      setProgressWidth(result);
    };
    maxWidth();
  }, [limit, props?.resultData?.questions]);

  const isEqual = (currSet, optSet) => {
    for (let i = 0; i < currSet?.length; i++) {
      if (optSet?.indexOf(currSet[i]) === -1) {
        return false;
      }
    }
    return true;
  };

  const handleLanguage = (value) => {
    setActiveLanguage(value);
  };

  return (
    <>
      {props?.resultData ? (
        <>
          {props?.resultData?.is_company_assmt && (
            <div className='modal-header'>
              <h5 className='modal-title' id='staticBackdropLabel'>
                Organization Resiliency Assessment (RP-ORA)
              </h5>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={props.handleCloseModal}
              ></button>
            </div>
          )}
          <div
            className={`questionnaire bg-white br-12 ${
              !props?.resultData?.is_company_assmt ? 'box_shadow' : ''
            } `}
          >
            {props?.resultData?.languages?.map((el) => el)?.length > 1 ? (
              <div
                className='sidenav'
                style={
                  props?.resultData?.is_company_assmt ? { top: '80px' } : {}
                }
              >
                {props?.resultData?.languages?.map((el, id) => (
                  <div
                    key={id}
                    onClick={() => handleLanguage(el?.rp_gbl_language_id)}
                  >
                    <button
                      href='#!'
                      className={
                        activeLanguage !== el?.rp_gbl_language_id
                          ? 'lang-btn'
                          : 'lang-btn active'
                      }
                    >
                      {el?.language_name}
                    </button>
                  </div>
                ))}
              </div>
            ) : (
              ''
            )}
            {result?.map((data, id) => (
              <div className='questions' key={data?.rp_assmt_question_id}>
                <Questionnarie
                  data={data}
                  id={id}
                  handleData={handleChecked}
                  valueSet={selectedData}
                  resultData={props?.resultData?.questions}
                  langCode={activeLanguage}
                />
              </div>
            ))}
            <div className='next-button d-flex justify-content-between mt-5 pt-4'>
              <button
                className={
                  index
                    ? 'btn btn-next btn-rp-primary'
                    : 'btn btn-next btn-rp-primary disabled'
                }
                onClick={handlePrevious}
              >
                Previous
              </button>
              {currData && window.innerWidth < 768 && (
                <ScrollLink smooth={true} duration={500} to='assessment'>
                  <button
                    className={
                      isEqual(
                        currData?.map((data) => data?.rp_assmt_question_id),
                        selectedData?.map((data) => data?.quesId)
                      )
                        ? showButton === 'finish' && isBtnLoading
                          ? 'btn btn-next btn-rp-primary disabled'
                          : 'btn btn-next btn-rp-primary'
                        : 'btn btn-next btn-rp-primary disabled'
                    }
                    onClick={
                      showButton === 'next' ? handleNext : handleQuestionnaire
                    }
                  >
                    {isBtnLoading ? (
                      <Spinner
                        as='i'
                        animation='border'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    ) : showButton === 'next' ? (
                      'Next'
                    ) : props?.resultData?.is_company_assmt ? (
                      'Finish'
                    ) : (
                      'Result'
                    )}
                  </button>
                </ScrollLink>
              )}
              {currData && window.innerWidth > 768 && (
                <button
                  className={
                    isEqual(
                      currData?.map((data) => data?.rp_assmt_question_id),
                      selectedData?.map((data) => data?.quesId)
                    )
                      ? showButton === 'finish' && isBtnLoading
                        ? 'btn btn-next btn-rp-primary disabled'
                        : 'btn btn-next btn-rp-primary'
                      : 'btn btn-next btn-rp-primary disabled'
                  }
                  onClick={
                    showButton === 'next' ? handleNext : handleQuestionnaire
                  }
                >
                  {isBtnLoading ? (
                    <Spinner
                      as='i'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : showButton === 'next' ? (
                    'Next'
                  ) : props?.resultData?.is_company_assmt ? (
                    'Finish'
                  ) : (
                    'Result'
                  )}
                </button>
              )}
            </div>
          </div>
          <div
            className={
              props?.resultData?.is_company_assmt
                ? 'test-progress primary-gradient'
                : 'test-progress br-12 primary-gradient'
            }
          >
            <div className='progress-header d-flex justify-content-between'>
              <span className='part'>
                Resiliency Assessment{' '}
                {props?.resultData?.is_company_assmt ? '(RP-ORA)' : '(RP-ERA)'}
              </span>
              {props?.resultData?.questions?.length > 0 ? (
                <span className='questions-left'>
                  {props?.resultData?.questions?.length - selectedData?.length}{' '}
                  questions remaining
                </span>
              ) : (
                ''
              )}
            </div>
            <div className='progress'>
              <div
                className='progress-bar'
                role='progressbar'
                style={{ width: `${progressWidth}%` }}
                aria-valuenow='25'
                aria-valuemin='0'
                aria-valuemax='100'
              ></div>
            </div>
          </div>
        </>
      ) : (
        <div className='centered' style={{ marginLeft: '35rem' }}>
          <LoadingSpinner />
        </div>
      )}
    </>
  );
};

export default Assessment;
