import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Column2dGraph from '../../../../common/Column2dGraph';
import MSColumn2dGraph from '../../../../common/MSColumn2dGraph';
import Pie2dGraph from '../../../../common/Pie2dGraph';
import Skeleton from 'react-loading-skeleton';
import ScrollToTopButton from '../../../../common/ScrollToTopButton';
import EraFilter from './Components/Common/EraFilter';
import Message from './Components/Common/Message';
import { isEmpty } from 'lodash';
import Sidebar from './Components/Common/Sidebar';
import AdminFilter from './Components/Common/AdminFilter';
import StackedColumnChart from '../../../../common/StackedColumnChart';
import { saveFilters } from '../../../../store/filters/actions';
import { saveTabs } from '../../../../store/tabs/actions';

const Dashbaord = (props) => {
  //Variables section
  const dispatch = useDispatch();
  const [topLocationsBurnoutData, setTopLocationsBurnoutData] = useState({
    data: '',
    loading: true,
  });
  const [topGroupsBurnoutData, setTopGroupsBurnoutData] = useState({
    data: '',
    loading: true,
  });
  const [burnoutGenderData, setBurnoutGenderData] = useState({
    data: '',
    loading: true,
  });
  const [burnoutManagerData, setBurnoutManagerData] = useState({
    data: '',
    loading: true,
  });
  const [burnoutAgeGroupGenderData, setBurnoutAgeGroupGenderData] = useState({
    data: '',
    loading: true,
  });
  const [burnoutAgeGroupManagerData, setBurnoutAgeGroupManagerData] = useState({
    data: '',
    loading: true,
  });
  const [totalParticipationGenderData, setTotalParticipationGenderData] =
    useState({
      data: '',
      loading: true,
    });
  const [totalParticipationManagerData, setTotalParticipationManagerData] =
    useState({
      data: '',
      loading: true,
    });
  const [stressAgeGenderData, setStressAgeGenderData] = useState({
    data: '',
    loading: true,
  });
  const [stressGroupData, setStressGroupData] = useState({
    data: '',
    loading: true,
  });
  const [stressLocationData, setStressLocationData] = useState({
    data: '',
    loading: true,
  });
  const [customChartOptions, setCustomChartOptions] = useState({
    data: [],
    loading: true,
  });
  const [result, setResult] = useState([]);
  const [customChartData, setCustomChartData] = useState({
    data: [],
    loading: true,
  });
  const [assmtId, setAssmtId] = useState('');
  const [selectedArray, setSelectedArray] = useState({});
  const [eraStatus, setEraStatus] = useState('');
  const [eraMsg, setEraMsg] = useState('');
  const [reminderEnabledValue, setReminderEnabledValue] = useState('');
  const [isStressAssessment, setIsStressAssessment] = useState();
  const location = useLocation();
  const loc = location.pathname.split('/');

  const userState = useSelector((state) => state?.user);
  const companyTypeId = useSelector((state) => state?.companyTypeId?.payload);
  const tabState = useSelector(
    (state) => state?.tabs?.analytics?.[`${loc[3]}`]
  );

  useEffect(() => {
    window.addEventListener('load', clearFilter);
    return () => {
      window.addEventListener('load', clearFilter);
    };
  }, []);

  const clearFilter = (e) => {
    e.preventDefault();
    if (window.$utility.pageAccessedByReload()) {
      dispatch(saveFilters({}, 'dashboard'));
    }
  };

  useEffect(() => {
    dispatch(saveTabs(tabState, 'dashboard'));
  }, [tabState]);

  useEffect(() => {
    getCustomCharts();
  }, [companyTypeId]);

  const getCustomCharts = async () => {
    try {
      const query = {
        rp_company_id: window.$utility.setCompanyId(userState, companyTypeId),
      };
      const result = await window.$http.getWithQueryAndHeaders(
        `${window.$utility.setApiUrl(
          userState
        )}analytics/dashboard/custom_charts`,
        query
      );
      if (result?.code === window?.$constants?.STATUS?.OK) {
        setResult(result?.data?.custom_charts);
        setCustomChartOptions({
          data: result?.data?.custom_charts?.map((el) => el?.chart_name),
          loading: false,
        });
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAssmtId = (val) => {
    if (val) {
      setAssmtId(val);
      dispatch(saveTabs(val, 'dashboard'));
    }
  };

  const getFilterValues = (value) => {
    const data = window.$utility.setDynamicFilterOptions(value, selectedArray);
    setSelectedArray(data);
    dispatch(saveFilters(data, 'dashboard'));
  };

  //Custom functions section
  async function getChartDataByParameter(parameter) {
    try {
      const preQuery = {
        type: parameter,
        rp_company_assmt_id: tabState,
        rp_company_id: window.$utility.setCompanyId(userState, companyTypeId),
      };
      if (tabState) {
        const query = window.$utility.prepareDynamicParamsCustomChart(
          preQuery,
          selectedArray
        );

        const result = await window.$http.getWithQueryAndHeaders(
          `${window.$utility.setApiUrl(userState)}analytics/dashboard`,
          query
        );
        if (result.code === window.$constants.STATUS.OK) {
          setData(result?.data, parameter);
        } else {
          console.log('Something went wrong!');
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  function setData(resultData, parameter) {
    let data = window.$utility.prepareChartData(
      resultData,
      resultData?.chart_type
    );
    if (parameter === 'top-locations-burnout') {
      setTopLocationsBurnoutData({ data: data, loading: false });
    } else if (parameter === 'top-groups-burnout') {
      setTopGroupsBurnoutData({ data: data, loading: false });
    } else if (parameter === 'burnout-gender') {
      setBurnoutGenderData({ data: data, loading: false });
    } else if (parameter === 'burnout-manager') {
      setBurnoutManagerData({ data: data, loading: false });
    } else if (parameter === 'burnout-age-group-gender') {
      setBurnoutAgeGroupGenderData({ data: data, loading: false });
    } else if (parameter === 'burnout-age-group-manager') {
      setBurnoutAgeGroupManagerData({ data: data, loading: false });
    } else if (parameter === 'total-participation-gender') {
      setTotalParticipationGenderData({ data: data, loading: false });
    } else if (parameter === 'total-participation-manager') {
      setTotalParticipationManagerData({ data: data, loading: false });
    } else if (parameter === 'stress-age-gender') {
      setStressAgeGenderData({ data: data, loading: false });
    } else if (parameter === 'stress-group') {
      setStressGroupData({ data: data, loading: false });
    } else if (parameter === 'stress-location') {
      setStressLocationData({ data: data, loading: false });
    } else if (customChartOptions?.data) {
      for (let param of customChartOptions?.data) {
        if (param === parameter) {
          let customData = customChartData?.data;
          customData[param] = data;
          setCustomChartData({ data: customData, loading: false });
        }
      }
    }
  }

  function apiCalls() {
    setTopLocationsBurnoutData({ data: '', loading: true });
    setTopGroupsBurnoutData({ data: '', loading: true });
    setBurnoutGenderData({ data: '', loading: true });
    setBurnoutManagerData({ data: '', loading: true });
    setBurnoutAgeGroupGenderData({ data: '', loading: true });
    setBurnoutAgeGroupManagerData({ data: '', loading: true });
    setTotalParticipationGenderData({ data: '', loading: true });
    setTotalParticipationManagerData({ data: '', loading: true });
    setStressAgeGenderData({ data: '', loading: true });
    setStressGroupData({ data: '', loading: true });
    setStressLocationData({ data: '', loading: true });
    setCustomChartData({ data: [], loading: true });

    let parameterArray = [
      'top-locations-burnout',
      'top-groups-burnout',
      'burnout-gender',
      'burnout-manager',
      'burnout-age-group-gender',
      'burnout-age-group-manager',
      'total-participation-gender',
      'total-participation-manager',
      'participation-chart-discipline',
      'participation-chart-level',
      'stress-age-gender',
      'stress-group',
      'stress-location',
    ];
    if (parameterArray) {
      for (let param of parameterArray) {
        getChartDataByParameter(param);
      }
    }

    // if (customChartOptions?.data) {
    //   for (let param of customChartOptions?.data) {
    //     getChartDataByParameter(param);
    //   }
    // }
  }

  //Inbuit functions section

  useEffect(() => {
    apiCalls();
  }, [tabState, companyTypeId]);

  function renderChart(el) {
    return (
      <>
        {customChartData?.data[el?.chart_name]?.type === 'column2d' ? (
          <div id='chart-container'>
            {customChartData?.data[el?.chart_name]?.loading ? (
              <Skeleton width='100%' height='300px' />
            ) : (
              <Column2dGraph
                data={customChartData?.data[el?.chart_name]}
                height={300}
                type={customChartData?.data[el?.chart_name]?.type}
              />
            )}
          </div>
        ) : (
          ''
        )}
        {customChartData?.data[el?.chart_name]?.type === 'pie2d' ? (
          <div id='chart-containerpie'>
            {customChartData?.data[el?.chart_name]?.loading ? (
              <Skeleton width='100%' height='350px' />
            ) : (
              <Pie2dGraph
                data={customChartData?.data[el?.chart_name]}
                height={350}
                type={customChartData?.data[el?.chart_name]?.type}
              />
            )}
          </div>
        ) : (
          ''
        )}
        {customChartData?.data[el?.chart_name]?.type === 'stackedcolumn2d' ||
        customChartData?.data[el?.chart_name]?.type ===
          'scrollstackedcolumn2d' ? (
          <div className='col-12 d-flex'>
            {customChartData?.data[el?.chart_name]?.loading ? (
              <Skeleton width='1000px' height='390px' />
            ) : (
              customChartData?.data[el?.chart_name] &&
              Object.keys(customChartData?.data[el?.chart_name]).map(
                (data, index) => (
                  <div key={index} className='col-6'>
                    <div>
                      <StackedColumnChart
                        data={customChartData?.data[el?.chart_name]}
                        xAxisName={
                          customChartData?.data[el?.chart_name]?.xAxisName
                        }
                        yAxisName={
                          customChartData?.data[el?.chart_name]?.yAxisName
                        }
                        separator={data}
                        height={390}
                        categories={
                          customChartData?.data[el?.chart_name]?.categories
                        }
                        type={customChartData?.data[el?.chart_name]?.type}
                      />
                    </div>
                  </div>
                )
              )
            )}
          </div>
        ) : (
          ''
        )}
        {customChartData?.data[el?.chart_name]?.type === 'MSColumn2D' ? (
          <div id='chart-container3'>
            {customChartData?.data[el?.chart_name]?.loading ? (
              <Skeleton width='100%' height='400px' />
            ) : (
              <MSColumn2dGraph
                data={customChartData?.data[el?.chart_name]}
                xAxisName={customChartData?.data[el?.chart_name]?.xAxisName}
                yAxisName={customChartData?.data[el?.chart_name]?.yAxisName}
                height={400}
                type={customChartData?.data[el?.chart_name]?.type}
              />
            )}
          </div>
        ) : (
          ''
        )}
      </>
    );
  }

  const getEraStatus = (val, msg, remVal, stressAssmt) => {
    setEraStatus(val);
    setEraMsg(msg);
    setReminderEnabledValue(remVal);
    setIsStressAssessment(stressAssmt);
  };

  return (
    <main>
      <div className='plan_container'>
        <Sidebar
          assmtId={assmtId}
          getFilterValues={getFilterValues}
          apiCalls={apiCalls}
          tabState={tabState}
        />
        <div className='main_content'>
          <AdminFilter />
          <div className='row'>
            <div className='col-12 mb-4'>
              <EraFilter
                apiCalls={apiCalls}
                getAssmtId={getAssmtId}
                tab={props?.tab}
                getEraStatus={getEraStatus}
              />
            </div>
            {eraStatus ? (
              <>
                {isEmpty(burnoutGenderData?.data?.message) ? (
                  <>
                    {/* <div className='col-12 p-4 br-12 justify-content-between d-flex flex-wrap'></div> */}
                    <div className='col-12'>
                      <div className=' mb-4 p-3 br-8 bg-white'>
                        <div className='col-12 d-flex'>
                          <div className='col-6'>
                            <div className='question-anlytics_graph p-3'>
                              <div className='title'>
                                <p className='rp-primary-dark-g fw-6 mb-4'>
                                  {totalParticipationGenderData?.data?.title}
                                </p>
                              </div>
                              <div id='chart-containerpie'>
                                {totalParticipationGenderData?.loading ? (
                                  <Skeleton width='100%' height='350px' />
                                ) : totalParticipationGenderData?.data
                                    ?.length ? (
                                  <Pie2dGraph
                                    data={totalParticipationGenderData?.data}
                                    height={250}
                                    type={
                                      totalParticipationGenderData?.data?.type
                                    }
                                  />
                                ) : (
                                  <p
                                    style={{
                                      textAlign: 'center',
                                      marginTop: '200px',
                                    }}
                                  >
                                    No Data
                                  </p>
                                )}
                              </div>
                              <p
                                style={{
                                  color: '#e02726',
                                  textAlign: 'center',
                                  fontSize: '13px',
                                }}
                              >
                                {
                                  totalParticipationGenderData?.data
                                    ?.chartMessage
                                }
                              </p>
                            </div>
                          </div>

                          <div className='col-6'>
                            <div className='question-anlytics_graph p-3'>
                              <div className='title'>
                                <p className='rp-primary-dark-g fw-6 mb-4'>
                                  {totalParticipationManagerData?.data?.title}
                                </p>
                              </div>
                              <div id='chart-containerpie2'>
                                {totalParticipationManagerData?.loading ? (
                                  <Skeleton width='100%' height='350px' />
                                ) : totalParticipationManagerData?.data
                                    ?.length ? (
                                  <Pie2dGraph
                                    data={totalParticipationManagerData?.data}
                                    height={250}
                                    type={
                                      totalParticipationManagerData?.data?.type
                                    }
                                  />
                                ) : (
                                  <p
                                    style={{
                                      textAlign: 'center',
                                      marginTop: '200px',
                                    }}
                                  >
                                    No Data
                                  </p>
                                )}
                              </div>
                              <p
                                style={{
                                  color: '#e02726',
                                  textAlign: 'center',
                                  fontSize: '13px',
                                }}
                              >
                                {
                                  totalParticipationManagerData?.data
                                    ?.chartMessage
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {result?.map((el) => (
                        <>
                          {el?.container_name?.includes(
                            'participation_charts'
                          ) ? (
                            customChartData?.data[el?.chart_name]?.length ? (
                              <div
                                style={
                                  el?.additional_class === 'full-container'
                                    ? { width: '100%' }
                                    : { width: '49%' }
                                }
                                className=' mb-4 p-3 br-8 box_shadow'
                              >
                                <p className='rp-primary-dark-g fw-6 mb-4'>
                                  {customChartData?.data[el?.chart_name]?.title}
                                </p>
                                {renderChart(el)}
                                <p
                                  style={{
                                    color: '#e02726',
                                    textAlign: 'center',
                                    fontSize: '13px',
                                  }}
                                >
                                  {customChartData?.data?.chartMessage}
                                </p>
                              </div>
                            ) : (
                              ''
                            )
                          ) : (
                            ''
                          )}
                        </>
                      ))}
                      <div className=' mb-4 p-3 br-8 bg-white'>
                        <div className='col-12 d-flex'>
                          <div className='col-6'>
                            <div className='question-anlytics_graph p-3'>
                              <div className='title'>
                                <p className='rp-primary-dark-g fw-6 mb-4'>
                                  {topLocationsBurnoutData?.data?.title}
                                </p>
                              </div>
                              <div id='chart-container'>
                                {topLocationsBurnoutData?.loading ? (
                                  <Skeleton width='100%' height='300px' />
                                ) : (
                                  <Column2dGraph
                                    data={topLocationsBurnoutData?.data}
                                    height={300}
                                    type={topLocationsBurnoutData?.data?.type}
                                  />
                                )}
                              </div>
                              <p
                                style={{
                                  color: '#e02726',
                                  textAlign: 'center',
                                  fontSize: '13px',
                                }}
                              >
                                {topLocationsBurnoutData?.data?.chartMessage}
                              </p>
                            </div>
                          </div>
                          <div className='col-6'>
                            <div className='question-anlytics_graph p-3'>
                              <div className='title'>
                                <p className='rp-primary-dark-g fw-6 mb-4'>
                                  {topGroupsBurnoutData?.data?.title}
                                </p>
                              </div>
                              <div id='chart-container2'>
                                {topGroupsBurnoutData?.loading ? (
                                  <Skeleton width='100%' height='300px' />
                                ) : (
                                  <Column2dGraph
                                    data={topGroupsBurnoutData?.data}
                                    height={300}
                                    type={topGroupsBurnoutData?.data?.type}
                                  />
                                )}
                              </div>
                              <p
                                style={{
                                  color: '#e02726',
                                  textAlign: 'center',
                                  fontSize: '13px',
                                }}
                              >
                                {topGroupsBurnoutData?.data?.chartMessage}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {result?.map((el) => (
                        <>
                          {el?.container_name?.includes(
                            'top_burnout_charts'
                          ) ? (
                            customChartData?.data[el?.chart_name]?.length ? (
                              <div
                                style={
                                  el?.additional_class === 'full-container'
                                    ? { width: '100%' }
                                    : { width: '49%' }
                                }
                                className=' mb-4 p-3 br-8 box_shadow'
                              >
                                <p className='rp-primary-dark-g fw-6 mb-4'>
                                  {customChartData?.data[el?.chart_name]?.title}
                                </p>
                                {renderChart(el)}
                                <p
                                  style={{
                                    color: '#e02726',
                                    textAlign: 'center',
                                    fontSize: '13px',
                                  }}
                                >
                                  {
                                    customChartData?.data[el?.chart_name]
                                      ?.chartMessage
                                  }
                                </p>
                              </div>
                            ) : (
                              ''
                            )
                          ) : (
                            ''
                          )}
                        </>
                      ))}
                      <div className='question-anlytics_graph p-3'>
                        <div className='title'>
                          <p className='rp-primary-dark-g fw-6 mb-4'>
                            {burnoutGenderData?.data?.title}
                          </p>
                        </div>
                        <div id='chart-container3'>
                          {burnoutGenderData?.loading ? (
                            <Skeleton width='100%' height='400px' />
                          ) : (
                            <MSColumn2dGraph
                              data={burnoutGenderData?.data}
                              xAxisName={burnoutGenderData?.data?.xAxisName}
                              yAxisName={burnoutGenderData?.data?.yAxisName}
                              height={400}
                              type={burnoutGenderData?.data?.type}
                            />
                          )}
                        </div>
                        <p
                          style={{
                            color: '#e02726',
                            textAlign: 'center',
                            fontSize: '13px',
                          }}
                        >
                          {burnoutGenderData?.data?.chartMessage}
                        </p>
                      </div>
                      <div
                        style={{ width: '100%' }}
                        className='mb-4 p-3 br-8 bg-white'
                      >
                        <p className='rp-primary-dark-g text-center fw-6 mb-4'>
                          {burnoutManagerData?.data?.title}
                        </p>
                        <div className='col-12 d-flex'>
                          {burnoutManagerData?.loading ? (
                            <Skeleton width='1370px' height='390px' />
                          ) : (
                            <>
                              {burnoutManagerData?.data?.length
                                ? burnoutManagerData?.data?.map(
                                    (data, index) => (
                                      <div key={index} className='col-6'>
                                        <div id='burnoutManagers_pie'>
                                          <Pie2dGraph
                                            data={data}
                                            height={250}
                                            type={
                                              burnoutManagerData?.data?.type
                                            }
                                          />
                                        </div>
                                        <p className='text-center mb-4'>
                                          {data[0]?.separator}
                                        </p>
                                      </div>
                                    )
                                  )
                                : 'No Data'}
                            </>
                          )}
                        </div>
                        <p
                          style={{
                            color: '#e02726',
                            textAlign: 'center',
                            fontSize: '13px',
                          }}
                        >
                          {burnoutManagerData?.data?.chartMessage}
                        </p>
                      </div>
                      <div
                        style={{ width: '100%' }}
                        className='mb-4 p-3 br-8 bg-white'
                      >
                        <p className='rp-primary-dark-g text-center fw-6 mb-4'>
                          {burnoutAgeGroupGenderData?.data?.title}
                        </p>

                        <div className='col-12 row justify-content-center'>
                          {burnoutAgeGroupGenderData?.loading ? (
                            <Skeleton width='1370px' height='390px' />
                          ) : (
                            burnoutAgeGroupGenderData?.data?.data &&
                            Object.keys(
                              burnoutAgeGroupGenderData?.data?.data
                            ).map((data, index) => (
                              <div key={index} className='col-6'>
                                <div id='burnoutMale'>
                                  <StackedColumnChart
                                    data={
                                      burnoutAgeGroupGenderData?.data?.data[
                                        data
                                      ]
                                    }
                                    xAxisName={
                                      burnoutAgeGroupManagerData?.data
                                        ?.xAxisName
                                    }
                                    yAxisName={
                                      burnoutAgeGroupManagerData?.data
                                        ?.yAxisName
                                    }
                                    separator={data}
                                    height={390}
                                    categories={
                                      burnoutAgeGroupGenderData?.data
                                        ?.categories
                                    }
                                    type={
                                      burnoutAgeGroupManagerData?.data?.type
                                    }
                                  />
                                </div>
                                <p
                                  style={{
                                    color: '#e02726',
                                    textAlign: 'center',
                                    fontSize: '13px',
                                  }}
                                >
                                  {
                                    burnoutAgeGroupGenderData?.data
                                      ?.chartMessage
                                  }
                                </p>
                              </div>
                            ))
                          )}
                        </div>
                      </div>
                      <div
                        style={{ width: '100%' }}
                        className='p-3 br-8 bg-white'
                      >
                        <p className='rp-primary-dark-g text-center fw-6 mb-4'>
                          {burnoutAgeGroupManagerData?.data?.title}
                        </p>
                        <div className='col-12 d-flex'>
                          {burnoutAgeGroupManagerData?.loading ? (
                            <Skeleton width='1370px' height='390px' />
                          ) : (
                            burnoutAgeGroupManagerData?.data?.data &&
                            Object.keys(
                              burnoutAgeGroupManagerData?.data?.data
                            ).map((data, index) => (
                              <div key={index} className='col-6'>
                                <div>
                                  <StackedColumnChart
                                    data={
                                      burnoutAgeGroupManagerData?.data?.data[
                                        data
                                      ]
                                    }
                                    xAxisName={
                                      burnoutAgeGroupManagerData?.data
                                        ?.xAxisName
                                    }
                                    yAxisName={
                                      burnoutAgeGroupManagerData?.data
                                        ?.yAxisName
                                    }
                                    separator={data}
                                    height={390}
                                    categories={
                                      burnoutAgeGroupManagerData?.data
                                        ?.categories
                                    }
                                    type={
                                      burnoutAgeGroupManagerData?.data?.type
                                    }
                                  />
                                </div>
                                <p
                                  style={{
                                    color: '#e02726',
                                    textAlign: 'center',
                                    fontSize: '13px',
                                  }}
                                >
                                  {
                                    burnoutAgeGroupManagerData?.data
                                      ?.chartMessage
                                  }
                                </p>
                              </div>
                            ))
                          )}
                        </div>
                      </div>

                      {isStressAssessment ? (
                        <>
                          <div className='col-12 '>
                            <div
                              style={{
                                backgroundColor: 'rgba(224, 39, 38, .2)',
                                borderRadius: '8px',
                                border: '1px solid rgb(244, 39, 38)',
                              }}
                              className='p-4'
                            >
                              <h2
                                className='section-titel section-heading mb-4'
                                style={{ fontSize: '42px', color: '#42526e' }}
                              >
                                Stress Analytics
                              </h2>
                              <div className=' mb-4 p-3 br-8 bg-white'>
                                <p className='rp-primary-dark-g text-center fw-6 mb-4'>
                                  {stressGroupData?.data?.title}
                                </p>
                                <div className='col-12'>
                                  <div id='chart-container3'>
                                    {stressGroupData?.loading ? (
                                      <Skeleton width='100%' height='400px' />
                                    ) : (
                                      <StackedColumnChart
                                        data={
                                          stressGroupData?.data?.data['data']
                                        }
                                        xAxisName={
                                          stressGroupData?.data?.xAxisName
                                        }
                                        yAxisName={
                                          stressGroupData?.data?.yAxisName
                                        }
                                        separator={''}
                                        height={390}
                                        categories={
                                          stressGroupData?.data?.categories
                                        }
                                        bgColor={'#ffffff'}
                                        type={stressGroupData?.data?.type}
                                      />
                                    )}
                                  </div>
                                  <p
                                    style={{
                                      color: '#e02726',
                                      textAlign: 'center',
                                      fontSize: '13px',
                                    }}
                                  >
                                    {stressGroupData?.data?.chartMessage}
                                  </p>
                                </div>
                              </div>
                              <div className=' mb-4 p-3 br-8 bg-white'>
                                <p className='rp-primary-dark-g text-center fw-6 mb-4'>
                                  {stressLocationData?.data?.title}
                                </p>
                                <div className='col-12'>
                                  <div id='chart-container3'>
                                    {stressLocationData?.loading ? (
                                      <Skeleton width='100%' height='400px' />
                                    ) : (
                                      <StackedColumnChart
                                        data={
                                          stressLocationData?.data?.data['data']
                                        }
                                        xAxisName={
                                          stressLocationData?.data?.xAxisName
                                        }
                                        yAxisName={
                                          stressLocationData?.data?.yAxisName
                                        }
                                        separator={''}
                                        height={390}
                                        categories={
                                          stressLocationData?.data?.categories
                                        }
                                        bgColor={'#ffffff'}
                                        type={stressLocationData?.data?.type}
                                      />
                                    )}
                                  </div>
                                  <p
                                    style={{
                                      color: '#e02726',
                                      textAlign: 'center',
                                      fontSize: '13px',
                                    }}
                                  >
                                    {stressLocationData?.data?.chartMessage}
                                  </p>
                                </div>
                              </div>
                              <div
                                style={{ width: '100%' }}
                                className='mb-4 p-3 br-8 bg-white'
                              >
                                <p className='rp-primary-dark-g text-center fw-6 mb-4'>
                                  {stressAgeGenderData?.data?.title}
                                </p>
                                <div className='col-12 row justify-content-center'>
                                  {stressAgeGenderData?.loading ? (
                                    <Skeleton width='1370px' height='390px' />
                                  ) : (
                                    stressAgeGenderData?.data?.data &&
                                    Object.keys(
                                      stressAgeGenderData?.data?.data
                                    ).map((data, index) => (
                                      <div key={index} className='col-6'>
                                        <div id='burnoutMale'>
                                          <StackedColumnChart
                                            data={
                                              stressAgeGenderData?.data?.data[
                                                data
                                              ]
                                            }
                                            xAxisName={
                                              stressAgeGenderData?.data
                                                ?.xAxisName
                                            }
                                            yAxisName={
                                              stressAgeGenderData?.data
                                                ?.yAxisName
                                            }
                                            separator={data}
                                            height={390}
                                            categories={
                                              stressAgeGenderData?.data
                                                ?.categories
                                            }
                                            type={
                                              stressAgeGenderData?.data?.type
                                            }
                                          />
                                        </div>
                                        <p
                                          style={{
                                            color: '#e02726',
                                            textAlign: 'center',
                                            fontSize: '13px',
                                          }}
                                        >
                                          {
                                            stressAgeGenderData?.data
                                              ?.chartMessage
                                          }
                                        </p>
                                      </div>
                                    ))
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </>
                ) : (
                  <h6 className='text-center'>
                    {burnoutGenderData?.data?.message}
                  </h6>
                )}
              </>
            ) : eraStatus === '' ? (
              ''
            ) : (
              <Message
                tab={props?.tab}
                msg={eraMsg}
                reminderEnabledValue={reminderEnabledValue}
              />
            )}
            <ScrollToTopButton />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Dashbaord;
