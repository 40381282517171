import React from 'react';
import parse from 'html-react-parser';

const SuccessMsg = (props) => {
  return (
    <>
      <div className='modal-body'>
        <div
          className='thanq_page mt-3'
          id='nav-thanks'
          role='tabpanel'
          aria-labelledby='nav-thanks-tab'
          style={
            props.value && {
              color: 'grey',
            }
          }
        >
          {props.msg ? props.msg : ''}
          {props?.data?.description ? parse(props?.data?.description) : ''}
          {props?.data?.note ? parse(props?.data?.note) : ''}
        </div>
      </div>
      <div className='modal-footer'>
        <div className='text-end mt-4 fs-5'>
          {props?.handleCloseModal ? (
            <button
              type='button'
              className='btn btn-rp-primary btn-small'
              data-bs-dismiss='modal'
              onClick={props.handleCloseModal}
            >
              OK
            </button>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
};

export default SuccessMsg;
