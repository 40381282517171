import React from 'react';
import { Spinner } from 'react-bootstrap';

const Authentication = (props) => {
  return (
    <>
      <div className='wlcm-note mt-6'>
        <h6>Two Factor Authentication</h6>
        <p>
          Your code has been sent to your registered mobile number or email id
        </p>
      </div>
      <div className='my-4'>
        <div className='d-flex align-items-center'>
          <input
            type='number'
            maxLength='6'
            max='6'
            className={
              !props.otpValid
                ? 'form-control ps-2 border-danger'
                : 'form-control ps-2'
            }
            placeholder='Enter Authentication Code'
            value={props.otpCode}
            onChange={props.otpChangeHandler}
            onKeyDown={(evt) =>
              ['e', '+', '-'].includes(evt.key) && evt.preventDefault()
            }
            onKeyPress={(e) =>
              e.key === 'Enter' || e.key === 'NumpadEnter'
                ? props.handleVerifyCode()
                : ''
            }
          />
        </div>
      </div>

      <div className='form-check form-switch my-3'>
        <input
          className='form-check-input'
          type='checkbox'
          role='switch'
          id='rememberMe'
          onClick={props.handleRememberMeEnable}
        />
        <label className='form-check-label'>Remember me for 30 days</label>
      </div>
      <div className='d-flex justify-content-between align-items-center mb-3'>
        <button
          className={
            props.counter === 0
              ? 'link-color cursor-p me-4'
              : 'link-color cursor-p me-4 disabled'
          }
          onClick={props.resendCode}
        >
          Resend authentication code
        </button>
        {props.mfaValue || props.isResendClicked ? (
          props.counter > 0 ? (
            <span className='rp-primary-red-color'>
              <span className='rp-primary-red-color' id='time'>
                {props.counter}
              </span>{' '}
              Sec.
            </span>
          ) : (
            ''
          )
        ) : (
          ''
        )}
        <div className='bck-log text-end'>
          <button onClick={() => props?.handleAuthRoute('login')}>
            Back to login
          </button>
        </div>
      </div>
      <button
        type='button'
        className={`btn btn-login mt-2 ${props?.isLoading ? 'disabled' : ''}`}
        onClick={props.handleVerifyCode}
      >
        {props?.isLoading ? (
          <Spinner
            as='i'
            animation='border'
            size='sm'
            role='status'
            aria-hidden='true'
            style={{ color: '#fff' }}
          />
        ) : (
          'Verify'
        )}
      </button>
    </>
  );
};

export default Authentication;
