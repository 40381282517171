import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Popconfirm } from 'antd';
import Header from '../../../layout/Components/Header';
import Footer from '../../../layout/Components/Footer';
import InviteMore from '../../../common/Modals/InviteMore';
import '../../../config';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isEmpty } from 'lodash';

const Invite = () => {
  const [showModal, setShowModal] = useState('');
  const [userData, setUserData] = useState();
  const tableId = document.getElementById('table');
  const columnLength = tableId?.rows[0]?.cells?.length;
  const [rpProfileId, setRpProfileId] = useState();
  const [reminderValueSet, setReminderValueSet] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [resendState, setResendState] = useState({
    set: [],
    isLoading: false,
    rpProfileId: '',
  });
  const [userModal, setUserModal] = useState(false);

  useEffect(() => {
    getUserReferrralData();
  }, []);

  const getUserReferrralData = async () => {
    try {
      const result = await window.$http.getWithHeaders('user/referral');
      if (result.code === window.$constants.STATUS.OK) {
        setUserData(result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseModal = () => {
    setShowModal('');
    setUserModal(false);
    getUserReferrralData();
  };

  const handleShowModal = (value, id) => {
    setRpProfileId(id);
    setShowModal(value);
    setUserModal(true);
  };

  const handleDeleteUser = async (profileId) => {
    try {
      const result = await window.$http.delete(`delete/referral/${profileId}`);
      if (result?.data?.code === window.$constants.STATUS.OK) {
        getUserReferrralData();
        toast.success(result?.data?.message);
      } else {
        result?.data?.message
          ? toast.error(result?.data?.message)
          : toast.error('Error occured, please try again or contact admin.');
      }
    } catch (error) {
      toast.error('Error occured, please try again or contact admin.');
      console.log(error);
    }
  };

  const handleSendReminder = async (rp_profile_id) => {
    setRpProfileId(rp_profile_id);
    reminderValueSet.push(rp_profile_id);
    setReminderValueSet(reminderValueSet);
    setIsLoading(true);
    try {
      const result = await window.$http.postWithHeaders(
        `referral/assmt/reminder/${rp_profile_id}`
      );
      if (result.code === window.$constants.STATUS.OK) {
        setIsLoading(false);
        setReminderValueSet((curr) =>
          curr.filter((value) => {
            return value !== rp_profile_id;
          })
        );
        toast.success(result?.message);
      } else {
        setIsLoading(false);
        setReminderValueSet((curr) =>
          curr.filter((value) => {
            return value !== rp_profile_id;
          })
        );
        result?.message
          ? toast.error(result?.message)
          : toast.error('Error occured, please try again or contact admin.');
      }
    } catch (err) {
      setIsLoading(false);
      setReminderValueSet((curr) =>
        curr.filter((value) => {
          return value !== rp_profile_id;
        })
      );
      toast.error('Error occured, please try again or contact admin.');
      console.log(err);
    }
  };

  const handleResendEmail = async (rp_profile_id) => {
    resendState?.set?.push(rp_profile_id);
    setResendState({
      rpProfileId: rp_profile_id,
      set: resendState?.set,
      isLoading: true,
    });

    try {
      const result = await window.$http.postWithHeaders(
        `referral/welcome/mail/${rp_profile_id}`
      );

      if (result.code === window.$constants.STATUS.OK) {
        setResendState({
          ...resendState,
          isLoading: false,
          set: resendState?.set?.filter((value) => {
            return value !== rp_profile_id;
          }),
        });
        return toast.success(result?.message);
      } else {
        setResendState({
          ...resendState,
          isLoading: false,
          set: resendState?.set?.filter((value) => {
            return value !== rp_profile_id;
          }),
        });
        return toast.error(result?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Header />
      <section>
        <div className='container container_2'>
          <div className='row'>
            <div className='br-12 bg-light p-4 box_shadow'>
              <div className='mb-4 d-flex justify-content-between align-items-center'>
                <div className='rp-primary-red-color'>
                  <span className='fw-bold rp-primary-red-color'> Note:</span>{' '}
                  You can add upto 3 people only
                </div>
                <button
                  data-bs-toggle='modal'
                  data-bs-target='#invite'
                  type='button'
                  className='btn btn-rp-primary'
                  disabled={userData?.data?.is_add_more_disabled}
                  onClick={() => handleShowModal('inviteMore')}
                >
                  Invite
                </button>
              </div>
              <div className='table-responsive'>
                <table className='table align-middle invite-table' id='table'>
                  <thead>
                    <tr height='50' valign='middle'>
                      <th width='190'>Name</th>
                      <th width='290'>E-mail</th>
                      <th width='170'>Relationship</th>
                      <th width='150'>Welcome Invitation</th>
                      <th width='130'>On-boarding Completed</th>
                      <th width='100'>Assessment Completed</th>
                      <th width='100'>Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {!isEmpty(userData?.data?.result) ? (
                      userData?.data?.result?.map((data, id) => (
                        <tr height='65 ' className='tr-group-item' key={id}>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div
                                className='fw-bold cursor-p'
                                data-bs-toggle='modal'
                                data-bs-target='#invite'
                                onClick={() =>
                                  handleShowModal(
                                    'inviteMore',
                                    data?.rp_profile_id
                                  )
                                }
                              >
                                {(!isEmpty(data?.first_name)
                                  ? data?.first_name
                                  : '') +
                                  ' ' +
                                  (!isEmpty(data?.last_name)
                                    ? data?.last_name
                                    : '')}
                              </div>
                            </div>
                          </td>
                          <td>{data?.email}</td>
                          <td>{data?.relation_title}</td>
                          <td className='downlode_result'>
                            {' '}
                            {data?.can_delete_user_profile
                              ? 'Unopened'
                              : 'Accepted'}{' '}
                            {data?.can_delete_user_profile ? (
                              <span
                                className={
                                  resendState?.isLoading &&
                                  (resendState?.rpProfileId ===
                                    data?.rp_profile_id ||
                                    resendState.set?.some(
                                      (el) => el === data?.rp_profile_id
                                    ))
                                    ? 'btn btn-rp-secondary btn-sm me-1 disabled'
                                    : 'btn btn-rp-secondary btn-sm me-1'
                                }
                                title='Resend Welcome Email'
                                onClick={() =>
                                  handleResendEmail(data?.rp_profile_id)
                                }
                              >
                                {resendState?.isLoading &&
                                (resendState?.rpProfileId ===
                                  data?.rp_profile_id ||
                                  resendState?.set?.some(
                                    (el) => el === data?.rp_profile_id
                                  )) ? (
                                  <Spinner
                                    as='i'
                                    animation='border'
                                    size='sm'
                                    role='status'
                                    aria-hidden='true'
                                  />
                                ) : (
                                  <i className='fa-solid fa-paper-plane'></i>
                                )}
                              </span>
                            ) : (
                              ''
                            )}
                          </td>
                          <td>{data?.is_user_onboard}</td>
                          <td className='downlode_result'>
                            <span className='me-1'>
                              {data?.rp_user_assmt_id > 0 ? 'Yes' : 'No'}
                            </span>
                            {data?.can_send_assmt_reminder ? (
                              <Popconfirm
                                placement='topRight'
                                title={
                                  data?.assmt_reminder_date
                                    ? `Last reminder was sent on ${moment(
                                        data?.assmt_reminder_date
                                      ).format('MMM DD, YYYY, h:mm A')}
                                          Are you sure you still want to send
                                          reminder?`
                                    : 'Are you sure you want to send reminder?'
                                }
                                onConfirm={() =>
                                  handleSendReminder(data?.rp_profile_id)
                                }
                                overlayInnerStyle={{ width: '412px' }}
                                okText='Yes'
                                cancelText='No'
                                overlayClassName='antdReminderButton'
                              >
                                <span
                                  className={
                                    isLoading &&
                                    (rpProfileId === data?.rp_profile_id ||
                                      reminderValueSet?.some(
                                        (el) => el === data?.rp_profile_id
                                      ))
                                      ? 'btn btn-rp-secondary btn-sm disabled'
                                      : 'btn btn-rp-secondary btn-sm'
                                  }
                                  title='Assessment Reminder'
                                >
                                  {isLoading &&
                                  (rpProfileId === data?.rp_profile_id ||
                                    reminderValueSet?.some(
                                      (el) => el === data?.rp_profile_id
                                    )) ? (
                                    <Spinner
                                      as='i'
                                      animation='border'
                                      size='sm'
                                      role='status'
                                      aria-hidden='true'
                                    />
                                  ) : (
                                    <i className='fa-solid fa-stopwatch'></i>
                                  )}
                                </span>
                              </Popconfirm>
                            ) : (
                              ''
                            )}
                          </td>
                          <td>
                            {data?.can_delete_user_profile ? (
                              <Popconfirm
                                placement='topRight'
                                title='Are you sure you want to delete?'
                                onConfirm={() =>
                                  handleDeleteUser(data?.rp_profile_id)
                                }
                                okText='Yes'
                                cancelText='No'
                                overlayClassName='antdButton'
                              >
                                <span
                                  className='btn btn-sm btn-rp-primary-red'
                                  title='Delete User'
                                >
                                  <i className='fa-solid fa-trash-can'></i>
                                </span>
                              </Popconfirm>
                            ) : (
                              ''
                            )}
                          </td>
                        </tr>
                      ))
                    ) : userData?.code ? (
                      <tr className='tr-group-item'>
                        <td
                          colspan={columnLength}
                          height='80'
                          style={{ borderRadius: 'unset' }}
                          className='text-center'
                        >
                          No result found
                        </td>
                      </tr>
                    ) : (
                      ''
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
      <Footer addClass='fixed-bottom' />

      {showModal === 'inviteMore' && (
        <InviteMore
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          rpProfileId={rpProfileId}
          userModal={userModal}
        />
      )}
    </>
  );
};

export default Invite;
