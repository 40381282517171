import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import '../../../config';
import { isEmpty } from 'lodash';
import { useTour } from '@reactour/tour';

const HeaderTabs = () => {
  const { setIsOpen, isOpen } = useTour();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user);

  let location = useLocation();
  let loc = location.pathname.split('/');

  useEffect(() => {
    if (
      (loc[1] === 'dashboard' &&
        loc[2] &&
        loc[2] !== 'assessments' &&
        !loc[3]) ||
      loc[1] !== 'dashboard' ||
      (loc[3] && loc[3] === 'result')
    ) {
      setIsOpen(false);
    }
  }, [loc]);

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <div className='tabs-group py-2'>
      {userData?.visible_header_tabs?.length && (
        <>
          {userData?.visible_header_tabs?.some((el) => el === 'assessment') ? (
            <button
              className={
                !userData?.is_web_enabled
                  ? 'head-tab asses-tab disabled'
                  : (loc[2] !== 'resiliency-plan' &&
                      loc[2] !== 'company-analytics' &&
                      loc[2] === 'assessments') ||
                    location.pathname === '/dashboard/' ||
                    location.pathname === '/dashboard'
                  ? 'head-tab active'
                  : 'head-tab'
              }
              onClick={() => handleNavigate('/dashboard/assessments')}
            >
              Assessments
            </button>
          ) : (
            ''
          )}
          {userData?.visible_header_tabs?.some((el) => el === 'plan') ? (
            <button
              className={
                isOpen || !userData?.is_web_enabled
                  ? 'head-tab plan-tab disabled'
                  : loc[2] === 'resiliency-plan'
                  ? 'head-tab plan-tab active'
                  : 'head-tab plan-tab'
              }
              onClick={() => handleNavigate('/dashboard/resiliency-plan')}
            >
              Resiliency Plan
            </button>
          ) : (
            ''
          )}
          {userData?.is_company_admin ? (
            <>
              {!isEmpty(userData?.analytics_visible_options) ? (
                userData?.visible_header_tabs?.some(
                  (el) => el === 'analytic'
                ) ? (
                  <button
                    className={
                      !userData?.is_web_enabled
                        ? 'head-tab asses-tab disabled'
                        : loc[2] === 'company-analytics'
                        ? 'head-tab d-none d-xl-inline active'
                        : 'head-tab d-none d-xl-inline'
                    }
                    onClick={() =>
                      handleNavigate('/dashboard/company-analytics')
                    }
                  >
                    Analytics
                  </button>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              {!isEmpty(userData?.admin_visible_options) &&
              userData?.admin_visible_options !== null ? (
                userData?.visible_header_tabs?.some((el) => el === 'admin') ? (
                  <button
                    className={
                      !userData?.is_web_enabled
                        ? 'head-tab asses-tab disabled'
                        : loc[2] === 'admin' || loc[1] === 'manage'
                        ? 'head-tab d-none d-xl-inline active'
                        : 'head-tab d-none d-xl-inline'
                    }
                    onClick={() => handleNavigate('/manage')}
                  >
                    Admin
                  </button>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
            </>
          ) : (
            ''
          )}
        </>
      )}
    </div>
  );
};

export default HeaderTabs;
