import CompanyAnalytics from '../../../pages/Dashboard/Components/CompanyAnalytics';
import Dashboard from '../../../pages/Dashboard/Components/CompanyAnalytics/Dashboard';
import People from '../../../pages/Dashboard/Components/CompanyAnalytics/People';
import Corporate from '../../../pages/Dashboard/Components/CompanyAnalytics/Corporate';
import Workshops from '../../../pages/Dashboard/Components/CompanyAnalytics/Workshops';
import ReportPage from '../../../pages/Dashboard/Components/CompanyAnalytics/Components/People/ReportPage';

const AnalyticsRoutes = [
  {
    url: '/',
    name: 'Company Analytics',
    element: CompanyAnalytics,
  },
  {
    url: '/dashboard',
    name: 'Company Dashboard',
    element: Dashboard,
  },
  {
    url: '/corporate',
    name: 'Corporate Resiliency',
    element: Corporate,
  },
  {
    url: '/people',
    name: 'People Resiliency',
    element: People,
  },
  {
    url: '/workshop',
    name: 'Workshops',
    element: Workshops,
  },
  {
    url: '/report/:selectedEra1Tab/:selectedEra2Tab',
    name: 'Report Page',
    element: ReportPage,
  },
];

export default AnalyticsRoutes;
