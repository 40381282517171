import React, { useState } from 'react';

const QuestionData = (props) => {
  const [optionId, setOptionId] = useState('');

  const handleChecked = async (optId, quesId) => {
    setOptionId(optId);

    props.handleData(optId, quesId);
  };

  const getQuestionNo = (content) => {
    if (props?.resultData?.length) {
      return (
        props?.resultData?.findIndex((el) => el?.question_text === content) + 1
      );
    }
  };

  return (
    <>
      <div className='question' key={props?.id}>
        <p>
          {getQuestionNo(props?.data?.question_text)}.{' '}
          {props?.data?.question_desc[props.langCode]}
        </p>
      </div>
      <div className='options'>
        <div
          className='btn-group'
          role='group'
          aria-label='Basic radio toggle button group'
        >
          {props?.data?.options?.map((optionData, id) => (
            <div
              key={id}
              className='options btn-group'
              onClick={() =>
                handleChecked(
                  optionData?.rp_assmt_option_id,
                  props?.data?.rp_assmt_question_id
                )
              }
            >
              <label
                key={id}
                className={
                  optionId === id + 1 ||
                  props?.valueSet?.some(
                    (data) =>
                      data?.quesId === props?.data?.rp_assmt_question_id &&
                      data?.optId === optionData?.rp_assmt_option_id
                  )
                    ? 'btn btn-options active-opt'
                    : 'btn btn-options'
                }
                htmlFor={optionData?.option_title}
              >
                <input
                  key={id}
                  type='radio'
                  className={
                    optionId === id + 1 ? 'btn-check active-opt' : 'btn-check'
                  }
                  name='radio'
                  autoComplete='off'
                />
                {optionData?.option_desc[props.langCode]}
              </label>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default QuestionData;
