import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../../../../../../../common/LoadingSpinner';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Skeleton from 'react-loading-skeleton';

const RecipeModal = (props) => {
  const userState = useSelector((state) => state?.user);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [isEmailLoading, setIsEmailLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageData, setImageData] = useState([]);

  useEffect(() => {
    getImages(props?.recipeModalResult?.image);
  }, [props?.recipeModalResult?.image]);

  const getImages = async (id, key) => {
    try {
      if (id) {
        setLoading(true);
        const result = await window.$http.getWithHeaders(
          `media/images/${id}/food_diet`
        );
        if (result.code === window.$constants.STATUS.OK) {
          setImageData(result?.data);
          if (result?.data?.map((el) => el?.id)) {
            setLoading(false);
          }
        } else {
          console.log(result);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const downloadRecipe = async () => {
    setIsDownloadLoading(true);
    try {
      const result = await window.$http.getWithHeaders(
        `food-diet/recipe/${props.textId}/download`
      );
      if (result.code === window.$constants.STATUS.OK) {
        setIsDownloadLoading(false);
        var linkSource = result?.data?.media_content;
        var downloadLink = document.createElement('a');
        var fileName = result?.data?.filename
          ? result?.data?.filename
          : 'recipe.pdf';
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      } else {
        setIsDownloadLoading(false);
        result?.message
          ? toast.error(result?.message)
          : toast.error('Error occured, please try again or contact admin.');
      }
    } catch (error) {
      setIsDownloadLoading(false);
      console.log(error);
    }
  };

  const emailRecipe = async () => {
    setIsEmailLoading(true);
    try {
      const result = await window.$http.postWithHeaders(
        `food-diet/recipe/${props.textId}/email`,
        {
          addresses: userState?.email,
        }
      );

      if (result.code === window.$constants.STATUS.OK) {
        setIsEmailLoading(false);
        toast.success(result?.message);
      } else {
        setIsEmailLoading(false);
        result?.message
          ? toast.error(result?.message)
          : toast.error('Error occured, please try again or contact admin.');
      }
    } catch (err) {
      setIsEmailLoading(false);
      toast.error('Error occured, please try again or contact admin.');
      console.log(err);
    }
  };

  return (
    <>
      <Modal
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        scrollable={true}
        show={props.showModal ? true : false}
      >
        {props?.recipeModalResult && !props.isModalLoading ? (
          <>
            <Modal.Header className='px-4'>
              <h5 className='modal-title'>
                {props?.recipeModalResult?.modal_title}
              </h5>
              <button
                type='button'
                className='btn-close'
                aria-label='Close'
                onClick={props.handleCloseModal}
              ></button>
            </Modal.Header>
            <Modal.Body>
              <div className='d-flex justify-content-between'>
                {props?.ingredients != null &&
                props?.ingredients != '' &&
                props?.ingredients?.length != 0 ? (
                  <div className='recipe-cnt pe-4'>
                    <span className='recipe-ing-title'>Ingredients</span>
                    <ul className='ul_disc mt-2'>
                      {props?.ingredients?.map((data, id) => (
                        <li className='' key={id}>
                          {data}
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  ''
                )}

                <div className='dite-image' style={{ width: '45%' }}>
                  {loading ? (
                    <Skeleton width='100%' height='230px' />
                  ) : (
                    imageData &&
                    imageData?.map((elem) =>
                      props?.recipeModalResult?.image === elem?.id ? (
                        <img
                          src={
                            !elem?.image_data ? (
                              <Skeleton width='100%' height='230px' />
                            ) : (
                              elem?.image_data
                            )
                          }
                        ></img>
                      ) : (
                        ''
                      )
                    )
                  )}
                </div>
              </div>

              {props?.modalDesc != null &&
              props?.modalDesc != '' &&
              props?.modalDesc?.length != 0 ? (
                <div>
                  <span className='recipe-ing-title'>Recipe</span>
                  <ul className='ul_disc mt-2'>
                    {props?.modalDesc?.map((data, id) => (
                      <li className=''>{data}</li>
                    ))}
                  </ul>
                </div>
              ) : (
                ''
              )}

              {props?.recipeModalResult?.note ? (
                <p>
                  <span className='fw-6 rp-primary-red-color'>Note:</span>{' '}
                  {props?.recipeModalResult?.note}
                </p>
              ) : (
                ''
              )}
            </Modal.Body>
          </>
        ) : (
          <div className='centered' style={{ marginLeft: '1rem' }}>
            <LoadingSpinner />
          </div>
        )}
        <Modal.Footer>
          <div className='mt-4'>
            <button
              className='btn btn-rp-primary me-3'
              onClick={downloadRecipe}
              disabled={isDownloadLoading && true}
            >
              {' '}
              {isDownloadLoading ? (
                <Spinner
                  as='i'
                  animation='border'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
              ) : (
                'Download Recipe'
              )}
            </button>
            <button
              className='btn btn-rp-primary jq-email'
              onClick={emailRecipe}
              disabled={isEmailLoading && true}
            >
              {' '}
              {isEmailLoading ? (
                <Spinner
                  as='i'
                  animation='border'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
              ) : (
                'Email Recipe To Me'
              )}
            </button>
          </div>
        </Modal.Footer>
        <ToastContainer />
      </Modal>
    </>
  );
};

export default RecipeModal;
