import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import RadialBar from '../../../../common/RadialBar';
import ProgressBar from './Components/People/ProgressBar';
import SectionResult from './Components/People/SectionResult';
import ParticipantCard from '../../../../common/ParticipantCard';
import ImpactCard from './Components/People/ImpactCard';
import ChartCard from './Components/People/ChartCard';
import ChartResultSkeleton from './Components/People/ChartResultSkeleton';
import Skeleton from 'react-loading-skeleton';
import SectionCardSkeleton from './Components/People/SectionCardSkeleton';
import Pie2dGraph from '../../../../common/Pie2dGraph';
import parse from 'html-react-parser';
import ScrollToTopButton from '../../../../common/ScrollToTopButton';
import EraFilter from './Components/Common/EraFilter';
import Message from './Components/Common/Message';
import { isEmpty } from 'lodash';
import Sidebar from './Components/Common/Sidebar';
import AdminFilter from './Components/Common/AdminFilter';
import CorporateProgressBar from './Components/Corporate/ProgressBar';
import { saveFilters } from '../../../../store/filters/actions';
import QuestionAnalytics from '../../../../common/QuestionAnalytics';
import StackedColumnChart from '../../../../common/StackedColumnChart';
import { saveTabs } from '../../../../store/tabs/actions';
import DoughnutGraph from '../../../../common/DoughnutGraph';
import CompareModal from './Components/People/CompareModal';
import ManagerDropdown from '../../../../common/ManagerDropdown';
import ScrollToTop from '../../../../app/routes/ScrollToTop';
import CurrencyDropdown from '../../../../common/CurrencyDropdown';

const InitialState = {
  managerSelectedOption: '',
  questionSelectedOption: '',
};

const People = (props) => {
  //Variables section
  const dispatch = useDispatch();
  const [currState, setCurrState] = useState(InitialState);
  const [progressBurnLevel, setProgressBurnLevel] = useState(0);
  const [chartButton, setChartButton] = useState('historic');
  const [peopleBurnLevelResult, setPeopleBurnLevelResult] = useState({
    data: '',
    loading: true,
  });
  const [peopleBurnLevelResultById, setPeopleBurnLevelResultById] = useState({
    data: '',
    loading: true,
  });
  const [questionAnalyticsData, setQuestionAnalyticsData] = useState({
    data: '',
    loading: true,
  });
  const [impactResult, setImpactResult] = useState({ data: '', loading: true });
  const [chartResult, setChartResult] = useState({ data: '', loading: true });
  const [burnText, setBurnText] = useState('Overall');
  const [managerOptions, setManagerOptions] = useState({
    data: '',
    loading: true,
  });
  const [questionOptions, setQuestionOptions] = useState({
    data: '',
    loading: true,
  });
  const [questionData, setQuestionData] = useState({
    data: '',
    loading: true,
  });

  const [invert, setInvert] = useState(false);

  const [questionResult, setQuestionResult] = useState('');
  const [assmtId, setAssmtId] = useState('');
  const [selectedArray, setSelectedArray] = useState({});
  const [eraStatus, setEraStatus] = useState('');
  const [queryParams, setQueryParams] = useState({});
  const [eraMsg, setEraMsg] = useState('');
  const [reminderEnabledValue, setReminderEnabledValue] = useState('');
  const [countValue, setCountValue] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [eraLength, setEraLength] = useState();
  const [eraOptions, setEraOptions] = useState({
    data: [],
    loading: true,
  });

  const location = useLocation();
  const loc = location.pathname.split('/');

  const userState = useSelector((state) => state?.user);
  const companyTypeId = useSelector((state) => state?.companyTypeId?.payload);
  const tabState = useSelector(
    (state) => state?.tabs?.analytics?.[`${loc[3]}`]
  );
  const [countryId, setCountryId] = useState();

  useEffect(() => {
    <ScrollToTop />;
    window.addEventListener('load', clearFilter);
    return () => {
      window.removeEventListener('load', clearFilter);
    };
  }, []);

  const getSelectedQuestion = (id) => {
    setCurrState({ ...currState, questionSelectedOption: id });
    setQuestionAnalyticsData({ data: '', loading: true });
  };

  const getSelectedManager = (id) => {
    setCurrState({ ...currState, managerSelectedOption: id });
    setQuestionAnalyticsData({ data: '', loading: true });
  };

  const clearFilter = (e) => {
    e.preventDefault();
    if (window.$utility.pageAccessedByReload()) {
      dispatch(saveFilters({}, 'people'));
    }
  };

  useEffect(() => {
    dispatch(saveTabs(tabState, 'people'));
  }, [tabState]);

  async function getPeopleBurnLevel(query) {
    try {
      const result = await window.$http.getWithQueryAndHeaders(
        `${window.$utility.setApiUrl(userState)}analytics/people/burnout-level`,
        query
      );
      if (result.code === window.$constants.STATUS.OK) {
        setPeopleBurnLevelResult({ data: result.data, loading: false });
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getPeopleBurnLevelById(burnLevel = 0, query) {
    try {
      const result = await window.$http.getWithQueryAndHeaders(
        `${window.$utility.setApiUrl(
          userState
        )}analytics/people/assmt-overview/${burnLevel}`,
        query
      );
      if (result.code === window.$constants.STATUS.OK) {
        setPeopleBurnLevelResultById({ data: result.data, loading: false });
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    setImpactResult({ data: '', loading: true });
    getImpactData();
  }, [countryId]);

  async function getImpactData() {
    try {
      let query = {};
      const preQuery = {
        currency_country_id: countryId
          ? countryId
          : impactResult?.data?.currencies?.find(
            (el) => el?.is_selected === true
          )?.value,
        rp_company_assmt_id: tabState,
        rp_company_id: window.$utility.setCompanyId(userState, companyTypeId),
      };
      query = window.$utility.prepareDynamicParamsCustomChart(
        preQuery,
        selectedArray
      );
      const result = await window.$http.getWithQueryAndHeaders(
        `${window.$utility.setApiUrl(
          userState
        )}v2/analytics/people/active-participation`,
        query
      );
      if (result.code === window.$constants.STATUS.OK) {
        setImpactResult({ data: result.data, loading: false });
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getChartData(query) {
    try {
      const result = await window.$http.getWithQueryAndHeaders(
        `${window.$utility.setApiUrl(
          userState
        )}analytics/people/explore-section`,
        query
      );
      if (result.code === window.$constants.STATUS.OK) {
        setChartResult({ data: result.data, loading: false });
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getLookupData() {
    try {
      let query = {};

      query = {
        types: 'manager',
        rp_company_id: window.$utility.setCompanyId(userState, companyTypeId),
      };

      const result = await window.$http.getWithQueryAndHeaders(
        `${window.$utility.setApiUrl(userState)}lookup`,
        query
      );
      if (result.code === window.$constants.STATUS.OK) {
        setCurrState({
          ...currState,
          managerSelectedOption: result?.data?.manager[0]?.id,
        });
        setManagerOptions({ data: result?.data?.manager, loading: false });
        setQuestionOptions({
          data: result?.data?.people_analytics_questions,
          loading: false,
        });
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getQuestionAnalticsData() {
    try {
      const query = queryParamaBuilder();
      const result = await window.$http.getWithQueryAndHeaders(
        `${window.$utility.setApiUrl(
          userState
        )}analytics/people/question-analytics/${currState?.managerSelectedOption
        }/${currState?.questionSelectedOption}`,
        query
      );
      if (result.code === window.$constants.STATUS.OK) {
        setData(result?.data);
        setQuestionResult(result?.data);
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  }

  function setData(resultData) {
    let allChartData = [];
    if (resultData) {
      const answerChartData = window.$utility.prepareChartData(
        resultData?.question_answer,
        resultData?.question_answer?.chart_type
      );

      const genderChartData = window.$utility.prepareChartData(
        resultData?.question_gender,
        resultData?.question_gender?.chart_type
      );

      const ageChartData = window.$utility.prepareChartData(
        resultData?.question_age,
        resultData?.question_age?.chart_type
      );

      allChartData['chartAnswer'] = answerChartData;
      allChartData['chartGender'] = genderChartData;
      allChartData['chartAge'] = ageChartData;

      setQuestionAnalyticsData({ data: allChartData, loading: false });
    }
  }

  function handleSwapClick(chart) {
    let allChartData = questionAnalyticsData?.data;
    let invertValue = !invert;
    setInvert(invertValue);
    if (chart === 'chartGender') {
      allChartData['chartGender'] = window.$utility.prepareChartData(
        questionResult?.question_gender,
        questionResult?.question_gender?.chart_type,
        invertValue
      );
      setQuestionAnalyticsData({ data: allChartData, loading: false });
    } else if (chart === 'chartAge') {
      allChartData['chartAge'] = window.$utility.prepareChartData(
        questionResult?.question_age,
        questionResult?.question_age?.chart_type,
        invertValue
      );
      setQuestionAnalyticsData({ data: allChartData, loading: false });
    }
  }

  function handleProgressBarClick(burnLevel, burnText) {
    if (progressBurnLevel !== burnLevel) {
      setPeopleBurnLevelResultById({ data: '', loading: true });
      setProgressBurnLevel(burnLevel);

      const query = queryParamaBuilder();

      getPeopleBurnLevelById(burnLevel, query);
      setBurnText(burnText);
    }
  }

  function handleChartButtonClick(buttonValue, burnLevel) {
    setChartButton(buttonValue);
  }

  function handleQuestionAnalyticsChange(e) {
    const { name, value } = e.target;
    if (name === 'manager') {
      setCurrState({ ...currState, managerSelectedOption: value });
      setQuestionAnalyticsData({ data: '', loading: true });
      setQuestionData({ data: '', loading: true });
    } else if (name === 'question') {
      setCurrState({ ...currState, questionSelectedOption: value });
      setQuestionAnalyticsData({ data: '', loading: true });
      setQuestionData({ data: '', loading: true });
    }
  }

  const handleCount = (val) => {
    setCountValue(val);
  };

  function changeText() {
    if (!isEmpty(countValue)) {
      return 'selected criteria';
    } else {
      return 'organization';
    }
  }

  function queryParamaBuilder() {
    let query = {};
    if (tabState) {
      const preQuery = {
        rp_company_assmt_id: tabState,
        rp_company_id: window.$utility.setCompanyId(userState, companyTypeId),
      };
      query = window.$utility.prepareDynamicParamsCustomChart(
        preQuery,
        selectedArray
      );
    }

    setQueryParams(query);
    return query;
  }

  function questionsData() {
    if (
      currState?.managerSelectedOption !== '' &&
      currState?.managerSelectedOption !== undefined &&
      currState?.questionSelectedOption !== '' &&
      currState?.questionSelectedOption !== undefined
    ) {
      getQuestionAnalticsData();
    }
  }

  function apiCalls() {
    if (tabState) {
      setPeopleBurnLevelResult({ data: '', loading: true });
      setPeopleBurnLevelResultById({ data: '', loading: true });
      setImpactResult({ data: '', loading: true });
      setChartResult({ data: '', loading: true });
      setQuestionAnalyticsData({ data: '', loading: true });
      setQuestionData({ data: '', loading: true });
      const query = queryParamaBuilder();
      getPeopleBurnLevel(query);
      getImpactData();
      getChartData(query);
      getPeopleBurnLevelById(progressBurnLevel, query);

      questionsData();
      getQuestionsData();
    }
  }

  const getQuestionsData = async () => {
    try {
      if (
        currState?.managerSelectedOption === 0 ||
        currState?.managerSelectedOption === 1 ||
        currState?.managerSelectedOption === -1
      ) {
        setQuestionData({ data: '', loading: true });
        let query = {};
        const preQuery = {
          is_manager: currState?.managerSelectedOption,
          rp_company_assmt_id: tabState,
          rp_company_id: window.$utility.setCompanyId(userState, companyTypeId),
        };
        query = window.$utility.prepareDynamicParamsCustomChart(
          preQuery,
          selectedArray
        );
        const result = await window.$http.getWithQueryAndHeaders(
          `${window.$utility.setApiUrl(userState)}analytics/people/questions`,
          query
        );
        if (result.code === window.$constants.STATUS.OK) {
          setQuestionData({ data: result.data, loading: false });
        } else {
          console.log('Something went wrong!');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  //Inbuit functions section

  useEffect(() => {
    if (tabState) {
      getQuestionsData();
    }
  }, [currState?.managerSelectedOption, tabState, companyTypeId]);

  useEffect(() => {
    getLookupData();
  }, [companyTypeId]);

  useEffect(() => {
    apiCalls();
  }, [tabState, companyTypeId]);

  useEffect(() => {
    questionsData();
  }, [currState, companyTypeId, tabState]);

  const getAssmtId = (val) => {
    if (val) {
      setAssmtId(val);
      dispatch(saveTabs(val, 'people'));
    }
  };

  const getFilterValues = (value) => {
    const data = window.$utility.setDynamicFilterOptions(value, selectedArray);
    setSelectedArray(data);
    dispatch(saveFilters(data, 'people'));
  };

  const getEraStatus = (val, msg, remVal) => {
    setEraStatus(val);
    setEraMsg(msg);
    setReminderEnabledValue(remVal);
  };

  const getEraLength = (data) => {
    setEraLength(data?.length);
    setEraOptions({ data: data, loading: false });
  };

  const handleCompareModal = () => {
    setShowModal(!showModal);
  };

  const handleCloseModal = () => {
    setShowModal('');
  };

  const getSelectedCurrency = (id) => {
    // setSelectedCurrency(id);
    setCountryId(
      impactResult?.data?.currencies?.find((el) => el?.label === id)?.value
    );
  };

  return (
    <main>
      <div className='plan_container'>
        <Sidebar
          assmtId={assmtId}
          getFilterValues={getFilterValues}
          apiCalls={apiCalls}
          handleCount={handleCount}
          tabState={tabState}
        />
        <div className='main_content'>
          <AdminFilter />
          <div className='col-12 mb-4 d-flex justify-content-between'>
            <EraFilter
              getAssmtId={getAssmtId}
              tab={props?.tab}
              getEraStatus={getEraStatus}
              getEraLength={getEraLength}
              value='people'
            />
            {eraLength >= 2 ? (
              <div>
                <button
                  type='button'
                  className='btn btn-rp-primary compare-btn'
                  data-bs-target='#mymodal'
                  data-bs-toggle='modal'
                  onClick={handleCompareModal}
                >
                  Compare
                </button>
              </div>
            ) : (
              ''
            )}
          </div>
          {eraStatus ? (
            <>
              {isEmpty(peopleBurnLevelResult?.data?.no_data_message) ? (
                <>
                  <section>
                    <div className='row'>
                      <div className='col-12'>
                        <div className='burnout_level'>
                          <div className='dots-styles-top-right' />
                          <div className='about z-1' style={{ width: '100%' }}>
                            <div className='col-md-6 col-lg-7'>
                              {peopleBurnLevelResult?.loading ? (
                                <Skeleton width='20%' />
                              ) : (
                                <p className='primary-color'>Burnout level</p>
                              )}
                              <h1 className='section-heading'>
                                {peopleBurnLevelResult?.loading ? (
                                  <Skeleton height={160} />
                                ) : (
                                  peopleBurnLevelResult?.data?.title
                                )}
                              </h1>
                              <h2 className='card-heading-light rp-med-gray fw-5 '>
                                {peopleBurnLevelResult?.loading ? (
                                  <Skeleton height={140} />
                                ) : peopleBurnLevelResult?.data?.description ? (
                                  parse(
                                    peopleBurnLevelResult?.data?.description
                                  )
                                ) : (
                                  ''
                                )}
                              </h2>
                            </div>
                            <div className='col-md-6 col-lg-5 radial-chart mt-5 mt-lg-0'>
                              {peopleBurnLevelResult?.loading ? (
                                <Skeleton
                                  circle={true}
                                  height={300}
                                  width={300}
                                />
                              ) : (
                                <RadialBar
                                  burnout_stage_id={
                                    peopleBurnLevelResult?.data
                                      ?.rp_gbl_burnout_stage_id
                                  }
                                  burnout_title={
                                    peopleBurnLevelResult?.data?.burnout_title
                                  }
                                  burnout_data={
                                    peopleBurnLevelResult?.data?.sections
                                  }
                                />
                              )}
                            </div>
                          </div>
                          <div className='col-12 z-1'>
                            {peopleBurnLevelResult?.loading ? (
                              <Skeleton width='20%' />
                            ) : (
                              <p className='p-hed mt-4'>Assessment Overview</p>
                            )}
                            {peopleBurnLevelResult?.loading ? (
                              <ul
                                className='nav nav-pills over-tab-div'
                                id='pills-tab'
                                role='tablist'
                              >
                                {[1, 2, 3, 4, 5, 6].map((n) => (
                                  <li
                                    key={n}
                                    className='nav-item over-tab'
                                    role='presentation'
                                  >
                                    <Skeleton
                                      width='150px'
                                      height='50px'
                                      key={n}
                                    />
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              <ProgressBar
                                handleProgressBarClick={handleProgressBarClick}
                                burnLevel={progressBurnLevel}
                                burnouts={peopleBurnLevelResult?.data?.burnouts}
                              />
                            )}
                            <p
                              className='mb-3'
                              style={{
                                fontWeight: '600',
                                fontSize: '18px',
                              }}
                            >
                              {peopleBurnLevelResultById?.data?.data_on_text}
                            </p>
                            {peopleBurnLevelResultById?.loading ? (
                              <div className='tab-content'>
                                <div className='tab-pane fade show active'>
                                  {/* <p
                                    className='mb-3'
                                    style={{
                                      fontWeight: '600',
                                      fontSize: '18px',
                                    }}
                                  >
                                    {
                                      peopleBurnLevelResultById?.data
                                        ?.data_on_text
                                    }
                                  </p> */}
                                  <div className='d-flex justify-content-between mb-6'>
                                    {[1, 2, 3, 4, 5].map((n) => (
                                      <Skeleton
                                        height={275}
                                        width='180px'
                                        key={n}
                                      />
                                    ))}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <ParticipantCard
                                participationDetail={
                                  peopleBurnLevelResultById?.data
                                    ?.participation_detail
                                }
                              />
                            )}
                            {peopleBurnLevelResultById?.loading ? (
                              <SectionCardSkeleton />
                            ) : (
                              <SectionResult
                                burnoutResult={peopleBurnLevelResultById?.data}
                                burnText={burnText}
                              />
                            )}
                          </div>
                          {peopleBurnLevelResultById?.data?.stress?.is_exist ? (
                            <div className='col-12 mt-4'>
                              {peopleBurnLevelResultById?.loading ? (
                                <Skeleton height={50} width={300} />
                              ) : (
                                <div className='d-flex'>
                                  <div
                                    className='init'
                                    style={{
                                      width: '50px',
                                      height: '50px',
                                      background: '#E2231A',
                                      fontWeight: '700',
                                      fontSize: '22px',
                                      color: '#fff',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      borderRadius: '50%',
                                    }}
                                  >
                                    S
                                    {/* {buttonClick
                                  ? window.$utility.getInitials(
                                      peopleBurnLevelResultById?.data?.stress
                                        ?.title
                                    )
                                  : window.$utility.getInitials(
                                      peopleBurnLevelResult?.data?.stress?.title
                                    )} */}
                                  </div>
                                  <div
                                    className='title'
                                    style={{
                                      fontWeight: '500',
                                      fontSize: '24px',
                                      lineHeight: '42px',
                                      marginLeft: '15px',
                                    }}
                                  >
                                    {
                                      peopleBurnLevelResultById?.data?.stress
                                        ?.title
                                    }
                                  </div>
                                </div>
                              )}
                              <div className='assessment-factor-card mt-4 p-0'>
                                {peopleBurnLevelResultById?.loading ? (
                                  <Skeleton height={250} width={1085} />
                                ) : (
                                  <CorporateProgressBar
                                    burnouts={
                                      peopleBurnLevelResultById?.data?.stress
                                        ?.levels
                                    }
                                    value='people'
                                  />
                                )}
                              </div>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  </section>

                  {
                    isEmpty(impactResult?.data?.cards) ? ('') : (
                      <section>
                        <div className='row justify-content-around'>
                          <div className='col-10'>
                            <h2 className='section-titel section-heading mb-5'>
                              Resiliency Program has analyzed the following tangible
                              impact to your {changeText()}
                            </h2>
                          </div>
                          <div className='col-2' style={{ width: '12%' }}>
                            <CurrencyDropdown
                              data={impactResult?.data?.currencies}
                              getSelectedCurrency={getSelectedCurrency}
                            />
                          </div>
                          {impactResult?.loading ? (
                            <Skeleton height='500px' />
                          ) : (
                            <ImpactCard
                              impactResult={impactResult?.data?.cards}
                              changeText={changeText()}
                            />
                          )}
                        </div>
                      </section>
                    )
                  }

                  <div className='row'>
                    <h2 className='section-titel section-heading mb-5'>
                      Explore how your {changeText()} does on individual
                      parameters such as Enthusiasm, Motivation, Negativity
                      &amp; Fatigue.
                    </h2>
                    {chartResult?.loading
                      ? [1, 2, 3, 4].map((n) => (
                        <ChartResultSkeleton key={n} index={n} />
                      ))
                      : chartResult?.data?.map((item, index) => (
                        <ChartCard
                          key={index}
                          handleChartButtonClick={handleChartButtonClick}
                          chartButton={chartButton}
                          chartData={item}
                          index={index + 1}
                          query={queryParams}
                        />
                      ))}

                    {/* question analytics */}
                    <div className='col-12'>
                      <div className='br-12 bg-white p-4 mt-5'>
                        <div className='d-flex justify-content-between align-items-center mb-3'>
                          <div className='col-9'>
                            <p className='rp-primary-dark-g fw-bold'>
                              Questionnaire Level Analytics
                            </p>
                          </div>
                          <div className='col-3'>
                            {managerOptions?.loading ? (
                              <Skeleton height={40} />
                            ) : (
                              <ManagerDropdown
                                data={managerOptions?.data}
                                getSelectedManager={getSelectedManager}
                              />
                            )}
                          </div>
                        </div>

                        {questionData?.loading ? (
                          <Skeleton height='30vh' />
                        ) : (
                          <QuestionAnalytics
                            data={questionData?.data}
                            getSelectedQuestion={getSelectedQuestion}
                            manager={currState?.managerSelectedOption}
                          />
                        )}

                        <div className='d-flex flex-wrap justify-content-between mt-4'>
                          <div className='question-anlytics_graph p-3 br-8 box_shadow'>
                            <div className='title'>
                              <span className='rp-primary-dark-g'>
                                {
                                  questionAnalyticsData?.data?.chartAnswer
                                    ?.title
                                }
                              </span>
                            </div>
                            <div>
                              {questionAnalyticsData?.loading ? (
                                <Skeleton height='46vh' />
                              ) : (
                                <DoughnutGraph
                                  data={
                                    questionAnalyticsData?.data?.chartAnswer
                                  }
                                  height={350}
                                  bgColor={'#ffffff'}
                                  type={
                                    questionAnalyticsData?.data?.chartAnswer
                                      ?.type
                                  }
                                />
                              )}
                            </div>
                          </div>
                          <div className='question-anlytics_graph br-8 box_shadow p-3'>
                            <div className='title'>
                              <span className='rp-primary-dark-g'>
                                {
                                  questionAnalyticsData?.data?.chartGender
                                    ?.title
                                }
                              </span>
                            </div>
                            <div>
                              {questionAnalyticsData?.loading ? (
                                <Skeleton height='47vh' />
                              ) : (
                                <StackedColumnChart
                                  data={
                                    questionAnalyticsData?.data?.chartGender
                                      ?.data['data']
                                  }
                                  xAxisName={
                                    questionAnalyticsData?.data?.chartGender
                                      ?.xAxisName
                                  }
                                  yAxisName={
                                    questionAnalyticsData?.data?.chartGender
                                      ?.yAxisName
                                  }
                                  separator={''}
                                  height={390}
                                  categories={
                                    questionAnalyticsData?.data?.chartGender
                                      ?.categories
                                  }
                                  bgColor={'#ffffff'}
                                  type={
                                    questionAnalyticsData?.data?.chartGender
                                      ?.type
                                  }
                                />
                              )}
                            </div>
                            {window.$utility.showMessage(
                              questionAnalyticsData?.data?.chartGender
                                ?.chartMessage
                            )}
                          </div>
                          <div className='question-anlytics_graph p-3 br-8 box_shadow'>
                            <div className='title'>
                              <span className='rp-primary-dark-g'>
                                {questionAnalyticsData?.data?.chartAge?.title}
                              </span>
                            </div>
                            <div>
                              {questionAnalyticsData?.loading ? (
                                <Skeleton height='47vh' />
                              ) : (
                                <StackedColumnChart
                                  data={
                                    questionAnalyticsData?.data?.chartAge?.data[
                                    'data'
                                    ]
                                  }
                                  xAxisName={
                                    questionAnalyticsData?.data?.chartAge
                                      ?.xAxisName
                                  }
                                  yAxisName={
                                    questionAnalyticsData?.data?.chartAge
                                      ?.yAxisName
                                  }
                                  separator={''}
                                  height={390}
                                  categories={
                                    questionAnalyticsData?.data?.chartAge
                                      ?.categories
                                  }
                                  bgColor={'#ffffff'}
                                  type={
                                    questionAnalyticsData?.data?.chartAge?.type
                                  }
                                />
                              )}
                              {window.$utility.showMessage(
                                questionAnalyticsData?.data?.chartAge
                                  ?.chartMessage
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* question analtics end */}
                  </div>
                </>
              ) : (
                <h6 className='text-center'>
                  {peopleBurnLevelResult?.data?.no_data_message}
                </h6>
              )
              }
            </>
          ) : eraStatus === '' ? (
            ''
          ) : (
            <Message
              tab={props?.tab}
              msg={eraMsg}
              reminderEnabledValue={reminderEnabledValue}
            />
          )}
          <ScrollToTopButton />
          {
            showModal && (
              <CompareModal
                showModal={showModal}
                handleCloseModal={handleCloseModal}
                eraOptions={eraOptions}
              />
            )
          }
        </div >
      </div >
    </main >
  );
};

export default People;
