import React, { useEffect, useState } from 'react';
import Header from '../layout/Components/Header';
import Footer from '../layout/Components/Footer';
import QRCodePlayImage from '../assets/images/qr-code-play.png';
import QRCodeAppImage from '../assets/images/qr-code-app.png';
import AppStoreBadge from '../assets/images/app-store-badge.png';
import PlayStoreBadge from '../assets/images/google-play-badge.png';
import QRSvgImage from '../assets/images/qr-svg.svg';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';

const Access = () => {
  const navigate = useNavigate();
  const [result, setResult] = useState();
  const [loginResult, setLoginResult] = useState();
  const userState = useSelector((state) => state?.user);

  useEffect(() => {
    handleLogin();
    if (!isEmpty(userState) && userState?.is_web_enabled === true) {
      navigate('/');
    }
  }, []);

  const handleLogin = async () => {
    try {
      const result = await window.$http.getWithoutHeaders('login');
      if (result.code === window.$constants.STATUS.OK) {
        setLoginResult(result?.data);
      } else if (result.code === window.$constants.STATUS.UNDER_MAINTAINENCE) {
        setResult(result);
      }
    } catch (error) {
      setResult(error);
    }
  };

  return (
    <>
      <Header domainValue='sms' />
      <section>
        <div className='container'>
          <div className='row'>
            <div className='mobile-installation p-lg-5 p-3 bg-white box_shadow br-12'>
              <div
                className='text-center'
                style={{
                  marginBottom: '30px',
                  fontSize: '24px',
                  lineHeight: '29px',
                  fontWeight: '500',
                }}
              >
                {loginResult?.banner_message
                  ? loginResult?.banner_message
                  : 'This feature is available only on iOS and Android.'}
              </div>

              {loginResult?.is_mobile_app_view ? (
                ''
              ) : (
                <>
                  <div
                    className='text-center mb-4'
                    style={{
                      fontSize: '20px',
                      lineHeight: '24px',
                      color: '000',
                    }}
                  >
                    App installation instructions
                  </div>
                  <div
                    className='qr-icon m-auto mb-4'
                    style={{ width: '100px', height: '100px' }}
                  >
                    <img src={QRSvgImage} alt='' />
                  </div>
                  <div
                    className='mb-4 text-center'
                    style={{
                      fontSize: '24px',
                      lineHeight: '29px',
                      color: 'rgb(129, 129, 129)',
                    }}
                  >
                    Click/Scan the QR code below to download the app to your
                    device.
                  </div>
                </>
              )}

              <div className='col-lg-8 col-md-12 m-auto'>
                <div className='d-flex flex-lg-nowrap flex-wrap justify-content-around'>
                  <div
                    className='scan-download mb-4 mb-lg-0'
                    style={{ cursor: 'pointer' }}
                    onClick={() => window.open(window.$enums.Links.Ios)}
                  >
                    <div className='qr-scaner'>
                      <div
                        className='d-flex align-items-center justify-content-center'
                        style={{
                          backgroundColor: 'rgb(242, 242, 242)',
                          width: '220px',
                          height: '220px',
                          borderRadius: '24px',
                        }}
                      >
                        <img src={QRCodeAppImage} alt='' className='z-1' />
                      </div>
                    </div>
                    <div className='scaner-title mt-4 text-center'>
                      <div className='mb-2'>
                        <img
                          src={AppStoreBadge}
                          alt=''
                          style={{ width: '160px' }}
                        />
                      </div>
                      <span
                        style={{
                          fontSize: '18px',
                          lineHeight: '21px',
                        }}
                      >
                        Available on App Store
                      </span>
                    </div>
                  </div>
                  <div
                    className='scan-download mb-4 mb-lg-0'
                    style={{ cursor: 'pointer' }}
                    onClick={() => window.open(window.$enums.Links.Android)}
                  >
                    <div className='qr-scaner'>
                      <div
                        className='d-flex align-items-center justify-content-center'
                        style={{
                          backgroundColor: 'rgb(242, 242, 242)',
                          width: '220px',
                          height: '220px',
                          borderRadius: '24px',
                        }}
                      >
                        <img src={QRCodePlayImage} alt='' />
                      </div>
                    </div>
                    <div className='scaner-title mt-4 text-center'>
                      <div className='mb-2'>
                        <img
                          src={PlayStoreBadge}
                          alt=''
                          style={{ width: '160px', height: '50px' }}
                        />
                      </div>
                      <span
                        style={{
                          fontSize: '18px',
                          lineHeight: '21px',
                        }}
                      >
                        Available on Playstore
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer domainValue='sms' />
    </>
  );
};

export default Access;
