import React, { useEffect, useState } from 'react';

const ManagerDropdown = (props) => {
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [selectedManagerId, setSelectedManagerId] = useState();
  const [selectedManager, setSelectedManager] = useState();

  useEffect(() => {
    if (props?.data) {
      setSelectedManagerId(props?.data[0]?.id);
      setSelectedManager(props?.data[0]);
    }
  }, [props?.data]);

  useEffect(() => {
    if (
      selectedManagerId === 0 ||
      selectedManagerId === 1 ||
      selectedManagerId === -1
    ) {
      props?.getSelectedManager(selectedManagerId);
    }
  }, [selectedManagerId]);

  const handleArrowClick = () => {
    setDropDownOpen(!dropDownOpen);
  };

  const handleSelectedManager = (id, data) => {
    setSelectedManagerId(id);
    setSelectedManager(data);
    setDropDownOpen(!dropDownOpen);
  };
  return (
    <div className={props?.data?.length == 1 ? 'hide' : 'question-analytics'}>
      <div
        className={
          dropDownOpen
            ? 'select-container custom-dropdown active'
            : 'select-container custom-dropdown'
        }
        onClick={handleArrowClick}
      >
        <div className='select'>
          <div className='question-input' onFocus='this.blur();'>
            <div className='questions-graph'>{selectedManager?.value}</div>
          </div>
          <div
            style={{
              cursor: 'pointer',
              padding: '0 15px',
            }}
          >
            <i
              className={
                dropDownOpen
                  ? 'fas fa-chevron-down jq_drop__t rotate'
                  : 'fas fa-chevron-down jq_drop__t'
              }
              aria-hidden='true'
              onClick={handleArrowClick}
            ></i>
          </div>
        </div>
        <div className='option-container' style={{ zIndex: '3' }}>
          {props?.data && props?.data?.length
            ? props?.data?.map((data, index) => (
              <div className='option' value={data?.id} key={index}>
                <div
                  className={`questions-graph ${data?.id === selectedManagerId ? 'selected' : ''
                    } `}
                  onClick={() => handleSelectedManager(data?.id, data)}
                >
                  {data?.value}
                </div>
              </div>
            ))
            : ''}
        </div>
      </div>
    </div>
  );
};

export default ManagerDropdown;
