const Constants = {
  STATUS: {
    OK: 200,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    CONFLICT: 409,
    UNPROCESSABLE_CONTENT: 422,
    INTERNAL_SERVER_ERROR: 500,
    INVALID_SUBDOMAIN: 406,
    UNDER_MAINTAINENCE: 503,
  },
};

window.$constants = Constants;

export default Constants;
