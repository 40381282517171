import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../../layout/Components/Footer';
import Header from '../../../layout/Components/Header';
import MobileVerification from '../../../common/MobileVerification';
import { useSelector } from 'react-redux';

const PhoneVerification = () => {
  const navigate = useNavigate();
  const [counter, setCounter] = useState(30);
  const [footerState, setFooterState] = useState('');
  const user = useSelector((state) => state?.auth);
  const userState = useSelector((state) => state?.user);
  const [otpCode, setOtpCode] = useState({
    value: '',
    valid: '',
  });

  useEffect(() => {
    if (userState.onboarding_step === 0) {
      navigate('/on-boarding');
    }
  }, [userState]);

  useEffect(() => {
    if (otpCode?.valid !== '') {
      const timer =
        counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [otpCode?.valid]);

  const otpCodeValid = (value) => {
    setOtpCode({
      ...otpCode,
      valid: value,
    });
  };

  const newFooterState = (value) => {
    setFooterState(value);
  };

  return (
    <>
      <Header />
      <main>
        <section>
          <div className='container'>
            <div className='row'>
              <div className='personal-info mobile-verification p-3 p-lg-5'>
                <h3 className='mb-4'>Mobile Number Verification</h3>
                <p>
                  Note: If you use WhatsApp, then please provide the mobile
                  number associated with that account
                </p>
                <MobileVerification
                  value='Onboarding'
                  otpCodeValid={otpCodeValid}
                  newFooterState={newFooterState}
                />
              </div>
            </div>
          </div>
        </section>
      </main>

      {otpCode?.valid !== '' ? (
        <Footer />
      ) : (
        <Footer
          addClass='fixed-bottom'
          value={footerState}
          version={user?.version}
        />
      )}
    </>
  );
};

export default PhoneVerification;
