import React, { useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Series = (props) => {
  const [mySeries, setMySeries] = useState();
  const [forceUpdate, setForceUpdate] = useState(0);

  useEffect(() => {
    if (props?.contentData) {
      setMySeries(props?.contentData);
    }
  }, [props?.contentData]);

  async function handleLike(seriesId, favourite) {
    let seriesArray = props?.contentData;
    if (seriesArray.length) {
      let index = seriesArray?.findIndex((el) => el?.series_id === seriesId);

      seriesArray[index].is_favourite = !favourite;
      setMySeries(seriesArray);
      setForceUpdate((forceUpdate) => forceUpdate + 1);
    }
    handleLikeStatus(seriesId);
  }

  const handleLikeStatus = async (id) => {
    try {
      const result = await window.$http.postWithHeaders('favourite/series', {
        series_id: id,
      });
      if (result.code === window.$constants.STATUS.OK) {
        console.log('success');
        toast?.success(result?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showVideos = (series_id, series_name, series_description) => {
    props?.getVideosData(series_id, series_name, series_description);
  };

  return (
    <>
      {mySeries
        ? mySeries?.map((data, id) => (
            <div className='col-md-4 mb-5' key={id}>
              <div
                className='video-card position-relative'
                style={{
                  background: `url(${process.env.REACT_APP_API_URL}media/content/v2/${data?.series_image_id}/thumb/0) no-repeat center center/cover`,
                }}
              >
                {data?.is_favourite ? (
                  <Link
                    to='/dashboard/resiliency-plan/favourite/series'
                    className='link_overlay'
                    onClick={() =>
                      showVideos(
                        data?.series_id,
                        data?.series_name,
                        data?.series_description
                      )
                    }
                  ></Link>
                ) : (
                  <div className='undo-massage'>
                    <p>Series removed from your favourites</p>
                    <span
                      onClick={() =>
                        handleLike(data?.series_id, data?.is_favourite)
                      }
                    >
                      Undo
                    </span>
                  </div>
                )}
              </div>
              <div className='video-card-discription'>
                <div className='music-titel'>
                  {data?.series_name?.length < 40 ? (
                    <h6>{data?.series_name}</h6>
                  ) : (
                    <Tooltip title={data?.series_name} placement='bottomRight'>
                      <h6>
                        {data?.series_name?.slice(0, 40)}
                        <span
                          style={{
                            color: '#1a1a1a',
                          }}
                        >
                          ...
                        </span>
                      </h6>
                    </Tooltip>
                  )}

                  {data?.series_description?.length < 50 ? (
                    <p>{data?.series_description}</p>
                  ) : (
                    <Tooltip
                      title={data?.series_description}
                      placement='bottomRight'
                    >
                      <p>
                        {data?.series_description?.slice(0, 50)}
                        <span
                          style={{
                            color: 'rgba(44, 65, 67, 0.5)',
                          }}
                        >
                          ...
                        </span>
                      </p>
                    </Tooltip>
                  )}
                </div>
                <div className='cnt-source mb-2'>
                  {data?.videos_count}
                  <span
                    className={`like-btn ${
                      mySeries.length &&
                      mySeries?.find((el) => el.series_id === data?.series_id)
                        ?.is_favourite
                        ? 'liked'
                        : ''
                    }`}
                    onClick={() =>
                      handleLike(data?.series_id, data?.is_favourite)
                    }
                  ></span>
                </div>
              </div>
            </div>
          ))
        : ''}
      <ToastContainer />
    </>
  );
};

export default Series;
