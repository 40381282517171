const Enums = {
  Source: {
    RP: 1,
    Youtube: 2,
    Vimeo: 3,
    Amazon: 4,
    GoogleStore: 5,
    IphoneStore: 6,
  },

  ContentType: {
    Audio: 1,
    Video: 2,
    Image: 3,
    Html: 4,
    Text: 5,
    Apps: 6,
  },

  BannerMsgType: {
    Address: 'address',
    CompanyAssmt: 'company-assmt',
  },

  CompanyTypeId: {
    Employee: 1,
    Clinical: 2,
    Personal: 3,
    CompanyAdmin: 4,
  },

  OccupationType: {
    Student: 1,
    HouseManager: 2,
    Employee: 3,
    General: 4,
  },

  BurnoutStageId: {
    Severe: 1,
    Chronic: 2,
    Moderate: 3,
    Mild: 4,
    Resilient: 5,
  },

  BurnoutLevel: {
    Red: '#e02726',
    Green: '#00a39d',
  },

  AssmtStatus: {
    Previous: 0,
    Current: 1,
    Next: 2,
  },

  CountryId: {
    India: 99,
    US: 223,
  },

  Disease: {
    Other: '17',
  },

  Schedule: {
    History: 0,
    Live: 1,
    Future: 2,
  },

  Links: {
    Android: 'https://play.google.com/store/apps/details?id=com.app.resiliencyprogram&pli=1',
    Ios: 'https://apps.apple.com/in/app/resiliency-program/id1625719136',
  },
};

window.$enums = Enums;

export default Enums;
