import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';

function ManageLocationForm(props) {
  return (
    <Modal
      centered
      tabIndex='-1'
      size='lg'
      show={props.showModal ? true : false}
      aria-hidden={props.showModal ? true : false}
    >
      <Modal.Header>
        {!props?.items?.rp_location_id ? (
          <h5 className='modal-title add-people-hed'>Add Location</h5>
        ) : (
          <h5 className='modal-title add-people-hed'>Edit Location</h5>
        )}
        <button
          type='button'
          className='btn-close'
          aria-label='Close'
          onClick={props.handleCloseModal}
        ></button>
      </Modal.Header>
      <Modal.Body className='pt-0'>
        <div className='profile-edit'>
          <div className='row mt-3'>
            <div className='col-md-12 col-lg-6 mb-3'>
              <label className='asterisk'>Location Name</label>
              <input
                type='text'
                name='locationName'
                className={
                  (props?.formErrors && props?.formErrors?.locationName) ||
                  (props?.isSubmit && !props?.location?.locationName) ||
                  props?.message === 'Location name already exists'
                    ? 'form-control border-danger'
                    : 'form-control'
                }
                placeholder='Location Name'
                aria-label='Location name'
                value={props?.location?.locationName || ''}
                onChange={props.handleChange}
                required
              />
            </div>
            <div className='col-md-12 col-lg-6 mb-3'>
              <label>Parent Location</label>
              <select
                className='form-select form-control'
                aria-label='rp_parent_id'
                name='rp_parent_id'
                value={props?.rp_parent_id ? props?.rp_parent_id : ''}
                onChange={props.handleParentState}
              >
                {!props?.items?.rp_location_id ? (
                  <option value='' hidden>
                    Self
                  </option>
                ) : (
                  <option value=''>Select</option>
                )}
                {props?.locationOptions !== undefined
                  ? props?.locationOptions?.map((data, id) => (
                      <option value={data?.rp_location_id} key={id}>
                        {data?.location_name}
                      </option>
                    ))
                  : ''}
              </select>
            </div>
            <div className='col-md-12 col-lg-6 mb-3'>
              <label className='asterisk'>Location Id</label>
              <div className='d-flex align-items-center'>
                <input
                  type='text'
                  name='locationId'
                  className={
                    (props?.formErrors && props?.formErrors?.locationId) ||
                    (props?.isSubmit && !props?.location?.locationId) ||
                    props?.message === 'Location Id already exists'
                      ? 'form-control border-danger'
                      : 'form-control'
                  }
                  placeholder='Location Id'
                  aria-label='location_Id'
                  value={props?.location?.locationId || ''}
                  onChange={props.handleChange}
                  required
                />
              </div>
            </div>
            <div className='col-md-12 col-lg-6 mb-3'>
              <label className='asterisk'>Is Active</label>
              <select
                className='form-select form-control'
                aria-label='isActive'
                name='isActive'
                value={
                  props?.isActive?.toString() === 'true' ||
                  props?.isActive?.toString() === 'Yes'
                    ? 'Yes'
                    : props?.isActive?.toString() === 'false' ||
                      props?.isActive?.toString() === 'No'
                    ? 'No'
                    : ''
                }
                onChange={props.handleActiveState}
              >
                {global.activeData.map((data, id) => (
                  <option value={data?.value} key={id}>
                    {data?.value}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {!props?.items?.rp_location_id ? (
          <button
            type='button'
            className={`btn btn-rp-primary ${
              props?.buttonState?.isLoading &&
              props?.buttonState?.value === 'saveLocation'
                ? 'disabled'
                : ''
            }`}
            onClick={() => props.saveLocation()}
            data-bs-dismiss={props?.handleModal === false ? 'modal' : ''}
          >
            {props?.buttonState?.isLoading &&
            props?.buttonState?.value === 'saveLocation' ? (
              <Spinner
                as='i'
                animation='border'
                size='sm'
                role='status'
                aria-hidden='true'
              />
            ) : (
              'Save'
            )}
          </button>
        ) : (
          <button
            type='button'
            className={`btn btn-rp-primary ${
              props?.buttonState?.isLoading &&
              props?.buttonState?.value === 'updateLocation'
                ? 'disabled'
                : ''
            }`}
            aria-label='Close'
            onClick={() => props.updateLocation(props?.items.rp_location_id)}
          >
            {props?.buttonState?.isLoading &&
            props?.buttonState?.value === 'updateLocation' ? (
              <Spinner
                as='i'
                animation='border'
                size='sm'
                role='status'
                aria-hidden='true'
              />
            ) : (
              'Save'
            )}
          </button>
        )}
        {props?.message ? <p className='text-danger'>{props?.message}</p> : ''}
      </Modal.Footer>
    </Modal>
  );
}

export default ManageLocationForm;
