import React, { useEffect, useState } from 'react';
import enthusiasmIcon from '../../../../../../assets/images/enthu.png';
import motivationIcon from '../../../../../../assets/images/motivation-ioc.png';
import negativityIcon from '../../../../../../assets/images/negitivity-ioc.png';
import fatigueIcon from '../../../../../../assets/images/fatigue-ico.png';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Link as LinkTo } from 'react-scroll';
import moment from 'moment';
import RadialBar from '../../../../../../common/RadialBar';
import parse from 'html-react-parser';
import { Tooltip } from 'antd';
import BurnoutContent from './BurnoutContent';
import { saveAssmt } from '../../../../../../store/assmt/actions';
import { useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import VideoPlayer from '../../../../../../common/VideoPlayer';
import LoadingSpinner from '../../../../../../common/LoadingSpinner';
import { useTour } from '@reactour/tour';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';

const AssmtResult = () => {
  const { setIsOpen } = useTour();
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { rp_user_assmt_id } = useParams();
  const [resultData, setResultData] = useState();
  const [assmtData, setAssmtData] = useState();
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    getAssessmentData();
  }, []);

  const getAssessmentData = async () => {
    try {
      const result = await window.$http.getWithHeaders('my/assessment');
      if (result.code === window.$constants.STATUS.OK) {
        dispatch(saveAssmt(result));
        if (
          result?.data?.find(
            (el) => el.status === window.$enums.AssmtStatus.Previous
          )
        ) {
          setAssmtData(result);
        } else {
          return navigate('/dashboard/assessments');
        }
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getAssessmentResult = async () => {
      setIsLoading(true);
      try {
        const result = await window.$http.getById(
          'my/assessment/result',
          rp_user_assmt_id
        );

        if (result.code === window.$constants.STATUS.OK) {
          setIsLoading(false);
          setResultData(result);
        } else {
          console.log('Something went wrong!');
        }
      } catch (error) {
        console.log(error);
      }
    };
    getAssessmentResult();
  }, [rp_user_assmt_id]);

  const handlePrevAssmtResult = (id) => {
    return navigate(`/dashboard/assessments/result/${id}`);
  };

  const getReport = async (rp_user_assmt_id) => {
    setIsDownloadLoading(true);
    try {
      const result = await window.$http.getWithHeaders(
        `my/assessment/result/download/0/${rp_user_assmt_id}`
      );
      if (result.code === window.$constants.STATUS.OK) {
        setIsDownloadLoading(false);
        var linkSource = result.data.media_content;
        var downloadLink = document.createElement('a');
        var fileName = result?.data?.filename
          ? result?.data?.filename
          : 'result.pdf';
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      } else {
        setIsDownloadLoading(false);
        result?.message
          ? toast.error(result?.message)
          : toast.error('Error occured, please try again or contact admin.');
      }
    } catch (err) {
      setIsDownloadLoading(false);
      console.log(err);
    }
  };

  const handleCurrentAssmt = () => {
    if (user?.guided_tour_status !== '2') {
      setIsOpen(true);
    }

    navigate('/dashboard/assessments/');
  };

  return (
    <>
      {resultData ? (
        <main>
          <section>
            <div className='container'>
              <div className='row p-4 p-lg-0'>
                <div className='col-12'>
                  <div className='assessment-tabs-group d-flex align-items-center mb-3'>
                    {assmtData && (
                      <>
                        {assmtData?.data.map((data, id) =>
                          data?.status ===
                          window.$enums.AssmtStatus.Previous ? (
                            <button
                              type='button'
                              className={
                                (+rp_user_assmt_id === 0 &&
                                  (assmtData?.data?.find(
                                    (data) =>
                                      data?.status ===
                                      window.$enums.AssmtStatus.Current
                                  )
                                    ? assmtData?.data?.findIndex(
                                        (data) =>
                                          data?.status ===
                                          window.$enums.AssmtStatus.Current
                                      )
                                    : assmtData?.data?.find(
                                        (data) =>
                                          data?.status ===
                                          window.$enums.AssmtStatus.Next
                                      ) &&
                                      assmtData?.data?.findIndex(
                                        (data) =>
                                          data?.status ===
                                          window.$enums.AssmtStatus.Next
                                      )) -
                                    1 ===
                                    id) ||
                                (+rp_user_assmt_id === 0 &&
                                !assmtData?.data?.find(
                                  (data) =>
                                    data?.status ===
                                    window.$enums.AssmtStatus.Current
                                ) &&
                                !assmtData?.data?.find(
                                  (data) =>
                                    data?.status ===
                                    window.$enums.AssmtStatus.Next
                                )
                                  ? assmtData?.data?.length - 1 === id
                                  : '') ||
                                +rp_user_assmt_id === data?.rp_user_assmt_id
                                  ? 'btn-assment-month prev-mnth month-active'
                                  : 'btn-assment-month prev-mnth'
                              }
                              key={id}
                              onClick={() =>
                                handlePrevAssmtResult(data?.rp_user_assmt_id)
                              }
                            >
                              <span>Result</span>
                              <span>{data?.display_date}</span>
                            </button>
                          ) : data?.status ===
                            window.$enums.AssmtStatus.Next ? (
                            <button
                              className='btn-assment-month next-mnth disabled'
                              key={id}
                            >
                              <span>Next Assessment</span>
                              <span>{data?.display_date}</span>
                            </button>
                          ) : (
                            <LinkTo
                              to='assessment'
                              smooth={true}
                              duration={100}
                              spy={true}
                              key={id}
                            >
                              <button
                                className='btn-assment-month'
                                onClick={handleCurrentAssmt}
                              >
                                <span>Current</span>
                                <span>Assessment</span>
                              </button>
                            </LinkTo>
                          )
                        )}
                      </>
                    )}
                  </div>
                  <div className='bg-white about p-4 p-lg-5 br-12 br-top-0'>
                    {isLoading ? (
                      <Skeleton height={250} width={750} />
                    ) : (
                      <div className='col-md-6 col-lg-8'>
                        <p className='sub-hed-p'>
                          {
                            moment(resultData?.data?.completed_date)
                              .format('LL')
                              .split(' ')[0]
                          }{' '}
                          Assessment Result
                        </p>
                        <h1 className='section-heading rp-primary-dark-g'>
                          {resultData?.data?.burnout?.burnout_short_desc1
                            ? parse(
                                resultData?.data?.burnout?.burnout_short_desc1
                              )
                            : ''}
                        </h1>
                        <h2 className='section-sub-heading'>
                          {resultData?.data?.burnout?.burnout_short_desc2
                            ? parse(
                                resultData?.data?.burnout?.burnout_short_desc2
                              )
                            : ''}
                        </h2>
                      </div>
                    )}
                    <div className='col-md-6 col-lg-4 radial-chart text-end mt-3 my-lg-0 mb-5 mb-lg-0'>
                      {isLoading ? (
                        <Skeleton circle={true} height={250} width={250} />
                      ) : (
                        <RadialBar
                          burnout_stage_id={
                            resultData?.data?.burnout?.rp_gbl_burnout_stage_id
                          }
                          burnout_title={
                            resultData?.data?.burnout?.burnout_title
                          }
                          burnout_data={resultData?.data?.sections}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className='container'>
              <div className='row'>
                <div className='col-12'>
                  <div className='position-relative'>
                    <div className='dots-styles result_dot_style'></div>
                    <h2 className='section-titel section-heading mt-4'>
                      {' '}
                      Resiliency refers to your ability to deal with physical
                      and emotional stress.
                    </h2>
                    <div className='mt-5 p-4 p-xl-5 bg-white br-12 box_shadow'>
                      <span className='sub-titel'>Resiliency Factors</span>
                      <h2 className='section-sub-heading fw-normal primary-color'>
                        Factors towards Resiliency Result
                      </h2>
                      <div className='col-xl-8 col-md-12 mb-5'>
                        <p
                          style={{
                            fontSize: '23px',
                            margin: '17px 0',
                            lineHeight: '24px',
                          }}
                        >
                          Your resiliency result is calculated based on your
                          Enthusiasm, Fatigue, Motivation, and Negativity
                        </p>
                      </div>
                      {resultData?.data?.sections.map((data, id) => (
                        <>
                          <div className='row gx-5 user-results align-items-center mb-4'>
                            <div className='col-xl-5 col-md-12 user-result'>
                              <div class='d-flex'>
                                <div className='col-1 icon'>
                                  <img
                                    src={
                                      data.section_title === 'Enthusiasm'
                                        ? enthusiasmIcon
                                        : data.section_title === 'Motivation'
                                        ? motivationIcon
                                        : data.section_title === 'Negativity'
                                        ? negativityIcon
                                        : fatigueIcon
                                    }
                                    alt={`${data.section_title}`}
                                  />
                                </div>
                                {isLoading ? (
                                  <Skeleton height={100} width={350} />
                                ) : (
                                  <div className='li-cnt'>
                                    <h6 className='primary-color'>
                                      ({data.section_title.charAt(0)}){' '}
                                      {data.section_title}
                                    </h6>
                                    <p>
                                      {data.result_summary
                                        ? parse(data.result_summary)
                                        : ''}
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className='col-xl-7 col-md-12 z-1'>
                              {isLoading ? (
                                <Skeleton height={100} width={550} />
                              ) : (
                                <div className='mb-4' key={id}>
                                  <div className='resillency-factor'>
                                    <span>
                                      ({data?.section_title.charAt(0)}){' '}
                                      {data?.section_title}
                                    </span>
                                    <span
                                      style={{
                                        color: data?.color_code,
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      {' '}
                                      {data?.section_percentage} %
                                    </span>
                                  </div>
                                  <div className='factor-bar'>
                                    <Tooltip
                                      title={
                                        data?.is_high_score_resilient === true
                                          ? 'Minimum optimal level'
                                          : 'Maximum optimal level'
                                      }
                                      color='#00a39d'
                                      overlayStyle={{
                                        maxWidth: '106px',
                                        borderRadius: '10px',
                                      }}
                                      overlayInnerStyle={{
                                        fontSize: '12px',
                                        textAlign: 'center',
                                      }}
                                    >
                                      <div
                                        style={{
                                          left: data?.optimize_level,
                                        }}
                                        className='place'
                                      >
                                        <span>{data?.optimize_level}</span>
                                      </div>
                                    </Tooltip>
                                    <div className='progress'>
                                      <div
                                        className='progress-bar'
                                        role='progressbar'
                                        style={{
                                          width: `${Math.trunc(
                                            data.section_percentage
                                          )}%`,
                                          backgroundColor: data?.color_code,
                                        }}
                                        aria-valuenow='41'
                                        aria-valuemin='0'
                                        aria-valuemax='100'
                                      ></div>
                                    </div>
                                  </div>
                                  <div className='scaling-factor'>
                                    <span
                                      className={
                                        data?.is_high_score_resilient === false
                                          ? 'primary-color fw-bold'
                                          : 'rp-primary-red-color fw-bold'
                                      }
                                    >
                                      Low
                                    </span>
                                    <span
                                      className={
                                        data?.is_high_score_resilient === true
                                          ? 'primary-color fw-bold'
                                          : 'rp-primary-red-color fw-bold'
                                      }
                                    >
                                      High
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className='container more-about-result mt-5'>
              <div className='row'>
                <div className='col-12'>
                  <div className='box_shadow result-summry bg-white p-4 br-12'>
                    <h2 className='section-sub-heading fw-normal primary-color'>
                      Result Summary
                    </h2>

                    {isLoading ? (
                      <Skeleton height={250} width={1100} />
                    ) : (
                      <p className='pe-4' style={{ lineHeight: '2rem' }}>
                        {resultData?.data?.burnout?.burnout_summary
                          ? parse(resultData?.data?.burnout?.burnout_summary)
                          : ''}
                      </p>
                    )}
                  </div>
                </div>
                <BurnoutContent resultData={resultData} />
              </div>
              {resultData?.data?.should_show_recommendation ? (
                <>
                  <h2 className='section-titel section-heading mt-4 mb-5'>
                    {resultData?.data?.recommended_title}
                  </h2>
                  <div className='row gx-0 gx-lg-5'>
                    {resultData && (
                      <VideoPlayer
                        resultData={resultData?.data?.recommended_content}
                      />
                    )}
                  </div>
                  <div className='load-more-recommended text-end'>
                    <div className='d-inline-block'>
                      <div
                        className='load-button'
                        onClick={() => navigate('/dashboard/resiliency-plan')}
                      >
                        <span>View more recommendation</span>
                        <i className='fa-solid fa-circle-chevron-right'></i>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ''
              )}
              <div className='mt-4'>
                <Link
                  to='/dashboard/resiliency-plan'
                  className='btn btn-rp-primary'
                  onClick={() => navigate('/dashboard/resiliency-plan')}
                >
                  Take me to my Resiliency Plan
                </Link>
                <button
                  className='btn btn-rp-primary ms-lg-3 ms-0 mt-lg-0 mt-3'
                  onClick={() => getReport(rp_user_assmt_id)}
                  disabled={isDownloadLoading && true}
                >
                  {isDownloadLoading ? (
                    <Spinner
                      as='i'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    'Download Result'
                  )}
                </button>
              </div>
            </div>
          </section>
        </main>
      ) : (
        <>
          <div className='centered'>
            <LoadingSpinner />
          </div>
          <div style={{ height: '100vh' }}></div>
        </>
      )}
      <ToastContainer />
    </>
  );
};

export default AssmtResult;
