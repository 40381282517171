import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { saveRevision } from '../../../store/revision/actions';
import { mfaLogin } from '../../../store/mfaAuth/actions';
import { login } from '../../../store/auth/actions';
import OTPInput from 'otp-input-react';
import Spinner from 'react-bootstrap/Spinner';
import { save } from '../../../store/user/actions';

const device_id = 'RP123456789';

const LoginWithOtp = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { redirectTo } = queryString.parse(location.search);
  const [otp, setOtp] = useState({ emailOtp: '', mobileOtp: '' });
  const [invalidOtp, setInvalidOtp] = useState({
    emailOtp: false,
    mobileOtp: false,
  });
  const [counter, setCounter] = useState(5);
  const [isResendClicked, setIsResendClicked] = useState(false);
  const [message, setMessage] = useState({ success: '', failure: '' });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const { password_reset_hash } = useParams();
  const [result, setResult] = useState();

  useEffect(() => {
    if (password_reset_hash) {
      getOtpCodes(password_reset_hash);
    }
  }, []);

  useEffect(() => {
    if (props?.otpLoginData?.resent_timer) {
      setCounter(props?.otpLoginData?.resent_timer);
    }
  }, [props?.otpLoginData?.resent_timer]);

  const getOtpCodes = async (hashCode) => {
    try {
      const result = await window.$http.postWithHeaders('loginwithotp/hash', {
        password_reset_hash: hashCode,
      });

      if (result.code === window.$constants.STATUS.OK) {
        setResult(result?.data);
        setCounter(result?.data?.resent_timer);
        setMessage({ success: result?.message, failure: '' });
      } else {
        setMessage({ success: '', failure: result?.message });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const handleChangeOtp = (value, name) => {
    let currentFormErrors = formErrors;
    setOtp({ ...otp, [name]: value });
    setMessage({ success: '', failure: '' });

    switch (name) {
      case 'emailOtp':
        if (!value) {
          currentFormErrors[name] = 'Please enter otp';
        } else if (!global.numberRegex.test(value)) {
          currentFormErrors[name] = 'Please enter valid otp';
        } else {
          setInvalidOtp({ ...invalidOtp, emailOtp: false });
          delete currentFormErrors[name];
        }
        break;

      case 'mobileOtp':
        if (!value) {
          currentFormErrors[name] = 'Please enter otp';
        } else if (!global.numberRegex.test(value)) {
          currentFormErrors[name] = 'Please enter valid otp';
        } else {
          setInvalidOtp({ ...invalidOtp, mobileOtp: false });
          delete currentFormErrors[name];
        }
        break;

      default:
        currentFormErrors = 'All fields are required';
        break;
    }
    setOtp({ ...otp, [name]: value });
    setFormErrors(currentFormErrors);
    props.handleMsg();
  };

  const callProfileData = async () => {
    try {
      const result = await window.$http.getWithHeaders('v2/my_profile');
      if (result.code === window.$constants.STATUS.OK) {
        dispatch(save(result));
        if (
          result?.data?.rp_gbl_company_type_id ===
          window.$enums.CompanyTypeId.CompanyAdmin
        ) {
          return navigate('/manage');
        } else if (result?.data?.visible_header_tabs?.includes('admin')) {
          return navigate('/manage');
        } else if (!result?.data?.is_web_enabled) {
          return navigate('/landing-page');
        } else {
          if (result?.data?.is_profile_completed === false) {
            if (result?.data?.onboarding_step === 0) {
              setIsButtonLoading(false);
              return navigate(
                redirectTo === undefined ? '/on-boarding' : `/on-boarding`
              );
            } else if (result?.data?.onboarding_step === 1) {
              setIsButtonLoading(false);
              return navigate(
                redirectTo === undefined
                  ? '/phone-verification'
                  : `/phone-verification`
              );
            } else {
              setIsButtonLoading(false);
              return navigate(
                redirectTo === undefined ? '/dashboard' : `${redirectTo}`
              );
            }
          } else {
            if (redirectTo === '/on-boarding') {
              setIsButtonLoading(false);
              return navigate('/dashboard');
            }
            if (
              result?.data?.landing_page === 0 ||
              result?.data?.landing_page === 1
            ) {
              setIsButtonLoading(false);
              return navigate(
                redirectTo === undefined ? '/dashboard' : `${redirectTo}`
              );
            } else if (result?.data?.landing_page === 2) {
              setIsButtonLoading(false);
              return navigate(
                redirectTo === undefined
                  ? '/dashboard/resiliency-plan'
                  : `${redirectTo}`
              );
            } else {
              setIsButtonLoading(false);
              return navigate(
                redirectTo === undefined
                  ? '/dashboard/assessments/result/0'
                  : `${redirectTo}`
              );
            }
          }
        }
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSigninWithOtp = async (e) => {
    e.preventDefault();
    props.handleMsg();
    setMessage({ success: '', failure: '' });

    if (!props?.password_reset_hash && !props?.email) {
      setMessage({ success: '', failure: 'Email is required' });
      return setIsSubmitting(true);
    } else if (!otp?.emailOtp) {
      setMessage({
        success: '',
        failure: 'Email code is required',
      });
      return setIsSubmitting(true);
    } else if (props?.otpLoginData?.is_mfa_required && !otp?.mobileOtp) {
      setMessage({
        success: '',
        failure: 'Mobile code is required',
      });
      return setIsSubmitting(true);
    } else if (
      props?.otpLoginData?.is_mfa_required &&
      !global.otpRegex.test(otp?.mobileOtp) &&
      !global.otpRegex.test(otp?.emailOtp)
    ) {
      setInvalidOtp({ emailOtp: true, mobileOtp: true });
      setMessage({ success: '', failure: 'The code should be six digit' });
      return setIsSubmitting(true);
    } else if (!global.otpRegex.test(otp?.emailOtp)) {
      setInvalidOtp({ ...invalidOtp, emailOtp: true });
      setMessage({ success: '', failure: 'The code should be six digit' });
      return setIsSubmitting(true);
    } else if (
      props?.otpLoginData?.is_mfa_required &&
      !global.otpRegex.test(otp?.mobileOtp)
    ) {
      setInvalidOtp({ ...invalidOtp, mobileOtp: true });
      setMessage({ success: '', failure: 'The code should be six digit' });
      return setIsSubmitting(true);
    } else {
      setIsButtonLoading(true);
      try {
        const result = await window.$http.postWithoutHeaders(
          `${
            props?.password_reset_hash
              ? 'v2/validateloginwithotp/hash'
              : 'v2/validateloginwithotp'
          }`,
          {
            email: !props?.password_reset_hash ? props?.email : '',
            device_id,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            otp_code: otp?.emailOtp,
            mfa_code: otp?.mobileOtp,
            mfa_token: props?.otpLoginData?.mfa_token,
            remember_me: true,
            password_reset_hash: props?.password_reset_hash,
          }
        );
        if (result.code === window.$constants.STATUS.OK) {
          dispatch(saveRevision(result.revision));
          setMessage({ success: result?.message, failure: '' });
          if (result.data.is_mfa_required) {
            setCounter(props?.otpLoginData?.resent_timer);
            dispatch(mfaLogin(result));
          } else {
            dispatch(login(result));
            callProfileData();
          }
        } else if (
          result.code === window.$constants.STATUS.UNAUTHORIZED ||
          window.$constants.STATUS.BAD_REQUEST
        ) {
          setInvalidOtp({ emailOtp: true, mobileOtp: true });
          setIsButtonLoading(false);
          return setMessage({ success: '', failure: result.message });
        } else {
          setInvalidOtp({ emailOtp: true, mobileOtp: true });
          setIsButtonLoading(false);
          return setMessage({ success: '', failure: result.message });
        }
      } catch (error) {
        console.log(error);
      }
      setIsButtonLoading(false);
    }
  };

  const handleResendOtp = async () => {
    try {
      const result = await window.$http.postWithoutHeaders(
        `${password_reset_hash ? 'loginwithotp/hash' : 'loginwithotp'}`,
        {
          email: props?.email,
          password_reset_hash: password_reset_hash,
        }
      );
      if (result.code === window.$constants.STATUS.OK) {
        setIsResendClicked(true);
        setCounter(result?.data?.resent_timer);
        setMessage({ success: result?.message, failure: '' });
      } else {
        setMessage({ success: '', failure: result?.message });
      }
    } catch (error) {
      console.log(error);
    }
    props.handleMsg();
  };

  const handleOtp = () => {
    setMessage({ success: '', failure: '' });
    props.handleOtp();
  };

  return (
    <>
      <form className='Otp_login' onSubmit={handleSigninWithOtp}>
        <div className='wlcm-note mt-6'>
          <h6>Authentication</h6>
          <p>
            {props?.otpLoginData?.message
              ? props?.otpLoginData?.message
              : result?.message
              ? result?.message
              : ''}
          </p>
        </div>
        <div className='my-4'>
          <div className='userInput'>
            <label className='asterisk'>Email code</label>
            <div className='input-otp'>
              <OTPInput
                className={
                  formErrors?.emailOtp ||
                  (isSubmitting && !otp?.emailOtp) ||
                  invalidOtp.emailOtp
                    ? 'is_danger'
                    : ''
                }
                value={otp?.emailOtp}
                onChange={(e) => handleChangeOtp(e, 'emailOtp')}
                autoFocus
                OTPLength={6}
                otpType='number'
              />
            </div>
          </div>
          {(props?.password_reset_hash && result?.is_mfa_required) ||
          (!props?.password_reset_hash &&
            props?.otpLoginData?.is_mfa_required) ? (
            <div className='userInput mt-4'>
              <label className='asterisk'>Mobile code</label>
              <div className='input-otp'>
                <OTPInput
                  className={
                    formErrors?.mobileOtp ||
                    (isSubmitting && !otp?.mobileOtp) ||
                    invalidOtp.mobileOtp
                      ? 'is_danger'
                      : ''
                  }
                  value={otp?.mobileOtp}
                  onChange={(e) => handleChangeOtp(e, 'mobileOtp')}
                  OTPLength={6}
                  otpType='number'
                />
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className='my-2 text-end'>
          <span className='bck-log cursor-p' onClick={handleOtp}>
            Back to login
          </span>
        </div>
        <div className='my-4'>
          <span
            className={`primary-color cursor-p ${
              counter > 0 ? 'disabled' : ''
            }`}
            onClick={handleResendOtp}
          >
            Resend authentication code
          </span>
          <span
            className='rp-primary-red-color ms-2'
            id='time'
            style={counter <= 0 ? { visibility: 'hidden' } : {}}
          >
            {counter ? `${counter} Sec.` : ''}
          </span>
        </div>
        <button
          type='submit'
          className={`btn btn-login mt-2 ${isButtonLoading ? 'disabled' : ''}`}
          onClick={handleSigninWithOtp}
        >
          {isButtonLoading ? (
            <Spinner
              as='i'
              animation='border'
              size='sm'
              role='status'
              aria-hidden='true'
              style={{ color: '#fff' }}
            />
          ) : (
            'Sign In'
          )}
        </button>
      </form>
      {message?.failure ? (
        <p className='mt-4 text-danger fs-6'> {message?.failure} </p>
      ) : (
        message?.success && (
          <p className='primary-color mt-4 fs-6'>{message?.success}</p>
        )
      )}
    </>
  );
};

export default LoginWithOtp;
