import React from 'react';

const ProgressBar = (props) => {
  return (
    <>
      {props.burnouts?.length
        ? props.burnouts?.map((item, index) => (
            <>
              <div className='progress'>
                <div
                  key={index}
                  className='progress-bar'
                  role='progressbar'
                  style={
                    props?.value === 'people'
                      ? {
                          width: `${item.percnt}%`,
                          backgroundColor: `${item.color}`,
                        }
                      : {
                          width: `${item.participated_percentage}%`,
                          backgroundColor: `${item.color}`,
                        }
                  }
                  aria-valuenow={10}
                  aria-valuemin={0}
                  aria-valuemax={100}
                ></div>
              </div>
              <div className='d-flex justify-content-between mb-3'>
                <span
                  className='label fw-bold'
                  style={{ color: `${item.color}` }}
                >
                  {item?.title}
                </span>
                <span className='participants-count'>
                  {props?.value === 'people'
                    ? `${item?.percnt}%
                        (${item?.count} of ${item?.total_count})`
                    : `${item?.participated_percentage} % (${item?.participated} of ${item?.total})`}
                </span>
              </div>
            </>
          ))
        : 'No Data'}
    </>
  );
};

export default ProgressBar;
